import { call, put, takeLatest } from 'redux-saga/effects';
import LikeLog from '../Types/likeLog';
import { getLikes } from '../../Api/likeLogApi';
import { loadLikeLogFail, loadLikeLogSuccess } from '../Actions/likeLog';

function* handleLoadLikeLog(action) {
  try {
    const response = yield call(getLikes, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLikeLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLikeLogFail(error));
  }
}

export default function* likeLogSaga() {
  yield takeLatest(LikeLog.LOAD_LIKELOG, handleLoadLikeLog);
}
