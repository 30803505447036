import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { getUser } from '../../../Api/userAPI';
import {  sendEmailAgent, setTimeMailAgent } from '../../../Api/agentAPI';
import { MuiThemeProvider } from '@material-ui/core';
import { Card, Col, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import RadioGroup from '../../../Components/RadioGroup';
import CustomEditor from '../../../Components/CustomEditor';
import { getEnvironment } from '../../../Api/environmentApi';
import { conversionTags } from '../../../Api/conversionTagApi';
import { CONVERSION_TAG_URL, DISPLAY_OPTIONS, GENDER, TIMER_SENDING_TYPE } from '../../../Common/constant';
import Util from '../../../Util/Util';
import { style } from './common-styles';
import { themeStyleNormal1Modal } from './theme_modal';
import { TEXT_HTML_SEND_EMAIL_MODAL } from '../../../Common/modal_text_info';
import { convertDisplayOptionsText, displayOptionsOptionsRequired } from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { CODE_MAP_DEFINED_API } from '../../../Config/listConstants';

function SendEmailAgentModal(props) {
  const { visible, onClose, agentId, nickName, avatar, password, phoneNumber, reload, email, envInfoTag, needOtp, labelDb } = props;

  const [data, setData] = useState({});
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(moment(new Date(moment(new Date())).getTime()).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState(new Date(moment().startOf('day')).getTime());
  const [timeSend, setTimeSend] = useState();
  const [timerMail, setTimerMail] = useState(`${DISPLAY_OPTIONS.OFF}`);

  const [fromMails, setFromMails] = useState([]);
  const [conversionTag, setConversionTag] = useState([]);
  const [contentPreview, setContentPreview] = useState(null);

  useEffect(() => {
    getEnvironment({}).then((res) => {
      const env = res;
      const sendGridAddress = Object.keys(env.data.data).filter(t => t.indexOf('sendgridAddress') > -1).map(t => {
        return {
          key: t,
          value: env.data.data[t],
        };
      });
      const sendGridAddressFirst = sendGridAddress?.[0];
      data['fromAddress'] = sendGridAddressFirst?.key;
      data['from_mail_address'] = sendGridAddressFirst?.value;

      setData(data);

      setFromMails(sendGridAddress);
    });
    conversionTags({}).then((res) => {
      const { data: tags } = res;
      setConversionTag((tags?.data || []));
    });
  }, []);

  const replaceProfileSQLId = async (content, preview) => {
    if (content.includes('%%PROFILE_SQLID')) {
      const item = conversionTag.find(t => t.tag === '%%PROFILE_SQLID_UUID%%');
      if (item) {
        const userSendId = (content.split('%%PROFILE_SQLID_')[1] + '').substring(0, 36);
        const IsGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(userSendId);
        if (IsGuid) {
          let user = null;
          try {
            const response = await getUser(userSendId);
            const { data: userData } = response;
            user = userData.data;
            const myRegex = new RegExp(`%%PROFILE_SQLID_${userSendId}%%`, 'g');
            const path = `-user/${userSendId.replace(/-/g, '*')}`;
            const params = `${phoneNumber}-${password || ''}${path}`;

            const url = Util.buildLink('/autologin/?id=' + btoa(params));
            const userInfo = `◇${user.nickName}　${Util.calcAge(Util.timestampToDate(user.birthday, 'YYYY-MM-DD'))}歳　${user.gender === GENDER.MALE ? '男性' : '女性'}　${user?.states?.label || ''}`;
            content = content.replace(myRegex, `<p>${userInfo}</p>${(preview ? (item.previewMail || item.contentMail) : item.contentMail)}`.replace(/#URL#/g, url));
          } catch (e) {
            content = content.replace(new RegExp(`%%PROFILE_SQLID_${userSendId}`, 'g'), '<span style="color: red">PROFILE_SQLID_ERROR_TAG</span>');
          }
        } else {
          const text = userSendId.includes('%%') ? userSendId.split('%%')[0] : userSendId;
          content = content.replace(new RegExp(`%%PROFILE_SQLID_${text}%%`, 'g'), '<span style="color: red">PROFILE_SQLID_ERROR_TAG</span>');
        }
        if (content.includes('%%PROFILE_SQLID')) {
          return replaceProfileSQLId(content, preview);
        }

        return content;
      }
    }
    return content;
  };

  const handleSubmit = async () => {
    if (data && data['content'] && data.content.includes('%%PROFILE_SQLID')) {
      const contentProfile = await replaceProfileSQLId(data['content']);
      const content = await replaceUrl(contentProfile);
      data['content'] = content;
      setData(data);
      if (String(timerMail) && +timerMail === DISPLAY_OPTIONS.OFF) {
        await sendEmailAgent({ id: agentId, ...data, force_send: true })
          .then((response) => {
            onClose();
            reload();
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          })
          .catch((e) => {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            onClose();
            reload();
          });
      } else {
        await setTimeMailAgent({
          sendTime: timeSend,
          fromAddress: data['fromAddress'],
          agentIds: [agentId],
          type: TIMER_SENDING_TYPE.FROM_AGENT_DETAIL,
          ...data,
        })
          .then((response) => {
            onClose();
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          })
          .catch((e) => {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            onClose();
          });
      }
      return;
    }

    if (data && data['content']) {
      const content = await replaceUrl(data['content']);
      data['content'] = content;
      setData(data);
    }
    if (String(timerMail) && +timerMail === DISPLAY_OPTIONS.OFF) {
      await sendEmailAgent({ id: agentId, ...data, force_send: true })
        .then((response) => {
          onClose();
          reload();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        })
        .catch((e) => {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          onClose();
          reload();
        });
    } else {
      await setTimeMailAgent({
        sendTime: timeSend,
        fromAddress: data['fromAddress'],
        agentIds: [agentId],
        type: TIMER_SENDING_TYPE.FROM_AGENT_DETAIL,
        ...data,
      })
        .then((response) => {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        })
        .catch((e) => {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          onClose();
        });
    }

  };

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const onChangeContent = (event) => {
    data['content'] = event.editor.getData();
    setData(data);
  };

  const handleConfirm = async () => {
    if (!email) {
      return toast.error('メールアドレスが登録されていません!');
    }
    if (!data.title) {
      return toast.error('タイトルしてください!');
    }
    if (!data.content) {
      return toast.error('本文してください!');
    }
    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;
    if (String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON && checkTime && checkTime < new Date().getTime()) {
      return toast.error('予約時間が過去に設定されています');
    }
    const content = await replaceUrl(data.content, true);
    setContentPreview(content);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleFromMailAddress = (e, key) => {
    data[key] = e.target.value;
    data['fromAddress'] = e.target[e.target.selectedIndex].getAttribute('data-key');
    setData(data);
  };

  const replaceUrl = async (content, preview) => {
    if (content.includes('%%PROFILE_SQLID')) {
      const contentProfile = await replaceProfileSQLId(content, preview);
      if (contentProfile) content = contentProfile;
    }
    for (const item of conversionTag) {
      const regex = new RegExp(item.tag, 'g');
      if (['%%LOGIN_HOME%%',
        '%%LOGIN_BUY_POINT%%',
        '%%LOGIN_MYPAGE%%',
        '%%LOGIN_INFO_1%%',
        '%%LOGIN_INFO_2%%',
        '%%LOGIN_INFO_3%%',
        '%%LOGIN_INFO_4%%',
        '%%LOGIN_INFO_5%%',
        '%%LOGIN_INFO_6%%',
        '%%LOGIN_INFO_7%%',
        '%%LOGIN_INFO_8%%',
        '%%LOGIN_MYPROFILE%%',
      ].includes(item.tag) && content.indexOf(item.tag) > -1) {
        let path = '';
        let replace = true;
        if (item.tag === '%%LOGIN_BUY_POINT%%') {
          path = '-point';
        }
        if (item.tag === '%%LOGIN_MYPAGE%%') {
          path = '-mypagemale';
        }
        if (item.tag === '%%LOGIN_INFO_1%%') {
          path = '-info/info1';
        }
        if (item.tag === '%%LOGIN_INFO_2%%') {
          path = '-info/info2';
        }
        if (item.tag === '%%LOGIN_INFO_3%%') {
          path = '-info/info3';
        }
        if (item.tag === '%%LOGIN_INFO_4%%') {
          path = '-info/info4';
        }
        if (item.tag === '%%LOGIN_INFO_5%%') {
          path = '-info/info5';
        }
        if (item.tag === '%%LOGIN_INFO_6%%') {
          path = '-info/info6';
        }
        if (item.tag === '%%LOGIN_INFO_7%%') {
          path = '-info/info7';
        }
        if (item.tag === '%%LOGIN_INFO_8%%') {
          path = '-info/info8';
        }
        if (item.tag === '%%LOGIN_MYPROFILE%%') {
          path = `-myprofile`;
        }
        const params = `${phoneNumber}-${password || ''}${path}`;
        const url = Util.buildLink('/autologin/?id=' + btoa(params), replace);
        content = content.replace(regex, (preview ? (item.previewMail || item.contentMail) : item.contentMail).replace(/#URL#/g, url));
      } else if (item.tag === '%NICKNAME%' && content.indexOf(item.tag)) {
        content = content.replace(regex, preview ? item.previewMail : `${nickName}`);
      } else if (item.tag === '%SITE_NAME%' && content.indexOf(item.tag)) {
        const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.SITE_NAME)?.content;
        content = content.replace(regex, preview ? item.previewMail : `${description}`);
      } else if (item.tag === '%URL_LP%' && content.indexOf(item.tag)) {
        const url = Util.buildLink('/lp');
        const txtLp = preview ? (item.previewMail || item.contentMail) : item.contentMail;
        if (txtLp) {
          content = content.replace(regex, txtLp.replace(/#URL#/g, url));
        }
      } else if (item.tag === '%MAIL_ADDRESS%' && content.indexOf(item.tag)) {
        const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS)?.content;
        content = content.replace(regex, preview ? item.previewMail : `${description}`);
      } else if (item.tag === '%PHONE_NUMBER%' && content.indexOf(item.tag)) {
        const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER)?.content;
        content = content.replace(regex, preview ? item.previewMail : `${description}`);
      } else if (item.tag === '%URL_CONTACT%' && content.indexOf(item.tag)) {
        const url = Util.buildLink(CONVERSION_TAG_URL.URL_CONTACT);
        const txtContact = preview ? (item.previewMail || item.contentMail) : item.contentMail;
        if (txtContact) {
          content = content.replace(regex, txtContact.replace(/#URL#/g, url));
        }
      } else {
        content = content.replace(regex, preview ? (item.previewMail || item.contentMail) : item.contentMail);
      }
    }
    setConFirmVisible(preview);

    return content;
  };

  const handlerTimerMail = (value) => {
    setTimerMail(`${value}`);
  };

  return (
    <div style={{ position: 'relative' }}>
      <>
        {confirmVisible && (
          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={confirmVisible}
            onClose={onCloseConfirm}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
            <Button
              style={{
                position: 'absolute',
                top: 8,
                right: 0,
              }}
              onClick={onCloseConfirm}
            >
              <CloseIcon color="primary" />
            </Button>
            <DialogContent>
              <div style={{ margin: '0.6rem 0 1rem 0' }}>
                {/*実行してよろしいですか？*/}
                <Card body className="mb-4">
                  <Row>
                    <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <p style={{ paddingLeft: 3 }}>日時</p>
                      </div>
                      <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                        <p>
                          {String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON && timeSend ? moment(timeSend).format('YYYY/MM/DD HH:mm') : dateSend}
                        </p>
                      </div>
                    </Col>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                        <span
                          className={`${String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON ? 'd-red-color' : 'd-blue-color'}`}>
                          {convertDisplayOptionsText(timerMail)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <p style={{ paddingLeft: 3 }}>送信元メールアドレス</p>
                      </div>
                      <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                        {data?.from_mail_address}
                      </div>
                    </Col>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                    </Col>
                  </Row>

                  <Row className={'d-line-col'}>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                    </Col>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                    </Col>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label style={style} xs={3}>
                      <p style={{ paddingLeft: 3 }}>タイトル</p>
                    </Label>
                    <Label style={{ ...style, paddingLeft: 8 }} xs={9}>
                      {data?.title}
                    </Label>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label style={style} xs={3}>
                      <p style={{ paddingLeft: 3 }}>本文</p>
                    </Label>
                    <Label style={{ ...style, paddingLeft: 8 }} xs={9}>
                      <div dangerouslySetInnerHTML={{ __html: contentPreview }}></div>
                    </Label>
                  </Row>
                </Card>

              </div>
              <div>
                <center style={{ margin: 15 }}>
                  <Button
                    onClick={onCloseConfirm}
                    color="primary"
                    className="btn-custom btn-red"
                  >
                    いいえ
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    className="btn-custom btn-blue"
                  >
                    はい
                  </Button>
                </center>
              </div>
            </DialogContent>
          </Dialog>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginRight: 50,
              }}
            >
              <p className={'d-title mb-0'}>会社詳細-個別メール送信</p>
              <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>受信者</p>
                <img className="object-fit-cover"
                     style={{
                       width: 30,
                       height: 30,
                       borderRadius: '50%',
                       marginRight: 20,
                     }}
                     src={avatar ? avatar.url : NoPhoto}
                     alt=""
                />
                <p className={'mb-0'} style={{ fontSize: '1rem' }}>{nickName}</p>
              </div>
            </div>
          </DialogTitle>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_SEND_EMAIL_MODAL }}></div>
          </div>

          <DialogContent>
            <MuiThemeProvider theme={themeStyleNormal1Modal}>
              <Card style={{ marginBottom: 10 }}>
                <Row style={{ padding: 20 }}>
                  <Col xs={12}>
                    <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>タイトル</div>
                    <input
                      style={{
                        width: '100%',
                        marginBottom: '20px',
                        border: '1px solid rgb(118, 118, 118)',
                      }}
                      name="title"
                      onChange={onChange}
                    />
                    <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>本文</div>

                    <CustomEditor
                      name="content"
                      rows="3"
                      data=""
                      onChange={onChangeContent}
                      style={{ width: '100%', marginBottom: '20px' }}
                      maxLength={500}
                    />

                    <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>送信元メールアドレス
                    </div>
                    <Row>
                      <Col className="datePicker" xs={12}>
                        <select
                          onChange={(e) => handleFromMailAddress(e, 'from_mail_address')}
                          style={{ width: '100%' }}
                        >
                          {fromMails.map((t) => (
                            <option data-key={t.key} value={t.value}>
                              {t.value}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>

                    <div className="mt-3 mb-1 timer-container">
                      <span>タイマー日時</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="datePicker">
                        <DatePicker
                          selected={selectedDate}
                          className="date"
                          onChange={(e) => handleDate(e)}
                          locale="ja"
                          dateFormat="yyyy/MM/dd"
                          popperPlacement="top-start"
                          disabled={!(String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON)}
                        />
                        <select
                          defaultChecked={hourSend}
                          onChange={(e) => handleHour(e, 'start_hour')}
                          className="hour"
                          disabled={!(String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON)}
                        >
                          {hour.map((value) => (
                            <option selected={value.toString() === hourSend} key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                        <select
                          onChange={(e) => handleMinus(e, 'start_minus')}
                          className="minus"
                          disabled={!(String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON)}
                        >
                          {minute.map((value) => (
                            <option selected={value.toString() === minusSend} key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="display-setting-timer d-un-width">
                        <RadioGroup
                          name="displaySetting"
                          defaultValue={timerMail}
                          options={displayOptionsOptionsRequired()}
                          onChange={(e) => handlerTimerMail(Number(e.target.value))}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <div style={{ textAlign: 'right', marginBottom: 2 }}>
                {data ? (
                  <Button
                    onClick={handleConfirm}
                    color="primary"
                    className="btn-custom btn-blue"
                  >
                    送信
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: 'lightgray',
                      color: 'white',
                      cursor: 'no-drop',
                      padding: '4px 36px',
                      fontSize: 18,
                      fontWeight: 800,
                    }}
                  >
                    送信
                  </Button>
                )}
              </div>
            </MuiThemeProvider>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default SendEmailAgentModal;
