import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Label, Row } from 'reactstrap';
import LoadingBar from '../../../Components/LoadingBar';
import { PROFILE_TYPE } from '../../../Config/listConstants';
import { loadAgent, updateAgent } from '../../../Redux/Actions/agent';
import { loadResourceAgent } from '../../../Redux/Actions/resourceAgent';
import ImageList from './ImageList';
import set from 'lodash/set';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
import { loadAllAreaAgent, loadStateAgent } from '../../../Redux/Actions/nameStateAgent';
import {
  checkFullWidth,
  convertEnvInfo,
  validateFullWidthVsAlphabet,
} from '../../../Util/utils';
import { REGEX_ALPHABET, REGEX_EMAIL, REGEX_NUMBER } from '../../../Common/constant';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { Button, createMuiTheme, FormLabel, MenuItem, MuiThemeProvider, Select } from '@material-ui/core';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function EditAgent() {
  const params = useParams();
  const agentId = params.agentId;
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();
  const [colorLabel, setColorLabel] = useState('')

  useEffect(() => {
    dispatch(loadAgent(agentId));
    dispatch(loadStateAgent());
    dispatch(loadResourceAgent({}));
  }, [dispatch, agentId]);

  const history = useHistory();
  const redirectPageDetailAgent = (id) => {
    history.push(`/agentope/${id}/detailope`);
  };

  const loading = useSelector((state) => state.agent.loading);
  const agent = useSelector((state) => state.agent.data);
  const updated = useSelector((state) => state.agent.updated);
  const resource = useSelector((state) => state.resourceAgent.data);
  const loadingResource = useSelector((state) => state.resourceAgent.loading);
  const nameStateAgent = useSelector((state) => state.nameStateAgent.data);
  const areas = useSelector((state) => state.nameStateAgent.dataChild);
  const envInfo = useSelector((state) => state.envInfo.data);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2?.content)
  }, [envInfo]);

  const [editingAgent, setEditingAgent] = useState();

  useEffect(() => {
    setEditingAgent(agent);

    if (agent?.state) {
      dispatch(loadAllAreaAgent(agent?.state));
    }
  }, [dispatch, agent]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailAgent(agentId)
    }
  }, [updated]);

  if (!editingAgent || !agent || !resource) {
    return null;
  }

  // Profile
  const showResource = () => {
    return resource.items
      .filter((item) => item.value === PROFILE_TYPE.ROOT)
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        return {
          resource: findResources(resourceItem.label),
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label)?.label,
        };
      }).map((item, key) => (
        <Row key={`${key}-${item.name}`}>
          <Label xs={12}>
            <FormLabel style={{ color: colorLabel }} component="legend">{item.label}</FormLabel>
            <div className="d-line-select-border">
              {!loadingResource ? (
                <Select name={item.name}
                        key={item?.name}
                        onChange={onChangeAttribute}
                        className={'w-100 d-select-multiple-cus'}
                        defaultValue={agent?.attributes && agent?.attributes[item?.name]}
                        value={editingAgent?.attributes && editingAgent?.attributes[item?.name]}
                >
                  <MenuItem value=""></MenuItem>
                  {item.resource
                    .sort((a, b) => a.value - b.value)
                    .map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option?.value}
                        selected={
                          editingAgent?.attributes
                          && option?.value === editingAgent?.attributes[option.profile]
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              ) : <div></div>}
            </div>
          </Label>
        </Row>
      ));
  };

  const onChangeAttribute = (e) => {
    setEditingAgent({
      ...editingAgent,
      attributes: {
        ...editingAgent.attributes,
        [e.target.name]: Number(e.target.value),
      },
    });
  };

  const findAttributes = (resourceName) => {
    let parentName = resource.items.find((x) => x.label === resourceName);

    const responseResources = resource.items.find(
      (x) => x.profile === parentName?.profile,
      // x.value === user.attributes[snakeToCamel(parrentName.profile)],
    );

    return responseResources?.label;
  };

  const findResources = (resourceName) => {
    const parentName = resource.items.find((x) => x.label === resourceName);

    return resource.items.filter(x => x.profile === parentName.profile && x.value !== 0);
  };

  const refreshAgent = () => {
    dispatch(loadAgent(agentId));
  };

  const handleChangeState = (e) => {
    onChange(e);
    dispatch(loadAllAreaAgent(e.target.value));
  };

  const handleSubmit = () => {
    if (!editingAgent.nickName) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agent.name);
    }

    if (!editingAgent.password) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
    } else if (editingAgent.password && (
      editingAgent.password.length < 8
      || editingAgent.password.length > 10
      || !REGEX_ALPHABET.test(editingAgent.password)
      || checkFullWidth(editingAgent.password)
    )) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
    }

    if (editingAgent.phoneNumber && (checkFullWidth(editingAgent.phoneNumber) || !REGEX_NUMBER.test(String(editingAgent.phoneNumber)))) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
    }

    if (!editingAgent.emailAddressMain) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.email);
    } else if (editingAgent.emailAddressMain && !REGEX_EMAIL.test(editingAgent.emailAddressMain)) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
    }

    if (!editingAgent.agentNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agencyNumber);
    } else if (editingAgent.agentNumber && !REGEX_ALPHABET.test(editingAgent.agentNumber)) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.agencyNumber);
    }

    const updateData = pick(editingAgent, [
      'id',
      'nickName',
      'emailAddressMain',
      'phoneNumber',
      'password',
      'registerType',
      'agentNumber',
      'state',
      'logincontrol',
      'rank',
      'aboutMe',
      'adminMemo',
      'attributes',
      'viewStyle',
      'area',
      'nameContact',
      'postCode',
      'address',
      'urlCompany',
      'googleMap',
      'sendgridStatus',
      'memberType',
      'planExpireDate',
      'countPaying',
      'totalPaying',
      'datePayment',
      'balancePayment',
    ]);
    dispatch(updateAgent(updateData));
  };

  const onChange = (e) => {
    const updatedAgent = set(editingAgent, e.target.name, e.target.value);
    if ('state' === e.target.name) {
      updatedAgent.area = null;
    }
    setEditingAgent(JSON.parse(JSON.stringify(updatedAgent)));
  };

  const handleDate = (date, e, name) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    setEditingAgent(JSON.parse(JSON.stringify({ ...editingAgent, [name]: (date ? new Date(date).getTime() : null) })));
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        editingAgent[name] = '';
      } else {
        editingAgent[name] = value;
      }
      setEditingAgent({ ...editingAgent });
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={customTheme}>
        <div className="align">
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={() => redirectPageDetailAgent(agent.id)}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmit}
          >
            登録
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <ImageList
                agent={agent}
                loading={loading}
                onUpdateData={refreshAgent}
              />
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label xs={12}>
                  <FormLabel style={{ color: colorLabel }} component="legend">
                    <span className="text-red">*</span>メールアドレス（重複NG）
                  </FormLabel>
                  <input
                    value={editingAgent?.emailAddressMain ?? ''}
                    name="emailAddressMain"
                    type="text"
                    onChange={onChange}
                  />
                </Label>
              </Row>
              <Col xs={12} style={{ marginBottom: 10 }}></Col>
            </Card>
            <Card body className="mb-4">
              {resource && editingAgent && agent && showResource()}
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend"><span
                        className="text-red">*</span>会社名</FormLabel>
                      <input
                        value={editingAgent?.nickName}
                        name="nickName"
                        type="text"
                        onChange={onChange}
                      />
                    </Label>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        電話番号
                      </FormLabel>
                      <input
                        name="phoneNumber"
                        type="text"
                        value={editingAgent?.phoneNumber}
                        onChange={onChange}
                      />
                    </Label>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">パスワード（英数字8～10文字）</FormLabel>
                      <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <input
                          value={editingAgent?.password}
                          name="password"
                          type="text"
                          onChange={(e) => validatePassword(e.target.value, e.target.name)}
                        />
                      </div>
                    </Label>
                  </Row>
                </Col>
                <Col xs={6}>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend"><span className="text-red">*</span>都道府県</FormLabel>
                    <div className="d-line-select-border">
                      {nameStateAgent && nameStateAgent.items ? (
                        <Select name="state"
                                onChange={handleChangeState}
                                className={'w-100 d-select-multiple-cus'}
                                defaultValue={agent?.state}
                                value={editingAgent?.state}>
                          <MenuItem value="">---</MenuItem>
                          {nameStateAgent.items?.filter((state) => !!state?.label)?.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : <div></div>}
                    </div>
                  </Label>
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">エリア</FormLabel>
                    <div className="d-line-select-border">
                      {areas ? (
                        <Select name="area"
                                onChange={onChange}
                                className={'w-100 d-select-multiple-cus'}
                                defaultValue={agent?.area}
                                value={editingAgent?.area}>
                          <MenuItem value="">---</MenuItem>
                          {areas.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              selected={option.id === agent?.area}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : <div></div>}
                    </div>
                  </Label>
                </Card>

              </Col>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Label style={{ marginTop: 28 }} xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      担当者名
                    </FormLabel>
                    <input
                      value={editingAgent?.nameContact}
                      name="nameContact"
                      type="text"
                      onChange={onChange}
                    />
                  </Label>
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      郵便番号
                    </FormLabel>
                    <input
                      value={editingAgent?.postCode}
                      name="postCode"
                      type="text"
                      onChange={onChange}
                    />
                  </Label>
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      住所
                    </FormLabel>
                    <input
                      value={editingAgent?.address}
                      name="address"
                      type="text"
                      onChange={onChange}
                    />
                  </Label>
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      ホームページURL
                    </FormLabel>
                    <input
                      value={editingAgent?.urlCompany}
                      name="urlCompany"
                      type="text"
                      onChange={onChange}
                    />
                  </Label>
                  <Label xs={12}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      Google Map URL
                    </FormLabel>
                    <input
                      value={editingAgent?.googleMap}
                      name="googleMap"
                      type="text"
                      onChange={onChange}
                    />
                  </Label>
                </Card>
              </Col>
              <Col xs={12}>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">会社/店舗について</FormLabel>
                      <textarea
                        value={editingAgent?.aboutMe}
                        name="aboutMe"
                        rows="3"
                        onChange={onChange}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Label>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MuiThemeProvider>
    </div>
  );
}

export default EditAgent;
