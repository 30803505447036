import { handleActions } from 'redux-actions';
import JobMember from '../Types/jobMember';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
};

const actions = {
  [JobMember.LOAD_JOB_MEMBER]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [JobMember.LOAD_JOB_MEMBER_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      loading: false,
      success: false,
    };
  },
  [JobMember.LOAD_JOB_MEMBER_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [JobMember.UPDATE_JOB_MEMBER]: (state) => ({
    ...state,
    updating: true,
  }),
  [JobMember.UPDATE_JOB_MEMBER_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [JobMember.UPDATE_JOB_MEMBER_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};

export default handleActions(actions, initialState);
