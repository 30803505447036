import React, { useState, useEffect } from 'react';
import { Typography, Select, Button, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { loadSearchPresets } from '../../../Redux/Actions/searchPresets';

export default function SearchPresetSelect({
  selectedPresets,
  isShow,
  isHideLoadBtn,
  isRequired,
  isLoadBtnDisabled,
  handleChange,
  handleLoad,
  titleStyle,
}) {
  const { loading: isLoadingPresets, data: fetchedPresets } = useSelector(
    (state) => state.searchPresets,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSearchPresets());
  }, [dispatch, isShow]);

  const handleChangeSelectPresets = (e) => {
    handleChange(e);
  };
  const handleLoadPreset = (event) => {
    const { content: filter } = fetchedPresets.find(
      (e) => e.id === selectedPresets,
    );
    handleLoad(event, filter);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
      }}
    >
      <Typography style={{ color: '#4F4F4F', fontWeight: 500, ...titleStyle }}>
        {isRequired && <span style={{ color: 'red' }}>*</span>}
        メモリーの適用
      </Typography>
      <Select
        value={selectedPresets}
        disabled={isLoadingPresets}
        onChange={handleChangeSelectPresets}
        fullWidth
        MenuProps={MenuProps}
      >
        {fetchedPresets.map((e) => (
          <MenuItem key={e.id} value={e.id}>
            {e.label}
          </MenuItem>
        ))}
      </Select>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '5px',
        }}
      >
        {!isHideLoadBtn && (
          <Button
            disabled={isLoadBtnDisabled}
            onClick={handleLoadPreset}
            color="primary"
            className="btn-custom btn-blue"
          >
            メモリーを適用する
          </Button>
        )}
      </div>
    </div>
  );
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
