import { sendDelete, sendGet } from './axios';

export const getFavorites = (filter) => sendGet('/cms/favorite/list', {
  ...filter,
});

export const getFavoritesByMe = (filter) => sendGet('/cms/favorites', {
  ...filter,
});

export const getFavoritesToMe = (filter) => sendGet('/cms/users/favorites', {
  ...filter,
});

export const deleteFavorite = (params) => sendDelete(`/cms/favorite/delete`, params);
