import React, { useEffect, useState } from 'react';
import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { Link } from 'react-router-dom';
import NoPhotoJob from '../../assets/image/no_job_photo.png';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import Pagination from '../../Components/Pagination';
import FavoriteJobSearchBox from './FavoriteJobSearchBox';
import { loadFavoriteJobLog } from '../../Redux/Actions/favoriteJobLog';
import FavoriteJobLogDetailModal from './FavoriteJobLogDetailModal';
import { IMAGE_TYPE } from '../../Config/listConstants';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const pageSize = 50;

function Index() {
  const favoriteJobLog = useSelector((state) => state.favoriteJob.items);
  const totalRow = useSelector((state) => state.favoriteJob.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'createdDate': 'DESC' },
  });

  const [favoriteJobDetail, setFavoriteJobDetail] = useState(null);

  const handleClickDetail = (favorite) => {
    setFavoriteJobDetail(favorite);
  };

  const handleClose = () => {
    setFavoriteJobDetail(null);
  };

  const reload = () => {
    dispatch(loadFavoriteJobLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadFavoriteJobLog({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);

    dispatch(loadFavoriteJobLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter && (filter?.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC')) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter && filter?.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }

    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });

    setPageIndex(1);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'createdDate': 'DESC' } });

  const renderUrlJobMain = (images = []) => {
    if (Array.isArray(images) && images.length) {
      const imgJobMain = images.filter(ele => ele?.type && +ele?.type === IMAGE_TYPE.AVATAR);

      if (imgJobMain && imgJobMain.length) {
        return imgJobMain[0]?.url;
      }
    }

    return NoPhotoJob;
  };

  const renderImageSource = (favorite = {}) => {
    return favorite && favorite?.favorite?.avatar?.url ? favorite?.favorite?.avatar?.url : NoPhoto;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <FavoriteJobSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />

      {favoriteJobDetail && (
        <FavoriteJobLogDetailModal
          onClose={handleClose}
          item={favoriteJobDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* favorite */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* target */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="タイトル（仕事）"
                    name="title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>画像（仕事）</TableCell>
                <TableCell>会社名</TableCell>
                <TableCell>登録日</TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {favoriteJobLog.map((favorite, index) => (
                <TableRow key={favorite.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{favorite?.favorite?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${favorite?.favorite?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="imgRound"
                           src={
                            favorite?.favorite?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                            renderImageSource(favorite)}
                           alt="image-source"
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{favorite?.favorite?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(favorite?.favorite?.gender)}
                  </TableCell>

                  {/* target */}
                  <TableCell>{favorite?.target?.title}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/job/${favorite?.target?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img
                        className="imgRound"
                        src={renderUrlJobMain(favorite?.target?.imageJobs)}
                        alt="url-main-job"
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{favorite?.target?.agent?.nickName}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(favorite?.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(favorite)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
