import { call, put, takeLatest } from 'redux-saga/effects';
import Popups from '../Types/popups';
import { getPopups } from '../../Api/popupApi';
import { loadPopupsFail, loadPopupsSuccess } from '../Actions/popups';

function* handleLoadPopups(action) {
  try {
    const response = yield call(getPopups, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadPopupsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadPopupsFail(error));
  }
}

export default function* popupsSaga() {
  yield takeLatest(Popups.LOAD_POPUPS, handleLoadPopups);
}
