import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card, MuiThemeProvider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { updateNameState } from '../../Redux/Actions/nameState';

function ChildStateList({
  dataChildState,
  handleEdit,
  onClose,
  isEditing,
  changeDataChildState,
}) {
  const [dataProfile, setDataProfile] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataProfile(dataChildState);
  }, [dataChildState]);

  const handleUpdate = () => {
    dispatch(updateNameState(inputUpdate));
    setDataProfile(inputUpdate); // Set update when change data
    changeDataChildState(inputUpdate); // Set update when change data
    onClose();
  };

  const handleChangeLabel = (item, e) => {
    const newArray = [...inputUpdate];

    const changeItem = newArray.find((t) => t.id === item.id);
    if (changeItem) {
      changeItem.label = e.target.value;
      // changeDataChildState({ ...changeItem });
    }

    setInputUpdate([...newArray]);
  };

  const handleClose = () => {
    changeDataChildState([ ...dataProfile ]); // Revert data old
    setInputUpdate([]) // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); // Clone deep data when use array
    handleEdit()
  };

  const renderTitle = () => {
    if (dataChildState && dataChildState.length > 2)
      return `${dataChildState[0].id}　～　${
        dataChildState[dataChildState.length - 1].id
      }`;

    return '';
  };

  const renderRowData = () => {
    return (dataProfile || []).map((item) => (
      <TableRow>
        <TableCell></TableCell>
        <TableCell>{item.id}</TableCell>
        <TableCell>
          {isEditing ? (
            <input
              defaultValue={item.label}
              name="label"
              onChange={(e) => {
                handleChangeLabel(item, e);
              }}
            />
          ) : (
            item.label
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div style={{ textAlign: 'right', margin: '10px 0' }}>
        {isEditing ? (
          <div className="my-3">
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={handleClose}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue"
              variant="contained"
              onClick={handleUpdate}
            >
              更新する
            </Button>
          </div>
        ) : (
          <Button
            className="btn-blue"
            style={{
              padding: '6px 14px',
              margin: 'auto',
              color: 'white',
              marginRight: 10,
            }}
            variant="contained"
            onClick={handleBtnEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table">
          <TableBody>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell>{renderTitle()}</TableCell>
              <TableCell>エリア名　～　エリア名</TableCell>
            </TableRow>
            {renderRowData()}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ChildStateList;
