import { createMuiTheme, Dialog, DialogContent, DialogTitle, FormLabel, MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import { removeAgent, updateAgentLp } from '../../../Api/agentAPI';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../../Common/constant';
import { LINK_MAP_DEFINED_API, PROFILE_TYPE } from '../../../Config/listConstants';
import { loadAgent, loadAgentUpdateLocal } from '../../../Redux/Actions/agent';
import { loadCharacter } from '../../../Redux/Actions/character';
import { loadResourceAgent } from '../../../Redux/Actions/resourceAgent';
import Util from '../../../Util/Util';
import ImageList from './ImageList';
import CloseIcon from '@material-ui/icons/Close';
import { getUsers } from '../../../Api/userAPI';
import configs from '../../../Config/config';
import {
  accessAndPassDataIframe,
  convertEmailAddressMainStatusText,
  convertEnvInfo,
  showToastErrorByCodeApi,
} from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const btnDangerStyle = {
  marginLeft: 25,
  fontSize: 14,
  fontWeight: 300,
  color: 'white',
  position: 'absolute',
  bottom: 10,
  right: 16,
};

function Index() {
  const params = useParams();
  const agentId = params.agentId;
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const listChara = useSelector((state) => state.chara.data);
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [isUserRemoved, setIsUserRemoved] = useState(false);
  const [showDialogLpAgent, setShowDialogLpAgent] = useState(false);
  const [labelDb, setLabelDb] = useState({});
  const [urlPathLpAgent, setUrlPathLpAgent] = useState('');
  const [pathLpAgent, setPathLpAgent] = useState('');
  const [infoPathLpAgentId, setInfoPathLpAgentId] = useState(null);
  const [colorLabel, setColorLabel] = useState('')
  const [fakeLoginDetail, setFakeLoginDetail] = useState();

  const deleteButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.AGENT_DETAIL_DELETE);

  const agent = useSelector((state) => state.agent.data);
  const resource = useSelector((state) => state.resourceAgent.data);
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1?.content)
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadAgent(agentId));
    dispatch(loadResourceAgent({}));
    dispatch(loadCharacter({ agentId, pageIndex: 1, pageSize: 1 }));
    getUsers().then(res => {
      setFakeLoginDetail(res.data?.data[0]);
    });
  }, [dispatch, agentId]);


  const history = useHistory();
  const redirectPageEditAgent = (id) => {
    history.push(`/agentope/${id}/editagent`);
  };
  
  const urlPathLpAgentDefault = (path = '') => {
    if (path) {
      path = path.replace(new RegExp(urlPathLpAgent), '');
      path = path.replace(/[^a-z0-9-._]/gi, '');
    }

    setPathLpAgent(path);
  };

  useEffect(() => {
    setUrlPathLpAgent(`${window.origin.replace('admin.', '')}/agent_lp/`);
    urlPathLpAgentDefault(agent?.urlLp);
  }, [agent]);


  const onCloseDialogLpAgent = () => {
    setInfoPathLpAgentId(null);
    setShowDialogLpAgent(false);
    setPathLpAgent('');
  };

  const handleSubmitLpAgent = async () => {
    const updateData = {
      id: infoPathLpAgentId,
      urlLp: urlPathLpAgent + pathLpAgent,
      pathLp: pathLpAgent,
    };

    updateAgentLp(updateData).then(({ data }) => {
      if (data.success) {
        setShowDialogLpAgent(false);
        setInfoPathLpAgentId(null);
        dispatch(loadAgentUpdateLocal({ ...agent, urlLp: (urlPathLpAgent + pathLpAgent) }));
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
      }
    }).catch(({ response }) => {
      if (response.data && response.data.hashCode) {
        showToastErrorByCodeApi(response?.data?.hashCode);
        return;
      }
      toast.error('URLの制作に失敗しました。');
    });
  };

  const onInputChangeLp = e => {
    const { value } = e.target;
    let valueRep = value.replace(/[^a-z0-9-._]/gi, '');
    e.target.value = valueRep;

    setPathLpAgent(valueRep);
  };

  // Profile
  const showResource = () => {
    return resource?.items
      .filter((item) => item.value === PROFILE_TYPE.ROOT)
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        return {
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        };
      })
      .map((item, key) => (
        <>
          <Label style={style} xs={5}>
            <p style={{ paddingLeft: 3, color: colorLabel }}>{item.label}</p>
            <hr style={stylehrleft} />
          </Label>
          <Label style={style} xs={7}>
            <p style={{ paddingLeft: 3 }}>{item?.value}</p>
            <hr style={stylehrright} />
          </Label>
        </>
      ));
  };

  const findAttributes = (resourceName) => {
    const parentName = resource?.items.find((x) => x.label === resourceName);

    if (agent.attributes) {
      let responseResources = resource.items.find(x => {
        return agent?.attributes
          && agent?.attributes[parentName.profile]
          && x.profile === parentName?.profile
          && +x.value === +agent?.attributes[parentName.profile];
      });

      return responseResources?.label;
    }

    return null;
  };

  const handleMyProfile = () => {
    setMyProfVisible(true);
  };

  const accessAndPassData = () => {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  };

  return (
    <div>
      {/*会社プレビュー*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={myProfVisible}
        onClose={() => setMyProfVisible(false)}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          会社プレビュー
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => setMyProfVisible(false)}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ height: '80vh', overflow: 'hidden' }}>
          {
            fakeLoginDetail && <iframe title="s"
                                       id="iframeId"
                                       onLoad={accessAndPassData}
                                       style={{ border: 'none', width: '100%', height: '100%' }}
                                       src={Util.myProfSrc(fakeLoginDetail, `agent*profile/${agentId.replace(/-/g, '*')}`)} />
          }
        </DialogContent>
      </Dialog>

      {/*ユーザー詳細-個別SMS送信*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showDialogLpAgent}
        onClose={onCloseDialogLpAgent}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 800,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          ユーザー詳細-個別SMS送信
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseDialogLpAgent}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Col style={{ background: 'white', borderRadius: 5, padding: 15 }}>
            <FormLabel component="legend">
              <span className="text-red">*</span>日時
            </FormLabel>
            <Row className='mt-3'
                 style={{
                   margin: 10,
                   display: 'flex',
                   justifyContent: 'flex-start',
                   alignItems: 'baseline',
                   width: '100%',
                 }}>
              <p>{urlPathLpAgent}</p>
              <div style={{ width: '30%' }}>
                <input
                  defaultValue={pathLpAgent}
                  style={{ marginLeft: 10, minWidth: 50 }}
                  onChange={onInputChangeLp}
                  maxLength={50}
                  name="pathLpAgent"
                  type="text"
                />
              </div>
            </Row>
          </Col>
          <div style={{ display: 'flex', justifyContent: 'right' }} className='mt-3'>
            <Button
              disabled={!pathLpAgent}
              className={`btn-blue mr-2 ${(pathLpAgent ? '' : 'btn-disabled')}`}
              onClick={handleSubmitLpAgent}
            >
              送信
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {agent && (
        <MuiThemeProvider theme={customTheme}>
          <div
            id="max-width-dialog-title"
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/*float left*/}
            <div>
            </div>

            <div className='d-flex justify-content-start align-items-start flex-wrap pl-3'>
              <Button
                variant="contained"
                className="btn-custom btn-blue mr-3 mt-3 btn-act-edit-agent text-capitalize"
                onClick={() => redirectPageEditAgent(agent.id)}
              >
                編集
              </Button>
              <Button onClick={handleMyProfile} variant="contained"
                      className="btn-custom btn-blue mt-3 mr-3 btn-act-preview-agent text-capitalize"
                      style={{ float: 'right' }}>
                会社プレビュー
              </Button>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <Card body className="mb-4">
                <span>会社/店舗情報の詳細です。</span>
                <span className="mt-2">会社/店舗画像は編集画面からアップして下さい。</span>
                <span className="mt-2">会社/店舗プレビューで内容をご確認頂けます。</span>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Card body className="mb-4">
                <ImageList agent={agent} />
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={5}>
                    <p style={{ paddingLeft: 3, color: colorLabel }}>メールアドレス</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label style={style} xs={7}>
                    {agent.emailAddressMain}
                    <hr style={stylehrright} />
                  </Label>
                  <Label style={style} xs={5}>
                    <p style={{ paddingLeft: 3, color: colorLabel }}>
                      メールアドレス状態
                    </p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label style={style} xs={7}>
                    {convertEmailAddressMainStatusText(+agent.sendgridStatus)}
                    <hr style={stylehrright} />
                  </Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  {resource && showResource()}
                </Row>
              </Card>
            </Col>
            <Col xs={8}>
              <Row>
                <Col xs={6}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>会社名</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.nickName}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>電話番号</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.phoneNumber}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>パスワード</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.password}&nbsp;&nbsp;&nbsp;{' '}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>
                </Col>
                <Col xs={6}></Col>
                <Col xs={5}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>都道府県</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.states?.label}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>エリア</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent?.area_label}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>登録日</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent.registerDate, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>最終ログイン</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent.lastlogin, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>

                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>掲載有効期限</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent?.planExpireDate, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払予定日</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent?.datePayment, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払残高</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.separator(+agent?.balancePayment)}円
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>
                </Col>

                <Col xs={7}>
                  <Card body className="mb-4">
                    <Row style={{ marginBottom: 5 }}>
                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>担当者名</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.nameContact}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>郵便番号</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.postCode}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>住所</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.address}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={12}>
                        <label xs={4} className="col-4" style={{ paddingLeft: 0 }}>
                          <p style={{ paddingLeft: 3, marginBottom: 0, color: colorLabel }}>ホームページURL</p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '100%' }} />
                        </label>
                        <label xs={8} className="col-8" style={{ paddingLeft: 0 }}>
                          <p style={{ marginBottom: 0, paddingLeft: 10 }}>
                            <a target="_blank" href={agent?.urlCompany}>{agent?.urlCompany}&nbsp;</a>
                          </p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '97%' }} />
                        </label>
                      </Label>

                      <Label style={style} xs={12}>
                        <label xs={4} className="col-4" style={{ paddingLeft: 0 }}>
                          <p style={{ paddingLeft: 3, marginBottom: 0, color: colorLabel  }}>Google Map URL</p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '100%' }} />
                        </label>
                        <label xs={8} className="col-8" style={{ paddingLeft: 0 }}>
                          <p style={{ marginBottom: 0, paddingLeft: 10 }}>
                            <a target="_blank" href={agent?.googleMap}>{agent?.googleMap}&nbsp;</a>
                          </p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '97%' }} />
                        </label>
                      </Label>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={2}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>会社/店舗について</p>
                      </Label>
                      <Label
                        style={{
                          ...style,
                          height: 'auto',
                          whiteSpace: 'pre-line',
                        }}
                        xs={10}
                      >
                        {agent.aboutMe}
                      </Label>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </MuiThemeProvider>
      )}

    </div>
  );
}

export default Index;
