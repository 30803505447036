import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { getListChangePhoneHistory } from '../../Api/changePhoneHistory';
import Util from '../../Util/Util';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'reactstrap';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { Link } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import ChangePhoneDetailModal from './ChangePhoneDetailModal';
import { COLUMN_DEFINED_SEARCH_BOX, MEMBER_USER_STATUS } from '../../Common/constant';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';

const ChangePhoneHistory = () => {
  const [histories, setHistories] = useState();
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const [filter, setFilter] = useState({
    sort: { 'changePhoneHistory.createdDate': 'DESC' },
    pageIndex: 1,
    pageSize: 50,
    sortBy: 'changePhoneHistory.createdDate',
    orderBy: 'DESC',
    nickname: undefined,
  });
  const [isSearch, setIsSearch] = useState();
  const [changePhoneDetail, setChangePhoneDetail] = useState(false);

  const handleClickDetail = (block) => {
    setChangePhoneDetail(block);
  };

  const handleClose = () => {
    setChangePhoneDetail(null);
  };

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    setIsSearch(new Date());
    setFilter({ ...filter, pageIndex: 1 });
  };

  const onReload = () => setIsSearch(new Date());

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };
    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
      pageIndex: 1,
    });
    setIsSearch(new Date());
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleClear = () => {
    setFilter({});
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    setFilter({
      sort: { 'changePhoneHistory.createdDate': 'DESC' },
      pageIndex: 1,
      pageSize: 50,
      sortBy: 'changePhoneHistory.createdDate',
      orderBy: 'DESC',
      nickname: undefined,
    });
    // setIsSearch(new Date());
  };

  React.useEffect(() => {
    getListChangePhoneHistory(filter)
      .then(res => {
        setHistories(res.data);
      })
      .catch(err => {
        toast.error(err.message || 'Error');
      });
  }, [isSearch]);

  return (
    <div>
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1 }>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label="電話番号（新）"
                  type="text"
                  name="newNumber"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label="電話番号（旧）"
                  type="text"
                  name="oldNumber"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required1"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickName"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>

          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2 }>
            <FormGroup row>
              <Col xs={5}>
                <TextField
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="fromDate"
                  onChange={onChange}
                />
              </Col>
              <Col xs={2} className="flex-end d-col-2-symbol">
                〜
              </Col>
              <Col xs={5}>
                <TextField
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="toDate"
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ margin: '10px 0 20px 0' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell> No </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（新）"
                    name="changePhoneHistory.phoneNumberOld"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="member.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（旧）"
                    name="changePhoneHistory.phoneNumberNew"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>端末</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="changePhoneHistory.createdDate"
                    onClick={onSort}
                    defaultName="blocks.createdDate"
                  />
                </TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>
              {histories && histories.data && histories.data.map((v, i) => (
                <TableRow key={i}>
                  <TableCell> {i + 1} </TableCell>
                  <TableCell> {v.phoneNumberNew} </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${v?.member?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(v?.member?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          v.member?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          v.member?.avatar ? v.member?.avatar?.url : NoPhoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {v.member?.nickName}
                  </TableCell>
                  <TableCell>
                    {Util.genderIdToText(v?.member?.gender)}
                  </TableCell>
                  <TableCell>
                    {v.phoneNumberOld}
                  </TableCell>
                  <TableCell>
                    {Util.typeConnectionToText(v?.device)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(v.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>

                  <TableCell>
                    <Link onClick={() => handleClickDetail(v)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>

      {changePhoneDetail && (
        <ChangePhoneDetailModal
          onClose={handleClose}
          item={changePhoneDetail}
          onReload={onReload}
        />
      )}
    </div>
  );
};

export default ChangePhoneHistory;
