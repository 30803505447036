import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { MuiThemeProvider } from '@material-ui/core';
import { themeNormalModal } from '../theme_modal';
import AppChat from './AppChat'
import socket from '../../../../socket';
import { sendMessage } from '../../../../Api/chatAdminApi'
import ChatBox from './AppChat/ChatBox'

function ChatRoomAdminModal({ visible, onClose, agentId, agent, valueRoomChat }) {
  const [text, setText] = useState('');
  const [sender, setSender] = useState(valueRoomChat.sender);
  const [disabled, setDisabled] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const onButtonClick = async () => {
    if (text === '') {
      return false;
    }
    setDisabled(true);

    let data = {
      conversationId: valueRoomChat.id,
      content: text,
      type: 1,
      status: 1,
      receiverId: valueRoomChat.receiver?.id,
      senderId: agentId
    };

    try {
      await sendMessage(data).then(() => {
        socket.emit('sendMessageAdmin', data);
      }).catch(e => {
        setDisabled(true);
        setOpenMessage(true);
        setAlertMessage(e.response?.data?.message);
      });

      setText('');
      setDisabled(false);
    } catch (e) {
      setDisabled(true);
      setOpenMessage(true);
      setAlertMessage(e.response?.data?.message);
    }
  };

  const onButtonClickForSendImage = async (url) => {
    let data = {
      conversationId: valueRoomChat.id,
      content: url,
      type: 2,
      status: 1,
      receiverId: valueRoomChat.receiver?.id,
      senderId: agentId
    };

    try {
        await sendMessage(data);
        socket.emit('sendMessageAdmin', data);
      setText('');
      setDisabled(false);
      setSender({ ...sender});
    } catch (e) {
      setOpenMessage(true);
      setAlertMessage(e.response?.data?.message);
    }
  };

  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <>
          <Dialog
            fullWidth={true}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginRight: 50,
                }}
              >
                <p className={'d-title mb-0'}>サポートチャット</p>
              </div>
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <DialogContent className={'wrapper-content-cropper-modal'}>
            <AppChat
              data={valueRoomChat}
              agentId={agentId}
            />
            </DialogContent>
            <ChatBox
              text={text}
              disabled={disabled}
              onTextChange={(e) => onTextChange(e)}
              onButtonClick={onButtonClick}
              onButtonClickForSendImageFunc={(url, filename) => onButtonClickForSendImage(url, filename)}
              conversationId={valueRoomChat.id}
              sender={sender}
            />
          </Dialog>
        </>
      </div>
    </MuiThemeProvider>
  );
}

export default ChatRoomAdminModal;
