import React from 'react';
import { MuiThemeProvider, TableSortLabel } from '@material-ui/core';
import './SortArrows.css';
import { theme } from '../ConfigUI/tableTheme';
/**
 * @isAscending = true => will show arrow-up
 * @isAscending = false => will show arrow-down
 * @isAscending = null => will disable arrow active
 * UI:
 * arrow-up active: is ascending
 * arrow-down active: is descending
 * no arrows active: normal
 */
const SortArrows = ({
  text = '',
  sortObject = {},
  name = '',
  onClick = () => {},
  dangerouslySetInnerHTML,
}) => {
  const isAscending = sortObject[name] === 'DESC';
  return (
    <MuiThemeProvider theme={theme}>
      <TableSortLabel
        active={true}
        className={
          sortObject[name] === undefined
            ? 'custom-sort-arrow-normal'
            : 'custom-sort-arrow-active'
        }
        direction={isAscending ? 'desc' : 'asc'}
        onClick={() => onClick(name)}
      >
        {dangerouslySetInnerHTML ? (
          <span
            style={{ textAlign: 'center' }}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          ></span>
        ) : (
          <span>{text}</span>
        )}
      </TableSortLabel>
    </MuiThemeProvider>
  );
};

export default SortArrows;
