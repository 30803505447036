import { createMuiTheme } from '@material-ui/core';

const tableTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px !important',
        width: 0,
      },
    },
  },
});

export const tableNewTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '10px !important',
        WebkitBorderRadius: '10px !important',
        MozBorderRadius: '10px !important',
      },
      elevation1: {
        boxShadow:
          'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      },
    },
    MuiCard: {
      root: {
        border: '1px solid #dee2e6',
        borderBottom: 'none',
        marginBottom: '40px!important',
        overflow: 'auto',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'unset',
        boxShadow: 'none',
      },
    },
    MuiTableCell: {
      head: {
        height: '50px!important',
      },
      root: {
        borderBottom: 'unset!important',
      },
      paddingCheckbox: {
        padding: '0.75rem',
        width: 0,
        '&:last-child': {
          paddingLeft: '0.75rem!important',
        },
      },
      body: {
        height: '45px',
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 0.75rem !important',
      },
    },
  },
});

export const theme = createMuiTheme({
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: '$gray-900',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '2000 !important',
      },
    },
  },
});

export const configTheme = createMuiTheme({
  overrides: {
    MuiCard: {
      root: {
        marginBottom: '40px!important',
      },
    },
    MuiTableRow: {
      root: {
        background: '#fff',
        '&:hover': {
          backgroundColor: '#fffbf2',
        },
      },
    },
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '0.75rem',
        width: 0,
      },
    },
  },
});

export default tableTheme;
