import { call, put, takeLatest } from 'redux-saga/effects';
import EnvTopNavWeb from '../Types/envTopNavWeb';
import { getEnvTopNavWeb, updateEnvTopNavWeb } from '../../Api/EnvTopNavWebApi';
import {
  loadEnvTopNavWebFail,
  loadEnvTopNavWebSuccess,
  updateEnvTopNavWebFail,
} from '../Actions/envTopNavWeb';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvTopNavWeb(action) {
  try {
    const response = yield call(getEnvTopNavWeb, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadEnvTopNavWebSuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvTopNavWebFail(error));
  }
}

function* handleUpdateEnvTopNavWeb(action) {
  try {
    const response = yield call(updateEnvTopNavWeb, action.payload);
    const { data } = response;
    if (data.success) {
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvTopNavWebFail(error));
  }
}

export default function* envUISaga() {
  yield takeLatest(EnvTopNavWeb.LOAD_ENV_TOP_NAV_WEB, handleLoadEnvTopNavWeb);
  yield takeLatest(EnvTopNavWeb.UPDATE_ENV_TOP_NAV_WEB, handleUpdateEnvTopNavWeb);
}
