import { sendDelete, sendGet } from './axios';

export const getSalaries = (filter) => {
  return sendGet('/cms/salaries', {
    ...filter,
    sortBy: filter.sortBy || 'salaryLog.createdDate',
    orderBy: filter.orderBy || 'DESC',
  });
};

export const getSalaryByCharacter = (filter) => {
  return sendGet('/cms/salary/character', {
    ...filter,
    sortBy: filter.sortBy || 'salaryLog.createdDate',
    orderBy: filter.orderBy || 'DESC',
  });
};

export const deleteSalaryByCharacter = (salaryLogId) => {
  return sendDelete(`/cms/salary/character/${salaryLogId}`);
};
