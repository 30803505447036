import React from 'react';
import { toast } from 'react-toastify';
import { removeUser } from '../../../Api/userAPI';
import { useHistory } from 'react-router-dom';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';

function RemoveUserModal({ visible, onClose, userId, labelDb }) {
  const history = useHistory();

  const handleSubmit = async () => {
    await removeUser(userId).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        history.push('/user/search');
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={'<span>このユーザーを物理削除します、元には戻せません。<br />この処理はシステム管理者のみ実行して下さい</span>'}
      />
    </div>
  );
}

export default RemoveUserModal;
