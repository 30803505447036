import { call, put, takeLatest } from 'redux-saga/effects';
import Contacts from '../Types/contacts';
import { getContacts } from '../../Api/contactApi';
import { loadContactsFail, loadContactsSuccess } from '../Actions/contacts';


function* handleLoadContacts(action) {
  try {
    const response = yield call(getContacts, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadContactsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadContactsFail(error));
  }
}

export default function* contactsSaga() {
  yield takeLatest(Contacts.LOAD_CONTACTS, handleLoadContacts);
}
