import { call, put, takeLatest } from 'redux-saga/effects';
import LogSendNoti from '../Types/logSendNoti';
import { getLogSendNoti } from '../../Api/logSendNotiApi';
import { loadLogNotiFail, loadLogNotiSuccess } from '../Actions/logSendNoti';

function* handleLoadLogSendNoti(action) {
  try {
    const response = yield call(getLogSendNoti, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLogNotiSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLogNotiFail(error));
  }
}

export default function* logSendNotiSaga() {
  yield takeLatest(LogSendNoti.LOAD_LOG_SEND_NOTI, handleLoadLogSendNoti);
}
