import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ContactListItem from './ContactListItem';
import SortArrows from '../../Components/SortArrows';
import { Card } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

function ContactList(props) {
  const {
    contacts = [],
    pageIndex,
    pageSize,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    handleClickDetail = (contact) => {
    },
    labelDb
  } = props;

  return (
    <div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card
          className="scroll-x-on-top"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="user.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="メールアドレス"
                    name="contact.email"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="連絡先"
                    name="contact.phone_number"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="種類"
                    name="contact.type"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="本文"
                    name="contact.content"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">添付画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="問合せ内容"
                    name="contact.title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="確認状態"
                    name="contact.confirm"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="contact.createdDate"
                    onClick={onSort}
                    defaultName="contact.createdDate"
                  />
                </TableCell>

                <TableCell align="center" style={{ width: '4%' }}>
                  ボタン
                </TableCell>
              </TableRow>

              {contacts.map((contact, index) => (
                <ContactListItem
                  key={contact.id}
                  handleClickDetail={() => handleClickDetail(contact)}
                  contact={contact}
                  no={(pageIndex - 1) * pageSize + index + 1}
                />
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default ContactList;
