import { handleActions } from 'redux-actions';
import helpCms from '../Types/helpCms';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  creating: false,
  success: false,
  totalRow: 0,
  detail: null,
};

const actions = {
  [helpCms.LOAD_HELP_CMS]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [helpCms.LOAD_HELP_CMS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    totalRow: action.payload.totalRow,
    loading: false,
    success: false,
  }),
  [helpCms.LOAD_HELP_CMS_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),

  [helpCms.GET_BY_ID_HELP_CMS]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [helpCms.GET_BY_ID_HELP_CMS_SUCCESS]: (state, action) => ({
    ...state,
    detail: action.payload,
    loading: false,
    success: false,
  }),
  [helpCms.GET_BY_ID_HELP_CMS_FAIL]: (state) => ({
    ...state,
    detail: null,
    loading: false,
  }),

  [helpCms.CREATE_HELP_CMS]: (state) => ({
    ...state,
    creating: true,
  }),
  [helpCms.CREATE_HELP_CMS_SUCCESS]: (state, action) => ({
    ...state,
    creating: false,
    success: true,
  }),
  [helpCms.CREATE_HELP_CMS_FAIL]: (state) => ({
    ...state,
    creating: false,
  }),

  [helpCms.UPDATE_HELP_CMS]: (state) => ({
    ...state,
    updating: true,
  }),
  [helpCms.UPDATE_HELP_CMS_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [helpCms.UPDATE_HELP_CMS_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
