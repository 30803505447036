import { CKEditor } from 'ckeditor4-react';
import React, { forwardRef } from 'react';

const config = {
  versionCheck: false,
};

const CustomEditor = forwardRef(function CustomEditor(props, ref) {
  return <CKEditor config={config} {...props} ref={ref} />;
});

export default CustomEditor;
