import { sendDelete, sendGet, sendPost, sendPut } from './axios';
import _ from 'lodash';

export const jobMemberSearch = (payload) => sendGet('/cms/job-members', payload);
export const getJobMember = (id) => sendGet(`/cms/job-member/${id}`);
export const deleteJobMember = (id) => sendDelete(`cms/job-member/${id}`);
export const updateJobMember = (job) => sendPut(`cms/job-member/${job.id}`, _.omit(job, ['id']));

// Push email
export const sendEmailJobMember = (id, payload) => sendPost(`/cms/send-email/job-member/${id}`, payload);

// Push sms
export const sendSmsJobMember = (id, payload) => sendPost(`/cms/send-sms-job-member/${id}`, payload);
