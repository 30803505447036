import { sendGet, sendPut } from './axios';

export const getResourceAgent = (params) => sendGet('/all-resource-agent', params);
export const getNameStateAgent = (params) => sendGet('/agent-state', params);

export const getRootResourceAgent = (params) => sendGet('/cms/agent-all-root-resource', params);
export const getDetailResourceAgent = (params) => sendGet(`/cms/agent-child-attribute`, { profile: params });
export const updateRootResourceAgent = (params) => sendPut(`/cms/agent-update-root-profile`, params);
export const updateChildResourceAgent = (params) => sendPut(`/cms/agent-update-child-profile`, params);
export const updateNameStateAgent = (params) => sendPut(`/cms/agent-update`, params);

export const getAllAreaAgent = (params) => sendGet('/agent-area/' + params);
