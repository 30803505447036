import React, { useEffect, useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import { loadJob } from '../../../Redux/Actions/job';
import { useHistory, useParams } from 'react-router-dom';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import {
  Button,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
} from '@material-ui/core';
import * as jobApi from '../../../Api/jobApi';
import { toast } from 'react-toastify';
import set from 'lodash/set';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import ImageJobEdit from './ImageJobEdit';
import { loadAgents } from '../../../Redux/Actions/agents';
import { getAllTagJobs } from '../../../Api/tag';
import { loadAllAreaJob, loadStateJob } from '../../../Redux/Actions/nameStateJob';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { convertEnvInfo, hiddenStatusRequired, jobPublishingTypeRequired } from '../../../Util/utils';
import { IMAGE_TYPE } from '../../../Config/listConstants';
import RadioGroup from '../../../Components/RadioGroup';
import DatePicker from 'react-datepicker';
import orderBy from 'lodash/orderBy';
import CheckBoxItemModal from '../../../Components/CheckBoxItemModal';
import { loadResourceItemJob } from '../../../Redux/Actions/resourceItemJob';
import { showResourceItemJobEdit } from '../resource-item';
import moment from 'moment';
import { loadAgent } from '../../../Redux/Actions/agent';


export default function Index() {
  const params = useParams();
  const jobId = params.jobId;
  const history = useHistory();

  const nameStateJob = useSelector((state) => state.nameStateJob.data);
  const areas = useSelector((state) => state.nameStateJob.dataChild);
  const agents = useSelector((state) => state.agents.items);
  const resource = useSelector((state) => state.resourceItemJob.data);
  const loadingResource = useSelector((state) => state.resourceItemJob.loading);
  // const [editingItemJob, setEditingItemJob] = useState({});

  const loading = useSelector((state) => state.job.loading);
  const job = useSelector((state) => state.job.data);
  const dispatch = useDispatch();
  const [jobSetting, setJobSetting] = useState();

  const [subImages, setSubImages] = useState([]);
  const [main, setMain] = useState({});
  const [selectedTagJobList, setSelectedTagJobList] = useState([]);
  const [tagJobs, setTagJobs] = useState([]);
  const [visitTagShow, setVisitTagShow] = useState(false);
  const [colorLabel, setColorLabel] = useState('')
  const agent = useSelector((state) => state.agent.data);

  const envInfo = useSelector((state) => state.envInfo.data);

  useEffect(() => {
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2?.content)
  }, [envInfo]);

  const getAllTagJobMembers = async () => {
    getAllTagJobs().then(res => {
      if (res.data) {
        setTagJobs((res.data?.data ?? []));
      }
    }).catch(err => console.error('Error!', err));
  };

  useEffect(() => {
    setJobSetting(job);

    // if (job) {
    //   setEditingItemJob(job?.attribute);
    // }

    dispatch(loadStateJob());
    dispatch(loadResourceItemJob({}));
    dispatch(loadAllAreaJob(job?.stateId));
  }, [job]);

  useEffect(() => {
    dispatch(loadJob(jobId));
    dispatch(loadAgent(localStorage.getItem('userLoginId')));
  }, [dispatch]);

  useEffect(() => {
    if (jobSetting?.stateId) {
      dispatch(loadAgents({ pageIndex: 1, pageSize: 1000 }));
      dispatch(loadAllAreaJob(jobSetting?.stateId));
    }
  }, [dispatch, jobSetting?.stateId]);

  useEffect(() => {
    const imageJobs = job?.imageJobs ?? [];
    const mains = imageJobs.filter(ele => ele.type === IMAGE_TYPE.AVATAR);
    setMain(mains.length ? mains[0] : {});

    let subImagesObj = {};
    let subImagesRaw = job?.imageJobs ?? [];
    remove(subImagesRaw, (x) => x.type === IMAGE_TYPE.AVATAR);
    // orderBy Asc
    for (let i = 0; i < subImagesRaw.length; i++) {
      subImagesObj[subImagesRaw[i].subImgNumber] = subImagesRaw[i];
    }

    let tempSubImages = [];
    Array(5).fill(1).forEach((ele, index) => {
      if (subImagesObj[(index + 1)]) {
        tempSubImages.push({ ...subImagesObj[(index + 1)], none: false });
      } else {
        tempSubImages.push({
          none: true,
        });
      }
    });

    setSubImages(tempSubImages);
  }, [job]);

  useEffect(() => {
    getAllTagJobMembers();
  }, []);

  useEffect(() => {
    if (job && job?.tagNameJobs) {
      setSelectedTagJobList(job?.tagNameJobs.map(i => i.id));
    }
  }, [job]);

  if (!job) {
    return null;
  }

  const handleChange = (e) => {
    setJobSetting({ ...jobSetting, [e.target.name]: e.target.value });
  };

  const handleDate = (date, name) => {
    setJobSetting({ ...jobSetting, [name]: (date ? new Date(date).getTime() : null) });
  };

  const onChangeSelect = (e) => {
    const jobSetting = set(job, e.target.name, e.target.value);
    setJobSetting({ ...jobSetting });

    if ('stateId' === e.target.name) {
      dispatch(loadAllAreaJob(jobSetting?.stateId));
      setJobSetting({ ...jobSetting, areaId: null });
    }
  };

  const handleUpdate = () => {
    if (!jobSetting.title) {
      return toast.error('タイトルを入力してください。');
    }
    if (jobSetting?.title?.length > 20) {
      return toast.error('タイトルは20文字以内で登録して下さい');
    }
    if (!jobSetting.stateId) {
      return toast.error('都道府県を選択してください。');
    }
    if (jobSetting.displaySetting === null) {
      return toast.error('表示設定を選択してください。');
    }
    if (jobSetting.draft === null) {
      return toast.error('掲載状況を選択してください。');
    }
    // if (!jobSetting.startTime) {
    //   return toast.error('掲載開始日を選択してください。');
    // }
    // if (!jobSetting.endTime) {
    //   return toast.error('掲載終了日を選択してください。');
    // }
    // if (jobSetting.startTime && jobSetting.endTime && moment(+jobSetting.startTime).format('YYYYMMDD') > moment(+jobSetting.endTime).format('YYYYMMDD')) {
    //   return toast.error('掲載開始日は掲載終了日の前日をご記入ください。');
    // }
    if (!jobSetting.agentId) {
      return toast.error('会社Numberを選択してください。');
    }

    const updateData = pick(jobSetting, [
      'id',
      'title',
      'salaryDetail',
      'workHours',
      'workDetail',
      'location',
      'stateId',
      'areaId',
      'agentId',
      'displaySetting',
      'draft',
      // 'startTime',
      // 'endTime',
      'attribute',
      'minimumSalary',
      'maximumSalary',
      // 'state',
      // 'area',
    ]);

    handleUpdateData({
      ...updateData,
      ...updateData?.draft !== null && { draft: +updateData.draft },
      // ...updateData?.startTime !== null && { startTime: moment(+updateData.startTime).startOf('day').valueOf() },
      // ...updateData?.endTime !== null && { endTime: moment(+updateData.endTime).startOf('day').valueOf() },
      ...updateData?.displaySetting !== null && { displaySetting: +updateData.displaySetting },
      ...selectedTagJobList.length && { tagJobIds: selectedTagJobList },
      ...updateData?.minimumSalary !== null && { minimumSalary:updateData?.minimumSalary === "" ? null : +updateData.minimumSalary },
      ...updateData?.maximumSalary !== null && { maximumSalary:updateData?.maximumSalary === "" ? null : +updateData.maximumSalary },
    });
  };

  const refreshJobDetail = () => {
    dispatch(loadJob(jobId));
  };

  const handleUpdateData = (updateData) => {
    jobApi.updateJob(updateData).then((response) => {
      const { data } = response;
      if (data.success) {
        setJobSetting([]);
        onClose();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    }).catch(e => toast.error(e.response?.data?.message));
  };

  const handleChangeTag = (event) => {
    const tag = event.target.value;
    const indexFind = selectedTagJobList.indexOf(tag);
    if (indexFind !== -1) {
      selectedTagJobList.splice(indexFind, 1);
    } else {
      selectedTagJobList.push(tag);
    }

    setSelectedTagJobList([...selectedTagJobList]);
  };

  const convertChangeTagMemberList = () => {
    return tagJobs.filter(ele => selectedTagJobList.includes(ele.id));
  };

  const onChangeAttribute = (e) => {
    setJobSetting({
      ...jobSetting,
      attribute: {
        ...jobSetting.attribute,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onClose = () => {
    setJobSetting([]);
    history.push(`/job/${jobId}/detail`);
  };

  if (!jobSetting) {
    return null;
  }

  return (
    <div>
      <LoadingBar show={loading} />
      <div className="align" style={{ justifyContent: 'flex-end' }}>
        <div>
          <center>
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={onClose}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue mr-3"
              variant="contained"
              onClick={handleUpdate}
            >
              更新する
            </Button>
          </center>
        </div>
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Row>
          <Col xs={12}>
            <Card body className="mb-4">
              <span>求人の編集画面です。</span>
              <span className="mt-2">求人画像の変更が可能です。</span>
              <span className="mt-2">プレビューは更新後詳細画面よりご確認頂けます。</span>
            </Card>
          </Col>
        </Row>
        <Row>
          {/*left*/}
          <Col xs={4}>
            <Card body className="mb-4">
              <ImageJobEdit subImages={subImages} main={main} job={job} onUpdateData={refreshJobDetail} />
            </Card>
            <Card body className="mb-4">
              <Row>
                {!agent &&
                  <Col xs={12} style={{ marginBottom: 12 }}>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      <span className="text-red">*</span>表示設定
                    </FormLabel>
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={job?.displaySetting}
                      options={hiddenStatusRequired()}
                      onChange={onChangeSelect}
                      classCustom="d-cus-item"
                    />
                  </Col>
                }
                <Col xs={12} style={{ marginBottom: 12 }}>
                  <FormLabel style={{ color: colorLabel }} component="legend">
                    <span className="text-red">*</span>掲載状況
                  </FormLabel>
                  <RadioGroup
                    name="draft"
                    defaultValue={job?.draft}
                    options={jobPublishingTypeRequired()}
                    onChange={onChangeSelect}
                    classCustom="d-cus-item"
                  />
                </Col>
              </Row>
            </Card>
            {/* <Card body className="mb-4">
              <Row> */}
                {/* <Col xs={12} style={{ marginBottom: 12 }}>
                  <FormGroup row>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      <span className="text-red">*</span>掲載開始日
                    </FormLabel>
                    <DatePicker
                      name="startTime"
                      selected={jobSetting?.startTime && !isNaN(jobSetting?.startTime) ? new Date(+jobSetting.startTime) : null}
                      className="date"
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      onChange={(e) => handleDate(e, 'startTime')}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} style={{ marginBottom: 12 }}>
                  <FormGroup row>
                    <FormLabel style={{ color: colorLabel }} component="legend">
                      <span className="text-red">*</span>掲載終了日
                    </FormLabel>
                    <DatePicker
                      selected={jobSetting?.endTime && !isNaN(jobSetting?.endTime) ? new Date(+jobSetting.endTime) : null}
                      className="date"
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      onChange={(e) => handleDate(e, 'endTime')}
                    />
                  </FormGroup>
                </Col> */}
              {/* </Row>
            </Card> */}
            {
              !agent && 
                <Card body className="mb-4">
                  <Row>
                    <Col xs={12} style={{ marginBottom: 12 }}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>会社Number
                      </FormLabel>
                      <div className="d-line-select-border">
                        {agents ? (
                          <Select name="agentId"
                                  onChange={onChangeSelect}
                                  className={'w-100 d-select-multiple-cus'}
                                  defaultValue={job?.agentId}
                                  value={jobSetting?.agentId}>
                            <MenuItem value="">---</MenuItem>
                            {agents.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.id}
                                selected={option.id === jobSetting?.agentId}
                              >
                                {option.agentNumber}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : <div></div>}
                      </div>
                    </Col>
                  </Row>
                </Card>
            }

            <Card body className="mb-4">
              <Row>
                <Col xs={12} style={{ marginBottom: 12 }}>
                  <FormLabel style={{ color: colorLabel }} component="legend"><span className="text-red">*</span>都道府県</FormLabel>
                  <div className="d-line-select-border">
                    {nameStateJob ? (
                      <Select name="stateId"
                              onChange={onChangeSelect}
                              className={'w-100 d-select-multiple-cus'}
                              defaultValue={job?.stateId}
                              value={jobSetting?.stateId}>
                        <MenuItem value="">---</MenuItem>
                        {nameStateJob.items?.filter((state) => !!state?.label)?.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            selected={option.id === jobSetting?.stateId}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : <div></div>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={{ marginBottom: 12 }}>
                  <FormLabel style={{ color: colorLabel }} component="legend">エリア</FormLabel>
                  <div className="d-line-select-border">
                    {areas ? (
                      <Select name="areaId"
                              onChange={onChangeSelect}
                              className={'w-100 d-select-multiple-cus'}
                              defaultValue={job?.areaId}
                              value={jobSetting?.areaId}>
                        <MenuItem value="">---</MenuItem>
                        {areas.map((opt) => (
                          <MenuItem
                            key={opt.id}
                            value={opt.id}
                            selected={opt.id === jobSetting?.areaId}
                          >
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : <div></div>}
                  </div>
                </Col>
                <Col xs={12} style={{ marginBottom: 12 }}>
                  <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:10 }}>
                    <InputLabel id="mutiple-select-label" style={{ margin: 0, fontSize: 21.33, color: colorLabel}} shrink>給与幅</InputLabel>
                    <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                      <input
                        name="minimumSalary"
                        type="number"
                        style={{width:100}}
                        defaultValue={job?.minimumSalary}
                        onChange={handleChange}
                      />円
                      <span style={{ margin:'0 20px' }}>~</span>
                      <input
                        name="maximumSalary"
                        type="number"
                        style={{ width:100 }}
                        defaultValue={job?.maximumSalary}
                        onChange={handleChange}
                      />円
                    </div>
                  </div>
                </Col>
              </Row>
              {resource && showResourceItemJobEdit(resource?.items, loadingResource, onChangeAttribute, job?.attribute, jobSetting?.attribute, colorLabel)}
            </Card>
            {/*Tag list*/}
            <Card body className="mb-4">
              <Row>
                <Label xs={12} className="">
                  <InputLabel style={{ color: colorLabel }} className={'m-0'} id="mutiple-select-label"
                              onClick={() => setVisitTagShow(true)}>タグ設定</InputLabel>
                  <div className="d-wrapper-custom-select d-border-bottom-form d-flex pb-1"
                       onClick={() => setVisitTagShow(true)}>
                    {convertChangeTagMemberList()
                      ? (<div className="wrapper-tag w-100 cursor-pointer">
                        {orderBy((convertChangeTagMemberList() ?? []), ['displayOrder'], ['asc']).map((v, i) => (
                          <div className="tag" key={i}>{v.label}</div>))}
                      </div>)
                      : <div></div>
                    }
                    <svg className="d-svg-icon-select" focusable="false" viewBox="0 0 24 24"
                         aria-hidden="true" role="presentation">
                      <path d="M7 10l5 5 5-5z"></path>
                    </svg>
                  </div>
                </Label>
              </Row>
            </Card>
          </Col>
          {/*right*/}
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <FormLabel style={{ color: colorLabel }} component="legend">
                            <span className="text-red">*</span>タイトル（20文字以内）
                          </FormLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="title"
                            className="w-100"
                            required={true}
                            defaultValue={job?.title}
                            onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">仕事内容</FormLabel>
                        <textarea
                          name="workDetail"
                          rows="3"
                          style={{ width: '100%', padding: 10 }}
                          onChange={handleChange}
                          defaultValue={job?.workDetail}
                        />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">給与/福利厚生</FormLabel>
                        <textarea
                          name="salaryDetail"
                          rows="3"
                          style={{ width: '100%', padding: 10 }}
                          onChange={handleChange}
                          defaultValue={job?.salaryDetail}
                        />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">勤務日/休日/勤務時間</FormLabel>
                        <textarea
                          name="workHours"
                          rows="3"
                          style={{ width: '100%', padding: 10 }}
                          onChange={handleChange}
                          defaultValue={job?.workHours}
                        />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">勤務地</FormLabel>
                        <textarea
                          name="location"
                          rows="3"
                          style={{ width: '100%', padding: 10 }}
                          defaultValue={job?.location}
                          onChange={handleChange}
                        />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </MuiThemeProvider>

      {visitTagShow && <CheckBoxItemModal onClose={() => setVisitTagShow(false)}
                                          visible={visitTagShow}
                                          title="タグ設定"
                                          data={tagJobs}
                                          selected={selectedTagJobList}
                                          handleChange={handleChangeTag}

      />}

    </div>
  );
}
