import React, { useRef, useState } from 'react';
import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import TagSettingList from './TagSettingList';
import TagJobSettingList from './TagJobSettingList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TagAdminSettingList from './TagAdminSettingList';

tableNewTheme.overrides = Object.assign({}, {
  ...tableNewTheme?.overrides, MuiTabs: {
    root: {
      marginTop: '14px',
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: 'unset',
      },
    },
  },
});

const theme = createMuiTheme({
  ...tableNewTheme,
});

function RankSetting() {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('TAG_USER');
  const childCompRef = useRef();

  const handleUpdateAfter = () => {
    if (value === 'TAG_USER') {
      childCompRef?.current?.updateTagUser();
    }
    if (value === 'TAG_JOB') {
      childCompRef?.current?.updateTagJob();
    }
    if (value === 'TAG_ADMIN') {
      childCompRef?.current?.handleUpdateTagAdmin();
    }

    setIsEditing(false);
  };

  const handleChangeTab = (event, newValue) => {
    setIsEditing(false);
    setValue(newValue);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Tabs scrollButtons="auto"
              indicatorColor="unset"
              textColor="white"
              value={value}
              onChange={handleChangeTab}
              className="d-tab-wrapper"
        >
          <Tab disableRipple value={'TAG_USER'}
               className={`${(value === 'TAG_USER' ? 'btn-blue' : '')} btn-custom`}
               label="ユーザーのタグ名"
               title={'ユーザーのタグ名'} />
          <Tab disableRipple value={'TAG_ADMIN'}
               className={`${(value === 'TAG_ADMIN' ? 'btn-blue' : '')} btn-custom`}
               label="管理タグ名"
               title={'管理タグ名'} />
          <Tab disableRipple value={'TAG_JOB'}
               className={`${(value === 'TAG_JOB' ? 'btn-blue' : '')} btn-custom`}
               label="仕事のタグ名" title={'仕事のタグ名'} />
        </Tabs>

        <div style={{ marginRight: 15 }} className="mt-2 d-flex justify-content-between">
          <div></div>
          <div>
            {isEditing ? (
              <div>
                <center>
                  <Button className="btn-custom btn-red"
                          variant="contained"
                          onClick={() => setIsEditing(false)}
                  >
                    閉じる
                  </Button>
                  <Button className="btn-custom btn-blue"
                          variant="contained"
                          onClick={handleUpdateAfter}
                  >
                    更新する
                  </Button>
                </center>
              </div>
            ) : (
              <Button className="btn-custom btn-blue"
                      variant="contained"
                      onClick={() => setIsEditing(true)}
              >
                編集
              </Button>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-2">
          {value === 'TAG_USER' && (
            <TagSettingList isEditing={isEditing}
                            activeTag={value}
                            setIsEditing={setIsEditing}
                            ref={childCompRef}
            />

          )}
          {value === 'TAG_ADMIN' && (
            <TagAdminSettingList isEditing={isEditing}
                            activeTag={value}
                            setIsEditing={setIsEditing}
                            ref={childCompRef}
            />

          )}
          {value === 'TAG_JOB' && (
            <TagJobSettingList isEditing={isEditing}
                               activeTag={value}
                               setIsEditing={setIsEditing}
                               ref={childCompRef}
            />
          )}
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default RankSetting;
