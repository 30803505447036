import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import Util from '../../../Util/Util';
import { convertTimerSendingTypeText } from '../../../Util/utils';

function TimerMailList({
                         logSendMail = [],
                         pageIndex,
                         pageSize,
                         loading,
                         filter = { sort: {} },
                         onSort = (sortByKey) => {
                         },
                         handleClickDetail,
                       }) {

  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell>本文</TableCell>
                <TableCell>送信種別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="送信予定日時"
                    name="sendTime"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="作成日"
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendMail.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>

                  <TableCell>{item.title}</TableCell>
                  <TableCell>
                    <div className="ellipsis-1-line"
                      dangerouslySetInnerHTML={{ __html: (item.content || '').replace(/<p>/ig, '<span>').replace(/<\/p>/ig, '</span>') }}></div>
                  </TableCell>
                  <TableCell>
                    { convertTimerSendingTypeText(item?.type) }
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.sendTime,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell  className="d-color-link" style={{ cursor: 'pointer' }}>
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default TimerMailList;
