import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createMemberAdminFail,
  createMemberAdminSuccess,
  getByIdMemberAdminFail,
  getByIdMemberAdminSuccess,
  loadMemberAdminSuccess,
  loadMMemberAdminFail,
  updateMemberAdminFail,
  updateMemberAdminSuccess,
} from '../Actions/memberAdmin';
import { createMemberAdmin, getMemberAdmin, getMemberAdmins, updateMemberAdmin } from '../../Api/memberAdminAPI';
import memberAdmin from '../Types/memberAdmin';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadMemberAdmin(action) {
  try {
    const response = yield call(getMemberAdmins, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(loadMemberAdminSuccess(admin));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadMMemberAdminFail(error));
  }
}

function* handleCreateMemberAdmin(action) {
  try {
    const response = yield call(createMemberAdmin, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(createMemberAdminSuccess(admin));
      toast.success(TOAST_MESSAGE.SUCCESS_ADD);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_ADD);
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put(createMemberAdminFail(error));
  }
}

function* handleUpdateMemberAdmin(action) {
  try {
    const response = yield call(updateMemberAdmin, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(updateMemberAdminSuccess(admin));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put(updateMemberAdminFail(error));
  }
}

function* handleGetByIdMemberAdmin(action) {
  try {
    const response = yield call(getMemberAdmin, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(getByIdMemberAdminSuccess(admin));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(getByIdMemberAdminFail(error));
  }
}

export default function* memberAdminSaga() {
  yield takeLatest(memberAdmin.LOAD_MEMBER_ADMIN, handleLoadMemberAdmin);
  yield takeLatest(memberAdmin.CREATE_MEMBER_ADMIN, handleCreateMemberAdmin);
  yield takeLatest(memberAdmin.UPDATE_MEMBER_ADMIN, handleUpdateMemberAdmin);
  yield takeLatest(
    memberAdmin.GET_BY_ID_MEMBER_ADMIN,
    handleGetByIdMemberAdmin,
  );
}
