import { createAction } from 'redux-actions';
import envTopNavWeb from '../Types/envTopNavWeb';

export const loadEnvTopNavWeb = createAction(envTopNavWeb.LOAD_ENV_TOP_NAV_WEB);
export const loadEnvTopNavWebSuccess = createAction(envTopNavWeb.LOAD_ENV_TOP_NAV_WEB_SUCCESS);
export const loadEnvTopNavWebFail = createAction(envTopNavWeb.LOAD_ENV_TOP_NAV_WEB_FAIL);

export const updateEnvTopNavWeb = createAction(envTopNavWeb.UPDATE_ENV_TOP_NAV_WEB);
export const updateEnvTopNavWebSuccess = createAction(envTopNavWeb.UPDATE_ENV_TOP_NAV_WEB_SUCCESS);
export const updateEnvTopNavWebFail = createAction(envTopNavWeb.UPDATE_ENV_TOP_NAV_WEB_FAIL);
