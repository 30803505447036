import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { loadPointSetting, updatePointSetting } from '../../Redux/Actions/pointSetting';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

const pageSize = 20;

const TableOfPoinTableRowate = (props) => {
  const { pointRateLeft, pointRateRight, isEditing, setIsEditing } = props;

  const [filter] = useState();
  const [pointSettingsLeft, setPointSettingsLeft] = useState([]);
  const [pointSettingsRight, setPointSettingsRight] = useState([]);
  // let pointSettings = useSelector((state) => state.pointSetting.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadPointSetting({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handleEdit = (record) => {
    setIsEditing(true);
  };

  const onClose = () => {
    pointRateLeft && pointRateLeft.length > 0 && setPointSettingsLeft(reOrderPointSetting(pointRateLeft));
    setPointSettingsRight(pointRateRight);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = pointSettingsLeft.concat(pointSettingsRight);
    dispatch(updatePointSetting(values));
    setIsEditing(false);
  };

  useEffect(() => {
    pointRateLeft && pointRateLeft.length > 0 && setPointSettingsLeft(reOrderPointSetting(pointRateLeft));
    setPointSettingsRight(pointRateRight);
  }, [pointRateLeft, pointRateRight]);

  const handleChangeLeft = (e) => {
    const splitName = e.target.name.split('.');
    let newArray = [...pointSettingsLeft];
    const elementsIndex = pointSettingsLeft.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setPointSettingsLeft(newArray);
  };

  const handleChangeRight = (e) => {
    const splitName = e.target.name.split('.');
    let newArray = [...pointSettingsRight];
    const elementsIndex = pointSettingsRight.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setPointSettingsRight(newArray);
  };

  const reOrderPointSetting = (data) => {
    const orderSettingPoint = ['registration_point', 'profile_view', 'like_send', 'chat_send', 'photo_send', 'chat_receive', 'photo_receive', 'call_talk', 'photo_update', 'call_video'];
    const order = [];

    for (let i = 0; i < orderSettingPoint.length; i++) {
      for (let j = 0; j < data.length; j++) {
        const currentData = data[j];
        if (currentData.action === orderSettingPoint[i]) {
          order[i] = currentData;
          break;
        }
      }
    }

    return order;
  };

  const explain = (data) => {
    switch (data.action) {
      case 'call_video':
        return '×コールビデオ';
      case 'like_send':
        return 'いいね送信（ユーザー）';
      case 'profile_view':
        return 'プロフ閲覧（ユーザー）';
      case 'photo_receive':
        return '写真受信（キャラ）';
      case 'photo_update':
        return 'プロフ写真アップ（ユーザー・キャラ）';
      case 'photo_send':
        return '写真送信（ユーザー・キャラ）';
      case 'chat_send':
        return 'チャット送信（ユーザー・キャラ）';
      case 'registration_point':
        return '新規登録時ポイント';
      case 'chat_receive':
        return 'チャット受信 （キャラ）';
      case 'call_talk':
        return 'コール会話（ユーザー・キャラ）';
      default:
        return null;
    }
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {/* <h4 className="page-title">ランク設定</h4> */}
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Card className="card-box main-content-custom" style={{ width: '100%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明</TableCell>
                <TableCell>DATA名</TableCell>
                <TableCell>男Normal User</TableCell>
                <TableCell>男Point User</TableCell>
                <TableCell>男Plan User</TableCell>
                <TableCell>女Normal User</TableCell>
                <TableCell>女Point User</TableCell>
                <TableCell>女Plan User</TableCell>
                <TableCell>キャラクター</TableCell>
              </TableRow>
              {pointSettingsLeft?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{explain(item)}</TableCell>
                  <TableCell>{item.action}</TableCell>
                  {!isEditing ? (
                    <>
                      <TableCell>{item.male_1}</TableCell>
                      <TableCell>{item.male_2}</TableCell>
                      <TableCell>{item.male_3}</TableCell>
                      <TableCell>{item.female_1}</TableCell>
                      <TableCell>{item.female_2}</TableCell>
                      <TableCell>{item.female_3}</TableCell>
                      <TableCell>{item.chara}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_1.${item.id}`}
                          value={item.male_1}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_2.${item.id}`}
                          value={item.male_2}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_3.${item.id}`}
                          value={item.male_3}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_1.${item.id}`}
                          value={item.female_1}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_2.${item.id}`}
                          value={item.female_2}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_3.${item.id}`}
                          value={item.female_3}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`chara.${item.id}`}
                          value={item.chara}
                          onChange={handleChangeLeft}
                        />
                      </TableCell>
                    </>
                  )}
                  {/* <TableCell>
                    <div className="pointer" onClick={() => handleEdit(item)}>
                      <img src={EditButton} className="btnEdit" alt="" />
                    </div>
                  </TableCell> */}
                </TableRow>
              ))}

              {pointSettingsLeft?.length < 15 &&
              Array.from(
                { length: 15 - pointSettingsLeft?.length },
                (v, i) => (
                  <TableRow key={i}>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </Card>
        {/* <Card className="card-box" style={{ width: '49.3%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明</TableCell>
                <TableCell style={{ width: 250 }}>DATA名</TableCell>
                <TableCell>男Normal User</TableCell>
                <TableCell>男Point User</TableCell>
                <TableCell>男Plan User</TableCell>
                <TableCell>女Normal User</TableCell>
                <TableCell>女Point User</TableCell>
                <TableCell>女Plan User</TableCell>
                <TableCell>キャラクター（給与ポイント）</TableCell>
              </TableRow>
              {pointSettingsRight?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{explain(item)}</TableCell>
                  <TableCell>{item.action}</TableCell>
                  {!isEditing ? (
                    <>
                      <TableCell>{item.male_1}</TableCell>
                      <TableCell>{item.male_2}</TableCell>
                      <TableCell>{item.male_3}</TableCell>
                      <TableCell>{item.female_1}</TableCell>
                      <TableCell>{item.female_2}</TableCell>
                      <TableCell>{item.female_3}</TableCell>
                      <TableCell>{item.chara}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_1.${item.id}`}
                          value={item.male}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_2.${item.id}`}
                          value={item.male}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`male_3.${item.id}`}
                          value={item.male}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_1.${item.id}`}
                          value={item.female}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_2.${item.id}`}
                          value={item.female}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`female_3.${item.id}`}
                          value={item.female}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          className="input-rank"
                          name={`chara.${item.id}`}
                          value={item.chara}
                          onChange={handleChangeRight}
                        />
                      </TableCell>
                    </>
                  )}
                  // this section has been comment before
                  <TableCell>
                    <div className="pointer" onClick={() => handleEdit(item)}>
                      <img src={EditButton} className="btnEdit" alt="" />
                    </div>
                  </TableCell>
                  //this section has been comment before
                </TableRow>
              ))}
              {pointSettingsRight?.length < 15 &&
                Array.from(
                  { length: 15 - pointSettingsRight?.length },
                  (v, i) => (
                    <TableRow key={i}>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </Card> */}
      </div>
      {/* {isEditing && (
        <EditPointRateModal
          onClose={onClose}
          rowData={rowData}
          onUpdate={reloadList}
        />
      )} */}
    </MuiThemeProvider>
  );
};

export default TableOfPoinTableRowate;
