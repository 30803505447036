import { call, put, takeLatest } from 'redux-saga/effects';
import { getListPayment } from '../../Api/database';
import { loadListPaymentFail, loadListPaymentSuccess } from '../Actions/listPayment';
import ListPayment from '../Types/listPayment';

function* handleLoadListPayment(action) {
  try {
    const response = yield call(getListPayment, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListPaymentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListPaymentFail(error));
  }
}

export default function* ListPaymentSaga() {
  yield takeLatest(ListPayment.LOAD_LIST_PAYMENT, handleLoadListPayment);
}
