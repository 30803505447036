import { Button, createMuiTheme, FormGroup, FormLabel, MuiThemeProvider } from '@material-ui/core';
import RadioGroup from '../../Components/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Label, Row } from 'reactstrap';
import LoadingBar from '../../Components/LoadingBar';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getByIdMemberAdmin, updateMemberAdmin } from '../../Redux/Actions/memberAdmin';
import { REGEX_ALPHABET, REGEX_EMAIL } from '../../Common/constant';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { checkFullWidth, convertEnvInfo, validateFullWidthVsAlphabet } from '../../Util/utils';

const memberTypeOptions = [
  {
    label: 'システム管理者',
    value: 0,
  },
  {
    label: '上級管理者',
    value: 1,
  },
];

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function EditMemberAdmin() {
  const params = useParams();
  const envInfo = useSelector((state) => state.envInfo.data);
  const memberAdminId = params.memberAdminId;
  const [labelDb, setLabelDb] = useState({});
  const [colorLabel, setColorLabel] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2?.content)
  }, [envInfo]);

  useEffect(() => {
    dispatch(getByIdMemberAdmin(memberAdminId));
  }, [dispatch, memberAdminId]);

  const history = useHistory();
  const redirectPageDetailAgent = (id) => {
    history.push(`/administrator/${id}/detail`);
  };

  const loading = useSelector((state) => state.admin.loading);
  const admin = useSelector((state) => state.memberAdmin.detail);
  const updated = useSelector((state) => state.memberAdmin.updated);

  const [editingAdmin, setEditingAgent] = useState();

  useEffect(() => {
    setEditingAgent(admin);
  }, [dispatch, admin]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailAgent(memberAdminId)
    }
  }, [updated]);

  const handleSubmit = () => {
    if (!editingAdmin.nickName) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberAdmin.name);
    }
    if (!editingAdmin.emailAddressMain) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.email);
    } else if (editingAdmin.emailAddressMain && !REGEX_EMAIL.test(editingAdmin.emailAddressMain)) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
    }

    if (!editingAdmin.password) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
    } else if (
      editingAdmin.password &&
      (editingAdmin.password.length < 8
        || editingAdmin.password.length > 10
        || !REGEX_ALPHABET.test(editingAdmin.password)
        || checkFullWidth(editingAdmin.password))
    ) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
    }

    if (editingAdmin.memberType === null || editingAdmin.memberType === undefined) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberType);
    }

    const updateData = _.pick(editingAdmin, [
      'id',
      'nickName',
      'emailAddressMain',
      'password',
      'memberType',
    ]);
    dispatch(updateMemberAdmin(updateData));
  };

  const onChange = (e) => {
    const updatedAdmin = _.set(editingAdmin, e.target.name, e.target.value);
    setEditingAgent({ ...updatedAdmin });
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        editingAdmin[name] = '';
      } else {
        editingAdmin[name] = value;
      }
      setEditingAgent({ ...editingAdmin });
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={customTheme}>
        <div className="align">
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={() => redirectPageDetailAgent(admin.id)}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmit}
          >
            登録
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card>
              <Row>
                <Col xs={12}>
                  <Col xs={12} style={{ marginTop: 10 }}>
                    <FormGroup row>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>
                        メールアドレス（重複NG）
                      </FormLabel>
                      <input
                        defaultValue={editingAdmin?.emailAddressMain}
                        name="emailAddressMain"
                        type="text"
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                  <Label xs={12}></Label>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={6}>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">
                          <span className="text-red">*</span>{labelDb?.label_nickName?.content}
                        </FormLabel>
                        <input
                          defaultValue={editingAdmin?.nickName}
                          name="nickName"
                          type="text"
                          onChange={onChange}
                        />
                      </Label>
                      <Label xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">
                          <span className="text-red">*</span>
                          パスワード（英数字8文字～10文字）
                        </FormLabel>
                        <FormGroup row>
                          <input
                            defaultValue={editingAdmin?.password}
                            name="password"
                            type="text"
                            value={editingAdmin?.password}
                            onChange={e => validatePassword(e.target.value, e.target.name)}
                          />
                        </FormGroup>
                      </Label>
                    </Col>
                    <Col xs={6}>
                     <span className={'d-box-radio-group'}>
                        <FormLabel style={{ color: colorLabel }} component="legend">
                          <span className="text-red">*</span>会員種類
                        </FormLabel>
                        <RadioGroup
                          name="memberType"
                          defaultValue={admin?.memberType}
                          options={memberTypeOptions}
                          onChange={onChange}
                        />
                     </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </MuiThemeProvider>
    </div>
  );
}

export default EditMemberAdmin;
