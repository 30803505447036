import { call, put, takeLatest } from 'redux-saga/effects';
import Point from '../Types/point';
import { getPoint } from '../../Api/pointApi';
import { loadPointFail, loadPointSuccess } from '../Actions/point';


function* handleLoadPoint(action) {
  try {
    const response = yield call(getPoint, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadPointSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadPointFail(error));
  }
}

export default function* pointSaga() {
  yield takeLatest(Point.LOAD_POINT, handleLoadPoint);
}
