import { call, put, takeLatest } from 'redux-saga/effects';
import { importDataTest } from '../../Api/database';
import { importCSVFail, importCSVSuccess } from '../Actions/importCSV';
import { toast } from 'react-toastify';
import IMPORT_CSV from '../Types/importCSV';

function* handleImportCSV(action) {
  try {
    const response = yield call(importDataTest, action.payload);
    const { data } = response;
    if (!data.data.countFail) {
      yield put(importCSVSuccess());
    } else {
      const dt = data.data.errorValidate;
      let rs = ['失敗しました'];
      for (const r of dt) {
        if (r?.error) {
          rs.push(r?.error);
        }
      }
      toast.error(rs.join('\n'), {
        enableHtml: true,
        className: 'toast-new-line',
      });
      yield put(importCSVFail({ message: rs.join('\n') }));
    }
  } catch (e) {
    if (e.response?.data?.errorCode === 'CSV_INVALID_CLOSING_QUOTE') {
      toast.error('失敗しました \n ' + e.response?.data.message, {
        enableHtml: true,
        className: 'toast-new-line',
      });
      yield put(importCSVFail({ message: e.response }));
      return;
    }
    toast.error('失敗しました');
    yield put(importCSVFail({ message: e.response }));
  }
}

export default function* importCSVSaga() {
  yield takeLatest(IMPORT_CSV.IMPORT_CSV, handleImportCSV);
}
