import { sendDelete, sendGet } from './axios';

export const getFootprints = (filter) => sendGet('/cms/footprint/list', {
  ...filter,
});

export const getFootprintsByMe = (filter) => sendGet('/cms/footprints', {
  ...filter,
});

export const getFootprintsToMe = (filter) => sendGet('/cms/users/footprints', {
  ...filter,
});

export const deleteFootprint = (params) => sendDelete(`/cms/footprint/delete`, params);
