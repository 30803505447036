import React, { useEffect, useState } from 'react';
import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import SortArrows from '../../../Components/SortArrows';
import { Link } from 'react-router-dom';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import Pagination from '../../../Components/Pagination';
import { loadJobEntryMembers } from '../../../Redux/Actions/jobEntryMembers';
import JobEntryDetail from '../JobEntryDetail';
import { convertEnvInfo, convertJobEntryMemberTypeArrayText, convertMemberTypeArrayText } from '../../../Util/utils';
import { IMAGE_TYPE } from '../../../Config/listConstants';
import NoPhotoJob from '../../../assets/image/no_job_photo.png';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';

const pageSize = 50;

function Index() {
  const jobEntryMembers = useSelector((state) => state.jobEntryMembers.items);
  const totalRow = useSelector((state) => state.jobEntryMembers.totalRow);
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const [filter, setFilter] = useState({
    sort: { 'createdDate': 'DESC' },
  });
  const [jobEntryDetail, setJobEntryDetail] = useState(null);

  useEffect(() => {
    dispatch(loadJobEntryMembers({ pageIndex, pageSize, ...filter }));
  }, [dispatch, filter, pageIndex]);

  const handleClickDetail = (jobEntry) => {
    setJobEntryDetail(jobEntry);
  };

  const handleClose = () => {
    setJobEntryDetail(null);
  };

  const reload = () => {
    setPageIndex(1);
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter && (filter?.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC')) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter && filter?.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }

    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });

    setPageIndex(1);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'createdDate': 'DESC' } });

  const renderUrlJobMain = (images = []) => {
    if (Array.isArray(images) && images.length) {
      const imgJobMain = images.filter(ele => ele?.type && +ele?.type === IMAGE_TYPE.AVATAR);

      if (imgJobMain && imgJobMain.length) {
        return imgJobMain[0]?.url;
      }
    }

    return NoPhotoJob;
  };

  const renderUrlUserImage = (images = []) => {
    if (Array.isArray(images) && images.length) {
      const imgAvatar = images.filter(ele => ele?.type && +ele?.type === IMAGE_TYPE.AVATAR);

      if (imgAvatar && imgAvatar.length) {
        return imgAvatar[0]?.url;
      }
    }

    return NoPhoto;
  };

  const renderImageSource = (favorite = {}) => {
    return favorite && favorite?.avatar?.url ? favorite?.avatar?.url : NoPhoto;
  };

  return (
    <div style={{ marginTop: 20 }}>

      {jobEntryDetail && (
        <JobEntryDetail
          onClose={handleClose}
          item={jobEntryDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* favorite */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="会員種類"
                    name="memberType"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="×会員（仕事）"
                    name="type"
                    onClick={onSort}
                  />
                </TableCell>

                {/* target */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="タイトル（仕事）"
                    name="title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>画像（仕事）</TableCell>
                <TableCell>会社名</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {jobEntryMembers.map((jobEntry, index) => (
                <TableRow key={jobEntry.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{jobEntry?.member?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${jobEntry?.member?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="imgRound"
                           src={
                            jobEntry?.member?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                            renderUrlUserImage(jobEntry?.member?.images)}
                           alt="image-source"
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{jobEntry?.member?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(jobEntry?.member?.gender)}
                  </TableCell>
                  <TableCell>{convertMemberTypeArrayText(jobEntry?.member?.memberType)}</TableCell>
                  <TableCell>{convertJobEntryMemberTypeArrayText(jobEntry?.type)}</TableCell>

                  {/* target */}
                  <TableCell>{jobEntry?.job?.title}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/job/${jobEntry?.job?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img
                        className="imgRound"
                        src={renderUrlJobMain(jobEntry?.job?.imageJobs)}
                        alt="url-main-job"
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{jobEntry?.job?.agent?.nickName}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(jobEntry?.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(jobEntry)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
