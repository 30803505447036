import { call, put, takeLatest } from 'redux-saga/effects';
import TimerChatRoom from '../Types/timerChatRoom';
import { getTimerChatRooms } from '../../Api/timerAPI';
import { loadTimerChatRoomFail, loadTimerChatRoomSuccess } from '../Actions/timerChatRoom';

function* handleLoadTimerChatRoom(action) {
  try {
    const response = yield call(getTimerChatRooms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTimerChatRoomSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTimerChatRoomFail(error));
  }
}

export default function* timerChatRoomSaga() {
  yield takeLatest(TimerChatRoom.LOAD_TIMERCHATROOM, handleLoadTimerChatRoom);
}
