import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllScheduleSms } from '../../Api/userAPI';
import ScheduleSms from '../Types/scheduleSms';
import {
  estimateScheduleSmsReceiversSuccess,
  loadScheduleSmsFail,
  loadScheduleSmsSuccess,
} from '../Actions/scheduleSms';
import { estimateScheduleMailReceiversFail } from 'Redux/Actions/scheduleMail';

function* handleLoadScheduleSms(action) {
  try {
    const res = yield call(getAllScheduleSms, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadScheduleSmsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadScheduleSmsFail(error));
  }
}

function* handleEstimatingScheduleSmsReceivers(action) {
  try {
    const res = yield call(getAllScheduleSms, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(estimateScheduleSmsReceiversSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(estimateScheduleMailReceiversFail(error));
  }
}

export default function* scheduleSmsSaga() {
  yield takeLatest(ScheduleSms.LOAD_SCHEDULE_SMS, handleLoadScheduleSms);
  yield takeLatest(
    ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS,
    handleEstimatingScheduleSmsReceivers,
  );
}
