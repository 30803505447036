import {
  Button,
  Card,
  FormControlLabel,
  MuiThemeProvider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { updateEnvUI } from '../../../Redux/Actions/envUI';
import { settingOrder } from './settingOrder';
import { pathDisplayPageAfterLogin } from '../../../Util/utils';
import styles from './style.module.scss';

function EnvUiWebSettingList({ nameEnv = {}, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [envOrder, setEnvOrder] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(nameEnv);
  }, [nameEnv]);

  const handleChange = (e) => {
    setEnvSetting({ ...envSetting, [e.target.name]: e.target.value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onClose = () => {
    setEnvSetting(nameEnv);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = envSetting;
    dispatch(updateEnvUI(values));
    setIsEditing(false);
  };

  const handleSpecialDBDataSwitchUpdate = (e) => {
    const data = { ...envSetting, [e.target.name]: Number(!e.target.checked) };
    dispatch(updateEnvUI(data));
  };

  React.useEffect(() => {
    const setOrder = settingOrder.sort((a, b) => a.order - b.order);
    const data = [];

    for (let i = 0; i < setOrder.length; i++) {
      const { order, ...valueData } = setOrder[i];

      data[i] = valueData;
    }

    setEnvOrder(data);
  }, []);

  const checkValue = (key, index) => {
    if (key === 'footerNav') {
      switch (nameEnv[key]) {
        case 2:
          return 'Style 2';
        case 3:
          return 'Style 3';
        case 4:
          return 'Style 4';
        case 9:
          return 'Style 9';
        default:
          return 'Style 1';
      }
    }

    if (key === 'displayPageAfterLogin') {
      return pathDisplayPageAfterLogin(nameEnv[key]);
    }

    if (
      [
        'homeResultGender',
        'searchResultGender',
        'homeResultGenderApp',
        'searchResultGenderApp',
      ].includes(key)
    ) {
      if (nameEnv[key] === 0) {
        return 'Male and Female';
      }
      return 'Opposite';
    } else if (
      [
        'homeSortType',
        'searchSortType',
        'searchSortTypeApp',
        'homeSortTypeApp',
      ].includes(key)
    ) {
      switch (nameEnv[key]) {
        case 0:
          return 'image + login';
        case 1:
          return 'image + regist';
        case 2:
          return 'login';
        case 3:
          return 'regist';
        default:
          break;
      }
    } else if (['chatRoomAge', 'chatRoomAgeApp'].includes(key)) {
      if (nameEnv[key] === 0) {
        return 'free';
      }
      return 'authenticated user';
    } else if (key.includes('chatRoomMemberType')) {
      switch (nameEnv[key]) {
        case 0:
          return 'free';
        case 1:
          return 'up to 1 times';
        case 2:
          return 'up to 2 times';
        default:
          break;
      }
    }
    if (['homeResultBlockuser', 'searchResultBlockuser'].includes(key)) {
      if (nameEnv[key] === 0) {
        return 'off';
      }

      return 'on';
    }

    if (nameEnv[key] === 0) {
      return 'on';
    }

    return 'off';
  };

  function RenderSpecialDBDataField(key) {
    const checked = !nameEnv[key];
    return (
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name={key}
            checked={checked}
            onChange={handleSpecialDBDataSwitchUpdate}
            classes={{
              checked: styles.checked,
            }}
          />
        }
      />
    );
  }

  function RenderDBDataField(key) {
    const specialKey = [
        // 'displayAccountNotificationInfo'
    ];
    if (specialKey.includes(key)) return RenderSpecialDBDataField(key);

    if (isEditing)
      return (
        <input value={envSetting[key]} name={key} onChange={handleChange} />
      );
    return nameEnv[key];
  }

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        className="main-content-custom"
      >
        <Card style={{ width: '100%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell title="説明-WEB" style={{ width: '40%' }}>
                  説明-WEB
                </TableCell>
                <TableCell title="DATA名" style={{ width: '20%' }}>
                  DATA名
                </TableCell>
                <TableCell title="設定内容" style={{ width: '20%' }}>
                  設定内容
                </TableCell>
                <TableCell title="DBdata">DBdata</TableCell>
              </TableRow>
              {envOrder.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.explainText}</TableCell>
                  <TableCell>{item.key}</TableCell>
                  <TableCell>{checkValue(item.key, index)}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 500,
                    }}
                  >
                    {RenderDBDataField(item.key)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </MuiThemeProvider>
  );
}

export default EnvUiWebSettingList;
