const ScheduleSms = {
  LOAD_SCHEDULE_SMS: 'LOAD_SCHEDULE_SMS',
  LOAD_SCHEDULE_SMS_SUCCESS: 'LOAD_SCHEDULE_SMS_SUCCESS',
  LOAD_SCHEDULE_SMS_FAIL: 'LOAD_SCHEDULE_SMS_FAIL',
  ESTIMATE_SCHEDULE_SMS_RECEIVERS: 'ESTIMATE_SCHEDULE_SMS_RECEIVERS',
  ESTIMATE_SCHEDULE_SMS_RECEIVERS_SUCCESS: 'ESTIMATE_SCHEDULE_SMS_RECEIVERS_SUCCESS',
  ESTIMATE_SCHEDULE_SMS_RECEIVERS_FAIL: 'ESTIMATE_SCHEDULE_SMS_RECEIVERS_FAIL',
};

export default ScheduleSms;
