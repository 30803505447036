import { call, put, takeLatest } from 'redux-saga/effects';
import LogSendSms from '../Types/logSendSms';
import { getLogSendSms } from '../../Api/logSendSmsApi';
import { loadLogSmsFail, loadLogSmsSuccess } from '../Actions/logSendSms';

function* handleLoadLogSendSms(action) {
  try {
    const response = yield call(getLogSendSms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLogSmsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLogSmsFail(error));
  }
}

export default function* contactsSaga() {
  yield takeLatest(LogSendSms.LOAD_LOG_SEND_SMS, handleLoadLogSendSms);
}
