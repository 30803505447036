import { createMuiTheme, MuiThemeProvider, TableBody, TableCell, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Label, Row, Card, Table } from 'reactstrap';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE, REPLIED_STATUS } from '../../../Common/constant';
import { IMAGE_TYPE, LINK_MAP_DEFINED_API } from '../../../Config/listConstants';
import { loadAgent } from '../../../Redux/Actions/agent';
import { loadCharacter } from '../../../Redux/Actions/character';
import { loadResourceAgent } from '../../../Redux/Actions/resourceAgent';
import Util from '../../../Util/Util';
import RemoveAgentModal from './RemoveAgentModal';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getUsers } from '../../../Api/userAPI';
import { accessAndPassDataIframe, convertEnvInfo, jobPublishingTypeText } from '../../../Util/utils';
import Pagination from '../../../Components/Pagination';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import SortArrows from '../../../Components/SortArrows';
import { loadJobs } from '../../../Redux/Actions/jobs';
import NoPhotoJob from '../../../assets/image/no_job_photo.png';
import JobEntryAgentOpeModal from './JobEntryAgentOpeModal';
import { TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL } from '../../../Common/modal_text_info';
import FavoriteJobAgentModal from '../../AgentManagement/AgentDetail/FavoriteJobAgentModal';
import EntryPaidPlanModal from '../../AgentManagement/AgentDetail/EntryPaidPlanModal';
import StripeAgentModal from '../../AgentManagement/AgentDetail/StripeAgentModal';
import CheckoutBalancePayment from '../../AgentManagement/AgentDetail/CheckoutBalancePayment';
import ChatRoomAdminModal from '../../AgentManagement/AgentDetail/ChatRoomAdmin';
import { createChatRoomAdmin, getChatRoomByIdAgent, getChatRoomById } from '../../../Api/chatAdminApi';
import { counterUnReadMessageAgent } from '../../../Api/chatAgentAPI';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const pageSize = 50;

function AgentDetail() {
  const params = useParams();
  const agentId = params.agentId;
  const history = useHistory();
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const [typeShowActionBtn, setTypeShowActionBtn] = useState({
    listChara: DISPLAY_CMS_UI_TYPE.HIDDEN,
    pointUsage: DISPLAY_CMS_UI_TYPE.HIDDEN,
    favoriteJob: DISPLAY_CMS_UI_TYPE.HIDDEN,
    entryPaidPlan: DISPLAY_CMS_UI_TYPE.HIDDEN,
    balance: DISPLAY_CMS_UI_TYPE.HIDDEN,
    chatRoomAdmin: DISPLAY_CMS_UI_TYPE.HIDDEN,
  }); // 0 show, 1 show + not link, 2 hidden
  const [filter, setFilter] = useState({
    sort: { 'updatedDate': 'DESC' },
    agentId
  });
  
  const listChara = useSelector((state) => state.chara.data);
  
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [fakeLoginDetail, setFakeLoginDetail] = useState();
  const jobs = useSelector((state) => state.jobs.items);
  const totalRow = useSelector((state) => state.jobs.totalRow);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataMemberJobModal, setDataMemberJobModal] = useState({ show: false, jobId: null });
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [favoriteJobAgentVisible, setFavoriteJobAgentVisible] = useState(false);
  const [entryPaidPlanVisible, setEntryPaidPlanVisible] = useState(false);
  const [balanceVisible, setBalanceVisible] = useState(false);
  const [priceBalancePayment, setPriceBalancePayment] = useState(0);
  const [valueRoomChat, setValueRoomChat] = useState('');
  const [chatRoomAdminVisible, setChatRoomAdminVisible] = useState(false);
  const [countMessageAgentToAdminNoReply, setCountMessageAgentToAdminNoReply] = useState(0)
  const [chatRoomAgentToAdminId, setChatRoomAgentToAdminId] = useState('')
  const [countMessageAgentUnRead, setCountMessageAgentUnRead] = useState(0);
  const location = useLocation();
  
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(()=> {
    getChatRoomById(chatRoomAgentToAdminId).then(res =>{
      setCountMessageAgentToAdminNoReply(res?.data?.data?.filter((item) => (item.replied === REPLIED_STATUS.NOT_REPLIED && item.senderId !== agentId)).length)
    })
  },[chatRoomAgentToAdminId])

  useEffect(() => {
    getChatRoomByIdAgent(agentId).then(res => {
      setChatRoomAgentToAdminId(res?.data?.data?.id)
    })
    localStorage.setItem('userLoginId',agentId)
  },[agentId])

  useEffect(() => {
    counterUnReadMessageAgent({ replied : 0, agentId: agentId }).then((res) => {
      if (res) {
        const { data } = res;
        setCountMessageAgentUnRead(data.data.counter);
      }
    });
  },[countMessageAgentUnRead,agentId])

  useEffect(()=>{
    if(!!valueRoomChat){
      setChatRoomAdminVisible(true)
    }
  },[valueRoomChat])

  const handleVisible = (data) => {
    setDataMemberJobModal(data);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadJobs({ pageIndex: 1, pageSize, ...filter, agentId }));
  }, [dispatch, filter, pageIndex]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  useEffect(() => {
    dispatch(loadAgent(agentId));
    dispatch(loadResourceAgent({}));
    dispatch(loadCharacter({ agentId, pageIndex: 1, pageSize: 1 }));
    getUsers().then(res => {
      setFakeLoginDetail(res.data?.data[0])
    });
  }, [dispatch, agentId]);

  const agent = useSelector((state) => state.agent.data);
  const pointUsageLog = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPE_BTN_POINT_USAGE);
  const btnFavoriteJob = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPE_BTN_FAVORITE_JOB);
  const btnEntryPaidPlan = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPE_BTN_ENTRY_PAID_PLAN);
  const btnBalance = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPE_BTN_BALANCE);
  const btnChatRoomAdmin = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPE_BTN_CHAT_ROOM_ADMIN);

  const handleClose = () => {
    setConfirmVisible(false);
    setFavoriteJobAgentVisible(false);
    setEntryPaidPlanVisible(false);
    setBalanceVisible(false);
    setChatRoomAdminVisible(false);

  };

  const setAllShowAllForButton = () => {
    let tmp = {};
    Object.keys(typeShowActionBtn).forEach(key => {
      tmp[key] = DISPLAY_CMS_UI_TYPE.ON;
    });
    setTypeShowActionBtn(tmp);
  };
  const accessTypes = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.HIDDEN, DISPLAY_CMS_UI_TYPE.NOT_LINK]

  useEffect(() => {
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setAllShowAllForButton();
    }

    if (+admin?.memberType !== MEMBER_TYPE.SYSTEM_ADMIN) {
      let tmp = typeShowActionBtn;
      // Admin: Check type show setting
      if (pointUsageLog && accessTypes.includes(+pointUsageLog?.type)) {
        tmp.pointUsage = +pointUsageLog?.type;
      }
      if (btnFavoriteJob && accessTypes.includes(+btnFavoriteJob?.type)) {
        tmp.favoriteJob = +btnFavoriteJob?.type;
      }
      if (btnEntryPaidPlan && accessTypes.includes(+btnEntryPaidPlan?.type)) {
        tmp.entryPaidPlan = +btnEntryPaidPlan?.type;
      }
      if (btnBalance && accessTypes.includes(+btnBalance?.type)) {
        tmp.balance = +btnBalance?.type;
      }
      if (btnChatRoomAdmin && accessTypes.includes(+btnChatRoomAdmin?.type)) {
        tmp.chatRoomAdmin = +btnChatRoomAdmin?.type;
      }
      setTypeShowActionBtn(tmp);
    }
  }, [admin]);
  
  const accessAndPassData = () =>  {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  }

  const renderMainImageJob = (imgJobs = []) => {
    let url = NoPhotoJob;
    let mainImg = imgJobs.find(ele => ele.type === IMAGE_TYPE.AVATAR);

    if (mainImg) {
      url = mainImg.url;
    }

    return (
      <img className={'imgSquare object-fit-cover'}
           style={{
             objectFit: 'contain',
           }}
           src={url ?? NoPhotoJob}
           alt=""
      />
    );
  };

  const reload = () => {
    dispatch(loadAgent(agentId));
  };

  const handleClickFavoriteJobAgent = () => {
    setFavoriteJobAgentVisible(true);
  };

  const handleCheckoutBalancePayment = () => {
    setBalanceVisible(false); // Fixed
    setTimeout(() => {
      let checkoutBuyPointAgent = document.getElementById('checkout-buy-point-agent');
      if (checkoutBuyPointAgent) {
        checkoutBuyPointAgent.click();
      }
    }, 1200);
  };

  const handleSuccessCheckout = () => {
    reload();
    setBalanceVisible(false);
  };

  const handleClickChatRoomAdmin = () => {
    createConversation()
    // setChatRoomAdminVisible(true);
  };

  const createConversation = async() =>{
    try {
      const data = await createChatRoomAdmin(agentId);
      setValueRoomChat(data?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {chatRoomAdminVisible && (
        <ChatRoomAdminModal
          visible={chatRoomAdminVisible}
          onClose={handleClose}
          agentId={agentId}
          labelDb={labelDb}
          agent={agent}
          valueRoomChat={valueRoomChat}
        />
      )}
      {/*Stripe Balence Modal*/}
      {balanceVisible && (
        <StripeAgentModal
          visible={balanceVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          labelDb={labelDb}
          agent={agent}
          reload={reload}
          setCheckoutBalance={handleCheckoutBalancePayment}
          setPriceBalancePayment={setPriceBalancePayment}
          priceBalancePayment={priceBalancePayment}
        />
      )}
      {/*Entry Paid Plan Modal*/}
      {entryPaidPlanVisible && (
        <EntryPaidPlanModal
          visible={entryPaidPlanVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          labelDb={labelDb}
        />
      )}
      {favoriteJobAgentVisible && (
        <FavoriteJobAgentModal
          visible={favoriteJobAgentVisible}
          onClose={handleClose}
          agentId={agentId}
          labelDb={labelDb}
          agent={agent}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={myProfVisible}
        onClose={() => setMyProfVisible(false)}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600
          }
        }}
        className={"d-modal-wrapper"}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          MyProf
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => setMyProfVisible(false)}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ height: '80vh', overflow: 'hidden' }}>
          {
            fakeLoginDetail &&
            <iframe title="s"
                    id="iframeId"
                    onLoad={accessAndPassData}
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    src={Util.myProfSrc(fakeLoginDetail, `agent*profile/${agentId.replace(/-/g, '*')}`)}/>
          }
        </DialogContent>
      </Dialog>

      {confirmVisible && (
        <RemoveAgentModal
          visible={confirmVisible}
          onClose={handleClose}
          agentId={agentId}
          charaAllowed={(listChara || []).length === 0}
        />
      )}
      {agent && (
        <MuiThemeProvider theme={customTheme}>
          <div
            id="max-width-dialog-title"
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {pointUsageLog && typeShowActionBtn?.pointUsage !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  >
                    <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/agentope/point_usage_log/${agent.id}`, state: { prevPath: location.pathname }}}>{pointUsageLog?.content}</Link>
                  </Button>
                )}
                {btnFavoriteJob && typeShowActionBtn?.favoriteJob !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={handleClickFavoriteJobAgent}
                >
                  {btnFavoriteJob?.content}
                </Button>
                )}
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3"
                >
                  <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/ope-job/job-register`, state: { agentId: agent.id, prevPath: location.pathname }}}>仕事登録</Link>
                </Button>
            </div>

            
            <div className='d-flex'>
              {btnEntryPaidPlan && typeShowActionBtn?.entryPaidPlan !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={() => setEntryPaidPlanVisible(true)}
                >
                  {btnEntryPaidPlan?.content}
                </Button>
              )}
              {btnBalance && typeShowActionBtn?.balance !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={() => setBalanceVisible(true)}
                >
                  {btnBalance?.content}
                </Button>
              )}
              <div style={{ position: 'relative' }}>
                {btnChatRoomAdmin && typeShowActionBtn?.chatRoomAdmin !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                    onClick={() => handleClickChatRoomAdmin(agent.id)}
                  >
                    {btnChatRoomAdmin?.content}
                  </Button>
                )}
                {
                countMessageAgentToAdminNoReply ?
                  <div className="message-badge">
                    <span style={{ color: 'white' }}>{countMessageAgentToAdminNoReply}</span>
                  </div>
                  : <></>
                }
              </div>
              <div style={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3"
                >
                  <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/admin_agent/work_page_individual/${agent.id}`, state: { prevPath: location.pathname }}}>ユーザーとのチャット</Link>
                </Button>
                {
                  countMessageAgentUnRead ?
                    <div className="message-badge">
                      <span style={{ color: 'white' }}>{countMessageAgentUnRead}</span>
                    </div>
                    : <></>
                }
              </div>
              <Button
                variant="contained"
                className="btn-custom btn-blue mr-3 mt-3"
              >
                <Link style={{ color: 'white', textDecoration: 'none' }} target="_blank" to={`/agentope/${agentId}/detailope`}>アカウント詳細</Link>
              </Button>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <Card body className="mb-4">
                <span>会社専用画面です</span>
                <span className="mt-2">仕事の一覧が下に表示してます</span>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
            </Col>
            <Col xs={8}>
              <Row>
                <Col xs={6}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>会社名</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.nickName}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>電話番号（重複NG）</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.phoneNumber}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>メールアドレス</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.emailAddressMain}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>
                </Col>

                <Col xs={6}>
                <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>掲載有効期限</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(
                          agent.planExpireDate,
                          'YYYY/MM/DD HH:mm',
                        )}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>支払予定日</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(
                          agent.datePayment,
                          'YYYY/MM/DD HH:mm',
                        )}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3 }}>支払残高</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.separator(agent?.balancePayment)}円
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
              <Row>
                <Col xs={12}>
                  <MuiThemeProvider theme={tableNewTheme}>
                    <Card className="scroll-x-on-top" style={{ marginBottom: 20 }}>
                      <Table padding="checkbox" className="table mb-0">
                        <TableBody>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>タイトル</TableCell>
                            <TableCell align="center">画像</TableCell>
                            <TableCell>都道府県</TableCell>
                            <TableCell>エリア</TableCell>
                            <TableCell>掲載状況</TableCell>
                            <TableCell>
                              <SortArrows
                                sortObject={filter.sort}
                                dangerouslySetInnerHTML={{
                                  __html: '更新日時',
                                }}
                                name="updatedDate"
                                onClick={onSort}
                              />
                            </TableCell>
                            <TableCell>応募者数</TableCell>
                            <TableCell align="center">ボタン</TableCell>
                          </TableRow>

                          {jobs.map((job, index) => (
                            <TableRow key={job.id} style={{ height: 48 }}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <span>{job?.title}</span>
                              </TableCell>
                              <TableCell align="center">
                                {renderMainImageJob((job?.imageJobs ?? []))}
                              </TableCell>
                              <TableCell>{job?.state?.label}</TableCell>
                              <TableCell>{job?.area?.label}</TableCell>
                              <TableCell>{jobPublishingTypeText(job?.draft)}</TableCell>
                              <TableCell>
                                <span>
                                  {Util.timestampToDate(
                                    job?.updatedDate,
                                    'YYYY/MM/DD HH:mm:ss',
                                  )}
                                </span>
                              </TableCell>
                              <TableCell>
                                <a href='#' className="d-color-link" style={{ cursor: 'pointer', padding: 5 }} onClick={() => handleVisible({ show: true, jobId: job.id })}>
                                  {job?.countMembersApplicanJob ? job?.countMembersApplicanJob : 0 }
                                </a>
                              </TableCell>
                              <TableCell align="center">
                                <div className="d-color-link">
                                  <Link to={`/job/${job.id}/detail`} target="_blank">
                                    詳細
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                  </MuiThemeProvider>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                  <Pagination
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalRow={totalRow}
                    onPageChange={handlePageChange}
                  />
                </Col>
              </Row>
        </MuiThemeProvider>
      )}
      
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dataMemberJobModal.show}
        onClose={() => handleVisible({ show: false, jobId: null })}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          仕事一覧＞応募者一覧-詳細/AgentOpeManagement/AgentOpeRoot/index.js
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => handleVisible({ show: false, jobId: null })}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <JobEntryAgentOpeModal jobId={dataMemberJobModal.jobId} agentId={agentId} labelDb={labelDb} />
        </DialogContent>
      </Dialog>
      {priceBalancePayment ? (<CheckoutBalancePayment
        agentId={agentId}
        productId={agent.id}
        price={priceBalancePayment}
        cycle={0}
        reload={reload}
        labelDb={labelDb}
        nameBtn=''
        handleSuccessCheckout={handleSuccessCheckout}
      />) 
      : <></>
      }
    </div>
  );
}

export default AgentDetail;
