import React from 'react';
import { toast } from 'react-toastify';
import { deleteOperator } from '../../../../Api/operatorAPI';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../../Common/constant_text';
import RemoveItemModal from '../../../../Components/RemoveItemModal';

function RemoveModal({ visible, onClose, operatorId }) {
  const handleSubmit = async () => {
    await deleteOperator(operatorId)
      .then((response) => {
        onClose();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      })
      .catch((e) => {
        onClose();
        // toast.error(e.response.data.message);
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={'<span>このオペレーターを物理削除します、元には戻せません。<br />この処理はシステム管理者のみ実行して下さい</span>'}
      />
    </div>
  );
}

export default RemoveModal;
