import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { loadPointUser } from '../../../Redux/Actions/pointUser';
import LoadingBar from '../../../Components/LoadingBar';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Pagination from '../../../Components/Pagination';
import Util from '../../../Util/Util';
import { Link } from 'react-router-dom';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_POINT_MODAL } from '../../../Common/modal_text_info';
import PointPaymentDetail from '../../PaymentManagement/PointPaymentDetail';


const pageSize = 10;

function PaymentLogModel({ visible, onClose, userId, labelDb, user }) {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.pointUser.loading);
  const points = useSelector((state) => state.pointUser.items);
  const totalRow = useSelector((state) => state.pointUser.totalRow);
  const [filter, setFilter] = useState({
    sort: { 'paymentLog.createdDate': 'DESC' },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPointUser({ pageIndex, pageSize, ...filter, userId }));
  }, [dispatch, pageIndex, userId, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const [detail, setDetail] = useState();

  const showDetail = (data) => {
    setDetail(data);
  };

  const hideDetail = () => {
    setDetail(null);
  };

  const reload = () => {
    setPageIndex(1);
    dispatch(loadPointUser({ pageIndex, pageSize, ...filter, userId }));
  };

  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading} />
        <>
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginRight: 50,
                }}
              >
                <p className={'d-title mb-0'}>ユーザー詳細-入金ログ</p>
                <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                  <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>{user?.phoneNumber}</p>
                  <img className="object-fit-cover"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        marginRight: 20,
                      }}
                      src={
                        user?.avatar
                          ? user.avatar.url
                          : NoPhoto
                      }
                      alt=""
                  />
                  <p className={'mb-0'} style={{ fontSize: '1rem' }}>{user?.nickName}</p>
                </div>
              </div>
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <div className={'d-title-info-box'}>
              <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_POINT_MODAL }}></div>
            </div>

            <DialogContent className={'table-modal-wrapper'}>
              <Card className="scroll-x-on-top" style={{overflow: 'auto'}}>
                <Table padding="checkbox" className="d-table-modal mb-0" style={{ width: '100vw'}}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell style={{ minWidth: 130 }}>入金種類</TableCell>
                      <TableCell style={{ minWidth: 130 }}>入金タイプ</TableCell>
                      <TableCell style={{ minWidth: 130 }}>金額</TableCell>
                      <TableCell style={{ minWidth: 130 }}>購入期間</TableCell>
                      <TableCell style={{ minWidth: 130 }}>取得ポイント</TableCell>
                      <TableCell style={{ minWidth: 150 }}>ボーナスポイント</TableCell>
                      <TableCell style={{ minWidth: 170 }}>決済日時</TableCell>
                      <TableCell style={{ minWidth: 200 }}>メモ</TableCell>
                      <TableCell style={{ minWidth: 80 }} align="center">ボタン</TableCell>
                    </TableRow>
                    {points.map((point, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {Util.paymentTypeIdToText(point.paymentType)}
                        </TableCell>
                        <TableCell>
                          {Util.payingTypeIdToText(point.payingType)}
                        </TableCell>
                        <TableCell>
                          {Util.separator(point.price)}円
                        </TableCell>
                        <TableCell>{point.month}</TableCell>
                        <TableCell>{Util.separator(point.pointGet)}</TableCell>
                        <TableCell>
                          {Util.separator(point.pointBonus)}
                        </TableCell>
                        <TableCell>
                          {Util.timestampToDate(point.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          <div className="ellipsis-1-line">{point.memo}</div>
                        </TableCell>
                        <TableCell className="text-center" align="center">
                          <Link className="d-color-link" onClick={() => showDetail(point)}>表示</Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>

              <center>
                <Pagination
                  className={'d-page-wrapper'}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalRow={totalRow}
                  onPageChange={handlePageChange}
                />
              </center>
            </DialogContent>
          </Dialog>
        </>

        {detail && (
          <PointPaymentDetail detail={detail} onClose={hideDetail} reload={reload} type="user_short" />
        )}
      </div>
    </MuiThemeProvider>
  );
}

export default PaymentLogModel;
