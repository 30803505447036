import { createMuiTheme } from '@material-ui/core';

export const themeStyleNormal1Modal = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '10px !important',
      },
    },
  },
});

export const themeStyleNormal2Modal = createMuiTheme({
  overrides: {
    MuiCard: {
      root: {
        borderTop: '1px solid #dee2e6',
      },
    },
    MuiTable: {
      root: {
        borderTop: '1px solid #dee2e6',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
      },
    },
    MuiTableCell: {
      root: {
        height: 67,
      },
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: 1600,
      },
    },
  },
});

export const themeChatDetail = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: 1600,
      },
    },
  },
});

export const themeChatModal = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        height: 67
      },
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
    MuiTable: {
      root: {
        borderTop: '1px solid #dee2e6',
      },
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: 1600,
      },
    },
  },
});

export const themeNormalModal = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        borderTop: '1px solid #dee2e6',
      },
    },
    MuiTableCell: {
      root: {
        height: 67,
      },
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: 1600,
      },
    },
  },
});
