import { sendGet, sendPut } from './axios';

export const getResourceItemJob = (params) => sendGet('/all-resource-job-item', params);

export const getRootResourceItemJob = (params) => sendGet('/cms/root-item-job-resources', params);
export const updateRootResourceItemJob = (params) => sendPut(`/cms/update-item-job-profile`, params);

export const getDetailResourceItemJob = (params) => sendGet(`/cms/child-item-job-attributes`, { item: params });
export const updateChildResourceItemJob = (params) => sendPut(`/cms/update-child-item-job-profile`, params);

