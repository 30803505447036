import React, { useState } from 'react';
import { CircularProgress, createMuiTheme, FormLabel, MuiThemeProvider, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import { updateTextWebPages } from '../../Api/textWebPageApi';
import { toast } from 'react-toastify';
import './styles.css';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function TextWebPageFormModal({ onClose, onSuccess, textWebPage = {} }) {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(textWebPage);

  const onChange = (e) => {
    model[e.target.name] = e.target.value;
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const { description, content, link, displaySetting, displayOrder } = model;

    if (!(content && link) || !model.id) {
      return;
    }

    let body = {
      description,
      content,
      ...displayOrder && {displayOrder: +displayOrder},
      displaySetting: +displaySetting,
    };
    setLoading(true);
    updateTextWebPages([{ ...body, id: model.id }])
      .then((response) => {
        const { data } = response;
        if (data.data) {
          onClose();
          onSuccess();
          setLoading(false);
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
          setLoading(false);
        }
      })
      .catch((e) => setLoading(false));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      aria-labelledby="max-width-dialog-title"
      className={'d-modal-wrapper'}
    >
      <MuiThemeProvider theme={theme}>
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <p className={'d-title mb-0'}>{model.id ? 'Web上部テキスト-詳細' : '登録'}</p>
          <div>
            <Button
              onClick={onClose}
              color="primary"
              disabled={loading}
              className="btn-custom btn-red"
            >
              キャンセル
            </Button>
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              disabled={loading}
              className="btn-custom btn-blue mr-3"
            >
              {loading && <CircularProgress />}
              保存
            </Button>
          </div>
        </DialogTitle>
      </MuiThemeProvider>
      <DialogContent>
        <Row className="pb-3">
          <Col xs={12}>
            <Card body>
              <FormGroup row>
                <Col xs={12}>
                  <div className="w-100">
                    <label className={'mb-0 d-label-disable'} data-shrink="true">URL(link)</label>
                    <div className="d-box-disable">
                      <p className={'d-text-disable mb-0'}>{textWebPage.link}</p>
                    </div>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="説明"
                    type="text"
                    defaultValue={textWebPage.description}
                    name="description"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12}>
                  <FormLabel component="legend">表示テキスト</FormLabel>
                  <textarea
                    name="content"
                    rows="3"
                    defaultValue={textWebPage.content}
                    onChange={onChange}
                    style={{ width: '100%', padding: 10 }}
                  />
                </Col>
              </FormGroup>
              {/*0= on（表示）, 1=off（非表示）*/}
              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="表示設定(0:On/1:Off)"
                    type="number"
                    defaultValue={textWebPage.displaySetting}
                    name="displaySetting"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="表示順(管理）"
                    type="number"
                    defaultValue={textWebPage.displayOrder}
                    name="displayOrder"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>
            </Card>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}

export default TextWebPageFormModal;
