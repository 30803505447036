import { call, put, takeLatest } from 'redux-saga/effects';
import textWebPage from '../Types/textWebPage';
import { getTextWebPages } from '../../Api/textWebPageApi';
import { loadTextWebPageFail, loadTextWebPageSuccess } from '../Actions/textWebPage';

function* handleLoadTextWebPage(action) {
  try {
    const response = yield call(getTextWebPages, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTextWebPageSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTextWebPageFail(error));
  }
}


export default function* chatUserSaga() {
  yield takeLatest(textWebPage.LOAD_TEXT_WEB_PAGE, handleLoadTextWebPage);
}
