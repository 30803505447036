import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import NameStateList from './NameStateList';
import { loadState } from '../../Redux/Actions/nameState';

function NameState() {
  const loading = useSelector((state) => state.nameState.loading);
  const dataNameState = useSelector((state) => state.nameState.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadState());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loading || false} />
      <NameStateList nameState={dataNameState?.items} loading={loading} />
    </div>
  );
}

export default NameState;
