import { createAction } from 'redux-actions';
import ScheduleSms from '../Types/scheduleSms';

export const loadScheduleSms = createAction(ScheduleSms.LOAD_SCHEDULE_SMS);
export const loadScheduleSmsSuccess = createAction(
  ScheduleSms.LOAD_SCHEDULE_SMS_SUCCESS,
);
export const loadScheduleSmsFail = createAction(
  ScheduleSms.LOAD_SCHEDULE_SMS_FAIL,
);
export const estimateScheduleSmsReceivers = createAction(
  ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS,
);
export const estimateScheduleSmsReceiversSuccess = createAction(
  ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS_SUCCESS,
);
export const estimateScheduleSmsReceiversFail = createAction(
  ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS_FAIL,
);
