import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { loadDetailOperator, updateOperator } from '../../../../Redux/Actions/operator';
import { Card, Col, Label, Row } from 'reactstrap';
import LoadingBar from '../../../../Components/LoadingBar';
import { createMuiTheme } from '@material-ui/core/styles';
import { FormGroup, MuiThemeProvider, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { REGEX_NUMBER, REGEX_NUMBER_PHONE } from '../../../../Common/constant';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import { checkFullWidth } from '../../../../Util/utils';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index() {
  const params = useParams();
  const operatorId = params.operatorId;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.detailOperator.loading);
  const updating = useSelector((state) => state.detailOperator.updating);
  const updated = useSelector((state) => state.detailOperator.updated);
  const operator = useSelector((state) => state.detailOperator.data);
  const resource = useSelector((state) => state.resource.data);

  useEffect(() => {
    dispatch(loadDetailOperator(operatorId));
  }, [dispatch, operatorId]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailOperator(operatorId)
    }
  }, [updated]);

  const submitEditOperator = () => {
    if (!editingOperator.phoneNumber) {
      toast.error('電話番号を入力してください');
      return;
    }
    if (!REGEX_NUMBER_PHONE.test(editingOperator.phoneNumber)) {
      toast.error('電話番号の形式が正しくありません');
      return;
    }

    if (!editingOperator.operatorNumber) {
      toast.error('オペレーターNumberを入力して下さい');
      return;
    }
    if (!editingOperator.password) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
    }
    if (editingOperator.password && (
      editingOperator.password.length < 8
      || editingOperator.password.length > 10
      || !REGEX_NUMBER.test(editingOperator.password)
      || checkFullWidth(editingOperator.password))
    ) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
    }

    if (!editingOperator.operatorName) {
      toast.error('オペレーター名を入力して下さい');
      return;
    }

    const updateData = _.pick(editingOperator, [
      'id',
      'phoneNumber',
      'operatorNumber',
      'password',
      'operatorName',
      'adminMemo',
    ]);
    dispatch(updateOperator(updateData));
  };

  const [editingOperator, setEditingOperator] = useState();
  useEffect(() => {
    if (operator) {
      setEditingOperator((editingOperator) => editingOperator || operator);
    }
  }, [operator]);

  const onChange = (e) => {
    const updateOperator = _.set(
      editingOperator,
      e.target.name,
      e.target.value,
    );
    setEditingOperator({ ...updateOperator });
  };

  const onBlur = (e) => {
    const updateOperator = _.set(
      editingOperator,
      e.target.name,
      e.target.value,
    );
    setEditingOperator({ ...updateOperator });
  };

  const history = useHistory();
  const redirectPageDetailOperator = (id) => {
    history.push(`/opechara/${id}/detail`);
  };

  if (!operator) {
    return null
  }

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={(loading && !_.isEmpty(resource)) || updating} />
      <MuiThemeProvider theme={customTheme}>
        <div align="right" style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={() => redirectPageDetailOperator(operator.id)}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue"
            variant="contained"
            onClick={submitEditOperator}
          >
            更新する
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
            </Card>
            <Card body className="mb-4">
              <FormGroup row>
                <TextField
                  style={{ marginBottom: 10 }}
                  name="operatorNumber"
                  type="text"
                  label="オペレーターNumber（重複NG）"
                  defaultValue={operator?.operatorNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={onChange}
                  disabled={true}
                  onBlur={onBlur}
                  className="w-100"
                />
              </FormGroup>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <FormGroup row>
                <TextField
                  style={{ marginBottom: 10 }}
                  name="phoneNumber"
                  label="電話番号（重複NG）"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  defaultValue={operator?.phoneNumber}
                  maxLength={11}
                  //onChange={onChange}
                  className="w-100"
                  //onBlur={onBlur}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup row>
                <TextField
                  style={{ marginBottom: 10 }}
                  defaultValue={operator?.password}
                  label="パスワード（英数字8文字～10文字"
                  name="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  onChange={onChange}
                  onBlur={onBlur}
                  className="w-100"
                />
              </FormGroup>
              <FormGroup row>
                <TextField
                  defaultValue={operator?.operatorName}
                  name="operatorName"
                  label="オペレーター名"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //onChange={onChange}
                  //onBlur={onBlur}
                  disabled={true}
                  className="w-100"
                />
              </FormGroup>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label xs={2}>管理者メモ</Label>
                <Label xs={10}>
                  <textarea
                    style={{ padding: 10 }}
                    defaultValue={operator?.adminMemo}
                    name="adminMemo"
                    className="w-100"
                    rows="3"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Label>
              </Row>
            </Card>
          </Col>
        </Row>
      </MuiThemeProvider>
    </div>
  );
}

export default Index;
