import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { sendChatListUser } from '../../Api/userAPI';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import LoadingBar from '../../Components/LoadingBar';

function SendChatModal({ visible, onClose, operatorId, userIdsAll }) {
  const [data, setData] = useState({});
  const [userIds, setUsersId] = useState([]);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const totalRow = useSelector((state) => state.users.totalRowUserids);

  useEffect(() => {
    setUsersId(userIdsAll.join(','));
  }, [userIds]);

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!userIds) {
      setLoading(false);
      toast.error('受信者数が０人のため、メッセージ内容できません。');
      return;
    }
    await sendChatListUser({
      data,
      userIds: userIds.split(','),
      operatorId: operatorId,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        setLoading(false);
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
        setLoading(false);
      }
    });
  };

  const handleConfirm = () => {
    if (data.content) {
      setConFirmVisible(true);
    }
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title"></DialogTitle>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              実行してよろしいですか？
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          メッセージ内容
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent>
          <div>
            受信者数　<span>{totalRow}人</span>
          </div>
          <div style={{ margin: '16px 0 8px 0' }}>
            テキストの書込みのみ、お知らせ通知は送信されます
          </div>
          <textarea
            name="content"
            rows="3"
            onChange={onChange}
            style={{ width: '100%', marginBottom: '20px', padding: 10 }}
            maxLength={500}
          />
          <div style={{ textAlign: 'right' }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                チャットを送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendChatModal;
