import { handleActions } from 'redux-actions';
import NameStateAgent from '../Types/stateAgent';

const initialState = {
  data: null,
  loading: false,
  totalRow: 0,
  dataChild: [],
};

const actions = {
  [NameStateAgent.LOAD_STATE_AGENT]: (state) => ({
    ...state,
    loading: true,
  }),
  [NameStateAgent.LOAD_STATE_AGENT_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
  }),
  [NameStateAgent.LOAD_STATE_AGENT_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),

  [NameStateAgent.LOAD_ALL_AREA_AGENT]: (state) => ({
    ...state,
    loadingChild: true,
  }),
  [NameStateAgent.LOAD_ALL_AREA_AGENT_SUCCESS]: (state, action) => ({
    ...state,
    dataChild: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingChild: false,
  }),
  [NameStateAgent.LOAD_ALL_AREA_AGENT_FAIL]: (state) => ({
    ...state,
    dataChild: null,
    loadingChild: false,
  }),
};
export default handleActions(actions, initialState);
