import React, { useState } from 'react';
import NoPhotoJob from '../assets/image/no_job_photo.png';
import NonAuth from '../assets/image/non_auth.png';
import ImageModal from './ImageModal';
import { IMAGE_STATUS } from '../Config/listConstants';

export default function ImageJobList({ subImages, main }) {
  const [previewImage, setPreviewImage] = useState(null);

  const handleShowImageModal = (image) => {
    setPreviewImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewImage(null);
  };

  return (
    <div className="clearfix">
      <div style={{ position: 'relative', float: 'left' }} className={'d-main-image'}>
        <img
          src={main?.url ? main.url : NoPhotoJob}
          style={{ width: 160, height: 160, objectFit: 'cover' }}
          border={0}
          alt="main"
          onClick={() => handleShowImageModal(main)}
        />
        {main.inspection === IMAGE_STATUS.PENDING && (
          <div className="image-watermark">
            <img className="imgSquare object-fit-cover" src={NonAuth} alt="" />
          </div>
        )}
      </div>
      <div className={'sub-images'} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {subImages.map((image, index) => (
          <div
            key={index}
            style={{ position: 'relative', float: 'left', marginLeft: 15, marginBottom: 15 }}
          >
            {!image.none
              ? (<img
                  src={image.url ? image.url : NoPhotoJob}
                  style={{ width: 70, height: 70, objectFit: 'cover' }}
                  border={0}
                  alt=""
                  onClick={() => handleShowImageModal(image)}
                />
              )
              : (<img
                src={NoPhotoJob}
                style={{ width: 70, height: 70, objectFit: 'cover' }}
                border={0}
                alt=""
              />)
            }

            {image.inspection === IMAGE_STATUS.PENDING && (
              <div className="image-watermark">
                <img className="imgSquare object-fit-cover" src={NonAuth} alt="" width="32" />
              </div>
            )}
          </div>
        ))}
      </div>
      {previewImage && (
        <ImageModal image={previewImage} onClose={handleHideImageModal} />
      )}
    </div>
  );
}
