import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MuiThemeProvider,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import NoAvatarAgent from '../../assets/image/no_agent_photo.png';
import UserDelete from '../../assets/image/delete_user.png';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import AppChat from './ChatAgentManagement/AppChat';
import Util from '../../Util/Util';
import socket from '../../socket';
import { getAllChatRoom } from '../../Api/chatAgentAPI';
import ImageModal from '../../Components/ImageModal';
import { accessAndPassDataIframe } from '../../Util/utils';
import CloseIcon from '@material-ui/icons/Close';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const recordStyle = {
  display: 'grid',
  gridTemplateColumns: '31% 31% 8% 20% 10%',
};

export default function AgentWorkPageIndividual() {
  const { id: agentId } = useParams();
  const location = useLocation();
  const inputEl = useRef(null);
  const [filter, setFilter] = useState({ pageIndex: 1, pageSize: 1000 });
  const [listConversations, setListConversations] = useState();
  const [listMessages, setListMessages] = useState();
  const [conversationId, setConversationId] = useState();
  const [values, setValues] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [loadMore, setLoadMore] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [isOpeChara, setIsOpeChara] = useState(false);
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [user, setUser] = useState();

  // const dispatch = useDispatch();
  const conversations = useSelector((state) => state.listConversation.items);
  const [agentChatRooms, setAgentChatRooms] = useState([]);

  const getAllChatAgent = async () => {
    try {
      const result = await getAllChatRoom(filter);

      if (result.status === 200) {
        if (agentId) {
          const chatRoomListAgent = result.data.data.filter(
            (room, index) => agentId === room.receiverId,
          );
          setAgentChatRooms(chatRoomListAgent);
          return;
        }
        setAgentChatRooms(result.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllChatAgent();
  }, []);

  useEffect(() => {
    if (conversations) {
      const workPage = conversations?.map((message, index) => {
        if (message.sender.charaStatus) {
          const source = message.sender;
          const target = message.receiver;
          message.sender = target;
          message.receiver = source;
        }

        return message;
      });
      workPage.sort(
        (a, b) =>
          b.lastMessage - a.lastMessage ||
          b.messages[0]?.createdDate - a.messages[0]?.createdDate,
      );
      setListConversations(workPage);
    }
  }, [conversations]);

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom && !loadMore) {
      setFilter({ ...filter, pageSize: filter.pageSize + 1 });
      setLoadMore(bottom);
    }
  };

  const handleListItemClick = (
    sender,
    receiver,
    messages,
    index,
    conversationId,
  ) => {
    setSelectedIndex(index);
    setValues((values) => ({
      ...values,
      index,
      sender,
      receiver,
    }));
    let messagesBaseDate = _.groupBy(messages, function (message) {
      return moment(Number(message.createdDate)).format('YYYY-MM-DD');
    });

    setListMessages(messagesBaseDate);
    setConversationId(conversationId);

    socket.emit('joinAgentConversation', { conversationId });
  };

  const scrollToRef = (ref) => {
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (listMessages) {
      scrollToRef(inputEl);
    }
  }, [listMessages]);

  const showImage = (imageUrl) => {
    setImageUrl(imageUrl);
  };

  const handleMyProfile = (user) => {
    setUser(user)
    setMyProfVisible(true);
    localStorage.setItem(
      'userId',
      user?.id,
    )
  };

  const accessAndPassData = () =>  {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  }

  return (
    <>
     {/*応募者詳細*/}
     <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={myProfVisible}
        onClose={() => setMyProfVisible(false)}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          応募者詳細
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => setMyProfVisible(false)}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ marginBottom: 30, height: '80vh', overflow: 'hidden' }}>
          {user &&
            <iframe title="user-preview"
                    id="iframeId"
                    onLoad={accessAndPassData}
                    style={{ border: 'none', width: '100%', height: '100%' }}
                  src={user && Util.myProfSrc(user)}></iframe>
          }
        </DialogContent>
      </Dialog>
      <div>
        {location.pathname.includes('admin_agent') && (
          <div
            style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}
            className="mt-3"
          >
            <Link
              to={location?.state?.prevPath}
              target="_self"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className="btn-blue"
                style={{
                  margin: 'auto',
                  width: 'auto',
                  color: 'white',
                }}
                variant="contained"
              >
                前の画面に戻る
              </Button>
            </Link>
          </div>
        )}
        <MuiThemeProvider theme={tableNewTheme}>
          <Row>
            <Col xs={6} className="mt-3">
              <Row>
                <Col xs={12}>
                  <div className="container-conversation">
                    <div className="list-conversation" onScroll={handleScroll}>
                      <List component="nav" className="item-head">
                        <ListItem style={recordStyle}>
                          <div style={{ paddingLeft: 50 }}>ユーザー</div>
                          <div style={{ paddingLeft: 72 }}>会社名</div>
                          <div style={{ textAlign: 'start' }}>未返信</div>
                          <div style={{ marginLeft: 10, textAlign: 'start' }}>
                            最終チャット
                          </div>
                        </ListItem>
                      </List>
                      {agentChatRooms &&
                        agentChatRooms?.map((item, index) => (
                          <List
                            component="nav"
                            className="item-content"
                            key={index}
                          >
                            <ListItem style={recordStyle}>
                              <div className="user" style={{ width: '100%' }}>
                                <div
                                  onClick={
                                    () => handleMyProfile(item?.sender)
                                  }
                                  style={{ cursor: 'pointer'}}
                                >
                                  <img
                                    alt="Avatar"
                                    src={
                                      item?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                      item?.sender?.avatar?.url || NoPhoto}
                                    className="avatar"
                                  />
                                </div>
                                <ListItemText primary={item?.sender?.nickName} />
                              </div>

                              <div
                                className="user d-flex d-justify-start"
                                style={{ width: '100%' }}
                              >
                                <SyncAltIcon className="icon-transfer" />
                                  <img
                                    alt="Avatar"
                                    src={item?.receiver?.avatar?.url || NoAvatarAgent}
                                    className="avatar"
                                  />
                                <div
                                  className="user"
                                  style={{ flex: '1 1 auto' }}
                                >
                                  <span className="user-other">
                                    {item?.receiver?.nickName
                                      ? `${item?.receiver?.nickName}`
                                      : `サポート事務局`}
                                  </span>
                                </div>
                              </div>

                              <div style={{ textAlign: 'start' }}>
                                {' '}
                                {!item.lastMessage ? '無し' : '有り'}{' '}
                              </div>
                              <div style={{ marginLeft: 10, textAlign: 'start' }}>
                                {Util.timestampToDate(
                                  item.messages[0]?.createdDate,
                                  'YYYY/MM/DD',
                                )}
                                <br />
                                {Util.timestampToDate(
                                  item.messages[0]?.createdDate,
                                  'HH:mm',
                                )}
                              </div>

                              <div style={{ textAlign: 'end', position: 'relative' }}>
                                <Button
                                  style={{
                                    margin: 'auto',
                                    width: 'auto',
                                    backgroundColor:
                                      selectedIndex === index
                                        ? 'rgb(0, 90, 193)' : '#58C2FE',
                                    color: 'white',
                                    maxWidth: 24,
                                    minWidth: 24,
                                  }}
                                  variant="contained"
                                  onClick={() =>
                                    handleListItemClick(
                                      item?.sender,
                                      item?.receiver,
                                      item?.messages,
                                      index,
                                      item.id,
                                    )
                                  }
                                >
                                  <ArrowForwardIosIcon className="icon-transfer" />
                                </Button>
                                {(!item.messages[0].replied && item.messages[0].senderId !== agentId) && 
                                  <div
                                    style={{ width: 10,
                                    height: 10,
                                    borderRadius: '50%' ,
                                    backgroundColor: 'red',
                                    position: 'absolute',
                                    top: 2,
                                    right: -3 }} >
                                  </div>}
                                </div>
                            </ListItem>
                          </List>
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col
              xs={6}
              className={['mt-3', conversationId ? 'd-wrapper-conversation' : '']}
            >
              <div className={[conversationId ? 'container-conversation' : '']}>
                <div className="conversation-content">
                  {values.sender && (
                    <React.Fragment>
                      <div className="d-head-chat timebar">
                        <div className="flexCenter">
                          <ListItemAvatar>
                            <Avatar
                              alt="Avatar"
                              src={
                                values?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                values.sender.avatar?.url ?? NoPhoto}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            className="d-title"
                            primary={values.sender.nickName}
                          />
                          <SyncAltIcon style={{ margin: '0 10px' }} />
                          <ListItemAvatar>
                            <Avatar
                              alt="Avatar"
                              src={values.receiver.avatar?.url ?? NoPhoto}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            className="d-title"
                            primary={values.receiver.nickName}
                          />
                        </div>
                      </div>
                      {conversationId && (
                        <AppChat
                          conversationId={conversationId}
                          values={values}
                          viewImage={showImage}
                          listMessages={listMessages}
                          setListMessages={setListMessages}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {imageUrl && (
            <ImageModal
              image={{ url: imageUrl }}
              onClose={() => setImageUrl(null)}
            />
          )}
        </MuiThemeProvider>
      </div>
    </>
  );
}
