import { handleActions } from 'redux-actions';
import Operator from '../Types/operator';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
  totalRow: 0,
};

const actions = {
  [Operator.LOAD_OPERATOR]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [Operator.LOAD_OPERATOR_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.operator,
    totalRow: action.payload.totalRow,
    loading: false,
    success: false,
  }),
  [Operator.LOAD_OPERATOR_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
};
export default handleActions(actions, initialState);
