import React from 'react';
import { toast } from 'react-toastify';
import { getPointUsageDelete } from '../../../Api/pointUsageApi';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';

function RemovePointLogModal(payload) {
  const { visible, pointUsageId, onClose, reload, onCloseDetail } = payload;

  const handleSubmit = async () => {
    if (!pointUsageId) {
      return;
    }

    await getPointUsageDelete(pointUsageId).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        onCloseDetail();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default RemovePointLogModal;
