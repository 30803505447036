import maintenance from '../socket/maintenance';

const socketMaintenancePage = () => {
  maintenance.emit('init-admin-data-session', { initAdmin: 1 });

  maintenance.on('init-admin-data-session', (data) => {
    if (data?.force && data.force === 'page') {
      window.location.reload();
    }
  });
};

export default socketMaintenancePage;
