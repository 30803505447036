import { call, put, takeLatest } from 'redux-saga/effects';
import Reports from '../Types/reports';
import { getReports } from '../../Api/reportApi';
import { loadReportsFail, loadReportsSuccess } from '../Actions/reports';

function* handleLoadReports(action) {
  try {
    const response = yield call(getReports, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadReportsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadReportsFail(error));
  }
}

export default function* reportsSaga() {
  yield takeLatest(Reports.LOAD_REPORTS, handleLoadReports);
}
