import React, { useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Util from '../Util/Util';
import {
  TEXT_HTML_ADMIN_OPE_WORK_PAGE_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL,
  TEXT_HTML_CHAT_AGENT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL,
  TEXT_HTML_CHAT_SUPPORT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL, TEXT_HTML_MANAGEMENT_AGENT_UPLOAD_IMAGE_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_JOB_UPLOAD_IMAGE_LINK_MODAL, TEXT_HTML_MANAGEMENT_OPE_UPLOAD_IMAGE_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_USER_EDIT_UPLOAD_IMAGE_LINK_MODAL,
} from '../Common/modal_text_info';

function CropperDialog({ image, onCrop, onClose, type, ...props }) {
  const cropperRef = useRef(null);
  const [uploading, setUploading] = useState(false);

  const cropper = {
    image,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
  };

  const handleCrop = () => {
    const canvas = cropperRef.current.cropper.getCroppedCanvas();
    canvas.toBlob((blob) => {
      const filename = new Date().getTime() + '.jpg';
      setUploading(true);
      Util.firebaseUpload({ ref: 'images', filename, blob, dir: props.dir }).then((url) => {
        setUploading(false);
        onCrop(url);
      });
    }, 'image/jpeg');
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'d-modal-wrapper'}
    >
      <DialogTitle id="alert-dialog-title" className={'d-title-modal-custom'}>画像を切り取るCropperDialog</DialogTitle>
      {type && (
        type === 'user_management_user_edit'
        || type === 'user_management_user_edit_short'
        || type === 'user_management_user_register'
      )
      && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_USER_EDIT_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && type === 'job_management_image_job' && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_JOB_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && type === 'ope_work_page_management_app_chat' && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_ADMIN_OPE_WORK_PAGE_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && type === 'chat_support_management_app_chat' && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_CHAT_SUPPORT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && type === 'chat_agent_management_app_chat' && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_CHAT_AGENT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && type === 'ope_management_image_list' && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_OPE_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}
      {type && (type === 'agent_management_image_list_create' || type === 'agent_edit_image_list') && (
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_AGENT_UPLOAD_IMAGE_LINK_MODAL }}></div>
        </div>
      )}

      <DialogContent>
        <div className="cropper">
          <div className="crop-container">
            <Cropper
              ref={cropperRef}
              src={cropper.image}
              // aspectRatio={cropper.aspect}
              initialAspectRatio={1}
              guides={false}
              zoomable={false}
              scalable={true}
              viewMode={2}
              style={{ maxHeight: 700, width: '100%' }}
            />
          </div>
          {uploading && <LinearProgress />}
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              onClick={() => handleCrop()}
              variant="contained"
              color="primary"
            >
              アップロード
            </Button>
            <Button
              onClick={() => onClose()}
              variant="contained"
              color="default"
            >
              キャンセル
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CropperDialog;
