import React, { useState } from 'react';
import Nophoto from '../../../assets/image/no_user_photo.png';
import NonAuth from '../../../assets/image/non_auth.png';
import _ from 'lodash';
import ImageModal from '../../../Components/ImageModal';
import { IMAGE_STATUS, IMAGE_TYPE } from '../../../Config/listConstants';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';

function ImageList({ user }) {
  const avatar = user.avatar || {};
  const subImagesRaw = user.images;
  _.remove(subImagesRaw, (x) => x.type === IMAGE_TYPE.AVATAR);

  // orderBy Asc
  let subImagesObj = {};
  for (let i = 0; i < subImagesRaw.length; i++) {
    subImagesObj[subImagesRaw[i].subImgNumber] = subImagesRaw[i];
  }

  const subImages = [];
  Array(5).fill(1).forEach((ele, index) => {
    if (subImagesObj[(index + 1)]) {
      subImages.push({ ...subImagesObj[(index + 1)], none: false });
    } else {
      subImages.push({
        none: true,
      });
    }
  });

  const [previewingImage, setPreviewingImage] = useState(null);

  const handleShowImageModal = (image) => {
    setPreviewingImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };
  return (
    <div className="clearfix">
      <div style={{ position: 'relative', float: 'left' }} className={'d-main-image'}>
        {
          user.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ?
          <img
          src={UserDelete}
          style={{ width: 160, height: 160, objectFit: 'cover' }}
          border={0}
          alt=""/> :
          <>
            <img
              className="object-fit-cover"
              src={avatar.url ? avatar.url : Nophoto}
              style={{ width: 160, height: 160, objectFit: 'cover' }}
              border={0}
              alt=""
              onClick={() => handleShowImageModal(avatar)}
            />
            {avatar.inspection === IMAGE_STATUS.PENDING && (
              <div className="image-watermark">
                <img className="imgSquare object-fit-cover" src={NonAuth} alt="" />
              </div>
            )}
          </>
        }
      </div>
      {user.memberStatus !== MEMBER_USER_STATUS.IN_ACTIVE && 
        <div style={{ display: 'flex', flexWrap: 'wrap' }} className={'sub-images'}>
          {subImages.map((image, index) => (
            <div
              key={index}
              style={{ position: 'relative', float: 'left', marginLeft: 15, marginBottom: 15 }}
            >
              {!image.none ? (<img
                  className="object-fit-cover"
                  src={image.url ? image.url : Nophoto}
                  style={{ width: 70, height: 70, objectFit: 'cover' }}
                  border={0}
                  alt=""
                  onClick={() => handleShowImageModal(image)}
                />)
                : (<img
                  src={Nophoto}
                  style={{ width: 70, height: 70, objectFit: 'cover' }}
                  border={0}
                  alt=""
                />)
              }
              {image.inspection === IMAGE_STATUS.PENDING && (
                <div className="image-watermark">
                  <img className="imgSquare object-fit-cover" src={NonAuth} alt="" width="32" />
                </div>
              )}
            </div>
          ))}
        </div>
      }
      {previewingImage && (
        <ImageModal image={previewingImage} onClose={handleHideImageModal} />
      )}
    </div>
  );
}

export default ImageList;
