import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import ReportListItem from './ReportListItem';
import SortArrows from '../../Components/SortArrows';
import ReportDetailModal from './ReportDetailModal';
import { Card } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

function ReportList(props) {
  const {
    reports = [],
    pageIndex,
    pageSize,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    reload,
    labelDb
  } = props;

  const [reportDetail, setReportDetail] = useState(null);

  const handleClickDetail = (report) => {
    setReportDetail(report);
  };

  const handleClose = () => {
    setReportDetail(null);
  };
  return (
    <div>
      {reportDetail ? (
        <ReportDetailModal
          onClose={handleClose}
          report={reportDetail}
          reload={reload}
          labelDb={labelDb}
        />
      ) : null}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card
          className="scroll-x-on-top"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="reporter.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell align="center">画像</TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="reporter.nickName"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell align="center">性別</TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="target.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell align="center">画像</TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="target.nickName"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell align="center">性別</TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="報告内容"
                    name="report.content"
                    onClick={onSort}
                  />
                </TableCell>

                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="report.createdDate"
                    onClick={onSort}
                    defaultName="report.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {reports.map((report, index) => (
                <ReportListItem
                  key={report.id}
                  report={report}
                  no={(pageIndex - 1) * pageSize + index + 1}
                  handleClickDetail={() => handleClickDetail(report)}
                />
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default ReportList;
