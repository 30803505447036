import React from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { deleteHelpById } from '../../Api/helpAPI';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function HelpCmsRemoveDialog({ visible, onClose, id, onSuccess }) {
  const handleSubmit = async () => {
    if (!id) {
      return;
    }
    await deleteHelpById(id).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onSuccess();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={"d-modal-wrapper"}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}/>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '1rem 0' }}>削除してもよろしいですか？</div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onClose}
                  variant="contained"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className="btn-custom btn-blue ml-2"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default HelpCmsRemoveDialog;
