import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import { loadChatMessage } from '../../../Redux/Actions/messageChat';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Util from '../../../Util/Util';
import { Card } from '@material-ui/core';
import { themeChatDetail } from '../theme_modal';
import { TEXT_HTML_CHAT_DETAIL_MODAL } from '../../../Common/modal_text_info';
import {
  convertHiddenStatusText,
  convertInspectionStatusText,
  convertReadStatusText,
  convertRepliedStatusText,
} from '../../../Util/utils';
import { CHAT_MESSAGE_TYPE } from '../../../Common/constant';

const pageSize = 10;

function ChatDetailModal({ visible, onClose, conversationId, sender, receiver, labelDb }) {
  const [pageIndex, setPageIndex] = useState(1);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.chatMessage.loading);
  const messages = useSelector((state) => state.chatMessage.items);
  const totalRow = useSelector((state) => state.chatMessage.totalRow);

  useEffect(() => {
    dispatch(loadChatMessage({ pageIndex, pageSize, conversationId }));
  }, [dispatch, pageIndex, conversationId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };
  const checkUser = (senderId) => {
    if (senderId === sender?.id) {
      return true;
    }
    return false;
  };
  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={themeChatDetail}>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-チャットログ個別
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_CHAT_DETAIL_MODAL }}></div>
          </div>

          <DialogContent className={'table-modal-wrapper'}>
            <Card className="scroll-x-on-top">
              <Table padding="checkbox" className="table mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '5%' }}>No</TableCell>
                    <TableCell style={{ width: '15%' }}>日時</TableCell>
                    <TableCell style={{ width: '5%' }}>既読</TableCell>
                    <TableCell style={{ width: '5%' }}>返信</TableCell>
                    <TableCell style={{ width: '5%' }}>×表示</TableCell>
                    <TableCell style={{ width: '5%' }}>×認証</TableCell>
                    <TableCell style={{ width: '20%' }}>
                      {sender?.nickName}
                    </TableCell>
                    <TableCell>{receiver?.nickName}</TableCell>
                  </TableRow>
                  {messages.map((message, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>
                        {(pageIndex - 1) * pageSize + index + 1}
                      </TableCell>
                      <TableCell className="text-muted">
                        {Util.timestampToDate(message.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                      </TableCell>
                      <TableCell>{convertReadStatusText(+message?.read)}</TableCell>
                      <TableCell>{convertRepliedStatusText(+message?.replied)}</TableCell>
                      <TableCell>{convertHiddenStatusText(+message?.hidden)}</TableCell>
                      <TableCell>{convertInspectionStatusText(+message?.inspection)}</TableCell>
                      <TableCell>
                        {checkUser(message.senderId)
                          ? (message.type === CHAT_MESSAGE_TYPE.TEXT
                              ? <div style={{ whiteSpace: 'pre-line' }}>{message.content}</div>
                              : <img className="imgSquare object-fit-cover"
                                   style={{ width: 30, height: 30 }}
                                   src={message.content}
                                   alt=""
                              />
                          ) : null}
                      </TableCell>
                      <TableCell>
                        {!checkUser(message.senderId)
                          ? (message.type === CHAT_MESSAGE_TYPE.TEXT
                              ? <div style={{ whiteSpace: 'pre-line' }}>{message.content}</div>
                              : <img className="imgSquare object-fit-cover"
                                   style={{ width: 30, height: 30 }}
                                   src={message.content}
                                   alt=""
                              />
                          ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>

            <center>
              <Pagination
                className={'d-page-wrapper'}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default ChatDetailModal;
