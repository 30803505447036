import { handleActions } from 'redux-actions';
import Character from '../Types/character';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
};

const actions = {
  [Character.LOAD_CHARACTER]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [Character.LOAD_CHARACTER_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    success: false,
  }),
  [Character.LOAD_CHARACTER_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
};
export default handleActions(actions, initialState);
