import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import { loadRootResourceItemJob } from '../../Redux/Actions/resourceItemJob';
import NameItemJobList from './NameItemJobList';

function Index() {
  const loadingRoot = useSelector((state) => state.resourceItemJob.loadingRoot);
  const rootResource = useSelector((state) => state.resourceItemJob.dataRoot);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRootResourceItemJob());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loadingRoot || false} />
      <NameItemJobList rootResource={rootResource} loading={loadingRoot} />
    </div>
  );
}

export default Index;
