import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Label, Row, Card } from 'reactstrap';
import { DISPLAY_CMS_UI_TYPE } from '../../Common/constant';
import { getByIdMemberAdmin } from '../../Redux/Actions/memberAdmin';
import RemoveModal from './RemoveModal';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';
import { convertEnvInfo } from '../../Util/utils';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const btnDangerStyle = {
  marginLeft: 25,
  fontSize: 14,
  fontWeight: 300,
  color: 'white',
  position: 'absolute',
  right: 16,
  bottom: '40%',
};

function MemberAdminDetail() {
  const params = useParams();
  const memberAdminId = params.memberAdminId;
  const envUICms = useSelector((state) => state.envUICms.data);
  const currentAdmin = useSelector((state) => state.admin.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [colorLabel, setColorLabel] = useState('')
  const deleteButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.ADMINISTRATOR_DETAIL_DELETE);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const dispatch = useDispatch();


  useEffect(() => {
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1?.content)
  }, [envInfo]);

  useEffect(() => {
    dispatch(getByIdMemberAdmin(memberAdminId));
  }, [dispatch, memberAdminId]);

  const history = useHistory();
  const redirectPageEditAgent = (id) => {
    history.push(`/administrator/${id}/edit`);
  };

  const admin = useSelector((state) => state.memberAdmin.detail);

  const handleRemoveUser = () => {
    setConfirmVisible(true);
  };

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const memberTypeToText = (type) => {
    switch (type) {
      case 0:
        return 'システム管理者';
      case 1:
        return '上級管理者';
      default:
        return 'sys_admin';
    }
  };

  return (
    <div>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          memberAdminId={memberAdminId}
        />
      )}
      {admin && (
        <MuiThemeProvider theme={customTheme}>
          <div
            id="max-width-dialog-title"
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div>
              <Button
                variant="contained"
                className="btn-custom btn-blue"
                onClick={() => redirectPageEditAgent(admin.id)}
              >
                編集
              </Button>
            </div>
          </div>
          <Row>
            <Col xs={4}>
              <Card body className="mb-6">
                <Row>
                  <Col xs={12}>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>MySQL ID</p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {admin.id}
                      <hr style={stylehrright} />
                    </Label>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        メールアドレス（重複NG）
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {admin.emailAddressMain}
                      <hr style={stylehrright} />
                    </Label>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={8}>
              <Card body className="mb-6">
                <Row>
                  <Col xs={6}>
                    <Row>
                      <Label style={style} xs={6}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>管理者名</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={6}>
                        {admin.nickName}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={6}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>パスワード（数字6桁）</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={6}>
                        {admin.password}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Col>

                  <Col xs={6}>
                    <Row>
                      <Label style={style} xs={6}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>会員種類</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={6}>
                        {memberTypeToText(admin?.memberType)}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {(currentAdmin?.memberType === 0 ||
            deleteButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button className="btn-red" style={btnDangerStyle} onClick={handleRemoveUser}>
                {deleteButton.content}
              </Button>
            )}
        </MuiThemeProvider>
      )}
    </div>
  );
}

export default MemberAdminDetail;
