const NameRank = {
  LOAD_NAME_RANK: 'LOAD_NAME_RANK',
  LOAD_NAME_RANK_SUCCESS: 'LOAD_NAME_RANK_SUCCESS',
  LOAD_NAME_RANK_FAIL: 'LOAD_NAME_RANK_FAIL',

  LOAD_LIST_NAME_RANK: 'LOAD_LIST_NAME_RANK',
  LOAD_LIST_NAME_RANK_SUCCESS: 'LOAD_LIST_NAME_RANK_SUCCESS',
  LOAD_LIST_NAME_RANK_FAIL: 'LOAD_LIST_NAME_RANK_FAIL',

  UPDATE_NAME_RANK: 'UPDATE_NAME_RANK',
  UPDATE_NAME_RANK_SUCCESS: 'UPDATE_NAME_RANK_SUCCESS',
  UPDATE_NAME_RANK_FAIL: 'UPDATE_NAME_RANK_FAIL',
};
export default NameRank;
