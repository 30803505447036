const Resource = {
  LOAD_STATE_AGENT: 'LOAD_STATE_AGENT',
  LOAD_STATE_AGENT_SUCCESS: 'LOAD_STATE_AGENT_SUCCESS',
  LOAD_STATE_AGENT_FAIL: 'LOAD_STATE_AGENT_FAIL',

  LOAD_ALL_AREA_AGENT: 'LOAD_ALL_AREA_AGENT',
  LOAD_ALL_AREA_AGENT_SUCCESS: 'LOAD_ALL_AREA_AGENT_SUCCESS',
  LOAD_ALL_AREA_AGENT_FAIL: 'LOAD_ALL_AREA_AGENT_FAIL',

  UPDATE_STATE_AGENT: 'UPDATE_ROOT_STATE_AGENT',
  UPDATE_STATE_AGENT_SUCCESS: 'UPDATE_ROOT_STATE_AGENT_SUCCESS',
  UPDATE_STATE_AGENT_FAIL: 'UPDATE_ROOT_STATE_AGENT_FAIL',
};
export default Resource;
