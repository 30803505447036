import {
  Button,
  createMuiTheme,
  FormGroup,
  FormLabel,
  MuiThemeProvider,
  TextField,
} from '@material-ui/core';
import RadioGroup from '../../Components/RadioGroup';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMemberAdmin } from '../../Api/memberAdminAPI';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import {
  CREATE_USER_TYPE,
  REGEX_ALPHABET,
  REGEX_EMAIL,
} from '../../Common/constant';
import {
  checkFullWidth,
  convertEnvInfo,
  validateFullWidthVsAlphabet,
} from '../../Util/utils';
import { checkExistingUserBeforeCreate } from '../../Api/userAPI';

const memberTypeOptions = [
  {
    label: 'システム管理者',
    value: 0,
  },
  {
    label: '上級管理者',
    value: 1,
  },
];

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [colorLabel, setColorLabel] = useState('');
  const [colorLabelDialog, setColorLabelDialog] = useState('');

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
    setColorLabelDialog(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
  }, [envInfo]);

  const redirectPageList = (id) => {
    history.push(`/administrator_list`);
  };

  const [editingAdmin, setEditingAgent] = useState({});

  const handleSubmit = async () => {
    let error = false;
    if (!editingAdmin.nickName) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberAdmin.name);
      error = true;
    }
    if (!editingAdmin.emailAddressMain) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.email);
      error = true;
    } else if (
      editingAdmin.emailAddressMain &&
      !REGEX_EMAIL.test(editingAdmin.emailAddressMain)
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
      error = true;
    }

    if (!editingAdmin.password) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
      error = true;
    } else if (
      editingAdmin.password &&
      (editingAdmin.password.length < 8 ||
        editingAdmin.password.length > 10 ||
        !REGEX_ALPHABET.test(editingAdmin.password) ||
        checkFullWidth(editingAdmin.password))
    ) {
      error = true;
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
    }

    if (
      editingAdmin.memberType == null ||
      editingAdmin.memberType === undefined
    ) {
      error = true;
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberType);
    }

    if (editingAdmin.emailAddressMain) {
      try {
        const { emailAddressMain } = editingAdmin;
        const { data: existingResult } = await checkExistingUserBeforeCreate({
          role: CREATE_USER_TYPE.MEMBER_ADMIN,
          emailAddressMain,
        });
        if (existingResult?.errMsgs?.length) {
          error = true;
          existingResult.errMsgs.forEach((m) => {
            toast.error(m);
          });
        }
      } catch (err) {
        toast.warn(
          '管理者ターデータがDB上に存在するかどうかを確認できません(WebWarning)',
        );
      }
    }

    if (!error) {
      setOpen(true);
    }
  };

  const onChange = (e) => {
    const createAdmin = _.set(editingAdmin, e.target.name, e.target.value);
    setEditingAgent({ ...createAdmin });
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        editingAdmin[name] = '';
      } else {
        editingAdmin[name] = value;
      }
      setEditingAgent({ ...editingAdmin });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateMemberAdminSubmit = () => {
    const createData = _.pick(editingAdmin, [
      'id',
      'nickName',
      'emailAddressMain',
      'password',
      'memberType',
    ]);
    createMemberAdmin(createData)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          redirectPageList();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const renderMemberTypeOptionsText = (number) => {
    const obj = memberTypeOptions.find((e) => e.value === number);
    if (!obj) return '';
    return obj.label;
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <MuiThemeProvider theme={customTheme}>
          <div className="align">
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={() => redirectPageList()}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue mr-3"
              variant="contained"
              onClick={handleSubmit}
            >
              登録
            </Button>
          </div>
          <Row>
            <Col xs={4}>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Col xs={12} style={{ marginTop: 10 }}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>
                          メールアドレス（重複NG）
                        </FormLabel>
                        <TextField
                          id="standard-required"
                          type="text"
                          name="emailAddressMain"
                          onChange={onChange}
                          className="w-100"
                        />
                      </FormGroup>
                    </Col>
                    <Label xs={12}></Label>
                    <Label xs={12}></Label>
                    <Label xs={12}></Label>
                    <Label xs={12}></Label>
                    <Label xs={12}></Label>
                    <Label xs={12}></Label>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={8}>
              <Card body className="mb-4">
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={6}>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>
                            {labelDb?.label_nickName?.content}
                          </FormLabel>
                          <FormGroup row>
                            <TextField
                              id="standard-required"
                              type="text"
                              name="nickName"
                              onChange={onChange}
                              className="w-100"
                            />
                          </FormGroup>
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>
                            パスワード（英数字8文字～10文字）
                          </FormLabel>
                          <FormGroup row>
                            <TextField
                              id="standard-required"
                              type="text"
                              autoComplete={'new-password'}
                              name="password"
                              value={editingAdmin?.password}
                              onChange={(e) =>
                                validatePassword(e.target.value, e.target.name)
                              }
                              className="w-100"
                            />
                          </FormGroup>
                        </Label>
                      </Col>
                      <Col xs={6}>
                        <span className={'d-box-radio-group'}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>会員種類
                          </FormLabel>
                          <RadioGroup
                            name="memberType"
                            options={memberTypeOptions}
                            onChange={onChange}
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </MuiThemeProvider>
      </div>

      {/* Modal */}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogContent>
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <div
            style={{
              position: 'absolute',
              top: 6,
              right: 0,
              width: 'auto',
              bottom: 10,
              paddingRight: 50,
            }}
          >
            <center>
              <Button
                className="btn-red"
                style={{
                  padding: '4px 20px',
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'white',
                  marginRight: 20,
                }}
                variant="contained"
                onClick={handleClose}
              >
                戻る
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleCreateMemberAdminSubmit}
              >
                登録
              </Button>
            </center>
          </div>
          <Row>
            <Col xs={4}>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabelDialog }} xs={7}>
                    メールアドレス（重複NG）
                  </Label>
                  <Label xs={5}>{editingAdmin.emailAddressMain}</Label>
                </Row>
              </Card>
            </Col>
            <Col xs={8}>
              <Card body className="mb-4">
                <Row>
                  <Col xs={6}>
                    <Label style={{ color: colorLabelDialog }} xs={6}>
                      ニックネーム（DB）
                    </Label>
                    <Label xs={6}>{editingAdmin.nickName}</Label>
                    <Label style={{ color: colorLabelDialog }} xs={6}>
                      パスワード
                    </Label>
                    <Label xs={6}>{editingAdmin.password}</Label>
                  </Col>
                  <Col xs={6}>
                    <Label style={{ color: colorLabelDialog }} xs={6}>
                      会員種類
                    </Label>
                    <Label xs={6}>
                      {renderMemberTypeOptionsText(+editingAdmin.memberType)}
                    </Label>
                    <Label xs={6}>&nbsp;</Label>
                    <Label xs={6}>&nbsp;</Label>
                    <Label xs={6}>&nbsp;</Label>
                    <Label xs={6}>&nbsp;</Label>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Index;
