import { handleActions } from 'redux-actions';
import NameRank from '../Types/nameRank';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
  totalRow: 0,
  dataListRank: null,
  loadingListRank: false,
  totalRowListRank: 0,
};

const actions = {
  [NameRank.LOAD_NAME_RANK]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [NameRank.LOAD_NAME_RANK_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    loading: false,
    success: false,
    totalRow: action.payload.totalRow,
  }),
  [NameRank.LOAD_NAME_RANK_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [NameRank.LOAD_LIST_NAME_RANK]: (state) => ({
    ...state,
    loadingListRank: true,
    success: false,
  }),
  [NameRank.LOAD_LIST_NAME_RANK_SUCCESS]: (state, action) => ({
    ...state,
    dataListRank: action.payload.data,
    loadingListRank: false,
    success: false,
    totalRowListRank: action.payload.totalRow,
  }),
  [NameRank.LOAD_LIST_NAME_RANK_FAIL]: (state) => ({
    ...state,
    dataListRank: null,
    loadingListRank: false,
  }),
  [NameRank.UPDATE_NAME_RANK]: (state) => ({
    ...state,
    updating: true,
  }),
  [NameRank.UPDATE_NAME_RANK_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [NameRank.UPDATE_NAME_RANK_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
