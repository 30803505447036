import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import { loadEnvInfo } from '../../Redux/Actions/envInfo';
import EnvInfoSetting from './EnvInfoSetting';
import './style.css'

const pageSize = 1000;
const mutating = false;

function EnvInfo() {
  const [pageIndex] = useState(1);
  const loading = useSelector((state) => state.envInfo.loading);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadEnvInfo({ pageSize, pageIndex }));
  }, [dispatch, pageIndex]);


  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvInfoSetting
        loading={loading}
        data={envInfo}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export default EnvInfo;
