import React, { forwardRef, useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import { FormLabel } from '@material-ui/core';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

let hour = [];
for (let index = 0; index < 24; index++) {
  hour.push({
    value: index,
    displayValue: index < 10 ? `0${index}` : `${index}`,
  });
}

const ReactDateTimePicker = forwardRef(function (
  { name, title, titleStyle, timestamp, onChange },
  ref,
) {
  const [dateState, setDateState] = useState(
    moment(+timestamp).format('YYYY-MM-DD'),
  );
  const [hourState, setHourState] = useState(moment(+timestamp).hour());
  const [minuteState, setMinuteState] = useState(
    moment(+timestamp).minute().toString(),
  );

  const handleDate = (date) => {
    const value = moment(date).format('YYYY-MM-DD');
    setDateState(value);

    let time = moment(`${value} ${hourState}:${minuteState}:00`).valueOf();
    onChange(name, time);
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourState(value);
    let time = moment(`${dateState} ${value}:${minuteState}:00`).valueOf();
    onChange(name, time);
  };

  const handleMinute = (e) => {
    let value = e.target.value;
    setMinuteState(value);
    let time = moment(`${dateState} ${hourState}:${value}:00`).valueOf();
    onChange(name, time);
  };

  return (
    <React.Fragment>
      <FormGroup row>
        <Col xs={12}>
          <FormLabel component="legend" style={titleStyle}>
            {title}
          </FormLabel>
        </Col>

        <Col className="datePicker" xs={12}>
          <ReactDatePicker
            className="date"
            selected={new Date(parseInt(timestamp))}
            onChange={(e) => handleDate(e)}
            locale="ja"
            dateFormat="yyyy-MM-dd"
          />
          <select
            value={hourState}
            onChange={(e) => handleHour(e)}
            className="hour"
          >
            {hour.map((e) => (
              <option key={e.value} value={e.value}>
                {e.displayValue}
              </option>
            ))}
          </select>
          <select
            value={minuteState}
            onChange={(e) => handleMinute(e)}
            className="minus"
          >
            {Array.from({ length: 60 }, (_, i) => i).map((e) => (
              <option key={e} value={e}>
                {e.toLocaleString('ja-JP', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </option>
            ))}
          </select>
        </Col>
      </FormGroup>
    </React.Fragment>
  );
});

export default ReactDateTimePicker;
