import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoading from '../../Components/CircularLoading';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadInformations } from '../../Redux/Actions/information';
import Pagination from '../../Components/Pagination';
import LoadingBar from '../../Components/LoadingBar';
import Nophoto from '../../assets/image/no_user_photo.png';
import moment from 'moment';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import InformationAddEditPopup from './InformationAddEditPopup';
import InformationDetail from './InformationDetail';
import { Link } from 'react-router-dom';

const pageSize = 50;

function InformationList() {
  const loading = useSelector((state) => state.informations.loading);
  const infos = useSelector((state) => state.informations.items);
  const totalRow = useSelector((state) => state.informations.totalRow);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'information.createdDate',
    orderBy: 'DESC',
    sort: { 'information.created_date': 'DESC' },
  });
  const [addPopupVisible, setAddPopupVisible] = useState(false);
  const [infoDetail, setInfoDetail] = useState(null);
  const [infoEdit, setInfoEdit] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInformations({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadInformations({ ...filter, pageIndex, pageSize }));
  };

  const refreshList = () => {
    dispatch(loadInformations({ pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };

  const handleClickAddPopup = () => {
    setAddPopupVisible(true);
  };

  const handleClose = () => {
    setInfoEdit(null);
    setAddPopupVisible(false);
  };

  const handleDetailClose = () => {
    setInfoDetail(null);
  };

  const handleClickDetail = (info) => {
    setInfoDetail(info);
  };

  const handleClickEdit = (info) => {
    setInfoEdit(info);
  };

  const reload = () => {
    dispatch(loadInformations({ pageIndex: 1, pageSize, ...filter }));
  };

  if (!infos) {
    return;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      {(addPopupVisible || infoEdit) && (
        <InformationAddEditPopup
          visible={(addPopupVisible || infoEdit)}
          onClose={handleClose}
          onUpdate={refreshList}
          dataInfo={(addPopupVisible ? null : infoEdit)}
        />
      )}
      {infoDetail && (
        <InformationDetail
          onClose={handleDetailClose}
          info={infoDetail}
          reload={reload}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            variant="contained"
            className="btn-custom btn-blue mr-3 ml-3"
            onClick={handleClickAddPopup}
          >
            お知らせ-新規登録
          </Button>
        </div>
        <Card className="scroll-x-on-top">
          {loading ? (
            <CircularLoading />
          ) : (
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="タイトル"
                      name="information.title"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-left">本文</TableCell>
                  <TableCell className="text-center">画像</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="×Type"
                      name="information.type"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="information.confirm"
                      text="×Confirm"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="information.display_setting"
                      text="表示設定"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="投稿日"
                      name="information.regist_date"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="登録日"
                      name="information.created_date"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-center">ボタン</TableCell>
                </TableRow>

                {infos.map((info, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>{info?.title}</TableCell>
                    <TableCell>{info?.content}</TableCell>
                    <TableCell className="text-center">
                      <img
                        className="imgSquare object-fit-cover"
                        src={
                          info?.urlImage ? info?.urlImage : Nophoto
                        }
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{info?.type}</TableCell>
                    <TableCell>{info?.confirm}</TableCell>
                    <TableCell>{info?.displaySetting}</TableCell>
                    <TableCell>
                      <p style={{ margin: 0 }}>
                        {moment(Number(_.get(info, 'registDate'))).format('YYYY-MM-DD')}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p style={{ margin: 0 }}>
                        {moment(Number(_.get(info, 'createdDate'))).format('YYYY-MM-DD hh:mm:ss')}
                      </p>
                    </TableCell>
                    <TableCell className="text-center">
                      <div className="d-color-link">
                        <Link to={'#'}
                              onClick={() => handleClickEdit(info)}>
                          編集
                        </Link>
                        <Link to={'#'}
                              className="d-color-link"
                              onClick={() => handleClickDetail(info)}
                              style={{ marginLeft: 15}}>
                          表示
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default InformationList;
