import { createAction } from 'redux-actions';
import PointSetting from '../Types/pointSetting';

export const loadPointSetting = createAction(PointSetting.LOAD_POINT_SETTING);
export const loadPointSettingSuccess = createAction(
  PointSetting.LOAD_POINT_SETTING_SUCCESS,
);
export const loadPointSettingFail = createAction(
  PointSetting.LOAD_POINT_SETTING_FAIL,
);

export const updatePointSetting = createAction(
  PointSetting.UPDATE_POINT_SETTING,
);
export const updatePointSettingSuccess = createAction(
  PointSetting.UPDATE_POINT_SETTING_SUCCESS,
);
export const updatePointSettingFail = createAction(
  PointSetting.UPDATE_POINT_SETTING_FAIL,
);
