import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import Util from '../../../Util/Util';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { loadSalaryChara } from '../../../Redux/Actions/salaryLog';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_SALARY_LOG_MODAL } from '../../../Common/modal_text_info';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';

const pageSize = 10;

function SalatyLogModal({ visible, onClose, userId, labelDb }) {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.pointUser.loading);
  const salaryLog = useSelector((state) => state.salary.items);
  const totalRow = useSelector((state) => state.salary.totalRow);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSalaryChara({ pageIndex, pageSize, userId }));
  }, [dispatch, pageIndex, userId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };


  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading} />
        <>
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              ユーザー詳細-給与ログ
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <div className={'d-title-info-box'}>
              <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_SALARY_LOG_MODAL }}></div>
            </div>

            <DialogContent className={'table-modal-wrapper'}>
              <Card className="scroll-x-on-top">
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>オペNum</TableCell>
                      <TableCell>会社Num</TableCell>
                      <TableCell>給与ポイント</TableCell>
                      <TableCell>種類</TableCell>
                      <TableCell>登録日</TableCell>
                    </TableRow>
                    {salaryLog.map((salary, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>
                          {(pageIndex - 1) * pageSize + index + 1}
                        </TableCell>
                        <TableCell>{salary?.user?.phoneNumber}</TableCell>
                        <TableCell>
                          <center>
                            <img className="object-fit-cover"
                                 style={{
                                   width: 30,
                                   height: 30,
                                   borderRadius: '50%',
                                 }}
                                 src={
                                  salary?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                  salary?.user?.avatar
                                    ? salary?.user?.avatar?.url
                                    : NoPhoto
                                 }
                                 alt=""
                            />
                          </center>
                        </TableCell>
                        <TableCell>{salary?.user?.nickName}</TableCell>
                        <TableCell>
                          {salary?.user?.operator?.operatorNumber}
                        </TableCell>
                        <TableCell>
                          {salary?.user?.agent?.agentNumber}
                        </TableCell>

                        <TableCell>{Util.separator(salary.addPoint)}</TableCell>
                        <TableCell>
                          {Util.convertPointUsageTypeToText(salary.type)}
                        </TableCell>
                        <TableCell className="muted">
                          {Util.timestampToDate(
                            salary.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>

              <center>
                <Pagination
                  className={'d-page-wrapper'}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalRow={totalRow}
                  onPageChange={handlePageChange}
                />
              </center>
            </DialogContent>
          </Dialog>
        </>
      </div>
    </MuiThemeProvider>
  );
}

export default SalatyLogModal;
