import { handleActions } from 'redux-actions';
import NameState from '../Types/state';

const initialState = {
  data: null,
  loading: false,
  totalRow: 0,
  loadingChild: false,
};

const actions = {
  [NameState.LOAD_STATE]: (state) => ({
    ...state,
    loading: true,
  }),
  [NameState.LOAD_STATE_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
  }),
  [NameState.LOAD_STATE_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),

  [NameState.LOAD_ALL_AREA]: (state) => ({
    ...state,
    loadingChild: true,
  }),
  [NameState.LOAD_ALL_AREA_SUCCESS]: (state, action) => ({
    ...state,
    dataChild: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingChild: false,
  }),
  [NameState.LOAD_ALL_AREA_FAIL]: (state) => ({
    ...state,
    dataChild: null,
    loadingChild: false,
  }),
};
export default handleActions(actions, initialState);
