import { createAction } from 'redux-actions';
import PointRate from '../Types/pointRate';

export const loadPointRate = createAction(PointRate.LOAD_POINT_RATE);
export const loadPointRateSuccess = createAction(PointRate.LOAD_POINT_RATE_SUCCESS);
export const loadPointRateFail = createAction(PointRate.LOAD_POINT_RATE_FAIL);

export const updatePointRate = createAction(PointRate.UPDATE_POINT_RATE);
export const updatePointRateSuccess = createAction(PointRate.UPDATE_POINT_RATE_SUCCESS);
export const updatePointRateFail = createAction(PointRate.UPDATE_POINT_RATE_FAIL);
