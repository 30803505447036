import { call, put, takeLatest } from 'redux-saga/effects';
import ChatMessage from '../Types/messageConversation';
import { getChatMessageByConversation } from '../../Api/chatApi';
import { loadChatMessageFail, loadChatMessageSuccess } from '../Actions/messageChat';

function* handleLoadChatMessage(action) {
  try {
    const response = yield call(getChatMessageByConversation, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadChatMessageSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadChatMessageFail(error));
  }
}

export default function* chatMessageSaga() {
  yield takeLatest(ChatMessage.LOAD_MESSAGE_CONVERSATION, handleLoadChatMessage);
}
