import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, MuiThemeProvider, Card } from '@material-ui/core';
import { loadListRawTwilioHistory } from '../../Redux/Actions/listRawDatabase';
import isEmpty from 'lodash/isEmpty';
import Util from '../../Util/Util';
import Pagination from '../../Components/Pagination';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

const pageSize = 50;

function ListRawTwilioHistory() {
  const [filter] = useState({ sort: {} });
  const twilioHistories = useSelector((state) => state.listRawDatabase.twilioHistories);
  const totalRow = useSelector((state) => state.listRawDatabase.totalTwilioHistory);
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    dispatch(loadListRawTwilioHistory({ ...filter, pageIndex: pageIndex, pageSize }));
  }, [dispatch, filter, pageIndex]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  return (
    <>
      <React.Fragment>
        <div className="space-top">&nbsp;</div>
        <div className="space-left">&nbsp;</div>
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top mt-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  {!isEmpty(twilioHistories) &&
                  Object.keys(twilioHistories[0]).map((key) => (
                    <TableCell>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {twilioHistories.map((user, index) => (
                  <TableRow key={user.id} style={{ height: 48 }}>
                    <TableCell>{index + 1}</TableCell>
                    {Object.keys(user).map((key) => (
                      <TableCell>
                        {['lastlogin', 'birthday', 'registerDate'].includes(key)
                          ? Util.timestampToDate(user[key], 'YYYY/MM/DD')
                          : user[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
        <center>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
            onPageChange={handlePageChange}
          />
        </center>
      </React.Fragment>
    </>
  );
}

export default ListRawTwilioHistory;
