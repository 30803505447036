import React, { useEffect, useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import { loadJob } from '../../../Redux/Actions/job';
import { useHistory, useParams } from 'react-router-dom';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { Button, Dialog, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import remove from 'lodash/remove';
import Util from '../../../Util/Util';
import CloseIcon from '@material-ui/icons/Close';
import orderBy from 'lodash/orderBy';
import { accessAndPassDataIframe, convertEnvInfo, convertHiddenStatusText, jobPublishingTypeText } from '../../../Util/utils';
import { IMAGE_TYPE } from '../../../Config/listConstants';
import { copyToClipboard } from '../../JobMemberManagement/common-action';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import RemoveJobModal from './RemoveJobModal';
import ImageJobList from '../../../Components/ImageJobList';
import moment from 'moment';
import { loadResourceItemJob } from '../../../Redux/Actions/resourceItemJob';
import { showResourceItemJob } from '../resource-item';
import { loadAgent } from '../../../Redux/Actions/agent';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

export default function Index() {
  const params = useParams();
  const jobId = params.jobId;
  const history = useHistory();

  const loading = useSelector((state) => state.job.loading);
  const job = useSelector((state) => state.job.data);
  const dispatch = useDispatch();
  const [colorLabel, setColorLabel] = useState('')
  const [subImages, setSubImages] = useState([]);
  const [main, setMain] = useState({});
  const [isPreviewJob, setIsPreviewJob] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const resource = useSelector((state) => state.resourceItemJob.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  useSelector((state) => state.resourceItemJob.loading);
  const agent = useSelector((state) => state.agent.data);

  useEffect(() => {
    dispatch(loadJob(jobId));
    dispatch(loadAgent(localStorage.getItem('userLoginId')));
    dispatch(loadResourceItemJob({}));
  }, [dispatch, jobId]);

  useEffect(() => {
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1?.content)
  }, [envInfo]);

  useEffect(() => {
    const imageJobs = job?.imageJobs ?? [];
    const mains = imageJobs.filter(ele => ele.type === IMAGE_TYPE.AVATAR);
    setMain(mains.length ? mains[0] : {});

    let subImagesObj = {};
    let subImagesRaw = job?.imageJobs ?? [];
    remove(subImagesRaw, (x) => x.type === IMAGE_TYPE.AVATAR);
    // orderBy Asc
    for (let i = 0; i < subImagesRaw.length; i++) {
      subImagesObj[subImagesRaw[i].subImgNumber] = subImagesRaw[i];
    }

    let tempSubImages = [];
    Array(5).fill(1).forEach((ele, index) => {
      if (subImagesObj[(index + 1)]) {
        tempSubImages.push({ ...subImagesObj[(index + 1)], none: false });
      } else {
        tempSubImages.push({
          none: true,
        });
      }
    });

    setSubImages(tempSubImages);
  }, [job]);

  const handleRemoveConfirm = () => {
    setConfirmVisible(true);
  };

  const handleRemoveClose = () => {
    setConfirmVisible(false);
  };

  const refreshList = () => {
    history.push('/job/job-list');
  };

  if (!job) {
    return null;
  }

  const handleEdit = () => {
    history.push(`/job/${jobId}/edit`);
  };

  const accessAndPassData = () => {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
        '.iframe-pre-hidden-top-menu',
        '.iframe-pre-hidden-top-back',
        '.iframe-pre-btn-register',
      ],
    });
  };

  return (
    <div>
      <LoadingBar show={loading} />
      <div className="align" style={{ justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          className="btn-custom btn-blue mr-3"
          style={{ float: 'right' }}
          onClick={() => setIsPreviewJob(true)}
        >
          仕事プレビュー
        </Button>
        <Button
          variant="contained"
          className="btn-custom btn-blue mr-3"
          style={{ float: 'right' }}
          onClick={handleEdit}
        >
          編集
        </Button>
      </div>
      <>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={isPreviewJob}
          onClose={() => setIsPreviewJob(false)}
          aria-labelledby="max-width-dialog-title"
          PaperProps={{
            style: {
              top: 0,
              maxHeight: '100%',
              maxWidth: 600,
            },
          }}
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            仕事プレビュー
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={() => setIsPreviewJob(false)}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent style={{ height: '80vh', overflow: 'hidden', padding: '8px 20px' }}>
            {job && (<iframe title="仕事プレビュー"
                             id="iframeId"
                             onLoad={accessAndPassData}
                             style={{ border: 'none', width: '100%', height: '100%' }}
                             src={job && Util.previewJobDetail(job)} />
            )}
          </DialogContent>
        </Dialog>
      </>
      <MuiThemeProvider theme={tableNewTheme}>
        <Row>
          <Col xs={12}>
            <Card body className="mb-4">
              <span>求人の詳細です。</span>
              <span className="mt-2">求人画像は編集画面からアップして下さい。</span>
              <span className="mt-2">仕事プレビューで掲載内容をご確認頂けます。</span>
            </Card>
          </Col>
        </Row>
        <Row>
          {/*left*/}
          <Col xs={4}>
            <Card body className="mb-4">
              <ImageJobList subImages={subImages} main={main} />
            </Card>
            <Card body className="mb-4">
              <Row>
                {!agent && 
                <>
                  <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>MySQL ID</Label>
                  <Label xs={8} style={{ paddingBottom: 5 }}>
                    <div onClick={copyToClipboard(job.id)}
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{job.id}</span>
                      <span id='copy-btn'>
                        <CopyIcon style={{ fontSize: 18 }} />
                      </span>
                    </div>
                  </Label>
                  <hr style={{ width: '95%', margin: '0 auto' }} />
                </>
                }
                <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>会社名</Label>
                <Label xs={8} style={{ paddingBottom: 5 }}>{job?.agent?.nickName}</Label>
                <hr style={{ width: '95%', margin: '0 auto' }} />
                {!agent &&
                <>
                  <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>会社Number</Label>
                  <Label xs={8} style={{ paddingBottom: 5 }}>{job?.agent?.agentNumber}</Label>
                </>
                }
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                {!agent &&
                <>
                  <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>表示設定</Label>
                  <Label xs={8} style={{ paddingBottom: 5 }}>{convertHiddenStatusText(job?.displaySetting)}</Label>
                  <hr style={{ width: '95%', margin: '0 auto' }} />
                </>}
                <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>掲載状況</Label>
                <Label xs={8} style={{ paddingBottom: 5 }}>{jobPublishingTypeText(job?.draft)}</Label>
                <hr style={{ width: '95%', margin: '0 auto' }} />
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>都道府県</Label>
                <Label xs={8} style={{ paddingBottom: 5 }}>{job?.state?.label}</Label>
                <hr style={{ width: '95%', margin: '0 auto' }} />
                <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>エリア</Label>
                <Label xs={8} style={{ paddingBottom: 5 }}>{job?.area?.label}</Label>
                <hr style={{ width: '95%', margin: '0 auto' }} />
                {resource && showResourceItemJob(resource?.items, job?.attribute, colorLabel)}
                <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>給与報酬幅</Label>
                <Label xs={8} style={{ paddingBottom: 5 }}>
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'60%'}}>
                    <span>{Util.separator(job?.minimumSalary)}円</span>
                    <span>~</span>
                    <span>{Util.separator(job?.maximumSalary)}円</span>
                  </div>
                </Label>
                <hr style={{ width: '95%', margin: '0 auto' }} />
              </Row>
            </Card>
            {/*Tag list*/}
            <Card body className="mb-4">
              <Row>
                <div style={{ width: '100%', flexDirection: 'column', display: 'flex' }}>
                  <div style={{ width: '100%', marginBottom: 5 }}>
                    <Label style={style} xs={12}>
                      <p style={{ paddingLeft: 3, paddingTop: 5, marginBottom: 0, color: colorLabel }}>タグ設定</p>
                    </Label>
                    {job && job?.tagNameJobs && job?.tagNameJobs.length
                      ? (<Label style={style} xs={12}>
                        <div className="wrapper-tag">
                          {orderBy((job?.tagNameJobs ?? []), ['displayOrder'], ['asc']).map((v, i) => (
                            <div className="tag" key={i}>{v.label}</div>))}
                        </div>
                      </Label>)
                      : <div></div>
                    }
                  </div>
                  <hr style={{ width: '94%', alignItems: 'center', position: 'unset', margin: '0px auto' }} />
                </div>
              </Row>
            </Card>
          </Col>
          {/*right*/}
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabel }} xs={2}>タイトル</Label>
                    <Label xs={10}>{job?.title}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabel }} xs={2}>仕事内容</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{job?.workDetail}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabel }} xs={2}>給与/福利厚生</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{job?.salaryDetail}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabel }} xs={2}>勤務日/休日/勤務時間</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{job?.workHours}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabel }} xs={2}>勤務地</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{job?.location}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Button
          variant="contained"
          className="btn-custom btn-red"
          style={{ position: 'absolute', bottom: 20, right: 27 }}
          onClick={handleRemoveConfirm}
        >
          削除
        </Button>
      </MuiThemeProvider>

      {confirmVisible && (
        <RemoveJobModal
          visible={confirmVisible}
          onClose={handleRemoveClose}
          id={job.id}
          reload={refreshList}
        />
      )}
    </div>
  );
}

