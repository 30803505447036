import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { updateNameRank } from '../../Redux/Actions/nameRank';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import './index.scss';

function RankSettingList({
  nameRankLeft = [],
  nameRankRight = [],
  pageIndex,
  pageSize,
  loading,
  isEditing,
  setIsEditing,
}) {
  const [leftRankSetting, setLeftRankSetting] = useState([]);
  const [rightRankSetting, setRightRankSetting] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setLeftRankSetting(nameRankLeft);
    setRightRankSetting(nameRankRight);
  }, [nameRankLeft, nameRankRight]);

  const handleChangeLeft = (e) => {
    const splitName = e.target.name.split('.');
    let newArray = [...leftRankSetting];
    const elementsIndex = leftRankSetting.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setLeftRankSetting(newArray);
  };

  const handleChangeRight = (e) => {
    const splitName = e.target.name.split('.');
    let newArray = [...rightRankSetting];
    const elementsIndex = rightRankSetting.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setRightRankSetting(newArray);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onClose = () => {
    setLeftRankSetting(nameRankLeft);
    setRightRankSetting(nameRankRight);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = leftRankSetting.concat(rightRankSetting);
    dispatch(updateNameRank(values));
    setIsEditing(false);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {/* <h4 className="page-title">ランク設定</h4> */}
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="main-content-custom">
        <Card style={{ width: '49.3%' }}>
          <Table
            padding="checkbox"
            className="table text-nowrap mb-0"
          >
            <TableBody>
              <TableRow>
                <TableCell>Column Name</TableCell>
                <TableCell className="font-weight-normal">表示名</TableCell>
                <TableCell className="font-weight-normal">マーク</TableCell>
              </TableRow>
              {leftRankSetting.map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        value={item.label}
                        name={`label.${item.id}`}
                        onChange={handleChangeLeft}
                        className="input-rank"
                      />
                    ) : (
                      item.label
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        value={item.markWord}
                        name={`markWord.${item.id}`}
                        onChange={handleChangeLeft}
                        className="input-rank"
                      />
                    ) : (
                      item.markWord
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <Card
          style={{
            width: '49.3%',
            boxShadow:
              '0px 0px 0px -6px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(236 239 244 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0.12)',
          }}
        >
          <Table
            padding="checkbox"
            className="table text-nowrap mb-0"
          >
            <TableBody>
              <TableRow>
                <TableCell>Column Name</TableCell>
                <TableCell className="font-weight-normal">表示名</TableCell>
                <TableCell className="font-weight-normal">マーク</TableCell>
              </TableRow>
              {rightRankSetting.map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        value={item.label}
                        name={`label.${item.id}`}
                        onChange={handleChangeRight}
                        className="input-rank"
                      />
                    ) : (
                      item.label
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        value={item.markWord}
                        name={`markWord.${item.id}`}
                        onChange={handleChangeRight}
                        className="input-rank"
                      />
                    ) : (
                      item.markWord
                    )}
                  </TableCell>
                </TableRow>
              ))}

              {(rightRankSetting.length + leftRankSetting.length) % 2 !== 0 &&
                (!isEditing ? (
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>
                      <input
                        className="input-rank"
                        style={{ opacity: 0, visibility: 'hidden' }}
                      ></input>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </MuiThemeProvider>
  );
}

export default RankSettingList;
