import { Button, Card, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import get from 'lodash/get';
import moment from 'moment';
import NoAvatar from '../../../assets/image/sys_photo.png';
import RemoveModal from './RemoveModal';
import { TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_ROM_LINK_MODAL } from '../../../Common/modal_text_info';
import { CHAT_MESSAGE_TYPE } from '../../../Common/constant';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function TimerChatRoomDetailModal({ onClose, timerChatRoom, reload }) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          timerChatRoomId={timerChatRoom?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!timerChatRoom}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>予約一覧チャットルーム-詳細</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                {timerChatRoom.sender?.phoneNumber}
              </p>
              <img className="object-fit-cover"
                   style={{
                     width: 30,
                     height: 30,
                     borderRadius: '50%',
                     marginRight: 20,
                   }}
                   src={timerChatRoom.sender?.avatar ? timerChatRoom.sender.avatar.url : NoAvatar}
                   alt=""
              />
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {timerChatRoom.sender?.nickName}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_ROM_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <Row>
            <Col xs={12}>
              <Card
                className="card card-body"
                style={{ marginBottom: '1rem' }}
              >
                <Row>
                  <Col xs={6}>
                    <Row>
                      <Label xs={6} style={style}>
                        <p>日時</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        {moment(Number(get(timerChatRoom, 'createdDate'))).format(
                          'YYYY-MM-DD hh:mm:ss',
                        )}
                        <hr style={stylehrright} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>受信者ネックネーム</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>{timerChatRoom.receiver?.nickName}</p>
                        <hr style={stylehrright} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>受信者電話番号</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>{timerChatRoom.receiver?.phoneNumber}</p>
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Row>
                      <Label xs={6} style={style}>
                        <p style={{ opacity: 0 }}>1</p>
                      </Label>
                      <Label xs={6} style={style}>
                        <p style={{ opacity: 0 }}>1</p>
                      </Label>
                      <Label xs={6} style={style}>
                        <p style={{ opacity: 0 }}>1</p>
                      </Label>
                      <Label xs={6} style={style}>
                        <p style={{ opacity: 0 }}>1</p>
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card
                className="card card-body"
                style={{ marginBottom: '1rem' }}
              >
                <Row>
                  <Label xs={2}>本文</Label>
                  {Number(timerChatRoom.type) === CHAT_MESSAGE_TYPE.IMAGE && (
                    <Label xs={10}>
                      <img alt={timerChatRoom.content} width="100" src={timerChatRoom.content} />
                    </Label>)}

                  {Number(timerChatRoom.type) === CHAT_MESSAGE_TYPE.TEXT && (
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>
                      <div dangerouslySetInnerHTML={{ __html: timerChatRoom.content }}></div>
                    </Label>)}

                </Row>
              </Card>
            </Col>
          </Row>
          <Button
            variant="contained"
            className="btn-custom btn-red float-right"
            onClick={handleRemove}
            style={{ marginBottom: 2 }}
          >
            削除
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
