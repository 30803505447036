import {
  Button,
  Card,
  MenuItem,
  MuiThemeProvider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateEnvSysCms } from '../../Api/EnvSysCmsApi';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { useSelector } from 'react-redux';

function EnvValueSetting({ data, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);
  const { data: nameRank } = useSelector((state) => state.nameRank);

  useEffect(() => {
    setEnvSetting(data);
  }, [data]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = [...inputUpdate];
    const elementsIndex = envSetting.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };

  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    updateEnvSysCms(inputUpdate)
      .then((res) => {
        const { data } = res;
        if (data.data) {
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
    setEnvSetting(inputUpdate);
    setIsEditing(false);
  };

  const renderDescription = (content) => {
    switch (content) {
      case 'mail_max':
        return 'メール-1最大送信数';
      case 'push_max':
        return 'Push(オペ画面含）-1最大送信数';
      case 'sms_max':
        return 'SMS-1最大送信数';
      case 'contact_notification_mail':
        return '問合せ-1メール転送設定';
      case 'call_notification_sms':
        return 'コール-1事前告知SMS送信';
      case 'form_notification_mail':
        return '問合せフォーム-1（仕事）メール転送設定';
      case 'notification_mail_chat_admin':
        return 'メール通知設定（管理者→会社のメール）';
      case 'notification_mail_chat_agent':
        return 'メール通知設定（ユーザー会社のメール）';
      case 'notification_mail_job_entry':
        return 'メール通知設定（ユーザーの仕事応募→会社）';
      case 'otp_send':
        return 'OTPを送信';
      case 'rank_change_first_paying':
        return '初回入金ランク変更（初回入金日に記録時）';
      default:
        return '';
    }
  };

  const renderEditValueField = ({ id, content, value }) => {
    //this is the most dirty way to do this that I've ever seen....
    switch (content) {
      case 'rank_change_first_paying':
        return (
          <Select
            name={`value.${id}`}
            defaultValue={value}
            onChange={handleChange}
            fullWidth
          >
            {nameRank.map((e) => (
              <MenuItem
                key={e.id}
                value={e.id}
                style={{ padding: '10px 10px' }}
              >
                {`${e.id}: ${e.markWord}: ${e.label}`}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell title="説明" style={{ width: '25%' }}>
                説明
              </TableCell>
              <TableCell title="DATA名(content)" style={{ width: '10%' }}>
                DATA名(content)
              </TableCell>
              <TableCell title="最大送信数制限(max)">
                最大送信数制限(max)
              </TableCell>
              <TableCell title="有効設定（0：制限無 1：制限有） (status)">
                有効設定（0：制限無 1：制限有） (status)
              </TableCell>
              <TableCell title="初回入金ランク変更のみ使用(value)">価値 (value)</TableCell>
            </TableRow>
            {envSetting.map((item, key) => (
              <TableRow key={key}>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {renderDescription(item.content)}
                </TableCell>
                <TableCell title={item?.content}>{item.content}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.max}
                      name={`max.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.max
                  )}
                </TableCell>

                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.status}
                      name={`status.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.status
                  )}
                </TableCell>
                <TableCell>
                  {isEditing ? renderEditValueField(item) : item?.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default EnvValueSetting;
