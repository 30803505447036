const ResourcesAgent = {
  LOAD_RESOURCE_AGENT: 'LOAD_RESOURCE_AGENT',
  LOAD_RESOURCE_AGENT_SUCCESS: 'LOAD_RESOURCE_AGENT_SUCCESS',
  LOAD_RESOURCE_AGENT_FAIL: 'LOAD_RESOURCE_AGENT_FAIL',

  LOAD_ROOT_RESOURCE_AGENT: 'LOAD_ROOT_RESOURCE_AGENT',
  LOAD_ROOT_RESOURCE_AGENT_SUCCESS: 'LOAD_ROOT_RESOURCE_AGENT_SUCCESS',
  LOAD_ROOT_RESOURCE_AGENT_FAIL: 'LOAD_ROOT_RESOURCE_AGENT_FAIL',

  GET_DETAIL_ROOT_RESOURCE_AGENT: 'GET_DETAIL_ROOT_RESOURCE_AGENT',
  GET_DETAIL_ROOT_RESOURCE_AGENT_SUCCESS: 'GET_DETAIL_ROOT_RESOURCE_AGENT_SUCCESS',
  GET_DETAIL_ROOT_RESOURCE_AGENT_FAIL: 'GET_DETAIL_ROOT_RESOURCE_AGENT_FAIL',

  UPDATE_ROOT_RESOURCE_AGENT: 'UPDATE_ROOT_RESOURCE_AGENT',
  UPDATE_ROOT_RESOURCE_AGENT_SUCCESS: 'UPDATE_ROOT_RESOURCE_AGENT_SUCCESS',
  UPDATE_ROOT_RESOURCE_AGENT_FAIL: 'UPDATE_ROOT_RESOURCE_AGENT_FAIL',

  UPDATE_CHILD_RESOURCE_AGENT: 'UPDATE_CHILD_RESOURCE_AGENT',
  UPDATE_CHILD_RESOURCE_AGENT_SUCCESS: 'UPDATE_CHILD_RESOURCE_AGENT_SUCCESS',
  UPDATE_CHILD_RESOURCE_AGENT_FAIL: 'UPDATE_CHILD_RESOURCE_AGENT_FAIL',
};
export default ResourcesAgent;
