import { sendGet, sendPost, sendPut } from './axios';

export const getPointSetting = (filter = {}) => {
  return sendGet('/cms/point-setting/search', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
  });
};
export const createPointSetting = (params) => {
  return sendPost('/cms/point-setting/create', params);
};
export const updatePointSetting = (data) => {
  return sendPut(`/cms/update-list-point-setting`, data);
};
