import { createAction } from 'redux-actions';
import helpMailSmsText from '../Types/helpMailSmsText';

export const loadHelpMailSmsText = createAction(helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT);
export const loadHelpMailSmsTextSuccess = createAction(helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT_SUCCESS);
export const loadMHelpMailSmsTextFail = createAction(helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT_FAIL);

export const updateHelpMailSmsText = createAction(helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT);
export const updateHelpMailSmsTextSuccess = createAction(helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT_SUCCESS);
export const updateHelpMailSmsTextFail = createAction(helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT_FAIL);

// export const getByIdHelpMailSmsText = createAction(helpMailSmsText.GET_BY_ID_HELP_MAIL_SMS_TEXT);

// export const getByIdHelpMailSmsTextSuccess = createAction(helpMailSmsText.GET_BY_ID_HELP_MAIL_SMS_TEXT_SUCCESS);
// export const getByIdHelpMailSmsTextFail = createAction(helpMailSmsText.GET_BY_ID_HELP_MAIL_SMS_TEXT_FAIL);

// export const createHelpMailSmsText = createAction(helpMailSmsText.CREATE_HELP_MAIL_SMS_TEXT);
// export const createHelpMailSmsTextSuccess = createAction(helpMailSmsText.CREATE_HELP_MAIL_SMS_TEXT_SUCCESS);
// export const createHelpMailSmsTextFail = createAction(helpMailSmsText.CREATE_HELP_MAIL_SMS_TEXT_FAIL);
