import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import {
  Card,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import Util from '../../Util/Util';
import { getLogEnRollmentMembers } from '../../Api/userAPI';
import moment from 'moment';
import { GENDER, MEMBER_CATEGORY_TYPE } from '../../Common/constant';
import { REGISTER_TYPE_LOG_ENROLLMENT } from '../../Config/listConstants';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  td: {
    width: '100px !important',
  },
});

export default function Enrollment() {
  const classes = useStyles();
  const [dataEnrollment, setDataEnrollment] = useState({});
  const [dataEnrollmentTotal, setDataEnrollmentTotal] = useState({});
  const [dataLogByMonth, setDataLogByMonth] = useState();
  const [months, setMonths] = useState();
  const [month, setMonth] = useState('2021-01');

  useEffect(() => {
    getLogEnRollmentMembers().then((res) => {
      setDataEnrollment(res.data.data.dataLogByDateTime);
      setDataEnrollmentTotal(res.data.data);
    }
    );
  }, []);

  useEffect(() => {
    let data = _.groupBy(dataEnrollment, function (enrollment) {
      return moment(Number(enrollment.registerDate)).format('YYYY-MM');
    });
    setMonths(Object.keys(data).sort((a, b) => new Date(b) - new Date(a)));
    setMonth(Object.keys(data).sort((a, b) => new Date(b) - new Date(a))[0]);
    setDataLogByMonth(data);
  }, [dataEnrollment]);

  const countTotal = (array) => {
    const dataTotal = {
      countLogMaleWeb: 0,
      countLogFemaleWeb: 0,
      countLogMaleJob: 0,
      countFemaleJob: 0,
      countLogWeb: 0,
      countLogAPP: 0,
      countLogCMS: 0,
      countLogCSV: 0,
    };

    for (let i = 0; i < array.length; i++) {
      const currentArrayIndex = array[i];
      switch (currentArrayIndex.gender) {
        case GENDER.MALE:
          if (
            currentArrayIndex.memberCategory === MEMBER_CATEGORY_TYPE.WEB_MATCH
          )
            dataTotal.countLogMaleWeb += 1;
          if (currentArrayIndex.memberCategory === MEMBER_CATEGORY_TYPE.JOB)
            dataTotal.countLogMaleJob += 1;
          break;
        case GENDER.FEMALE:
          if (
            currentArrayIndex.memberCategory === MEMBER_CATEGORY_TYPE.WEB_MATCH
          )
            dataTotal.countLogFemaleWeb += 1;
          if (currentArrayIndex.memberCategory === MEMBER_CATEGORY_TYPE.JOB)
            dataTotal.countFemaleJob += 1;
          break;
        default:
          break;
      }

      switch (+currentArrayIndex.registerType) {
        case REGISTER_TYPE_LOG_ENROLLMENT.WEB:
          dataTotal.countLogWeb += 1;
          break;
        case REGISTER_TYPE_LOG_ENROLLMENT.APP:
          dataTotal.countLogAPP += 1;
          break;
        case REGISTER_TYPE_LOG_ENROLLMENT.CMS:
          dataTotal.countLogCMS += 1;
          break;
        case REGISTER_TYPE_LOG_ENROLLMENT.CSV:
          dataTotal.countLogCSV += 1;
          break;
        default:
          break;
      }
    }

    return dataTotal;
  };

  const generateData = () => {
    if (dataLogByMonth && dataLogByMonth[month]) {
      let data = _.groupBy(dataLogByMonth[month], function (enrollment) {
        return moment(Number(enrollment.registerDate)).format('YYYY-MM-DD');
      });
      data = Object.entries(data).sort((a, b) => new Date(b[0]) - new Date(a[0]));
      return data.map((item, index) => {
        const {
          countLogMaleWeb,
          countLogFemaleWeb,
          countLogMaleJob,
          countFemaleJob,
          countLogWeb,
          countLogAPP,
          countLogCMS,
          countLogCSV,
        } = countTotal(item[1]);
        return (
          <TableRow key={index}>
            <TableCell>{item[0]}</TableCell>
            <TableCell>{Util.separator(countLogMaleWeb)}</TableCell>
            <TableCell>{Util.separator(countLogFemaleWeb)}</TableCell>
            <TableCell>{Util.separator(countLogMaleJob)}</TableCell>
            <TableCell>{Util.separator(countFemaleJob)}</TableCell>
            <TableCell>{Util.separator(countLogWeb)}</TableCell>
            <TableCell>{Util.separator(countLogAPP)}</TableCell>
            <TableCell>{Util.separator(countLogCMS)}</TableCell>
            <TableCell>{Util.separator(countLogCSV)}</TableCell>
          </TableRow>
        );
      });
    }
  };

  const totalData = () => {
    if (dataLogByMonth && dataLogByMonth[month]) {
      let totalLogMaleWeb = 0,
        totalLogMaleJob = 0,
        totalLogFemaleWeb = 0,
        totalFemaleJob = 0,
        totalLogWeb = 0,
        totalLogAPP = 0,
        totalLogCMS = 0,
        totalLogCSV = 0;

      dataLogByMonth[month].map((item) => {
        switch (item.gender) {
          case GENDER.MALE:
            if (item.memberCategory === MEMBER_CATEGORY_TYPE.WEB_MATCH)
              totalLogMaleWeb += 1;
            if (item.memberCategory === MEMBER_CATEGORY_TYPE.JOB)
              totalLogMaleJob += 1;
            break;
          case GENDER.FEMALE:
            if (item.memberCategory === MEMBER_CATEGORY_TYPE.WEB_MATCH)
              totalLogFemaleWeb += 1;
            if (item.memberCategory === MEMBER_CATEGORY_TYPE.JOB)
              totalFemaleJob += 1;
            break;
          default:
            break;
        }

        switch (+item.registerType) {
          case REGISTER_TYPE_LOG_ENROLLMENT.WEB:
            totalLogWeb += 1;
            break;
          case REGISTER_TYPE_LOG_ENROLLMENT.APP:
            totalLogAPP += 1;
            break;
          case REGISTER_TYPE_LOG_ENROLLMENT.CMS:
            totalLogCMS += 1;
            break;
          case REGISTER_TYPE_LOG_ENROLLMENT.CSV:
            totalLogCSV += 1;
            break;
          default:
            break;
        }

        return true;
      });
      return (
        <TableRow>
          <TableCell>合計</TableCell>
          <TableCell>{Util.separator(totalLogMaleWeb)}</TableCell>
          <TableCell>{Util.separator(totalLogFemaleWeb)}</TableCell>
          <TableCell>{Util.separator(totalLogMaleJob)}</TableCell>
          <TableCell>{Util.separator(totalFemaleJob)}</TableCell>
          <TableCell>{Util.separator(totalLogWeb)}</TableCell>
          <TableCell>{Util.separator(totalLogAPP)}</TableCell>
          <TableCell>{Util.separator(totalLogCMS)}</TableCell>
          <TableCell>{Util.separator(totalLogCSV)}</TableCell>
        </TableRow>
      );
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ textAlign: 'right' }}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink>月表示</InputLabel>
            <Select
              name="state"
              onChange={(e) => setMonth(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              required={true}
              style={{ width: 200 }}
              value={month}
            >
              {months &&
                months.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <Card className="scroll-x-on-top" style={{ marginTop: 20 }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>WEB（男性）</TableCell>
                <TableCell>WEB（女性）</TableCell>
                <TableCell>仕事（男性）</TableCell>
                <TableCell>仕事（女性）</TableCell>
                <TableCell>登録元（WEB）</TableCell>
                <TableCell>登録元（アプリ）</TableCell>
                <TableCell>登録元（管理）</TableCell>
                <TableCell>登録元（CSV）</TableCell>
              </TableRow>
              <TableRow key={1} style={{ height: 48 }}>
                <TableCell>総合計</TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalMaleWeb)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalFemaleWeb)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalMaleJob)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalFemaleJob)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalWeb)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalAPP)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalCMS)}
                </TableCell>
                <TableCell>
                  {Util.separator(dataEnrollmentTotal?.totalCSV)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {generateData()}
              {totalData()}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}
