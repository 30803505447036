import React from 'react';
import { toast } from 'react-toastify';

import RemoveItemModal from '../../Components/RemoveItemModal';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import * as campaignApi from '../../Api/campaignApi';


export default function RemoveCampaignModal({ visible, onClose, onCloseRoot, editingCampaign, onDeleteImage, onUpdate }) {
  const handleSubmit = async () => {
    await campaignApi.deleteCampaign(editingCampaign.id).then((response) => {
      const { data } = response;
      if (data.success) {
        onDeleteImage(editingCampaign.url);
        onCloseRoot();
        onUpdate();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}
