import { call, put, takeLatest } from 'redux-saga/effects';
import Information from '../Types/information';
import { getInformation, updateInformation } from '../../Api/informationAPI';
import { toast } from 'react-toastify';
import {
  loadInformationFail,
  loadInformationSuccess,
  updateInformationFail,
  updateInformationSuccess,
} from '../Actions/information';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadInformation(action) {
  try {
    const response = yield call(getInformation, action.payload);
    const { data } = response;
    if (data.success) {
      const agent = data.data;
      yield put(loadInformationSuccess(agent));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadInformationFail(error));
  }
}

function* handleUpdateInformation(action) {
  try {
    const response = yield call(updateInformation, action.payload);
    const { data } = response;
    if (data.success) {
      const agent = data.data;
      yield put(updateInformationSuccess(agent));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateInformationFail(error));
  }
}


export default function* agentSaga() {
  yield takeLatest(Information.LOAD_INFORMATION, handleLoadInformation);
  yield takeLatest(Information.UPDATE_INFORMATION, handleUpdateInformation);
}
