import { call, put, takeLatest } from 'redux-saga/effects';
import Operator from '../Types/operator';
import { getDetailOperator, getOperator, updateOperator } from '../../Api/operatorAPI';
import { toast } from 'react-toastify';
import {
  loadDetailOperatorFail,
  loadDetailOperatorSuccess,
  loadOperatorFail,
  loadOperatorSuccess,
  updateOperatorFail,
  updateOperatorSuccess,
} from '../Actions/operator';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadOperator(action) {
  try {
    const response = yield call(getOperator, action.payload);
    const { data } = response;
    if (data.success) {
      const operator = data.data;
      const totalRow = data.totalRow;
      yield put(loadOperatorSuccess({ operator, totalRow }));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadOperatorFail(error));
  }
}

function* handleLoadDetailOperator(action) {
  try {
    const response = yield call(getDetailOperator, action.payload);
    const { data } = response;
    if (data.success) {
      const detailOperator = data.data;
      yield put(loadDetailOperatorSuccess(detailOperator));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadDetailOperatorFail(error));
  }
}

function* handleUpdateOperator(action) {
  try {
    const response = yield call(updateOperator, action.payload);
    const { data } = response;
    if (data.success) {
      const operator = data.data;
      yield put(updateOperatorSuccess(operator));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateOperatorFail(error));
  }
}

export default function* operatorSaga() {
  yield takeLatest(Operator.LOAD_OPERATOR, handleLoadOperator);
  yield takeLatest(Operator.LOAD_DEATIL_OPERATOR, handleLoadDetailOperator);
  yield takeLatest(Operator.UPDATE_OPERATOR, handleUpdateOperator);
}
