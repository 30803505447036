import { sendGet, sendPut } from './axios';

// Tag user
export const getAllTagUsers = (filter = {}) => sendGet('/tags/all', {...filter});
export const updateTagUsers = (tags) => sendPut('/tags', { tags });
export const getUsersWithTagUsers = (params) => sendGet('/tags/users', params);

// Tag of job
export const getAllTagJobs = (filter = {}) => sendGet('/tag-job/all', {...filter});
export const updateTagJobs = (tags) => sendPut('/tag-job', { tags });

/* -------------------------------- Tag Admin ------------------------------- */
export const getAllTagAdmin = (filter = {}) => sendGet('/tag-admin/all', {...filter});
export const updateTagAdmin = (tags) => sendPut('/tag-admin', { tags });