import { handleActions } from 'redux-actions';
import TimerNoti from '../Types/timerNoti';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [TimerNoti.LOAD_TIMERNOTI]: (state) => ({
    ...state,
    loading: true,
  }),
  [TimerNoti.LOAD_TIMERNOTI_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [TimerNoti.LOAD_TIMERNOTI_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
