import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import ChatRoomTimerComponent from './ChatRoomTimer';
import NotifyTimerComponent from './NotifyTimer';
import SmsTimerComponent from './SmsTimer';
import MailTimerComponent from './MailTimer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../Common/constant';
import orderBy from 'lodash/orderBy';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';

tableNewTheme.overrides = Object.assign({}, {
  ...tableNewTheme?.overrides, MuiTabs: {
    root: {
      marginTop: '14px',
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: 'unset',
      },
    },
  },
});

const theme = createMuiTheme({
  ...tableNewTheme,
});

function Index() {
  const [value, setValue] = useState('');
  const [typeShowLogs, setTypeShowLogs] = useState([
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'MAIL_TIMER', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'SMS_TIMER', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'PUSH_TIMER', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'CHAT_ROOM_TIMER', order: -1, content: '' },
  ]); // 0 show, 1 show + not link, 2 hidden

  const defineShows = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.NOT_LINK];
  const admin = useSelector((state) => state.admin.data);
  const envUICms = useSelector((state) => state.envUICms.data);

  const timerMailStandby = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_TIMER_MAIL_STANDBY);
  const timerSmsStandby = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_TIMER_SMS_STANDBY);
  const timerPushStandby = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_TIMER_PUSH_STANDBY);
  const timerChatRoomStandby = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_TIMER_CHATROOM_STANDBY);

  const setDataTypeShowAllActive = () => {
    const tmp = typeShowLogs.map(ele => ({ ...ele, type: DISPLAY_CMS_UI_TYPE.ON }));
    setTypeShowLogs(uniqBy(tmp, 'active'));
  };

  const setDataTypeShowActive = (key = '', payload) => {
    let findActive = typeShowLogs.find(ele => ele.active === key);

    if (findActive) {
      Object.assign(findActive, payload);
      setTypeShowLogs(orderBy(uniqBy(Object.assign([], [...typeShowLogs, findActive]), 'active'), ['order'], ['asc']));
    }
  };

  useEffect(() => {
    let typeSysAdmin = false;
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setDataTypeShowAllActive();
      typeSysAdmin = true;
    }

    // Check type show setting
    if (timerMailStandby) {
      setDataTypeShowActive('MAIL_TIMER', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +timerMailStandby.type),
        order: timerMailStandby.order,
        content: timerMailStandby.content,
      });
    }
    if (timerSmsStandby) {
      setDataTypeShowActive('SMS_TIMER', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +timerSmsStandby.type),
        order: timerSmsStandby.order,
        content: timerSmsStandby.content,
      });
    }
    if (timerPushStandby) {
      setDataTypeShowActive('PUSH_TIMER', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +timerPushStandby.type),
        order: timerPushStandby.order,
        content: timerPushStandby.content,
      });
    }
    if (timerChatRoomStandby) {
      setDataTypeShowActive('CHAT_ROOM_TIMER', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +timerChatRoomStandby.type),
        order: timerChatRoomStandby.order,
        content: timerChatRoomStandby.content,
      });
    }

  }, [admin]);

  useEffect(() => {
    let findActive = typeShowLogs.filter(ele => ele.type === DISPLAY_CMS_UI_TYPE.ON);
    if (findActive && findActive.length) {
      setValue(findActive[0].active);
    }
  }, [typeShowLogs]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const clickTabSelected = (selected) => {
    setValue(selected.active);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Tabs scrollButtons="auto"
              indicatorColor="unset"
              textColor="white"
              value={value}
              onChange={handleChangeTab}
              className="d-tab-wrapper tab-timer-send-wrapper"
        >
          {typeShowLogs && typeShowLogs.map((ele, index) => (
              <>
                {defineShows?.includes(ele.type) ? (
                    <Tab disableRipple
                         key={index}
                         value={ele.active}
                         data-id={ele.active}
                         onClick={() => clickTabSelected(ele)}
                         className={`${value === ele.active ? 'btn-blue' : ''} btn-custom`}
                         label={ele.content}
                         title={ele.content} />)
                  : <div key={index}></div>}
              </>
            ),
          )}
        </Tabs>

        <div className="mt-2">
          {value === 'MAIL_TIMER' && (typeShowLogs.findIndex(ele => ele.active === 'MAIL_TIMER' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <MailTimerComponent value={value} />
          )}
          {value === 'SMS_TIMER' && (typeShowLogs.findIndex(ele => ele.active === 'SMS_TIMER' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <SmsTimerComponent value={value} />
          )}
          {value === 'PUSH_TIMER' && (typeShowLogs.findIndex(ele => ele.active === 'PUSH_TIMER' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <NotifyTimerComponent value={value} />
          )}
          {value === 'CHAT_ROOM_TIMER' && (typeShowLogs.findIndex(ele => ele.active === 'CHAT_ROOM_TIMER' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <ChatRoomTimerComponent value={value} />
          )}
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default Index;
