import React, { useEffect } from 'react';
import history from './history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Main from './Page/Main';
import './App.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrivateRoute } from './Components/PrivateRoute';
import { Provider } from 'react-redux';
import configureStore from './Redux/Store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getEnvironment } from './Api/environmentApi';
// import configs from './Config/config';
// import CryptoJS from 'crypto-js';
import SystemLogin from './Page/Login/LoginSys';
import OpeLogin from './Page/Login/LoginOpe';
import AgentLogin from './Page/Login/LoginAgent';
import AdminLogin from './Page/Login/LoginAdmin';
import NotFound from './Page/404/index';

function App() {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getEnvironment({});
        // const cipherText = data.data;
        // const plainText = CryptoJS.AES.decrypt(
        //   cipherText,
        //   configs.resources.aesSecretKey,
        // );
        // const rawData = JSON.parse(plainText.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        <CssBaseline />
        <Switch>
          <Route exact path="/login_sys" component={SystemLogin} />
          <Route exact path="/login_admin" component={AdminLogin} />
          <Route exact path="/login_ope" component={OpeLogin} />
          <Route exact path="/login_agent" component={AgentLogin} />
          <Route path='/404' exact component={NotFound} />

          <PrivateRoute path="/" component={Main} />
          <PrivateRoute path="/home" component={Main} />
          <Route path='*' exact component={NotFound} />

          <Redirect to="/home" />
        </Switch>

        <ToastContainer hideProgressBar />
      </Router>
    </Provider>
  );
}

export default App;
