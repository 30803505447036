const Agent = {
  LOAD_AGENT: 'LOAD_AGENT',
  LOAD_AGENT_SUCCESS: 'LOAD_AGENT_SUCCESS',
  LOAD_AGENT_FAIL: 'LOAD_AGENT_FAIL',
  LOAD_AGENT_UPDATE_LOCAL: 'LOAD_AGENT_UPDATE_LOCAL',

  UPDATE_AGENT: 'UPDATE_AGENT',
  UPDATE_AGENT_SUCCESS: 'UPDATE_AGENT_SUCCESS',
  UPDATE_AGENT_FAIL: 'UPDATE_AGENT_FAIL',

  LOAD_NAME_RANK: 'LOAD_NAME_RANK',
  LOAD_NAME_RANK_SUCCESS: 'LOAD_NAME_RANK_SUCCESS',
  LOAD_NAME_RANK_FAIL: 'LOAD_NAME_RANK_FAIL',

  AGENT_LOG_OUT: 'LOG_OUT',
};
export default Agent;
