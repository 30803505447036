const SearchPresets = {
  LOAD_SEARCH_PRESETS: '    LOAD_SEARCH_PRESETS',
  LOAD_SEARCH_PRESETS_SUCCESS: '    LOAD_SEARCH_PRESETS_SUCCESS',
  LOAD_SEARCH_PRESETS_FAIL: '    LOAD_SEARCH_PRESETS_FAIL',

  EDIT_SEARCH_PRESETS: '    EDIT_SEARCH_PRESETS',
  EDIT_SEARCH_PRESETS_SUCCESS: '    EDIT_SEARCH_PRESETS_SUCCESS',
  EDIT_SEARCH_PRESETS_FAIL: '    EDIT_SEARCH_PRESETS_FAIL',

  DELETE_SEARCH_PRESETS: '    DELETE_SEARCH_PRESETS',
  DELETE_SEARCH_PRESETS_SUCCESS: '    DELETE_SEARCH_PRESETS_SUCCESS',
  DELETE_SEARCH_PRESETS_FAIL: '    DELETE_SEARCH_PRESETS_FAIL',
};

export default SearchPresets;

