import { sendGet, sendPut } from './axios';

export const getHelpMailSmsTexts = (filter = {}) => {
  return sendGet('/cms/mail-sms-texts', {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};
export const updateHelpMailSmsText = (data) => sendPut(`/cms/mail-sms-text/update`, data);
// export const createHelpMailSmsText = (data) => sendPost(`/cms/mail-sms-text`, data);
export const getHelpMailSmsTextById = (id) => sendGet(`/cms/mail-sms-text/${id}`);
// export const deleteHelpMailSmsTextById = (id) => sendDelete(`/cms/mail-sms-text/${id}`);
