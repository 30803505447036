import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { updateNameState } from '../../Redux/Actions/nameState';

function RootNameStateList({
  dataRootNameState,
  isEditing,
  handleEdit,
  onClose,
  changeDataRootNameState,
}) {
  const [dataProfile, setDataProfile] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataProfile(dataRootNameState);
  }, [dataRootNameState]);

  const handleUpdate = () => {
    dispatch(updateNameState(inputUpdate));
    setDataProfile(inputUpdate); // Set update when change data
    changeDataRootNameState(inputUpdate); // Set update when change data
    onClose();
  };

  const handleChangeLabel = (item, e) => {
    const newArray = [...inputUpdate ];

    const changeItem = newArray.find((t) => t.id === item.id);
    if (changeItem) {
      changeItem.label = e.target.value;
      // changeDataRootNameState({ ...changeItem });
    }
    setInputUpdate([...newArray ])
  };

  const handleClose = () => {
    changeDataRootNameState([ ...dataProfile ]); // Revert data old
    setInputUpdate([]) // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); //Clone deep data when use array
    handleEdit()
  };

  const renderRowData = () => {
    return (dataProfile || []).map((item, index) => (
      <TableRow align="left" key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{item.id}</TableCell>
        <TableCell>
          {isEditing ? (
            <input
              defaultValue={item.label}
              onChange={(e) => {
                handleChangeLabel(item, e);
              }}
            />
          ) : (
            item.label
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ textAlign: 'right', margin: '10px 0' }}>
          {isEditing ? (
            <div className="my-3">
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={handleClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </div>
          ) : (
            <Button
              className="btn-blue"
              style={{
                padding: '6px 14px',
                margin: 'auto',
                color: 'white',
                marginRight: 10,
              }}
              variant="contained"
              onClick={handleBtnEdit}
            >
              編集
            </Button>
          )}
        </div>

        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>都道府県No 00～99</TableCell>
                <TableCell>00 ～ 99</TableCell>
              </TableRow>
              {renderRowData()}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </>
  );
}

export default RootNameStateList;
