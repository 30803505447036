import React, { useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// drag drop file component
function DragDropFile({ indexInput, handleFile, acceptFileType }) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = useRef(null);

  // Handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // Triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // Triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // Triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      className="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        className="input-file-upload"
        multiple
        accept={acceptFileType || "*"}
        id={indexInput}
        onChange={handleChange}
      />

      <div
        htmlFor="input-file-upload"
        onClick={onButtonClick}
        className={`label-file-upload ${dragActive ? 'drag-active' : ''}`}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CloudUploadIcon
              color="action"
              style={{
                width: 40,
                height: 40,
              }}
            />
            <p style={{ marginTop: 3 }}>ファイルをドラッグ&ドロップ</p>
          </div>

          <p style={{ marginRight: 45, marginLeft: 45 }}>または</p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '1px solid #d9cece',
              padding: 20,
              borderRadius: 5,
            }}
          >
            <AddIcon
              color="action"
              style={{
                width: 40,
                height: 40,
              }}
            />
            <button className="upload-button" style={{ marginTop: 3 }}>
              ファイルを選択する
            </button>
          </div>
        </div>
      </div>
      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default DragDropFile;
