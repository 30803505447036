import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { loadListChat } from '../../Redux/Actions/listChat';
import _ from 'lodash';
import Util from '../../Util/Util';

const pageSize = 1000;

function ListChat() {
  const [filter] = useState({ sort: {} });
  const chats = useSelector((state) => state.listChat.items);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadListChat({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  return (
    <>
      <React.Fragment>
        <div className="space-top">&nbsp;</div>
        <div className="space-left">&nbsp;</div>
        <Table style={{ marginBottom: 20 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              {!_.isEmpty(chats) &&
              Object.keys(chats[0]).map((key) => (
                <TableCell>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chats.map((chat, index) => (
              <TableRow key={chat.id} style={{ height: 48 }}>
                <TableCell>{index + 1}</TableCell>
                {Object.keys(chat).map((key) => (
                  <TableCell>
                    {['createdDate', 'updatedDate'].includes(key)
                      ? Util.timestampToDate(chat[key], 'YYYY/MM/DD')
                      : chat[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    </>
  );
}

export default ListChat;
