import { handleActions } from 'redux-actions';
import PointUser from '../Types/pointUser';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [PointUser.LOAD_POINT_USER]: (state) => ({
    ...state,
    loading: true,
  }),
  [PointUser.LOAD_POINT_USER_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [PointUser.LOAD_POINT_USER_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
