import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import { changeAllLinksForContent } from '../../../Util/utils';
import {
  EMAIL_SEND_CATEGORIES,
  MEMBER_USER_STATUS,
} from '../../../Common/constant';
import { LOG_SEND_MAIL_TYPE, emailSendCategory } from 'Common/constant_text';
import UserDelete from 'assets/image/delete_user.png';

function LogSendList(props) {
  const { labelDb } = props;
  const {
    logSendSms = [],
    pageIndex,
    pageSize,
    loading,
    handleClickDetail,
    filter = { sort: {} },
    onSort = (sortByKey) => {},
  } = props;

  const displayHtmlContent = (content) => {
    const brRegex = new RegExp(`<br />`, 'gi');
    return (content || '')
      .replace(/<p>/gi, '<span>')
      .replace(/<\/p>/gi, '</span>')
      .replace(brRegex, '');
  };

  useEffect(() => {
    changeAllLinksForContent('content-email-log');
  }, []);

  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="タイトル"
                    name="title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>送信元ユーザー</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="createdDate"
                    onClick={onSort}
                    defaultName="createdDate"
                  />
                </TableCell>
                <TableCell>カテゴリー</TableCell>
                <TableCell>タイプ</TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendSms.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (
                      <span>{item?.user?.phoneNumber}</span>
                    )}
                    {/*AGENT*/}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (
                      <span>{item?.agent?.phoneNumber}</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (
                      <Link
                        to={`/user/${item.user?.id}/detail`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '8px' }}
                      >
                        <img
                          className="imgRound"
                          src={
                            item.user?.avatar ? item.user.avatar.url : NoPhoto
                          }
                          alt=""
                        />
                      </Link>
                    )}
                    {/*AGENT*/}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (
                      <Link
                        to={`/user/${item.agent?.id}/detail`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '8px' }}
                      >
                        <img
                          className="imgRound"
                          src={
                            item.agent?.avatar ? item.agent.avatar.url : NoPhoto
                          }
                          alt=""
                        />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (
                      <div>{item?.user?.nickName}</div>
                    )}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (
                      <div>{item?.agent?.nickName}</div>
                    )}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER &&
                      Util.genderIdToText(item.user?.gender)}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT &&
                      Util.genderIdToText(item.agent?.gender)}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell align="center">
                    {item.sender && (
                      <Link
                        to={`/user/${item.sender.id}/detail`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '8px' }}
                      >
                        <img
                          className="imgRound"
                          src={
                            item.sender.memberStatus ===
                            MEMBER_USER_STATUS.IN_ACTIVE
                              ? UserDelete
                              : item.sender.avatar?.url || NoPhoto
                          }
                          alt=""
                        />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>{item.sender?.nickName}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    {
                      emailSendCategory.find((e) => e.id === item?.category)
                        ?.value
                    }
                  </TableCell>
                  <TableCell>
                    {item?.type
                      ? LOG_SEND_MAIL_TYPE.find((e) => e.value === item?.type)
                          ?.label
                      : ''}
                  </TableCell>
                  <TableCell
                    className="d-color-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default LogSendList;
