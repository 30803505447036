import { call, put, takeLatest } from 'redux-saga/effects';
import NameStateJob from '../Types/stateJob';
import { getAllAreaJob, getNameStateJob, updateNameStateJob } from '../../Api/resourseJobApi';
import {
  loadAllAreaJobFail,
  loadAllAreaJobSuccess,
  loadStateJobFail,
  loadStateJobSuccess,
  updateNameStateJobFail,
  updateNameStateJobSuccess,
} from '../Actions/nameStateJob';

function* handleLoadNameStateJob(action) {
  try {
    const response = yield call(getNameStateJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadStateJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadStateJobFail(error));
  }
}

function* handleLoadAllAreaJob(action) {
  try {
    const response = yield call(getAllAreaJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadAllAreaJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadAllAreaJobFail(error));
  }
}

function* handleUpdateNameStateJob(action) {
  try {
    const response = yield call(updateNameStateJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(updateNameStateJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(updateNameStateJobFail(error));
  }
}

export default function* nameStateJobSaga() {
  yield takeLatest(NameStateJob.LOAD_STATE_JOB, handleLoadNameStateJob);
  yield takeLatest(NameStateJob.LOAD_ALL_AREA_JOB, handleLoadAllAreaJob);

  // Manage
  yield takeLatest(NameStateJob.UPDATE_ROOT_STATE_JOB, handleUpdateNameStateJob);


}
