const Resource = {
  LOAD_STATE_JOB: 'LOAD_STATE_JOB',
  LOAD_STATE_JOB_SUCCESS: 'LOAD_STATE_JOB_SUCCESS',
  LOAD_STATE_JOB_FAIL: 'LOAD_STATE_JOB_FAIL',

  LOAD_ALL_AREA_JOB: 'LOAD_ALL_AREA_JOB',
  LOAD_ALL_AREA_JOB_SUCCESS: 'LOAD_ALL_AREA_JOB_SUCCESS',
  LOAD_ALL_AREA_JOB_FAIL: 'LOAD_ALL_AREA_JOB_FAIL',

  UPDATE_ROOT_STATE_JOB: 'UPDATE_ROOT_STATE_JOB',
  UPDATE_STATE_JOB_SUCCESS: 'UPDATE_ROOT_STATE_JOB_SUCCESS',
  UPDATE_STATE_JOB_FAIL: 'UPDATE_ROOT_STATE_JOB_FAIL',
};

export default Resource;
