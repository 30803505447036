import React, { useRef, useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import style from './style.module.scss';
import { uploadImage } from '../../../../../../Api/uploadApi';
import moment from 'moment';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { APP_ALLOW_UPLOAD_TYPE, DIR_FOLDER_IMAGE } from '../../../../../../Common/constant';
import configs from '../../../../../../Config/config';
import SendImageIcon from '../../../../../../assets/image/send_image.png';
import SendContentIcon from '../../../../../../assets/image/send_content.png';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      multiline: {
        padding: '8px 14px',
      },
    },
  },
});
function Chatbox(props) {
  const [avatar, setavatar] = useState('');
  const [imgUpload, setimgUpload] = useState(null);
  const [loading, setloading] = useState(false);
  const [isShowingCropper, setIsShowingCropper] = useState(false);
  const imageInputRef = React.useRef();
  const [cropper, setCropper] = useState({
    image: '',
    crop: {
      x: 0,
      y: 0,
    },
    zoom: 1,
    aspect: NaN,
  });
  const cropperTest = useRef(null);

  const onClickButton = (avatar) => {
    if (!props.disabled) {
      if (avatar.type !== "click") {
        handleUploadSuccess(avatar);
      } else {
        props.onButtonClick();
      }
    }
  };
  const handleUploadSuccess = async (file) => {
    const metadata = {
      contentType: 'image/jpeg',
    };
    try {
      setloading(true);
      if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
        const filename = new Date().getTime() + '.jpg';
        const formData = new FormData();
        formData.append('imageFile', new File([file], filename));
        const dir = `${DIR_FOLDER_IMAGE.CHAT_ADMIN}/${props.conversationId}/${moment().format('YYYYMMDD')}`;
        const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
        const { data: uploaded } = await uploadImage(formData, { dir: btoa(dir) });
        if (uploaded.data) {
          props.onButtonClickForSendImageFunc(imagePath);
          setloading(false);
          setimgUpload(null);
          setavatar('');
          document.getElementById('imgUpload').reset();
        }
      }
    } catch (error) {
      setloading(false);
    }
  };

  const onChangeImg = e => {
    cropper.image = URL.createObjectURL(e.target.files[0]);
    cropper.zoom = 1;
    setCropper(cropper);
    setIsShowingCropper(true);
  };

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(cropperTest.current.cropper.getCroppedCanvas());
    setavatar(croppedImage);
    setimgUpload(URL.createObjectURL(croppedImage));
    setIsShowingCropper(false);
    onClickButton(croppedImage);
  }

  const getCroppedImg = async (canvas) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        resolve(file)
      }, 'image/jpeg')
    })
  }

  const cancelCroppedImage = () => {
    setIsShowingCropper(false);
    if (imageInputRef) {
      imageInputRef.current.value = null;
    }
  };

  let cropperHTML = '';
  if (isShowingCropper) {
    cropperHTML = (
      <Dialog BackdropProps={{ style: { backgroundColor: '#000', opacity: 0.3 } }}
              open={true}
              className={'d-wrapper-upload'}
              PaperProps={{ className: 'd-mui-paper' }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">

        <div id="alert-dialog-title" className={'d-head-upload'}>
          <div className={`d-title box-header`}>
            <div>画像トリミング</div>
          </div>
          <div className={'d-clear-icon'}>
            <ClearIcon style={{ cursor: 'pointer' }}
                       onClick={cancelCroppedImage}
                       color="action" />
          </div>
        </div>

        <DialogContent style={{ display: 'block' }} className={'d-content-upload'}>
          <div style={{ fontSize: '14px', marginTop: '4px 0' }}>
            <p style={{ margin: 'inherit' }}>画像をアップロードします <br />
              不適切な画像は削除させていただくことがありますのでご注意下さい。</p>
          </div>
          <div className="cropper">
            <div className="crop-container">
              <Cropper
                ref={cropperTest}
                src={cropper.image}
                aspectRatio={cropper.aspect}
                guides={false}
                zoomable={false}
                className="crop-content"
              />
            </div>
            <div style={{
              textAlign: 'center',
              paddingBottom: 20,
              paddingTop: 20,
              display: 'flex',
              justifyContent: 'space-around',
            }}>
              <Button onClick={showCroppedImage} variant="contained" className="btn-submit" color="primary">
                  アップロード
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }


  return (
    <div>
      <Table className='wrapper-cropprer-modal'>
        <TableBody>
          <TableRow className={style.__table__row}>
            <TableCell padding="none">
              <form id="imgUpload">
                <label style={{ cursor: "pointer" }}>
                  <img src={SendImageIcon} alt="" />
                  <input ref={imageInputRef} hidden accept="image/*" type="file" onChange={onChangeImg}  />
                  {cropperHTML}
                </label>
              </form>
            </TableCell>
            <TableCell padding="none" className={style.__table__cell}>
              <MuiThemeProvider theme={customTheme}>
                <TextField
                  id="outlined-full-width"
                  name="text"
                  multiline
                  onChange={e => props.onTextChange(e)}
                  margin="none"
                  variant="outlined"
                  value={props.text}
                  className={style.__textfield}
                />
              </MuiThemeProvider>
            </TableCell>
            <TableCell padding="none">
              <div className={style.__table__cell__tow}>
                <img
                  src={SendContentIcon}
                  alt=""
                  onClick={onClickButton}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default Chatbox;
