import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import LoadingBar from '../../Components/LoadingBar';
import { loadHelpMailSmsText } from '../../Redux/Actions/helpMailSmsText';
import SortArrows from '../../Components/SortArrows';
import MailSmsTextFormModal from './MailSmsTextFormModal';
import { replaceMultipleStrings } from '../../Util/utils';
import { parseInt } from 'lodash';
import { parse } from 'uuid';

const pageSize = 999;

function MailSmsTextList() {
  const mailSmsTexts = useSelector((state) => state.helpMailSmsText.data);
  const loading = useSelector((state) => state.helpMailSmsText.loading);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'displayOrder',
    orderBy: 'DESC',
    sort: { displayOrder: 'DESC' },
  });

  const [modalVisible, setModalVisible] = useState(false);

  const [model, setModel] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadHelpMailSmsText({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };

  const handleEdit = (m) => {
    const CODE_FORMAT = parseInt(100); //similar items should have similar displayOrder format, ex: [101, 102], [901, 902, 903]..
    const mailSmsTextValue = mailSmsTexts.filter((item) => {
      return (
        // NOTE: changed the filter function for better performance
        ~~(item.displayOrder / CODE_FORMAT) === ~~(m.displayOrder / CODE_FORMAT)
      );
    });
    setModel(mailSmsTextValue);
    setModalVisible(true);
  };

  const onSuccess = () => {
    dispatch(loadHelpMailSmsText({ ...filter, pageIndex, pageSize }));
  };

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top main-content-custom">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明テキスト</TableCell>
                <TableCell style={{ width: '40%' }}>内容</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="Code"
                    name="code"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示順(管理）</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {(mailSmsTexts || []).map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '40%',
                      minWidth: 200,
                    }}
                  >
                    {item.content}
                  </TableCell>

                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.displayOrder}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 500,
                    }}
                  >
                    {Boolean(item.isEditButton) && (
                      <Button
                        className="btn-cus-font btn-custom btn-blue btn-cus-r5"
                        variant="contained"
                        onClick={() => handleEdit(item)}
                      >
                        編集
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      {modalVisible && (
        <MailSmsTextFormModal
          mailSmsTexts={model}
          onClose={() => setModalVisible(false)}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
}

export default MailSmsTextList;
