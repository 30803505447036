import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import { loadTimerNoti } from '../../../Redux/Actions/timerNoti';
import TimerNotiDetailModal from './TimerNotiDetailModal';
import TimerNotiList from './TimerNotiList';
import TimerNotiSearchBox from './TimerNotiSearchBox';

const pageSize = 50;

function SendingTimerNoti() {
  const loading = useSelector((state) => state.timerNoti.loading);
  const timerNoti = useSelector((state) => state.timerNoti.items);
  const totalRow = useSelector((state) => state.timerNoti.totalRow);
  const [filter, setFilter] = useState({
    timer: 0,
    sort: { 'sendTime': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [timerNotiDetail, setTimerNotiDetail] = useState(null);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTimerNoti({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadTimerNoti({ ...filter, pageIndex, pageSize }));
  };

  const handleClose = () => {
    setTimerNotiDetail(null);
  };

  const reload = () => {
    dispatch(loadTimerNoti({ ...filter, pageIndex, pageSize }));
  };

  const handleClickDetail = (item) => {
    setTimerNotiDetail(item);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ timer: 0, sort: { 'sendTime': 'DESC' } });

  return (
    <div>
      <LoadingBar show={loading} />
      <TimerNotiSearchBox onSubmit={handleSearch} onClear={onClear} />
      {timerNotiDetail && (
        <TimerNotiDetailModal
          onClose={handleClose}
          timerNoti={timerNotiDetail}
          reload={reload}
        />
      )}
      <TimerNotiList
        loading={loading}
        logSendNoti={timerNoti}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default SendingTimerNoti;
