import { call, put, takeLatest } from 'redux-saga/effects';
import JobMembers from '../Types/jobMembers';
import { jobMemberSearch } from '../../Api/jobMemberApi';
import { loadJobMembersFail, loadJobMembersSuccess } from '../Actions/jobMembers';

function* handleLoadJobMembers(action) {
  try {
    const response = yield call(jobMemberSearch, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadJobMembersSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadJobMembersFail(error));
  }
}

export default function* jobMembersSaga() {
  yield takeLatest(JobMembers.LOAD_JOB_MEMBERS, handleLoadJobMembers);
}
