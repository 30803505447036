const User = {
  LOAD_USER: 'LOAD_USER',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_FAIL: 'LOAD_USER_FAIL',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

  LOAD_NAME_RANK: 'LOAD_NAME_RANK',
  LOAD_NAME_RANK_SUCCESS: 'LOAD_NAME_RANK_SUCCESS',
  LOAD_NAME_RANK_FAIL: 'LOAD_NAME_RANK_FAIL',

  LOG_OUT: 'LOG_OUT',
};
export default User;
