import { handleActions } from 'redux-actions';
import Users from '../Types/users';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
  itemsUserIds: [],
  loadingUserIds: false,
  totalRowUserids: 0,
};

const actions = {
  [Users.LOAD_USERS]: (state) => ({
    ...state,
    loading: true,
  }),
  [Users.LOAD_USERS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [Users.LOAD_USERS_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
  [Users.LOAD_USER_IDS]: (state) => ({
    ...state,
    loadingUserIds: true,
  }),
  [Users.LOAD_USER_IDS_SUCCESS]: (state, action) => ({
    ...state,
    itemsUserIds: action.payload.userIds,
    totalRowUserids: action.payload.totalUserIds,
    loadingUserIds: false,
  }),
  [Users.LOAD_USER_IDS_FAIL]: (state) => ({
    ...state,
    itemsUserIds: [],
    loadingUserIds: false,
  }),
};
export default handleActions(actions, initialState);
