import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const createInformation = (info) => sendPost('/cms/information/create', info);
export const getInformation = (id) => sendGet(`/cms/information/${id}`);
export const getInformations = (filter = {}) => {
  return sendPost('/cms/informations', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};

export const updateInformation = (info) => sendPut(`/cms/information/${info.id}`, info);
export const removeInformation = (id) => sendDelete(`/cms/information/${id}`);

