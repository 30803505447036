import {
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import _ from 'lodash';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import { getAllTagAdmin, getAllTagUsers } from '../../../Api/tag';
import {
  GENDER,
  ON_OFF_TYPE,
  OTP_TYPE,
  REGEX_EMAIL,
  REGEX_NUMBER,
} from '../../../Common/constant';
import { TOAST_MESSAGE, settingNotify } from '../../../Common/constant_text';
import DatePicker from '../../../Components/DatePicker';
import DateTimePicker from '../../../Components/DateTimePicker';
import LoadingBar from '../../../Components/LoadingBar';
import RadioGroup from '../../../Components/RadioGroup';
import { PROFILE_TYPE } from '../../../Config/listConstants';
import { loadAgents } from '../../../Redux/Actions/agents';
import { loadAllArea } from '../../../Redux/Actions/nameState';
import { loadOperators } from '../../../Redux/Actions/operators';
import { loadUser, updateUser } from '../../../Redux/Actions/user';
import Util from '../../../Util/Util';
import {
  charaStatusOptionsRequired,
  checkFullWidth,
  convertEnvInfo,
  emailAddressMainStatusRequired,
  inspectionStatusRequired,
  loginControlArrayOptionsRequired,
  memberArrayOptionsRequired,
  memberCategoryTypeRequireOptions,
  memberTypeOfUserOptionsRequired,
  needOtpOptionsRequired,
  registerStatusRequired,
  registerTypeRequired,
  sexOptionsRequired,
  standByRequired,
  validateFullWidthVsNumber,
} from '../../../Util/utils';
import CheckBoxItemModal from './../../../Components/CheckBoxItemModal';
import ImageList from './ImageList';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Index({ location }) {
  const params = useParams();
  const userId = params.userId;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedTagList, setSelectedTagList] = useState([]);
  const [selectedTagAdminList, setSelectedTagAdminList] = useState([]);
  const [tagMembers, setTagMembers] = useState([]);
  const [tagMembersAdmin, setTagMembersAdmin] = useState([]);
  const [listNotify, setListNotify] = useState([]);
  const [selectedListNoti, setSelectedListNoti] = useState([]);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [visitTagShow, setVisitTagShow] = useState(false);
  const [visitTagAdminShow, setVisitTagAdminShow] = useState(false);
  const [visitNotiShow, setVisitNotiShow] = useState(false);
  const [colorLabel, setColorLabel] = useState('');
  const [notificationSetting, setNotificationSetting] = useState({
    pushChat: '0',
    pushLike: '0',
    pushInfo: '0',
    sms_info_support: '0',
    emailInfoSupport: '0',
  });
  const loading = useSelector((state) => state.user.loading);
  const updating = useSelector((state) => state.user.updating);
  const updated = useSelector((state) => state.user.updated);
  const user = useSelector((state) => state.user.data);
  const resource = useSelector((state) => state.resource.data);
  const nameState = useSelector((state) => state.nameState.data);
  const loadingResource = useSelector((state) => state.resource.loading);
  const loadingNameRank = useSelector((state) => state.nameRank.loading);
  const operators = useSelector((state) => state.operators.items);
  const agents = useSelector((state) => state.agents.items);
  const areas = useSelector((state) => state.nameState.dataChild);

  const getAllTagMembers = async () => {
    getAllTagUsers()
      .then((res) => {
        if (res.data) {
          setTagMembers(res.data?.data ?? []);
        }
      })
      .catch((err) => console.error('Error!', err));
  };

  const getAllTagMembersAdmin = async () => {
    getAllTagAdmin()
      .then((res) => {
        if (res.data) {
          setTagMembersAdmin(res.data?.data ?? []);
        }
      })
      .catch((err) => console.error('Error!', err));
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailUser(userId);
    }
  }, [updated]);

  const refreshUser = () => {
    dispatch(loadUser(userId));
  };

  const history = useHistory();
  const redirectPageDetailUser = (id) => {
    history.push(`/user/${id}/detail`);
  };

  const submitEditMember = () => {
    if (!editingUser.nickName) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.nickname);
    }
    if (!editingUser.phoneNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.phoneNumber);
    } else if (
      editingUser.phoneNumber &&
      (checkFullWidth(editingUser.phoneNumber) ||
        !REGEX_NUMBER.test(String(editingUser.phoneNumber)))
    ) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
    }

    if (!editingUser.gender) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.gender);
    }

    if (!editingUser.state) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.state);
    }

    if (editingUser?.depositpoint.toString() === '') {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.depositpoint);
    }

    if (editingUser?.salarypoint.toString() === '') {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.salarypoint);
    }

    if (editingUser.operatorNumber && !Number(editingUser.charaStatus)) {
      return toast.info(TOAST_MESSAGE.INFO.operatorNumber);
    }

    if (editingUser.agentNumber && !Number(editingUser.charaStatus)) {
      return toast.info(TOAST_MESSAGE.INFO.agentNumber);
    }

    if (Number(editingUser.charaStatus) && !editingUser.operatorNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operatorNumber);
    }

    if (Number(editingUser.charaStatus) && !editingUser.agentNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agencyNumber);
    }
    if (Number(editingUser.needOtp) === OTP_TYPE.NO_OTP) {
      if (!editingUser.password) {
        return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password);
      } else if (
        editingUser.password &&
        (String(editingUser.password).match(/\d+/g) ?? []).join('').length !== 6
      ) {
        return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      } else if (editingUser.password && checkFullWidth(editingUser.password)) {
        return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      }
    }

    if (
      editingUser.emailAddressMain &&
      !REGEX_EMAIL.test(editingUser.emailAddressMain)
    ) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
    }

    const updateData = _.pick(
      {
        ...editingUser,
      },
      [
        'id',
        'nickName',
        'emailAddressMain',
        'sendgridStatus',
        'adcode',
        'phoneNumber',
        'password',
        'registerType',
        'gender',
        'birthday',
        'memberType',
        'state',
        'depositpoint',
        'logincontrol',
        'ageverification',
        'memberStatus',
        'rank',
        'operatorNumber',
        'charaStatus',
        'aboutMe',
        'adminMemo',
        'attributes',
        'agentNumber',
        'needOtp',
        'notification',
        'registerStatus',
        'registerInfo',
        'area',
        'standBy',
        'realNameHurigana',
        'postCode',
        'address',
        'salarypoint',
        'registerDate',
        'lastlogin',
        'memberCategory',
        ...DateTimePickerFieldMasterData.map((e) => e.name),
      ],
    );

    if (!updateData.operatorNumber) {
      delete updateData.operatorNumber;
    }
    updateData.tagMemberIds = selectedTagList;
    updateData.tagMemberAdminIds = selectedTagAdminList;

    dispatch(updateUser({ ...updateData, notification: notificationSetting }));
  };

  const rankOptions = useSelector((state) => state.nameRank.data)?.map((x) => ({
    value: x.id,
    label: `${x.markWord} : ${x.label}`,
  }));

  const [isOpenSelect, setIsOpenSelect] = useState({
    operatorNumber: false,
    agentNumber: false,
  });

  useEffect(() => {
    dispatch(loadOperators({ pageIndex: 1, pageSize: 1000 }));
    dispatch(loadAgents({ pageIndex: 1, pageSize: 1000 }));
  }, [dispatch]);

  const [editingUser, setEditingUser] = useState();

  useEffect(() => {
    if (user?.notification) {
      setListNotify(
        settingNotify.map((el) => {
          el.val = user?.notification?.[el.id];
          el.label = el.value; /// Convert modal show check
          return el;
        }),
      );
    }
  }, [user]);

  useEffect(() => {
    if (user && !editingUser) {
      setEditingUser({
        ...user,
        operatorNumber: user.operator?.operatorNumber,
        agentNumber: user.agent?.agentNumber,
      });
      if (user?.state) {
        dispatch(loadAllArea(user?.state));
      }
    }

    if (editingUser) {
      setEditingUser({ ...user, ...editingUser });

      if (user?.state) {
        dispatch(loadAllArea(user?.state));
      }
    }
  }, [dispatch, user]);

  const onChange = (e) => {
    const updatedUser = _.set(editingUser, e.target.name, e.target.value);
    if ('state' === e.target.name) {
      updatedUser.area = null;
    }
    setEditingUser({ ...updatedUser });
  };

  const onChangeDateTime = (e) => {
    const updatedUser = _.set(
      editingUser,
      e.target.name,
      new Date(e.target.value).getTime(),
    );
    setEditingUser({ ...updatedUser });
  };

  const onChangeCustomDateTime = (name, value) => {
    const updatedUser = _.set(editingUser, name, value);
    setEditingUser({ ...updatedUser });
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsNumber(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
        editingUser[name] = '';
      } else {
        editingUser[name] = value;
      }

      setEditingUser({ ...editingUser });
    });
  };

  useEffect(() => {
    getAllTagMembers();
    getAllTagMembersAdmin();
  }, []);

  useEffect(() => {
    if (editingUser && editingUser?.tags) {
      setSelectedTagList(editingUser?.tags.map((i) => i.id));
    }
    if (editingUser && editingUser?.tagsAdmin) {
      setSelectedTagAdminList(editingUser?.tagsAdmin.map((i) => i.id));
    }
  }, [editingUser]);

  const handleChangeTag = (event) => {
    const tag = event.target.value;
    const indexFind = selectedTagList.indexOf(tag);
    if (indexFind !== -1) {
      selectedTagList.splice(indexFind, 1);
    } else {
      selectedTagList.push(tag);
    }

    setSelectedTagList([...selectedTagList]);
  };

  const handleChangeTagAdmin = (event) => {
    const tag = event.target.value;
    const indexFind = selectedTagAdminList.indexOf(tag);
    if (indexFind !== -1) {
      selectedTagAdminList.splice(indexFind, 1);
    } else {
      selectedTagAdminList.push(tag);
    }

    setSelectedTagAdminList([...selectedTagAdminList]);
  };

  const convertChangeTagMemberList = () => {
    return tagMembers.filter((ele) => selectedTagList.includes(ele.id));
  };

  const convertChangeTagMemberAdminList = () => {
    return tagMembersAdmin.filter((ele) =>
      selectedTagAdminList.includes(ele.id),
    );
  };

  const onChangeAttribute = (e) => {
    setEditingUser({
      ...editingUser,
      attributes: {
        ...editingUser.attributes,
        [e.target.name]: Number(e.target.value),
      },
    });
  };

  // Profile
  const showResource = () => {
    let userGender = Number(editingUser.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : editingUser.gender === GENDER.FEMALE
        ? 'female'
        : 'male';

    return resource.items
      .filter(
        (item) =>
          item.value === PROFILE_TYPE.ROOT && item.gender === userGender,
      )
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        return {
          resource: findResources(resourceItem.label, userGender),
          label: resourceItem.label,
          name: resourceItem?.profile,
        };
      })
      .map((item, key) => (
        <Row key={key}>
          <Label xs={12}>
            <FormLabel style={{ color: colorLabel }} component="legend">
              {item.label}
            </FormLabel>
            <div className="d-line-select-border">
              {!loadingResource ? (
                <Select
                  name={item.name}
                  onChange={onChangeAttribute}
                  defaultValue={
                    user?.attributes && user?.attributes[item?.name]
                  }
                  value={
                    editingUser?.attributes &&
                    editingUser?.attributes[item?.name]
                  }
                  className={'w-100 d-select-multiple-cus'}
                >
                  <MenuItem value=""></MenuItem>
                  {item.resource
                    .sort((a, b) => a.value - b.value)
                    .map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        selected={
                          editingUser?.attributes &&
                          +option.value ===
                            +editingUser?.attributes[option?.profile]
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <div></div>
              )}
            </div>
          </Label>
        </Row>
      ));
  };

  const findResources = (resourceName, userGender) => {
    const parentName = resource.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );

    return resource.items.filter((x) => {
      return (
        x.gender === userGender &&
        x?.profile === parentName?.profile &&
        x.value !== 0
      );
    });
  };

  const changeCharaStatus = (e) => {
    const updatedUser = _.set(editingUser, e.target.name, e.target.value);

    if (+e.target.value === 0) {
      const updatedUser = _.set({
        ...editingUser,
        operatorNumber: '',
        agentNumber: '',
      });
      setEditingUser({ ...updatedUser });
      return;
    }

    setEditingUser({
      ...updatedUser,
    });
  };

  const handleChangeState = (e) => {
    onChange(e);
    dispatch(loadAllArea(e.target.value));
  };

  const onChangeAgentOrOpeId = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'operatorNumber':
        setEditingUser({ ...editingUser, operatorNumber: value });
        break;
      case 'agentNumber':
        setEditingUser({ ...editingUser, agentNumber: value });
        break;
      default:
        break;
    }
  };

  const onCheckUserStatus = (name) => () => {
    if (+editingUser.charaStatus === 0) {
      setIsOpenSelect({ operatorNumber: false, agentNumber: false });
      const updatedUser = _.set({
        ...editingUser,
        operatorNumber: '',
        agentNumber: '',
      });

      setEditingUser({ ...updatedUser });
      return;
    }
    setIsOpenSelect({ ...isOpenSelect, [name]: !isOpenSelect[name] });
  };

  useEffect(() => {
    if (listNotify) {
      const listSelected = listNotify
        .filter((v) => +v.val === +ON_OFF_TYPE.ON)
        .map((i) => i.id);
      setSelectedListNoti(listSelected);

      let notificationUser = {};
      listNotify.forEach((i) => {
        notificationUser[i.id] = i.val; /// Set on off 0 Or 1
      });
      setNotificationSetting(notificationUser);
    }
  }, [listNotify]);

  const handleChangeSelectedNoti = (event) => {
    const noti = event.target.value;
    const newListNoti = listNotify.map((item) => {
      return {
        ...item,
        ...(item.id === noti && {
          val:
            +item.val === ON_OFF_TYPE.ON
              ? String(ON_OFF_TYPE.OFF)
              : String(ON_OFF_TYPE.ON),
        }),
      };
    });

    setListNotify(newListNoti);
  };

  const userDetailsFieldTitleText = {
    REGISTER_DATE: '登録日',
    LAST_LOGIN: '最終ログイン',
    FIRST_SENT_MESSAGE: '初回メッセージ送信',
    LAST_SENT_MESSAGE: '最終メッセージ送信',
    FIST_RECEIVED_MESSAGE: '初回メッセージ受信',
    LAST_RECEIVED_MESSAGE: '最終メッセージ受信',
    FIRST_PAID_DATE: '初回入金日',
    LAST_PAID_DATE: '最終入金日',
  };
  
  const DateTimePickerFieldMasterData = useMemo(() => {
    if (!user) return [];
    return [
      {
        name: 'registerDate',
        title: userDetailsFieldTitleText.REGISTER_DATE,
        timestamp: user.registerDate,
      },
      {
        name: 'lastlogin',
        title: userDetailsFieldTitleText.LAST_LOGIN,
        timestamp: user.lastlogin,
      },
      {
        name: 'firstMessageSendingDate',
        title: userDetailsFieldTitleText.FIRST_SENT_MESSAGE,
        timestamp: user.firstMessageSendingDate,
      },
      {
        name: 'lastMessageSendingDate',
        title: userDetailsFieldTitleText.LAST_SENT_MESSAGE,
        timestamp: user.lastMessageSendingDate,
      },
      {
        name: 'firstMessageReceivedDate',
        title: userDetailsFieldTitleText.FIST_RECEIVED_MESSAGE,
        timestamp: user.firstMessageReceivedDate,
      },
      {
        name: 'lastMessageReceivedDate',
        title: userDetailsFieldTitleText.LAST_RECEIVED_MESSAGE,
        timestamp: user.lastMessageReceivedDate,
      },
      {
        name: 'firstPayingDate',
        title: userDetailsFieldTitleText.FIRST_PAID_DATE,
        timestamp: user.firstPayingDate,
      },
      {
        name: 'lastPayingDate',
        title: userDetailsFieldTitleText.LAST_PAID_DATE,
        timestamp: user.lastPayingDate,
      },
    ];
  }, [user]);

  return (
    <>
      <Helmet>
        <title>ユーザー詳細-編集</title>
      </Helmet>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={(loading && !_.isEmpty(resource)) || updating} />

        {visitTagShow && (
          <CheckBoxItemModal
            onClose={() => setVisitTagShow(false)}
            visible={visitTagShow}
            title="タグ設定（プロフ）"
            data={tagMembers}
            selected={selectedTagList}
            handleChange={handleChangeTag}
          />
        )}

        {visitTagAdminShow && (
          <CheckBoxItemModal
            onClose={() => setVisitTagAdminShow(false)}
            visible={visitTagAdminShow}
            title="タグ設定（管理者）"
            data={tagMembersAdmin}
            selected={selectedTagAdminList}
            handleChange={handleChangeTagAdmin}
          />
        )}

        {visitNotiShow && (
          <CheckBoxItemModal
            onClose={() => setVisitNotiShow(false)}
            visible={visitNotiShow}
            title="通知設定"
            data={listNotify}
            selected={selectedListNoti}
            handleChange={handleChangeSelectedNoti}
          />
        )}

        {editingUser && !loadingNameRank && (
          <MuiThemeProvider theme={customTheme}>
            <div className="align">
              <div>
                <Button
                  className="btn-custom btn-red"
                  variant="contained"
                  onClick={() => redirectPageDetailUser(user.id)}
                >
                  閉じる
                </Button>
                <Button
                  className="btn-custom btn-blue mr-3"
                  variant="contained"
                  onClick={submitEditMember}
                >
                  更新する
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={4}>
                <Card body className="mb-4">
                  <ImageList
                    user={user}
                    onUpdateData={refreshUser}
                    loading={loading}
                  />
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        メールアドレス（重複NG）
                      </FormLabel>
                      <input
                        defaultValue={editingUser.emailAddressMain}
                        name="emailAddressMain"
                        type="text"
                        onChange={onChange}
                      />
                    </Label>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        メールアドレス状態
                      </FormLabel>
                      <RadioGroup
                        name="sendgridStatus"
                        defaultValue={editingUser?.sendgridStatus}
                        options={emailAddressMainStatusRequired()}
                        onChange={onChange}
                        disabled={false}
                      />
                    </Label>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        広告コード
                      </FormLabel>
                      <input
                        name="adcode"
                        defaultValue={editingUser.adcode}
                        type="text"
                        maxLength={11}
                        onChange={onChange}
                      />
                    </Label>
                    <Label xs={12}>
                      <InputLabel
                        style={{ color: colorLabel }}
                        className={'m-0'}
                      >
                        オペレーターNumber
                      </InputLabel>
                      <FormControl
                        className={`${classes.formControl} d-line-select-border`}
                      >
                        <Select
                          name="operatorNumber"
                          onOpen={onCheckUserStatus('operatorNumber')}
                          onClose={onCheckUserStatus('operatorNumber')}
                          onChange={onChangeAgentOrOpeId}
                          displayEmpty
                          className={'d-select-multiple-cus'}
                          required={true}
                          open={isOpenSelect.operatorNumber}
                          value={editingUser.operatorNumber || ''}
                          defaultChecked={editingUser.operatorNumber}
                        >
                          <MenuItem key={''} value={''}>
                            ---
                          </MenuItem>
                          {operators.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.operatorNumber}
                            >
                              {option.operatorNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Label>
                    <Label xs={12}>
                      <InputLabel
                        style={{ color: colorLabel }}
                        className={'m-0'}
                      >
                        会社Number
                      </InputLabel>
                      <FormControl
                        className={`${classes.formControl} d-line-select-border`}
                      >
                        <Select
                          name="agentNumber"
                          onOpen={onCheckUserStatus('agentNumber')}
                          onClose={onCheckUserStatus('agentNumber')}
                          onChange={onChangeAgentOrOpeId}
                          displayEmpty
                          className={'d-select-multiple-cus'}
                          required={true}
                          open={isOpenSelect.agentNumber}
                          value={editingUser.agentNumber || ''}
                          defaultChecked={editingUser.agentNumber}
                        >
                          <MenuItem key={''} value={''}>
                            ---
                          </MenuItem>
                          {agents.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.agentNumber}
                            >
                              {option.agentNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Label>
                  </Row>
                </Card>
                {/*notification user*/}
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12} className="">
                      <InputLabel
                        style={{ color: colorLabel }}
                        className={'m-0'}
                        id="mutiple-select-label"
                        onClick={() => setVisitNotiShow(true)}
                      >
                        通知設定
                      </InputLabel>
                      <div
                        className="d-wrapper-custom-select d-border-bottom-form d-flex pb-1"
                        onClick={() => setVisitNotiShow(true)}
                      >
                        {listNotify ? (
                          <div className="wrapper-tag w-100 cursor-pointer">
                            {listNotify?.map((item) => {
                              return (
                                <div
                                  className={[
                                    'tag',
                                    +item.val === ON_OFF_TYPE.ON
                                      ? 'd-blue-active'
                                      : '',
                                  ].join(' ')}
                                  key={item.id}
                                >
                                  {item.label}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <svg
                          className="d-svg-icon-select"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                      </div>
                    </Label>
                  </Row>
                </Card>

                {/*Tag list*/}
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12} className="">
                      <InputLabel
                        style={{ color: colorLabel }}
                        className={'m-0'}
                        id="mutiple-select-label"
                        onClick={() => setVisitTagShow(true)}
                      >
                        タグ設定（プロフ）
                      </InputLabel>
                      <div
                        className="d-wrapper-custom-select d-border-bottom-form d-flex pb-1"
                        onClick={() => setVisitTagShow(true)}
                      >
                        {convertChangeTagMemberList() ? (
                          <div className="wrapper-tag w-100 cursor-pointer">
                            {orderBy(
                              convertChangeTagMemberList() ?? [],
                              ['displayOrder'],
                              ['asc'],
                            ).map((v, i) => (
                              <div className="tag" key={i}>
                                {v.label}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <svg
                          className="d-svg-icon-select"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                      </div>
                    </Label>
                  </Row>
                </Card>

                {/*Tag admin list*/}
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12} className="">
                      <InputLabel
                        style={{ color: colorLabel }}
                        className={'m-0'}
                        id="mutiple-select-label"
                        onClick={() => setVisitTagAdminShow(true)}
                      >
                        タグ設定（管理者）
                      </InputLabel>
                      <div
                        className="d-wrapper-custom-select d-border-bottom-form d-flex pb-1"
                        onClick={() => setVisitTagAdminShow(true)}
                      >
                        {convertChangeTagMemberAdminList() ? (
                          <div className="wrapper-tag w-100 cursor-pointer">
                            {orderBy(
                              convertChangeTagMemberAdminList() ?? [],
                              ['displayOrder'],
                              ['asc'],
                            ).map((v, i) => (
                              <div className="tag" key={i}>
                                {v.label}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <svg
                          className="d-svg-icon-select"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                      </div>
                    </Label>
                  </Row>
                </Card>

                <Card body className="mb-4">
                  {!loadingResource ? showResource() : null}
                </Card>
              </Col>
              <Col xs={8}>
                <Card body className="mb-4">
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>
                            {labelDb?.label_nickName?.content}（全角15文字）
                          </FormLabel>
                          <input
                            defaultValue={editingUser.nickName}
                            name="nickName"
                            type="text"
                            onChange={onChange}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            フリガナ
                          </FormLabel>
                          <input
                            defaultValue={editingUser.realNameHurigana}
                            name="realNameHurigana"
                            type="text"
                            onChange={onChange}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>
                            電話番号（重複NG）（システム管理者のみ変更）
                          </FormLabel>
                          <input
                            style={{ maxWidth: 700 }}
                            name="phoneNumber"
                            type="text"
                            defaultValue={editingUser.phoneNumber}
                            maxLength={13}
                            onChange={onChange}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>
                            パスワード(数字6桁)
                          </FormLabel>
                          <div
                            style={{ display: 'flex', justifyContent: 'left' }}
                          >
                            <input
                              defaultValue={editingUser.password}
                              name="password"
                              type="text"
                              value={editingUser.password}
                              onChange={(e) =>
                                validatePassword(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            ログインSMS必要
                          </FormLabel>
                          <div
                            style={{ display: 'flex', justifyContent: 'left' }}
                          >
                            <RadioGroup
                              name="needOtp"
                              defaultValue={editingUser.needOtp}
                              options={needOtpOptionsRequired()}
                              onChange={onChange}
                            />
                          </div>
                        </Label>

                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            郵便番号
                          </FormLabel>
                          <input
                            name="postCode"
                            type="text"
                            defaultValue={editingUser.postCode}
                            onChange={onChange}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            住所
                          </FormLabel>
                          <input
                            name="address"
                            type="text"
                            defaultValue={editingUser.address}
                            onChange={onChange}
                          />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <FormGroup row>
                        <span className={'d-box-radio-group'}>
                          <FormLabel style={{ color: colorLabel }}>
                            性別
                          </FormLabel>
                          <RadioGroup
                            name="gender"
                            defaultValue={editingUser.gender}
                            options={sexOptionsRequired()}
                            onChange={onChange}
                          />
                        </span>
                        <span className={'d-box-radio-group'}>
                          <FormLabel style={{ color: colorLabel }}>
                            キャラクターステイタス(変更禁止）
                          </FormLabel>
                          <RadioGroup
                            name="charaStatus"
                            options={charaStatusOptionsRequired()}
                            onChange={changeCharaStatus}
                            defaultValue={editingUser.charaStatus}
                          />
                        </span>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            年齢 生年月日{' '}
                            {Util.calcAge(
                              Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
                            )}
                            歳
                          </FormLabel>
                          <DatePicker
                            defaultValue={Util.timestampToDate(
                              user?.birthday,
                              'YYYY-MM-DD',
                            )}
                            name="birthday"
                            onChange={onChangeDateTime}
                          />
                        </Label>
                        <span className={'d-box-radio-group'}>
                          <FormLabel style={{ color: colorLabel }}>
                            会員種類
                          </FormLabel>
                          <RadioGroup
                            name="memberType"
                            defaultValue={user.memberType}
                            options={memberTypeOfUserOptionsRequired()}
                            onChange={onChange}
                          />
                        </span>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            新規登録時環境
                          </FormLabel>
                          <RadioGroup
                            name="registerType"
                            defaultValue={editingUser?.registerType}
                            options={registerTypeRequired()}
                            onChange={onChange}
                            disabled={false}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            登録種類
                          </FormLabel>
                          <RadioGroup
                            name="memberCategory"
                            defaultValue={editingUser?.memberCategory}
                            options={memberCategoryTypeRequireOptions()}
                            onChange={onChange}
                            disabled={false}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            電話認証状態
                          </FormLabel>
                          <RadioGroup
                            name="registerStatus"
                            defaultValue={editingUser?.registerStatus}
                            options={registerStatusRequired()}
                            onChange={onChange}
                            disabled={false}
                          />
                        </Label>
                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            ランク
                          </FormLabel>
                          <div
                            style={{ width: '100%' }}
                            className="d-line-select-border"
                          >
                            <Select
                              name={'rank'}
                              onChange={onChange}
                              defaultValue={user.rank}
                              value={editingUser.rank}
                              className="w-100 d-select-multiple-cus"
                            >
                              {rankOptions.map((option) => (
                                <MenuItem
                                  key={option.label}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Label>

                        <Label xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            待機状態
                          </FormLabel>
                          <RadioGroup
                            name="standBy"
                            defaultValue={editingUser?.standBy}
                            options={standByRequired()}
                            onChange={onChange}
                            disabled={false}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Label xs={12} className={'pl-0 pr-0'}>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>都道府県
                        </FormLabel>
                        <div className="d-line-select-border">
                          {nameState ? (
                            <Select
                              name="state"
                              onChange={handleChangeState}
                              className="w-100 d-select-multiple-cus"
                              defaultValue={user.state}
                              value={editingUser?.state}
                            >
                              <MenuItem value="">---</MenuItem>
                              {nameState?.items
                                .filter((val) => !!val.label)
                                ?.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.id}
                                    selected={option.id === user.state}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </Label>
                      <Label xs={12} className={'pl-0 pr-0'}>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          エリア
                        </FormLabel>
                        <div className="d-line-select-border">
                          {areas ? (
                            <Select
                              name="area"
                              onChange={onChange}
                              className={'w-100 d-select-multiple-cus'}
                              defaultValue={user.area}
                              value={editingUser.area}
                            >
                              <MenuItem value="">---</MenuItem>
                              {areas.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  selected={option.id === user.area}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </Label>
                      {DateTimePickerFieldMasterData.map((e) => (
                        <DateTimePicker
                          key={e.name}
                          name={e.name}
                          title={e.title}
                          titleStyle={{ color: colorLabel }}
                          timestamp={e.timestamp}
                          onChange={onChangeCustomDateTime}
                        />
                      ))}
                      <Label className={'d-box-radio-group pl-0 pr-0'}>
                        <FormLabel style={{ color: colorLabel }}>
                          表示ステイタス（非表示：ログイン禁止・一部のログ削除）
                        </FormLabel>
                        <RadioGroup
                          name="memberStatus"
                          defaultValue={user.memberStatus}
                          options={memberArrayOptionsRequired()}
                          onChange={onChange}
                        />
                      </Label>
                      <Label className={'d-box-radio-group pl-0 pr-0'}>
                        <FormLabel style={{ color: colorLabel }}>
                          年齢認証
                        </FormLabel>
                        <RadioGroup
                          name="ageverification"
                          defaultValue={user.ageverification}
                          options={inspectionStatusRequired()}
                          onChange={onChange}
                        />
                      </Label>
                      <Label className={'d-box-radio-group pl-0 pr-0'}>
                        <FormLabel style={{ color: colorLabel }}>
                          ログイン制御
                        </FormLabel>
                        <RadioGroup
                          name="logincontrol"
                          defaultValue={user.logincontrol}
                          options={loginControlArrayOptionsRequired()}
                          onChange={onChange}
                        />
                      </Label>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Label xs={12} className={'pl-0 pr-0'}>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>
                          ポイント（システム管理者のみ変更）
                        </FormLabel>
                        <input
                          name="depositpoint"
                          type="text"
                          defaultValue={editingUser.depositpoint}
                          onChange={onChange}
                        />
                      </Label>
                      <Label xs={12} className={'pl-0 pr-0'}>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>
                          給与ポイント（システム管理者のみ変更）
                        </FormLabel>
                        <input
                          name="salarypoint"
                          type="text"
                          defaultValue={editingUser.salarypoint}
                          onChange={onChange}
                        />
                      </Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label xs={9}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            自己PR
                          </FormLabel>
                          <textarea
                            defaultValue={editingUser.aboutMe}
                            name="aboutMe"
                            rows="3"
                            onChange={onChange}
                            style={{ width: '100%', padding: 10 }}
                          />
                        </Label>
                      </Row>
                    </Card>
                    <Card body className="mb-4">
                      <Row>
                        <Label xs={9}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            管理者メモ
                          </FormLabel>
                          <textarea
                            defaultValue={editingUser.adminMemo}
                            name="adminMemo"
                            rows="3"
                            onChange={onChange}
                            style={{ width: '100%', padding: 10 }}
                          />
                        </Label>
                      </Row>
                    </Card>
                    <Card body className="mb-4">
                      <Row>
                        <Label xs={9}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            新規登録時情報
                          </FormLabel>
                          <textarea
                            defaultValue={editingUser.registerInfo}
                            name="registerInfo"
                            rows="3"
                            onChange={onChange}
                            style={{ width: '100%', padding: 10 }}
                          />
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MuiThemeProvider>
        )}
      </div>
    </>
  );
}

export default Index;
