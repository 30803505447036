import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SelectList from './SelectList';

const currentYear = moment().year();
const yearOptions = new Array(currentYear + 3 - 1899)
  .fill(0)
  .map((x, i) => currentYear + 3 - i)
  .reverse()
  .map((x) => ({ value: x, label: x }));
const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => ({
  value: x,
  label: x,
}));
const dayOptions = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
].map((x) => ({ value: x, label: x }));

function DatePicker({ defaultValue, name, onChange }) {
  const [date, setDate] = useState();
  useEffect(() => {
    const defaultDate = moment(defaultValue);
    setDate({
      day: defaultDate.date() || 1,
      month: defaultDate.month() + 1 || 0,
      year: defaultDate.year() || 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  window.moment = moment;
  const handleChange = (e) => {
    date[e.target.name] = e.target.value;
    setDate({ ...date });
    const dateString = moment()
      .set('year', date.year)
      .set('month', date.month - 1)
      .set('date', date.day)
      .startOf('day')
      .format();
    onChange({
      target: {
        name,
        value: dateString,
      },
    });
  };
  if (!date) return null;
  return (
    <div style={{ width: '100%' }} className="d-flex align-items-center">
      <SelectList
        options={yearOptions}
        name="year"
        defaultValue={date.year}
        onChange={handleChange}
      />
      <span className="px-2">年</span>
      <SelectList
        options={monthOptions}
        name="month"
        defaultValue={date.month}
        onChange={handleChange}
      />
      <span className="px-2">月</span>
      <SelectList
        options={dayOptions}
        name="day"
        defaultValue={date.day}
        onChange={handleChange}
      />
      <span className="px-2">日</span>
    </div>
  );
}

export default DatePicker;
