import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loadSearchPresetsFail,
  loadSearchPresetsSuccess,
} from '../Actions/searchPresets';
import { getPresets } from '../../Api/userAPI';
import { toast } from 'react-toastify';
import SearchPresets from '../Types/searchPresets';

function* handleLoadSearchPresets(action) {
  try {
    const { data } = yield call(getPresets, action.payload);
    if (data.success) {
      const presets = data.data;
      yield put(loadSearchPresetsSuccess(presets));
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    yield put(loadSearchPresetsFail(err));
  }
}

export default function* searchPresetsSaga() {
  yield takeLatest(SearchPresets.LOAD_SEARCH_PRESETS, handleLoadSearchPresets);
}
