import Button from '@material-ui/core/Button';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { fetchFolderList, removeFileContentsImageWeb, uploadFile } from '../../Api/uploadApi';
import { useSelector } from 'react-redux';

import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import Util from '../../Util/Util';
import CloseIcon from '@material-ui/icons/Close';
import { getUsers } from '../../Api/userAPI';
import DragDropFile from '../../Components/DragDropFile';
import axios from 'axios';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../Common/constant';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL, UPLOAD_FILE_IMAGE_TO_CONTENTS_IMAGE_WEB } from '../../Common/modal_text_info';
import RemoveFileModal from './RemoveFileModal';
import { accessAndPassDataIframe, fileDataType } from '../../Util/utils';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';
import ImageModal from '../../Components/ImageModal';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const folderWebList = [
  'APP-Webview/webview_web/Lp',
  'APP-Webview/webview_web/Information',
  'APP-Webview/webview_web/Information/DetailInfo',
  'APP-Webview/webview_web/Buy_Point',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint',
  'APP-Webview/webview_web/Help',
];

const folderAppList = [
  'APP-Webview/webview_app/Lp',
  'APP-Webview/webview_app/buy',
  'APP-Webview/webview_app/Help',
  'APP-Webview/webview_app/image',
  'APP-Webview/webview_app/Information',
  'APP-Webview/webview_app/Information/DetailInfo',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint',
  'APP-Webview/webview_app/Buy_Point',
];

const folderContentsImageWeb = [
  'APP-Image/contents_image_web',
];

const explain = (fileName) => {
  switch (fileName) {
    case 'privacy_web.html':
      return 'Web-LP-プラーバシーポリシー';
    case 'terms_web.html':
      return 'Web-LP-利用規約';
    case 'transaction_web.html':
      return 'Web-LP-特定商取引';
    case 'buy_point_menu_web.html':
      return 'WEB-ポイント購入メニュー';
    case 'buy_point_bank_web1.html':
      return 'WEB-ポイント購入1-銀行振込';
    case 'buy_point_bank_web3.html':
      return 'WEB-ポイント購入-3-コンビニ（BitCash）';
    case 'buy_point_bank_web4.html':
      return 'WEB-ポイント購入ページ-4';
    case 'buy_point_bank_web5.html':
      return 'WEB-ポイント購入ページ-5';
    case 'buy_point_bank_web6.html':
      return 'WEB-ポイント購入ページ-6';
    case 'buy_point_bank_web7.html':
      return 'WEB-ポイント購入ページ-7';
    case 'buy_point_bank_web8.html':
      return 'WEB-ポイント購入ページ-8';
    case 'buy_point_stripe_web2.html':
      return 'WEB-ポイント購入2-クレジット（Stripe）';
    case 'infopage_menu_web.html':
      return 'WEB-お知らせメニュー';
    case 'infopage1_web.html':
      return 'WEB-お知らせページ-1';
    case 'infopage2_web.html':
      return 'WEB-お知らせページ-2';
    case 'infopage3_web.html':
      return 'WEB-お知らせページ-3';
    case 'infopage4_web.html':
      return 'WEB-お知らせページ-4';
    case 'infopage5_web.html':
      return 'WEB-お知らせページ-5';
    case 'infopage6_web.html':
      return 'WEB-お知らせ6-特定商取引';
    case 'infopage7_web.html':
      return 'WEB-お知らせ7-利用規約';
    case 'infopage8_web.html':
      return 'WEB-お知らせ8-プラーバシーポリシー';
    case 'help_web.html':
      return 'WEB-ヘルプページ';
    case 'privacy_app.html':
      return 'アプリ-LP-プラーバシーポリシー';
    case 'terms_app.html':
      return 'アプリ-LP-利用規約';
    case 'transaction_app.html':
      return 'アプリ-LP-特定商取引';
    case 'point_stripe_app.html':
      return 'アプリ-ポイント購入-GooglePay';
    case 'infopage_menu_app.html':
      return 'アプリ-お知らせメニュー';
    case 'infopage1_app.html':
      return 'アプリ-お知らせページ-1';
    case 'infopage2_app.html':
      return 'アプリ-お知らせページ-2';
    case 'infopage3_app.html':
      return 'アプリ-お知らせページ-3';
    case 'infopage4_app.html':
      return 'アプリ-お知らせページ-4';
    case 'infopage5_app.html':
      return 'アプリ-お知らせページ-5';
    case 'infopage6_app.html':
      return 'アプリ-お知らせ6-特定商取引';
    case 'infopage7_app.html':
      return 'アプリ-お知らせ7-利用規約';
    case 'infopage8_app.html':
      return 'アプリ-お知らせ8-プラーバシーポリシー';
    case 'buy_point_menu_app.html':
      return 'アプリ-ポイント購入メニュー';
    case 'buy_point_google_app1.html':
      return 'アプリ-ポイント購入1-グーグル';
    case 'buy_point_stripe_app2.html':
      return 'アプリ-ポイント購入2-クレジット（Stripe）';
    case 'buy_point_bank_app3.html':
      return 'アプリ-ポイント購入ページ-3';
    case 'buy_point_bank_app4.html':
      return 'アプリ-ポイント購入ページ-4';
    case 'buy_point_bank_app5.html':
      return 'アプリ-ポイント購入ページ-5';
    case 'buy_point_bank_app6.html':
      return 'アプリ-ポイント購入ページ-6';
    case 'buy_point_bank_app7.html':
      return 'アプリ-ポイント購入ページ-7';
    case 'buy_point_bank_app8.html':
      return 'アプリ-ポイント購入ページ-8';
    case 'help_app.html':
      return 'アプリ-ヘルプページ';
    case 'point_google_app.html':
      return 'アプリ-ポイント購入-GooglePay';
    case 'header_home.png':
      return 'アプリ-ロゴ（LP）';
    case 'lp_text.css':
      return 'Web-LPtext-CSS（編集禁止）';
    case 'sp_lp_text.css':
      return 'Web-LP-SPtext-CSS（編集禁止）';
    case 'help.css':
      return 'Web-ヘルプ-CSS（編集禁止）';
    case 'help.js':
      return 'Web-ヘルプ-JS（編集禁止）';

    default:
      return '';
  }
};

const webUrl = {
  'APP-Webview/webview_web/Lp/privacy_web.html': 'lp/text/privacy_web_frame.html',
  'APP-Webview/webview_web/Lp/terms_web.html': '/lp/text/terms_web_frame.html',
  'APP-Webview/webview_web/Lp/transaction_web.html': 'lp/text/transaction_web_frame.html',
  'APP-Webview/webview_web/Information/infopage_menu_web.html': 'info',
  'APP-Webview/webview_web/Information/DetailInfo/infopage1_web.html': 'info/info1',
  'APP-Webview/webview_web/Information/DetailInfo/infopage2_web.html': 'info/info2',
  'APP-Webview/webview_web/Information/DetailInfo/infopage3_web.html': 'info/info3',
  'APP-Webview/webview_web/Information/DetailInfo/infopage4_web.html': 'info/info4',
  'APP-Webview/webview_web/Information/DetailInfo/infopage5_web.html': 'info/info5',
  'APP-Webview/webview_web/Information/DetailInfo/infopage6_web.html': 'info/info6',
  'APP-Webview/webview_web/Information/DetailInfo/infopage7_web.html': 'info/info7',
  'APP-Webview/webview_web/Information/DetailInfo/infopage8_web.html': 'info/info8',
  'APP-Webview/webview_web/Buy_Point/buy_point_menu_web.html': 'point',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web1.html': 'point/point1',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web3.html': 'point/point3',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web4.html': 'point/point4',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web5.html': 'point/point5',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web6.html': 'point/point6',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web7.html': 'point/point7',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_bank_web8.html': 'point/point8',
  'APP-Webview/webview_web/Buy_Point/DetailBuyPoint/buy_point_stripe_web2.html': 'point/point2',
  'APP-Webview/webview_web/Help/help_web.html': 'help',
  'APP-Webview/webview_app/Lp/privacy_app.html': 'webview_app/Lp/privacy_app.html',
  'APP-Webview/webview_app/Lp/terms_app.html': 'webview_app/Lp/terms_app.html',
  'APP-Webview/webview_app/Lp/transaction_app.html': 'webview_app/Lp/transaction_app.html',
  'APP-Webview/webview_app/buy/point_google_app.html': 'webview_app/buy/point_google_app.html',
  'APP-Webview/webview_app/Help/help_app.html': 'webview_app/Help/help_app.html',
  'APP-Webview/webview_app/image/header_home.png': 'webview_app/image/header_home.png',
  'APP-Webview/webview_app/Information/infopage_menu_app.html': 'webview_app/Information/infopage_menu_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage1_app.html': 'webview_app/Information/DetailInfo/infopage1_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage2_app.html': 'webview_app/Information/DetailInfo/infopage2_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage3_app.html': 'webview_app/Information/DetailInfo/infopage3_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage4_app.html': 'webview_app/Information/DetailInfo/infopage4_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage5_app.html': 'webview_app/Information/DetailInfo/infopage5_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage6_app.html': 'webview_app/Information/DetailInfo/infopage6_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage7_app.html': 'webview_app/Information/DetailInfo/infopage7_app.html',
  'APP-Webview/webview_app/Information/DetailInfo/infopage8_app.html': 'webview_app/Information/DetailInfo/infopage8_app.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app3.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app3.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app4.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app4.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app5.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app5.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app6.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app6.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app7.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app7.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app8.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_bank_app8.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_google_app1.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_google_app1.html',
  'APP-Webview/webview_app/Buy_Point/DetailBuyPoint/buy_point_stripe_app2.html': 'webview_app/Buy_Point/DetailBuyPoint/buy_point_stripe_app2.html',
  'APP-Webview/webview_app/Buy_Point/buy_point_menu_app.html': 'webview_app/Buy_Point/buy_point_menu_app.html',
};

function FileUpload() {
  const [detailFolder, setDetailFolder] = useState([]);
  const [showDialog, setShowDialog] = useState({
    isShow: false,
    webUrl: '',
  });
  const [user, setUser] = useState();
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [value, setValue] = useState('');
  const [confirmKey, setConfirmKey] = useState('');
  const [typeShowFile, setTypeShowFile] = useState([
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'TYPE_WEB', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'TYPE_APP', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'TYPE_CONTENT_IMAGE_WEB', order: -1, content: '' }]);

  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [indexInput, setIndexInput] = useState(false);
  const [indexInputValue, setIndexInputValue] = useState(false);
  const [fileNameChose, setFileNameChose] = useState('');
  const defineShows = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.NOT_LINK];
  const admin = useSelector((state) => state.admin.data);
  const envUICms = useSelector((state) => state.envUICms.data);

  const fileWeb = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.FILE_UPLOAD_WEB);
  const fileApp = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.FILE_UPLOAD_APP);
  const fileContentImageWeb = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.FILE_UPLOAD_CONTENT_IMAGE_WEB);
  const [previewingImage, setPreviewingImage] = useState(null);
  const [openModalUploadImage, setOpenModalUploadImage] = useState(false);
  const [refresh, setRefesh] = useState(false)

  const setDataTypeShowAllActive = () => {
    const tmp = typeShowFile.map(ele => ({ ...ele, type: DISPLAY_CMS_UI_TYPE.ON }));
    setTypeShowFile(uniqBy(tmp, 'active'));
  };

  const setDataTypeShowActive = (key = '', payload) => {
    let findActive = typeShowFile.find(ele => ele.active === key);

    if (findActive) {
      Object.assign(findActive, payload);
      setTypeShowFile(orderBy(uniqBy(Object.assign([], [...typeShowFile, findActive]), 'active'), ['order'], ['asc']));
    }
  };

  useEffect(() => {
    let typeSysAdmin = false;
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setDataTypeShowAllActive();
      typeSysAdmin = true;
    }

    // Check type show setting
    if (fileWeb) {
      setDataTypeShowActive('TYPE_WEB', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +fileWeb.type),
        order: fileWeb.order,
        content: fileWeb.content,
      });
    }
    if (fileApp) {
      setDataTypeShowActive('TYPE_APP', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +fileApp.type),
        order: fileApp.order,
        content: fileApp.content,
      });
    }
    if (fileContentImageWeb) {
      setDataTypeShowActive('TYPE_CONTENT_IMAGE_WEB', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +fileContentImageWeb.type),
        order: fileContentImageWeb.order,
        content: fileContentImageWeb.content,
      });
    }

  }, [admin]);

  useEffect(() => {
    let findActive = typeShowFile.filter(ele => ele.type === DISPLAY_CMS_UI_TYPE.ON);
    if (findActive && findActive.length) {
      setValue(findActive[0].active);
    }
  }, [typeShowFile]);

  const [fileData, setFileData] = useState(fileDataType);
  const inputEl = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          folderList: [],
        };
        if (value === 'TYPE_APP' && typeShowFile.findIndex(ele => ele.active === 'TYPE_APP' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) {
          payload.folderList = [...folderAppList];
        }
        if (value === 'TYPE_WEB' && typeShowFile.findIndex(ele => ele.active === 'TYPE_WEB' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) {
          payload.folderList = [...folderWebList];
        }
        if (value === 'TYPE_CONTENT_IMAGE_WEB' && typeShowFile.findIndex(ele => ele.active === 'TYPE_CONTENT_IMAGE_WEB' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) {
          payload.folderList = [...folderContentsImageWeb];
        }
        if (!payload.folderList.length) {
          setDetailFolder([]);
          return;
        }
        const { data } = await fetchFolderList(payload);
        const dataFetch = data.data;

        if (value === 'TYPE_WEB') {
          const detailBuyPoint = dataFetch['APP-Webview/webview_web/Buy_Point/DetailBuyPoint'];

          detailBuyPoint.splice(1, 0, detailBuyPoint.splice(7, 1)[0]);
          dataFetch['APP-Webview/webview_web/Buy_Point/DetailBuyPoint'] = detailBuyPoint;
        }

        setDetailFolder(dataFetch);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    getUsers().then(res => {
      setUser(res.data?.data[0]);
    });
  }, [value, refresh]);

  const openModalFileChanged = (index, fileName, path) => {
    setIndexInput(index);
    setIndexInputValue(path);
    setFileNameChose(fileName);

    setShowDialogDragDrop(true);
  };

  const inputFileChangedClick = (files) => {
    setShowDialogDragDrop(false);
    let file = files[0];
    const listPath = indexInputValue.split('/');

    if (file) {
      let reader = new FileReader();
      if (listPath.includes('image')) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file, 'UTF-8');
      }
      reader.onload = function(evt) {
        setFileData({ pathUpload: indexInputValue, fileName: file.name, fileContent: evt.target.result });
      };

      setShowUploadDialog(true);

      reader.onerror = function(evt) {
        console.error('error reading file');
      };
    }
  };

  const uploadImageWeb = (files) => {
    setOpenModalUploadImage(false);
    let file = files[0]
    let reader = new FileReader();
    if(file){
      if (file.type.includes('image')) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file, 'UTF-8');
      }
    }
    reader.onload = function(evt) {
      setFileData({ pathUpload: folderContentsImageWeb[0], fileName: file.name, fileContent: evt.target.result });
    };

    setShowUploadDialog(true);

    reader.onerror = function(evt) {
      console.error('error reading file');
    };
  }

  const onCloseDialogDragDrop = () => {
    setIndexInput(-1);
    setIndexInputValue('');
    setFileNameChose('');
    setShowDialogDragDrop(false);
  };

  const handleSubmit = () => {
    const { fileName, fileContent, pathUpload } = fileData;
    uploadFile({ fileName, fileContent, pathUpload }).then((response) => {
      const { data } = response;
      if (data.success) {
        toast.success('ファイルをアップロードしました');
        onCloseUploadDialog()
        setRefesh(!refresh)
      } else {
        toast.error('アップロードに失敗しました、ファイル名を確認して下さい');
      }
    });
  };

  // const removeFile = (e, key) => {
  //   if (window.confirm('ファイルを削除してもよろしいですか？')) {
  //     removeFileBuild({ key }).then((response) => {
  //       const { data } = response;
  //       if (data.success) {
  //         toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
  //       } else {
  //         toast.error(TOAST_MESSAGE.ERROR_DELETE);
  //       }
  //     });
  //   }
  // };

  const handleRemoveConfirm = (e, key) => {
    setConfirmKey(key);
  };

  const handleRemoveClose = () => {
    setConfirmKey('');
  };


  const downloadFile = async (e, link, filename) => {
    const urlFileContentsImageWeb = `${value === 'TYPE_CONTENT_IMAGE_WEB' ? Util.domainWebUseWithUseWWW() : ''}/${link.replace('APP-Webview/', '')}/${filename}`;

    const getUrl = () => {
      if (value === 'TYPE_CONTENT_IMAGE_WEB') {
        return urlFileContentsImageWeb.replace('APP-Image/', '');
      }

      const host = value === 'TYPE_WEB' ? Util.domainWebUseWithUseWWW() : '';
      return `${host}/${link.replace('APP-Webview/', '')}/${filename}`;
    };

      await axios({
        url: `${getUrl()}?timestamp=${new Date().getTime()}`,
        method: 'GET',
        responseType: 'blob',
        cache: 'no-cache',
      }).then(res => {

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      if (res.statusText === 'OK') {
        toast.success('保存しました');
      } else {
        toast.error('保存に失敗しました');
      }

    }).catch((err) => {
      toast.error('保存に失敗しました');
    })
  };

  const onShowDialog = (webUrl) => setShowDialog({ isShow: true, webUrl });

  const onCloseDialog = () => setShowDialog({ isShow: false, webUrl: '' });

  const onCloseUploadDialog = () => {
    setShowUploadDialog(false);
    setOpenModalUploadImage(false)
    setFileData(fileDataType);
    // Remove set data
    setIndexInput(-1);
    setIndexInputValue('');
    setFileNameChose('');
  };

  const styleTableCell = {
    height: '45px',
    padding: '0.25rem 0.75rem',
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const clickTabSelected = (selected) => {
    setValue(selected.active);
  };

  const accessAndPassData = () =>  {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
        '.iframe-pre-hidden-top-menu'
      ],
    });
  }

  const handleShowImageModal = (link, fileName) => {
    const imageUrl = {
      url: `${value === 'TYPE_CONTENT_IMAGE_WEB' ? Util.domainWebUseWithUseWWW() : ''}/${link.replace('APP-Image/', '')}/${fileName}`,
    }
    setPreviewingImage(imageUrl);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  const handleDeleteFileImage = (link, fileName) => {
    if (window.confirm('ファイルを削除してもよろしいですか？')) {
      removeFileContentsImageWeb({ fileName }).then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          setRefesh(!refresh)
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    }
  }

  return (
    <div className='mt-3'>
      <MuiThemeProvider theme={tableNewTheme}>
        <Tabs scrollButtons="auto"
              indicatorColor="unset"
              textColor="white"
              value={value}
              onChange={handleChangeTab}
              className="d-tab-wrapper tab-file-upload-wrapper"
        >
          {typeShowFile && typeShowFile.map((ele, index) => (
              <>
                {defineShows?.includes(ele.type) ? (
                    <Tab disableRipple
                         key={index}
                         value={ele.active}
                         onClick={() => clickTabSelected(ele)}
                         className={`${value === ele.active ? 'btn-blue' : ''} btn-custom`}
                         label={ele.content}
                         title={ele.content} />)
                  : <div key={index}></div>}
              </>
            ),
          )}
        </Tabs>

        {value && (typeShowFile.findIndex(ele => ele.active === value && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
          <Card className={'mt-3'} style={{ width: '100%' }} key={value}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table mb-0'>
              <TableBody>
                <TableRow>
                  <TableCell>ファイル名</TableCell>
                  {value !== 'TYPE_CONTENT_IMAGE_WEB' &&
                    <TableCell>DATA名</TableCell>
                  }
                  {value === 'TYPE_CONTENT_IMAGE_WEB' &&
                    <TableCell>バナー画像</TableCell>
                  }
                  <TableCell></TableCell>
                  <TableCell>
                    <div style={{display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                      {value === 'TYPE_CONTENT_IMAGE_WEB' &&
                        <div style={{marginLeft:50}}>
                          <Button
                            className="btn-cus-font btn-blue"
                            onClick={() => setOpenModalUploadImage(true)}
                          >
                            ファイルアップ
                          </Button>
                        </div>
                      }
                    </div>
                  </TableCell>
                </TableRow>

                {Object.keys(detailFolder).map((val, index) => {
                  return detailFolder[val].map((item, i) => {
                    const webUrlKey = `${val}/${item}`;
                    const webUrlData = webUrl[webUrlKey] || '';
                    return (
                      <TableRow key={`${item}-${i}`}>
                        <TableCell style={styleTableCell}>{value === 'TYPE_CONTENT_IMAGE_WEB' ? item : `${explain(item)}`}</TableCell>
                        {value !== 'TYPE_CONTENT_IMAGE_WEB' &&
                          <TableCell style={styleTableCell}>{val}</TableCell>
                        }
                        {
                          value === 'TYPE_CONTENT_IMAGE_WEB' &&
                          <TableCell style={styleTableCell}>
                            <img
                              src={`${Util.domainWebUseWithUseWWW()}/${val.replace('APP-Image/', '')}/${item}`}
                              style={{
                                height: 100,
                                width: 114,
                                objectFit: 'contain',
                              }}
                            />
                          </TableCell>
                        }
                        <TableCell style={styleTableCell}>{value !== 'TYPE_CONTENT_IMAGE_WEB' && item}</TableCell>

                        <TableCell style={styleTableCell}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                          {value !== 'TYPE_CONTENT_IMAGE_WEB' &&
                            <AddBoxIcon
                              color="action"
                              style={{
                                width: 40,
                                height: 40,
                              }}
                              onClick={() => openModalFileChanged(inputEl.current[index], item, val)}
                            />
                          }

                            {/*<div>*/}
                            {/*  <Button*/}
                            {/*    className="btn-cus-font btn-red btn-cus-r5"*/}
                            {/*    onClick={(e) => removeFile(e, value + '/' + item)}*/}
                            {/*    // onClick={(e) => handleRemoveConfirm(e, value + '/' + item)}*/}
                            {/*  >*/}
                            {/*    削除*/}
                            {/*  </Button>*/}
                            {/*</div>*/}

                            <div>
                              <Button
                                className="btn-cus-font btn-blue btn-cus-r5"
                                onClick={(e) => downloadFile(e, val, item)}
                              >
                                ダウンロード
                              </Button>
                            </div>

                            {(webUrlData !== '' || value === 'TYPE_CONTENT_IMAGE_WEB') && (
                              <div>
                                <Button
                                  className="btn-cus-font btn-blue"
                                  onClick={value === 'TYPE_CONTENT_IMAGE_WEB'
                                    ? () => handleShowImageModal(val, item)
                                    : () => onShowDialog(webUrlData)}
                                >
                                  表示確認
                                </Button>
                              </div>
                            )}
                            {value === 'TYPE_CONTENT_IMAGE_WEB' && (
                              <div style={{marginLeft:6}}>
                                <Button
                                  className="btn-cus-font btn-red"
                                  onClick={() => handleDeleteFileImage(val, item)}
                                >
                                  削除
                                </Button>
                              </div>
                            )}
                          </div>
                        </TableCell>

                      </TableRow>
                    );
                  });
                })}
              </TableBody>
            </Table>
          </Card>)}
      </MuiThemeProvider>

      {/*showDialog.isShow*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showDialog.isShow}
        onClose={onCloseDialog}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          表示プレビュー
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseDialog}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ height: '80vh', overflow: 'hidden' }}>
          {user && <iframe title="s"
                           id="iframeId"
                           onLoad={accessAndPassData}
                           style={{ border: 'none', width: '100%', height: '100%' }}
                           src={user && Util.myProfSrc(user, showDialog.webUrl)} />}
        </DialogContent>
      </Dialog>

      {/*showDialogDragDrop*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showDialogDragDrop}
        onClose={onCloseDialogDragDrop}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 1200,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ファイルアップロード</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {fileNameChose}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseDialogDragDrop}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL  }}></div>
        </div>

        <DialogContent style={{ height: '50%', overflow: 'hidden' }}>
          <DragDropFile indexInput={indexInput} handleFile={inputFileChangedClick} />
        </DialogContent>
      </Dialog>

{     /*showDialogUploadImageWeb*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openModalUploadImage}
        onClose={onCloseUploadDialog}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 1200,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ファイルアップロード</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {fileNameChose}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseUploadDialog}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: UPLOAD_FILE_IMAGE_TO_CONTENTS_IMAGE_WEB }}></div>
        </div>

        <DialogContent style={{ height: '50%', overflow: 'hidden' }}>
          <DragDropFile indexInput={indexInput} handleFile={uploadImageWeb} />
        </DialogContent>
      </Dialog>

      {/*showUploadDialog*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showUploadDialog}
        onClose={onCloseUploadDialog}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 1200,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ファイルアップロード-詳細</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {fileNameChose}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseUploadDialog}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: value === 'TYPE_CONTENT_IMAGE_WEB' ? UPLOAD_FILE_IMAGE_TO_CONTENTS_IMAGE_WEB : TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL }}></div>
        </div>

        <DialogContent style={{ marginBottom: 30, overflow: 'hidden' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className='mt-3'>

            <TableBody>
              <TableRow>

                <TableCell> アップロード先フォルダー </TableCell>
                <TableCell> {fileData.pathUpload} </TableCell>

                <TableCell> ファイル名 </TableCell>
                <TableCell> {fileData.fileName} </TableCell>

              </TableRow>
            </TableBody>
          </Table>

          <div style={{ display: 'flex', justifyContent: 'center' }} className='mt-3'>
            <Button
              className="btn-red mr-2"
              onClick={onCloseUploadDialog}
            >
              キャンセル
            </Button>

            <Button
              className="btn-blue mr-2"
              onClick={handleSubmit}
            >
              アップロード
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {!!(confirmKey && confirmKey.length) && (
        <RemoveFileModal
          visible={!!confirmKey}
          onClose={handleRemoveClose}
          confirmKey={confirmKey}
        />
      )}

      {previewingImage && (
        <ImageModal image={previewingImage} onClose={handleHideImageModal} />
      )}
    </div>
  );
}

export default FileUpload;
