import { call, put, takeLatest } from 'redux-saga/effects';
import { getFavoriteJobs } from '../../Api/favoriteJobLogApi';
import FavoriteJobLog from '../Types/favoriteJobLog';
import { loadFavoriteJobLogFail, loadFavoriteJobLogSuccess } from '../Actions/favoriteJobLog';

function* handleLoadFavoriteJobLog(action) {
  try {
    const response = yield call(getFavoriteJobs, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFavoriteJobLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFavoriteJobLogFail(error));
  }
}


export default function* favoriteLogSaga() {
  yield takeLatest(FavoriteJobLog.LOAD_FAVORITE_JOB_LOG, handleLoadFavoriteJobLog);
}
