import Axios from 'axios';
import Cookies from 'js-cookie';
import history from '../history';
import configs from '../Config/config';

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.apiDomain,
});
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
    return config;
  },
  (error) => Promise.reject(error),
);
axiosInstance.interceptors.response.use(
  (response) => {
    // invalid token
    if (typeof response.data !== 'object') {
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      Cookies.remove('operatorId');
      Cookies.remove('agentId');
      localStorage.removeItem('operatorId');
      localStorage.removeItem('agentId');
      localStorage.removeItem('userId');
      localStorage.removeItem('keepSidenav');
      localStorage.removeItem('memberAdminId');
      localStorage.removeItem('homePage');
      history.push('/');
    }
    return response;
  },
  (error) => Promise.reject(error),
);

export const sendGet = (url, params) => axiosInstance.get(url, { params });
export const sendPost = (url, params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams });
export const sendPostFile = (url, params, queryParams) =>
  axiosInstance.post(url, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: queryParams,
    timeout: 60000,
  });
export const sendPut = (url, params) => axiosInstance.put(url, params);
export const sendPatch = (url, params) => axiosInstance.patch(url, params);
export const sendDelete = (url, params) =>
  axiosInstance.delete(url, { params });
