import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { changeImageStatus } from '../../../Api/imageApi';
import { toast } from 'react-toastify';
import { IMAGE_STATUS } from '../../../Config/listConstants';
import { TOAST_MESSAGE } from '../../../Common/constant_text';

function ModalShowImage({ image, onClose, onUpdate }) {
  const [updating, setUpdating] = useState(false);

  const handleUpdate = () => {
    setUpdating(true);
    onUpdate();
    changeImageStatus({
      id: image.id,
      inspection:
        image.inspection === IMAGE_STATUS.PENDING
          ? IMAGE_STATUS.CONFIRMED
          : IMAGE_STATUS.PENDING,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onUpdate();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={"d-modal-wrapper"}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          プロフィール画像管理-画像承認
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            <img
              src={image.url}
              style={{ maxWidth: 400, maxHeight: 400 }}
              alt=""
            />
          </div>
          <div>
            <center style={{ marginBottom: 15 }}>
              <Button
                onClick={onClose}
                variant="contained"
                className="btn-custom btn-red"
                style={{ marginRight: 10 }}
              >
                閉じる
              </Button>
              <Button
                onClick={() => handleUpdate()}
                variant="contained"
                className="btn-custom btn-blue"
                disabled={updating}
              >
                {image.inspection === IMAGE_STATUS.PENDING
                  ? '認証する'
                  : '認証しない'}
              </Button>
            </center>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ModalShowImage;
