import { handleActions } from 'redux-actions';
import envInfo from '../Types/envInfo';

const initialState = {
    data: [],
    loading: false,
    totalRow: 0,
    updating: false,
    success: false,
};

const actions = {
    [envInfo.LOAD_ENVINFO]: (state) => ({
        ...state,
        loading: true,
    }),
    [envInfo.LOAD_ENVINFO_SUCCESS]: (state, action) => ({
        ...state,
        data: action.payload.data,
        totalRow: action.payload.totalRow,
        loading: false,
    }),
    [envInfo.LOAD_ENVINFO_FAIL]: (state) => ({
        ...state,
        data: [],
        loading: false,
    }),
    [envInfo.UPDATE_ENVINFO]: (state) => ({
        ...state,
        updating: true,
    }),
    [envInfo.UPDATE_ENVINFO_SUCCESS]: (state) => ({
        ...state,
        updating: false,
        success: true,
    }),
    [envInfo.UPDATE_ENVINFO_FAIL]: (state) => ({
        ...state,
        updating: false,
    }),
};
export default handleActions(actions, initialState);
