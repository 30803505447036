import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import RadioGroup from '../../Components/RadioGroup';
import LoadingBar from '../../Components/LoadingBar';
import Util from '../../Util/Util';
import { updateUsers } from '../../Api/userAPI';
import _ from 'lodash';
import {
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  charaStatusOptionsRequired,
  convertCharaStatusText,
  convertEnvInfo,
  convertInspectionStatusText,
  convertMemberCategoryTypeText,
  convertMemberTypeArrayText, convertNeedOtpText,
  convertRegisterStatusText,
  convertStandByText,
  loginControlArrayOptionsRequired,
  memberArrayOptionsRequired,
  memberCategoryTypeRequireOptions,
  memberTypeOfUserOptionsRequired,
  needOtpOptionsRequired,
  registerStatusRequired,
  sexOptionsRequired,
  standByRequired,
} from '../../Util/utils';
import { OTP_TYPE } from '../../Common/constant';
import { TOAST_MESSAGE } from '../../Common/constant_text';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        display: 'flex !important',
        fontSize: '16px',
      },
      asterisk: {
        color: 'red',
        order: '-1',
        marginLeft: '-4px',
      },
      required: {
        display: 'flex',
      },
    },
    MuiFormGroup: {
      root: {
        alignItems: 'flex-end',
      },
    },
  },
});

const statusOptions = [
  { value: 0, label: '未認証', selected: false },
  { value: 1, label: '認証済', selected: false },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function UserEditMulti() {
  const envInfo = useSelector((state) => state.envInfo.data);
  const classes = useStyles();

  const [dataChange, setDataChange] = useState({
    gender: { value: null, isSend: false },
    state: { value: null, isSend: false },
    ageverification: { value: null, isSend: false },
    memberStatus: { value: null, isSend: false },
    memberType: { value: null, isSend: false },
    adcode: { value: null, isSend: false },
    needOtp: { value: null, isSend: false },
    charaStatus: { value: null, isSend: false },
    nickName: { value: null, isSend: false },
    realNameHurigana: { value: null, isSend: false },
    password: { value: null, isSend: false },
    memberCategory: { value: null, isSend: false },
    registerStatus: { value: null, isSend: false },
    standBy: { value: null, isSend: false },
    logincontrol: { value: null, isSend: false },
    depositpoint: { value: null, isSend: false },
    salarypoint: { value: null, isSend: false },
  });

  const [open, setOpen] = useState(false);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [labelDb, setLabelDb] = useState({});
  const [colorLabel, setColorLabel] = useState('');

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
  }, [envInfo]);

  const history = useHistory();
  const redirectCancelCreateUser = () => {
    history.push(`/user/search`);
  };
  const [updateData, setUpdateData] = useState(null);
  const ids = localStorage.getItem('userIds');

  useEffect(() => {
    return localStorage.removeItem('UserSearchFilter');
  }, []);

  const nameState = useSelector((state) => state.nameState.data);
  const loadingState = useSelector((state) => state.nameState.loading);

  const handleSubmit = () => {
    // if (charaStatus.value !== 0 && !operatorNumber) {
    //   toast.error('オペレーターNumberは必須項目です');
    //   return false;
    // }
    const dt = {
      ids: ids.split(','),
    };
    for (const name in dataChange) {
      if (dataChange[name].isSend) {
        dt[name] = dataChange[name].value;
      }
    }

    setUpdateData(dt);
    setOpen(true);
  };

  const onChangeSendDataValue = (name, value) => {
    let tmp = dataChange[name];
    tmp.value = value;
    dataChange[name] = tmp;
    setDataChange({
      ...dataChange,
      ...tmp,
    });
  };

  const onChangeSendDataCheck = (name) => {
    let tmp = dataChange[name];
    tmp.isSend = !tmp.isSend;
    dataChange[name] = tmp;
    setDataChange({
      ...dataChange,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditUsers = () => {
    setOpen(false);
    setConFirmVisible(false);
    updateUsers(updateData).then((response) => {
      const status = response.status;
      if (status === 200 && !response.data.errorCode) {
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loadingState} />
      <MuiThemeProvider theme={customTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
          }}
        >
          <h3>{ids.split(',').length} 人のユーザーを変換します</h3>
          <div>
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={redirectCancelCreateUser}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue"
              variant="contained"
              onClick={handleSubmit}
            >
              登録
            </Button>
          </div>
        </div>

        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <div style={{ marginBottom: 10 }}>
                <FormGroup row>
                  <Col xs={11}>
                    <InputLabel style={{ color: colorLabel }} shrink>
                      <span className="text-red">*</span>
                      {`広告コード`}
                    </InputLabel>
                    <TextField
                      id="standard-required"
                      type="text"
                      maxLength={300}
                      name="adcode"
                      onChange={(e) =>
                        onChangeSendDataValue('adcode', e.target.value)
                      }
                      className="w-100"
                    />
                  </Col>
                  <Col xs={1}>
                    <input
                      name="checkAdcode"
                      value=""
                      type="checkbox"
                      onClick={() => onChangeSendDataCheck('adcode')}
                      style={{
                        marginRight: '6px',
                        maxWidth: '20px',
                        width: 16,
                        height: 16,
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Card>
            <Card body className="mb-4">
              {/*resource && showResource()*/}
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row className="mt-18">
                        <Col xs={11}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`${labelDb?.label_nickName?.content}`}
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            maxLength={300}
                            name="nickName"
                            onChange={(e) =>
                              onChangeSendDataValue('nickName', e.target.value)
                            }
                            className="w-100"
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checknickName"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('nickName')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mt-18">
                        <Col xs={11}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`フリガナ`}
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            maxLength={300}
                            name="realNameHurigana"
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'realNameHurigana',
                                e.target.value,
                              )
                            }
                            className="w-100"
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkrealNameHurigana"
                            value=""
                            type="checkbox"
                            onClick={() =>
                              onChangeSendDataCheck('realNameHurigana')
                            }
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mt-18">
                        <Col xs={11}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`パスワード(数字6桁)`}
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            maxLength={300}
                            name="password"
                            onChange={(e) =>
                              onChangeSendDataValue('password', e.target.value)
                            }
                            className="w-100"
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkPassword"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('password')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mt-18">
                        <Col xs={11}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`ログインSMS必要`}
                          </InputLabel>
                          <RadioGroup
                            name="needOtp"
                            defaultValue=""
                            options={needOtpOptionsRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'needOtp',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkNeedOtp"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('needOtp')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <div className="w-100">
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`性別`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="gender"
                            defaultValue=""
                            options={sexOptionsRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'gender',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                            required={true}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkGender"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('gender')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mt-15 w-100">
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`キャラクターステイタス`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="charaStatus"
                            defaultValue=""
                            options={charaStatusOptionsRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'charaStatus',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkCharaStatus"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('charaStatus')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>

                    <div className="mt-15 w-100">
                      <FormGroup style={{ marginTop: 10 }} row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`会員種類`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="memberType"
                            defaultValue=""
                            options={memberTypeOfUserOptionsRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'memberType',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkMemberType"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('memberType')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mt-15 w-100">
                      <FormGroup style={{ marginTop: 10 }} row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`登録種類`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="memberCategory"
                            defaultValue=""
                            options={memberCategoryTypeRequireOptions()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'memberCategory',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkmemberCategory"
                            value=""
                            type="checkbox"
                            onClick={() =>
                              onChangeSendDataCheck('memberCategory')
                            }
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mt-15 w-100">
                      <FormGroup style={{ marginTop: 10 }} row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`電話認証状態`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="registerStatus"
                            defaultValue=""
                            options={registerStatusRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'registerStatus',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkregisterStatus"
                            value=""
                            type="checkbox"
                            onClick={() =>
                              onChangeSendDataCheck('registerStatus')
                            }
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mt-15 w-100">
                      <FormGroup style={{ marginTop: 10 }} row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`待機状態`}
                          </InputLabel>
                        </Col>
                        <Col xs={11}>
                          <RadioGroup
                            name="standBy"
                            defaultValue=""
                            options={standByRequired()}
                            onChange={(e) =>
                              onChangeSendDataValue(
                                'standBy',
                                Number(e.target.value),
                              )
                            }
                            disabled={false}
                          />
                        </Col>
                        <Col xs={1}>
                          <input
                            name="checkstandBy"
                            value=""
                            type="checkbox"
                            onClick={() => onChangeSendDataCheck('standBy')}
                            style={{
                              marginRight: '6px',
                              maxWidth: '20px',
                              width: 16,
                              height: 16,
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col xs={6}>
                <Card body className="mb-4">
                  <div style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      <Col xs={11}>
                        {nameState && (
                          <FormControl className={classes.formControl}>
                            <InputLabel style={{ color: colorLabel }} shrink>
                              <span className="text-red">*</span>
                              {`都道府県`}
                            </InputLabel>
                            <Select
                              name="state"
                              onChange={(e) =>
                                onChangeSendDataValue('state', e.target.value)
                              }
                              displayEmpty
                              className={classes.selectEmpty}
                              required={true}
                            >
                              {nameState.items.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Col>
                      <Col xs={1}>
                        <input
                          name="checkState"
                          value=""
                          type="checkbox"
                          onClick={() => onChangeSendDataCheck('state')}
                          style={{
                            marginRight: '6px',
                            maxWidth: '20px',
                            width: 16,
                            height: 16,
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                  <div className="mt-18">
                    <FormGroup row>
                      <Col xs={12}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          <span className="text-red">*</span>
                          {`表示ステイタス（非表示ログインNG）`}
                        </InputLabel>
                      </Col>
                      <Col xs={11}>
                        <RadioGroup
                          name="memberStatus"
                          defaultValue=""
                          options={memberArrayOptionsRequired()}
                          onChange={(e) =>
                            onChangeSendDataValue(
                              'memberStatus',
                              Number(e.target.value),
                            )
                          }
                        />
                      </Col>
                      <Col xs={1}>
                        <input
                          name="checkMemberStatus"
                          value=""
                          type="checkbox"
                          onClick={() => onChangeSendDataCheck('memberStatus')}
                          style={{
                            marginRight: '6px',
                            maxWidth: '20px',
                            width: 16,
                            height: 16,
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                  <div className="mt-15">
                    <FormGroup row>
                      <Col xs={11}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          <span className="text-red">*</span>
                          {`年齢認証`}
                        </InputLabel>
                        <RadioGroup
                          name="ageverification"
                          defaultValue=""
                          options={statusOptions}
                          onChange={(e) =>
                            onChangeSendDataValue(
                              'ageverification',
                              Number(e.target.value),
                            )
                          }
                        />
                      </Col>
                      <Col xs={1}>
                        <input
                          name="checkAgeverification"
                          value=""
                          type="checkbox"
                          onClick={() =>
                            onChangeSendDataCheck('ageverification')
                          }
                          style={{
                            marginRight: '6px',
                            maxWidth: '20px',
                            width: 16,
                            height: 16,
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                  <div className="mt-15">
                    <FormGroup row>
                      <Col xs={11}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          <span className="text-red">*</span>
                          {`ログイン制御`}
                        </InputLabel>
                        <RadioGroup
                          name="logincontrol"
                          defaultValue=""
                          options={loginControlArrayOptionsRequired()}
                          onChange={(e) =>
                            onChangeSendDataValue(
                              'logincontrol',
                              Number(e.target.value),
                            )
                          }
                        />
                      </Col>
                      <Col xs={1}>
                        <input
                          name="checklogincontrol"
                          value=""
                          type="checkbox"
                          onClick={() => onChangeSendDataCheck('logincontrol')}
                          style={{
                            marginRight: '6px',
                            maxWidth: '20px',
                            width: 16,
                            height: 16,
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </Card>
              </Col>
              <Col xs={6}>
                <Card body className="mb-4">
                  <FormGroup row className="mt-18">
                    <Col xs={11}>
                      <InputLabel style={{ color: colorLabel }} shrink>
                        {`ポイント`}
                      </InputLabel>
                      <TextField
                        id="standard-required"
                        type="number"
                        maxLength={300}
                        name="depositpoint"
                        onChange={(e) =>
                          onChangeSendDataValue(
                            'depositpoint',
                            Number(e.target.value),
                          )
                        }
                        className="w-100"
                      />
                    </Col>
                    <Col xs={1}>
                      <input
                        name="checkDepositpoint"
                        value=""
                        type="checkbox"
                        onClick={() => onChangeSendDataCheck('depositpoint')}
                        style={{
                          marginRight: '6px',
                          maxWidth: '20px',
                          width: 16,
                          height: 16,
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-18">
                    <Col xs={11}>
                      <InputLabel style={{ color: colorLabel }} shrink>
                        {`給与ポイント`}
                      </InputLabel>
                      <TextField
                        id="standard-required"
                        type="number"
                        maxLength={300}
                        name="salarypoint"
                        onChange={(e) =>
                          onChangeSendDataValue(
                            'salarypoint',
                            Number(e.target.value),
                          )
                        }
                        className="w-100"
                      />
                    </Col>
                    <Col xs={1}>
                      <input
                        name="checkdesalarypoint"
                        value=""
                        type="checkbox"
                        onClick={() => onChangeSendDataCheck('salarypoint')}
                        style={{
                          marginRight: '6px',
                          maxWidth: '20px',
                          width: 16,
                          height: 16,
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MuiThemeProvider>
      {updateData && (
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            {ids.split(',').length} 人のユーザーを変換します
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                position: 'absolute',
                top: 6,
                right: 0,
                width: 'auto',
                bottom: 10,
                paddingRight: 50,
                zIndex: 1000,
              }}
            >
              <center>
                <Button
                  className="btn-red"
                  style={{
                    padding: '4px 20px',
                    fontSize: 16,
                    fontWeight: 500,
                    marginRight: 20,
                    color: 'white',
                  }}
                  variant="contained"
                  onClick={handleClose}
                >
                  戻る
                </Button>
                <Button
                  className="btn-custom btn-blue"
                  variant="contained"
                  onClick={() => setConFirmVisible(true)}
                >
                  登録
                </Button>
              </center>
            </div>
            <Row>
              <Col xs={4}>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={6}>
                      <InputLabel style={{ color: colorLabel }} shrink>
                        {`広告コード`}
                      </InputLabel>
                    </Label>
                    <Label style={{ color: 'red' }} xs={6}>
                      {updateData?.adcode}
                    </Label>
                  </Row>
                </Card>
              </Col>
              <Col xs={8}>
                <Card body className="mb-4">
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            {labelDb?.label_nickName?.content}
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.nickName}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            フリガナ
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.realNameHurigana}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            パスワード(数字6桁)
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.password}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            都道府県
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.state
                            ? _.find(
                                nameState.items,
                                (x) => x.id === updateData?.state,
                              )['label']
                            : null}
                        </Label>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            ログインSMS必要
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertNeedOtpText(updateData.needOtp)}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            表示ステイタス（非表示ログインNG）
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                           {Util.memberStateToText(updateData.memberStatus)}
                        </Label>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            年齢認証
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertInspectionStatusText(updateData?.ageverification)}
                        </Label>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            ログイン制御
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {Util.loginControlToText(updateData?.logincontrol)}
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            性別
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.gender &&
                            Util.genderIdToText(Number(updateData.gender))}
                        </Label>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            キャラクターステイタス
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertCharaStatusText(+updateData?.charaStatus)}
                        </Label>
                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            会員種類
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertMemberTypeArrayText(updateData?.memberType)}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            登録種類
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertMemberCategoryTypeText(
                            updateData?.memberCategory,
                          )}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            電話認証状態
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertRegisterStatusText(
                            updateData?.registerStatus,
                          )}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            待機状態
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {convertStandByText(updateData?.standBy)}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            ポイント
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.depositpoint}
                        </Label>

                        <Label xs={6}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            給与ポイント
                          </InputLabel>
                        </Label>
                        <Label style={{ color: 'red' }} xs={6}>
                          {updateData?.salarypoint}
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </DialogContent>
        </Dialog>
      )}

      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={confirmVisible}
          onClose={() => setConFirmVisible(false)}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={() => setConFirmVisible(false)}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              実行してよろしいですか？
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={() => setConFirmVisible(false)}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleEditUsers}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default UserEditMulti;
