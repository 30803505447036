import React, { useMemo } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { LOG_SEND_MAIL_TYPE } from 'Common/constant_text';
import { MEMBER_USER_STATUS } from 'Common/constant';
import UserDelete from 'assets/image/delete_user.png';

const LogSendListItem = ({ logSendSms, no, handleClickDetail }) => {
  const content = _.truncate(_.get(logSendSms, 'content'), {
    length: 24,
    separator: ' ',
  });
  return useMemo(
    () => (
      <TableRow style={{ height: 48 }}>
        <TableCell>{no}</TableCell>
        <TableCell>{_.get(logSendSms, 'phoneNumber')}</TableCell>
        <TableCell align="center">
          <Link
            to={`/user/${_.get(logSendSms, 'user.id')}/detail`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: '8px' }}
          >
            <img
              className="imgRound"
              src={_.get(logSendSms, 'user.avatar.url') || NoPhoto}
              alt=""
            />
          </Link>
        </TableCell>
        <TableCell>{_.get(logSendSms, 'user.nickName')}</TableCell>
        <TableCell>
          {Util.genderIdToText(_.get(logSendSms, 'user.gender'))}
        </TableCell>
        <TableCell>{content}</TableCell>
        <TableCell align="center">
          {logSendSms.sender && (
            <Link
              to={`/user/${logSendSms.sender.id}/detail`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '8px' }}
            >
              <img
                className="imgRound"
                src={
                  logSendSms.sender.memberStatus ===
                  MEMBER_USER_STATUS.IN_ACTIVE
                    ? UserDelete
                    : logSendSms.sender.avatar?.url || NoPhoto
                }
                alt=""
              />
            </Link>
          )}
        </TableCell>
        <TableCell>{logSendSms.sender?.nickName}</TableCell>
        <TableCell className="text-muted">
          {moment(Number(_.get(logSendSms, 'createdDate'))).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </TableCell>
        <TableCell>
          {logSendSms?.type
            ? LOG_SEND_MAIL_TYPE.find((e) => e.value === logSendSms?.type)
                ?.label
            : ''}
        </TableCell>
        <TableCell className="d-color-link" style={{ cursor: 'pointer' }}>
          <Link
            onClick={() => handleClickDetail(logSendSms)}
            className="d-color-link"
          >
            表示
          </Link>
        </TableCell>
      </TableRow>
    ),
    [logSendSms, no, content, handleClickDetail],
  );
};

export default LogSendListItem;
