import { call, put, takeLatest } from 'redux-saga/effects';
import PointSetting from '../Types/pointSetting';
import { getPointSetting, updatePointSetting } from '../../Api/pointSettingAPI';
import { toast } from 'react-toastify';
import {
  loadPointSettingFail,
  loadPointSettingSuccess,
  updatePointSettingFail,
  updatePointSettingSuccess,
} from '../Actions/pointSetting';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadPointSetting(action) {
  try {
    const response = yield call(getPointSetting, action.payload);
    const { data } = response;
    if (data.success) {
      const pointSetting = data;
      yield put(loadPointSettingSuccess(pointSetting));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadPointSettingFail(error));
  }
}

function* handleUpdatePointSetting(action) {
  try {
    const response = yield call(updatePointSetting, action.payload);
    const { data } = response;
    if (data.success) {
      const pointSetting = data.data;
      yield put(updatePointSettingSuccess(pointSetting));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updatePointSettingFail(error));
  }
}

export default function* pointSettingSaga() {
  yield takeLatest(PointSetting.LOAD_POINT_SETTING, handleLoadPointSetting);
  yield takeLatest(PointSetting.UPDATE_POINT_SETTING, handleUpdatePointSetting);
}
