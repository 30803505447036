import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card, MuiThemeProvider } from '@material-ui/core';
import { updateChildResourceAgent } from '../../Redux/Actions/resourceAgent';
import { useDispatch } from 'react-redux';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { toast } from 'react-toastify';
import { REGEX_NUMBER } from '../../Common/constant';
import SortArrows from '../../Components/SortArrows';

function ChildProfileList({
                            dataChildProfile,
                            handleEdit,
                            onClose,
                            isEditing,
                            changeDataChildProfile,
                          }) {
  const dispatch = useDispatch();
  const [dataProfile, setDataProfile] = useState({});
  const [inputUpdate, setInputUpdate] = useState({});
  const [filter, setFilter] = useState({
    sortBy: 'profile',
    orderBy: 'ASC',
    sort: { profile: 'ASC' },
  });

  useEffect(() => {
    const sortedEntries = Object.entries(dataChildProfile);
    sortedEntries.forEach((x) => (x[0] = '_' + x[0]));
    sortedEntries.sort(([, a], [, b]) => {
      if (b[0].profile > a[0].profile) {
        return -1;
      } else if (b[0].profile < a[0].profile) {
        return 1;
      }
      return 0;
    });

    const data = Object.fromEntries(sortedEntries);
    setDataProfile(data);

  }, [dataChildProfile]);

  const handleChangeLabel = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.label = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // let newData = { ...dataChildProfile };
    // changeDataChildProfile(newData);
  };

  const handleChangeValue = (e) => {
    const valueOld = e.target.dataset.valold;
    const value = e.target.value;
    // Validate
    if (!value) {
      toast.error('表示順を正しい値で入力してください。');
    } else if (value && !REGEX_NUMBER.test(value)) {
      toast.error('表示順を正しい値で入力してください。');
    } else if (value && (value <= 0 || value >= 100)) {
      toast.error('表示順を正しい値で入力してください。');
    }

    // Validate change value for order sort
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.value = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // const newData = { ...dataChildProfile };
    // changeDataChildProfile(newData);
  };

  const handleUpdate = () => {
    // Validate
    const arrayData = {};
    const arrayDataValue = [];

    for (let key in inputUpdate) {
      arrayDataValue.push(inputUpdate[key].map(ele => ele.value));
      if (inputUpdate[key].length !== 1) {
        arrayData[key] = true;
      }

      for (let k in inputUpdate[key]) {
        if (isNaN(inputUpdate[key][k].value) || !inputUpdate[key][k].value) {
          arrayData[key] = true;
        }
      }

      let value0 = inputUpdate[key][0].value;
      const valueFilter = inputUpdate[key].filter(ele => !isNaN(ele.value) && +ele.value === +value0);

      if (valueFilter.length !== 1) {
        arrayData[key] = true;
      }
    }

    for (let i = 0; i < arrayDataValue.length; i++) {
      let value0 = arrayDataValue[i][0];
      for (let j = i + 1; j < arrayDataValue.length; j++) {
        const valueFilter = !isNaN(arrayDataValue[j][0]) && +arrayDataValue[j][0] === +value0;

        if (valueFilter) {
          arrayData[i] = true;
        }
      }
    }

    if (!Object.keys(arrayData).length) {
      dispatch(updateChildResourceAgent(inputUpdate));
      setDataProfile(inputUpdate); // Set update when change data
      changeDataChildProfile(inputUpdate); // Set update when change data
      onClose();
    } else {
      // Error
      toast.error('表示順は同じ値で入力してください。');
    }
  };

  const handleClose = () => {
    changeDataChildProfile({ ...dataProfile }); // Revert data old
    setInputUpdate({}); // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); // Clone deep data when use object
    handleEdit();
  };

  const onSort = (profile) => {
    if (isEditing) {
      return; // Not sort while editing
    }

    let sortable = {};
    if (filter.orderBy === 'ASC') {
      const sortedEntries = Object.entries(dataProfile).sort(([, a], [, b]) => {
        if (b[0][profile] > a[0][profile]) {
          return 1;
        } else if (b[0][profile] < a[0][profile]) {
          return -1;
        }
        return 0;
      });
      sortable = sortedEntries.reduce((prev, [k, v]) => {
        prev[k] = v;
        return prev;
      }, {});

    } else {
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (a[0][profile] > b[0][profile]) {
            return 1;
          } else if (a[0][profile] < b[0][profile]) {
            return -1;
          }
          return 0;
        }),
      );
    }

    setFilter({
      ...filter,
      sortBy: profile,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [profile]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });

    setDataProfile(sortable);
  };

  if (!dataProfile || dataProfile && !Object.keys(dataProfile).length) {
    return null;
  }

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div style={{ textAlign: 'right', margin: '10px 0' }}>
        {isEditing ? (
          <div className="my-3">
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={handleClose}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue"
              variant="contained"
              onClick={handleUpdate}
            >
              更新する
            </Button>
          </div>
        ) : (
          <Button
            className="btn-blue"
            style={{
              padding: '6px 14px',
              margin: 'auto',
              color: 'white',
              marginRight: 10,
            }}
            variant="contained"
            onClick={handleBtnEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div></div>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table">
          <TableBody>
            <TableRow>
              <TableCell style={{ width: '50px' }}></TableCell>
              <TableCell align="center">カラム名</TableCell>
              <TableCell align="center" style={{ width: '30%' }}>
                <SortArrows
                  sortObject={filter.sort}
                  text="表示順（空欄NG）"
                  name="value"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell style={{ width: '250px' }}>
                <SortArrows
                  sortObject={filter.sort}
                  text="項目名"
                  name="label"
                  onClick={onSort}
                />
              </TableCell>
            </TableRow>
            {Object.keys(dataProfile)?.map((child, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '50px' }}>{index + 1}</TableCell>
                <TableCell align="center">
                  {dataProfile[child][0]?.profile}
                </TableCell>
                <TableCell align="center" style={{ width: '30%' }}>
                  {isEditing ? (
                    <input
                      data-idold={dataProfile[child][0]?.id}
                      data-valold={dataProfile[child][0]?.value}
                      className="input-rank"
                      defaultValue={dataProfile[child][0]?.value}
                      name={`value.${dataProfile[child][0]?.id}`}
                      onChange={handleChangeValue}
                    />
                  ) : (
                    dataProfile[child][0]?.value
                  )}
                </TableCell>
                <TableCell style={{ width: '350px' }}>
                  {isEditing ? (
                    <input
                      style={{ width: '350px' }}
                      className="input-rank"
                      defaultValue={dataProfile[child][0]?.label}
                      name={`value.${dataProfile[child][0]?.id}`}
                      onChange={handleChangeLabel}
                    />
                  ) : (
                    dataProfile[child][0]?.label
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ChildProfileList;
