import { handleActions } from 'redux-actions';
import ChatLog from '../Types/chatLog';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [ChatLog.LOAD_CHATLOG]: (state) => ({
    ...state,
    loading: true,
  }),
  [ChatLog.LOAD_CHATLOG_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ChatLog.LOAD_CHATLOG_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
