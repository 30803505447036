import { call, put, takeLatest } from 'redux-saga/effects';
import { getListRawUsers } from '../../Api/database';
import { loadListRawUsersFail, loadListRawUsersSuccess } from '../Actions/listRawUsers';
import ListRawUsers from '../Types/listRawUsers';

function* handleLoadListRawUsers(action) {
  try {
    const response = yield call(getListRawUsers, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawUsersSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawUsersFail(error));
  }
}

export default function* listRawUsersSaga() {
  yield takeLatest(ListRawUsers.LOAD_LIST_RAW_USERS, handleLoadListRawUsers);
}
