import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Util from '../../Util/Util';
import AddCampaignModal from './AddCampaignModal';
import EditCampaignModal from './EditCampaignModal';
import moment from 'moment';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { Link } from 'react-router-dom';
import { convertHiddenStatusText, convertInspectionStatusText } from '../../Util/utils';
import Nophoto from '../../assets/image/no_user_photo.png';

const AppDestinationOptions = [
  {
    value: 0,
    label: '',
  },
  {
    value: 1,
    label: 'プラン購入',
  },
  {
    value: 2,
    label: 'ポイント購入',
  },
  {
    value: 3,
    label: '問い合わせ',
  },
  {
    value: 4,
    label: '問合せ（年齢認証）',
  },
  {
    value: 11,
    label: 'お知らせ1',
  },
  {
    value: 12,
    label: 'お知らせ2',
  },
  {
    value: 13,
    label: 'お知らせ3',
  },
  {
    value: 14,
    label: 'お知らせ4',
  },
  {
    value: 15,
    label: 'お知らせ5',
  },
  {
    value: 16,
    label: 'お知らせ6',
  },
  {
    value: 17,
    label: 'お知らせ7',
  },
  {
    value: 18,
    label: 'お知らせ8',
  },
];

const placeCampaign = {
  point: 1,
  plan: 2,
};

function CampaignList({
                        campaigns,
                        onUpdate,
                        filter = {},
                        onSort = (sortByKey) => {
                        },
                      }) {
  const [addCampaignModalVisible, setShowAddCampaignModal] = useState();
  const [editingCampaign, setEditingCampaign] = useState();

  const showAddCampaignModal = () => {
    setShowAddCampaignModal(true);
  };

  const hideAddCampaignModal = () => {
    setShowAddCampaignModal(false);
  };

  const showEditModal = (campaign) => {
    setEditingCampaign(campaign);
  };

  const hideEditModal = () => {
    setEditingCampaign(null);
  };

  const findAppDestination = (params) => {
    const data = AppDestinationOptions.find((item) => item.value === params);
    return data.label;
  };

  return (
    <div>
      <div className="align" style={{ justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          className="btn-custom btn-blue ml-3"
          onClick={() => showAddCampaignModal()}
        >
          キャンペーンを追加
        </Button>
      </div>
      {addCampaignModalVisible && (
        <AddCampaignModal onClose={hideAddCampaignModal} onUpdate={onUpdate} />
      )}
      {editingCampaign && (
        <EditCampaignModal
          campaign={editingCampaign}
          onClose={hideEditModal}
          onUpdate={onUpdate}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ marginBottom: 20 }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="設定名"
                    name="campaign.name"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">バナー画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="表示場所"
                    name="campaign.place"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示設定</TableCell>
                <TableCell>表示端末</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="×性別"
                    name="campaign.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="×年齢認証"
                    name="campaign.confirmAge"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="リンク先"
                    name="campaign.app_destination"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="開始日時"
                    name="campaign.start_time"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="終了日時"
                    name="campaign.end_time"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    dangerouslySetInnerHTML={{
                      __html: '更新日時<br/>（最新が優先表示）',
                    }}
                    name="campaign.updatedDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {campaigns.map((campaign, index) => (
                <TableRow key={campaign.id} style={{ height: 48 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <span>{campaign.name}</span>
                  </TableCell>
                  <TableCell align="center">
                    <img
                      style={{
                        height: 100,
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={campaign.url && campaign.url.length ? campaign.url : Nophoto}
                      alt=""
                    />
                  </TableCell>
                  <TableCell>
                    {campaign.place === placeCampaign.point
                      ? '左側'
                      : campaign.place === placeCampaign.plan
                        ? '右側'
                        : ''}
                  </TableCell>
                  <TableCell>
                    {convertHiddenStatusText(campaign.displaySetting)}
                  </TableCell>

                  <TableCell>
                    {Util.typeConnectionToText(campaign.typeDevice)}
                  </TableCell>
                  <TableCell>
                    {campaign.gender === 0
                      ? '全て'
                      : Util.genderIdToText(campaign.gender)}
                  </TableCell>
                  <TableCell align="left">
                    {convertInspectionStatusText(+campaign.confirmAge)}
                  </TableCell>
                  <TableCell align="left">
                    {findAppDestination(campaign.app_destination)}
                  </TableCell>
                  <TableCell>
                    {moment(new Date(parseInt(campaign.start_time))).format('YYYY/MM/DD HH:mm')}
                  </TableCell>
                  <TableCell>
                    {moment(new Date(parseInt(campaign.end_time))).format('YYYY/MM/DD HH:mm')}
                  </TableCell>
                  <TableCell>
                    <span>
                      {Util.timestampToDate(campaign.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-color-link">
                      <Link onClick={() => showEditModal(campaign)} className="d-color-link">
                        編集
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default CampaignList;
