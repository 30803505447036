import { Button, Card, createMuiTheme, Dialog, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import Util from '../../Util/Util';
import { toast } from 'react-toastify';
import { removeTwilio } from '../../Api/resourseApi';
import { TEXT_HTML_MANAGEMENT_LOG_TWILIO_HISTORY_LINK_MODAL } from '../../Common/modal_text_info';
import {
  convertTwilioReceiverStatusTypeText,
  convertTwilioResultTypeText,
  convertTwilioStatusTypeText,
} from '../../Util/utils';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';


const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

function DetailModal({ detail, onClose, reload, labelDb }) {
  const [removeVisible, setRemoveVisible] = useState(false);
  const onCloseRemove = () => {
    setRemoveVisible(false);
  };

  const handleRemove = () => {
    setRemoveVisible(true);
  };

  const handleDelete = async () => {
    await removeTwilio(detail.id).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div>
      {removeVisible && (
        <RemoveItemModal visible={removeVisible}
                         onClose={onCloseRemove}
                         handleSubmit={handleDelete}
                         title={MESSAGE_CONFIRM_DELETE.TITLE}
                         content={MESSAGE_CONFIRM_DELETE.CONTENT}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        onClose={onClose}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>Twilio通話ログ-詳細</p>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_LOG_TWILIO_HISTORY_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card body style={{ marginBottom: 10, padding: 20 }}>
                  <Row xs={12}>

                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.timestampToDate(detail.createdDate, 'YYYY/MM/DD HH:mm:ss')}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>送信者</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.sender?.nickName}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>送信電話番号</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.sender?.phoneNumber}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>受信者</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.receiver?.nickName}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>受信電話番号</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.receiver?.phoneNumber}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>Twilio番号</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.twilioCallNumber}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>発信Status</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{convertTwilioStatusTypeText(detail?.status)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>コール結果</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{convertTwilioResultTypeText(detail?.twilioResult)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>受信者応答</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{convertTwilioReceiverStatusTypeText(detail?.receiverStatus)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>

                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>会話開始時間</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.timestampToDate(detail?.startTime, 'YYYY/MM/DD HH:mm:ss')}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>会話終了時間</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.timestampToDate(detail?.endTime, 'YYYY/MM/DD HH:mm:ss')}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>通話時間</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{detail?.durationCallTime}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>Pレート/分</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(detail?.pointRate)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>一時預かりP</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(detail?.depositPoint)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>利用P</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(detail?.usagePoint)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>戻しP</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(detail?.returnPoint)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={12} style={style}>
                          <p> &nbsp; </p>
                          <hr style={{ ...stylehrleft, width: '95%' }} />
                        </Label>
                      </Row>
                    </Col>

                    <Col xs={12}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>Twilio_ID</p>
                          <hr style={{ ...stylehrleft, width: '95%' }} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p> {detail?.twilioId} &nbsp; </p>
                          <hr style={{ ...stylehrleft, width: '95%' }} />
                        </Label>
                      </Row>

                      <Row>
                        <Label xs={6} style={style}>
                          <p>Twilio_Parameter</p>
                          <hr style={{ ...stylehrleft, width: '95%' }} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p title={detail?.twilioParameter}
                             style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {detail?.twilioParameter} &nbsp;
                          </p>
                          <hr style={{ ...stylehrleft, width: '95%' }} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemove}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DetailModal;
