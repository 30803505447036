import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducer from '../Reducer/rootReducer';
import rootSaga from '../Saga/rootSaga';
import { compose } from 'redux';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }
  const composeEnhancers =
    process.env.REACT_APP_NODE_ENV === 'local'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
export default configureStore;
