import { call, put, takeLatest } from 'redux-saga/effects';
import Agents from '../Types/agents';
import { getAgentIds, getAgents } from '../../Api/agentAPI';
import { loadAgentIdsFail, loadAgentIdsSuccess, loadAgentsFail, loadAgentsSuccess } from '../Actions/agents';

function* handleLoadAgents(action) {
  try {
    const response = yield call(getAgents, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadAgentsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadAgentsFail(error));
  }
}

function* handleLoadAgentIds(action) {
  try {
    const response = yield call(getAgentIds, action.payload);
    const { data } = response;
    if (data.success) {
      const agentIds = data.data;
      const totalAgentIds = data.totalRow;
      yield put(loadAgentIdsSuccess({ agentIds, totalAgentIds }));
    }
  } catch (error) {
    yield put(loadAgentIdsFail(error));
  }
}

export default function* agentsSaga() {
  yield takeLatest(Agents.LOAD_AGENTS, handleLoadAgents);
  yield takeLatest(Agents.LOAD_AGENT_IDS, handleLoadAgentIds);
}
