import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

function SelectList({ name, defaultValue, options, onChange }) {
  return (
    <Select
      name={name}
      onChange={onChange}
      value={defaultValue}
      className="w-100"
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectList;
