import { handleActions } from 'redux-actions';
import envUI from '../Types/envUI';

const initialState = {
  data: [],
  loading: false,
  totalRow: 0,
  updating: false,
  success: false,
};

const actions = {
  [envUI.LOAD_ENVUI]: (state) => ({
    ...state,
    loading: true,
  }),
  [envUI.LOAD_ENVUI_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    loading: false,
  }),
  [envUI.LOAD_ENVUI_FAIL]: (state) => ({
    ...state,
    data: [],
    loading: false,
  }),
  [envUI.UPDATE_ENVUI]: (state) => ({
    ...state,
    updating: true,
  }),
  [envUI.UPDATE_ENVUI_SUCCESS]: (state, action) => {
    return {
      ...state,
      updating: false,
      success: true,
      data: action.payload.data,
    };
  },
  [envUI.UPDATE_ENVUI_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
