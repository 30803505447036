import {
  Button,
  Checkbox,
  createMuiTheme,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import RadioCheckboxGroup from '../../Components/RadioCheckboxGroup';
import RadioGroup from '../../Components/RadioGroup';
import './style.scss';

import dayjs from 'dayjs';
import { getAgents } from '../../Api/agentAPI';
import { getOperators } from '../../Api/operatorAPI';
import {
  COLUMN_DEFINED_SEARCH_BOX,
  GENDER,
  GENDER_CHARACTER,
} from '../../Common/constant';
import AntDateTimePicker from '../../Components/AntDateTimePicker/AntDateTimePicker';
import AntDateTimeRangePicker from '../../Components/AntDateTimePicker/AntDateTimeRangePicker';
import {
  emailAddressMainStatusOptions,
  inspectionStatusOptionsNormal,
  memberCategoryTypeOptions,
  memberImageTypeOptions,
  memberStatusOptionsRequired,
  memberTypeOfUserOptions,
  needOtpOptions,
  registerStatusOptions,
  registerTypeOptions,
  searchBoxGenders,
  standByOptions,
  hasDepositedOnTheDayOptions,
} from '../../Util/utils';
import { optionsSearchLastLogin } from '../../Common/constant_text';

const areaOptions = [
  { id: 'name_area_00', value: 0, label: 'Area01' },
  { id: 'name_area_01', value: 1, label: 'Area02' },
  { id: 'name_area_02', value: 2, label: 'Area03' },
  { id: 'name_area_03', value: 3, label: 'Area04' },
];

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      shrink: {
        fontSize: '18px',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const defaultFilter = {
  id: '',
  phone: '',
  ageverification: '',
  memberstate: '',
  state: [],
  email: '',
  operatorid: '',
  memberCategory: '',
  gender: [
    GENDER.MALE,
    GENDER.FEMALE,
    GENDER_CHARACTER.MALE_CHAR,
    GENDER_CHARACTER.FEMALE_CHAR,
  ], //  // 1, 2, 3, 4
  nickname: '',
  avatar: '',
  agentid: '',
  address: '',
  aboutme: '',
  adminmemo: '',
  pointlower: '',
  pointupper: '',
  registdatelower: '',
  registdateupper: '',
  adcode: '',
  lastlogin: '',
  lastloginlower: '',
  lastloginupper: '',
  registerType: '',
  registerStatus: '',
  emailAddressMainStatus: '',
  membertype: '',
  needotp: '',
  yearoldlower: '',
  yearoldupper: '',
  logincountlower: '',
  logincountupper: '',
  planexpiredatelower: '',
  planexpiredateupper: '',
  depositpointlower: '',
  depositpointupper: '',
  salarypointlower: '',
  salarypointupper: '',
  paymenttimeslower: '',
  paymenttimesupper: '',
  paymenttotallower: '',
  paymenttotalupper: '',
  countReceivedLower: '',
  countReceivedUpper: '',
  countSendingLower: '',
  countSendingUpper: '',
  countcallrecievedlower: '',
  countcallrecievedupper: '',
  countcallsendinglower: '',
  countcallsendingupper: '',
  countphotorecievedlower: '',
  countphotorecievedupper: '',
  countphotosendinglower: '',
  countphotosendingupper: '',
  pointpaymentlower: '',
  pointpaymentupper: '',
  standby: '',
  charastatus: '',
  memberImageType: '',
  password: '',
  rank: [],
};

function SearchBox({ onSubmit, labelDb }) {
  const classes = useStyles();
  const [filter, setFilter] = useState({ ...defaultFilter });

  const [operators, setOperators] = useState([]);
  const [agents, setAgents] = useState([]);

  React.useEffect(() => {
    getOperators({ pageIndex: 1, pageSize: 1000 }).then((res) => {
      setOperators(res.data.data);
    });
    getAgents({ pageIndex: 1, pageSize: 1000 }).then((res) => {
      setAgents(res.data.data);
    });
  }, []);

  const nameState = useSelector((state) => state.nameState.data?.items)?.map(
    (x) => ({
      id: x.id,
      value: x.id,
      label: x.label,
    }),
  );

  const rankOptions = useSelector((state) => state.nameRank.data)?.map((x) => ({
    id: x.id,
    value: x.key,
    label: x.label,
    markWord: x.markWord,
  }));

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const onChangeAntdDatePicker = (name) => {
    const curryOnChangeFn = (value, dateString) => {
      setFilter((prev) => {
        return {
          ...prev,
          [`${name}`]: dateString ? dayjs(dateString).valueOf() : undefined,
        };
      });
    };

    return curryOnChangeFn;
  };

  const onChangeAntdDateRangePicker = (
    name,
    startDateSuffix,
    endDateSuffix,
  ) => {
    const curryOnChangeFn = (value, dateString) => {
      setFilter((prev) => {
        return {
          ...prev,
          [`${name}${startDateSuffix}`]: dateString[0]
            ? dayjs(dateString[0]).valueOf()
            : undefined,
          [`${name}${endDateSuffix}`]: dateString[1]
            ? dayjs(dateString[1]).valueOf()
            : undefined,
        };
      });
    };

    return curryOnChangeFn;
  };

  const handleSearch = () => {
    try {
      let rank = filter.rank
        .map((item) => rankOptions.find((item2) => item2.id === item).id)
        .join(',');
      let state = filter.state
        .map((item) => nameState.find((item2) => item2.id === item).value)
        .join(',');

      return onSubmit({ ...filter, rank, state });
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    setFilter({ ...defaultFilter });
  };

  const validNumber = (e) => {
    if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 190) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };
  const onChangeGender = (event) => {
    let newGender = [];
    if (filter.gender?.indexOf(Number(event.target.value)) > -1) {
      newGender = filter.gender.filter(
        (item) => item !== Number(event.target.value),
      );
    } else {
      newGender = [...filter.gender, Number(event.target.value)];
    }
    if (!Number(event.target.value) && filter.gender.length === 0) {
      newGender = [
        GENDER.MALE,
        GENDER.FEMALE,
        GENDER_CHARACTER.MALE_CHAR,
        GENDER_CHARACTER.FEMALE_CHAR,
      ]; // 1, 2, 3, 4
    }
    if (!Number(event.target.value) && filter.gender.length > 0) {
      newGender = [
        GENDER.MALE,
        GENDER.FEMALE,
        GENDER_CHARACTER.MALE_CHAR,
        GENDER_CHARACTER.FEMALE_CHAR,
      ]; // 1, 2, 3, 4
    }
    if (!Number(event.target.value) && filter.gender.length === 4) {
      newGender = [];
    }

    setFilter({ ...filter, gender: newGender });
  };

  const userDetailsFieldTitleText = {
    REGISTER_DATE: '登録日',
    LAST_LOGIN: '最終ログイン',
    FIRST_SENT_MESSAGE: '初回メッセージ送信',
    LAST_SENT_MESSAGE: '最終メッセージ送信',
    FIST_RECEIVED_MESSAGE: '初回メッセージ受信',
    LAST_RECEIVED_MESSAGE: '最終メッセージ受信',
    FIRST_PAID_DATE: '初回入金日',
    LAST_PAID_DATE: '最終入金日',
  };

  const DateFieldData = [
    {
      title: userDetailsFieldTitleText.LAST_LOGIN,
      apiName: 'lastlogin',
    },
    {
      title: userDetailsFieldTitleText.REGISTER_DATE,
      apiName: 'registdate',
    },
    {
      title: '月額会員期限',
      apiName: 'planexpiredate',
    },
  ];

  const DateTimeFieldsData = [
    {
      title: userDetailsFieldTitleText.FIRST_SENT_MESSAGE,
      apiName: 'firstMessageSendingDate',
    },
    {
      title: userDetailsFieldTitleText.LAST_SENT_MESSAGE,
      apiName: 'lastMessageSendingDate',
    },
    {
      title: userDetailsFieldTitleText.FIST_RECEIVED_MESSAGE,
      apiName: 'firstMessageReceivedDate',
    },
    {
      title: userDetailsFieldTitleText.LAST_RECEIVED_MESSAGE,
      apiName: 'lastMessageReceivedDate',
    },
    {
      title: userDetailsFieldTitleText.FIRST_PAID_DATE,
      apiName: 'lastMessageReceivedDate',
    },
    {
      title: userDetailsFieldTitleText.LAST_PAID_DATE,
      apiName: 'lastPayingDate',
    },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <div className="form__search" style={{ margin: '0px 15px 15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}>
            <Row>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="電話番号"
                      type="text"
                      name="phone"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="MySQL ID"
                      type="text"
                      name="id"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label={labelDb?.label_nickName?.content}
                      type="text"
                      name="nickname"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="パスワード"
                      type="text"
                      name="password"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="メールアドレス"
                      type="text"
                      name="email"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-1">
                <FormGroup className="mb-2 d-item-form-wrapper">
                  <FormLabel component="legend">メールアドレス状態</FormLabel>
                  <RadioGroup
                    name="emailAddressMainStatus"
                    defaultValue=""
                    options={emailAddressMainStatusOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>

              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink>都道府県</InputLabel>
                      <Select
                        multiple
                        value={filter.state}
                        onChange={handleChange}
                        name="state"
                        input={<Input />}
                        renderValue={(selected) =>
                          selected
                            .sort()
                            .map(
                              (item) =>
                                nameState.find((option) => option.id === item)
                                  ?.label,
                            )
                            .join(', ')
                        }
                        style={{ width: '100%' }}
                        className={classes.selectEmpty}
                      >
                        {nameState
                          ?.filter((item) => item?.label)
                          ?.map((option, key) => (
                            <MenuItem key={key} value={option.id}>
                              <Checkbox
                                checked={filter.state?.indexOf(option.id) > -1}
                              />
                              <ListItemText primary={`${option.label}`} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink>ランク</InputLabel>
                      <Select
                        multiple
                        value={filter.rank}
                        onChange={handleChange}
                        name="rank"
                        input={<Input />}
                        renderValue={(selected) =>
                          selected
                            .sort()
                            .map(
                              (item) =>
                                rankOptions.find((option) => option.id === item)
                                  .markWord,
                            )
                            .join(', ')
                        }
                        style={{ width: '100%' }}
                        className={classes.selectEmpty}
                      >
                        {rankOptions
                          ?.filter((item) => item.label && item.markWord)
                          .map((option, key) => (
                            <MenuItem key={key} value={option.id}>
                              <Checkbox
                                checked={filter.rank.indexOf(option.id) > -1}
                              />
                              <ListItemText
                                primary={`${option.id}: ${option.markWord} : ${option.label}`}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="広告コード"
                      type="text"
                      name="adcode"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Select
                      id="operatorid"
                      label="オペレーターNumber"
                      name="operatorid"
                      onChange={onChange}
                      className="w-100"
                      defaultValue={'label'}
                    >
                      <MenuItem
                        disabled
                        value="label"
                        style={{ display: 'none' }}
                      >
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                          オペレーターNumber
                        </span>
                      </MenuItem>
                      <MenuItem value={''}>---</MenuItem>
                      {operators.map((v) => {
                        return (
                          <MenuItem value={v.operatorNumber} key={v.id}>
                            {v.operatorNumber}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <TextField
                      label="オペレーターNumber"
                      type="text"
                      name="operatorid"
                      onChange={onChange}
                      className="w-100"
                    /> */}
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Select
                      id="agentid"
                      label="会社Number"
                      name="agentid"
                      onChange={onChange}
                      className="w-100"
                      defaultValue={'label'}
                    >
                      <MenuItem
                        disabled
                        value="label"
                        style={{ display: 'none' }}
                      >
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                          会社Number
                        </span>
                      </MenuItem>
                      <MenuItem value={''}>---</MenuItem>
                      {agents.map((v) => {
                        return (
                          <MenuItem value={v.agentNumber} key={v.id}>
                            {v.agentNumber}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="自己PR（AND OR 検索可）"
                      type="text"
                      name="aboutme"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <TextField
                      label="管理者メモ（AND OR 検索可）"
                      type="text"
                      name="adminmemo"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Col>

              {[
                {
                  title: '登録日時から分以内（単位分）',
                  apiName: 'registerInRange',
                },
                {
                  title: '最終ログインから分以内（単位分）',
                  apiName: 'lastLoginInRange',
                },
                {
                  title: '初回メッセージ送信から分以内',
                  apiName: 'firstMessageInRange',
                },
                {
                  title: '最終メッセージ送信から分以内',
                  apiName: 'lastMessageInRange',
                },
              ].map((e) => (
                <Col xs={12}>
                  <FormGroup row>
                    <Col xs={12}>
                      <TextField
                        label={e.title}
                        type="tel"
                        name={e.apiName}
                        onChange={onChange}
                        className="w-100"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              ))}
              <Col xs={12} className="mt-1">
                <FormGroup className="mb-2 d-item-form-wrapper">
                  <FormLabel component="legend">最終入金日</FormLabel>
                  <RadioGroup
                    name="hasDeposited"
                    defaultValue={0}
                    options={hasDepositedOnTheDayOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}>
            <Row>
              {DateFieldData.map((e) => (
                <Col xs={12} key={e}>
                  <FormGroup row>
                    <Col xs={12}>
                      <FormLabel
                        component="legend"
                        style={{
                          fontSize: '18px',
                          transform: 'translate(0, 1.5px) scale(0.75)',
                          transformOrigin: 'top left',
                        }}
                      >
                        {e.title}
                      </FormLabel>
                    </Col>
                    <Row
                      xs={12}
                      style={{
                        width: '90.5%',
                        margin: '0',
                      }}
                    >
                      <Col xs>
                        <AntDateTimePicker
                          className="w-100"
                          onChange={onChangeAntdDatePicker(`${e.apiName}lower`)}
                          name={`${e.apiName}lower`}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        ~
                      </Col>
                      <Col>
                        <AntDateTimePicker
                          className="w-100"
                          onChange={onChangeAntdDatePicker(`${e.apiName}upper`)}
                          name={`${e.apiName}upper`}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              ))}
              {DateTimeFieldsData.map((e) => (
                <Col xs={12}>
                  <AntDateTimeRangePicker
                    title={e.title}
                    showTime={true}
                    onChange={onChangeAntdDateRangePicker(
                      e.apiName,
                      'Start',
                      'End',
                    )}
                  />
                </Col>
              ))}

              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="年齢"
                          className="w-100"
                          // size={7}
                          type="number"
                          onKeyDown={(e) => validNumber(e)}
                          name="yearoldlower"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="年齢"
                          className="w-100"
                          // size={7}
                          type="number"
                          onKeyDown={(e) => validNumber(e)}
                          name="yearoldupper"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="ポイント"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="depositpointlower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="ポイント"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="depositpointupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="給与ポイント"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="salarypointlower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="給与ポイント"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="salarypointupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="ログイン回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="logincountlower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="ログイン回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="logincountupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="入金回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="paymenttimeslower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="入金回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="paymenttimesupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="入金総額"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="paymenttotallower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="入金総額"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="paymenttotalupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="チャット受信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countReceivedLower"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="チャット受信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countReceivedUpper"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-8">
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="チャット送信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countSendingLower"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="チャット送信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countSendingUpper"
                          maxLength={7}
                          onChange={onChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="コール受信回数"
                          className="w-100"
                          type="number"
                          name="countcallrecievedlower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="コール受信回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countcallrecievedupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="コール発信回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countcallsendinglower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="コール発信回数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countcallsendingupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="画像受信数"
                          className="w-100"
                          type="number"
                          name="countphotorecievedlower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="画像受信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countphotorecievedupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5}>
                        <TextField
                          label="画像送信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countphotosendinglower"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                      <Col xs={2} className="flex-end d-col-2-symbol">
                        〜
                      </Col>
                      <Col xs={5}>
                        <TextField
                          label="画像送信数"
                          className="w-100"
                          // size={7}
                          type="number"
                          name="countphotosendingupper"
                          maxLength={7}
                          onChange={onChange}
                          onKeyDown={(e) => validNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <Row>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={12} className="d-item-form-wrapper d-item-checkbox">
                    <FormLabel component="legend">性別</FormLabel>
                    <RadioCheckboxGroup
                      name="gender"
                      options={searchBoxGenders()}
                      onChange={onChangeGender}
                      checked={filter.gender}
                      classCustom="d-cus-item"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">
                    プロフィール画像登録（未認証を含む）
                  </FormLabel>
                  <RadioGroup
                    name="memberImageType"
                    defaultValue=""
                    options={memberImageTypeOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-1">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">
                    登録環境（新規登録時）
                  </FormLabel>
                  <RadioGroup
                    name="registerType"
                    defaultValue=""
                    options={registerTypeOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">会員種類</FormLabel>
                  <RadioGroup
                    name="membertype"
                    defaultValue=""
                    options={memberTypeOfUserOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">会員カテゴリー</FormLabel>
                  <RadioGroup
                    name="memberCategory"
                    defaultValue=""
                    options={memberCategoryTypeOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">年齢認証</FormLabel>
                  <RadioGroup
                    name="ageverification"
                    defaultValue=""
                    options={inspectionStatusOptionsNormal()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">会員ステイタス</FormLabel>
                  <RadioGroup
                    name="memberstate"
                    defaultValue=""
                    options={memberStatusOptionsRequired()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              {/* <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">
                    キャラクターステイタス
                  </FormLabel>
                  <RadioGroup
                    name="charastatus"
                    defaultValue=""
                    options={charaStatusOptionsNormal()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col> */}
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">待機状態</FormLabel>
                  <RadioGroup
                    name="standby"
                    defaultValue=""
                    options={standByOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">ログインSMS必要</FormLabel>
                  <RadioGroup
                    name="needotp"
                    defaultValue=""
                    options={needOtpOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">ログイン履歴</FormLabel>
                  <RadioGroup
                    name="lastlogin"
                    defaultValue=""
                    options={[
                      { value: '', label: '全て' },
                      ...optionsSearchLastLogin,
                    ]}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="mt-0">
                <FormGroup className="d-item-form-wrapper">
                  <FormLabel component="legend">電話認証状態</FormLabel>
                  <RadioGroup
                    name="registerStatus"
                    defaultValue=""
                    options={registerStatusOptions()}
                    onChange={onChange}
                    classCustom="d-cus-item"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default SearchBox;
