import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { updateRootResourceAgent } from '../../Redux/Actions/resourceAgent';
import { useDispatch } from 'react-redux';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';

function RootProfileList({
  dataRootProfile,
  isEditing,
  handleEdit,
  onClose,
  changeDataRootProfile,
}) {
  const [dataProfile, setDataProfile] = useState({});
  const [inputUpdate, setInputUpdate] = useState({});
  const [filter, setFilter] = useState({
    sortBy: 'profile',
    orderBy: 'ASC',
    sort: { profile: 'ASC' },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    setDataProfile(dataRootProfile);
  }, [dataRootProfile]);

  const handleChangeItemProfile = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    if (splitName && splitName.length) {
      for (const property in newArray) {
        newArray[property] = newArray[property].map((item) => {
          if (item.profile === splitName[1]) {
            item[splitName[0]] = e.target.value;
          }

          return item;
        });
      }
      setInputUpdate(newArray);
      changeDataRootProfile(newArray);
    }
  };

  const handleUpdate = () => {
    dispatch(updateRootResourceAgent(inputUpdate));
    setDataProfile(inputUpdate); // Set update when change data
    changeDataRootProfile(inputUpdate); // Set update when change data
    onClose();
  };

  const handleClose = () => {
    changeDataRootProfile({ ...dataProfile }); // Revert data old
    setInputUpdate({}); // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); // Clone deep data when use object
    handleEdit();
  };

  const onSort = (item) => {
    if (isEditing) {
      return; // Not sort while editing
    }

    let sortable;
    if (filter.orderBy === 'ASC') {
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (b[0][item] > a[0][item]) {
            return 1;
          } else if (b[0][item] < a[0][item]) {
            return -1;
          }

          return 0;
        }),
      );
    } else {
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (a[0][item] > b[0][item]) {
            return 1;
          } else if (a[0][item] < b[0][item]) {
            return -1;
          }

          return 0;
        }),
      );
    }

    setFilter({
      ...filter,
      sortBy: item,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [item]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });

    setDataProfile(sortable);
  };

  return (
    <>
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ textAlign: 'right', margin: '10px 0' }}>
          {isEditing ? (
            <div className="my-3">
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={handleClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </div>
          ) : (
            <Button
              className="btn-blue"
              style={{
                padding: '6px 14px',
                margin: 'auto',
                color: 'white',
                marginRight: 10,
              }}
              variant="contained"
              onClick={handleBtnEdit}
            >
              編集
            </Button>
          )}
        </div>

        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="カラム名"
                    name="profile"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="会社プロフ"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="プロフィール表示順"
                    name="profileOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索表示順"
                    name="searchOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索方法(1Check/2Radio/3Range)"
                    name="searchType"
                    onClick={onSort}
                  />
                </TableCell>
              </TableRow>
              {Object.keys(dataProfile).map((itemGroup, index) => (
                <TableRow align="left" key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{itemGroup}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataProfile[itemGroup][0]?.label}
                        name={`label.${itemGroup}`}
                        onChange={handleChangeItemProfile}
                      />
                    ) : (
                      dataProfile[itemGroup][0]?.label
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataProfile[itemGroup][0]?.profileOrder}
                        name={`profileOrder.${itemGroup}`}
                        onChange={handleChangeItemProfile}
                      />
                    ) : (
                      dataProfile[itemGroup][0]?.profileOrder
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataProfile[itemGroup][0]?.searchOrder}
                        name={`searchOrder.${itemGroup}`}
                        onChange={handleChangeItemProfile}
                      />
                    ) : (
                      dataProfile[itemGroup][0]?.searchOrder
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataProfile[itemGroup][0]?.searchType}
                        name={`searchType.${itemGroup}`}
                        onChange={handleChangeItemProfile}
                      />
                    ) : (
                      dataProfile[itemGroup][0]?.searchType
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </>
  );
}

export default RootProfileList;
