import { IMAGE_TYPE } from '../Config/listConstants';
import { sendDelete, sendGet, sendPost, sendPostFile, sendPut } from './axios';

export const getImages = (filter) => {
  return sendPost('/cms/images', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};

export const changeImageStatus = ({ id, inspection }) => {
  return sendPut(`/cms/image/${id}`, { inspection });
};

export const changeImageStatusAgent = ({ id, inspection }) => {
  return sendPut(`/cms/image-agent/${id}`, { inspection });
};

export const createAvatar = (payload) => {
  const { url, memberId, status } = payload;

  return sendPost('/cms/avatar', {
    url,
    memberId,
    status,
    type: IMAGE_TYPE.AVATAR,
  });
};

export const createAvatarAgent = (payload) => {
  const { url, memberAgentId, status } = payload;

  return sendPost('/cms/avatar-agent', {
    url,
    memberAgentId,
    status,
    type: IMAGE_TYPE.AVATAR,
  });
};

export const createSubImages = (payload) => {
  const { url, subImgNumber, memberId, status } = payload;

  return sendPost('/cms/subimages', {
    sub_images: [
      {
        url,
        subImgNumber,
        memberId,
        status,
        type: IMAGE_TYPE.SUB_IMAGE,
      },
    ],
  });
};

export const createSubImagesAgent = (payload) => {
  const { url, subImgNumber, memberAgentId, status } = payload;

  return sendPost('/cms/subimages-agent', {
    sub_images: [
      {
        url,
        subImgNumber,
        memberAgentId,
        status,
        type: IMAGE_TYPE.SUB_IMAGE,
      },
    ],
  });
};

export const deleteImage = (id) => {
  return sendDelete(`/cms/image/${id}`);
};

export const deleteImageAgent = (id) => {
  return sendDelete(`/cms/image-agent/${id}`);
};

export const deleteImageStorage = (file) => {
  return sendGet(`/image/storage?file=${btoa(file)}`);
};

export const uploadImage = (formData, params) => sendPostFile(`/image/upload`, formData, params);