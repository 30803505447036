import { Button, Dialog, DialogContent, DialogTitle, FormLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import LoadingBar from '../../Components/LoadingBar';
import { loadUserIds } from '../../Redux/Actions/users';
import { toast } from 'react-toastify';
import { sendNotiListUser, setTimeNoti } from '../../Api/userAPI';
import { Card, Col, Label, Row } from 'reactstrap';
import moment from 'moment';
import RadioGroup from '../../Components/RadioGroup';
import DatePicker from 'react-datepicker';
import { DISPLAY_OPTIONS, TIMER_SENDING_TYPE } from '../../Common/constant';
import { TEXT_HTML_MANAGEMENT_USER_SEARCH_NOTIFY_LINK_MODAL } from '../../Common/modal_text_info';
import { convertDisplayOptionsText, displayOptionsOptionsRequired } from '../../Util/utils';
import { style } from '../UserManagement/common-styles';
import { TOAST_MESSAGE } from '../../Common/constant_text';


function SendNotiModal({ visible, onClose, filter }) {
  const [data, setData] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hourSend, setHourSend] = useState('00');
  const [minusSend, setMinusSend] = useState('00');
  const [dateSend, setDateSend] = useState(moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState(new Date(moment().startOf('day')).getTime());
  const [timeSend, setTimeSend] = useState();
  const [timerNoti, setTimerNoti] = useState(`${DISPLAY_OPTIONS.OFF}`);
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.itemsUserIds);
  const totalRow = useSelector((state) => state.users.totalRowUserids);

  useEffect(() => {
    dispatch(loadUserIds(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    const userIds = users.map((item) => item.id);
    setUserIds(userIds);
  }, [users]);

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (userIds.length <= 0) {
      setLoading(false);
      toast.error('受信者数が０人のため、EMAIL送信できません。');
      return;
    }

    if (String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.OFF) {
      await sendNotiListUser({ ...data, userIds }).then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          setLoading(false);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          setLoading(false);
        }
      });
    } else {
      await setTimeNoti({
        sendTime: timeSend,
        userIds: userIds,
        type: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
        ...data,
      }).then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          setConFirmVisible(false);
        }
      });
    }
  };

  const handleConfirm = () => {
    if (!data.title) {
      return toast.error('タイトルしてください!');
    }
    if (!data.content) {
      return toast.error('本文してください!');
    }

    if (data.content && data.title) {
      setConFirmVisible(true);
    }
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handlerTimerNotify = (value) => {
    setTimerNoti(`${value}`);
  };

  const formatShowContentMessage = (content = '') => {
    return `${content}`.replace(/(\r\n|\r|\n)/g, '<br>');
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              {/*実行してよろしいですか？99999*/}
              <Card body className="mb-4">
                <Row>
                  <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <p style={{ paddingLeft: 3 }}>日時</p>
                    </div>
                    <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                      <p>
                        {String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.ON && timeSend ? moment(timeSend).format('YYYY/MM/DD HH:mm') : dateSend}
                      </p>
                    </div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                        <span className={`${String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.ON ? 'd-red-color' : 'd-blue-color'}`}>
                          {convertDisplayOptionsText(timerNoti)}
                        </span>
                    </div>
                  </Col>
                </Row>
                <Row className={'d-line-col'}>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                  </Col>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>タイトル</p>
                  </Label>
                  <Label style={{ ...style, paddingLeft: 8 }} xs={9}>
                    <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
                  </Label>
                </Row>
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>本文</p>
                  </Label>
                  <Label style={{ ...style, paddingLeft: 8 }} xs={9}>
                    <div dangerouslySetInnerHTML={{ __html: formatShowContentMessage(data.content) }}></div>
                  </Label>
                </Row>
              </Card>
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>PUSH送信</p>
            <div style={{ fontSize: '1rem' }} className={'wrapper-info-title'}>
              受信者数 <span>{totalRow}人</span>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_USER_SEARCH_NOTIFY_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <Card style={{ marginBottom: 10 }}>
            <Row style={{ padding: 20 }}>
              <Col xs={12}>
                <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>タイトル</div>
                <input
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    border: '1px solid rgb(118, 118, 118)',
                  }}
                  name="title"
                  onChange={onChange}
                />
                <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>本文</div>
                <textarea
                  name="content"
                  rows="3"
                  onChange={onChange}
                  style={{ width: '100%', marginBottom: '10px', padding: 10 }}
                  maxLength={500}
                />

                <div className="mt-3 mb-1 timer-container">
                  <span>タイマー日時</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="datePicker">
                    <DatePicker
                      selected={selectedDate}
                      className="date"
                      onChange={(e) => handleDate(e)}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      popperPlacement="top-start"
                      disabled={!(String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.ON)}
                    />
                    <select
                      defaultChecked={hourSend}
                      onChange={(e) => handleHour(e, 'start_hour')}
                      className="hour"
                      disabled={!(String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.ON)}
                    >
                      {hour.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleMinus(e, 'start_minus')}
                      className="minus"
                      disabled={!(String(timerNoti) && +timerNoti === DISPLAY_OPTIONS.ON)}
                    >
                      {minute.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="display-setting-timer d-un-width">
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={DISPLAY_OPTIONS.OFF}
                      options={displayOptionsOptionsRequired()}
                      onChange={(e) => handlerTimerNotify(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <div style={{ textAlign: 'right', marginBottom: 2 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendNotiModal;
