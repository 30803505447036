import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CircularLoading() {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', minHeight: '65vh', justifyContent: 'center', alignItems: 'center' }}>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  );
}
