import { createAction } from 'redux-actions';
import Character from '../Types/character';

export const loadCharacter = createAction(Character.LOAD_CHARACTER);
export const loadCharacterSuccess = createAction(
  Character.LOAD_CHARACTER_SUCCESS,
);
export const loadCharacterFail = createAction(Character.LOAD_CHARACTER_FAIL);

export const loadDetailCharacter = createAction(Character.LOAD_DEATIL_CHARACTER);
export const loadDetailCharacterSuccess = createAction(Character.LOAD_DEATIL_CHARACTER_SUCCESS);
export const loadDetailCharacterFail = createAction(Character.LOAD_DEATIL_CHARACTER_FAIL);

export const updateCharacter = createAction(Character.UPDATE_CHARACTER);
export const updateCharacterSuccess = createAction(Character.UPDATE_CHARACTER_SUCCESS);
export const updateCharacterFail = createAction(Character.UPDATE_CHARACTER_FAIL);
