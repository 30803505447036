import { call, put, takeLatest } from 'redux-saga/effects';
import EnvUI from '../Types/envUI';
import { getEnvUI, updateEnvUI } from '../../Api/EnvUiAPI';
import { loadEnvUIFail, loadEnvUISuccess, updateEnvUIFail, updateEnvUISuccess } from '../Actions/envUI';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvUI(action) {
  try {
    const response = yield call(getEnvUI, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadEnvUISuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvUIFail(error));
  }
}

function* handleUpdateEnvUI(action) {
  try {
    const response = yield call(updateEnvUI, action.payload);
    const { data } = response;
    if (data.success) {
      const environment = data.data;
      yield put(updateEnvUISuccess({ data: environment }));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvUIFail(error));
  }
}

export default function* envUISaga() {
  yield takeLatest(EnvUI.LOAD_ENVUI, handleLoadEnvUI);
  yield takeLatest(EnvUI.UPDATE_ENVUI, handleUpdateEnvUI);
}
