import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import NoPhotoJob from '../assets/image/no_job_photo.png';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiPaper: {
      root: {
        background: '#8a8a8a !important',
        overflow: 'hidden',
      },
    },
  },
});

function ModalShowImage({ image, onClose }) {
  return (
    <MuiThemeProvider theme={customTheme}>
      <div>
        <Dialog
          maxWidth={'md'}
          onClose={onClose}
          open={true}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            <Button
              style={{
                position: 'absolute',
                top: 8,
                right: -15,
              }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
              }}
            >
              <img
                src={
                  image.url
                    ? image.url
                    : image.content
                    ? image.content
                    : NoPhotoJob
                }
                style={{ maxWidth: 500, maxHeight: 500 }}
                alt={image.url}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}

export default ModalShowImage;
