import { call, put, takeLatest } from 'redux-saga/effects';
import NameRank from '../Types/nameRank';
import { getListNameRank, getNameRank, updateNameRank } from '../../Api/nameRankAPI';
import { toast } from 'react-toastify';
import {
  loadListNameRankFail,
  loadListNameRankSuccess,
  loadNameRankFail,
  loadNameRankSuccess,
  updateNameRankFail,
  updateNameRankSuccess,
} from '../Actions/nameRank';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadNameRank(action) {
  try {
    const response = yield call(getNameRank, action.payload);
    const { data } = response;
    if (data.success) {
      const nameRank = data;
      yield put(loadNameRankSuccess(nameRank));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadNameRankFail(error));
  }
}

function* handleLoadListNameRank(action) {
  try {
    const response = yield call(getListNameRank, action.payload);
    const { data } = response;
    if (data.success) {
      const nameRank = data;
      yield put(loadListNameRankSuccess(nameRank));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadListNameRankFail(error));
  }
}

function* handleUpdateNameRank(action) {
  try {
    const response = yield call(updateNameRank, action.payload);
    const { data } = response;
    if (data.success) {
      const user = data.data;
      yield put(updateNameRankSuccess(user));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateNameRankFail(error));
  }
}

export default function* userSaga() {
  yield takeLatest(NameRank.LOAD_NAME_RANK, handleLoadNameRank);
  yield takeLatest(NameRank.LOAD_LIST_NAME_RANK, handleLoadListNameRank);
  yield takeLatest(NameRank.UPDATE_NAME_RANK, handleUpdateNameRank);
}
