const JobMember = {
  LOAD_JOB_MEMBER: 'LOAD_JOB_MEMBER',
  LOAD_JOB_MEMBER_SUCCESS: 'LOAD_JOB_MEMBER_SUCCESS',
  LOAD_JOB_MEMBER_FAIL: 'LOAD_JOB_MEMBER_FAIL',

  UPDATE_JOB_MEMBER: 'UPDATE_JOB_MEMBER',
  UPDATE_JOB_MEMBER_SUCCESS: 'UPDATE_JOB_MEMBER_SUCCESS',
  UPDATE_JOB_MEMBER_FAIL: 'UPDATE_JOB_MEMBER_FAIL',
};

export default JobMember;
