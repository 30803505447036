import React from 'react';

function RadioGroup({
  name,
  defaultValue,
  controlled,
  options,
  onChange,
  disabled,
  classCustom,
}) {
  return (
    <div className={`radio-group ${classCustom ?? ''}`}>
      {options.map((option) => (
        <div
          style={{ width: `${100 / options.length}%` }}
          className={`${classCustom ?? ''}-child`}
          key={option.label}
        >
          <label className="radio-option">
            <input
              name={name}
              defaultChecked={
                controlled ? undefined : `${option.value}` === `${defaultValue}`
              }
              checked={
                controlled ? `${option.value}` === `${defaultValue}` : undefined
              }
              value={option.value}
              type="radio"
              onChange={onChange}
              disabled={disabled}
            />
            <span>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
