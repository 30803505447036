import { call, put, takeLatest } from 'redux-saga/effects';
import Resource from '../Types/resources';
import {
  getDetailResource,
  getResource,
  getRootResource,
  updateChildResource,
  updateRootResource,
} from '../../Api/resourseApi';
import {
  getDetailRootResourceFail,
  getDetailRootResourceSuccess,
  loadResourceFail,
  loadResourceSuccess,
  loadRootResourceFail,
  loadRootResourceSuccess,
  updateChildResourceFail,
  updateChildResourceSuccess,
  updateRootResourceFail,
  updateRootResourceSuccess,
} from '../Actions/resource';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadResource(action) {
  try {
    const response = yield call(getResource, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadResourceSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadResourceFail(error));
  }
}

function* handleLoadRootResource(action) {
  try {
    const response = yield call(getRootResource, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      yield put(loadRootResourceSuccess({ items }));
    }
  } catch (error) {
    yield put(loadRootResourceFail(error));
  }
}

function* handleGetDetailResource(action) {
  try {
    const response = yield call(getDetailResource, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(getDetailRootResourceSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(getDetailRootResourceFail(error));
  }
}

function* handleUpdateRootResource(action) {
  try {
    const response = yield call(updateRootResource, action.payload);
    const { data } = response;
    if (data.success) {
      const rootResource = data.data;
      yield put(updateRootResourceSuccess(rootResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateRootResourceFail(error));
  }
}

function* handleUpdateChildResource(action) {
  try {
    const response = yield call(updateChildResource, action.payload);
    const { data } = response;
    if (data.success) {
      const childResource = data.data;
      yield put(updateChildResourceSuccess(childResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateChildResourceFail(error));
  }
}

export default function* resourceSaga() {
  yield takeLatest(Resource.LOAD_RESOURCE, handleLoadResource);
  yield takeLatest(Resource.LOAD_ROOT_RESOURCE, handleLoadRootResource);
  yield takeLatest(Resource.GET_DETAIL_ROOT_RESOURCE, handleGetDetailResource);
  yield takeLatest(Resource.UPDATE_ROOT_RESOURCE, handleUpdateRootResource);
  yield takeLatest(Resource.UPDATE_CHILD_RESOURCE, handleUpdateChildResource);
}
