import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link, useLocation } from 'react-router-dom';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import { Card, MuiThemeProvider, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { Col, Row } from 'reactstrap';
import LoadingBar from '../../Components/LoadingBar';
import { convertMemberTypeArrayText } from '../../Util/utils';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {
  accessAndPassDataIframe,
} from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

function JobEntryMemberList(props) {
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [user, setUser] = useState();
  const {pathname} = useLocation()

  const {
    jobEntryMembers,
    filter = {},
    onSort,
    pageIndex,
    pageSize,
    loading,
    totalRow,
    type,
    labelDb
  } = props;

  const handleMyProfile = (userId) => {
    setUser(userId)
    setMyProfVisible(true);
  };

  const accessAndPassData = () =>  {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  }

  return (
    <div>
      {/*応募者詳細*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={myProfVisible}
        onClose={() => setMyProfVisible(false)}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          応募者詳細
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => setMyProfVisible(false)}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ marginBottom: 30, height: '80vh', overflow: 'hidden' }}>
          {user &&
            <iframe title="user-preview"
                    id="iframeId"
                    onLoad={accessAndPassData}
                    style={{ border: 'none', width: '100%', height: '100%' }}
                  src={user && Util.myProfSrc(user)}></iframe>
          }
        </DialogContent>
      </Dialog>

      <React.Fragment>
        <LoadingBar show={loading} />
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top">
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="電話番号"
                      name="phoneNumber"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    画像
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text={`${labelDb?.label_nickName?.content}`}
                      name="nickName"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-left">名前（フリガナ）</TableCell>
                  <TableCell className="text-left">メールアドレス</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={
                        filter.sort?.gender && {
                          ...filter.sort,
                          gender:
                            filter.sort?.gender === 'ASC' ? 'DESC' : 'ASC',
                        }
                      }
                      text="性別"
                      name="gender"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>年齢</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="state"
                      text="都道府県"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>会員種類</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="createdDate"
                      onClick={onSort}
                      text="登録日"
                    />
                  </TableCell>
                {
                  pathname?.includes('detailagent') && <TableCell>応募者詳細</TableCell>
                }
                </TableRow>

                {jobEntryMembers.map((jobEntryMember, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>{jobEntryMember?.member?.phoneNumber}</TableCell>
                    <TableCell>
                      <img
                        className="imgRound"
                        src={
                          jobEntryMember?.member?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          jobEntryMember?.member?.avatar?.url ? jobEntryMember?.member?.avatar?.url : NoPhoto}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{jobEntryMember?.member?.nickName}</TableCell>
                    <TableCell>{jobEntryMember?.member?.realNameHurigana}</TableCell>
                    <TableCell className="text-left">{jobEntryMember?.member?.emailAddressMain}</TableCell>
                    <TableCell>{Util.genderIdToText(jobEntryMember?.member?.gender)}</TableCell>
                    <TableCell>
                      <time>
                        {Util.calcAge(Util.timestampToDate(jobEntryMember?.member?.birthday, 'YYYY-MM-DD'))}
                      </time>
                    </TableCell>
                    <TableCell>{jobEntryMember?.member?.states?.label}</TableCell>
                    <TableCell>{convertMemberTypeArrayText(jobEntryMember?.member?.memberType)}</TableCell>
                    <TableCell>
                      {Util.timestampToDate(jobEntryMember.createdDate, 'YYYY/MM/DD')}
                    </TableCell>
                    <TableCell className="text-center">
                      {
                        pathname?.includes('detailagent') &&
                        <Button onClick={() => handleMyProfile(jobEntryMember?.member)} variant="contained"
                          className="btn-custom btn-blue btn-action-preview-profile"
                        >
                          詳細
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
      </React.Fragment>
    </div>
  );
}

export default JobEntryMemberList;
