import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import ExecutingImportModal from './ExecutingImportModal';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { filesize } from 'filesize';

export default function ConfirmImportModal({
  isOpen,
  onClose,
  onSubmit,
  fileData,
}) {
  const [isExecutingModalOpen, setIsExecutingModalOpen] = useState(false);

  const handleClickSubmit = () => {
    onSubmit();
    setIsExecutingModalOpen(true);
    onClose();
  };
  const handleClickClose = () => {
    onClose();
  };

  const onCloseExecutingModal = () => {
    setIsExecutingModalOpen(false);
    onClose();
  };

  return (
    <>
      <ExecutingImportModal
        isOpen={isExecutingModalOpen}
        onClose={onCloseExecutingModal}
      />
      <Dialog
        open={isOpen}
        onClose={onClose}
        className={classNames(styles['modal-wrapper'], 'd-modal-wrapper')}
      >
        <DialogTitle>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '200px',
            }}
          >
            <Typography align="center" style={{ fontSize: '20px' }}>
              このファイルをインポートしますか？
            </Typography>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                border: 'dashed 1px ',
                borderRadius: '15px',
                padding: '10px',
              }}
            >
              <FontAwesomeIcon
                icon={faFileCsv}
                style={{
                  color: '#1e6d41',
                  transform: 'scale(2)',
                  marginRight: '15px',
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{fileData.fileName}</Typography>
                <Typography variant="subtitle2">
                  {filesize(fileData.size)}
                </Typography>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button className="btn-custom btn-red" onClick={handleClickClose}>
            いいえ
          </Button>
          <Button className="btn-custom btn-blue" onClick={handleClickSubmit}>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
