import { createAction } from 'redux-actions';
import Agent from '../Types/agent';

export const loadAgent = createAction(Agent.LOAD_AGENT);
export const loadAgentSuccess = createAction(Agent.LOAD_AGENT_SUCCESS);
export const loadAgentFail = createAction(Agent.LOAD_AGENT_FAIL);
export const loadAgentUpdateLocal = createAction(Agent.LOAD_AGENT_UPDATE_LOCAL);

export const updateAgent = createAction(Agent.UPDATE_AGENT);
export const updateAgentSuccess = createAction(Agent.UPDATE_AGENT_SUCCESS);
export const updateAgentFail = createAction(Agent.UPDATE_AGENT_FAIL);

export const agentLogout = createAction(Agent.AGENT_LOG_OUT);

