import { call, put, takeLatest } from 'redux-saga/effects';
import EnvUICms from '../Types/envUICms';
import { getEnvUICms, updateEnvUICms } from '../../Api/EnvUiCmsAPI';
import { loadEnvUICmsFail, loadEnvUICmsSuccess, updateEnvUICmsFail, updateEnvUICmsSuccess } from '../Actions/envUICms';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvUICms(action) {
  try {
    const response = yield call(getEnvUICms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadEnvUICmsSuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvUICmsFail(error));
  }
}

function* handleUpdateEnvUICms(action) {
  try {
    const response = yield call(updateEnvUICms, action.payload);
    const { data } = response;
    if (data.success) {
      const environment = data.data;
      yield put(updateEnvUICmsSuccess({ data: environment }));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvUICmsFail(error));
  }
}

export default function* envUICmsSaga() {
  yield takeLatest(EnvUICms.LOAD_ENVUICMS, handleLoadEnvUICms);
  yield takeLatest(EnvUICms.UPDATE_ENVUICMS, handleUpdateEnvUICms);
}
