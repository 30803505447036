import { call, put, takeLatest } from 'redux-saga/effects';
import paymentJob from '../Types/paymentJob';
import { getPaymentJobList } from '../../Api/paymentJobApi';
import { loadPaymentJobFail, loadPaymentJobSuccess } from '../Actions/paymentJob';


function* handleLoadPaymentJob(action) {
  try {
    const response = yield call(getPaymentJobList, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadPaymentJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadPaymentJobFail(error));
  }
}

export default function* paymentJobSaga() {
  yield takeLatest(paymentJob.LOAD_PAYMENT_JOB, handleLoadPaymentJob);
}
