import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import React, { useState } from 'react';
import Nophoto from '../../../assets/image/no_user_photo.png';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { TEXT_HTML_ENTRY_PAID_MODAL } from '../../../Common/modal_text_info';
import { themeStyleNormal1Modal } from './theme_modal';
import {
  ADD_DAY_ENTRY_PAIL_PLAN_AGENT,
} from '../../../Common/constant';
import moment from 'moment';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { entryPailPlanAgent } from '../../../Api/agentAPI';
import RadioCheckboxGroup from '../../../Components/RadioCheckboxGroup';
import Util from '../../../Util/Util';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrCustom = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 25,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    fontSize: 18,
    marginBottom: 0,
  },
}));

const dataOptionEntryPailPlanCheckbox = [
  {
    label: '１ヶ月掲載に申し込みます。',
    value: 1,
    link:''
  },
  {
    label: '規約に同意します。',
    value: 2,
    link: `${Util.domainWebUseWithUseWWW()}/lp/text/terms_web_frame.html`
  },
]

function EntryPaidPlanModal({
  visible,
  onClose,
  agentId,
  nickName,
  avatar,
}) {
  const [data, setData] = useState({});
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [filter, setFilter] = useState({ type: [] });

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  const handleConfirm = () => {
    let check = true;
    if (!filter.type.includes(1)) {
      check = false;
      toast.error('「掲載申し込み」にチェックが入っていません');
    }
    if (!filter.type.includes(2)) {
      check = false;
      toast.error('「規約の同意」にチェックが入っていません');
    }
    setData({
      planExpireDate: new Date(moment().startOf('day')).getTime(),
    });
    if (check) setConFirmVisible(true);
  };

  const handleSubmit = async () => {
    await entryPailPlanAgent({ ...data, agentId })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          setConFirmVisible(false);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        }
      })
      .catch((e) => {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
        setConFirmVisible(false);
      });
  };

  const onChangeType = (event) => {
    const { value } = event.target;
    let newType = [];

    if (value === '') {
      setFilter({ ...filter, type: newType });
      return;
    }

    newType = filter.type.includes(+value)
      ? filter.type.filter((item) => item !== +value && item !== '')
      : [...filter.type, +value];

    setFilter({ ...filter, type: newType });
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              {/*実行してよろしいですか？*/}
              <Row>
                <Col xs={12}>
                  <Card body style={{ marginBottom: 10, padding: 20 }}>
                    <Row className='mb-3'>
                      <Col xs={12}>
                        <span>１ヶ月掲載に申し込みます。</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Row>
                          <Label xs={6} style={style}>
                            <p>１ヶ月掲載申込金額</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.separator(0)}円</p>
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Label xs={6} style={style}>
                            <p>次回支払い期限</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>
                              {moment(
                                new Date(moment().startOf('day')).getTime(),
                              )
                                .add(ADD_DAY_ENTRY_PAIL_PLAN_AGENT.DAY, 'days')
                                .format('YYYY/MM/DD')}
                            </p>
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>掲載申込</p>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{ __html: TEXT_HTML_ENTRY_PAID_MODAL }}
          ></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={themeStyleNormal1Modal}>
            <Card>
              <Row style={{ padding: 20 }}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="d-flex flex-column">
                      <p style={{ marginBottom: 5 }}>お申し込み日の翌日から1ヶ月間求人掲載が可能です。</p>
                      <p style={{ marginBottom: 5 }}>料金は後払いとなります、下記お支払い期限までにお支払い下さい。</p>
　　　　　　　　　　　　<p style={{ marginBottom: 5 }}>ただ今開設キャンペーンにつき、0円にて掲載して頂けます。</p>
                      <p>掲載数に原則制限はございません。</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col className='mb-3' xs={6}>
                          <div className="d-flex justify-content-start align-items-center">
                            <Label>1ヶ月間掲載金額（消費税別）</Label>
                            <span className="mb-2 ml-5">
                              {Util.separator(0)}円
                            </span>
                          </div>
                          <hr style={stylehrCustom} />
                        </Col>
                        <Col className='mb-3' xs={6}>
                          <div className="d-flex justify-content-start align-items-center">
                            <Label>次回支払い期限</Label>
                            <span className="mb-2 ml-5">
                              {moment(new Date(moment().startOf('day')).getTime())
                                .add(92, 'days')
                                .format('YYYY/MM/DD')}
                            </span>
                          </div>
                          <hr style={stylehrCustom} />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={9}>
                      <FormGroup row>
                        <Col
                          xs={6}
                          className="d-item-form-wrapper d-item-checkbox p-0"
                        >
                          <span style={{ fontSize: 14 }}>下記に事項を確認しチェックを入れて下さい。</span>
                          <RadioCheckboxGroup
                            name="type"
                            options={dataOptionEntryPailPlanCheckbox}
                            onChange={onChangeType}
                            checked={filter.type}
                            classCustom="d-cus-item"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </MuiThemeProvider>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EntryPaidPlanModal;
