const PointSetting = {
  LOAD_POINT_SETTING: 'LOAD_POINT_SETTING',
  LOAD_POINT_SETTING_SUCCESS: 'LOAD_POINT_SETTING_SUCCESS',
  LOAD_POINT_SETTING_FAIL: 'LOAD_POINT_SETTING_FAIL',

  UPDATE_POINT_SETTING: 'UPDATE_POINT_SETTING',
  UPDATE_POINT_SETTING_SUCCESS: 'UPDATE_POINT_SETTING_SUCCESS',
  UPDATE_POINT_SETTING_FAIL: 'UPDATE_POINT_SETTING_FAIL',
};
export default PointSetting;
