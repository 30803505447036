import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { loadDetailOperator, updateOperator } from '../../../../Redux/Actions/operator';
import { Card, Col, Label, Row } from 'reactstrap';
import LoadingBar from '../../../../Components/LoadingBar';
import { createMuiTheme } from '@material-ui/core/styles';
import { FormGroup, FormLabel, InputLabel, MuiThemeProvider, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { REGEX_ALPHABET, REGEX_NUMBER } from '../../../../Common/constant';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import { checkFullWidth, convertEnvInfo, loginControlArrayOptionsRequired, validateFullWidthVsAlphabet } from '../../../../Util/utils';
import RadioGroup from '../../../../Components/RadioGroup';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index() {
  const params = useParams();
  const operatorId = params.operatorId;
  const dispatch = useDispatch();
  const envInfo = useSelector((state) => state.envInfo.data);
  const [colorLabel, setColorLabel] = useState('')

  useEffect(() => {
    dispatch(loadDetailOperator(operatorId));
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2?.content)
  }, [dispatch, operatorId, envInfo]);

  const submitEditOperator = () => {
    if (!editingOperator.phoneNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.phoneNumber);
    } else if (editingOperator.phoneNumber && (checkFullWidth(editingOperator.phoneNumber) || !REGEX_NUMBER.test(String(editingOperator.phoneNumber)))) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
    }

    if (!editingOperator.operatorNumber) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operatorNumber);
    } else if (editingOperator.operatorNumber && !REGEX_ALPHABET.test(editingOperator.operatorNumber)) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.operatorNumber);
    }

    if (!editingOperator.password) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
    }
    if (
      editingOperator.password &&
      (editingOperator.password.length < 8
        || editingOperator.password.length > 10
        || !REGEX_ALPHABET.test(editingOperator.password)
        || checkFullWidth(editingOperator.password))
    ) {
      return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
    }
    if (!editingOperator.operatorName) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operator.name);
    }

    const updateData = _.pick(editingOperator, [
      'id',
      'phoneNumber',
      'operatorNumber',
      'password',
      'operatorName',
      'adminMemo',
      'logincontrol'
    ]);
    dispatch(updateOperator(updateData));
  };

  const loading = useSelector((state) => state.detailOperator.loading);
  const updating = useSelector((state) => state.detailOperator.updating);
  const updated = useSelector((state) => state.detailOperator.updated);
  const operator = useSelector((state) => state.detailOperator.data);
  const resource = useSelector((state) => state.resource.data);

  const [editingOperator, setEditingOperator] = useState();
  useEffect(() => {
    if (operator) {
      setEditingOperator((editingOperator) => editingOperator || operator);
    }
  }, [operator]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailOperator(operatorId)
    }
  }, [updated]);

  const onChange = (e) => {
    const updateOperator = _.set(editingOperator, e.target.name, e.target.value);
    setEditingOperator({ ...updateOperator });
  };

  const onBlur = (e) => {
    const updateOperator = _.set(editingOperator, e.target.name, e.target.value);
    setEditingOperator({ ...updateOperator });
  };

  const history = useHistory();
  const redirectPageDetailOperator = (id) => {
    history.push(`/operator/${id}/detail`);
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        editingOperator[name] = '';
      } else {
        editingOperator[name] = value;
      }
      setEditingOperator({ ...editingOperator });
    });
  };

  if (!editingOperator) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={(loading && !_.isEmpty(resource)) || updating} />
      <MuiThemeProvider theme={customTheme}>
        <div align="right" style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={() => redirectPageDetailOperator(operator.id)}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue"
            variant="contained"
            onClick={submitEditOperator}
          >
            更新する
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <FormGroup row>
                <InputLabel style={{ color: colorLabel }} shrink>
                  <span className="text-red">*</span>
                  オペレーターNumber（重複NG）
                </InputLabel>
                <input
                  style={{ marginBottom: 10 }}
                  name="operatorNumber"
                  type="text"
                  defaultValue={editingOperator?.operatorNumber}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="w-100"
                />
              </FormGroup>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel }} shrink>
                      <span className="text-red">*</span>
                      電話番号（重複NG）
                    </InputLabel>
                    <input
                      style={{ marginBottom: 10 }}
                      name="phoneNumber"
                      type="text"
                      defaultValue={editingOperator?.phoneNumber}
                      maxLength={11}
                      onChange={onChange}
                      className="w-100"
                      onBlur={onBlur}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel }} shrink>
                      <span className="text-red">*</span>
                      パスワード（英数字8文字～10文字）
                    </InputLabel>
                    <input
                      style={{ marginBottom: 10 }}
                      defaultValue={editingOperator?.password}
                      value={editingOperator?.password}
                      name="password"
                      type="text"
                      onChange={(e) => validatePassword(e.target.value, e.target.name)}
                      onBlur={onBlur}
                      className="w-100"
                    />
                  </FormGroup>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel }} shrink>
                      <span className="text-red">*</span>
                      オペレーター名
                    </InputLabel>
                    <input
                      defaultValue={editingOperator?.operatorName}
                      name="operatorName"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      className="w-100"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormLabel style={{ color: colorLabel }} component="legend">ログイン制御</FormLabel>
                  <RadioGroup
                    name="logincontrol"
                    defaultValue={editingOperator?.logincontrol}
                    options={loginControlArrayOptionsRequired()}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label style={{ color: colorLabel }} xs={2}>管理者メモ</Label>
                <Label xs={10}>
                  {/* <FormLabel component="legend">管理者メモ</FormLabel> */}
                  <textarea
                    defaultValue={editingOperator?.adminMemo}
                    name="adminMemo"
                    className="w-100"
                    rows="3"
                    style={{ padding: 10 }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Label>
              </Row>
            </Card>
          </Col>
        </Row>
      </MuiThemeProvider>
    </div>
  );
}

export default Index;
