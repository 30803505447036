import { call, put, takeLatest } from 'redux-saga/effects';
import { getListCampaign } from '../../Api/database';
import { loadListCampaignFail, loadListCampaignSuccess } from '../Actions/listCampaign';
import ListCampaign from '../Types/listCampaign';

function* handleLoadListCampaign(action) {
  try {
    const response = yield call(getListCampaign, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListCampaignSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListCampaignFail(error));
  }
}

export default function* listCampaignSaga() {
  yield takeLatest(ListCampaign.LOAD_LIST_CAMPAIGN, handleLoadListCampaign);
}
