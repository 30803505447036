import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  InputLabel,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import { deleteImageStorage } from '../../Api/imageApi';
import Util from '../../Util/Util';
import configs from '../../Config/config';
import { uploadImage } from '../../Api/uploadApi';
import { createInformation, updateInformation } from '../../Api/informationAPI';
import { toast } from 'react-toastify';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import RadioGroup from '../../Components/RadioGroup';
import pick from 'lodash/pick';
import RemoveInformationModal from './RemoveInformationModal';
import { fileDataType, hiddenStatusRequired } from '../../Util/utils';
import { APP_ALLOW_UPLOAD_TYPE, DIR_FOLDER_IMAGE } from '../../Common/constant';
import FileUploadModal from '../../Components/FileUploadModal';
import { TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL } from '../../Common/modal_text_info';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { DeleteImageButton } from '../../Components/IconImageAction';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

export default function InformationAddEditPopup({ onClose, visible, onUpdate, dataInfo }) {
  const [submitting, setSubmitting] = useState(false);
  const [actionAddOrEdit, setActionAddOrEdit] = useState(false); // F is Add, T is Edit
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [urlImageCacheChangeEdit, setUrlImageCacheChangeEdit] = useState('');
  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);

  const [popup, setPopup] = useState({
    registDate: new Date(moment().startOf('day')).getTime(),
    title: '',
    content: '',
    urlImage: '',
    displaySetting: 0,
  });

  useEffect(() => {
    if (dataInfo) {
      setActionAddOrEdit(true); // Flag is Action edit
      const pickData = pick(dataInfo, ['content', 'title', 'displaySetting', 'urlImage']);
      setPopup({ ...popup, ...pickData, registDate: +dataInfo.registDate });
    }
  }, [dataInfo]);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic(fileData.file); // Trigger call data
    }
  }, [fileData]);

  const onEmitClose = () => {
    // It's ADD and not submit, then remove image it's exist
    if (!actionAddOrEdit && popup.urlImage) {
      onDeleteImage(popup.urlImage);
    }
    // It's EDIT and not submit and data urlImage diff, then remove image it's old
    if (actionAddOrEdit && popup.urlImage && popup.urlImage !== dataInfo.urlImage) {
      onDeleteImage(popup.urlImage);
    }

    onClose();
  };

  const onDeleteImage = (urlImage) => {
    if (urlImage) {
      deleteImageStorage(Util.getFilePath(urlImage)).then();
    }
  };

  const handleRemoveInformation = () => {
    setConfirmVisible(true);
  };

  const handleRemoveClose = () => {
    setConfirmVisible(false);
  };

  const handleSubmit = async (action) => {
    let isError = false;

    if (!popup.registDate) {
      toast.error('タイマー日時を入力してください。');
      isError = true;
      if (action === 'EDIT') {
        return false;
      }
    }
    if (!popup.title) {
      toast.error('本文を入力してください。');
      isError = true;
      if (action === 'EDIT') {
        return false;
      }
    }
    if (!popup.content) {
      toast.error('タイトルを入力してください。');
      isError = true;
      if (action === 'EDIT') {
        return false;
      }
    }

    if (isError) {
      return;
    }

    if (action === 'ADD') {
      actionAddInfo();
    } else if (action === 'EDIT') {
      actionEditInfo();
    }
  };

  const actionAddInfo = () => {
    // Trigger create information setting
    createInformation(popup)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          onUpdate();
          setSubmitting(true);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const actionEditInfo = () => {
    // Trigger edit information setting
    updateInformation({ ...dataInfo, ...popup })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          // Check has change image, so remove image old
          if (actionAddOrEdit && popup.urlImage && popup.urlImage !== dataInfo.urlImage) {
            onDeleteImage(dataInfo.urlImage);
          }
          onClose();
          onUpdate();
          setSubmitting(true);
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDate = (date) => {
    popup.registDate = new Date(moment(date).startOf('day')).getTime();

    setPopup({ ...popup });
  };

  const onChange = (e) => {
    popup[e.target.name] = e.target.value;
    setPopup({ ...popup, [e.target.name]: e.target.value });
  };

  const uploadStartLogic = async (file, task) => {
    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      if (task) {
        task.cancel();
      }
      const filename = new Date().getTime() + '.jpg';
      const formData = new FormData();
      formData.append('imageFile', new File([file], filename));
      const dir = `${DIR_FOLDER_IMAGE.INFO_ADMIN}`;
      const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
      const { data: uploaded } = await uploadImage(formData, { dir: btoa(dir) });

      if (uploaded.data) {
        if (!actionAddOrEdit) {
          // Action ADD then change image
          onDeleteImage(popup.urlImage);
        }

        // If's Edit, so check template cache url change image
        if (actionAddOrEdit) {
          onDeleteImage(urlImageCacheChangeEdit);
          setUrlImageCacheChangeEdit(imagePath);
        }

        popup.urlImage = imagePath;
        setPopup({ ...popup });
      }
    }
  };

  const handleDeleteImage = (image) => {
    // Create
    if (!actionAddOrEdit) {
      onDeleteImage(image);
      popup.urlImage = null;
      setPopup({ ...popup });
      return;
    }
    // Edit
    if (actionAddOrEdit) {
      onDeleteImage(popup.urlImage);
      popup.urlImage = null;
      setPopup({ ...popup });
    }
  };

  const handleUploading = (snapshot) => {
    // Todo
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.info('Upload is ' + progress + '% done');
  };

  const handleUploadError = (payload) => {
    console.error('Firebase.storage::FAIL ', payload);
  };

  const handleUploadSuccess = (url) => {
    if (String(url)) {
      popup.urlImage = url;
      setPopup({ ...popup });
    }
  };

  return (
    <div>
      {confirmVisible && (
        <RemoveInformationModal
          visible={confirmVisible}
          onClose={handleRemoveClose}
          informationId={dataInfo?.id}
          reload={onUpdate}
          onCloseDetail={onClose}
          information={dataInfo}
          urlImageCacheEdit={urlImageCacheChangeEdit}
        />
      )}

      <>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={!!visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <MuiThemeProvider theme={theme}>
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              <p className={'d-title mb-0'}>お知らせ-新規編集</p>
              <div>
                <Button
                  onClick={onEmitClose}
                  color="primary"
                  disabled={submitting}
                  className="btn-custom btn-red"
                >
                  閉じる
                </Button>
                <Button
                  onClick={() => handleSubmit((!actionAddOrEdit ? 'ADD' : 'EDIT'))}
                  color="primary"
                  disabled={submitting}
                  className="btn-custom btn-blue mr-3"
                >
                  {submitting && <CircularProgress />}
                  登録
                </Button>
              </div>
            </DialogTitle>

            <DialogContent>

              <Row>
                <Col xs={12}>
                  <Card body style={{ marginBottom: '1rem' }}>
                    <FormGroup row>
                      <Col xs={6}>
                        <InputLabel shrink>
                          <span className="text-red">*</span>投稿日
                        </InputLabel>
                        <DatePicker
                          selected={!isNaN(popup?.registDate) ? new Date(popup?.registDate) : null}
                          className="date"
                          onChange={(e) => handleDate(e)}
                          locale="ja"
                          dateFormat="yyyy/MM/dd"
                        />
                      </Col>
                      <Col xs={6}>
                        <InputLabel shrink>表示設定</InputLabel>
                        <RadioGroup
                          name="displaySetting"
                          defaultValue={popup.displaySetting}
                          options={hiddenStatusRequired()}
                          onChange={onChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col xs={12}>
                        <InputLabel shrink>
                          <span className="text-red">*</span>タイトル
                        </InputLabel>
                        <TextField
                          type="text"
                          defaultValue={popup.title}
                          name="title"
                          onChange={onChange}
                          className="w-100"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col xs={12}>
                        <InputLabel shrink><span className="text-red">*</span>本文</InputLabel>
                        <TextareaAutosize
                          rows={4}
                          defaultValue={popup.content}
                          name="content"
                          onChange={onChange}
                          className="w-100"
                        />
                      </Col>
                    </FormGroup>

                    {!popup?.urlImage && (<FormGroup row className="pt-2 mb-0">
                      <Col xs={12}>
                        <FormLabel component="legend">
                          画像を選択
                        </FormLabel>
                      </Col>
                      <Col xs={12}>
                        {!popup.url && (
                          <label>
                            <AddBoxIcon onClick={() => setShowDialogDragDrop(true)}
                                        color="action"
                                        style={{ width: 50, height: 50, objectFit: 'cover', margin: 'auto' }}
                            />
                          </label>
                        )}
                      </Col>
                    </FormGroup>)}
                  </Card>
                </Col>
              </Row>
              {popup.urlImage && (
                <Card key={popup.urlImage} body className={`my-3 align-items-center`}>
                  <label
                    style={{
                      position: 'relative',
                      height: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      width: 220,
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={popup.urlImage}
                      alt="popup"
                    />
                    {popup.urlImage && (
                      <DeleteImageButton onClick={() => handleDeleteImage(popup.urlImage)} />
                    )}
                  </label>
                </Card>
              )}

              {actionAddOrEdit && (<Button
                variant="contained"
                className="btn-custom btn-red float-right"
                onClick={handleRemoveInformation}
                style={{ marginBottom: 2 }}
              >
                削除
              </Button>)}
            </DialogContent>
          </MuiThemeProvider>
        </Dialog>

        {showDialogDragDrop && (
          <FileUploadModal showDialogDragDrop={showDialogDragDrop}
                           setShowDialogDragDrop={setShowDialogDragDrop}
                           setFileData={setFileData}
                           fileData={fileData}
                           dataInfoHeadText={TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL}
          />
        )}
      </>
    </div>);
}
