import { MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Row } from 'reactstrap';
import { updateUser } from '../../../Api/userAPI';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { TEXT_HTML_UPDATE_MEMBER_PROFILE_MODAL } from '../../../Common/modal_text_info';
import { loadUser } from '../../../Redux/Actions/user';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { themeStyleNormal1Modal } from '../theme_modal';

function UpdateProfilePageModal(props) {
  const { visible, onClose, userId, nickName, avatar, htmlProfilePageMember } =
    props;
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [html, setHtml] = useState(null);
  const dispatch = useDispatch();
  console.log({ html, htmlProfilePageMember });
  useEffect(() => {
    setHtml(htmlProfilePageMember);
    return () => {
      setHtml(null);
      console.log('return');
    };
  }, [htmlProfilePageMember]);

  const handleSubmit = async () => {
    try {
      updateUser({ profilePageMember: html, id: userId }).then((response) => {
        const { data } = response;
        if (data.success) {
          dispatch(loadUser(userId));
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
          setConFirmVisible(false);
        }
      });
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  const onChangeProfilePageMember = (event) => {
    const text = event?.target?.value;
    setHtml(text);
  };

  const handleConfirm = async () => {
    setConFirmVisible(true);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <>
        {confirmVisible && (
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={confirmVisible}
            onClose={onCloseConfirm}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle
              id="max-width-dialog-title"
              className={'d-title-modal-custom'}
            />
            <Button
              style={{
                position: 'absolute',
                top: 8,
                right: 0,
              }}
              onClick={onCloseConfirm}
            >
              <CloseIcon color="primary" />
            </Button>

            <DialogContent>
              <div style={{ margin: '0.6rem 0 1rem 0' }}>
                <Card body>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Col
                      xs={9}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{ maxWidth: '544px' }}
                        dangerouslySetInnerHTML={{
                          __html: html,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div>
                <center style={{ margin: 15 }}>
                  <Button
                    className="btn-custom btn-red"
                    onClick={onCloseConfirm}
                    color="primary"
                  >
                    いいえ
                  </Button>
                  <Button
                    className="btn-custom btn-blue"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    はい
                  </Button>
                </center>
              </div>
            </DialogContent>
          </Dialog>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginRight: 50,
              }}
            >
              <p className={'mb-0'}>ユーザー詳細-プロフィールフリーページ</p>
              <div style={{ display: 'flex' }}>
                <p
                  className={'mb-0'}
                  style={{ fontSize: '1rem', marginRight: 20 }}
                >
                  プロフ表示編集
                </p>
                <img
                  className="object-fit-cover"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    marginRight: 20,
                  }}
                  src={avatar ? avatar.url : NoPhoto}
                  alt=""
                />
                <p className={'mb-0'} style={{ fontSize: '1rem' }}>
                  {nickName}
                </p>
              </div>
            </div>
          </DialogTitle>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div
              className={'text mb-0'}
              dangerouslySetInnerHTML={{
                __html: TEXT_HTML_UPDATE_MEMBER_PROFILE_MODAL,
              }}
            ></div>
          </div>

          <DialogContent>
            <MuiThemeProvider theme={themeStyleNormal1Modal}>
              <Card body className="mb-4">
                <Row>
                  <Col xs={6}>
                    <textarea
                      name="profilePageMember"
                      rows="3"
                      value={html}
                      onChange={onChangeProfilePageMember}
                      style={{ width: '100%', minHeight: '544px' }}
                    />
                  </Col>
                  <Col
                    xs={6}
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      flexGrow: 1,
                    }}
                  >
                    <div
                      style={{
                        maxWidth: '544px',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: html,
                      }}
                    ></div>
                  </Col>
                </Row>
              </Card>
              <div style={{ textAlign: 'right', marginBottom: 2 }}>
                <Button
                  onClick={handleConfirm}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  更新する
                </Button>
              </div>
            </MuiThemeProvider>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default UpdateProfilePageModal;
