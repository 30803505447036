import { createAction } from 'redux-actions';
import ListCampaign from '../Types/listCampaign';

export const loadListCampaign = createAction(ListCampaign.LOAD_LIST_CAMPAIGN);
export const loadListCampaignSuccess = createAction(
  ListCampaign.LOAD_LIST_CAMPAIGN_SUCCESS,
);
export const loadListCampaignFail = createAction(
  ListCampaign.LOAD_LIST_CAMPAIGN_FAIL,
);
