import { handleActions } from 'redux-actions';
import TimerChatRoom from '../Types/timerChatRoom';

const initialState = {
    items: [],
    loading: false,
    totalRow: 0,
};

const actions = {
    [TimerChatRoom.LOAD_TIMERCHATROOM]: (state) => ({
        ...state,
        loading: true,
    }),
    [TimerChatRoom.LOAD_TIMERCHATROOM_SUCCESS]: (state, action) => ({
        ...state,
        items: action.payload.items,
        totalRow: action.payload.totalRow,
        loading: false,
    }),
    [TimerChatRoom.LOAD_TIMERCHATROOM_FAIL]: (state) => ({
        ...state,
        items: [],
        loading: false,
    }),
};
export default handleActions(actions, initialState);
