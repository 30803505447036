import { createAction } from 'redux-actions';
import NameState from '../Types/state';

export const loadState = createAction(NameState.LOAD_STATE);
export const loadStateSuccess = createAction(NameState.LOAD_STATE_SUCCESS);
export const loadStateFail = createAction(NameState.LOAD_STATE_FAIL);

export const loadAllArea = createAction(NameState.LOAD_ALL_AREA);
export const loadAllAreaSuccess = createAction(NameState.LOAD_ALL_AREA_SUCCESS);
export const loadAllAreaFail = createAction(NameState.LOAD_ALL_AREA_FAIL);

export const updateNameState = createAction(NameState.UPDATE_STATE);
export const updateNameStateSuccess = createAction(NameState.UPDATE_STATE_SUCCESS);
export const updateNameStateFail = createAction(NameState.UPDATE_STATE_FAIL);
