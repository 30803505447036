import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUsers } from '../../Redux/Actions/users';
import SearchBox from './SearchBox';
import UserList from './UserList';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { useParams, useLocation, Link } from 'react-router-dom';
import { convertEnvInfo } from '../../Util/utils';
import queryString from 'query-string';
import { loadUser } from '../../Redux/Actions/user';
import { Button } from '@material-ui/core';
import './style.scss';
import { getEnvSysCms } from '../../Api/EnvSysCmsApi';

const pageSize = 50;

function OpeSearchUser() {
  const params = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const userId = parsed?.userId;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.users.loading);
  const users = useSelector((state) => state.users.items);
  const totalRow = useSelector((state) => state.users.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const operator = useSelector((state) => state.detailOperator.data);
  const operatorId = operator?.id;
  const [EnvSysCms, setEnvSysCms] = useState([]);

  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    getEnvSysCms().then(res => {
      const { data } = res;
      setEnvSysCms(data.data);
    });
  }, []);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'lastlogin',
    orderBy: 'DESC',
  });

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadUser(userId));
    return () => {
      dispatch(loadUser(0));
    };
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(loadUsers({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sortBy: 'lastlogin', orderBy: 'DESC' });
    setPageIndex(1);
    dispatch(loadUsers({ ...searchFilter, pageIndex: 1, pageSize }));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadUsers({ ...filter, pageIndex, pageSize }));
  };

  const refreshList = () => {
    dispatch(loadUsers({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      <Link
            to={`/opechara/${operatorId}/detail`}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
            className="bottom-back"
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              前の画面に戻る
            </Button>
          </Link>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />
      <UserList
        users={users}
        onUpdate={refreshList}
        filter={filter}
        onSort={onSort}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={loading}
        totalRow={totalRow}
        operatorId={userId}
        labelDb={labelDb}
        EnvSysCms={EnvSysCms}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default OpeSearchUser;
