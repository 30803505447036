import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { loadUser } from '../../../Redux/Actions/user';
import { Card, Col, Label, Row } from 'reactstrap';
import Util from '../../../Util/Util';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import ImageList from './ImageList';
import LoadingBar from '../../../Components/LoadingBar';
import ChatModel from './ChatModel';
import LikeModel from './LikeModel';
import RemoveUserModal from './RemoveUserModal';
import FavoriteModel from './FavoriteModel';
import FootprintModel from './FootPrintModel';
import SalatyLogModal from './SalaryLogModal';
import BlogLogModal from './BlogLogModal';
import BlockModal from './BlockModal';
import TwilioHistoryModal from '../UserDetail/TwilioHistoryModal';
import { Helmet } from 'react-helmet';
import { LINK_MAP_DEFINED_API, PROFILE_TYPE } from '../../../Config/listConstants';
import { DISPLAY_CMS_UI_TYPE, GENDER } from '../../../Common/constant';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  convertNeedOtpText,
  convertRegisterStatusText, convertRegisterTypeText,
  convertStandByText,
  convertInspectionStatusText, convertEnvInfo, accessAndPassDataIframe, convertCharaStatusText,
} from '../../../Util/utils';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};
const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index({ location }) {
  const params = useParams();
  const userId = params.userId;
  const [chatVisible, setChatVisible] = useState(false);
  const [likeVisible, setLikeVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [favoriteVisible, setFavoriteVisible] = useState(false);
  const [footprintVisible, setFootprintVisible] = useState(false);
  const [salaryLogVisible, setSalaryLogVisible] = useState(false);
  const [blogLogVisible, setBlogLogVisible] = useState(false);
  const [blockVisible, setBlockVisible] = useState(false);
  const rankOptions = useSelector((state) => state.nameRank.data);
  const [isOpeChara, setIsOpeChara] = useState(false);
  const [twilioHistoryVisible, setTwilioHistoryVisible] = useState(false);
  const [myProfVisible, setMyProfVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(userId));
    setIsOpeChara((localStorage.getItem('homePage') || '').includes('opechara'));
  }, [dispatch, userId]);

  const loading = useSelector((state) => state.user.loading);
  const user = useSelector((state) => state.user.data);
  const resource = useSelector((state) => state.resource.data);
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const chatLogButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_CHAT_LOG);
  const likeButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_LIKE);
  const favoriteButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_FAVORITE);
  const footprintButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_FOOTPRINT);
  const blogLogtButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_BLOG_LOG);
  const blockButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_BLOCK);
  const editButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_EDIT);
  const salaryLogtButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_SALARY_LOG);
  const twilioHistoryButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_TWILIO_LOG);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);


  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setChatVisible(false);
    setLikeVisible(false);
    setConfirmVisible(false);
    setFavoriteVisible(false);
    setFootprintVisible(false);
    setSalaryLogVisible(false);
    setBlogLogVisible(false);
    setBlockVisible(false);
    setTwilioHistoryVisible(false);
  };
  const handleClickChat = () => {
    setChatVisible(true);
  };
  const handleClickLike = () => {
    setLikeVisible(true);
  };

  const handleClickFavorite = () => {
    setFavoriteVisible(true);
  };
  const handleClickFootprint = () => {
    setFootprintVisible(true);
  };

  const handleClickBlogLog = () => {
    setBlogLogVisible(true);
  };

  const handleClickBlock = () => {
    setBlockVisible(true);
  };

  const handleClickSalaryLog = () => {
    setSalaryLogVisible(true);
  };

  const handleClickTwilioHistory = () => {
    setTwilioHistoryVisible(true);
  };

  const history = useHistory();
  const redirectPageEditUser = (id) => {
    history.push(`/${isOpeChara ? 'character' : 'user'}/${id}/editshort`);
  };
  const showResource = () => {
    let profileItem = [];
    let userGender = Number(user.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : user.gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    resource.items
      .filter(item => item.value === PROFILE_TYPE.ROOT && item.gender === userGender)
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        profileItem.push({
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        });
        return resourceItem;
      });
    return profileItem.map((item, key) => (
      <>
        <Label style={style} xs={5}>
          <p style={{ paddingLeft: 3 }}>{item.label}</p>
          <hr style={stylehrleft} />
        </Label>
        <Label style={style} xs={7}>
          <p style={{ paddingLeft: 3 }}>{item?.value}</p>
          <hr style={stylehrright} />
        </Label>
      </>
    ));
  };

  const findAttributes = (resourceName) => {
    let userGender = Number(user.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : user.gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    let parrentName = resource.items.find(x => x.gender === userGender && x.label === resourceName);
    let responseResources = resource?.items.find(x =>
      x.gender === userGender
      && x.profile === parrentName?.profile
      && user?.attributes && user?.attributes[parrentName.profile]
      && x.value === user?.attributes[parrentName.profile],
    );
    return responseResources?.label;
  };

  const accessAndPassData = () =>  {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  }

  return (
    <>
      <Helmet>
        <title>ユーザー詳細</title>
      </Helmet>
      <div>
        <div>
          {chatVisible && (
            <ChatModel
              visible={chatVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {likeVisible && (
            <LikeModel
              visible={likeVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {confirmVisible && (
            <RemoveUserModal
              visible={confirmVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {favoriteVisible && (
            <FavoriteModel
              visible={favoriteVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {footprintVisible && (
            <FootprintModel
              visible={footprintVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {salaryLogVisible && (
            <SalatyLogModal
              visible={salaryLogVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {blogLogVisible && (
            <BlogLogModal
              visible={blogLogVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {blockVisible && (
            <BlockModal
              visible={blockVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}

          {twilioHistoryVisible && (
            <TwilioHistoryModal
              visible={twilioHistoryVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}

          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={myProfVisible}
            onClose={() => setMyProfVisible(false)}
            aria-labelledby="max-width-dialog-title"
            PaperProps={{
              style: {
                top: 0,
                maxHeight: '100%',
                maxWidth: 600,
              },
            }}
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              MyProfプレビュー
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={() => setMyProfVisible(false)}
            >
              <CloseIcon color="primary" />
            </Button>
            <DialogContent style={{ marginBottom: 30, height: '80vh', overflow: 'hidden' }}>
              {user &&
              <iframe title="s"
                      id="iframeId"
                      onLoad={accessAndPassData}
                      style={{ border: 'none', width: '100%', height: '100%' }}
                      src={user && Util.myProfSrc(user)}></iframe>
              }
            </DialogContent>
          </Dialog>
        </div>

        <LoadingBar show={loading} />
        {user && (
          <MuiThemeProvider theme={customTheme}>
            <div
              id="max-width-dialog-title"
              className='d-box-content-action-user-short'
              style={{ marginTop: 10, marginBottom: 10, display: 'flow-root' }}
            >
              {(admin?.memberType === 0 ||
                chatLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 ml-3"
                  onClick={
                    admin?.memberType === 0 ||
                    chatLogButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickChat
                      : ''
                  }
                >
                  {chatLogButton?.content}
                </Button>
              )}
              {(admin?.memberType === 0 ||
                likeButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    likeButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickLike
                      : ''
                  }
                >
                  {likeButton?.content}
                </Button>
              )}
              {(admin?.memberType === 0 ||
                favoriteButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    favoriteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickFavorite
                      : ''
                  }
                >
                  {favoriteButton.content}
                </Button>
              )}

              {(admin?.memberType === 0 ||
                footprintButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    footprintButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickFootprint
                      : ''
                  }
                >
                  {footprintButton.content}
                </Button>
              )}
              {(admin?.memberType === 0 ||
                blogLogtButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    blogLogtButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickBlogLog
                      : ''
                  }
                >
                  {blogLogtButton.content}
                </Button>
              )}
              {(admin?.memberType === 0 ||
                blockButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    blockButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickBlock
                      : ''
                  }
                >
                  {blockButton.content}
                </Button>
              )}

              {(admin?.memberType === 0 ||
                salaryLogtButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    salaryLogtButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickSalaryLog
                      : ''
                  }
                >
                  {salaryLogtButton.content}
                </Button>
              )}

              {(admin?.memberType === 0 ||
                twilioHistoryButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  onClick={
                    admin?.memberType === 0 ||
                    twilioHistoryButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleClickTwilioHistory
                      : ''
                  }
                >
                  {twilioHistoryButton.content}
                </Button>
              )}

              <Button onClick={() => setMyProfVisible(true)} variant="contained"
                      className="btn-custom btn-blue mr-3"
                      style={{ float: 'right' }}>
                MyProfプレビュー
              </Button>

              {(admin?.memberType === 0 ||
                editButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3"
                  style={{ float: 'right' }}
                  onClick={
                    admin?.memberType === 0 ||
                    editButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? () => redirectPageEditUser(user.id)
                      : ''
                  }
                >
                  {editButton.content}
                </Button>
              )}

            </div>
            <Row style={{ 'margin-bottom': 50 }}>
              <Col xs={4}>
                <Card body className="mb-4">
                  <ImageList user={user} />
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label style={style} xs={12}>&nbsp;</Label>
                    <Label style={style} xs={12}>&nbsp;</Label>
                    <Label style={style} xs={12}>&nbsp;</Label>
                    <Label style={style} xs={12}>&nbsp;</Label>
                    <Label style={style} xs={12}>&nbsp;</Label>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>{resource && showResource()}</Row>
                </Card>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Col xs={6}>
                          <Row>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>{labelDb?.label_nickName?.content}</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.nickName}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>
                                電話番号（重複NG）
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.phoneNumber}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>
                                パスワード（数字6桁
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.password}&nbsp;&nbsp;&nbsp;
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>
                                ログインSMS必要
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertNeedOtpText(+user?.needOtp)}
                              <hr style={stylehrright} />
                            </Label>
                          </Row>
                        </Col>
                        <Col xs={6}>
                          <Row>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>性別</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.genderIdToText(user.gender)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>ーキャラクターステイタス</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertCharaStatusText(+user.charaStatus) }
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>年齢</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.calcAge(
                                Util.timestampToDate(
                                  user.birthday,
                                  'YYYY-MM-DD',
                                ),
                              )}
                              歳{' '}
                              {Util.timestampToDate(
                                user.birthday,
                                'YYYY/MM/DD',
                              )}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>会員種類</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.memberTypeToText(user.memberType)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>
                                新規登録時環境（1/2/9）
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertRegisterTypeText(+user?.registerType)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>
                                電話認証状態（0：未認証/1：認証済）
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertRegisterStatusText(+user.registerStatus)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>ランク</p>
                              <hr style={stylehrleft} />
                            </Label>
                            {rankOptions && (
                              <Label style={style} xs={7}>
                                {Util.rankValueToText(parseInt(user.rank), rankOptions)}
                                <hr style={stylehrright} />
                              </Label>
                            )}
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3 }}>待機状態</p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertStandByText(+user?.standBy)}
                              <hr style={stylehrright} />
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>都道府県</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.states?.label}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>エリア</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user?.area_label}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>登録日</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.timestampToDate(
                            user.registerDate,
                            'YYYY/MM/DD HH:mm',
                          )}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>最終ログイン</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.timestampToDate(
                            user.lastlogin,
                            'YYYY/MM/DD HH:mm',
                          )}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>月額会員期限</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.planExpireDate &&
                          Util.timestampToDate(
                            user.planExpireDate,
                            'YYYY-MM-DD',
                          )}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>表示ステイタス（非表示ログインNG）</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.memberStateToText(user.memberStatus)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>年齢認証</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {convertInspectionStatusText(user.ageverification)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>通知設定</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          push-chat:{user?.notification ? user?.notification?.pushChat: '0'}; push-like: {user?.notification ? user?.notification?.pushLike : '0'}; sms-info: {user?.notification ? user?.notification?.smsInfo : '0'}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>×ログイン制御</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.loginControlToText(Number(user?.logincontrol))}
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={12}>&nbsp;</Label>
                        <Label xs={12}>&nbsp;</Label>
                      </Row>
                    </Card>
                  </Col>

                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>ポイント</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.depositpoint)}P
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>給与ポイント</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.salarypoint)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>ログイン回数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.countLogin)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>入金回数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPaying}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>入金総額</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.totalPaying)}
                          円
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>チャット受信数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countTextReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>チャット送信数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countTextSending}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>画像受信数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPhotoReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>画像送信数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPhotoSending}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>コール受信回数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countCallReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3 }}>コール発信回数</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countCallSending}
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3 }}>自己PR</p>
                        </Label>
                        <Label
                          style={{
                            ...style,
                            height: 'auto',
                            whiteSpace: 'pre-line',
                          }}
                          xs={10}
                        >
                          {user.aboutMe}
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3 }}>管理者メモ</p>
                        </Label>
                        <Label
                          style={
                            (style, { height: 'auto', whiteSpace: 'pre-line' })
                          }
                          xs={10}
                        >
                          {user.adminMemo}
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3 }}>新規登録時情報</p>
                        </Label>
                        {user.registerInfo &&
                        IsJsonString(user.registerInfo) ? (
                          <Label style={(style, { height: 'auto' })} xs={10}>
                            <p style={{ paddingLeft: 3 }}>
                              IP : {JSON.parse(user.registerInfo).ip}
                            </p>
                            <p style={{ paddingLeft: 3 }}>
                              Browser: {JSON.parse(user.registerInfo).browser}
                            </p>
                          </Label>
                        ) : (
                          <Label style={(style, { height: 'auto' })} xs={10}>
                            <p style={{ paddingLeft: 3 }}>
                              {user.registerInfo}
                            </p>
                          </Label>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MuiThemeProvider>
        )}
      </div>
    </>
  );
}

export default Index;
