import { createAction } from 'redux-actions';
import JobMember from '../Types/jobMember';

export const loadJobMember = createAction(JobMember.LOAD_JOB_MEMBER);
export const loadJobMemberSuccess = createAction(JobMember.LOAD_JOB_MEMBER_SUCCESS);
export const loadJobMemberFail = createAction(JobMember.LOAD_JOB_MEMBER_FAIL);

export const updateJobMemberMember = createAction(JobMember.UPDATE_JOB_MEMBER);
export const updateJobMemberSuccess = createAction(JobMember.UPDATE_JOB_MEMBER_SUCCESS);
export const updateJobMemberFail = createAction(JobMember.UPDATE_JOB_MEMBER_FAIL);
