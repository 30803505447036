import React from 'react';
import { ITEM_JOB_TYPE } from '../../Config/listConstants';
import { Label, Row } from 'reactstrap';
import { FormLabel, MenuItem, Select } from '@material-ui/core';


export const findAttributes = (resource, resourceName) => {
  let parentName = resource.find((x) => x.label === resourceName);
  let responseResources = resource.find(x => x.item === parentName?.item);

  return responseResources?.label;
};

export const findResources = (resource, resourceName, resourceItem = '', editingItemJob = {}) => {
  const parentName = resource.find((x) => x.label === resourceName);

  return resource.filter((x) => x.item === parentName.item && x.value !== 0);
};

export const showResourceItemJobForm = (resourceItems, loadingResource, onChangeAttribute, colorLabel='', editingItemJob = {}) => {
  return resourceItems
    .filter((item) => item.value === ITEM_JOB_TYPE.ROOT)
    .sort((a, b) => a.profileOrder - b.profileOrder)
    .map(resourceItem => {
      return {
        resource: findResources(resourceItems, resourceItem.label, resourceItem.item, editingItemJob),
        label: resourceItem.label,
        name: resourceItem?.item,
        value: findAttributes(resourceItems, resourceItem.label),
      };
    })
    .map((item, key) => (
      <Row key={key}>
        <Label xs={12}>
          <FormLabel style={{ color: colorLabel }} component="legend">{item.label}</FormLabel>
          {!loadingResource ? (
            <Select
              name={item.name}
              style={{ width: '100%' }}
              onChange={onChangeAttribute}
            >
              <MenuItem value="" />
              {item.resource
                .sort((a, b) => a.value - b.value)
                .map((option, index) => (
                  <MenuItem key={index}
                            value={option}
                            data-label-root={item.label}
                  >
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          ) : <div></div>}
        </Label>
      </Row>
    ));
};

// Show item job
export const showResourceItemJob = (resourceItems, attribute = {}, colorLabel) => {
  return resourceItems
    .filter((item) => item.value === ITEM_JOB_TYPE.ROOT)
    .sort((a, b) => a.profileOrder - b.profileOrder)
    .map((resourceItem) => {
      return {
        label: resourceItem.label,
        name: resourceItem.item,
        value: findAttributeByFilter(resourceItems, resourceItem.label, attribute),
      };
    })
    .map((item, key) => (
      <>
        <Label xs={4} style={{ paddingBottom: 5, color: colorLabel }}>{item?.label}</Label>
        <Label xs={8} style={{ paddingBottom: 5 }}>{item?.value}</Label>
        <hr style={{ width: '95%', margin: '0 auto' }} />
      </>
    ));
};

const findAttributeByFilter = (resourceItems, resourceName, attribute = {}) => {
  const parentName = resourceItems.find((x) => x.label === resourceName);

  if (attribute && Object.keys(attribute).length) {
    let responseResources = resourceItems.find(x => {
      return attribute
        && attribute[parentName.item]
        && x.item === parentName?.item
        && +x.value === +attribute[parentName.item];
    });

    return responseResources?.label;
  }

  return null;
};


// Item job edit
export const showResourceItemJobEdit = (resourceItems, loadingResource, onChangeAttribute, attribute, editingItemJob, colorLabel='') => {
  return resourceItems
    .filter((item) => item.value === ITEM_JOB_TYPE.ROOT)
    .sort((a, b) => a.profileOrder - b.profileOrder)
    .map((resourceItem) => {
      return {
        resource: findResources(resourceItems, resourceItem.label),
        label: resourceItem.label,
        name: resourceItem.item,
        value: findAttributes(resourceItems, resourceItem.label),
      };
    }).map((item, key) => (
      <Row key={`${key}-${item.name}`}>
        <Label xs={12}>
          <FormLabel style={{ color: colorLabel }} component="legend">{item.label}</FormLabel>
          <div className="d-line-select-border">
            {!loadingResource ? (
              <Select name={item.name}
                      key={item?.name}
                      onChange={onChangeAttribute}
                      className={'w-100 d-select-multiple-cus'}
                      defaultValue={attribute && attribute[item?.name]}
                      value={editingItemJob && editingItemJob[item?.name]}
              >
                <MenuItem value=""></MenuItem>
                {item.resource
                  .sort((a, b) => a.value - b.value)
                  .map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option?.value}
                      selected={editingItemJob && option?.value === editingItemJob[option.profile]}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            ) : <div></div>}
          </div>
        </Label>
      </Row>
    ));
};
