import { call, put, takeLatest } from 'redux-saga/effects';
import Operators from '../Types/operators';
import { getOperators } from '../../Api/operatorAPI';
import { loadOperatorsFail, loadOperatorsSuccess } from '../Actions/operators';

function* handleLoadOperators(action) {
  try {
    const response = yield call(getOperators, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadOperatorsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadOperatorsFail(error));
  }
}

export default function* operatorsSaga() {
  yield takeLatest(Operators.LOAD_OPERATORS, handleLoadOperators);
}
