import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Button, IconButton, ListItemAvatar, TextareaAutosize } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileUploader from 'react-firebase-file-uploader';
import moment from 'moment';
import { getChatRoomById, readAllMessageUnRead, sendMessage } from '../../../../Api/chatAdminApi';
import _ from 'lodash';
import '../styles.scss';
import Util from '../../../../Util/Util';
import socket from '../../../../socket';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DatePicker from 'react-datepicker';
import RadioGroup from '../../../../Components/RadioGroup';
import { createChatRoomTimer } from '../../../../Api/timerAPI';
import configs from '../../../../Config/config';
import CropperDialog from '../../../../Components/CropperDialog';
import { displayOptionsOptionsRequired } from '../../../../Util/utils';
import {
  APP_ALLOW_UPLOAD_TYPE, CHAT_CATEGORY_TYPE,
  CHAT_MESSAGE_TYPE,
  DIR_FOLDER_IMAGE,
  DISPLAY_OPTIONS,
  HIDDEN_TYPE,
  INSPECTION_TYPE,
  READ_TYPE,
  REPLIED_STATUS,
} from '../../../../Common/constant';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import NoPhotoAgent from '../../../../assets/image/no_agent_photo.png';
import NoAvatar from '../../../../assets/image/sys_photo.png';


function AppChat({ conversationId, values, viewImage, listMessages, setListMessages }) {
  const [text, setText] = useState('');
  const [messageSocket, setMessageSocket] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [croppingImage, setCroppingImage] = useState(false);
  const inputEl = useRef(null);

  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'));

  const [timeSend, setTimeSend] = useState();
  const [timerSms, setTimerSms] = useState(`${DISPLAY_OPTIONS.OFF}`);
  const [selectedDate, setSelectedDate] = useState(new Date(moment().startOf('day')).getTime());
  const [dir, setDir] = useState('');
  const [fileUploader, setFileUploader] = useState();

  useEffect(() => {
    const readMessages = async () => {
      await readAllMessageUnRead({
        chatRoomId: conversationId,
      });
    };

    readMessages();
    getListMessagesById();

    return () => {
      setListMessages({});
    };
  }, [conversationId, values]);

  const getListMessagesById = async () => {
    try {
      const result = await getChatRoomById(conversationId);
      if (result.status === 200) {
        // Object
        let messagesBaseDate = _.groupBy(result.data.data, function(message) {
          return moment(Number(message.createdDate)).format('YYYY-MM-DD');
        });

        setListMessages(messagesBaseDate);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const resetTimerState = useCallback(() => {
    setHourSend(moment().format('HH').toString());
    setMinusSend(moment().format('mm').toString());
    setDateSend(
      moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'),
    );
    setTimerSms(`${DISPLAY_OPTIONS.OFF}`);
  }, []);

  useEffect(() => {
    resetTimerState();
  }, [resetTimerState, conversationId]);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      const list = document?.getElementById('list-messages');
      list?.scrollTo(0, list.scrollHeight);
    }
  };

  useEffect(() => {
    if (listMessages) {
      setTimeout(() => scrollToRef(inputEl), 650);
    }
  }, [listMessages]);

  const loadToBottom = () => {
    scrollToRef(inputEl);
  };

  useEffect(() => {
    socket.on('receiveMessageAdmin', (data) => {
      const currentDate = moment(new Date()).format('YYYY-MM-DD');

      const dataListMessages = listMessages[currentDate] && listMessages[currentDate].map(v => {
        if (data.senderId === v.receiverId) {
          v.replied = REPLIED_STATUS.REPLIED; // 1
          v.read = READ_TYPE.READ; // 1
        }
        return v;
      });

      const dataMessage = {
        ...data,
        createdDate: String(data.createdDate),
        inspection: INSPECTION_TYPE.INSPECTION, // 1,
        hidden: HIDDEN_TYPE.NO_HIDDEN, // 0
        replied: REPLIED_STATUS.NOT_REPLIED, // 0
      };

      setListMessages({
        ...listMessages,
        [currentDate]: listMessages[currentDate] ? [...dataListMessages, dataMessage] : [dataMessage],
      });
    });
  }, [messageSocket, listMessages]);

  const onClickButton = (conversationId, image = null) => {
    if (!image && !text) {
      return;
    }

    let data = {
      senderId: values.receiver.id,
      receiverId: values.sender.id,
      conversationId: conversationId,
      content: image ? image : text,
      type: image ? CHAT_MESSAGE_TYPE.IMAGE : CHAT_MESSAGE_TYPE.TEXT,
      status: 1,
      isAdminReply: true,
    };

    if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.OFF) {
      sendMessage(data).then((response) => {
        const { data } = response;
        if (data.success) {
          setText('');
        } else {
          toast.error(data.message);
        }
      });
      socket.emit('sendMessageAdmin', data);
    } else {
      const timer = validateTimer();

      if (timer.valid) {
        data.sendTime = timeSend;
        data.chatCategory = CHAT_CATEGORY_TYPE.CHAT_ADMIN;

        createChatRoomTimer(data).then((response) => {
          const { data } = response;
          if (data.success) {
            setText('');
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
          }
        }).catch(err => {
          console.error(err);
        });
      }
    }

    setMessageSocket(new Date());
  };

  const validateTimer = () => {
    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;

    if (checkTime && checkTime < new Date().getTime() && String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON) {
      toast.error('予約時間が過去に設定されています');
      return { valid: false, timeStr: moment(checkTime).format('YYYYMMDD') };
    }

    return { valid: true, timeStr: moment(checkTime).format('YYYYMMDD') };
  };

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const uploadStartLogic = async (file) => {
    const timer = validateTimer();
    if (!timer.valid) {
      return;
    }

    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          setCroppingImage(reader.result);
          setShowCropper(true);
          setDir(`${DIR_FOLDER_IMAGE.CHAT_ADMIN}/${values?.sender?.id}/${timer.timeStr}`);
        },
        false,
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const customOnChangeHandler = (event) => {
    const timer = validateTimer();
    if (!timer.valid) {
      return;
    }

    const { target: { files } } = event;

    for (let file of files) {
      if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
        uploadStartLogic(file);
      }
    }
  };

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const onCropImage = (url) => {
    setShowCropper(false);
    onClickButton(conversationId, url);
  };

  const cancelCropImage = () => {
    setShowCropper(false);
    setCroppingImage(null);
  };

  const handlerTimerSms = (value) => {
    setTimerSms(`${value}`);
  };
  return (
    <>
      <div
        className={`d-content-chat chatFrame`}
        id="list-messages"
        style={{ position: 'relative', height: '63%' }}
      >
        {listMessages &&
        Object.entries(listMessages).map(([dateStr, messageDay], i) => (
          <div key={i}>
            <div className="textCenter">
              <span>{dateStr}</span>
            </div>
            {messageDay.map((messageTime) => (
              <React.Fragment key={messageTime.id}>
                {messageTime.senderId === values.sender.id ? (
                  <div className="flex d-wrapper-left">
                    <ListItemAvatar>
                      <Avatar alt="Avatar" src={values.sender.avatar?.url ?? NoPhotoAgent} />
                    </ListItemAvatar>
                    <div className="userMessageWrapper">
                      {messageTime.type === CHAT_MESSAGE_TYPE.IMAGE ? (
                        <img
                          onClick={() => viewImage(messageTime.content)}
                          className="contentImage"
                          src={messageTime.content}
                          alt=""
                        />
                      ) : (
                        <div className="messageFrame">
                          {messageTime.content}
                        </div>
                      )}
                      <span className="timeSpan">
                        {moment(Number(messageTime.createdDate)).format('HH:mm')}
                        <p style={{ marginBottom: 0 }}>
                          {Util.hiddenToText(messageTime.hidden)}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {Util.readIdToText(Number(messageTime.read))}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {Util.repliedToText(messageTime.replied)}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {Util.inspectionToText(messageTime.inspection)}
                        </p>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex d-wrapper-right">
                    <div className="myMessageWrapper">
                      <span className="timeSpan">
                        {moment(Number(messageTime.createdDate)).format('HH:mm')}
                        <p style={{ marginBottom: 0 }}>
                          {Util.hiddenToText(messageTime.hidden)}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {Util.readIdToText(messageTime.read)}
                        </p>

                        <p style={{ marginBottom: 0 }}>
                          {Util.repliedToText(messageTime.replied)}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {Util.inspectionToText(messageTime.inspection)}
                        </p>
                      </span>
                      {messageTime.type === CHAT_MESSAGE_TYPE.IMAGE ? (
                        <img
                          onClick={() => viewImage(messageTime.content)}
                          className="contentImage"
                          src={messageTime.content}
                          alt="contentImage"
                        />
                      ) : (
                        <div className="messageFrame">
                          {messageTime.content}
                        </div>
                      )}
                    </div>
                    <ListItemAvatar>
                      <Avatar
                        alt="Avatar"
                        src={values.receiver.avatar?.url ?? NoAvatar}
                      />
                    </ListItemAvatar>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
        <div
          style={{
            position: 'fixed',
            right: 62,
            bottom: 220,
            zIndex: 10000,
            background: 'rgb(0,0,0,0.1)',
            borderRadius: '50%',
          }}
        >
          <IconButton color="primary" onClick={() => loadToBottom()}>
            <KeyboardArrowDownIcon color="action" />
          </IconButton>
        </div>
        <div style={{ float: 'left', clear: 'both' }} ref={inputEl} />
      </div>

      <div className="d-content-action chatControlFrame">
        <div className="d-flex d-justify-space-between">
          <label style={{ alignSelf: 'flex-end', margin: 0, marginRight: 5, marginLeft: -5 }}>
            <FileUploader
              hidden
              accept="image/*"
              randomizeFilename
              onClick={(e) => (e.target.value = null)}
              onChange={customOnChangeHandler}
              ref={instance => setFileUploader(instance)}
            />
            <AttachFileIcon className="attach-icon" />
          </label>

          <div className="wrapperSendMessage w-100" style={{ border: '1px solid lightgray' }}>
            <TextareaAutosize
              name
              className="textArea"
              rowsMin={3}
              rowsMax={3}
              onChange={onTextChange}
              value={text}
            />
          </div>

          <label style={{ alignSelf: 'flex-end', margin: 0 }}>
            <Button
              variant="contained"
              color="primary"
              className="btnCustomer mr-0 ml-2"
              onClick={() => onClickButton(conversationId)}
            >
              送信
            </Button>
          </label>
        </div>

        <div className="timer-container">
          <div className="mt-3 mb-1">
            <span>タイマー日時</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="datePicker">
              <DatePicker
                selected={selectedDate}
                className="date"
                onChange={(e) => handleDate(e)}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                popperPlacement="top-start"
                disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
              />
              <select
                defaultChecked={hourSend}
                onChange={(e) => handleHour(e, 'start_hour')}
                className="hour"
                disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
              >
                {hour.map((value) => (
                  <option selected={value.toString() === hourSend} key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => handleMinus(e, 'start_minus')}
                className="minus"
                disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
              >
                {minute.map((value) => (
                  <option selected={value.toString() === minusSend} key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="display-setting-timer d-un-width">
              <RadioGroup
                controlled
                name="displaySetting"
                defaultValue={timerSms}
                options={displayOptionsOptionsRequired()}
                onChange={(e) => handlerTimerSms(e.target.value)}
              />
            </div>
          </div>
        </div>

        {showCropper && (
          <CropperDialog
            image={croppingImage}
            onCrop={onCropImage}
            onClose={cancelCropImage}
            dir={dir} //
            type={'chat_admin_management_app_chat'}
          />
        )}
      </div>
    </>
  );
}

export default AppChat;
