import { call, put, takeLatest } from 'redux-saga/effects';
import Jobs from '../Types/jobs';
import { getJobs } from '../../Api/jobApi';
import { loadJobsFail, loadJobsSuccess } from '../Actions/jobs';


function* handleLoadJobs(action) {
  try {
    const response = yield call(getJobs, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadJobsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadJobsFail(error));
  }
}

export default function* jobsSaga() {
  yield takeLatest(Jobs.LOAD_JOBS, handleLoadJobs);
}
