const ScheduleMail = {
  LOAD_SCHEDULE_MAIL: 'LOAD_SCHEDULE_MAIL',
  LOAD_SCHEDULE_MAIL_SUCCESS: 'LOAD_SCHEDULE_MAIL_SUCCESS',
  LOAD_SCHEDULE_MAIL_FAIL: 'LOAD_SCHEDULE_MAIL_FAIL',
  ESTIMATE_SCHEDLULE_MAIL_RECEIVERS: 'ESTIMATE_SCHEDLULE_MAIL_RECEIVERS',
  ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_SUCCESS:
    'ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_SUCCESS',
  ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_FAIL:
    'ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_FAIL',
};

export default ScheduleMail;
