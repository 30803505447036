import { call, put, takeLatest } from 'redux-saga/effects';
import FavoritesToMe from '../Types/favoriteUserToMe';
import { getFavoritesToMe } from '../../Api/favoriteLogApi';
import { loadFavoritesToMeFail, loadFavoritesToMeSuccess } from '../Actions/favoriteUserToMe';

function* handleLoadFavoriteUserToMe(action) {
  try {
    const response = yield call(getFavoritesToMe, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFavoritesToMeSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFavoritesToMeFail(error));
  }
}

export default function* favoritesToMeSaga() {
  yield takeLatest(
    FavoritesToMe.LOAD_FAVORITES_TO_ME,
    handleLoadFavoriteUserToMe,
  );
}
