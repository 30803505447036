import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import LoadingBar from '../../Components/LoadingBar';
import { loadHelpCms } from '../../Redux/Actions/helpCms';
import SortArrows from '../../Components/SortArrows';
import HelpCmsRemoveDialog from './HelpCmsRemoveDialog';
import HelpCmsFormModal from './HelpCmsForm';

const pageSize = 999;

function HelpCmsList() {
  const helpsCms = useSelector((state) => state.helpCms.data);
  const loading = useSelector((state) => state.helpCms.loading);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'help_cms.display_order',
    orderBy: 'DESC',
    sort: { 'help_cms.display_order': 'DESC' },
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleRemove, setModalVisibleRemove] = useState(false);

  const [model, setModel] = useState({});
  const [id, setId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadHelpCms({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };
  const handleEdit = (m) => {
    setModel(m);
    setModalVisible(true);
  };
  const handleDelete = (m) => {
    setId(m.id);
    setModalVisibleRemove(true);
  };
  const handleCreate = () => {
    setModel({});
    setModalVisible(true);
  };

  const onSuccess = () => {
    dispatch(loadHelpCms({ ...filter, pageIndex, pageSize }));
  };

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={tableNewTheme}>
        <div className="align align-fixed-top">
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleCreate}
          >
            新規登録
          </Button>
        </div>
        <Card className="scroll-x-on-top main-content-custom">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明テキスト</TableCell>
                <TableCell style={{ width: '40%' }}>内容</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="Link"
                    name="help_cms.link"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示順(管理）</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {(helpsCms || []).map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '40%',
                      minWidth: 200,
                    }}
                  >
                    {item.content}
                  </TableCell>

                  <TableCell>{item.link}</TableCell>
                  <TableCell>{item.display_order}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 500,
                    }}
                  >
                    <Button
                      className="btn-cus-font btn-custom btn-blue btn-cus-r5"
                      variant="contained"
                      onClick={() => handleEdit(item)}
                    >
                      編集
                    </Button>
                    <Button
                      className="btn-cus-font btn-custom btn-red"
                      variant="contained"
                      onClick={() => handleDelete(item)}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      {modalVisible && (
        <HelpCmsFormModal
          helpCms={model}
          onClose={() => setModalVisible(false)}
          onSuccess={onSuccess}
        />
      )}
      {modalVisibleRemove && (
        <HelpCmsRemoveDialog
          id={id}
          visible={modalVisibleRemove}
          onClose={() => setModalVisibleRemove(false)}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
}

export default HelpCmsList;
