import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_MAIN_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_SUB_LINK_MODAL,
} from '../../../Common/modal_text_info';
import { IMAGE_TYPE } from '../../../Config/listConstants';

function ImageJobEditModal({ image, onClose, onUpdate }) {
 
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          title show image
        </DialogTitle>
        <div className={'d-title-info-box'}>
          {/*image main*/}
          {image && image?.type === IMAGE_TYPE.AVATAR && (
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_MAIN_LINK_MODAL }}></div>
          )}
          {/*image sub*/}
          {image && image?.type === IMAGE_TYPE.SUB_IMAGE && (
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_SUB_LINK_MODAL }}></div>
          )}
        </div>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            <img
              src={image.url}
              style={{ maxWidth: 400, maxHeight: 400 }}
              alt=""
            />
          </div>
          <div>
            <center style={{ marginBottom: 15 }}>
              <Button
                onClick={onClose}
                variant="contained"
                className="btn-custom btn-red"
                style={{ marginRight: 10 }}
              >
                閉じる
              </Button>
              {/*<Button*/}
              {/*  onClick={() => handleUpdate()}*/}
              {/*  variant="contained"*/}
              {/*  className="btn-custom btn-blue"*/}
              {/*  disabled={updating}*/}
              {/*>*/}
              {/*  {image.inspection === IMAGE_STATUS.PENDING*/}
              {/*    ? '認証する'*/}
              {/*    : '認証しない'}*/}
              {/*</Button>*/}
            </center>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImageJobEditModal;
