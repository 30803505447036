import { call, put, takeLatest } from 'redux-saga/effects';
import ChatUser from '../Types/chatUser';
import { getChatByUser } from '../../Api/chatApi';
import { loadChatUserFail, loadChatUserSuccess } from '../Actions/chatUser';

function* handleLoadChatUser(action) {
  try {
    const response = yield call(getChatByUser, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadChatUserSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadChatUserFail(error));
  }
}

export default function* chatUserSaga() {
  yield takeLatest(ChatUser.LOAD_CHAT_USER, handleLoadChatUser);
}
