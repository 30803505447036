import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { updateEnvUICms } from '../../Redux/Actions/envUICms';

function EnvUICmsSettingList({ data, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(data);
  }, [data]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = [...inputUpdate];
    const elementsIndex = envSetting.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };
  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    dispatch(updateEnvUICms(inputUpdate));
    setEnvSetting(inputUpdate);
    setIsEditing(false);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell title="説明" style={{ width: '15%' }}>説明</TableCell>
              <TableCell title="URL(link)" style={{ width: '20%' }}>URL(link)</TableCell>
              <TableCell title="メニュー表示名(content)" style={{ width: '20%' }}>メニュー表示名(content)</TableCell>
              <TableCell title="設定表示並び順(order)" style={{ width: "5%" }}>設定表示並び順(order)</TableCell>
              <TableCell title="DATA名 (parrent_id)">DATA名 (parrent_id)</TableCell>
              <TableCell title="表示設定（0～2）(type)">表示設定（0～2）(type) </TableCell>
              <TableCell title="表示順(管理）" >表示順(管理）</TableCell>

            </TableRow>
            {envSetting.map((item, key) => (
              <TableRow key={key}>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                  title={item?.description}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item?.description}
                      name={`description.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item?.description
                  )}
                </TableCell>
                <TableCell title={item.link}>{item.link}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                  title={item.content}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.content}
                      name={`content.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.content
                  )}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.order}
                      name={`order.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.order
                  )}
                </TableCell>
                <TableCell>{item.parrentId}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.type}
                      name={`type.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.type
                  )}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.display_order}
                      name={`display_order.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.display_order
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default EnvUICmsSettingList;
