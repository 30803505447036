import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, Card } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

export default function CheckBoxItemModal(props) {
  const {
    strClass = '',
    onClose,
    isCloseFooter,
    visible,
    handleSubmit,
    title = '',
    data = [],
    selected = [],
    contentSticky = '',
    handleChange,
  } = props;

  return (
    <div className={strClass}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div className="w-100"
               style={{
                 justifyContent: 'space-between',
                 display: 'flex',
                 alignItems: 'center',
               }}
          >
            <p className={'d-title mb-0'}>{title}</p>
            <Button onClick={onClose} style={{ padding: 0 }}>
              <CloseIcon color="primary" />
            </Button>
          </div>
        </DialogTitle>
        {contentSticky &&
        (<div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: contentSticky }} />
          </div>
        )}

        <DialogContent>
          <Card theme={theme}>
            <div className="d-wrapper-tag-flex-grid-3">
              {data && Array.isArray(data) && data.length && data.map(option => (
                <>
                  {option.label && String(option.label).trim() && (<div key={option.id} className="tag">
                    <FormControlLabel label={option.label}
                                      style={{ margin: 0 }}
                                      control={<Checkbox onChange={handleChange}
                                                         className="d-checkbox-ui"
                                                         value={option.id}
                                                         checked={selected.indexOf(option.id) !== -1}
                                      />}
                    />
                  </div>)}
                </>
              ))}
            </div>

            <div>
              <center style={{ margin: 15 }}>
                {isCloseFooter && (<Button
                  onClick={onClose}
                  variant="contained"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>)}
                {handleSubmit && (<Button
                  onClick={handleSubmit}
                  variant="contained"
                  className="btn-custom btn-blue ml-2"
                >
                  はい
                </Button>)}
              </center>
            </div>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
