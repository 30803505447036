const resourcesItemJob = {
  LOAD_RESOURCE_ITEM_JOB: 'LOAD_RESOURCE_ITEM_JOB',
  LOAD_RESOURCE_ITEM_JOB_SUCCESS: 'LOAD_RESOURCE_ITEM_JOB_SUCCESS',
  LOAD_RESOURCE_ITEM_JOB_FAIL: 'LOAD_RESOURCE_ITEM_JOB_FAIL',

  LOAD_ROOT_RESOURCE_ITEM_JOB: 'LOAD_ROOT_RESOURCE_ITEM_JOB',
  LOAD_ROOT_RESOURCE_ITEM_JOB_SUCCESS: 'LOAD_ROOT_RESOURCE_ITEM_JOB_SUCCESS',
  LOAD_ROOT_RESOURCE_ITEM_JOB_FAIL: 'LOAD_ROOT_RESOURCE_ITEM_JOB_FAIL',

  GET_DETAIL_ROOT_RESOURCE_ITEM_JOB: 'GET_DETAIL_ROOT_RESOURCE_ITEM_JOB',
  GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_SUCCESS: 'GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_SUCCESS',
  GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_FAIL: 'GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_FAIL',

  UPDATE_ROOT_RESOURCE_ITEM_JOB: 'UPDATE_ROOT_RESOURCE_ITEM_JOB',
  UPDATE_ROOT_RESOURCE_ITEM_JOB_SUCCESS: 'UPDATE_ROOT_RESOURCE_ITEM_JOB_SUCCESS',
  UPDATE_ROOT_RESOURCE_ITEM_JOB_FAIL: 'UPDATE_ROOT_RESOURCE_ITEM_JOB_FAIL',

  UPDATE_CHILD_RESOURCE_ITEM_JOB: 'UPDATE_CHILD_RESOURCE_ITEM_JOB',
  UPDATE_CHILD_RESOURCE_ITEM_JOB_SUCCESS: 'UPDATE_CHILD_RESOURCE_ITEM_JOB_SUCCESS',
  UPDATE_CHILD_RESOURCE_ITEM_JOB_FAIL: 'UPDATE_CHILD_RESOURCE_ITEM_JOB_FAIL',
};
export default resourcesItemJob;
