import React, { useEffect, useState } from 'react';
import { getEnvSysCmsAll } from '../../Api/EnvSysCmsApi';
import LoadingBar from '../../Components/LoadingBar';
import EnvValueSetting from './EnvValueSetting';

const mutating = false;

function Index() {
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [EnvSysCmsData, setEnvSysCmsData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getEnvSysCmsAll().then(res => {
      const { data } = res;
      setEnvSysCmsData(data.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvValueSetting
        loading={loading}
        data={EnvSysCmsData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export default Index;
