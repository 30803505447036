import React, { useState } from 'react';
import { CircularProgress, createMuiTheme, FormLabel, MuiThemeProvider, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import { createHelp, updateHelp } from '../../Api/helpAPI';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function HelpCmsFormModal({ onClose, onSuccess, helpCms = {} }) {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(helpCms);

  const onChange = (e) => {
    model[e.target.name] = e.target.value;
    setModel({ ...model, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    const { name, content, link, display_order } = model;

    if (!(name && link)) {
      return;
    }
    let body = {
      name,
      content,
      link,
      display_order: +display_order,
    };
    let req;

    if (model.id) {
      req = updateHelp({ ...body, id: model.id });

    } else {
      req = createHelp(body);
    }
    setLoading(true);

    req.then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onSuccess();
        setLoading(false);
        if (model.id) {
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        }
      } else {
        if (model.id) {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
        setLoading(false);
      }
    })
      .catch((e) => setLoading(false));
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      aria-labelledby="max-width-dialog-title"
      className={"d-modal-wrapper"}
    >
      <MuiThemeProvider theme={theme}>
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <p className={'d-title mb-0'}>{model.id ? '更新' : '登録'}</p>
          <div>
            <Button
              onClick={onClose}
              color="primary"
              disabled={loading}
              className="btn-custom btn-red"
            >
              キャンセル
            </Button>
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              disabled={loading}
              className="btn-custom btn-blue mr-3"
            >
              {loading && <CircularProgress />}
              保存
            </Button>
          </div>
        </DialogTitle>
      </MuiThemeProvider>
      <DialogContent>
        <Row className="pb-3">
          <Col xs={12}>
            <Card body>
              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="説明テキスト"
                    type="text"
                    defaultValue={helpCms.name}
                    name="name"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12}>
                  <FormLabel component="legend">内容</FormLabel>
                  <textarea
                    name="content"
                    rows="3"
                    defaultValue={helpCms.content}
                    onChange={onChange}
                    style={{ width: '100%', padding: 10 }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="Link"
                    type="text"
                    defaultValue={helpCms.link}
                    name="link"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col xs={12}>
                  <TextField
                    label="Order"
                    type="number"
                    defaultValue={helpCms.display_order}
                    name="display_order"
                    onChange={onChange}
                    className="w-100"
                  />
                </Col>
              </FormGroup>
            </Card>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}

export default HelpCmsFormModal;
