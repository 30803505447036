import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import { loadRootResource } from '../../Redux/Actions/resource';
import NameProfileList from './NameProfileList';

function NameProfile() {
  const loadingRoot = useSelector((state) => state.resource.loadingRoot);
  const rootResource = useSelector((state) => state.resource.dataRoot);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRootResource());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loadingRoot || false} />
      <NameProfileList rootResource={rootResource} loading={loadingRoot} />
    </div>
  );
}
export default NameProfile;
