import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const getHelpByLink = (link) => sendGet('/cms/help/get-by-link/', link);
export const getHelps = (filter = {}) => {
  return sendGet('/cms/helps', {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};
export const updateHelp = (data) => sendPut(`/cms/help/update`, data);
export const createHelp = (data) => sendPost(`/cms/helps`, data);
export const getHelpById = (id) => sendGet(`/cms/help/${id}`);
export const deleteHelpById = (id) => sendDelete(`/cms/help/${id}`);
