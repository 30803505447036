import { createAction } from 'redux-actions';
import Information from '../Types/information';

export const loadInformation = createAction(Information.LOAD_INFORMATION);
export const loadInformationSuccess = createAction(Information.LOAD_INFORMATION_SUCCESS);
export const loadInformationFail = createAction(Information.LOAD_INFORMATION_FAIL);

export const loadInformations = createAction(Information.LOAD_INFORMATIONS);
export const loadInformationsSuccess = createAction(Information.LOAD_INFORMATIONS_SUCCESS);
export const loadInformationsFail = createAction(Information.LOAD_INFORMATIONS_FAIL);


export const updateInformation = createAction(Information.UPDATE_INFORMATION);
export const updateInformationSuccess = createAction(Information.UPDATE_INFORMATION_SUCCESS);
export const updateInformationFail = createAction(Information.UPDATE_INFORMATION_FAIL);
