import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default function ExecutingImportModal({ isOpen, onClose }) {
  const { loading, success } = useSelector((state) => state.importCSV);

  useEffect(() => {
    // if false, disable popup to display toast
    if (isOpen && !loading && !success) onClose();
  }, [isOpen, loading, success, onClose]);

  const renderContent = () => {
    if (loading)
      return (
        <>
          <Typography align="center">インポート中</Typography>
          <CircularProgress variant="indeterminate" />
        </>
      );

    return (
      success && (
        <>
          <CheckCircle style={{ fontSize: '60px', color: '#25ae88' }} />
          <Typography align="center">インポートが完了しましました</Typography>
        </>
      )
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={classNames(styles['modal-wrapper'], 'd-modal-wrapper')}
    >
      <DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '200px',
            gap: '20px',
          }}
        >
          {renderContent()}
        </div>
      </DialogContent>
    </Dialog>
  );
}
