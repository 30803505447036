const operator = {
  LOAD_OPERATOR: 'LOAD_OPERATOR',
  LOAD_OPERATOR_SUCCESS: 'LOAD_OPERATOR_SUCCESS',
  LOAD_OPERATOR_FAIL: 'LOAD_OPERATOR_FAIL',
  LOAD_DEATIL_OPERATOR: 'LOAD_DEATIL_OPERATOR',
  LOAD_DEATIL_OPERATOR_SUCCESS: 'LOAD_DEATIL_OPERATOR_SUCCESS',
  LOAD_DEATIL_OPERATOR_FAIL: 'LOAD_DEATIL_OPERATOR_FAIL',
  UPDATE_OPERATOR: 'UPDATE_OPERATOR',
  UPDATE_OPERATOR_SUCCESS: 'UPDATE_OPERATOR_SUCCESS',
  UPDATE_OPERATOR_FAIL: 'UPDATE_OPERATOR_FAIL',
  OPERATOR_LOG_OUT: 'OPERATOR_LOG_OUT',
};

export default operator;
