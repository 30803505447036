import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LoadingBar from '../../../Components/LoadingBar';
import SortArrows from '../../../Components/SortArrows';
import { useDispatch, useSelector } from 'react-redux';
import { loadBlogs } from '../../../Redux/Actions/blogs';
import { BLOG_TYPE } from '../../../Common/constant';
import { Link } from 'react-router-dom';
import Pagination from '../../../Components/Pagination';
import Util from '../../../Util/Util';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_BLOG_LOG_MODAL } from '../../../Common/modal_text_info';

const pageSize = 50;

function BlogLogModal({ visible, onClose, userId, labelDb }) {
  const [filter, setFilter] = useState({ sort: {} });
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.blogs.loading);
  const blogs = useSelector((state) => state.blogs.items);
  const totalRow = useSelector((state) => state.blogs.totalRow);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBlogs({ pageIndex: 1, pageSize, ...filter, userId }));
  }, [dispatch, filter, userId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading} />
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-ブログ
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_BLOG_LOG_MODAL }}></div>
          </div>

          <DialogContent className={'table-modal-wrapper'}>
            <Card className="scroll-x-on-top">
              <Table padding="checkbox" className="d-table-modal mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>投稿文</TableCell>
                    <TableCell>投稿画像</TableCell>
                    <TableCell>種類</TableCell>
                    <TableCell>×Status</TableCell>
                    <TableCell>×表示</TableCell>
                    <TableCell>×認証</TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text="日時"
                        name="blog.createdDate"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell align="center">×ボタン</TableCell>
                  </TableRow>
                  {blogs.map((blog, index) => (
                    <TableRow key={blog.id} style={{ height: 48 }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {blog.type === BLOG_TYPE.TEXT ? blog.content : null}
                      </TableCell>
                      <TableCell>
                        {blog.type === BLOG_TYPE.IMAGE ? (
                          <img
                            src={blog.content}
                            className="imgSquare object-fit-cover"
                            alt=""
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {Util.blogTypeToText(blog?.type)}
                      </TableCell>
                      <TableCell>{blog.status}</TableCell>
                      <TableCell>{Util.hiddenToText(blog.hidden)}</TableCell>
                      <TableCell>
                        {Util.inspectionToText(blog.inspection)}
                      </TableCell>
                      <TableCell>
                        <time>
                          {Util.timestampToDate(blog.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                        </time>
                      </TableCell>
                      <TableCell className="text-center">
                        <Link className="d-color-link">×認証ボタン</Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <center>
              <Pagination
                className={'d-page-wrapper'}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </DialogContent>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}

export default BlogLogModal;
