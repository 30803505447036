import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { loadEnvUI } from '../../Redux/Actions/envUI';
import EnvUiWebSettingList from './EnvUiWebSettingList/index';

const pageSize = 50;
const mutating = false;

function Index() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.envUI.loading);
  const envUI = useSelector((state) => state.envUI.data);
  const totalRow = useSelector((state) => state.envUI.totalRow);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadEnvUI());
  }, [dispatch, pageIndex]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvUiWebSettingList
        loading={loading}
        nameEnv={envUI}
        pageIndex={pageIndex}
        pageSize={pageSize}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <center>
        <Pagination
          pageIndex={pageIndex || 1}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}
export default Index;
