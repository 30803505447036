import { call, put, takeLatest } from 'redux-saga/effects';
import { getListMessage } from '../../Api/database';
import { getMessages } from '../../Api/messagesApi';
import { loadListMessageFail, loadListMessageSuccess } from '../Actions/listMessage';
import ListMessage from '../Types/listMessage';

function* handleLoadListMessage(action) {
  try {
    const response = yield call(getListMessage, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListMessageSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListMessageFail(error));
  }
}

function* handleLoadListMessageById(action) {
  try {
    const response = yield call(getMessages, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListMessageSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListMessageFail(error));
  }
}

export default function* listMessageSaga() {
  yield takeLatest(ListMessage.LOAD_LIST_MESSAGE, handleLoadListMessage);
  yield takeLatest(ListMessage.LOAD_LIST_MESSAGE_BY_ID, handleLoadListMessageById);
}
