import { createAction } from 'redux-actions';
import ScheduleMail from '../Types/scheduleMail';

export const loadScheduleMail = createAction(ScheduleMail.LOAD_SCHEDULE_MAIL);
export const loadScheduleMailSuccess = createAction(
  ScheduleMail.LOAD_SCHEDULE_MAIL_SUCCESS,
);
export const loadScheduleMailFail = createAction(
  ScheduleMail.LOAD_SCHEDULE_MAIL_FAIL,
);
export const estimateScheduleMailReceivers = createAction(
  ScheduleMail.ESTIMATE_SCHEDLULE_MAIL_RECEIVERS,
);
export const estimateScheduleMailReceiversSuccess = createAction(
  ScheduleMail.ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_SUCCESS,
);
export const estimateScheduleMailReceiversFail = createAction(
  ScheduleMail.ESTIMATE_SCHEDLULE_MAIL_RECEIVERS_FAIL,
);
