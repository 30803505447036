import { call, put, takeLatest } from 'redux-saga/effects';
import Job from '../Types/job';
import { getJob, updateJob } from '../../Api/jobApi';
import { loadJobFail, loadJobSuccess, updateJobFail, updateJobSuccess } from '../Actions/job';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadJob(action) {
  try {
    const response = yield call(getJob, action.payload);
    const { data } = response;
    if (data.success) {
      const job = data.data;
      yield put(loadJobSuccess(job));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadJobFail(error));
  }
}

function* handleUpdateJob(action) {
  try {
    const response = yield call(updateJob, action.payload);
    const { data } = response;
    if (data.success) {
      const job = data.data;
      yield put(updateJobSuccess(job));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateJobFail(error));
  }
}

export default function* jobSaga() {
  yield takeLatest(Job.LOAD_JOB, handleLoadJob);
  yield takeLatest(Job.UPDATE_JOB, handleUpdateJob);
}
