import React from 'react';
import { toast } from 'react-toastify';
import * as jobApi from '../../../Api/jobApi';

import RemoveItemModal from '../../../Components/RemoveItemModal';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../Common/constant_text';


export default function RemoveJobModal({ visible, onClose, id, reload }) {
  const handleSubmit = async () => {
    await jobApi.deleteJob(id).then((response) => {
      const { data } = response;
      if (data.success) {
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
        reload()
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}
