import { handleActions } from 'redux-actions';
import Information from '../Types/information';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
  totalRow: 0,
};

const actions = {
  [Information.LOAD_INFORMATION]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [Information.LOAD_INFORMATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      loading: false,
      success: false,
    };
  },
  [Information.LOAD_INFORMATION_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [Information.UPDATE_INFORMATION]: (state) => ({
    ...state,
    updating: true,
  }),
  [Information.UPDATE_INFORMATION_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [Information.UPDATE_INFORMATION_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
