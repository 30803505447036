import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../Common/constant';

function TagSearchBox(props) {
  const {labelDb} = props
  const [filter, setFilter] = useState({ gender: [1, 2, 3, 4] });

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    setFilter({});
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    // props.onClear();
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">チャット画像</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1 }>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickName"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label="電話番号"
                  type="text"
                  name="phoneNumber"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TagSearchBox;
