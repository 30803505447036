import { call, put, takeLatest } from 'redux-saga/effects';
import User from '../Types/user';
import { getUser, updateUser } from '../../Api/userAPI';
import { toast } from 'react-toastify';
import { loadUserFail, loadUserSuccess, updateUserFail, updateUserSuccess } from '../Actions/user';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadUser(action) {
  try {
    const response = yield call(getUser, action.payload);
    const { data } = response;
    if (data.success) {
      const user = data.data;
      yield put(loadUserSuccess(user));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadUserFail(error));
  }
}

function* handleUpdateUser(action) {
  try {
    const response = yield call(updateUser, action.payload);
    const { data } = response;
    if (data.success) {
      const user = data.data;
      yield put(updateUserSuccess(user));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    toast.error(error.response?.data?.message);
    yield put(updateUserFail(error));
  }
}

export default function* userSaga() {
  yield takeLatest(User.LOAD_USER, handleLoadUser);
  yield takeLatest(User.UPDATE_USER, handleUpdateUser);
}
