import { handleActions } from "redux-actions";
import ListRawUsers from "../Types/listRawUsers";

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [ListRawUsers.LOAD_LIST_RAW_USERS]: (state) => ({
    ...state,
    loading: true,
  }),
  [ListRawUsers.LOAD_LIST_RAW_USERS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ListRawUsers.LOAD_LIST_RAW_USERS_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
