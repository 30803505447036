import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Util from '../../Util/Util';
import AddPopupModal from './AddPopupModal';
import EditPopupModal from './EditPopupModal';

import moment from 'moment';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { Link } from 'react-router-dom';
import { convertHiddenStatusText, convertNeedOtpText, renderStatusAgeConfirm } from '../../Util/utils';
import Nophoto from '../../assets/image/no_user_photo.png';

const AppDestinationOptions = [
  {
    value: 0,
    label: '',
  },
  {
    value: 1,
    label: 'プラン購入',
  },
  {
    value: 2,
    label: 'ポイント購入',
  },
  {
    value: 3,
    label: '問い合わせ',
  },
  {
    value: 4,
    label: '問合せ（年齢認証）',
  },
  {
    value: 11,
    label: 'お知らせ1',
  },
  {
    value: 12,
    label: 'お知らせ2',
  },
  {
    value: 13,
    label: 'お知らせ3',
  },
  {
    value: 14,
    label: 'お知らせ4',
  },
  {
    value: 15,
    label: 'お知らせ5',
  },
  {
    value: 16,
    label: 'お知らせ6',
  },
  {
    value: 17,
    label: 'お知らせ7',
  },
  {
    value: 18,
    label: 'お知らせ8',
  },
];

function PopupList({
                     popups,
                     onUpdate,
                     filter = { sort: {} },
                     onSort = (sortByKey) => {
                     },
                   }) {
  const [addPopupModalVisible, setShowAddPopupModal] = useState();
  const [editingPopup, setEditingPopup] = useState();

  const showAddPopupModal = () => {
    setShowAddPopupModal(true);
  };

  const hideAddPopupModal = () => {
    setShowAddPopupModal(false);
  };

  const showEditModal = (popup) => {
    setEditingPopup(popup);
  };

  const hideEditModal = () => {
    setEditingPopup(null);
  };

  const findAppDestination = (params) => {
    const data = AppDestinationOptions.find((item) => item.value === params);
    return data.label;
  };

  return (
    <div>
      <div className="align" style={{ justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          className="btn-custom btn-blue ml-3"
          onClick={() => showAddPopupModal()}
        >
          ポップアップ設定を追加
        </Button>
      </div>
      {addPopupModalVisible && (
        <AddPopupModal onClose={hideAddPopupModal} onUpdate={onUpdate} />
      )}
      {editingPopup && (
        <EditPopupModal
          popup={editingPopup}
          onClose={hideEditModal}
          onUpdate={onUpdate}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ marginBottom: 20 }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="設定名"
                    name="popup.name"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">バナー画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="表示場所"
                    name="popup.place"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示設定</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="優先表示順位"
                    name="popup.order"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示端末</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="popup.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="年齢認証"
                    name="popup.confirmAge"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="ログイン方式"
                    name="popup.needOtp"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="リンク先"
                    name="popup.app_destination"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="開始日時"
                    name="popup.start_time"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="終了日時"
                    name="popup.end_time"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    dangerouslySetInnerHTML={{
                      __html: '更新日時',
                    }}
                    name="popup.updatedDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {popups.map((popup, index) => (
                <TableRow key={popup.id} style={{ height: 48 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <span>{popup.name}</span>
                  </TableCell>
                  <TableCell align="center">
                    <img
                      style={{
                        height: 100,
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={popup?.url && popup.url.length ? popup.url : Nophoto}
                      alt=""
                    />
                  </TableCell>
                  <TableCell>{popup.place === 0 ? 'Home' : null}</TableCell>
                  <TableCell>
                    {convertHiddenStatusText(popup.displaySetting)}
                  </TableCell>
                  <TableCell>{popup.order} </TableCell>

                  <TableCell>
                    {Util.typeConnectionToText(popup.typeDevice)}
                  </TableCell>
                  <TableCell>
                    {Util.genderIdToText(popup.gender)}
                  </TableCell>
                  <TableCell align="left">
                    {renderStatusAgeConfirm(popup.confirmAge)}
                  </TableCell>
                  <TableCell align="left">
                    {convertNeedOtpText(popup.needOtp)}
                  </TableCell>
                  <TableCell align="left">
                    {findAppDestination(popup.app_destination)}
                  </TableCell>
                  <TableCell>
                    {moment(new Date(parseInt(popup.start_time))).format('YYYY/MM/DD HH:mm')}
                  </TableCell>
                  <TableCell>
                    {moment(new Date(parseInt(popup.end_time))).format('YYYY/MM/DD HH:mm'                    )}
                  </TableCell>
                  <TableCell>
                    {moment(new Date(parseInt(popup.updatedDate))).format('YYYY/MM/DD HH:mm:ss')}
                  </TableCell>

                  <TableCell align="center">
                    <div className="d-color-link">
                      <Link onClick={() => showEditModal(popup)}>
                        編集
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default PopupList;
