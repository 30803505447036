import { call, put, takeLatest } from 'redux-saga/effects';
import Campaigns from '../Types/campaigns';
import { getCampaigns } from '../../Api/campaignApi';
import { loadCampaignsFail, loadCampaignsSuccess } from '../Actions/campaigns';


function* handleLoadCampaigns(action) {
  try {
    const response = yield call(getCampaigns, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadCampaignsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadCampaignsFail(error));
  }
}

export default function* campaignsSaga() {
  yield takeLatest(Campaigns.LOAD_CAMPAIGNS, handleLoadCampaigns);
}
