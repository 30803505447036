import { call, put, takeLatest } from 'redux-saga/effects';
import LikeUser from '../Types/likeUser';
import { getLikeByUser } from '../../Api/likeApi';
import { loadLikeUserFail, loadLikeUserSuccess } from '../Actions/likeUser';

function* handleLoadLikeUser(action) {
  try {
    const response = yield call(getLikeByUser, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLikeUserSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLikeUserFail(error));
  }
}

export default function* likeUserSaga() {
  yield takeLatest(LikeUser.LOAD_LIKE_USER, handleLoadLikeUser);
}
