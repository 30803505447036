import { createAction } from 'redux-actions';
import memberAdmin from '../Types/memberAdmin';

export const loadMemberAdmin = createAction(memberAdmin.LOAD_MEMBER_ADMIN);
export const loadMemberAdminSuccess = createAction(memberAdmin.LOAD_MEMBER_ADMIN_SUCCESS);
export const loadMMemberAdminFail = createAction(memberAdmin.LOAD_MEMBER_ADMIN_FAIL);

export const updateMemberAdmin = createAction(memberAdmin.UPDATE_MEMBER_ADMIN);
export const updateMemberAdminSuccess = createAction(memberAdmin.UPDATE_MEMBER_ADMIN_SUCCESS);
export const updateMemberAdminFail = createAction(memberAdmin.UPDATE_MEMBER_ADMIN_FAIL);

export const getByIdMemberAdmin = createAction(memberAdmin.GET_BY_ID_MEMBER_ADMIN);
export const getByIdMemberAdminSuccess = createAction(memberAdmin.GET_BY_ID_MEMBER_ADMIN_SUCCESS);
export const getByIdMemberAdminFail = createAction(memberAdmin.GET_BY_ID_MEMBER_ADMIN_FAIL);

export const createMemberAdmin = createAction(memberAdmin.CREATE_MEMBER_ADMIN);
export const createMemberAdminSuccess = createAction(memberAdmin.CREATE_MEMBER_ADMIN_SUCCESS);
export const createMemberAdminFail = createAction(memberAdmin.CREATE_MEMBER_ADMIN_FAIL);
