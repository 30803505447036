const Resource = {
  LOAD_RESOURCE: 'LOAD_RESOURCE',
  LOAD_RESOURCE_SUCCESS: 'LOAD_RESOURCE_SUCCESS',
  LOAD_RESOURCE_FAIL: 'LOAD_RESOURCE_FAIL',
  LOAD_ROOT_RESOURCE: 'LOAD_ROOT_RESOURCE',
  LOAD_ROOT_RESOURCE_SUCCESS: 'LOAD_ROOT_RESOURCE_SUCCESS',
  LOAD_ROOT_RESOURCE_FAIL: 'LOAD_ROOT_RESOURCE_FAIL',
  GET_DETAIL_ROOT_RESOURCE: 'GET_DETAIL_ROOT_RESOURCE',
  GET_DETAIL_ROOT_RESOURCE_SUCCESS: 'GET_DETAIL_ROOT_RESOURCE_SUCCESS',
  GET_DETAIL_ROOT_RESOURCE_FAIL: 'GET_DETAIL_ROOT_RESOURCE_FAIL',
  UPDATE_ROOT_RESOURCE: 'UPDATE_ROOT_RESOURCE',
  UPDATE_ROOT_RESOURCE_SUCCESS: 'UPDATE_ROOT_RESOURCE_SUCCESS',
  UPDATE_ROOT_RESOURCE_FAIL: 'UPDATE_ROOT_RESOURCE_FAIL',
  UPDATE_CHILD_RESOURCE: 'UPDATE_CHILD_RESOURCE',
  UPDATE_CHILD_RESOURCE_SUCCESS: 'UPDATE_CHILD_RESOURCE_SUCCESS',
  UPDATE_CHILD_RESOURCE_FAIL: 'UPDATE_CHILD_RESOURCE_FAIL',
};
export default Resource;
