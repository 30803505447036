import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { conversionTags } from '../../Api/conversionTagApi';
import LoadingBar from '../../Components/LoadingBar';
import ConversionTagList from './ConversionTagList';
import { convertEnvInfo } from '../../Util/utils';

const mutating = false;

function ConversionTag() {
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [conversionTag, setConversionTag] = useState([]);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    setLoading(true);
    conversionTags().then(res => {
      const { data } = res;
      setConversionTag(data.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <ConversionTagList
        loading={loading}
        data={conversionTag}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        labelDb={labelDb}
      />
    </div>
  );
}

export default ConversionTag;
