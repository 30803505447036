import { call, put, takeLatest } from 'redux-saga/effects';
import Images from '../Types/images';
import { getImages } from '../../Api/imageApi';
import { loadImagesFail, loadImagesSuccess } from '../Actions/images';


function* handleLoadImages(action) {
  try {
    const response = yield call(getImages, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadImagesSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadImagesFail(error));
  }
}

export default function* imagesSaga() {
  yield takeLatest(Images.LOAD_IMAGES, handleLoadImages);
}
