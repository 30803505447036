import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { loadUsers } from '../../Redux/Actions/users';
import { convertEnvInfo } from '../../Util/utils';
import UserArchiveList from './UserArchiveList';

const pageSize = 50;

function UserArchiveSearch() {
  const loading = useSelector((state) => state.users.loading);
  const users = useSelector((state) => state.users.items);
  const totalRow = useSelector((state) => state.users.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'lastlogin',
    orderBy: 'DESC',
    sort: { lastlogin: 'DESC' },
    archive: 1
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);


  useEffect(() => {
    dispatch(loadUsers({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);


  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadUsers({ ...filter, pageIndex, pageSize }));
  };

  const refreshList = () => {
    dispatch(loadUsers({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const onSearch = (searchData) => {
    setFilter({...filter, ...searchData})
  }

  const onClear = () => {
    setFilter({
      sortBy: 'lastlogin',
      orderBy: 'DESC',
      sort: { lastlogin: 'DESC' },
      archive: 1
    });
  }

  return (
    <div className="mt-2">
      <LoadingBar show={loading} />
      <UserArchiveList
        users={users}
        onUpdate={refreshList}
        filter={filter}
        onSort={onSort}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={loading}
        totalRow={totalRow}
        onSearch={onSearch}
        onClear={onClear}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default UserArchiveSearch;
