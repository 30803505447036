import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import React, { useState } from 'react';
import Nophoto from '../../../assets/image/no_user_photo.png';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import { addPointUser } from '../../../Api/userAPI';
import { toast } from 'react-toastify';
import Util from '../../../Util/Util';
import { TEXT_HTML_ADD_POINT_MODAL } from '../../../Common/modal_text_info';
import { themeStyleNormal1Modal } from '../theme_modal';
import { payingTypeOptionsRequired, paymentTypeOptionsRequired } from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';


const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    fontSize: 18,
    marginBottom: 0,
  },
}));

function PaymentModal({ visible, onClose, userId, nickName, avatar, labelDb }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [confirmVisible, setConFirmVisible] = useState(false);

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  const onChange = (event) => {
    data[event.target.name] = event.target.value;
    setData(data);
  };

  const handleConfirm = () => {
    let check = true;
    if (!data.paymentType) {
      check = false;
      toast.error('入金種類を入力してください（入金ログ）');
    } else {
      data['typeGetLog'] = data.paymentType;
    }
    if (!data.payingType) {
      check = false;
      toast.error('入金タイプを入力してください（入金ログ）');
    }
    if (!data.pointGet || data.pointGet === '') {
      check = false;
      toast.error('取得ポイントを入力してください');
    } else {
      data['pointGetLog'] = data.pointGet;
    }
    if (!data.pointBonus || data.pointBonus === '') {
      check = false;
      toast.error('ボーナスポイントを入力してください');
    }
    if (!data.price || data.price === '') {
      check = false;
      toast.error('金額を入力してください');
    }
    if (!data.month || data.month === '') {
      check = false;
      toast.error('購入期間を入力してください');
    }
    if (check) setConFirmVisible(true);
  };

  const handleSubmit = async () => {
    await addPointUser({ ...data, userId })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          setConFirmVisible(false);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        }
      })
      .catch((e) => {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
        setConFirmVisible(false);
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              {/*実行してよろしいですか？*/}
              <Row>
                <Col xs={12}>
                  <Card body style={{ marginBottom: 10, padding: 20 }}>
                    <Row>
                      <Col xs={6}>
                        <Row>
                          <Label xs={12} style={style}>
                            <p>入金ログ</p>
                          </Label>
                          <Label xs={6} style={style}>
                            <p>入金種類</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.paymentTypeIdToText(data?.paymentType)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>入金タイプ</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.payingTypeIdToText(data?.payingType)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>取得ポイント</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.separator(data?.pointGet)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>ボーナスポイント</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.separator(data?.pointBonus)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>金額</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.separator(data?.price)}円</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>購入期間</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{data?.month}</p>
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Label xs={12} style={style}>
                            <p>ポイント取得ログ</p>
                          </Label>
                          <Label xs={6} style={style}>
                            <p>入金種類</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.paymentTypeIdToText(data?.typeGetLog)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>取得ポイント</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{Util.separator(data?.pointGetLog)}</p>
                            <hr style={stylehrright} />
                          </Label>

                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <Card style={{ padding: 20, marginBottom: 10 }}>
                    <Row>
                      <Col xs={3}>
                        <Label>メモ</Label>
                      </Col>
                      <Col xs={9}>
                        <Label style={{ whiteSpace: 'pre-line' }}>{data?.memo}</Label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ユーザー詳細-入金処理</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>受信者</p>
              <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>{nickName}</p>
              <img className="object-fit-cover"
                   style={{ width: 30, height: 30, borderRadius: '50%' }}
                   src={avatar ? avatar.url : Nophoto}
                   alt=""
              />
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_ADD_POINT_MODAL }}></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={themeStyleNormal1Modal}>
            <Card>
              <Row style={{ padding: 20 }}>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel} shrink>
                          <span className="text-red">*</span>
                          入金種類
                        </InputLabel>
                        <Select
                          name="paymentType"
                          onChange={onChange}
                          displayEmpty
                          className={classes.selectEmpty}
                          required={true}
                        >
                          {paymentTypeOptionsRequired().map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl style={{ marginTop: 20 }} className={classes.formControl}>
                        <InputLabel className={classes.inputLabel} shrink> <span className="text-red">*</span>入金タイプ</InputLabel>
                        <Select
                          name="payingType"
                          onChange={onChange}
                          displayEmpty
                          className={classes.selectEmpty}
                          required={true}
                        >
                          {payingTypeOptionsRequired().map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <InputLabel className={classes.inputLabel} style={{ marginTop: 20 }} shrink><span
                        className="text-red">*</span>取得ポイント</InputLabel>
                      <Input
                        type="number"
                        style={{
                          width: '100%',
                          marginBottom: '20px',
                        }}
                        name="pointGet"
                        onChange={onChange}
                      />
                      <InputLabel className={classes.inputLabel} shrink><span
                        className="text-red">*</span>ボーナスポイント</InputLabel>
                      <Input
                        type="number"
                        style={{
                          width: '100%',
                          marginBottom: '20px',
                        }}
                        name="pointBonus"
                        onChange={onChange}
                      />
                      <InputLabel className={classes.inputLabel} shrink><span
                        className="text-red">*</span>金額</InputLabel>
                      <Input
                        type="number"
                        style={{
                          width: '100%',
                          marginBottom: '20px',
                        }}
                        name="price"
                        onChange={onChange}
                      />
                      <InputLabel className={classes.inputLabel} shrink><span
                        className="text-red">*</span>購入期間（ポイント購入の場合は、0を記録して下さい）</InputLabel>
                      <Input
                        type="number"
                        style={{
                          width: '100%',
                          marginBottom: '20px',
                        }}
                        name="month"
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <InputLabel>メモ</InputLabel>
                  <textarea
                    type="text"
                    style={{
                      width: '100%',
                      marginBottom: '20px',
                      padding: 10
                    }}
                    row="3"
                    name="memo"
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </Card>
          </MuiThemeProvider>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PaymentModal;
