const listRawDatabase = {
  // information
  LOAD_LIST_RAW_INFORMATION: 'LOAD_LIST_RAW_INFORMATION',
  LOAD_LIST_RAW_INFORMATION_SUCCESS: 'LOAD_LIST_RAW_INFORMATION_SUCCESS',
  LOAD_LIST_RAW_INFORMATION_FAIL: 'LOAD_LIST_RAW_INFORMATION_FAIL',

  // twilio-history
  LOAD_LIST_RAW_TWILIO_HISTORY: 'LOAD_LIST_RAW_TWILIO_HISTORY',
  LOAD_LIST_RAW_TWILIO_HISTORY_SUCCESS: 'LOAD_LIST_RAW_TWILIO_HISTORY_SUCCESS',
  LOAD_LIST_RAW_TWILIO_HISTORY_FAIL: 'LOAD_LIST_RAW_TWILIO_HISTORY_FAIL',

  // text-web-page
  LOAD_LIST_RAW_TEXT_WEB_PAGE: 'LOAD_LIST_RAW_USERS',
  LOAD_LIST_RAW_TEXT_WEB_PAGE_SUCCESS: 'LOAD_LIST_RAW_USERS_SUCCESS',
  LOAD_LIST_RAW_TEXT_WEB_PAGE_FAIL: 'LOAD_LIST_RAW_TEXT_WEB_PAGE_FAIL',

  // operator
  LOAD_LIST_RAW_OPERATOR: 'LOAD_LIST_RAW_OPERATOR',
  LOAD_LIST_RAW_OPERATOR_SUCCESS: 'LOAD_LIST_RAW_OPERATOR_SUCCESS',
  LOAD_LIST_RAW_OPERATOR_FAIL: 'LOAD_LIST_RAW_OPERATOR_FAIL',
  // agent
  LOAD_LIST_RAW_AGENT: 'LOAD_LIST_RAW_AGENT',
  LOAD_LIST_RAW_AGENT_SUCCESS: 'LOAD_LIST_RAW_AGENT_SUCCESS',
  LOAD_LIST_RAW_AGENT_FAIL: 'LOAD_LIST_RAW_AGENT_FAIL',

  // member_archive
  LOAD_LIST_RAW_MEMBER_ARCHIVE: 'LOAD_LIST_RAW_MEMBER_ARCHIVE',
  LOAD_LIST_RAW_MEMBER_ARCHIVE_SUCCESS: 'LOAD_LIST_RAW_MEMBER_ARCHIVE_SUCCESS',
  LOAD_LIST_RAW_MEMBER_ARCHIVE_FAIL: 'LOAD_LIST_RAW_MEMBER_ARCHIVE_FAIL',

  // member_admin
  LOAD_LIST_RAW_MEMBER_ADMIN: 'LOAD_LIST_RAW_MEMBER_ADMIN',
  LOAD_LIST_RAW_MEMBER_ADMIN_SUCCESS: 'LOAD_LIST_RAW_MEMBER_ADMIN_SUCCESS',
  LOAD_LIST_RAW_MEMBER_ADMIN_FAIL: 'LOAD_LIST_RAW_MEMBER_ADMIN_FAIL',

  // otp
  LOAD_LIST_RAW_OTP: 'LOAD_LIST_RAW_OTP',
  LOAD_LIST_RAW_OTP_SUCCESS: 'LOAD_LIST_RAW_OTP_SUCCESS',
  LOAD_LIST_RAW_OTP_FAIL: 'LOAD_LIST_RAW_OTP_FAIL',

  // payment_log_job
  LOAD_LIST_RAW_PAYMENT_LOG_JOB: 'LOAD_LIST_RAW_PAYMENT_LOG_JOB',
  LOAD_LIST_RAW_PAYMENT_LOG_JOB_SUCCESS: 'LOAD_LIST_RAW_PAYMENT_LOG_JOB_SUCCESS',
  LOAD_LIST_RAW_PAYMENT_LOG_JOB_FAIL: 'LOAD_LIST_RAW_PAYMENT_LOG_JOB_FAIL',

  // popup_setting
  LOAD_LIST_RAW_POPUP_SETTING: 'LOAD_LIST_RAW_POPUP_SETTING',
  LOAD_LIST_RAW_POPUP_SETTING_SUCCESS: 'LOAD_LIST_RAW_POPUP_SETTING_SUCCESS',
  LOAD_LIST_RAW_POPUP_SETTING_FAIL: 'LOAD_LIST_RAW_POPUP_SETTING_FAIL',

   // log_mail_verification
   LOAD_LIST_RAW_LOG_MAIL_VERIFICATION: 'LOAD_LIST_RAW_LOG_MAIL_VERIFICATION',
   LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_SUCCESS: 'LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_SUCCESS',
   LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_FAIL: 'LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_FAIL',

  // job_detail
  LOAD_LIST_RAW_JOB_DETAIL: 'LOAD_LIST_RAW_JOB_DETAIL',
  LOAD_LIST_RAW_JOB_DETAIL_SUCCESS: 'LOAD_LIST_RAW_JOB_DETAIL_SUCCESS',
  LOAD_LIST_RAW_JOB_DETAIL_FAIL: 'LOAD_LIST_RAW_JOB_DETAIL_FAIL',
};
export default listRawDatabase;
