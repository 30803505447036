const helpMailSmsText = {
  LOAD_HELP_MAIL_SMS_TEXT: 'LOAD_HELP_MAIL_SMS_TEXT',
  LOAD_HELP_MAIL_SMS_TEXT_SUCCESS: 'LOAD_HELP_MAIL_SMS_TEXT_SUCCESS',
  LOAD_HELP_MAIL_SMS_TEXT_FAIL: 'LOAD_HELP_MAIL_SMS_TEXT_FAIL',

  // GET_BY_ID_HELP_MAIL_SMS_TEXT_SUCCESS: 'GET_BY_ID_HELP_MAIL_SMS_TEXT_SUCCESS',
  // GET_BY_ID_HELP_MAIL_SMS_TEXT_FAIL: 'GET_BY_ID_HELP_MAIL_SMS_TEXT_FAIL',

  // CREATE_HELP_MAIL_SMS_TEXT: 'CREATE_HELP_MAIL_SMS_TEXT',
  // CREATE_HELP_MAIL_SMS_TEXT_SUCCESS: 'CREATE_HELP_MAIL_SMS_TEXT_SUCCESS',
  // CREATE_HELP_MAIL_SMS_TEXT_FAIL: 'CREATE_HELP_MAIL_SMS_TEXT_FAIL',

  UPDATE_HELP_MAIL_SMS_TEXT: 'UPDATE_HELP_MAIL_SMS_TEXT',
  UPDATE_HELP_MAIL_SMS_TEXT_SUCCESS: 'UPDATE_HELP_MAIL_SMS_TEXT_SUCCESS',
  UPDATE_HELP_MAIL_SMS_TEXT_FAIL: 'UPDATE_HELP_MAIL_SMS_TEXT_FAIL',
};
export default helpMailSmsText;
