import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import _ from 'lodash';
import ImageModal from '../../Components/ImageModal';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function InformationDetail({ onClose, info, reload }) {
  const [previewingImage, setPreviewingImage] = useState(null);

  const handleShowImageModal = (info) => {
    setPreviewingImage(info);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  if (!info) {
    return;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!info}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={"d-modal-wrapper"}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>お知らせ-詳細</p>
          </div>
        </DialogTitle>
        <Button style={{ position: 'absolute', top: 10, right: 0 }}
                onClick={onClose}>
          <CloseIcon color="primary" />
        </Button>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card body style={{ marginBottom: '1rem' }}>
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>投稿日</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{moment(Number(_.get(info, 'registDate'))).format(
                            'YYYY-MM-DD',
                          )}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p></p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p></p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>

                <Card body style={{ marginBottom: '0.5rem' }}>
                  <Row>
                    <Label xs={2} >タイトル</Label>
                    <Label xs={10}>{_.get(info, 'title')}</Label>
                  </Row>
                </Card>

                <Card body style={{ marginBottom: '0.5rem' }}>
                  <Row>
                    <Label xs={2}>本文</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>
                      <div dangerouslySetInnerHTML={{ __html: info.content }}></div>
                    </Label>
                  </Row>
                </Card>

                {info?.urlImage &&
                (<Card className="card card-body" style={{ marginBottom: 10 }}>
                  <Row>
                    <Col xs={12}>
                      <img
                        style={{
                          width: 250,
                          objectFit: 'cover',
                          margin: 2.5,
                        }}
                        src={info?.urlImage}
                        alt={info?.urlImage}
                        onClick={() => handleShowImageModal(info)}
                      />
                    </Col>
                  </Row>
                </Card>)}
              </Col>
            </Row>
            {previewingImage && (
              <ImageModal
                image={previewingImage}
                onClose={handleHideImageModal}
              />
            )}
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
