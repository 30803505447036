import { handleActions } from 'redux-actions';
import SalaryLog from '../Types/salaryLog';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [SalaryLog.LOAD_SALARYLOG]: (state) => ({
    ...state,
    loading: true,
  }),
  [SalaryLog.LOAD_SALARY_CHARA]: (state) => ({
    ...state,
    loading: true,
  }),
  [SalaryLog.LOAD_SALARYLOG_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [SalaryLog.LOAD_SALARYLOG_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
