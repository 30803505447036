import { call, put, takeLatest } from 'redux-saga/effects';
import Character from '../Types/character';
import { getCharaByAgent } from '../../Api/agentAPI';
import { toast } from 'react-toastify';
import { loadCharacterFail, loadCharacterSuccess } from '../Actions/character';

function* handleLoadCharacter(action) {
  try {
    const response = yield call(getCharaByAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const character = data.data;
      yield put(loadCharacterSuccess(character));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadCharacterFail(error));
  }
}

export default function* characterSaga() {
  yield takeLatest(Character.LOAD_CHARACTER, handleLoadCharacter);
}
