import { call, put, takeLatest } from 'redux-saga/effects';
import Resource from '../Types/resourcesAgent';
import {
  getDetailResourceAgent,
  getResourceAgent,
  getRootResourceAgent,
  updateChildResourceAgent,
  updateRootResourceAgent,
} from '../../Api/resourseAgentApi';
import {
  getDetailRootResourceAgentFail,
  getDetailRootResourceAgentSuccess,
  loadResourceAgentFail,
  loadResourceAgentSuccess,
  loadRootResourceAgentFail,
  loadRootResourceAgentSuccess,
  updateChildResourceAgentFail,
  updateChildResourceAgentSuccess,
  updateRootResourceAgentFail,
  updateRootResourceAgentSuccess,
} from '../Actions/resourceAgent';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadResourceAgent(action) {
  try {
    const response = yield call(getResourceAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadResourceAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadResourceAgentFail(error));
  }
}

function* handleLoadRootResourceAgent(action) {
  try {
    const response = yield call(getRootResourceAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      yield put(loadRootResourceAgentSuccess({ items }));
    }
  } catch (error) {
    yield put(loadRootResourceAgentFail(error));
  }
}

function* handleGetDetailResourceAgent(action) {
  try {
    const response = yield call(getDetailResourceAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(getDetailRootResourceAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(getDetailRootResourceAgentFail(error));
  }
}

function* handleUpdateRootResourceAgent(action) {
  try {
    const response = yield call(updateRootResourceAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const rootResource = data.data;
      yield put(updateRootResourceAgentSuccess(rootResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateRootResourceAgentFail(error));
  }
}

function* handleUpdateChildResourceAgent(action) {
  try {
    const response = yield call(updateChildResourceAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const childResource = data.data;
      yield put(updateChildResourceAgentSuccess(childResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateChildResourceAgentFail(error));
  }
}

export default function* resourceSaga() {
  yield takeLatest(Resource.LOAD_RESOURCE_AGENT, handleLoadResourceAgent);

  yield takeLatest(Resource.LOAD_ROOT_RESOURCE_AGENT, handleLoadRootResourceAgent);
  yield takeLatest(Resource.UPDATE_ROOT_RESOURCE_AGENT, handleUpdateRootResourceAgent);

  yield takeLatest(Resource.GET_DETAIL_ROOT_RESOURCE_AGENT, handleGetDetailResourceAgent);
  yield takeLatest(Resource.UPDATE_CHILD_RESOURCE_AGENT, handleUpdateChildResourceAgent);
}
