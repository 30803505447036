import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { loadEnvInfo } from '../../Redux/Actions/envInfo';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAY_CMS_UI_TYPE, LOGIN_TYPE } from '../../Common/constant';
import { useLocation } from 'react-router-dom';
import './style.scss';
import Nophoto from '../../assets/image/no_user_photo.png';
import { CODE_MAP_DEFINED_API } from '../../Config/listConstants';

const LoginOtpOptions = [
  { value: LOGIN_TYPE.SYSTEM_ADMIN, label: '管理者モード', selected: true },
  { value: LOGIN_TYPE.OPERATOR, label: 'オペレーターモード', selected: false },
  { value: LOGIN_TYPE.AGENT, label: '会社モード', selected: false },
];

function CommonLayout(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [loginOptions, setLoginOptions] = React.useState(LoginOtpOptions);

  useEffect(() => {
    dispatch(loadEnvInfo({ pageIndex: 1, pageSize: 10000 }));
  }, [dispatch]);

  const envInfo = useSelector((state) => state.envInfo.data);
  const loginTitle = envInfo.find((item) => item.code === CODE_MAP_DEFINED_API.LOGIN_TITLE);
  const loginLogo = envInfo.find((item) => item.code === CODE_MAP_DEFINED_API.LOGIN_LOGO);
  const CmsTitle = envInfo.find((item) => item.code === CODE_MAP_DEFINED_API.CMS_TITLE_NAME);

  if (CmsTitle) {
    document.getElementsByTagName('title')[0].text = CmsTitle.content;
  }

  React.useState(() => {
    let options = [];

    switch (location.pathname) {
      case '/login_admin':
        options = [{}, { value: LOGIN_TYPE.SYSTEM_ADMIN, label: '管理者用管理画面', selected: true }, {}];
        break;
      case '/login_ope':
        options = [{}, { value: LOGIN_TYPE.OPERATOR, label: 'オペレーター用管理画面', selected: true }, {}];
        break;
      case '/login_agent':
        options = [{}, { value: LOGIN_TYPE.AGENT, label: '会社/店舗様用管理画面', selected: true }, {}];
        break;
      default:
        options = LoginOtpOptions;
        break;
    }

    setLoginOptions(options);
  }, []);

  const renderRadioOptions = (options) => {
    return (
      <div className="group">
        {options.map((option) => {
          return option.value >= 0 ? (
            <label
              key={option.label}
              className="radio-option"
            >
              <input
                name='needOtp'
                defaultChecked={option.selected}
                value={option.value}
                type="radio"
                onChange={onChange}
              />
              <span>{option.label}</span>
            </label>
          ) : <div />;
        })}
      </div>
    );
  };

  const onChange = (e) => {
    if (location.pathname === '/login_sys') props.setTypeLogin(Number(e.target.value));
  };

  return (
    <main>
      <CssBaseline />
      <Avatar
        style={{
          margin: 'auto',
          marginTop: 8,
          backgroundColor:
            loginLogo && loginLogo.type !== DISPLAY_CMS_UI_TYPE.HIDDEN
              ? ''
              : '#f50057',
          marginBottom: 8,
        }}
      >
        {loginLogo && loginLogo.type !== DISPLAY_CMS_UI_TYPE.HIDDEN ? (
          <img
            style={{
              textAlign: 'center',
              margin: 'auto',
              marginTop: 8,
              marginBottom: 8,
            }}
            className="imgRound"
            src={loginLogo.content ?? Nophoto}
            alt=""
          />
        ) : (
          <LockIcon />
        )}
      </Avatar>
      <Typography component="h1" variant="h5" align="center" style={{ marginBottom: 20, fontWeight: 'normal' }}>
        {loginTitle && loginTitle.type !== DISPLAY_CMS_UI_TYPE.HIDDEN
          ? loginTitle.content
          : 'Login'}
      </Typography>

      {renderRadioOptions(loginOptions)}
    </main>
  );
}

export default CommonLayout;
