import React, { useEffect, useState } from 'react';
import NonAuth from '../../../assets/image/non_auth.png';
import CropperDialog from '../../../Components/CropperDialog';
import * as imageJobApi from '../../../Api/imageJobApi';
import ImageJobEditModal from './ImageJobEditModal';
import Util from '../../../Util/Util';
import { toast } from 'react-toastify';
import { IMAGE_STATUS, IMAGE_TYPE } from '../../../Config/listConstants';
import { DIR_FOLDER_IMAGE } from '../../../Common/constant';
import FileUploadModal from '../../../Components/FileUploadModal';
import { TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL } from '../../../Common/modal_text_info';
import { AddImageButton, DeleteImageButton } from '../../../Components/IconImageAction';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { fileDataType } from '../../../Util/utils';


function ImageJobEdit({ job, loading, onUpdateData, subImages, main }) {
  const [previewImage, setPreviewImage] = useState(null);

  const [uploadingMain, setUploadingMain] = useState(false);
  const [croppingImage, setCroppingImage] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [indexLoadImageSub, setIndexLoadImageSub] = useState(-1);

  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic({ ...fileData, file: fileData.file }); // Trigger call data
      setShowDialogDragDrop(false);
    }
  }, [fileData]);

  const onClickAddImage = (payloadOption) => {
    setShowDialogDragDrop(true);
    setFileData(payloadOption);
  };

  const uploadStartLogic = (props) => {
    const { file, options } = props;
    const { isMain, key } = options;

    setUploadingMain(isMain);
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function() {
        setCroppingImage(reader.result);
        setShowCropper(true);
      },
      false,
    );
    if (file) {
      reader.readAsDataURL(file);
    }

    if (!isMain) {
      setIndexLoadImageSub(key);
    }
  };

  const onCropImage = (url) => {
    setShowCropper(false);
    const createImage = (uploadingMain ? imageJobApi.createImgMain : imageJobApi.createSubImageJob);
    const payload = {
      url,
      jobDetailId: job.id,
      ...uploadingMain && { type: IMAGE_TYPE.AVATAR },
      ...!uploadingMain && { type: IMAGE_TYPE.SUB_IMAGE, subImgNumber: (indexLoadImageSub + 1) },
    };

    createImage(payload).then(response => {
      const { data } = response;
      if (data.data || data.success) {
        if (uploadingMain) {
          if (main && main?.id) {
            handleDeleteImage(main);
          } else {
            onUpdateData();
          }
        } else {
          onUpdateData();
        }
      } else {
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      }
    }).catch(e => {
      toast.error(TOAST_MESSAGE.ERROR_ADD);
      // toast.error(e.response?.data?.message)
    });
  };

  const cancelCropImage = () => {
    setShowCropper(false);
    setCroppingImage(null);
  };

  const handleDeleteImage = (image) => {
    imageJobApi.deleteImageJob(image.id).then((response) => {
      const { data } = response;
      if (data.data || data.success) {
        imageJobApi.deleteImageJobStorage(Util.getFilePath(image.url)).then({});
        onUpdateData();
      } else {
        alert('更新に失敗しました');
      }
    }).catch(e => toast.error(e.response?.data?.message));
  };

  const handleShowImageModal = (image) => {
    setPreviewImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewImage(null);
  };

  return (
    <div className={`clearfix ${loading ? 'disabled' : ''}`}>
      <div className={'d-main-image'}
           style={{ position: 'relative', float: 'left', width: 160, height: 160, border: '1px solid #f4eaea' }}
      >
        {main && main?.url && (
          <img
            src={main.url}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            border={0}
            alt=""
            onClick={() => handleShowImageModal(main)}
          />
        )}
        {main && main.inspection === IMAGE_STATUS.PENDING && (
          <div className="image-watermark">
            <img src={NonAuth} alt="" />
          </div>
        )}
        {main && main?.url && (
          <DeleteImageButton onClick={() => handleDeleteImage(main)} />
        )}
        {!!(!main || main && !main?.url) && (
          <AddImageButton
            size={160}
            onClickAddImage={onClickAddImage}
            payloadOption={{ ...fileData, ...fileDataType, options: { isMain: true } }}
          />
        )}
      </div>
      <div className="sub-images"
           style={{ width: 'calc(100% - 160px)', display: 'flex', flexWrap: 'wrap', rowGap: 12 }}>
        {[0, 1, 2, 3, 4].map((index) => {
          const image = subImages.find((e) => e.subImgNumber === index + 1);
          return (
            <div
              key={index}
              className={`job-${index}`}
              style={{ position: 'relative', float: 'left', marginLeft: 15, border: '1px solid rgb(244, 234, 234)' }}
            >
              {image && (
                <img
                  src={image.url}
                  style={{ width: 70, height: 70, objectFit: 'cover' }}
                  border={0}
                  onClick={() => handleShowImageModal(image)}
                  alt=""
                />
              )}
              {image && image.inspection === IMAGE_STATUS.PENDING && (
                <div className="image-watermark">
                  <img src={NonAuth} alt="" width="16" />
                </div>
              )}
              {image && (
                <DeleteImageButton onClick={() => handleDeleteImage(image)} />
              )}
              {!image && (
                <AddImageButton
                  size={70}
                  onClickAddImage={onClickAddImage}
                  payloadOption={{ ...fileData, ...fileDataType, options: { isMain: false, key: index, } }}
                />
              )}
            </div>
          );
        })}
      </div>
      {showCropper && (
        <CropperDialog
          image={croppingImage}
          onCrop={onCropImage}
          onClose={cancelCropImage}
          dir={`${DIR_FOLDER_IMAGE.JOB_DETAIL}/${job.id}`}
          type={'job_management_image_job'}
        />
      )}
      {previewImage && (
        <ImageJobEditModal
          image={previewImage}
          onClose={handleHideImageModal}
          onUpdate={onUpdateData}
        />
      )}

      {showDialogDragDrop && (
        <FileUploadModal showDialogDragDrop={showDialogDragDrop}
                         setShowDialogDragDrop={setShowDialogDragDrop}
                         setFileData={setFileData}
                         fileData={fileData}
                         dataInfoHeadText={TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL}
        />
      )}
    </div>
  );
}

export default ImageJobEdit;
