import { handleActions } from 'redux-actions';
import ScheduleSms from '../Types/scheduleSms';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [ScheduleSms.LOAD_SCHEDULE_SMS]: (state) => ({
    ...state,
    loading: true,
    loadingReceivers: false,
  }),
  [ScheduleSms.LOAD_SCHEDULE_SMS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ScheduleSms.LOAD_SCHEDULE_SMS_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
  [ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS]: (state) => ({
    ...state,
    loadingReceivers: true,
  }),
  [ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingReceivers: false,
  }),
  [ScheduleSms.ESTIMATE_SCHEDULE_SMS_RECEIVERS_FAIL]: (state) => ({
    ...state,
    items: [],
    loadingReceivers: false,
  }),
};
export default handleActions(actions, initialState);
