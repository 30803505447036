import { handleActions } from 'redux-actions';
import envUISidenav from '../Types/envUISidenav';

const initialState = {
  data: [],
  loading: false,
  totalRow: 0,
  updating: false,
  success: false,
};

const actions = {
  [envUISidenav.LOAD_ENVUISIDENAV]: (state) => ({
    ...state,
    loading: true,
  }),
  [envUISidenav.LOAD_ENVUISIDENAV_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [envUISidenav.LOAD_ENVUISIDENAV_FAIL]: (state) => ({
    ...state,
    data: [],
    loading: false,
  }),
  [envUISidenav.UPDATE_ENVUISIDENAV]: (state) => ({
    ...state,
    updating: true,
  }),
  [envUISidenav.UPDATE_ENVUISIDENAV_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [envUISidenav.UPDATE_ENVUISIDENAV_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
