const Information = {
  LOAD_INFORMATION: 'LOAD_INFORMATION',
  LOAD_INFORMATION_SUCCESS: 'LOAD_INFORMATION_SUCCESS',
  LOAD_INFORMATION_FAIL: 'LOAD_INFORMATION_FAIL',

  LOAD_INFORMATIONS: 'LOAD_INFORMATIONS',
  LOAD_INFORMATIONS_SUCCESS: 'LOAD_INFORMATIONS_SUCCESS',
  LOAD_INFORMATIONS_FAIL: 'LOAD_INFORMATIONS_FAIL',

  UPDATE_INFORMATION: 'UPDATE_INFORMATION',
  UPDATE_INFORMATION_SUCCESS: 'UPDATE_INFORMATION_SUCCESS',
  UPDATE_INFORMATION_FAIL: 'UPDATE_INFORMATION_FAIL',

};
export default Information;
