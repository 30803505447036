import { call, put, takeLatest } from 'redux-saga/effects';
import { getListChat } from '../../Api/database';
import { loadListChatFail, loadListChatSuccess } from '../Actions/listChat';
import ListChat from '../Types/listChat';

function* handleLoadListChat(action) {
  try {
    const response = yield call(getListChat, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListChatSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListChatFail(error));
  }
}

export default function* listChatSaga() {
  yield takeLatest(ListChat.LOAD_LIST_CHAT, handleLoadListChat);
}
