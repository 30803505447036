import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import ChatAdminLogDetailModal from './ChatAdminLogDetailModal';
import ChatAdminLogList from './ChatAdminLogList';
import ChatAdminSearchBox from './ChatAdminSearchBox';
import { getMessages } from '../../Api/chatAdminApi';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function Index() {
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'message.createdDate': 'DESC' },
  });
  const [chatDetail, setChatDetail] = useState(null);
  const [messageAdmin, setMessageAdmin] = useState({ data: [], totalRow: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const handleClickDetail = (chat) => {
    setChatDetail(chat);
  };

  const handleClose = () => {
    setChatDetail(null);
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    onLoadMessages();
  }, [filter]);

  const onLoadMessages = async () => {
    setIsLoading(true);

    try {
      const result = await getMessages({ ...filter, pageIndex, pageSize });
      if (result.status === 200) {
        setMessageAdmin({ data: result.data.data, totalRow: result.data.totalRow });
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const refreshList = () => onLoadMessages();

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
    onLoadMessages();
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    onLoadMessages();
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read

    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const reload = () => {
    setPageIndex(1);
    onLoadMessages();
  };

  const onClear = () => setFilter({ sort: { 'message.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={isLoading} />

      {messageAdmin.data && (
        <>
          <ChatAdminSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />

          {chatDetail && (
            <ChatAdminLogDetailModal
              onClose={handleClose}
              chatLog={chatDetail}
              reload={reload}
              labelDb={labelDb}
            />
          )}
          <ChatAdminLogList
            chatLog={messageAdmin.data}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onUpdate={refreshList}
            // for sorting
            filter={filter}
            onSort={onSort}
            handleClickDetail={handleClickDetail}
            labelDb={labelDb}
          />
          <center>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRow={messageAdmin.totalRow}
              onPageChange={handlePageChange}
            />
          </center>
        </>
      )}
    </div>
  );
}

export default Index;
