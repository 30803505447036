import { call, put, takeLatest } from 'redux-saga/effects';
import TimerMail from '../Types/timerMail';
import { getAllTimerMail } from '../../Api/userAPI';
import { loadTimerMailFail, loadTimerMailSuccess } from '../Actions/timerMail';

function* handleLoadTimerMail(action) {
  try {
    const response = yield call(getAllTimerMail, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTimerMailSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTimerMailFail(error));
  }
}

export default function* timerMailSaga() {
  yield takeLatest(TimerMail.LOAD_TIMERMAIL, handleLoadTimerMail);
}
