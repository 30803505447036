import React, { useEffect, useState } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import { loadReports } from '../../Redux/Actions/reports';
import ReportList from './ReportList';
import Pagination from '../../Components/Pagination';
import SearchBox from './SearchBox';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function Index() {
  const [timestamp, setTimestamp] = React.useState(new Date().getTime());
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.reports.loading);
  const reports = useSelector((state) => state.reports.items);
  const totalRow = useSelector((state) => state.reports.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const [filter, setFilter] = useState({
    sort: { 'report.createdDate': 'DESC' },
  });
  const dispatch = useDispatch();

  const reload = () => setTimestamp(new Date().getTime());

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);


  useEffect(() => {
    dispatch(loadReports({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter, timestamp]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: {} });
    setPageIndex(1);
    dispatch(loadReports({ pageIndex, pageSize: 1, ...filter }));
  };

  return (
    <div>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />

      <LoadingBar show={loading} />
      <ReportList
        reports={reports}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        reload={reload}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
