import { sendPost, sendPostFile } from './axios';

export const uploadFile = (payload) => sendPost('/upload', payload);

export const fetchFolderList = (params) => sendPost('/upload/list-file', params);

export const removeFileBuild = (params) => sendPost(`/upload/remove-file`, params);

export const uploadImage = (formData, params) => sendPostFile(`/image/upload`, formData, params);

export const removeFileContentsImageWeb = (params) => sendPost(`/remove-file/contents-image-web`, params);