import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import ChildStateList from './ChildStateList';
import RootNameStateList from './RootNameStateList';
import { loadAllArea } from '../../Redux/Actions/nameState';

function NameStateList({ nameState = [], loading, setIsEditing }) {
  const [dataNameState, setDataNameState] = useState([]);
  const [dataChildState, setDataChildState] = useState([]);
  const [isEditingChild, setIsEditingChild] = useState(false);
  const [isEditingRoot, setIsEditingRoot] = useState(false);
  const childResource = useSelector((state) => state.nameState.dataChild);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataNameState(nameState);
  }, [nameState]);

  useEffect(() => {
    setDataChildState(childResource);
  }, [childResource]);

  const [ChildState, setChildState] = useState(null);
  const [RootNameState, setRootNameState] = useState(null);

  const handleDetailRootNameState = () => {
    setRootNameState(true);
    setIsEditingChild(false);
    setChildState(null);
  };

  const handleClickDetail = (stateId) => {
    dispatch(loadAllArea(stateId));
    setChildState(stateId);
    setRootNameState(false);
  };

  const changeDataChildState = (data) => {
    setChildState(data);
  };

  const handleEditChildState = () => {
    setIsEditingChild(true);
  };

  const handleCloseEditChildState = () => {
    setIsEditingChild(false);
  };

  const handleEditRootNameState = () => {
    setIsEditingRoot(true);
  };
  const handleCloseEditRootNameState = () => {
    setIsEditingRoot(false);
  };
  const changeDataRootNameState = (data) => {
    setDataNameState(data); // Change data update after fetch api
    setRootNameState(data);
  };

  const showNameState = (dataNameState) => {
    if (dataNameState && dataNameState.length < 25) {
      dataNameState.push({ id: '', label: '' });
    }
    return (dataNameState || []).map((item) => (
      <TableRow>
        <TableCell>{item.id}</TableCell>
        <TableCell>{item.label}</TableCell>
        <TableCell>
          {item.id && (
            <Button
              className="btnEditProfile"
              variant="contained"
              onClick={() => handleClickDetail(item.id)}
            >
              表示
            </Button>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <div className="m-2 mt-3 mb-3">
        <span style={{ marginRight: 105 }}>大項目</span>
        <span style={{ marginRight: 150 }}>項目名設定</span>
        <Button
          className="btnEditRootProfile"
          variant="contained"
          onClick={handleDetailRootNameState}
        >
          表示
        </Button>
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card style={{ display: 'flex', overflowX: 'hidden' }}>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>都道府県No 00～24</TableCell>
                <TableCell>00 ～ 24</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {showNameState((dataNameState || []).slice(0, 25))}
            </TableBody>
          </Table>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>都道府県No 25～99</TableCell>
                <TableCell>25 ～ 99</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {showNameState((dataNameState || []).slice(25))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      {ChildState && (
        <ChildStateList
          dataChildState={dataChildState}
          handleEdit={handleEditChildState}
          onClose={handleCloseEditChildState}
          isEditing={isEditingChild}
          changeDataChildState={changeDataChildState}
        />
      )}
      {RootNameState && (
        <RootNameStateList
          dataRootNameState={dataNameState}
          handleEdit={handleEditRootNameState}
          onClose={handleCloseEditRootNameState}
          isEditing={isEditingRoot}
          changeDataRootNameState={changeDataRootNameState}
        />
      )}
    </>
  );
}

export default NameStateList;
