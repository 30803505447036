import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  MuiThemeProvider,
  TextareaAutosize,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import { getHelpByLink, updateHelp } from '../../../Api/helpAPI';
import { toast } from 'react-toastify';
import { themeStyleNormal1Modal } from '../theme_modal';
import { TEXT_HTML_HELP_MODAL } from '../../../Common/modal_text_info';
import { TOAST_MESSAGE } from '../../../Common/constant_text';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function HelpModal({ visible, onClose, user, labelDb }) {
  // let request = user;
  let userId = 'not thing';
  if (user) {
    userId = user.id;
  }
  const classes = useStyles();
  const [help, setHelp] = useState({});
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    async function getHelp() {
      // format current href
      let link = window.location.pathname;
      if (link.includes(userId)) {
        link = link.replace(userId, ':id');
      }
      let response = await getHelpByLink({ link: link });
      setHelp(response.data.data[0]);
    }

    getHelp();
  }, [isEdit]);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  const onChange = (event) => {
    help[event.target.name] = event.target.value;
    setHelp(help);
  };

  const handleConfirm = () => {
    setConFirmVisible(true);
  };

  const handleSubmit = async () => {
    await updateHelp({ ...help })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          setIsEdit(false);
          setConFirmVisible(false);
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        setConFirmVisible(false);
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              実行してよろしいですか？
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        style={{ paddingBottom: '20px' }}
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'mb-0'}>ヘルプ</p>
            <div style={{ textAlign: 'right', marginTop: 10 }}>
              {isEdit ? (
                <Button
                  onClick={handleConfirm}
                  className="btn-custom btn-blue"
                >
                  更新
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-custom btn-blue"
                  onClick={handleEdit}
                >
                  編集
                </Button>
              )}
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_HELP_MODAL }}></div>
        </div>

        <DialogContent >
          <MuiThemeProvider theme={themeStyleNormal1Modal}>
            <Card>
              <Row style={{ padding: 20, minHeight: 250 }}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <FormControl className={classes.formControl}>
                        {isEdit ? (
                          <TextareaAutosize name="content" onChange={onChange}>
                            {help ? help.content : 'There is no help yet'}
                          </TextareaAutosize>
                        ) : (
                          <Label
                            style={{ height: 'auto', whiteSpace: 'pre-line' }}
                          >
                            {help ? help.content : 'There is no help yet'}{' '}
                          </Label>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HelpModal;
