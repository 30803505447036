import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const createAgent = (agent) => sendPost('/cms/agent/create', agent);
export const getAgent = (id) => sendGet(`/cms/agent/${id}`);
export const getAgents = (filter = {}) => {
  return sendPost('/cms/agents', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};

export const getAgentIds = (filter = {}) => {
  return sendPost('/cms/list-agent-id', filter);
};

export const updateAgent = (agent) => sendPut(`/cms/agent/${agent.id}`, agent);

export const updateAgentLp = (agent) => sendPut(`/cms/agent/lp/${agent.id}`, agent);

export const removeAgent = (id) => sendDelete(`/cms/agent/${id}`);

export const getCharaByAgent = (filter = {}) => {
  return sendGet(`/cms/characters/list-by-agent`, filter);
};

export const getListChara = () => sendGet(`/cms/characters/list-all`);

export const sendEmailAgent = (data) => {
  return sendPost(`/cms/send-email-agent/${data.id}`, {
    title: data.title,
    content: data.content,
    from_mail_address: data.from_mail_address,
    force_send: data?.force_send || false,
  });
};

export const setTimeMailAgent = (data) => sendPost(`/cms/set-time-mail-agent`, data);

export const addPointAgent = (params) => {
  return sendPost('/cms/agent/add-point', params);
};

export const entryPailPlanAgent = (params) => {
  return sendPost('/cms/agent/entry-paid-plan', params);
};

export const buyPointAgent = (params) => {
  return sendPost('/payment/buy-point-agent', params);
};
