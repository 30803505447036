import { call, put, takeLatest } from 'redux-saga/effects';
import TimerNoti from '../Types/timerNoti';
import { getAllTimerNoti } from '../../Api/userAPI';
import { loadTimerNotiFail, loadTimerNotiSuccess } from '../Actions/timerNoti';

function* handleLoadTimerNoti(action) {
  try {
    const response = yield call(getAllTimerNoti, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTimerNotiSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTimerNotiFail(error));
  }
}

export default function* timerNotiSaga() {
  yield takeLatest(TimerNoti.LOAD_TIMERNOTI, handleLoadTimerNoti);
}
