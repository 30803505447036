import React, { useEffect, useState } from 'react';
import NonAuth from '../../../assets/image/non_auth.png';
import CropperDialog from '../../../Components/CropperDialog';
import * as imageApi from '../../../Api/imageApi';
import ImageModal from './ImageModal';
import Util from '../../../Util/Util';
import { DIR_FOLDER_IMAGE } from '../../../Common/constant';
import { IMAGE_STATUS } from '../../../Config/listConstants';
import FileUploadModal from '../../../Components/FileUploadModal';
import { TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL } from '../../../Common/modal_text_info';
import { AddImageButton, DeleteImageButton } from '../../../Components/IconImageAction';
import { fileDataType } from '../../../Util/utils';


function ImageList({ agent, loading, onUpdateData }) {
  const avatar = agent?.avatar;
  const subImages = agent?.images.sort((a, b) => a.subImgNumber - b.subImgNumber);

  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [croppingImage, setCroppingImage] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [previewingImage, setPreviewingImage] = useState(null);
  const [indexLoadImageSub, setIndexLoadImageSub] = useState(-1);

  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic({ ...fileData, file: fileData.file }); // Trigger call data
      setShowDialogDragDrop(false);
    }
  }, [fileData]);

  const onClickAddImage = (payloadOption) => {
    setShowDialogDragDrop(true);
    setFileData(payloadOption);
  };

  const uploadStartLogic = (props) => {
    const { file, options } = props;
    const { isAvatar, key } = options;

    setUploadingAvatar(isAvatar);
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function() {
        setCroppingImage(reader.result);
        setShowCropper(true);
      },
      false,
    );
    if (file) {
      reader.readAsDataURL(file);
    }
    if (!isAvatar) setIndexLoadImageSub(key);
  };

  const onCropImage = (url) => {
    setShowCropper(false);
    const createImage = uploadingAvatar
      ? imageApi.createAvatarAgent
      : imageApi.createSubImagesAgent;
    createImage({
      url,
      memberAgentId: agent.id,
      subImgNumber: indexLoadImageSub + 1,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onUpdateData();
      } else {
        alert('更新に失敗しました');
      }
    });
  };
  const cancelCropImage = () => {
    setShowCropper(false);
    setCroppingImage(null);
  };

  const handleDeleteImage = (image) => {
    imageApi.deleteImageAgent(image.id).then((response) => {
      const { data } = response;
      if (data.success) {
        imageApi.deleteImageStorage(Util.getFilePath(image.url)).then({});
        onUpdateData();
      } else {
        alert('更新に失敗しました');
      }
    });
  };

  const handleShowImageModal = (image) => {
    setPreviewingImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  return (
    <div className={`clearfix ${loading ? 'disabled' : ''}`}>
      <div className={'d-main-image'}
           style={{ position: 'relative', float: 'left', width: 160, height: 160 }}
      >
        {avatar && (
          <img
            src={avatar.url}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            border={0}
            alt=""
            onClick={() => handleShowImageModal(avatar)}
          />
        )}
        {avatar && avatar.inspection === IMAGE_STATUS.PENDING && (
          <div className="image-watermark">
            <img src={NonAuth} alt="" />
          </div>
        )}
        {avatar && (
          <DeleteImageButton onClick={() => handleDeleteImage(avatar)} />
        )}
        {!avatar && (
          <AddImageButton
            size={160}
            onClickAddImage={onClickAddImage}
            payloadOption={{ ...fileData, ...fileDataType, options: { isAvatar: true } }}
          />
        )}
      </div>
      <div className="sub-images">
        {[0, 1, 2, 3, 4].map((index) => {
          let image;
          if (subImages) {
            image = subImages.find((e) => e.subImgNumber === index + 1);
          }

          return (
            <div
              key={index}
              style={{ position: 'relative', float: 'left', marginLeft: 15 }}
            >
              {image && (<img
                  src={image.url}
                  style={{ width: 70, height: 70, objectFit: 'cover' }}
                  border={0}
                  onClick={() => handleShowImageModal(image)}
                  alt=""
                />
              )}
              {image && image.inspection === IMAGE_STATUS.PENDING && (
                <div className="image-watermark">
                  <img src={NonAuth} alt="" width="16" />
                </div>
              )}
              {image && (
                <DeleteImageButton onClick={() => handleDeleteImage(image)} />
              )}
              {!image && (
                <AddImageButton
                  size={70}
                  onClickAddImage={onClickAddImage}
                  payloadOption={{ ...fileData, ...fileDataType, options: { isAvatar: false, key: index } }}
                />
              )}
            </div>
          );
        })}
      </div>
      {showCropper && (
        <CropperDialog
          image={croppingImage}
          onCrop={onCropImage}
          onClose={cancelCropImage}
          dir={`${DIR_FOLDER_IMAGE.PROFILE_AGENT}/${agent?.id}`}
          type={'agent_edit_image_list'}
        />
      )}
      {previewingImage && (
        <ImageModal
          image={previewingImage}
          onClose={handleHideImageModal}
          onUpdate={onUpdateData}
        />
      )}

      {showDialogDragDrop && (
        <FileUploadModal showDialogDragDrop={showDialogDragDrop}
                         setShowDialogDragDrop={setShowDialogDragDrop}
                         setFileData={setFileData}
                         fileData={fileData}
                         dataInfoHeadText={TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL}
        />
      )}
    </div>
  );
}

export default ImageList;
