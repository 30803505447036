import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, MuiThemeProvider, Card } from '@material-ui/core';
import { loadListRawOtp } from '../../Redux/Actions/listRawDatabase';
import isEmpty from 'lodash/isEmpty';
import Util from '../../Util/Util';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import Pagination from '../../Components/Pagination';

const pageSize = 50;

function Index() {
  const otp = useSelector((state) => state.listRawDatabase.otp);
  const totalOtp = useSelector((state) => state.listRawDatabase.totalOtp);
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'otp.updatedDate': 'DESC' },
  });

  useEffect(() => {
    dispatch(loadListRawOtp({ ...filter, pageIndex: pageIndex, pageSize }));
  }, [dispatch, filter, pageIndex]);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadListRawOtp({ ...filter, pageIndex, pageSize }));
  };

  const onClear = () => setFilter({ sort: { 'otp.updatedDate': 'DESC' } });

  
  return (
    <>
      <React.Fragment>
        <div className="space-top">&nbsp;</div>
        <div className="space-left">&nbsp;</div>
        <MuiThemeProvider theme={tableNewTheme}>
        <Card className="mt-3 scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>MemberId</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="Phone"
                    name="otp.phone"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Result/0:Delivered/1:Failed/11:Unknown/12:Rejected</TableCell>
                <TableCell>OtpSend/0:送信/1:擬送信</TableCell>
                <TableCell>CreatedDate</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="UpdatedDate"
                    name="otp.updatedDate"
                    onClick={onSort}
                  />
                </TableCell>
              </TableRow>

              {otp.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x.memberId !== null ? x.memberId : null}</span>
                  </TableCell>
                  <TableCell>
                    {x.phone !== null ? x.phone : null}
                  </TableCell>
                  <TableCell>{x.code}</TableCell>
                  <TableCell>{x.status}</TableCell>
                  <TableCell>{x.type}</TableCell>
                  <TableCell>{x.network}</TableCell>
                  <TableCell>{x.result}</TableCell>
                  <TableCell>{x.otpSend}</TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD')}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      </React.Fragment>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalOtp}
          onPageChange={handlePageChange}
        />
      </center>
    </>
  );
}

export default Index;
