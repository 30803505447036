import { call, put, takeLatest } from 'redux-saga/effects';
import PointRate from '../Types/pointRate';
import { getPointRate, updatePointRate } from '../../Api/pointRateAPI';
import { toast } from 'react-toastify';
import {
  loadPointRateFail,
  loadPointRateSuccess,
  updatePointRateFail,
  updatePointRateSuccess,
} from '../Actions/pointRate';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadPointRate(action) {
  try {
    const response = yield call(getPointRate, action.payload);
    const { data } = response;
    if (data.success) {
      const pointRate = data;
      yield put(loadPointRateSuccess(pointRate));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadPointRateFail(error));
  }
}

function* handleUpdatePointRate(action) {
  try {
    const response = yield call(updatePointRate, action.payload);
    const { data } = response;
    if (data.success) {
      const pointRate = data.data;
      yield put(updatePointRateSuccess(pointRate));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updatePointRateFail(error));
  }
}

export default function* pointRateSaga() {
  yield takeLatest(PointRate.LOAD_POINT_RATE, handleLoadPointRate);
  yield takeLatest(PointRate.UPDATE_POINT_RATE, handleUpdatePointRate);
}
