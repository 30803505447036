import React from 'react';

function RadioCheckboxGroup(props) {
  const { name, defaultValue, options, onChange, disabled, checked = [], classCustom = '' } = props;

  return (
    <div className={`radio-group ${classCustom ?? ''}`}>
      {options.map((option) => (
        <label
          key={option.label}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: '1',
            marginBottom: '0',
          }}
          className={`${classCustom ? classCustom + '-child' : ''}`}
        >
          <input
            name={name}
            value={option.value}
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={
              checked.indexOf(option.value) > -1 ||
              (option.value === '' && checked.length === 4)
            }
          />
          <span>
            {
              option?.link ?
              <span>
                {option.label}
                <a style={{ color: 'black', textDecoration: 'none', marginLeft: 20 }} href={option?.link} target='_blank'>
                  規約はこちら
                </a>
              </span>
              : option.label
            } 
          </span>
        </label>
      ))}
    </div>
  );
}

export default RadioCheckboxGroup;
