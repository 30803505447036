import React from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteButton from '../assets/image/delete.png';

export const AddImageButton = (props) => {
  const { size, payloadOption, onClickAddImage } = props;

  return (
    <label>
      <AddBoxIcon onClick={() => onClickAddImage(payloadOption)}
                  color="action"
                  style={{
                    width: size,
                    height: size,
                  }}
      />
    </label>
  );
};

export const DeleteImageButton = ({ onClick }) => {
  return (
    <img
      src={DeleteButton}
      onClick={onClick}
      style={{
        position: 'absolute',
        top: -10,
        right: -10,
        width: 18,
        height: 18,
        backgroundColor: 'white',
        borderRadius: '50%',
        zIndex: 1001,
        cursor: 'pointer'
      }}
      alt=""
    />
  );
};
