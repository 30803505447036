import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '../../../Components/Pagination';
import { loadTwilio } from '../../../Redux/Actions/twilio';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import SortArrows from '../../../Components/SortArrows';
import LoadingBar from '../../../Components/LoadingBar';
import CloseIcon from '@material-ui/icons/Close';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_TWILIO_HISTORY_MODAL } from '../../../Common/modal_text_info';
import {
  convertTwilioReceiverStatusTypeText,
  convertTwilioResultTypeText,
  convertTwilioStatusTypeText,
} from '../../../Util/utils';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';

const pageSize = 50;

function TwilioHistoryModal({ visible, onClose, userId, labelDb }) {
  const [filter, setFilter] = useState({
    sender_id: userId,
    sort: { 'twiliohistory.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.twilio.loading);
  const twilio = useSelector((state) => state.twilio.items);
  const totalRow = useSelector((state) => state.twilio.totalRow);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTwilio({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadTwilio({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <>
      <MuiThemeProvider theme={themeNormalModal}>
        <div style={{ position: 'relative' }}>
          <LoadingBar show={loading} />
          <>
            <Dialog
              fullWidth={true}
              maxWidth={'xl'}
              open={visible}
              onClose={onClose}
              aria-labelledby="max-width-dialog-title"
              className={'d-modal-wrapper'}
            >
              <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
                ユーザー詳細-会話ログ
              </DialogTitle>
              <Button
                style={{ position: 'absolute', top: 10, right: 0 }}
                onClick={onClose}
              >
                <CloseIcon color="primary" />
              </Button>
              <div className={'d-title-info-box'}>
                <div className={'text mb-0'}
                     dangerouslySetInnerHTML={{ __html: TEXT_HTML_TWILIO_HISTORY_MODAL }}></div>
              </div>

              <DialogContent className={'table-modal-wrapper'}>
                <Card className="scroll-x-on-top">
                  <Table padding="checkbox" className="d-table-modal mb-0">
                    <TableBody>
                      <TableRow>
                        <TableCell className="d-width-no">No</TableCell>
                        <TableCell className="d-width-phone">電話番号</TableCell>
                        <TableCell>画像</TableCell>
                        <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                        <TableCell>日時</TableCell>
                        <TableCell>
                          <SortArrows
                            sortObject={filter.sort}
                            text="発信Status"
                            name="twiliohistory.status"
                            onClick={onSort}
                          />
                        </TableCell>
                        <TableCell>T Result通話結果</TableCell>
                        <TableCell>受信者応答</TableCell>
                        <TableCell>通話時間</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Point rate</TableCell>
                        <TableCell>一時預かりP</TableCell>
                        <TableCell>利用P</TableCell>
                        <TableCell>戻しP</TableCell>
                      </TableRow>

                      {twilio.map((x, index) => (
                        <TableRow key={x.id}>
                          <TableCell>
                            {(pageIndex - 1) * pageSize + index + 1}
                          </TableCell>
                          <TableCell>
                            <span>{x?.receiver?.id !== userId ? x?.receiver?.phoneNumber : x?.sender?.phoneNumber}</span>
                          </TableCell>
                          <TableCell className="text-center">
                            {
                              x?.receiver?.id !== userId ?
                                <Link
                                  to={`/user/${x?.receiver?.id}/detail`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() => saveLocalStore(x?.receiver?.id)}
                                >
                                  <img
                                    className="imgRound"
                                    src={
                                      x?.receiver?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                      x?.receiver?.avatar && x?.receiver?.avatar?.url
                                        ? x?.receiver?.avatar?.url
                                        : NoPhoto
                                    }
                                    alt=""
                                  />
                                </Link> :
                                <Link
                                  to={`/user/${x?.sender?.id}/detail`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() => saveLocalStore(x?.sender?.id)}
                                >
                                  <img
                                    className="imgRound"
                                    src={
                                      x?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                      x?.sender?.avatar && x?.sender?.avatar?.url
                                        ? x?.sender?.avatar?.url
                                        : NoPhoto
                                    }
                                    alt=""
                                  />
                                </Link>

                            }
                          </TableCell>
                          <TableCell>
                            {x?.receiver?.id !== userId ? x?.receiver?.nickName : x?.sender?.nickName}
                          </TableCell>
                          <TableCell>
                            {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                          </TableCell>
                          <TableCell>
                            {convertTwilioStatusTypeText(x.status)}
                          </TableCell>
                          <TableCell>
                            {convertTwilioResultTypeText(x.twilioResult)}
                          </TableCell>
                          <TableCell>
                            {convertTwilioReceiverStatusTypeText(x.receiverStatus)}
                          </TableCell>
                          <TableCell>
                            {x.durationCallTime !== null ? x.durationCallTime : null}
                          </TableCell>
                          <TableCell>
                            {Util.timestampToDate(x.startTime, 'YYYY/MM/DD HH:mm:ss')}
                          </TableCell>
                          <TableCell>
                            {Util.timestampToDate(x.endTime, 'YYYY/MM/DD HH:mm:ss')}
                          </TableCell>
                          <TableCell>
                            {x?.pointRate}
                          </TableCell>
                          <TableCell>
                            {x?.depositPoint}
                          </TableCell>
                          <TableCell>
                            {x?.usagePoint}
                          </TableCell>
                          <TableCell>
                            {x?.returnPoint}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>

                <center>
                  <Pagination
                    className={'d-page-wrapper'}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalRow={totalRow}
                    onPageChange={handlePageChange}
                  />
                </center>
              </DialogContent>
            </Dialog>
          </>
        </div>
      </MuiThemeProvider>
    </>
  );
}

export default TwilioHistoryModal;
