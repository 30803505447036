const EnvTopNavWeb = {
  LOAD_ENV_TOP_NAV_WEB: 'LOAD_ENV_TOP_NAV_WEB',
  LOAD_ENV_TOP_NAV_WEB_SUCCESS: 'LOAD_ENV_TOP_NAV_WEB_SUCCESS',
  LOAD_ENV_TOP_NAV_WEB_FAIL: 'LOAD_ENV_TOP_NAV_WEB_FAIL',
  UPDATE_ENV_TOP_NAV_WEB: 'UPDATE_ENV_TOP_NAV_WEB',
  UPDATE_ENV_TOP_NAV_WEB_SUCCESS: 'UPDATE_ENV_TOP_NAV_WEB_SUCCESS',
  UPDATE_ENV_TOP_NAV_WEB_FAIL: 'UPDATE_ENV_TOP_NAV_WEB_FAIL',
};

export default EnvTopNavWeb;
