import { handleActions } from 'redux-actions';
import PointRate from '../Types/pointRate';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
};

const actions = {
  [PointRate.LOAD_POINT_RATE]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [PointRate.LOAD_POINT_RATE_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    totalRow: action.payload.totalRow,
    loading: false,
    success: true,
  }),
  [PointRate.LOAD_POINT_RATE_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [PointRate.UPDATE_POINT_RATE]: (state) => ({
    ...state,
    updating: true,
  }),
  [PointRate.UPDATE_POINT_RATE_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [PointRate.UPDATE_POINT_RATE_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
