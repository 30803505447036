import { call, put, takeLatest } from 'redux-saga/effects';
import FootprintsToMe from '../Types/footprintUserToMe';
import { getFootprintsToMe } from '../../Api/footprintLogApi';
import { loadFootprintsToMeFail, loadFootprintsToMeSuccess } from '../Actions/footprintUserToMe';

function* handleLoadFavoriteUserToMe(action) {
  try {
    const response = yield call(getFootprintsToMe, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFootprintsToMeSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFootprintsToMeFail(error));
  }
}

export default function* favoritesToMeSaga() {
  yield takeLatest(
    FootprintsToMe.LOAD_FOOTPRINTS_TO_ME,
    handleLoadFavoriteUserToMe,
  );
}
