import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import { loadRootResourceAgent } from '../../Redux/Actions/resourceAgent';
import NameProfileAgentList from './NameProfileAgentList';

function NameProfileAgent() {
  const loadingRoot = useSelector((state) => state.resourceAgent.loadingRoot);
  const rootResource = useSelector((state) => state.resourceAgent.dataRoot);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadRootResourceAgent());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loadingRoot || false} />
      <NameProfileAgentList rootResource={rootResource} loading={loadingRoot} />
    </div>
  );
}
export default NameProfileAgent;
