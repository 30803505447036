import { sendGet, sendPut } from './axios';

export const getTextWebPages = (filter) => {
  return sendGet('/cms/text-web-pages', { ...filter }, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
  });
};

// Update data by array
export const updateTextWebPages = (payload) => sendPut(`/cms/update/text-web-page`, payload);
