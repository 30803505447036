import { call, put, takeLatest } from 'redux-saga/effects';
import Block from '../Types/block';
import { getBlockSaga } from '../../Api/blockAPI';
import { loadBlockFail, loadBlockSuccess } from '../Actions/block';

function* handleLoadBlock(action) {
  try {
    if (Object.keys(action.payload.sort).length == 0) {
      action.payload.sort = { 'blocks.createdDate': 'DESC' };

    }
    const response = yield call(getBlockSaga, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadBlockSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadBlockFail(error));
  }
}

export default function* blockSaga() {
  yield takeLatest(Block.LOAD_BLOCK, handleLoadBlock);
}
