import { createAction } from 'redux-actions';
import NameRank from '../Types/nameRank';

export const loadNameRank = createAction(NameRank.LOAD_NAME_RANK);
export const loadNameRankSuccess = createAction(NameRank.LOAD_NAME_RANK_SUCCESS);
export const loadNameRankFail = createAction(NameRank.LOAD_NAME_RANK_FAIL);

export const loadListNameRank = createAction(NameRank.LOAD_LIST_NAME_RANK);
export const loadListNameRankSuccess = createAction(NameRank.LOAD_LIST_NAME_RANK_SUCCESS);
export const loadListNameRankFail = createAction(NameRank.LOAD_LIST_NAME_RANK_FAIL);

export const updateNameRank = createAction(NameRank.UPDATE_NAME_RANK);
export const updateNameRankSuccess = createAction(NameRank.UPDATE_NAME_RANK_SUCCESS);
export const updateNameRankFail = createAction(NameRank.UPDATE_NAME_RANK_FAIL);
