import { createMuiTheme, Dialog, DialogContent, DialogTitle, FormLabel, MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import { removeAgent, updateAgentLp } from '../../../Api/agentAPI';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE, REPLIED_STATUS } from '../../../Common/constant';
import { CODE_MAP_DEFINED_API, LINK_MAP_DEFINED_API, PROFILE_TYPE } from '../../../Config/listConstants';
import { loadAgent, loadAgentUpdateLocal } from '../../../Redux/Actions/agent';
import { loadCharacter } from '../../../Redux/Actions/character';
import { loadResourceAgent } from '../../../Redux/Actions/resourceAgent';
import Util from '../../../Util/Util';
import ImageList from './ImageList';
import RemoveAgentModal from './RemoveAgentModal';
import CloseIcon from '@material-ui/icons/Close';
import { getUsers } from '../../../Api/userAPI';
import configs from '../../../Config/config';
import {
  accessAndPassDataIframe,
  convertEmailAddressMainStatusText,
  convertEnvInfo,
  memberAgentTypeArrayText,
  showToastErrorByCodeApi,
} from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import SendEmailAgentModal from './SendEmailAgentModal';
import PaymentAgentModal from './PaymentAgentModal';
import StripeAgentModal from './StripeAgentModal';
import CheckoutBalancePayment from './CheckoutBalancePayment';
import PaymentLogJobModel from './PaymentLogJobModel';
import EntryPaidPlanModal from './EntryPaidPlanModal';
import FavoriteJobAgentModal from './FavoriteJobAgentModal';
import ChatRoomAdminModal from './ChatRoomAdmin';
import { createChatRoomAdmin, getChatRoomById, getChatRoomByIdAgent } from '../../../Api/chatAdminApi';
import { counterUnReadMessageAgent } from '../../../Api/chatAgentAPI';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const btnDangerStyle = {
  marginLeft: 25,
  fontSize: 14,
  fontWeight: 300,
  color: 'white',
  position: 'absolute',
  bottom: 10,
  right: 16,
};

function Index() {
  const params = useParams();
  const agentId = params.agentId;
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const location = useLocation();
  const listChara = useSelector((state) => state.chara.data);
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [isUserRemoved, setIsUserRemoved] = useState(false);
  const [showDialogLpAgent, setShowDialogLpAgent] = useState(false);
  const [labelDb, setLabelDb] = useState({});
  const [emailVisible, setEmailVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [entryPaidPlanVisible, setEntryPaidPlanVisible] = useState(false);
  const [balanceVisible, setBalanceVisible] = useState(false);
  const [pointAgentVisible, setPointAgentVisible] = useState(false);
  const [favoriteJobAgentVisible, setFavoriteJobAgentVisible] = useState(false);
  const [chatRoomAdminVisible, setChatRoomAdminVisible] = useState(false);
  const [priceBalancePayment, setPriceBalancePayment] = useState(0);
  const [urlPathLpAgent, setUrlPathLpAgent] = useState('');
  const [pathLpAgent, setPathLpAgent] = useState('');
  const [infoPathLpAgentId, setInfoPathLpAgentId] = useState(null);
  const [colorLabel, setColorLabel] = useState('')
  const [fakeLoginDetail, setFakeLoginDetail] = useState();
  const [typeShowActionBtn, setTypeShowActionBtn] = useState({
    lp: DISPLAY_CMS_UI_TYPE.HIDDEN,
    listChara: DISPLAY_CMS_UI_TYPE.HIDDEN,
    pointUsage: DISPLAY_CMS_UI_TYPE.HIDDEN,
    jobList: DISPLAY_CMS_UI_TYPE.HIDDEN,
    favoriteLogAgentModal: DISPLAY_CMS_UI_TYPE.HIDDEN,
    pointAgentModal: DISPLAY_CMS_UI_TYPE.HIDDEN,
    entryPaidPlanAgentModal: DISPLAY_CMS_UI_TYPE.HIDDEN,
    paymentAgentModal : DISPLAY_CMS_UI_TYPE.HIDDEN,
    balanceAgentModal : DISPLAY_CMS_UI_TYPE.HIDDEN,
    chatRoomAgentModal : DISPLAY_CMS_UI_TYPE.HIDDEN,
  }); // 0 show, 1 show + not link, 2 hidden
  const [valueRoomChat, setValueRoomChat] = useState('')
  const [chatRoomAgentToAdminId, setChatRoomAgentToAdminId] = useState('')
  const [countMessageAgentToAdminNoReply, setCountMessageAgentToAdminNoReply] = useState(0)
  const [countMessageAgentUnRead, setCountMessageAgentUnRead] = useState(0);

  const deleteButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.AGENT_DETAIL_DELETE);
  const agentLpData = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_DETAIL_CREATE_LP);
  const pageListChara = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_CHARA_LIST);
  const pointUsageLog = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_POINT_USAGE);
  const btnJobList = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_JOB_LIST);
  const btnFavoriteLogAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_FAVORITE_LOG_MODAL);
  const btnPointAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_POINT_MODAL);
  const btnEntryPaidPlanAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_ENTRY_PAIL_PLAN_MODAL);
  const btnPaymentAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_PAYMENT_MODAL);
  const btnBalanceAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_BALANCE_MODAL);
  const btnChatRoomAgentModal = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.AGENT_BTN_CHAT_ROOM_MODAL);

  const envInfoTag = envInfo.filter(item => [
      CODE_MAP_DEFINED_API.SITE_NAME,
      CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
      CODE_MAP_DEFINED_API.URL_LP,
      CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
      CODE_MAP_DEFINED_API.URL_CONTACT,
    ].includes(item.code),
  );

  const rankOptions = useSelector((state) => state.nameRank.data);
  const agent = useSelector((state) => state.agent.data);
  const resource = useSelector((state) => state.resourceAgent.data);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1?.content)
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadAgent(agentId));
    dispatch(loadResourceAgent({}));
    dispatch(loadCharacter({ agentId, pageIndex: 1, pageSize: 1 }));
    getUsers().then(res => {
      setFakeLoginDetail(res.data?.data[0]);
    });
  }, [dispatch, agentId]);

  useEffect(() => {
    getChatRoomByIdAgent(agentId).then(res => {
      setChatRoomAgentToAdminId(res?.data?.data?.id)
    })
  },[agentId])

  useEffect(()=> {
    getChatRoomById(chatRoomAgentToAdminId).then(res =>{
      setCountMessageAgentToAdminNoReply(res?.data?.data?.filter((item) => (item.replied === REPLIED_STATUS.NOT_REPLIED && item.senderId !== agentId)).length)
    })
  },[chatRoomAgentToAdminId])

  useEffect(() => {
    counterUnReadMessageAgent({ replied : 0, agentId: agentId }).then((res) => {
      if (res) {
        const { data } = res;
        setCountMessageAgentUnRead(data.data.counter);
      }
    });
  },[countMessageAgentUnRead,agentId])

  const history = useHistory();
  const redirectPageEditAgent = (id) => {
    history.push(`/agent/${id}/editagent`);
  };
  
  const redirectPageListChara = (id) => {
    history.push(`/agent/${id}/listchara`);
  };

  const openPageLpAgent = (id) => {
    setInfoPathLpAgentId(id);
    setShowDialogLpAgent(true);
    urlPathLpAgentDefault(agent?.urlLp);
  };

  const handleRemoveUser = () => {
    if ((listChara || []).length > 0) {
      removeAgent(agentId)
        .then((response) => {
          setRemoveStatus(true);
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          history.push(`/agent/agent_list`);
        })
        .catch((e) => {
          // toast.error(e.response.data.message);
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        });
    } else {
      setConfirmVisible(true);
    }
  };

  const urlPathLpAgentDefault = (path = '') => {
    if (path) {
      path = path.replace(new RegExp(urlPathLpAgent), '');
      path = path.replace(/[^a-z0-9-._]/gi, '');
    }

    setPathLpAgent(path);
  };

  useEffect(() => {
    setUrlPathLpAgent(`${window.origin.replace('admin.', '')}/agent_lp/`);
    urlPathLpAgentDefault(agent?.urlLp);
  }, [agent]);

  const setAllShowAllForButton = () => {
    let tmp = {};
    Object.keys(typeShowActionBtn).forEach(key => {
      tmp[key] = DISPLAY_CMS_UI_TYPE.ON;
    });
    setTypeShowActionBtn(tmp);
  };

  const accessTypes = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.HIDDEN, DISPLAY_CMS_UI_TYPE.NOT_LINK];

  useEffect(() => {
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setAllShowAllForButton();
    }

    if (+admin?.memberType !== MEMBER_TYPE.SYSTEM_ADMIN) {
      let tmp = typeShowActionBtn;
      // Admin: Check type show setting
      if (agentLpData && accessTypes.includes(+agentLpData?.type)) {
        tmp.lp = +agentLpData?.type;
      }
      if (pageListChara && accessTypes.includes(+pageListChara?.type)) {
        tmp.listChara = +pageListChara?.type;
      }
      if (pointUsageLog && accessTypes.includes(+pointUsageLog?.type)) {
        tmp.pointUsage = +pointUsageLog?.type;
      }
      if (btnJobList && accessTypes.includes(+btnJobList?.type)) {
        tmp.jobList = +btnJobList?.type;
      }
      if (btnFavoriteLogAgentModal && accessTypes.includes(+btnFavoriteLogAgentModal?.type)) {
        tmp.favoriteLogAgentModal = +btnFavoriteLogAgentModal?.type;
      }
      if (btnPointAgentModal && accessTypes.includes(+btnPointAgentModal?.type)) {
        tmp.pointAgentModal = +btnPointAgentModal?.type;
      }
      if (btnEntryPaidPlanAgentModal && accessTypes.includes(+btnEntryPaidPlanAgentModal?.type)) {
        tmp.entryPaidPlanAgentModal = +btnEntryPaidPlanAgentModal?.type;
      }
      if (btnPaymentAgentModal && accessTypes.includes(+btnPaymentAgentModal?.type)) {
        tmp.paymentAgentModal = +btnPaymentAgentModal?.type;
      }
      if (btnBalanceAgentModal && accessTypes.includes(+btnBalanceAgentModal?.type)) {
        tmp.balanceAgentModal = +btnBalanceAgentModal?.type;
      }
      if (btnChatRoomAgentModal && accessTypes.includes(+btnChatRoomAgentModal?.type)) {
        tmp.chatRoomAgentModal = +btnChatRoomAgentModal?.type;
      }

      setTypeShowActionBtn(tmp);
    }
  }, [admin]);

  useEffect(()=>{
    if(!!valueRoomChat){
      setChatRoomAdminVisible(true)
    }
  },[valueRoomChat])
  const setRemoveStatus = (status) => setIsUserRemoved(status);

  const onCloseDialogLpAgent = () => {
    setInfoPathLpAgentId(null);
    setShowDialogLpAgent(false);
    setPathLpAgent('');
  };

  const handleSubmitLpAgent = async () => {
    const updateData = {
      id: infoPathLpAgentId,
      urlLp: urlPathLpAgent + pathLpAgent,
      pathLp: pathLpAgent,
    };

    updateAgentLp(updateData).then(({ data }) => {
      if (data.success) {
        setShowDialogLpAgent(false);
        setInfoPathLpAgentId(null);
        dispatch(loadAgentUpdateLocal({ ...agent, urlLp: (urlPathLpAgent + pathLpAgent) }));
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
      }
    }).catch(({ response }) => {
      if (response.data && response.data.hashCode) {
        showToastErrorByCodeApi(response?.data?.hashCode);
        return;
      }
      toast.error('URLの制作に失敗しました。');
    });
  };

  const onInputChangeLp = e => {
    const { value } = e.target;
    let valueRep = value.replace(/[^a-z0-9-._]/gi, '');
    e.target.value = valueRep;

    setPathLpAgent(valueRep);
  };

  const handleClose = () => {
    setConfirmVisible(false);
    setEmailVisible(false);
    setBalanceVisible(false);
    setPaymentVisible(false);
    setPointAgentVisible(false);
    setEntryPaidPlanVisible(false);
    setFavoriteJobAgentVisible(false);
    setChatRoomAdminVisible(false);
  };

  const reload = () => {
    dispatch(loadAgent(agentId));
  };

  // Profile
  const showResource = () => {
    return resource?.items
      .filter((item) => item.value === PROFILE_TYPE.ROOT)
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        return {
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        };
      })
      .map((item, key) => (
        <>
          <Label style={style} xs={5}>
            <p style={{ paddingLeft: 3, color: colorLabel }}>{item.label}</p>
            <hr style={stylehrleft} />
          </Label>
          <Label style={style} xs={7}>
            <p style={{ paddingLeft: 3 }}>{item?.value}</p>
            <hr style={stylehrright} />
          </Label>
        </>
      ));
  };

  const findAttributes = (resourceName) => {
    const parentName = resource?.items.find((x) => x.label === resourceName);

    if (agent.attributes) {
      let responseResources = resource.items.find(x => {
        return agent?.attributes
          && agent?.attributes[parentName.profile]
          && x.profile === parentName?.profile
          && +x.value === +agent?.attributes[parentName.profile];
      });

      return responseResources?.label;
    }

    return null;
  };

  const handleMyProfile = () => {
    setMyProfVisible(true);
  };

  const isSubWWW = (!!configs.allowUseWww ? 'www.' : '');

  const accessAndPassData = () => {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: [
        '.iframe-trigger-preview',
        '.iframe-pre-hidden-btn',
      ],
    });
  };

  const handleCheckoutBalancePayment = () => {
    setBalanceVisible(false); // Fixed
    setTimeout(() => {
      let checkoutBuyPointAgent = document.getElementById('checkout-buy-point-agent');
      if (checkoutBuyPointAgent) {
        checkoutBuyPointAgent.click();
      }
    }, 1200);
  };

  const handleSuccessCheckout = () => {
    reload();
    setBalanceVisible(false);
  };

  const handleClickPoint = () => {
    setPointAgentVisible(true);
  };

  const handleClickFavoriteJobAgent = () => {
    setFavoriteJobAgentVisible(true);
  };

  const handleClickChatRoomAdmin = () => {
    createConversation()
    // setChatRoomAdminVisible(true);
  };

  const createConversation = async() =>{
    try {
      const data = await createChatRoomAdmin(agentId);
      setValueRoomChat(data?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {/*会社プレビュー*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={myProfVisible}
        onClose={() => setMyProfVisible(false)}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 600,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          会社プレビュー
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => setMyProfVisible(false)}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ height: '80vh', overflow: 'hidden' }}>
          {
            fakeLoginDetail && <iframe title="s"
                                       id="iframeId"
                                       onLoad={accessAndPassData}
                                       style={{ border: 'none', width: '100%', height: '100%' }}
                                       src={Util.myProfSrc(fakeLoginDetail, `agent*profile/${agentId.replace(/-/g, '*')}`)} />
          }
        </DialogContent>
      </Dialog>

      {/*ユーザー詳細-個別SMS送信*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showDialogLpAgent}
        onClose={onCloseDialogLpAgent}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 800,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          ユーザー詳細-個別SMS送信
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseDialogLpAgent}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Col style={{ background: 'white', borderRadius: 5, padding: 15 }}>
            <FormLabel component="legend">
              <span className="text-red">*</span>日時
            </FormLabel>
            <Row className='mt-3'
                 style={{
                   margin: 10,
                   display: 'flex',
                   justifyContent: 'flex-start',
                   alignItems: 'baseline',
                   width: '100%',
                 }}>
              <p>{urlPathLpAgent}</p>
              <div style={{ width: '30%' }}>
                <input
                  defaultValue={pathLpAgent}
                  style={{ marginLeft: 10, minWidth: 50 }}
                  onChange={onInputChangeLp}
                  maxLength={50}
                  name="pathLpAgent"
                  type="text"
                />
              </div>
            </Row>
          </Col>
          <div style={{ display: 'flex', justifyContent: 'right' }} className='mt-3'>
            <Button
              disabled={!pathLpAgent}
              className={`btn-blue mr-2 ${(pathLpAgent ? '' : 'btn-disabled')}`}
              onClick={handleSubmitLpAgent}
            >
              送信
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {confirmVisible && (
        <RemoveAgentModal
          visible={confirmVisible}
          onClose={handleClose}
          agentId={agentId}
          charaAllowed={(listChara || []).length === 0}
          setRemoveStatus={setRemoveStatus}
        />
      )}

      {/*Send Email Modal*/}
      {emailVisible && (
        <SendEmailAgentModal
          visible={emailVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          phoneNumber={agent?.phoneNumber}
          password={agent?.password}
          needOtp={agent?.needOtp}
          email={agent?.emailAddressMain}
          depositpoint={Util.separator(agent?.depositpoint)}
          envInfoTag={envInfoTag}
          reload={reload}
          labelDb={labelDb}
        />
      )}
       {/*Entry Paid Plan Modal*/}
       {entryPaidPlanVisible && (
        <EntryPaidPlanModal
          visible={entryPaidPlanVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          labelDb={labelDb}
        />
      )}
      {/*Payment Modal*/}
      {paymentVisible && (
        <PaymentAgentModal
          visible={paymentVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          labelDb={labelDb}
        />
      )}
      {/*Stripe Balence Modal*/}
      {balanceVisible && (
        <StripeAgentModal
          visible={balanceVisible}
          onClose={handleClose}
          agentId={agentId}
          nickName={agent?.nickName}
          avatar={agent?.avatar}
          labelDb={labelDb}
          agent={agent}
          reload={reload}
          setCheckoutBalance={handleCheckoutBalancePayment}
          setPriceBalancePayment={setPriceBalancePayment}
          priceBalancePayment={priceBalancePayment}
        />
      )}

      {pointAgentVisible && (
        <PaymentLogJobModel
          visible={pointAgentVisible}
          onClose={handleClose}
          agentId={agentId}
          labelDb={labelDb}
          agent={agent}
        />
      )}
      {favoriteJobAgentVisible && (
        <FavoriteJobAgentModal
          visible={favoriteJobAgentVisible}
          onClose={handleClose}
          agentId={agentId}
          labelDb={labelDb}
          agent={agent}
        />
      )}
      {chatRoomAdminVisible && (
        <ChatRoomAdminModal
          visible={chatRoomAdminVisible}
          onClose={handleClose}
          agentId={agentId}
          labelDb={labelDb}
          agent={agent}
          valueRoomChat={valueRoomChat}
        />
      )}

      {agent && (
        <MuiThemeProvider theme={customTheme}>
          <div
            id="max-width-dialog-title"
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/*float left*/}
            <div>
              {pointUsageLog && typeShowActionBtn?.pointUsage !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                >
                  <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/agentope/point_usage_log/${agent.id}`, state: { prevPath: location.pathname }}}>{pointUsageLog?.content}</Link>
                </Button>
              )}
              {btnJobList && typeShowActionBtn?.jobList !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                >
                  <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/agentope/job-list/${agent.id}`, state: { prevPath: location.pathname }}}>{btnJobList?.content}</Link>
                </Button>
              )}
               {btnFavoriteLogAgentModal && typeShowActionBtn?.favoriteLogAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={handleClickFavoriteJobAgent}
                >
                  {btnFavoriteLogAgentModal?.content}
                </Button>
              )}
              {btnPointAgentModal && typeShowActionBtn?.pointAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={handleClickPoint}
                >
                  {btnPointAgentModal?.content}
                </Button>
              )}
            </div>
             {/*float right*/}
            <div className='d-flex justify-content-start align-items-start flex-wrap pl-3'>
              {btnJobList && typeShowActionBtn?.listChara !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-redirect-chara text-capitalize"
                  onClick={() => {
                    if (typeShowActionBtn?.listChara === DISPLAY_CMS_UI_TYPE.ON) {
                      redirectPageListChara(agent.id);
                    }
                    return;
                  }}
                >
                  {pageListChara?.content}
                </Button>
              )}
              {btnEntryPaidPlanAgentModal && typeShowActionBtn?.entryPaidPlanAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={() => setEntryPaidPlanVisible(true)}
                >
                  {btnEntryPaidPlanAgentModal?.content}
                </Button>
              )}
              {btnPaymentAgentModal && typeShowActionBtn?.paymentAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={() => setPaymentVisible(true)}
                >
                  {btnPaymentAgentModal?.content}
                </Button>
              )}
              {/*Checkout Balance Modal*/}
              {btnBalanceAgentModal && typeShowActionBtn?.balanceAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                  onClick={() => setBalanceVisible(true)}
                >
                  {btnBalanceAgentModal?.content}
                </Button>
              )}

              {agentLpData && typeShowActionBtn?.lp !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-open-lp text-capitalize"
                  onClick={() => {
                    if (typeShowActionBtn?.lp === DISPLAY_CMS_UI_TYPE.ON) {
                      return openPageLpAgent(agent.id);
                    }
                    return;
                  }}
                >
                  {agentLpData?.content}
                </Button>
              )}
              <div style={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-act-edit-agent text-capitalize"
                >
                  <Link style={{ color: 'white', textDecoration: 'none' }} to={{pathname: `/admin_agent/work_page_individual/${agent.id}`, state: { prevPath: location.pathname }}}>ユーザーとのチャット</Link>
                </Button>
                {
                  countMessageAgentUnRead ?
                    <div className="message-badge">
                      <span style={{ color: 'white' }}>{countMessageAgentUnRead}</span>
                    </div>
                  : <></>
                }
              </div>
              <div style={{ position: 'relative' }}>
                {btnChatRoomAgentModal && typeShowActionBtn?.chatRoomAgentModal !== DISPLAY_CMS_UI_TYPE.HIDDEN && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-act-agent-ope text-capitalize"
                    onClick={() => handleClickChatRoomAdmin(agent.id)}
                  >
                    {btnChatRoomAgentModal?.content}
                  </Button>
                )}
                {
                  countMessageAgentToAdminNoReply ?
                    <div className="message-badge">
                      <span style={{ color: 'white' }}>{countMessageAgentToAdminNoReply}</span>
                    </div>
                  : <></>
                }
              </div>
              <Button
                variant="contained"
                className="btn-custom btn-blue mr-3 mt-3 btn-act-redirect-chara text-capitalize"
                onClick={() => setEmailVisible(true)}
              >
                Mail送信
              </Button>
              <Button
                variant="contained"
                className="btn-custom btn-blue mr-3 mt-3 btn-act-edit-agent text-capitalize"
                onClick={() => redirectPageEditAgent(agent.id)}
              >
                編集
              </Button>
              <Button onClick={handleMyProfile} variant="contained"
                      className="btn-custom btn-blue mt-3 mr-3 btn-act-preview-agent text-capitalize"
                      style={{ float: 'right' }}>
                会社プレビュー
              </Button>
            </div>
          </div>
          <Row>
            <Col xs={4}>
              <Card body className="mb-4">
                <ImageList agent={agent} />
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={5}>
                    <p style={{ paddingLeft: 3, color: colorLabel }}>MySQL ID</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label style={style} xs={7}>
                    {agent.id}
                    <hr style={stylehrright} />
                  </Label>
                  <Label style={style} xs={5}>
                    <p style={{ paddingLeft: 3, color: colorLabel }}>メールアドレス</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label style={style} xs={7}>
                    {agent.emailAddressMain}
                    <hr style={stylehrright} />
                  </Label>
                  <Label style={style} xs={5}>
                    <p style={{ paddingLeft: 3, color: colorLabel }}>
                      メールアドレス状態
                    </p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label style={style} xs={7}>
                    {convertEmailAddressMainStatusText(+agent.sendgridStatus)}
                    <hr style={stylehrright} />
                  </Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  {resource && showResource()}
                </Row>
              </Card>
            </Col>
            <Col xs={8}>
              <Row>
                <Col xs={12}>
                  <Card body className="mb-4">
                    <Row>
                      <Col xs={6}>
                        <Row>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>会社名</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {agent.nickName}
                            <hr style={stylehrright} />
                          </Label>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>電話番号</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {agent.phoneNumber}
                            <hr style={stylehrright} />
                          </Label>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>パスワード</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {agent.password}&nbsp;&nbsp;&nbsp;{' '}
                            <hr style={stylehrright} />
                          </Label>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>
                              会社Number（重複NG）
                            </p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {agent.agentNumber}
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>ランク</p>
                            <hr style={stylehrleft} />
                          </Label>
                          {rankOptions && (
                            <Label style={style} xs={7}>
                              {Util.rankValueToText(parseInt(agent.rank), rankOptions)}
                              <hr style={stylehrright} />
                            </Label>
                          )}
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>
                              プロフレイアウト（1:写真横/2345:既存/6:Job用）
                            </p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {agent.viewStyle}
                            <hr style={stylehrright} />
                          </Label>
                          <Label style={style} xs={5}>
                            <p style={{ paddingLeft: 3, color: colorLabel }}>
                              会社ステイタス
                            </p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label style={style} xs={7}>
                            {memberAgentTypeArrayText(agent?.memberType)}
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={5}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>都道府県</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent.states?.label}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>エリア</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent?.area_label}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>登録日</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent.registerDate, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>最終ログイン</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent.lastlogin, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>ログイン制御</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.loginControlToText(Number(agent?.logincontrol))}
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>

                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>掲載有効期限</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent?.planExpireDate, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払回数</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {agent?.countPaying}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払総額</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.separator(+agent?.totalPaying)}円
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払予定日</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.timestampToDate(agent?.datePayment, 'YYYY/MM/DD HH:mm')}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={5}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>支払残高</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={7}>
                        {Util.separator(+agent?.balancePayment)}円
                        <hr style={stylehrright} />
                      </Label>
                    </Row>
                  </Card>
                </Col>

                <Col xs={7}>
                  <Card body className="mb-4">
                    <Row style={{ marginBottom: 5 }}>
                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>担当者名</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.nameContact}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>郵便番号</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.postCode}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={4}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>住所</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={8}>
                        {agent?.address}
                        <hr style={stylehrright} />
                      </Label>

                      <Label style={style} xs={12}>
                        <label xs={4} className="col-4" style={{ paddingLeft: 0 }}>
                          <p style={{ paddingLeft: 3, marginBottom: 0, color: colorLabel }}>ホームページURL</p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '100%' }} />
                        </label>
                        <label xs={8} className="col-8" style={{ paddingLeft: 0 }}>
                          <p style={{ marginBottom: 0, paddingLeft: 10 }}>
                            <a target="_blank" href={agent?.urlCompany}>{agent?.urlCompany}&nbsp;</a>
                          </p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '97%' }} />
                        </label>
                      </Label>

                      <Label style={style} xs={12}>
                        <label xs={4} className="col-4" style={{ paddingLeft: 0 }}>
                          <p style={{ paddingLeft: 3, marginBottom: 0, color: colorLabel }}>×Url Lp</p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '100%' }} />
                        </label>
                        <label xs={8} className="col-8" style={{ paddingLeft: 0 }}>
                          <p style={{ marginBottom: 0, paddingLeft: 10 }}>
                            <a target="_blank" href={agent?.urlLp}>{agent?.urlLp}&nbsp;</a>
                          </p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '97%' }} />
                        </label>
                      </Label>

                      <Label style={style} xs={12}>
                        <label xs={4} className="col-4" style={{ paddingLeft: 0 }}>
                          <p style={{ paddingLeft: 3, marginBottom: 0, color: colorLabel  }}>Google Map URL</p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '100%' }} />
                        </label>
                        <label xs={8} className="col-8" style={{ paddingLeft: 0 }}>
                          <p style={{ marginBottom: 0, paddingLeft: 10 }}>
                            <a target="_blank" href={agent?.googleMap}>{agent?.googleMap}&nbsp;</a>
                          </p>
                          <hr style={{ ...stylehrright, top: 'unset', width: '97%' }} />
                        </label>
                      </Label>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={2}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>会社/店舗について</p>
                      </Label>
                      <Label
                        style={{
                          ...style,
                          height: 'auto',
                          whiteSpace: 'pre-line',
                        }}
                        xs={10}
                      >
                        {agent.aboutMe}
                      </Label>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={style} xs={2}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>管理者メモ</p>
                      </Label>
                      <Label
                        style={
                          (style, { height: 'auto', whiteSpace: 'pre-line' })
                        }
                        xs={10}
                      >
                        {agent.adminMemo}
                      </Label>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {(admin?.memberType === 0 ||
            deleteButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && !isUserRemoved && (
            <Button className="btn-red" style={btnDangerStyle} onClick={admin?.memberType === 0 ||
            deleteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
              ? handleRemoveUser : ''}>
              {deleteButton.content}
            </Button>
          )}
        </MuiThemeProvider>
      )}

      {priceBalancePayment ? (<CheckoutBalancePayment
        agentId={agentId}
        productId={agent.id}
        price={priceBalancePayment}
        cycle={0}
        reload={reload}
        labelDb={labelDb}
        nameBtn=''
        handleSuccessCheckout={handleSuccessCheckout}
      />) 
      : <></>
      }
    </div>
  );
}

export default Index;
