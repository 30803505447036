import React, { useEffect, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { getEnvironment } from '../../../../Api/environmentApi';
import { buyPointAgent } from '../../../../Api/agentAPI';
import Button from '@material-ui/core/Button';
import { DEVICE_TYPE } from '../../../../Common/constant';
import { toast } from 'react-toastify';


function Index({ agentId, title, price, nameBtn, onClosed, handleSuccessCheckout }) {
  const [stripePublicKey, setStripePublicKey] = useState('');

  useEffect(() => {
    getEnvironment({}).then((res) => {
      const stripePublicKey = res.data.data['stripePublicKey'];
      setStripePublicKey(stripePublicKey);
    });
  }, []);

  const onTokenBalancePayment = async (token) => {
    const data = {
      agentId,
      token: token?.id,
      price: +price,
      typeDevice: DEVICE_TYPE.WEB,
    };

    try {
      await buyPointAgent(data);
      toast.success('クレジット決済が完了しました')
      handleSuccessCheckout();
    } catch (e) {
      toast.error(e.response?.data?.message);
    }
  };

  if (!stripePublicKey) {
    return null;
  }

  return (
    <>
      <StripeCheckout
        token={(token) => onTokenBalancePayment(token)}
        stripeKey={stripePublicKey}
        image="https://stripe.com/img/documentation/checkout/marketplace.png"
        name={title}
        panelLabel={title}
        amount={+price}
        currency="JPY"
        locale="ja"
        closed={onClosed}
      >
        <Button
          variant="contained"
          id="checkout-buy-point-agent"
          className="btn-custom btn-blue mr-3 btn-act-redirect-chara d-none"
        >
          {nameBtn}
        </Button>
      </StripeCheckout>
    </>
  );
}

export default Index;
