import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadImages } from '../../Redux/Actions/images';
import SearchBox from './SearchBox';
import ImageList from './ImageList';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function Index() {
  const loading = useSelector((state) => state.images.loading);
  const images = useSelector((state) => state.images.items);
  const totalRow = useSelector((state) => state.images.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'image.createdDate': 'DESC' },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadImages({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: {} });
    setPageIndex(1);
    dispatch(loadImages({ ...searchFilter, pageIndex: 1, pageSize }));
  };

  const refreshList = () => {
    dispatch(loadImages({ ...filter, pageIndex, pageSize }));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadImages({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  return (
    <div>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb}/>
      <LoadingBar show={loading} />
      <ImageList
        images={images}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onUpdate={refreshList}
        // for sorting
        filter={filter}
        onSort={onSort}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
