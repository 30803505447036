import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Nophoto from '../../assets/image/no_user_photo.png';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadBlock } from '../../Redux/Actions/block';
import Util from '../../Util/Util';
import BlockLogDetailModal from './BlockLogDetailModal';
import BlockSearchBox from './BlockSearchBox';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const pageSize = 50;

function BlocksManagement() {
  const [filter, setFilter] = useState({
    sort: { 'blocks.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [blockDetail, setBlockDetail] = useState(null);
  const block = useSelector((state) => state.block.items);
  const loading = useSelector((state) => state.block.loading);
  const totalRow = useSelector((state) => state.block.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const dispatch = useDispatch();

  const handleClickDetail = (block) => {
    setBlockDetail(block);
  };

  const handleClose = () => {
    setBlockDetail(null);
  };

  useEffect(() => {
    dispatch(loadBlock({ pageIndex, pageSize, ...filter }));
  }, [dispatch, filter, pageIndex]);

  const reload = () => {
    dispatch(loadBlock({ pageIndex: 1, pageSize, ...filter }));
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };
    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };
  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: { 'blocks.createdDate': 'DESC' } });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'blocks.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 20 }}>
      <LoadingBar show={loading} />
      <BlockSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      {blockDetail && (
        <BlockLogDetailModal
          onClose={handleClose}
          item={blockDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ margin: '10px 0 20px 0' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信）"
                    name="blocker.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="blocker.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  電話番号（受信）
                  <SortArrows
                    sortObject={filter.sort}
                    text=" "
                    name="target.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>画像</TableCell>
                <TableCell>
                  {labelDb?.label_nickName?.content}
                  <SortArrows
                    sortObject={filter.sort}
                    text=" "
                    name="target.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="blocks.createdDate"
                    onClick={onSort}
                    defaultName="blocks.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {block?.map((block, index) => (
                <TableRow key={block.blocks_id} style={{ height: 48 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{block?.bPhone}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${block.bId}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(block?.bId)}
                    >
                      <img
                        className="imgRound"
                        src={
                          block?.bMemberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          block?.bAvatar ? block.bAvatar : Nophoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{block?.bName}</TableCell>
                  <TableCell>{Util.genderIdToText(block?.bGender)}</TableCell>
                  <TableCell>{block?.tPhone}</TableCell>
                  <TableCell>
                    <Link
                      to={`/user/${block.tId}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(block?.tId)}
                    >
                      <img
                        className="imgRound"
                        src={
                          block?.tMemberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          block?.tAvatar ? block.tAvatar : Nophoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{block?.tName}</TableCell>
                  <TableCell>{Util.genderIdToText(block?.tGender)}</TableCell>

                  <TableCell>
                    <time>
                      {Util.timestampToDate(block.blocks_created_date, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link className="d-color-link" onClick={() => handleClickDetail(block)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default BlocksManagement;
