const Resource = {
  LOAD_STATE: 'LOAD_STATE',
  LOAD_STATE_SUCCESS: 'LOAD_STATE_SUCCESS',
  LOAD_STATE_FAIL: 'LOAD_STATE_FAIL',

  LOAD_ALL_AREA: 'LOAD_ALL_AREA',
  LOAD_ALL_AREA_SUCCESS: 'LOAD_ALL_AREA_SUCCESS',
  LOAD_ALL_AREA_FAIL: 'LOAD_ALL_AREA_FAIL',

  UPDATE_STATE: 'UPDATE_ROOT_STATE',
  UPDATE_STATE_SUCCESS: 'UPDATE_ROOT_STATE_SUCCESS',
  UPDATE_STATE_FAIL: 'UPDATE_ROOT_STATE_FAIL',
};
export default Resource;
