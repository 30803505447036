import {
  Button,
  Card,
  createMuiTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { removeLogSendNoti } from '../../../Api/logSendNotiApi';
import { toast } from 'react-toastify';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { TEXT_HTML_MANAGEMENT_LOG_SEND_NOTIFY_LINK_MODAL } from '../../../Common/modal_text_info';
import {
  LOG_PUSH_TYPE,
  MESSAGE_CONFIRM_DELETE,
  TOAST_MESSAGE,
} from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function LogSendDetailModal({
  onClose,
  logSend,
  reload,
  labelDb,
}) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleSubmit = async () => {
    await removeLogSendNoti(logSend.id).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemoveLogSend = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!logSend}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title">
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>
              一斉送信ログ-プッシュ-詳細
            </p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                {logSend?.user?.phoneNumber}
              </p>
              <img
                className="object-fit-cover"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '50%',
                  marginRight: 20,
                }}
                src={
                  logSend?.user?.avatar ? logSend.user?.avatar?.url : NoPhoto
                }
                alt=""
              />
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {logSend.user.nickName}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_LOG_SEND_NOTIFY_LINK_MODAL,
            }}
          ></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(
                              Number(_.get(logSend, 'createdDate')),
                            ).format('YYYY-MM-DD hh:mm:ss')}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>タイプ</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          {logSend?.type
                            ? LOG_PUSH_TYPE.find(
                                (e) => e.value === logSend?.type,
                              )?.label
                            : ''}
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Label xs={2}>タイトル</Label>
                    <Label xs={10}>{logSend.title}</Label>
                  </Row>
                </Card>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Label xs={2}>本文</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: logSend.content }}
                      ></div>
                    </Label>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemoveLogSend}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
      {confirmVisible && (
        <RemoveItemModal
          visible={confirmVisible}
          onClose={handleClose}
          handleSubmit={handleSubmit}
          title={MESSAGE_CONFIRM_DELETE.TITLE}
          content={MESSAGE_CONFIRM_DELETE.CONTENT}
        />
      )}
    </div>
  );
}
