import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  Card,
  TableRow,
  MuiThemeProvider,
} from '@material-ui/core';
import { loadListRawUsers } from '../../Redux/Actions/listRawUsers';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import _ from 'lodash';
import Util from '../../Util/Util';
import Pagination from '../../Components/Pagination';

const pageSize = 50;

function ListRawUsers() {
  const [filter] = useState({ sort: {} });
  const [pageIndex, setPageIndex] = useState(1);
  const { items: users, totalRow } = useSelector((state) => state.listRawUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadListRawUsers({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadListRawUsers({ ...filter, pageIndex, pageSize }));
  };

  return (
    <>
      <React.Fragment>
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top mt-4">
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  {!_.isEmpty(users) &&
                    Object.keys(users[0]).map((key) => (
                      <TableCell>{key}</TableCell>
                    ))}
                </TableRow>
                {users.map((user, index) => (
                  <TableRow key={user.id} style={{ height: 48 }}>
                    <TableCell>{index + 1}</TableCell>
                    {Object.keys(user).map((key) => (
                      <TableCell>
                        {['lastlogin', 'birthday', 'registerDate'].includes(key)
                          ? Util.timestampToDate(user[key], 'YYYY/MM/DD')
                          : user[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
        <center>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
            onPageChange={handlePageChange}
          />
        </center>
      </React.Fragment>
    </>
  );
}

export default ListRawUsers;
