import React, { useEffect, useState } from 'react';
import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Link, useHistory, withRouter } from 'react-router-dom';
import MenuHomeIcon from '../assets/image/menu_home.png';
import MenuTeamIcon from '../assets/image/menu_team.png';
import MenuBoxIcon from '../assets/image/menu_box.png';
import MenuWatchIcon from '../assets/image/menu_watch.png';
import MenuMarkIcon from '../assets/image/menu_mark.png';
import MenuChatIcon from '../assets/image/menu_chat.png';
import MenuBasketIcon from '../assets/image/menu_basket.png';
import MenuMailIcon from '../assets/image/menu_mail.png';
import MenuSettingIcon from '../assets/image/menu_setting.png';
import MenuDatabaseIcon from '../assets/image/menu_database.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAY_CMS_UI_TYPE, LOGIN_TYPE, MEMBER_TYPE } from '../Common/constant';
import { Button } from '@material-ui/core';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { loadDetailOperator, operatorLogout } from '../Redux/Actions/operator';
import { agentLogout, loadAgent } from '../Redux/Actions/agent';
import NoPhoto from '../assets/image/no_user_photo.png';
import NoAvatar from '../assets/image/sys_photo.png';
import { loadEnvInfo } from '../Redux/Actions/envInfo';
import { ReactComponent as Logout } from '../assets/image/logout.svg';

import { userLogout } from '../Redux/Actions/user';

import User from '../Redux/Types/user';
import Operator from '../Redux/Types/operator';
import Agent from '../Redux/Types/agent';
import { AGENT_ACCESS_MENU_LINKS, CODE_MAP_DEFINED_API, LINK_MAP_DEFINED_API } from '../Config/listConstants';

function SidenavComponent({ location, history, user }) {
  const envUICms = useSelector((state) => state.envUISidenav.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const agent = useSelector((state) => state.agent.data);
  const operator = useSelector((state) => state.detailOperator.data);
  const [color, setColor] = useState('#171f46');

  const [isMenuHaveChild, setIsMenuHaveChild] = useState({});
  const histories = useHistory();

  const dataParent = envUICms
    .filter((item) => item.parrentId === null && item.link !== null)
    .map(item => ({ ...item, link: item.link.trim() }))
    .sort((a, b) => a.order - b.order);

  const dataChild = envUICms
    .filter(item => item.parrentId !== null)
    .map(item => ({ ...item, link: item.link.trim() }))
    .sort((a, b) => a.order - b.order);

  const [expanded, setExpanded] = useState(!!localStorage.getItem('__sidenav_expand'));

  useEffect(() => {
    const dataColor = envInfo.filter((item) => item.code === CODE_MAP_DEFINED_API.BG_COLOR_SIDE_NAV);
    if (dataColor.length > 0) {
      setColor(dataColor[0].content);
      document.documentElement.style.setProperty('--nav-variable', dataColor[0].content);
    } else {
      document.documentElement.style.setProperty('--nav-variable', color);
    }
  }, [envInfo, user]);

  const dispatch = useDispatch();
  useEffect(() => {
    const token = Cookies.get('token');
    const decoded = jwt_decode(token);

    if (decoded.typeLogin === LOGIN_TYPE.OPERATOR) {
      dispatch(loadDetailOperator(decoded.userId));
    } else if (decoded.typeLogin === LOGIN_TYPE.AGENT) {
      dispatch(loadAgent(decoded.userId));
    }

  }, [dispatch]);

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, []);

  useEffect(() => {
    const rootDiv = document.querySelector('#root > div');
    if (rootDiv & dataParent) {

      if (!expanded) {
        rootDiv.classList.add('d-navi-collapsed');
      } else {
        rootDiv.classList.remove('d-navi-collapsed');
      }
    }
  }, [dataParent]);

  useEffect(() => {
    if (dataParent) {
      const dataCheck = {};
      for (let i = 0; i < dataParent.length; i++) {
        const id = dataParent[i].id;
        const data = dataChild.filter((item) => item.parrentId === id);

        dataCheck[dataParent[i].link] = data.length > 0;
      }

      setIsMenuHaveChild(dataCheck);
    }
  }, [envUICms]);

  const checkIcon = (name) => {
    switch (name) {
      case 'users':
        return <img src={MenuTeamIcon} alt="" width="16px" />;
      case 'operationalmanagement':
        return <img src={MenuBoxIcon} alt="" width="16px" />;
      case 'logmanagement':
        return <img src={MenuWatchIcon} alt="" width="16px" />;
      case 'programManagement':
        return <img src={MenuMarkIcon} alt="" width="16px" />;
      case 'jobManagement':
        return <img src={MenuMarkIcon} alt="" width="16px" />;
      case 'support':
        return <img src={MenuChatIcon} alt="" width="16px" />;
      case 'configuration':
        return <img src={MenuBasketIcon} alt="" width="16px" />;
      case 'administrator':
        return <img src={MenuTeamIcon} alt="" width="16px" />;
      case 'agency':
        return <img src={MenuMailIcon} alt="" width="16px" />;
      case 'operator':
        return <img src={MenuSettingIcon} alt="" width="16px" />;
      case 'MySQL':
        return <img src={MenuDatabaseIcon} alt="" width="16px" />;
      default:
        return null;
    }
  };

  const [statusMenu, setStatusMenu] = useState([
    { key: 'users', status: false },
    { key: 'operationalmanagement', status: false },
    { key: 'logmanagement', status: false },
    { key: 'programManagement', status: false },
    { key: 'jobManagement', status: false },
    { key: 'support', status: false },
    { key: 'configuration', status: false },
    { key: 'administrator', status: false },
    { key: 'agency', status: false },
    { key: 'operator', status: false },
    { key: 'MySQL', status: false },
    { key: 'tag_name_setting', status: false },
  ]);

  const onToggle = (value) => () => {
    let index = statusMenu.findIndex((x) => x.key === value);
    statusMenu[index].status = !statusMenu[index].status;
    setStatusMenu([...statusMenu]);
  };

  const handleLogout = async () => {
    if (window.confirm(' ログアウトしますか？')) {
      // Check session type login for redirect page
      const typeSession = await removeSession();
      switch (typeSession) {
        case 'ADMIN':
          histories.push('/login_admin');
          break;
        case 'OPE':
          histories.push('/login_ope');
          break;
        case 'AGENT':
          histories.push('/login_agent');
          break;
  
        default:
          histories.push('/login_sys');
      }
    }else{
      window.location.href = location.pathname
    }
  };

  const removeSession = () => {
    let typeSession = '';
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('operatorId');
    Cookies.remove('agentId');
    localStorage.removeItem('operatorId');
    localStorage.removeItem('agentId');
    localStorage.removeItem('userId');
    localStorage.removeItem('memberAdminId');
    localStorage.removeItem('keepSidenav');
    localStorage.removeItem('homePage');
    localStorage.removeItem('userLoginId');

    if (user && +user?.memberType !== MEMBER_TYPE.SYSTEM_ADMIN) {
      typeSession = 'ADMIN';
      dispatch(userLogout(User.LOG_OUT));
    }
    if (agent) {
      typeSession = 'AGENT';
      dispatch(agentLogout(Agent.AGENT_LOG_OUT));
    }
    if (operator) {
      typeSession = 'OPE';
      dispatch(operatorLogout(Operator.OPERATOR_LOG_OUT));
    }

    return typeSession;
  };

  const checkKey = (value) => {
    let status = statusMenu.filter((x) => x.key === value);
    return status.length > 0 ? status[0].status : false;
  };

  const linkNewWindow = ['user/regist_user'];

  const declineLinkAction = (item, key) => {
    // Default
    return (
      <NavItem
        navitemClassName="d-sidenav-sub-nav-item"
        eventKey={`${linkNewWindow.includes(item.link) ? `${window.location.pathname.slice(1, window.location.pathname.length)}` : item.link}`}
        key={key}>
        <NavText title={item.content}>
          <Link target={linkNewWindow.includes(item.link) ? '_blank' : '_self'}
                className="none-tag"
                role="button"
                rel="noopener noreferrer"
                to={`/${item.link}`}>
            {item.content}
          </Link>
        </NavText>
      </NavItem>
    );
  };

  const renderDataChild = (id) => {
    const data = dataChild.filter((item) => item.parrentId === id);

    if (+user?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      return data.map((item, key) => {
        return declineLinkAction(item, key);
      });
    }

    // Any role but role system
    return data.map((item, key) => {
        if (item.type === DISPLAY_CMS_UI_TYPE.HIDDEN) {
          return null;
        }

        let linkEvent = `${linkNewWindow.includes(item.link) ? `${window.location.pathname.slice(1, window.location.pathname.length)}` : item.link}`;
        return (
          <NavItem
            navitemClassName="d-sidenav-sub-nav-item"
            eventKey={item.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK ? linkEvent : `app/${item.id}/${key}`}
            key={key}
            disabled={item.type === DISPLAY_CMS_UI_TYPE.NOT_LINK}
          >
            <NavText title={item.content}>
              <Link target={linkNewWindow.includes(item.link) ? '_blank' : '_self'}
                    className="none-tag"
                    role="button"
                    rel="noopener noreferrer"
                    to={item.type === DISPLAY_CMS_UI_TYPE.ON ? `/${item.link}` : `#`}>
                {item.content}
              </Link>
            </NavText>
          </NavItem>
        );
      },
    );
  };

  const renderDataParent = () => {
    const renderMenuByMemberType = (parent, memberType) => {
      return (
        <NavItem
          className='sidenav-sub-nav'
          subnavClassName="d-sidenav-sub-nav"
          eventKey={parent.link}
          key={parent.link}
          expanded={checkKey(parent.link)}
          onClick={isMenuHaveChild[parent.link]
            ? () => {
              onToggle(parent.link);
            }
            : () => {
              history.push(parent.link);
            }}
        >
          <NavIcon>{checkIcon(parent.link)}</NavIcon>
          <NavText>
            <span className="sidenav-text-root">{parent.content}</span>
            {isMenuHaveChild[parent.link] && (<ExpandMoreIcon />)}
          </NavText>
          {renderDataChild(parent.id)}

          {/*Setting link account superadmin*/}
          {parent.link === LINK_MAP_DEFINED_API.OPERATOR &&
          user?.memberType === memberType && (
            <NavItem eventKey="env_sys_setting">
              <NavText title="管理画面表示設定">
                <Link target={'_self'} className="none-tag" role="button" rel="noopener noreferrer" onClick={() => {
                }} to={`/env_sys_setting`}>
                  ・管理画面表示設定
                </Link>
              </NavText>
            </NavItem>
          )}
        </NavItem>
      );
    };

    if (+user?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      return dataParent.map((parent, key) => {
        return renderMenuByMemberType(parent, MEMBER_TYPE.SYSTEM_ADMIN);
      });
    }
    return dataParent.map((parent, key) => {
      return parent.type !== DISPLAY_CMS_UI_TYPE.HIDDEN && renderMenuByMemberType(parent, MEMBER_TYPE.SYSTEM_ADMIN);
    });
  };

  const renderItemAgentByDataParent = () => {
    return dataParent
      .filter(ele => ele.type !== DISPLAY_CMS_UI_TYPE.HIDDEN && AGENT_ACCESS_MENU_LINKS.indexOf(ele.link) !== -1)
      .map((parent, key) => {
        return (
          <NavItem
            className='sidenav-sub-nav'
            subnavClassName="d-sidenav-sub-nav"
            eventKey={`${parent.link}`}
            key={`${parent.link}`}
            expanded={checkKey(parent.link)}
            onClick={isMenuHaveChild[parent.link]
              ? () => onToggle(parent.link)
              : () => history.push(parent.link)}
          >
            <NavIcon>{checkIcon(parent.link)}</NavIcon>
            <NavText>
              <span className="sidenav-text-root">{parent.content}</span>
              {isMenuHaveChild[parent.link] && (<ExpandMoreIcon />)}
            </NavText>
            {renderDataChild(parent.id, key)}
          </NavItem>
        );
      });
  };

  return dataParent.length > 0 && (
    <SideNav
      className={`sidenav2 ${expanded ? 'sidenav2-expanded' : 'sidenav2-collapsed'}`}
      style={{ background: `${color}` }}
      expanded={expanded}
      onToggle={(expanded) => {
        localStorage.setItem('__sidenav_expand', expanded ? '1' : '');
        setExpanded(expanded);
      }}
      onSelect={(selected) => {
        const to = '/' + selected;
        if (location.pathname !== to) {
          history.push(to);
        }
      }}
    >
      <SideNav.Toggle className="sidenav-toggle" />
      <SideNav.Nav defaultSelected="dashboard" className="sidenav-nav">
        {/*menu items for system*/}
        {user && (
          <>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText>
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{user?.emailAddressMain}</span>
                  <img
                    className="imgRound"
                    src={NoAvatar}
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                </NavText>
              }
            </NavItem>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText>
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{user.nickName}</span>
                </NavText>
              }
            </NavItem>
            <NavItem
              eventKey="dashboard-user"
              key="dashboard-user"
              onClick={() => {
                history.push('/home');
              }}
            >
              <NavIcon>
                <img src={MenuHomeIcon} alt="" width="16px" />
              </NavIcon>
              <NavText>
                <span className="sidenav-text-root">ホーム</span>
              </NavText>
            </NavItem>
          </>
        )}
        {operator && (
          <>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText>
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{operator?.phoneNumber}</span>
                  <img
                    className="imgRound"
                    src={NoPhoto}
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                </NavText>
              }
            </NavItem>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText className="sidenav-text-root">
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{operator?.operatorName}</span>
                </NavText>
              }
            </NavItem>
            <NavItem className="no-highlight">
              <NavIcon>
                <img src={MenuWatchIcon} alt="operatorNumber" width="16px" />
              </NavIcon>
              <NavText className="sidenav-text-root">
                <span className="sidenav-text-root">{operator?.operatorNumber}</span>
              </NavText>
            </NavItem>
            <NavItem
              eventKey="home-ope"
              key="home-ope"
              onClick={() => {
                history.push(localStorage.getItem('homePage'));
              }}
            >
              <NavIcon>
                <img src={MenuHomeIcon} alt="ggg" width="16px" />
              </NavIcon>
              <NavText>
                <span className="sidenav-text-root">ホーム</span>
              </NavText>
            </NavItem>
          </>
        )}
        {agent && (
          <>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText>
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{agent?.phoneNumber}</span>
                  <img
                    className="imgRound"
                    src={agent?.avatar ? agent?.avatar?.url : NoPhoto}
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                </NavText>
              }
            </NavItem>
            <NavItem className="no-highlight">
              {
                expanded &&
                <NavText>
                  <span style={{ paddingLeft: 25 }} className="sidenav-text-root">{agent?.nickName}</span>
                </NavText>
              }
            </NavItem>
            <NavItem
              eventKey="dashboard-agent"
              key="dashboard-agent"
              onClick={() => {
                history.push(localStorage.getItem('homePage'));
              }}
            >
              <NavIcon>
                <img src={MenuHomeIcon} alt="" width="16px" />
              </NavIcon>
              <NavText>
                <span className="sidenav-text-root">ホーム</span>
              </NavText>
            </NavItem>
          </>
        )}
        {/*menu items for agent*/}
        {/* {agent && renderItemAgentByDataParent()} */}

        {/*menu items for system*/}
        {user && renderDataParent()}

        <NavItem
          eventKey="logout"
          key="logout"
          className='sidenav-logout'
          navitemClassName="d-sidenav-logout-nav"
        >
          <div className="d-icon-logout">
            <Logout onClick={handleLogout} className="svg-logout" alt="Logout" width="16px" />
          </div>

          <center>
            <Button
              className="btn-custom"
              style={{ background: '#5F5F5F', color: '#fff', textTransform: 'none' }}
              variant="contained"
              onClick={handleLogout}
            >
              ログアウト
            </Button>
          </center>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

const Sidenav = withRouter(SidenavComponent);
export default Sidenav;
