import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableOfPointRate from './TableOfPointRate';

const PointSetting = () => {
  const [isEditing, setIsEditing] = useState(false);
  const pointSettings = useSelector((state) => state.pointSetting.data);
  const [pointRateLeft, setPointRateLeft] = useState([]);
  const [pointRateRight, setPointRateRight] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pointSettings?.length > 15) {
      setPointRateLeft(pointSettings.slice(0, 14));
      setPointRateRight(pointSettings?.slice(14));
    } else {
      setPointRateLeft(pointSettings);
    }
  }, [pointSettings]);

  return (
    <div>
      <TableOfPointRate
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pointSettings={pointSettings}
        pointRateLeft={pointRateLeft}
        pointRateRight={pointRateRight}
      />
    </div>
  );
};

export default PointSetting;
