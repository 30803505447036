import { sendDelete, sendGet, sendPost } from './axios';

export const getAllChatRoom = filter => sendGet('/chat-admin/get-all-chat-room', { ...filter });
export const getChatRoomById = id => sendGet(`/chat-admin/get/${id}`);
export const sendMessage = (payload) => sendPost(`/chat-admin/send-message`, payload);
export const readAllMessageUnRead = (chatRoomId, agentId) => sendGet(`/chat-admin/read/${chatRoomId}`, { agentId });
export const removeMessageAdmintById = id => sendDelete(`/chat-admin/${id}`);
export const createChatRoomAdmin = (senderId) => sendPost('/chat-admin/create-conversation', {senderId})
export const getMessagesAdmin = (payload) => sendGet(`/chat-admin/messages/${payload}`);
export const getMessages = (payload) => sendGet(`/chat-admin/get-all`, payload);
export const counterUnReadMessageAdmin = (params) => sendGet('/chat-admin/counter-unread', params);
export const getChatRoomByIdAgent = id => sendGet(`/chat-admin/get-chat-room/${id}`);