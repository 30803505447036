import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getJobMember, updateJobMember } from '../../Api/jobMemberApi';
import {
  loadJobMemberFail,
  loadJobMemberSuccess,
  updateJobMemberFail,
  updateJobMemberSuccess,
} from '../Actions/jobMember';
import JobMember from '../Types/jobMember';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadJobMember(action) {
  try {
    const response = yield call(getJobMember, action.payload);
    const { data } = response;
    if (data.success) {
      const job = data.data;
      yield put(loadJobMemberSuccess(job));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadJobMemberFail(error));
  }
}

function* handleUpdateJobMember(action) {
  try {
    const response = yield call(updateJobMember, action.payload);
    const { data } = response;
    if (data.success) {
      const job = data.data;
      yield put(updateJobMemberSuccess(job));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateJobMemberFail(error));
  }
}

export default function* jobSaga() {
  yield takeLatest(JobMember.LOAD_JOB_MEMBER, handleLoadJobMember);
  yield takeLatest(JobMember.UPDATE_JOB_MEMBER, handleUpdateJobMember);
}
