const helpCms = {
  LOAD_HELP_CMS: 'LOAD_HELP_CMS',
  LOAD_HELP_CMS_SUCCESS: 'LOAD_HELP_CMS_SUCCESS',
  LOAD_HELP_CMS_FAIL: 'LOAD_HELP_CMS_FAIL',

  GET_BY_ID_HELP_CMS: 'GET_BY_ID_HELP_CMS',
  GET_BY_ID_HELP_CMS_SUCCESS: 'GET_BY_ID_HELP_CMS_SUCCESS',
  GET_BY_ID_HELP_CMS_FAIL: 'GET_BY_ID_HELP_CMS_FAIL',

  CREATE_HELP_CMS: 'CREATE_HELP_CMS',
  CREATE_HELP_CMS_SUCCESS: 'CREATE_HELP_CMS_SUCCESS',
  CREATE_HELP_CMS_FAIL: 'CREATE_HELP_CMS_FAIL',

  UPDATE_HELP_CMS: 'UPDATE_HELP_CMS',
  UPDATE_HELP_CMS_SUCCESS: 'UPDATE_HELP_CMS_SUCCESS',
  UPDATE_HELP_CMS_FAIL: 'UPDATE_HELP_CMS_FAIL',
};
export default helpCms;
