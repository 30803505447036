import { handleActions } from 'redux-actions';
import resourcesItemJob from '../Types/resourcesItemJob';

const initialState = {
  data: null,
  loading: false,
  totalRow: 0,
  dataRoot: null,
  loadingRoot: false,
  updating: false,
  success: false,
  loadingChild: false,
  updatingChild: false,
  successChild: false,
};

const actions = {
  [resourcesItemJob.LOAD_RESOURCE_ITEM_JOB]: (state) => ({
    ...state,
    loading: true,
  }),
  [resourcesItemJob.LOAD_RESOURCE_ITEM_JOB_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
  }),
  [resourcesItemJob.LOAD_RESOURCE_ITEM_JOB_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB]: (state) => ({
    ...state,
    loadingRoot: true,
  }),
  [resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB_SUCCESS]: (state, action) => ({
    ...state,
    dataRoot: action.payload.items,
    loadingRoot: false,
  }),
  [resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB_FAIL]: (state) => ({
    ...state,
    dataRoot: null,
    loadingRoot: false,
  }),
  [resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB]: (state) => ({
    ...state,
    loadingChild: true,
  }),
  [resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_SUCCESS]: (state, action) => ({
    ...state,
    dataChild: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingChild: false,
  }),
  [resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_FAIL]: (state) => ({
    ...state,
    dataChild: null,
    loadingChild: false,
  }),
  [resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB]: (state) => ({
    ...state,
    updating: true,
  }),
  [resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),
  [resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
  [resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB]: (state) => ({
    ...state,
    updatingChild: true,
  }),
  [resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB_SUCCESS]: (state) => ({
    ...state,
    updatingChild: false,
    successChild: true,
  }),
  [resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB_FAIL]: (state) => ({
    ...state,
    updatingChild: false,
  }),
};

export default handleActions(actions, initialState);
