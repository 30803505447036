const configs = {
  apiDomain: process.env.REACT_APP_API_DOMAIN,
  resources: {
    stripepublickey: process.env.REACT_APP_RESOURCES_STRIPE_PUBLIC_KEY,
    aesSecretKey: process.env.REACT_APP_RESOURCES_AES_SECRET_KEY,
  },
  allowUpload: process.env.REACT_APP_ALLOW_UPLOAD,
  allowUseWww: process.env.REACT_APP_USE_WEB_WWW === 'true',
};

export default configs;
