import { Card, MuiThemeProvider, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import NoPhotoAgent from '../../assets/image/no_agent_photo.png';
import NoAvatar from '../../assets/image/sys_photo.png';
import Util from '../../Util/Util';
import React from 'react';
import { convertReadStatusText } from '../../Util/utils';
import { CHAT_MESSAGE_TYPE, IS_ADMIN } from '../../Common/constant';

function ChatAdminLogList(props) {
  const {
    chatLog,
    onUpdate,
    pageSize = 2,
    pageIndex = 1,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    handleClickDetail,
    labelDb
  } = props;

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              {/* Sender */}
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="電話番号"
                  name="sender.phoneNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text={labelDb?.label_nickName?.content}
                  name="sender.nickName"
                  onClick={onSort}
                />
              </TableCell>
              {/* Receiver */}
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="受信者"
                  name="receiver.nickName"
                  onClick={onSort}
                />
              </TableCell>
              {/* content */}
              <TableCell>本文</TableCell>

              <TableCell>投稿画像</TableCell>

              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="既読"
                  name="message.read"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="返信"
                  name="message.replied"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="×表示"
                  name="message.hidden"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="×認証"
                  name="message.inspection"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="登録日"
                  name="message.createdDate"
                  onClick={onSort}
                  defaultName="message.createdDate"
                />
              </TableCell>
              <TableCell align="center">ボタン</TableCell>
            </TableRow>

            {chatLog.map((mess, index) => {
              const sender = mess?.sender || mess?.adminSender;
              const receiver = mess?.receiver || mess.userReceiver;
              return (
                <TableRow key={mess.id}>
                  <TableCell>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                  <TableCell>
                    <span>{sender?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    {
                      sender?.id !== IS_ADMIN.ADMIN ?
                        <Link
                          to={`/agent/${sender?.id}/detailagent`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(sender?.id)}
                        >
                          <img
                            className="imgRound"
                            src={
                              sender?.avatar ? sender?.avatar?.url : (sender?.isSupport ? NoAvatar : NoPhotoAgent)
                            }
                            alt=""
                          />
                        </Link> :
                          <img
                          className="imgRound"
                          src={
                            sender?.avatar ? sender?.avatar?.url : (sender?.isSupport ? NoAvatar : NoPhotoAgent)
                          }
                          alt=""
                          />
                    }
                  </TableCell>
                  <TableCell>{sender?.nickName}</TableCell>
                  <TableCell align="center">
                    {
                      receiver?.id !== IS_ADMIN.ADMIN ?
                        <Link
                          to={`/agent/${receiver?.id}/detailagent`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(receiver?.id)}
                        >
                          <img
                            className="imgRound"
                            src={
                              receiver?.avatar
                                ? receiver?.avatar.url
                                : (!sender?.isSupport ? NoAvatar : NoPhotoAgent)
                            }
                            alt=""
                          />
                        </Link> :
                          <img
                            className="imgRound"
                            src={
                              receiver?.avatar
                                ? receiver?.avatar.url
                                : (!sender?.isSupport ? NoAvatar : NoPhotoAgent)
                            }
                            alt=""
                          />
                    }
                  </TableCell>
                  <TableCell>{receiver?.nickName}</TableCell>
                  <TableCell>{mess.type === CHAT_MESSAGE_TYPE.TEXT && mess.content}</TableCell>
                  <TableCell>{mess.type === CHAT_MESSAGE_TYPE.IMAGE && (
                    <img src={mess.content ?? NoPhotoAgent} alt='messageImage' className='imgSquare object-fit-cover' />)}</TableCell>
                  <TableCell>{convertReadStatusText(mess.read)}</TableCell>
                  <TableCell>{Util.repliedToText(mess.replied)}</TableCell>
                  <TableCell>{Util.hiddenToText(mess.hidden)}</TableCell>
                  <TableCell>{Util.inspectionToText(mess.inspection)}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(mess.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <p style={{ color: '#0077FF', cursor: 'pointer', margin: 0 }} onClick={() => handleClickDetail(mess)}>表示</p>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ChatAdminLogList;
