import { sendGet } from './axios';

export const getTextChat = (filter) => {
  return sendGet('/cms/chat/text', {
    ...filter,
    sortBy: filter.sortBy || 'message.createdDate',
    orderBy: filter.orderBy || 'DESC',
  });
};

export const getAllChat = filter => sendGet('/cms/chat/all', { ...filter });
