import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoading from '../../Components/CircularLoading';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadInformations } from '../../Redux/Actions/information';
import Pagination from '../../Components/Pagination';
import LoadingBar from '../../Components/LoadingBar';
import moment from 'moment';
import get from 'lodash/get';
import Nophoto from '../../assets/image/no_user_photo.png';
import InformationDetail from './InformationDetail';
import { Link } from 'react-router-dom';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../Common/constant';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';

const pageSize = 50;

function InformationList() {
  const loading = useSelector((state) => state.informations.loading);
  const infos = useSelector((state) => state.informations.items);
  const totalRow = useSelector((state) => state.informations.totalRow);
  const user = useSelector((state) => state.admin.data);
  const envUICms = useSelector((state) => state.envUISidenav.data);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    // Set filter display setting for home
    filters: { displaySetting: 0 }, // Fix on
    sortBy: 'information.createdDate',
    orderBy: 'DESC',
    sort: { 'information.created_date': 'DESC' },
  });
  const [infoDetail, setInfoDetail] = useState(null);
  const [typeShowHomeActionLp, setTypeShowHomeActionLp] = useState(0); // 0 show, 1 show + not link, 2 hidden

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInformations({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);


  useEffect(() => {
    const informationHomeData = envUICms.find(item => {
        return (item.link === LINK_MAP_DEFINED_API.HOME || LINK_MAP_DEFINED_API.INFORMATION_HOME)
      },
    );
    // Sys admin
    if (+user?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setTypeShowHomeActionLp(DISPLAY_CMS_UI_TYPE.ON);
    }

    // Admin
    // Check type show setting
    if (user && +user?.memberType !== MEMBER_TYPE.SYSTEM_ADMIN) {
      if (informationHomeData) {
        switch (+informationHomeData?.type) {
          case 2: {
            // Check link and not show
            setTypeShowHomeActionLp(DISPLAY_CMS_UI_TYPE.HIDDEN);
            break;
          }
          case 1: {
            // Check link, but show text and not add action
            setTypeShowHomeActionLp(DISPLAY_CMS_UI_TYPE.NOT_LINK);
            break;
          }
          case 0: {
            // Check link, but show text and not add action
            setTypeShowHomeActionLp(DISPLAY_CMS_UI_TYPE.ON);
            break;
          }
          default:
            setTypeShowHomeActionLp(DISPLAY_CMS_UI_TYPE.HIDDEN);
        }
      }
    }

  }, [user, envUICms]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadInformations({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };

  const reload = () => {
    dispatch(loadInformations({ pageIndex: 1, pageSize, ...filter }));
  };

  const handleClose = () => {
    setInfoDetail(null);
  };

  const handleClickDetail = (info) => {
    setInfoDetail(info);
  };

  if (!infos) {
    return;
  }

  if (typeShowHomeActionLp !== DISPLAY_CMS_UI_TYPE.ON) {
    return null;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />

      {infoDetail && (
        <InformationDetail
          onClose={handleClose}
          info={infoDetail}
          reload={reload}
        />
      )}

      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ padding: "1rem" }}>
          <span>お知らせ・メンテナンス情報</span>
        </div>

        <Card className="scroll-x-on-top">
          {loading ? (
            <CircularLoading />
          ) : (
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="information.display_setting"
                      text="投稿日"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="タイトル"
                      name="information.title"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-left">本文</TableCell>
                  <TableCell className="text-center"></TableCell>
                  <TableCell className="text-center"></TableCell>
                </TableRow>

                {infos.map((info, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>
                      <p style={{ margin: 0 }}>
                        {moment(Number(get(info, 'registDate'))).format(
                          'YYYY-MM-DD',
                        )}
                      </p>
                    </TableCell>
                    <TableCell>{info?.title}</TableCell>
                    <TableCell>{info?.content}</TableCell>
                    <TableCell className="text-center">
                      <img
                        className="imgSquare object-fit-cover"
                        src={
                          info?.urlImage ? info?.urlImage : Nophoto
                        }
                        alt=""
                      />
                    </TableCell>
                    <TableCell className="text-center">
                      <Link to={'#'} onClick={() => handleClickDetail(info)} className="d-color-link">表示</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default InformationList;
