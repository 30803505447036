import React, { useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import RemoveReportModal from './RemoveReportModal';
import moment from 'moment';
import _ from 'lodash';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { TEXT_HTML_MANAGEMENT_REPORTS_LINK_MODAL } from '../../Common/modal_text_info';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

function ReportDetailModal({ onClose, report, reload, labelDb }) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemoveReport = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveReportModal
          visible={confirmVisible}
          onClose={handleClose}
          reportId={report?.id}
          onCloseModal={onClose}
          reload={reload}
        />
      )}
      <>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={!!report}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginRight: 50,
              }}
            >
              <p className={'d-title mb-0'}>報告-詳細</p>
              <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                  {report?.reporter?.phoneNumber}
                </p>
                <img className="object-fit-cover"
                     style={{
                       width: 30,
                       height: 30,
                       borderRadius: '50%',
                       marginRight: 20,
                     }}
                     src={
                      report?.reporter?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                      report?.reporter?.avatar ? report?.reporter?.avatar?.url : NoPhoto}
                     alt=""
                />
                <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                  {report?.reporter?.nickName}
                </p>
              </div>
            </div>
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_REPORTS_LINK_MODAL }}></div>
          </div>

          <DialogContent>
            <MuiThemeProvider theme={theme}>
              <Row>
                <Card body style={{ marginBottom: '1rem' }}>
                  <Row>
                    <Col xs={6}>
                      <Label xs={6} style={style}>
                        <p>日時</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>
                          {moment(Number(_.get(report, 'createdDate'))).format(
                            'YYYY-MM-DD hh:mm:ss',
                          )}
                        </p>
                        <hr style={stylehrright} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>名前</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p> {_.get(report, 'reporter.nickName')}</p>
                        <hr style={stylehrright} />
                      </Label>


                      <Label xs={6} style={style}>
                        <p>受信者電話番号</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>{report?.reporter?.phoneNumber}</p>
                        <hr style={stylehrright} />
                      </Label>
                    </Col>
                    <Col xs={6}>
                      <Label xs={6} style={style}>
                        <p>報告内容</p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label xs={6} style={style}>
                        <p>{_.get(report, 'content')}</p>
                        <hr style={stylehrright} />
                      </Label>
                    </Col>
                  </Row>
                </Card>
                <Col xs={12} className="p-0">
                  <Button
                    variant="contained"
                    className="btn-custom btn-red float-right m-0"
                    onClick={handleRemoveReport}
                  >
                    削除
                  </Button>
                </Col>
              </Row>
            </MuiThemeProvider>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default ReportDetailModal;
