import React from 'react';
// import ReactJsPaginate from 'react-js-pagination';
import Paginate from 'react-mui-pagination';

function Pagination({ pageIndex, totalRow, pageSize, onPageChange, className = '' }) {
  // const totalPage = Math.floor(totalRow / pageSize);
  // if (totalPage <= 1) return null;
  if (!totalRow) return null;
  return (
    // <ReactJsPaginate
    //   activePage={pageIndex}
    //   itemsCountPerPage={pageSize}
    //   totalItemsCount={totalRow}
    //   pageRangeDisplayed={5}
    //   onChange={onPageChange}
    // />
    <div style={{ marginTop: -30, marginBottom: 30 }} className={`d-paginate ${className}`}>
      <Paginate
        page={pageIndex}
        setPage={onPageChange}
        total={totalRow}
        perPage={pageSize}
      />
    </div>
  );
}

export default Pagination;
