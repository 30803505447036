import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../Redux/Actions/user';
import NoPhoto from '../assets/image/no_user_photo.png';
import NoPhotoAgent from '../assets/image/no_agent_photo.png';
import UserDelete from '../assets/image/delete_user.png';
import HelpIcon from '../assets/image/help.png';
import { loadAgent } from '../Redux/Actions/agent';
import { loadDetailOperator } from '../Redux/Actions/operator';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import {
  DISPLAY_CMS_UI_TYPE,
  LOGIN_TYPE,
  MEMBER_TYPE,
  MEMBER_USER_STATUS,
} from '../Common/constant';
import {
  CODE_MAP_DEFINED_API,
  LINK_MAP_DEFINED_API,
} from '../Config/listConstants';
import HelpModal from '../Page/UserManagement/UserDetail/HelpModal';
import { counterConfirmContacts } from '../Api/contactApi';
import { loadEnvInfo } from '../Redux/Actions/envInfo';
import ContactIcon from '../assets/image/contact_icon.png';
import { counterUnReadMessageSupport } from '../Api/chatSupportAPI';
import { counterUnReadMessageAdmin } from '../Api/chatAdminApi';

function Navbar({ admin }) {
  // const envUICms = useSelector((state) => state.envUISidenav.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const history = useHistory();
  const params = useParams();
  const [color, setColor] = useState('green');
  const [helpModalVisible, sethelpModalVisible] = useState(false);
  let userId;
  const pathName = history.location.pathname;
  const pathNameSplit = pathName.split('/');
  const [countContactUnconfirm, setCountContactUnconfirm] = useState(0);
  const [countMessageSupportUnRead, setCountMessageSupportUnRead] = useState(0);
  const [countMessageAdminUnRead, setCountMessageAdminUnRead] = useState(0);
  const envUICms = useSelector((state) => state.envUICms.data);
  const showContactIcon = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.CONTACTS,
  );
  const showIndividualIcon = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.CHAT_SUPPORT_INDIVIDUAL,
  );
  const showAdminIndividualIcon = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.CHAT_ADMIN_INDIVIDUAL,
  );

  const handleClose = () => {
    sethelpModalVisible(false);
  };
  const handleClickAddPoint = () => {
    sethelpModalVisible(true);
  };

  const findTitle = () => {
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
      pathName.includes('user')
    ) {
      return 'ユーザー詳細';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'edit' &&
      pathName.includes('user')
    ) {
      return 'ユーザー詳細-編集';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
      pathName.includes('operator')
    ) {
      return '管理-オペレーター詳細';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'edit' &&
      pathName.includes('operator')
    ) {
      return '管理-オペレーター編集';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detailagent' &&
      pathName.includes('agent')
    ) {
      return '会社詳細ルート（SA）';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detailope' &&
      pathName.includes('agent')
    ) {
      return '会社詳細（SA）';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'editagent' &&
      pathName.includes('agent')
    ) {
      return '会社詳細-編集（SA）';
    }
    if (pathName.includes('work_page_individual')) {
      return '個別送信画面(SA）';
    }
    if (pathName.includes('operator/salarypoint')) {
      return '管理-オペレーター給与ポイント';
    }
    if (pathName.includes('agentope/point_usage_log')) {
      return 'ポイント消費ログ（SA）';
    }
    if (pathName.includes('agentope/job-list')) {
      return '会社-仕事一覧';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
      pathName.includes('administrator')
    ) {
      return '管理者詳細';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'edit' &&
      pathName.includes('administrator')
    ) {
      return '管理者詳細-編集';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
      pathName.includes('opechara')
    ) {
      return 'オペレーター詳細（O）';
    }

    if (pathName.includes('opechara/salarypoint')) {
      return '給与ポイント（O）';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'edit' &&
      pathName.includes('opechara')
    ) {
      return 'オペレーター編集（O）';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detailshort' &&
      (pathName.includes('character') || pathName.includes('user'))
    ) {
      return 'ユーザー詳細（Short）';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'editshort' &&
      (pathName.includes('character') || pathName.includes('user'))
    ) {
      return 'ユーザー詳細編集（Short）';
    }

    if (
      pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
      pathName.match(/\/job\/([0-9a-z_.-]+)\/detail/)
    ) {
      return '仕事一覧-詳細';
    }
    if (
      pathNameSplit[pathNameSplit.length - 1] === 'edit' &&
      pathName.match(/\/job\/([0-9a-z_.-]+)\/edit/)
    ) {
      return '仕事-詳細-編集';
    }

    switch (pathName) {
      case '/home':
        return 'ホーム';
      case '/user/search':
        return 'ユーザー検索';
      case '/user/regist_user':
        return '新規ユーザー登録';
      case '/user/edit/multi':
        return '一括変換';
      case '/management/image_prof':
        return 'プロフィール画像管理';
      case '/management/chat_log':
        return 'チャットログ';
      case '/management/blog_log':
        return 'ブログ管理';
      case '/management/log-send':
        return '一斉送信ログ';
      case '/management/like_log':
        return 'いいね一覧';
      case '/management/favorite_log':
        return 'お気に入り一覧';
      case '/management/favorite_job_log':
        return 'お気に入り一覧（仕事）';
      case '/management/block_log':
        return 'ブロック一覧';
      case '/management/footprint_log':
        return '足跡一覧';
      case '/management/timer_standby':
        return '予約送信一覧';
      case '/management/schedule_standby':
        return 'スケジュール送信一覧';
      case '/management/chat_room':
        return 'チャットルーム管理';
      case '/log/payment_match':
        return '入金ログ';
      case '/log/payment_job':
        return '入金ログ（仕事）';
      case '/log/enrollment':
        return '登録者数';
      case '/log/point_usage':
        return 'ポイント消費ログ';
      case '/log/twilio_history':
        return 'Twilio通話ログ';
      case '/log/get_point':
        return 'ポイント取得ログ';
      case '/contents/popup_setting':
        return 'ポップアップ設定';
      case '/contents/campaign':
        return 'キャンペーン設定－リスト';
      case '/job/job-list':
        return '仕事一覧';
      case '/job/job-register':
        return '仕事登録';
      case '/ope-job/job-register':
        return '仕事登録(A）';
      case '/job/job-entry':
        return '仕事応募者一覧';
      case '/operator/search_ope':
        return 'オペレーター管理-キャラクターリスト';
      case '/operator/salary_log':
        return '給与全ポイントログ';
      case '/operator/regist_ope':
        return 'オペレーター管理-オペレーター新規登録';
      case '/contacts':
        return '問合せ一覧';
      case '/reports':
        return '報告一覧';
      case '/point_setting':
        return 'ポイント設定';
      case '/point_rate':
        return ' 課金レート設定';
      case '/rank_setting':
        return 'ユーザーランク設定';
      case '/env_asp_setting':
        return 'ASP環境ファイル設定';
      case '/env_ui_web_setting':
        return 'ユーザー画面表示設定';
      case '/env_sys_setting':
        return '管理画面表示設定';
      case '/top_nav_setting':
        return 'トップメニュー設定';
      case '/item_job_setting':
        return '仕事項目設定';
      case '/profile_setting_user':
        return 'プロフィール設定-ルート';
      case '/profile_setting_agent':
        return 'プロフィール設定(会社)';
      case '/profile_state_user':
        return '都道府県設定(ユーザー)';
      case '/profile_state_agent':
        return '都道府県設定(会社)';
      case '/profile_state_job':
        return '都道府県設定(仕事)';
      case '/file_upload':
        return 'ファイルアップロード';
      case '/database':
        return 'データーベース参照-一覧';
      case '/database/members':
        return 'ユーザー';
      case '/database/information':
        return 'お知らせ設定';
      case '/database/twilio-history':
        return 'Twilio通話ログ';
      case '/database/text-web-page':
        return 'Web上部テキスト';
      case '/database/contacts':
        return '問合せ';
      case '/database/message':
        return 'チャットログ';
      case '/database/campaign':
        return 'キャンペーン設定';
      case '/database/chat':
        return 'チャットルーム管理';
      case '/database/payment':
        return '入金ログ';
      case '/agent/regist_agent':
        return '会社登録';
      case '/agent/agent_list':
        return '会社一覧';
      case '/operator/salarypoint':
        return 'オペレーター管理-オペレーター給与ポイント';
      case '/operator/salary_summary':
        return 'オペレーター管理-月別給与集計';
      case '/administrator_list':
        return '管理者一覧';
      case '/regist_administrator':
        return '管理者登録新規登録';
      case '/help_cms':
        return 'ヘルプ表示設定';
      case '/env_value_setting':
        return '一斉上限・問合メール設定';
      case '/env_info':
        return '番組情報設定';
      case '/conversion_tag':
        return '変換タグ設定';
      case '/user_archive/user_archive_list':
        return '削除ユーザー一時保存';
      case '/log/change_phone':
        return '電話番号変更ログ';
      case '/log/otp_log':
        return 'OTP SMSログ-Result/0:Delivered/1:Failed/11:Unknown/12:Rejected-OtpSend/0:送信/1:擬送信';
      case '/chat_support_individual':
        return 'サポートチャット画面';
      case '/chat_admin_individual':
        return 'サポート×会社チャット画面';
      case '/admin_agent/work_page_individual':
        return 'chat-agent-individual';
      case '/chat_support_log':
        return 'サポートチャットログ';
      case '/chat_admin_log':
        return 'サポート×会社チャットログ';
      case '/agent_chat_log':
        return '会社チャットログ';
      case '/management/chat_log_user':
        return 'チャットログ管理';
      case '/tag_name_setting':
        return 'タグ設定';
      case '/tag_list':
        return 'タグ登録リスト';
      case '/information_setting':
        return 'お知らせ設定';
      case '/management/tag_list':
        return 'タグ登録一覧';
      case '/env_mailsms_text':
        return 'メールSMSテキスト挿入設定';
      case '/text_page_setting':
        return 'Web上部テキスト';
      case '/operator/detail/search_user':
        return '一斉送信（オペレーター）';
      case '/database/otp':
        return 'OTPログ1 status　0：OTPが未入力　1：OTP検証すると1に変わる  / type　register =1 login =2 change_phone =3';
      case '/database/member-admin':
        return '管理者';
      case '/database/member-archive':
        return 'ユーザー削除一時保存';
      case '/database/job-detail':
        return 'JOB-DETIAL';
      case '/database/operator':
        return 'オペレーター';
      case '/database/agent':
        return '会社';
      case '/database/popup-setting':
        return 'ポップアップ設定';
      case '/database/payment-log-job':
        return '入金ログ(仕事）';
      case '/database/log_mail_verification':
        return 'メール認証ログ';
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    const decoded = jwt_decode(token);
    let data;
    if (decoded.typeLogin === LOGIN_TYPE.SYSTEM_ADMIN) {
      data =
        admin && admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN
          ? CODE_MAP_DEFINED_API.SYSTEM_ADMIN
          : CODE_MAP_DEFINED_API.ADMIN;
    } else if (decoded.typeLogin === LOGIN_TYPE.OPERATOR) {
      data = CODE_MAP_DEFINED_API.OPERATOR;
    } else {
      data = CODE_MAP_DEFINED_API.AGENT;
    }
    const dataColor = envInfo.filter((item) => item.code === String(data));

    if (dataColor[0]) {
      setColor(dataColor[0].content);
    }
  }, [admin, envInfo]);

  const theme = createMuiTheme({
    overrides: {
      MuiAppBar: {
        root: {
          background: `${color} !important`,
        },
      },
    },
  });
  if (
    pathNameSplit[pathNameSplit.length - 1] === 'detail' &&
    pathName.includes('user')
  ) {
    userId = params.userId;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(loadAgent(userId));
      dispatch(loadUser(userId));
      dispatch(loadDetailOperator(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    counterConfirmContacts({ confirm: 0 }).then((res) => {
      if (res) {
        const { data } = res;
        setCountContactUnconfirm(data.data.counter);
      }
    });
    counterUnReadMessageSupport({ replied: 0 }).then((res) => {
      if (res) {
        const { data } = res;
        setCountMessageSupportUnRead(data.data.counter);
      }
    });
    counterUnReadMessageAdmin({ replied: 0 }).then((res) => {
      if (res) {
        const { data } = res;
        setCountMessageAdminUnRead(data.data.counter);
      }
    });
  }, [
    countContactUnconfirm,
    countMessageSupportUnRead,
    countMessageAdminUnRead,
  ]);

  const user = useSelector((state) => state.user.data);
  const agent = useSelector((state) => state.agent.data);
  const operator = useSelector((state) => state.detailOperator.data);
  let displayHelpPath = [
    '/administrator_list',
    '/agent/agent_list',
    '/agent/regist_agent',
    '/contacts',
    '/contents/campaign',
    '/contents/popup_setting',
    '/database',
    '/env_asp_setting',
    '/file_upload',
    '/help_cms',
    '/log/enrollment',
    '/log/get_point',
    '/log/payment_job',
    '/log/payment_match',
    '/log/point_usage',
    '/log/twilio_history',
    '/management/block_log',
    '/management/blog_log',
    '/management/chat_log',
    '/management/favorite_log',
    '/management/favorite_job_log',
    '/management/footprint_log',
    '/management/image_prof',
    '/management/like_log',
    '/management/log-send',
    '/management/timer_standby',
    '/management/chat_room',
    '/operator/regist_ope',
    '/operator/salary_log',
    '/operator/salary_summary',
    '/operator/search_ope',
    '/point_rate',
    '/point_setting',
    '/profile_setting_agent',
    '/profile_setting_user',
    '/profile_state_agent',
    '/profile_state_job',
    '/profile_state_user',
    '/rank_setting',
    '/regist_administrator',
    '/reports',
    '/env_sys_setting',
    '/top_nav_setting',
    '/env_ui_web_setting',
    '/user/regist_user',
    '/user/search',
    '/home',
  ];
  const displayHelp = displayHelpPath.indexOf(window.location.pathname) > -1;
  const displayContactUnconfirm = window.location.pathname === '/home';
  return (
    <>
      <Helmet>
        <title>{findTitle()}</title>
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <AppBar position="relative">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" color="inherit" noWrap>
              {findTitle()}
            </Typography>
            <div className="d-flex">
              {user && !operator && !agent && (
                <div style={{ marginRight: 30 }}>
                  <span> {user.phoneNumber}</span>
                  <img
                    className="imgRound"
                    src={
                      user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE
                        ? UserDelete
                        : user.avatar
                          ? user.avatar.url
                          : NoPhoto
                    }
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                  <span style={{ margin: '0px 20px' }}>{user.nickName}</span>
                  <img
                    onClick={handleClickAddPoint}
                    src={HelpIcon}
                    alt="help-icon"
                    width="25"
                    height="25"
                    style={{ cursor: 'pointer' }}
                  />
                  {helpModalVisible && (
                    <HelpModal
                      visible={helpModalVisible}
                      onClose={handleClose}
                      user={user}
                    />
                  )}
                </div>
              )}
              {user && operator && (
                <>
                  <div
                    style={{
                      paddingRight:
                        pathName === '/operator/detail/search_user' ? 45 : 30,
                    }}
                  >
                    <span>{operator.phoneNumber}</span>
                    <img
                      className="imgRound"
                      src={NoPhoto}
                      alt=""
                      style={{ margin: '0px 10px' }}
                    />
                    <span style={{ margin: '0px 20px 0 10px' }}>
                      {operator.operatorName}
                    </span>
                    <span>{operator.operatorNumber}</span>
                  </div>
                  <div
                    style={{
                      paddingRight: 30,
                      display:
                        pathName !== '/operator/detail/search_user' && 'none',
                    }}
                  >
                    <span>{user.phoneNumber}</span>
                    <img
                      className="imgRound"
                      src={
                        user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE
                          ? UserDelete
                          : user.avatar
                            ? user.avatar.url
                            : NoPhoto
                      }
                      alt=""
                      style={{ margin: '0px 10px' }}
                    />
                    <span style={{ margin: '0 20px 0 10px' }}>
                      {user.nickName}
                    </span>
                  </div>
                </>
              )}
              {!user && operator && (
                <div style={{ paddingRight: 30 }}>
                  <span>{operator.phoneNumber}</span>
                  <img
                    className="imgRound"
                    src={NoPhoto}
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                  <span style={{ marginRight: 10 }}>
                    {operator.operatorName}
                  </span>
                  <span>{operator.operatorNumber}</span>
                </div>
              )}
              {agent && !operator && !user && (
                <div style={{ paddingRight: 30 }}>
                  <span>{agent.phoneNumber}</span>
                  <img
                    className="imgRound"
                    src={agent.avatar ? agent.avatar.url : NoPhotoAgent}
                    alt=""
                    style={{ margin: '0px 20px' }}
                  />
                  <span style={{ marginRight: 10 }}>{agent.nickName}</span>
                </div>
              )}
              {displayContactUnconfirm && (
                <div className="d-flex">
                  {(admin?.memberType === 0 ||
                    showAdminIndividualIcon?.type !==
                      DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                    <div style={{ margin: 10, marginTop: 20 }}>
                      <Link
                        className="d-flex flex-column justify-content-center align-items-center a-text-decoration"
                        to={'/chat_admin_individual'}
                      >
                        <div style={{ position: 'relative' }}>
                          <img style={{ width: 30 }} src={ContactIcon} alt="" />
                          {countMessageAdminUnRead > 0 ? (
                            <span
                              className="contact-badge"
                              style={{ color: 'white' }}
                            >
                              {countMessageAdminUnRead}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <span style={{ color: 'white' }}>会社チャット</span>
                      </Link>
                    </div>
                  )}
                  {(admin?.memberType === 0 ||
                    showIndividualIcon?.type !==
                      DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                    <div
                      style={{
                        margin: 10,
                        marginTop: 20,
                        position: 'relative',
                      }}
                    >
                      <Link
                        className="d-flex flex-column justify-content-center align-items-center a-text-decoration"
                        to={'/chat_support_individual'}
                      >
                        <div style={{ position: 'relative' }}>
                          <img style={{ width: 30 }} src={ContactIcon} alt="" />
                          {countMessageSupportUnRead > 0 ? (
                            <span
                              className="contact-badge"
                              style={{ color: 'white' }}
                            >
                              {countMessageSupportUnRead}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <span style={{ color: 'white' }}>サポートチャット</span>
                      </Link>
                    </div>
                  )}
                  {(admin?.memberType === 0 ||
                    showContactIcon?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                    <div
                      style={{
                        margin: 10,
                        marginTop: 20,
                        position: 'relative',
                      }}
                    >
                      <Link
                        className="d-flex flex-column justify-content-center align-items-center a-text-decoration"
                        to={'/contacts'}
                      >
                        <div style={{ position: 'relative' }}>
                          <img style={{ width: 30 }} src={ContactIcon} alt="" />
                          {countContactUnconfirm > 0 ? (
                            <span
                              className="contact-badge"
                              style={{ color: 'white' }}
                            >
                              {countContactUnconfirm}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <span style={{ color: 'white' }}>問合せ</span>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {displayHelp && (
                <div>
                  <img
                    onClick={handleClickAddPoint}
                    src={HelpIcon}
                    alt="help-icon"
                    width="25"
                    height="25"
                    style={{ cursor: 'pointer' }}
                  />
                  {helpModalVisible && (
                    <HelpModal
                      visible={helpModalVisible}
                      onClose={handleClose}
                      user={user}
                    />
                  )}
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </>
  );
}

export default Navbar;
