import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { updateEnvTopNavWeb } from '../../Redux/Actions/envTopNavWeb';
import RenderSvgIcon from '../../Components/RenderSvgIcon';

const wrapStyleArrow = {
  paddingRight: 3,
  width: 24,
  height: 24,
  color: 'var(--text-secondary)',
  fill: 'currentColor',
  alignSelf: 'center',
};

function EnvTopNaviWebSettingList({ data, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(data);
  }, [data]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = [...inputUpdate];
    const elementsIndex = envSetting.findIndex((element) => element.id === splitName[1]);

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };

    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };
  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    dispatch(updateEnvTopNavWeb(inputUpdate));
    setEnvSetting(inputUpdate);
    setIsEditing(false);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell style={{ maxWidth: 100 }}>表示テキスト</TableCell>
              <TableCell style={{ maxWidth: 100 }}>URL(link)</TableCell>
              <TableCell style={{ width: '30%' }}>アイコンファイル名</TableCell>
              <TableCell style={{ width: '30%' }}>表示アイコン</TableCell>
              <TableCell>表示設定（0:On/1:Off)</TableCell>
              <TableCell>表示順（WEBと管理兼用）</TableCell>
            </TableRow>

            {envSetting.map((item, key) => (
              <TableRow key={key}>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 140,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item?.content}
                      name={`content.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item?.content
                  )}
                </TableCell>

                <TableCell style={{ maxWidth: 100 }}>{item.link}</TableCell>

                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.iconImage}
                      name={`iconImage.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.iconImage
                  )}
                </TableCell>
                <TableCell>
                  <RenderSvgIcon
                    onClick={() => {}}
                    name={item.iconImage.split('/').pop().replace('.svg','')}
                    fill="var(--text-secondary)"
                    style={wrapStyleArrow}
                    className={'d-svg'}
                  />
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.displaySetting}
                      name={`displaySetting.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.displaySetting
                  )}
                </TableCell>

                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.displayOrder}
                      name={`displayOrder.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.displayOrder
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default EnvTopNaviWebSettingList;
