import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  loadHelpMailSmsTextSuccess,
  loadMHelpMailSmsTextFail,
  updateHelpMailSmsTextFail,
  updateHelpMailSmsTextSuccess,
} from '../Actions/helpMailSmsText';
import { getHelpMailSmsTexts, updateHelpMailSmsText } from '../../Api/helpMailSmsTextAPI';
import helpMailSmsText from '../Types/helpMailSmsText';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadHelpMailSmsText(action) {
  try {
    const response = yield call(getHelpMailSmsTexts, action.payload);
    const { data } = response;
    if (data.success) {
      // const admin = data.data;

      yield put(loadHelpMailSmsTextSuccess(data));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadMHelpMailSmsTextFail(error));
  }
}

function* handleUpdateHelpMailSmsText(action) {
  try {
    const response = yield call(updateHelpMailSmsText, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(updateHelpMailSmsTextSuccess(admin));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateHelpMailSmsTextFail(error));
  }
}


export default function* helpMailSmsTextSaga() {
  yield takeLatest(helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT, handleLoadHelpMailSmsText);
  yield takeLatest(helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT, handleUpdateHelpMailSmsText);
}
