import React, { useEffect, useState } from 'react';
import LoadingBar from '../../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import { loadJobs } from '../../../Redux/Actions/jobs';
import JobList from './JobList';
import Pagination from '../../../Components/Pagination';
import { convertEnvInfo } from '../../../Util/utils';

const pageSize = 50;

export default function Index() {

  const [filter, setFilter] = useState({
    sort: { 'updatedDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.jobs.loading);
  const jobs = useSelector((state) => state.jobs.items);
  const totalRow = useSelector((state) => state.jobs.totalRow);
  const dispatch = useDispatch();
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadJobs({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const refreshList = () => {
    dispatch(loadJobs({ pageIndex, pageSize }));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      <JobList
        jobs={jobs}
        onUpdate={refreshList}
        filter={filter}
        onSort={onSort}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}
