import { handleActions } from 'redux-actions';
import helpMailSmsText from '../Types/helpMailSmsText';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  creating: false,
  success: false,
  totalRow: 0,
  detail: null,
};

const actions = {
  [helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [helpMailSmsText.LOAD_HELP_MAIL_SMS_TEXT_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    totalRow: action.payload.totalRow,
    loading: false,
    success: false,
  }),

  // [helpMailSmsText.GET_BY_ID_HELP_MAIL_SMS_TEXT_SUCCESS]: (state, action) => ({
  //   ...state,
  //   detail: action.payload,
  //   loading: false,
  //   success: false,
  // }),
  // [helpMailSmsText.GET_BY_ID_HELP_MAIL_SMS_TEXT_FAIL]: (state) => ({
  //   ...state,
  //   detail: null,
  //   loading: false,
  // }),

  [helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT]: (state) => ({
    ...state,
    updating: true,
  }),

  [helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
  }),

  [helpMailSmsText.UPDATE_HELP_MAIL_SMS_TEXT_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
};
export default handleActions(actions, initialState);
