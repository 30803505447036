import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, Col, Label, Row } from 'reactstrap';
import Util from '../../../Util/Util';
import {
  convertHiddenStatusText,
  jobPublishingTypeText,
} from '../../../Util/utils';
import moment from 'moment';
import orderBy from 'lodash/orderBy';


function JobRegisterConfirm(props) {
  const { handleCreateJob, open, setOpen, title, data, colorLabelConfirm } = props;
  const {
    agentId,
    agents,
    state,
    area,
    stateText,
    areaText,
    displaySetting,
    salaryDetail,
    workHours,
    workDetail,
    location,
    draft,
    // startTime,
    // endTime,
    tagSelected,
    editingItemJob,
    minimumSalary,
    maximumSalary,
  } = data;

  const agent = (agents ?? []).find(el => el.id === agentId);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className={'d-modal-wrapper'}
    >
      <DialogContent>
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
        <div
          style={{
            position: 'absolute',
            top: 6,
            right: 0,
            width: 'auto',
            bottom: 10,
            paddingRight: 50,
            zIndex: 1000,
          }}
        >
          <center>
            <Button
              className="btn-red"
              style={{
                padding: '4px 20px',
                fontSize: 16,
                fontWeight: 500,
                marginRight: 20,
                color: 'white',
              }}
              variant="contained"
              onClick={handleClose}
            >
              戻る
            </Button>
            <Button
              className="btn-custom btn-blue"
              variant="contained"
              onClick={handleCreateJob}
            >
              登録JobRegisterRoot
            </Button>
          </center>
        </div>
        <Row>
          {/*left*/}
          <Col xs={4}>
            <Card body className="mb-4">
              <Row>
                <Label style={{ color: colorLabelConfirm }} xs={4}>掲載状況</Label>
                <Label xs={8}>{jobPublishingTypeText(draft)}</Label>
              </Row>
            </Card>

            {/* <Card body className="mb-4">
              <Row>
                <Label style={{ color: colorLabelConfirm }} xs={4}>掲載開始日</Label>
                <Label xs={8}>{startTime ? moment(startTime).format('YYYY-MM-DD'): ''}</Label>

                <Label style={{ color: colorLabelConfirm }} xs={4}>掲載終了日</Label>
                <Label xs={8}>{endTime ? moment(endTime).format('YYYY-MM-DD'): ''}</Label>
              </Row>
            </Card> */}

            {/* <Card body className="mb-4">
              <Row>
                <Label style={{ color: colorLabelConfirm }} xs={4}>会社Number</Label>
                <Label xs={8}>{agent ? agent.agentNumber : ''}</Label>
              </Row>
            </Card> */}

            <Card body className="mb-4">
              <Row>
                <Label style={{ color: colorLabelConfirm }} xs={4}>都道府県</Label>
                <Label xs={8}>{stateText}</Label>
                <Label style={{ color: colorLabelConfirm }} xs={4}>エリア</Label>
                <Label xs={8}>{areaText}</Label>
                {editingItemJob && Object.keys(editingItemJob).length > 0 && Object.values(editingItemJob).map(ele => (
                  <>
                    <Label style={{ color: colorLabelConfirm }} xs={4}>{ele?.labelRoot}</Label>
                    <Label xs={8}>{ele?.label}</Label>
                  </>
                ))}
                <Label style={{ color: colorLabelConfirm }} xs={4}>給与報酬幅</Label>
                <Label xs={8}>
                  <div style={{ display:'flex',justifyContent:'space-between',alignItems:'center',width:'60%' }}>
                    <span>{Util.separator(+minimumSalary)}円</span>
                    <span>~</span>
                    <span>{Util.separator(+maximumSalary)}円</span>
                  </div>
                </Label>
              </Row>
            </Card>
          </Col>
          {/*right*/}
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>タイトル</Label>
                    <Label xs={10}>{title}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>給与詳細</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{salaryDetail}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>勤務時間</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{workHours}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>仕事内容</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{workDetail}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>勤務地</Label>
                    <Label xs={10} style={{ whiteSpace: 'pre-line' }}>{location}</Label>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Label style={{ color: colorLabelConfirm }} xs={2}>タグ設定</Label>
                    {tagSelected && tagSelected.length
                      ? (<Label xs={10}>
                        <div className="wrapper-tag">
                          {orderBy((tagSelected ?? []), ['displayOrder'], ['asc']).map((v, i) => (
                            <div className="tag" key={i}>{v.label}</div>))}
                        </div>
                      </Label>)
                      : <div></div>
                    }
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}

export default JobRegisterConfirm;
