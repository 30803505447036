import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import { loadEnvUICms } from '../../Redux/Actions/envUICms';
import EnvSysCmsSetting from './EnvSysCmsSetting';

const pageSize = 1000;
const mutating = false;

function Index() {
  const [pageIndex] = useState(1);
  const loading = useSelector((state) => state.envUICms.loading);
  const envUICms = useSelector((state) => state.envUICms.data);
  const [isEditing, setIsEditing] = useState(false);
  const [uiCms, setUiCmsLeft] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadEnvUICms({ pageSize, pageIndex}));
  }, [dispatch, pageIndex]);

  useEffect(() => {
    // const data = envUICms
    //   .filter((item) => item.parrentId != null)
    setUiCmsLeft(envUICms);
  }, [envUICms]);

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvSysCmsSetting
        loading={loading}
        data={uiCms}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export default Index;
