import React from 'react';
import { toast } from 'react-toastify';
import { deleteChangePhoneHistoryById } from '../../Api/changePhoneHistory';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';

function RemoveBlockModal({ visible, onClose, id, onCloseDetail, onReload }) {

  const handleSubmit = async () => {
    await deleteChangePhoneHistoryById(id).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onCloseDetail();
        onReload();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default RemoveBlockModal;
