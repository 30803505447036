import React, { useState, useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import { loadEnvironment } from '../../Redux/Actions/environment';
import EnvAspSettingList from './EnvAspSettingList';
import Pagination from '../../Components/Pagination';

const pageSize = 50;
const mutating = false;

function EnvAspSetting() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.environment.loading);
  const environment = useSelector((state) => state.environment.data.items);
  const totalRow = useSelector((state) => state.environment.totalRow);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvironment());
  }, [dispatch, pageIndex]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvAspSettingList
        loading={loading}
        nameEnv={environment}
        pageIndex={pageIndex}
        pageSize={pageSize}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <center>
        <Pagination
          pageIndex={pageIndex || 1}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}
export default EnvAspSetting;
