import { TextField } from '@material-ui/core';
import { LAST_MESSAGE_RANGE } from 'Common/constant';
import React from 'react';

export default function RangeRadioField({ type, range, fieldName, onChange }) {
  const handleChange = (type, range) => {
    onChange(fieldName, { type, range });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex' }}>
        <label className="radio-group" style={{ width: 'auto' }}>
          <input
            name={fieldName}
            type="radio"
            value={LAST_MESSAGE_RANGE.WITHIN_RANGE}
            checked={type === LAST_MESSAGE_RANGE.WITHIN_RANGE}
            onChange={(e) =>
              handleChange(LAST_MESSAGE_RANGE.WITHIN_RANGE, range)
            }
          />
          <span />
        </label>
        <TextField
          label="現在から分以内送信有"
          type="tel"
          disabled={type !== LAST_MESSAGE_RANGE.WITHIN_RANGE}
          value={type === LAST_MESSAGE_RANGE.WITHIN_RANGE ? range : ''}
          onChange={(e) => handleChange(type, e.target.value)}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <label className="radio-group" style={{ width: 'auto' }}>
          <input
            name={fieldName}
            type="radio"
            value={LAST_MESSAGE_RANGE.OUT_OF_RANGE}
            checked={`${type}` === `${LAST_MESSAGE_RANGE.OUT_OF_RANGE}`}
            onChange={() =>
              handleChange(LAST_MESSAGE_RANGE.OUT_OF_RANGE, range)
            }
            onClick={() => handleChange(LAST_MESSAGE_RANGE.OUT_OF_RANGE, range)}
          />
          <span />
        </label>
        <TextField
          label="現在から分以内送信無(Null除)"
          type="tel"
          disabled={type !== LAST_MESSAGE_RANGE.OUT_OF_RANGE}
          value={type === LAST_MESSAGE_RANGE.OUT_OF_RANGE ? range : ''}
          onChange={(e) => handleChange(type, e.target.value)}
        />
      </div>
    </div>
  );
}
