const PointRate = {
  LOAD_POINT_RATE: 'LOAD_POINT_RATE',
  LOAD_POINT_RATE_SUCCESS: 'LOAD_POINT_RATE_SUCCESS',
  LOAD_POINT_RATE_FAIL: 'LOAD_POINT_RATE_FAIL',

  UPDATE_POINT_RATE: 'UPDATE_POINT_RATE',
  UPDATE_POINT_RATE_SUCCESS: 'UPDATE_POINT_RATE_SUCCESS',
  UPDATE_POINT_RATE_FAIL: 'UPDATE_POINT_RATE_FAIL',
};
export default PointRate;
