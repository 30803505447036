import { sendDelete, sendPost } from './axios';

export const getFavoriteJobs = (filter) => {
  return sendPost('/cms/favorite-job/list', { ...filter }, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
  });
};

export const deleteFavoriteJob = (params) => sendDelete(`/cms/favorite-job/delete`, params);
