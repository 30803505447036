import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import NoPhotoJob from '../../../assets/image/no_job_photo.png';
import Pagination from '../../../Components/Pagination';
import Util from '../../../Util/Util';
import { Card, MuiThemeProvider } from '@material-ui/core';
import SortArrows from '../../../Components/SortArrows';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_FAVORITE_JOB_MODAL } from '../../../Common/modal_text_info';
import { loadFavoriteJobLog } from '../../../Redux/Actions/favoriteJobLog';
import { IMAGE_TYPE } from '../../../Config/listConstants';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';

const pageSize = 10;

function FavoriteJob({ visible, onClose, userId, labelDb, user }) {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.favoriteJob.loading);
  const favoriteJobLog = useSelector((state) => state.favoriteJob.items);
  const totalRow = useSelector((state) => state.favoriteJob.totalRow);

  const [filter, setFilter] = useState({
    sort: { 'pointgetlog.createdDate': 'DESC' },
  });
  const dispatch = useDispatch();

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  useEffect(() => {
    dispatch(loadFavoriteJobLog({ pageIndex: 1, pageSize, ...filter, userId }));
  }, [dispatch, filter, pageIndex, userId ]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadFavoriteJobLog({ ...filter, pageIndex, pageSize, userId }));
  };

  const renderUrlJobMain = (images = []) => {
    if (Array.isArray(images) && images.length) {
      const imgJobMain = images.filter(ele => ele?.type && +ele?.type === IMAGE_TYPE.AVATAR);

      if (imgJobMain && imgJobMain.length) {
        return imgJobMain[0]?.url;
      }
    }

    return NoPhotoJob;
  };

  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading} />
        <>
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginRight: 50,
                }}
              >
                <p className={'d-title mb-0'}>ユーザー詳細-お気に入り(仕事）</p>
                <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                  <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>{user?.phoneNumber}</p>
                  <img className="object-fit-cover"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        marginRight: 20,
                      }}
                      src={
                        user?.avatar
                          ? user.avatar.url
                          : NoPhotoJob
                      }
                      alt=""
                  />
                  <p className={'mb-0'} style={{ fontSize: '1rem' }}>{user?.nickName}</p>
                </div>
              </div>
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <div className={'d-title-info-box'}>
              <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_FAVORITE_JOB_MODAL }}></div>
            </div>

            <DialogContent className={'table-modal-wrapper'}>
            <MuiThemeProvider theme={tableNewTheme}>
              <Card className="scroll-x-on-top">
                <Table padding="checkbox" className="table mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell>No</TableCell>
                      {/* favorite */}
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text="電話番号"
                          name="phoneNumber"
                          onClick={onSort}
                        />
                      </TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text={labelDb?.label_nickName?.content}
                          name="nickName"
                          onClick={onSort}
                        />
                      </TableCell>
                      <TableCell>性別</TableCell>

                      {/* target */}
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text="タイトル（仕事）"
                          name="title"
                          onClick={onSort}
                        />
                      </TableCell>
                      <TableCell>画像（仕事）</TableCell>
                      <TableCell>会社（仕事）</TableCell>
                      <TableCell>登録日</TableCell>
                    </TableRow>

                    {favoriteJobLog.map((favorite, index) => (
                      <TableRow key={favorite.id}>
                        <TableCell>
                          {(pageIndex - 1) * pageSize + index + 1}
                        </TableCell>
                        <TableCell>
                          <span>{favorite?.favorite?.phoneNumber}</span>
                        </TableCell>
                        <TableCell align="center">
                          <img className="imgRound"
                                src={user?.avatar?.url ? user?.avatar?.url: NoPhotoJob}
                                alt="image-source"
                          />
                        </TableCell>
                        <TableCell>{favorite?.favorite?.nickName}</TableCell>
                        <TableCell>
                          {Util.genderIdToText(favorite?.favorite?.gender)}
                        </TableCell>

                        {/* target */}
                        <TableCell>{favorite?.target?.title}</TableCell>
                        <TableCell align="center">
                          <img
                            className="imgRound"
                            src={renderUrlJobMain(favorite?.target?.imageJobs)}
                            alt="url-main-job"
                          />
                        </TableCell>
                        <TableCell>{favorite?.target?.agent?.nickName}</TableCell>
                        <TableCell>
                          <time>
                            {Util.timestampToDate(favorite?.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                          </time>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </MuiThemeProvider>

              <center>
                <Pagination
                  className={'d-page-wrapper'}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalRow={totalRow}
                  onPageChange={handlePageChange}
                />
              </center>
            </DialogContent>
          </Dialog>
        </>
      </div>
    </MuiThemeProvider>
  );
}

export default FavoriteJob;
