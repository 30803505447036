import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Tab, Tabs } from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loadLikeUser } from '../../../Redux/Actions/likeUser';
import { loadLikeUserByMe } from '../../../Redux/Actions/likeUserByMe';
import Pagination from '../../../Components/Pagination';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import LoadingBar from '../../../Components/LoadingBar';
import _ from 'lodash';
import { createLike } from '../../../Api/userAPI';
import { Link } from 'react-router-dom';
import { themeStyleNormal2Modal } from '../theme_modal';
import { TEXT_HTML_LIKE_MODAL } from '../../../Common/modal_text_info';
import { LIKE_MATCH_TYPE, MEMBER_USER_STATUS } from '../../../Common/constant';
import UserDelete from '../../../assets/image/delete_user.png';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const pageSize = 10;

function LikeModel({ visible, onClose, userId, labelDb }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.likeUser.loading);
  const likes = useSelector((state) => state.likeUser.items);
  const likesByMe = useSelector((state) => state.likeUserByMe.items);
  const loadingLikeByMe = useSelector((state) => state.likeUserByMe.loading);
  const totalRow = useSelector((state) => state.likeUser.totalRow);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLikeUser({ pageIndex, pageSize, type: 0, userId }));
  }, [dispatch, pageIndex, userId, type]);

  useEffect(() => {
    dispatch(loadLikeUserByMe({ userId, type: 1 }));
  }, [dispatch, userId, type]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleLike = async (x) => {
    const data = { targetId: x.likerId, likerId: x.targetId };
    try {
      await createLike(data);
      dispatch(loadLikeUserByMe({ userId, type: 1 }));
    } catch (e) {
      console.error(e.response?.data?.message);
    }
  };

  const checkMatch = (like) => {
    if (!like?.liker) {
      return null;
    }

    let check = LIKE_MATCH_TYPE.NO_MATCH;
    if (likesByMe.find(obj => obj.targetId === like?.liker?.id)) {
      check = LIKE_MATCH_TYPE.MATCH;
    }

    return Util.likeMatchTypeToText(check);
  };

  return (
    <MuiThemeProvider theme={themeStyleNormal2Modal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading && loadingLikeByMe ? true : false} />
        <>
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              ユーザー詳細-いいね
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <div className={'d-title-info-box'}>
              <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_LIKE_MODAL }}></div>
            </div>

            <DialogContent className={'table-modal-wrapper'}>
              <Card>
                <Paper square className={classes.root}>
                  <Tabs
                    value={value}
                    variant="fullWidth"
                    indicatorColor="none"
                    aria-label="disabled tabs example"
                    onChange={handleChange}
                  >
                    <Tab className="async-blue" label="お相手から" />
                    <Tab className="async-blue" label="あなたから" />
                  </Tabs>
                </Paper>
                {value === 0 ? (
                  <Table padding="checkbox" className="d-table-modal mb-0">
                    <TableBody>
                      <TableRow>
                        <TableCell className="d-width-no">No</TableCell>
                        <TableCell className="d-width-phone">電話番号</TableCell>
                        <TableCell align="center">画像</TableCell>
                        <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                        <TableCell>性別</TableCell>
                        <TableCell>マッチ</TableCell>
                        <TableCell>登録日時</TableCell>
                        <TableCell>ボタン</TableCell>
                      </TableRow>
                      {likes.map((like, index) => (
                        <TableRow key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {like.liker !== null
                              ? like.liker.phoneNumber
                              : null}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              to={`/user/${like?.liker?.id}/detail`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => saveLocalStore(like.liker.id)}
                              style={{ paddingRight: 10 }}
                            >
                              <img className="object-fit-cover"
                                   style={{
                                     width: 30,
                                     height: 30,
                                     borderRadius: '50%',
                                   }}
                                   src={
                                    like.liker?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                    like.liker !== null && like.liker.avatar
                                      ? like.liker.avatar.url
                                      : NoPhoto
                                   }
                                   alt=""
                              />
                            </Link>
                          </TableCell>
                          <TableCell>
                            {like.liker !== null ? like.liker?.nickName : null}
                          </TableCell>
                          <TableCell>
                            {like.liker !== null
                              ? Util.genderIdToText(like?.liker?.gender)
                              : null}
                          </TableCell>
                          <TableCell>
                            {checkMatch(like)}
                          </TableCell>
                          <TableCell className="text-muted">
                            {Util.timestampToDate(like.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                          </TableCell>
                          <TableCell>
                            <Button
                              disabled={
                                _.find(likesByMe, (obj) => obj.targetId === like?.likerId)
                                  ? true
                                  : false
                              }
                              className={`${!_.find(
                                likesByMe,
                                (obj) => obj.targetId === like?.likerId,
                              )
                                ? `btn-blue`
                                : null
                              }`}
                              onClick={() => handleLike(like)}
                            >
                              <span>
                                {
                                   _.find(likesByMe, (obj) => obj.targetId === like?.likerId)
                                   ? 'いいね済み'
                                   : 'いいねする'
                                }
                              </span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Table padding="checkbox-" className="d-table-modal mb-0">
                    <TableBody>
                      <TableRow>
                        <TableCell className="d-width-no">No</TableCell>
                        <TableCell className="d-width-phone">電話番号</TableCell>
                        <TableCell align="center">画像</TableCell>
                        <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                        <TableCell>性別</TableCell>
                        <TableCell>マッチ</TableCell>
                        <TableCell>登録日時</TableCell>
                        {/* <TableCell>ボタン</TableCell> */}
                      </TableRow>
                      {likesByMe.map((like, index) => (
                        <TableRow key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {like.target !== null
                              ? like.target.phoneNumber
                              : null}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              to={`/user/${like?.target?.id}/detail`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => saveLocalStore(like?.target?.id)}
                              style={{ paddingRight: 10 }}
                            >
                              <img className="object-fit-cover"
                                   style={{
                                     width: 30,
                                     height: 30,
                                     borderRadius: '50%',
                                   }}
                                   src={
                                    like.target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                    like.target !== null &&
                                    like.target.avatar !== null
                                      ? like.target.avatar.url
                                      : NoPhoto
                                   }
                                   alt=""
                              />
                            </Link>
                          </TableCell>
                          <TableCell>
                            {like.target !== null ? like.target.nickName : null}
                          </TableCell>
                          <TableCell>
                            {like.target !== null
                              ? Util.genderIdToText(like.target.gender)
                              : null}
                          </TableCell>
                          <TableCell>
                            {checkMatch(like)}
                          </TableCell>
                          <TableCell className="text-muted">
                            {Util.timestampToDate(like.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                          </TableCell>
                          {/* <TableCell>
                        {like.target !== null ? (
                          <Link
                            to={`/user/${like.target.id}/detail`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(like.target.id)}
                            style={{ paddingRight: 10 }}
                          >
                            表示
                          </Link>
                        ) : (
                          "表示"
                        )}
                      </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Card>

              <center>
                <Pagination
                  className={'d-page-wrapper'}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalRow={totalRow}
                  onPageChange={handlePageChange}
                />
              </center>
            </DialogContent>
          </Dialog>
        </>
      </div>
    </MuiThemeProvider>
  );
}

export default LikeModel;
