import React, { forwardRef, useEffect, useState } from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData'; // ES 2015
import { SCHEDULE_TIMER_OPTIONS } from '../../../Common/constant';

dayjs.extend(localeData);
dayjs().localeData();

const CustomTimerPicker = forwardRef(function (
  { onChange, category, disabled },
  ref,
) {
  const [dateState, setDateState] = useState(dayjs().startOf('year'));

  useEffect(() => {
    if (disabled) return;
    onChange(dateState.valueOf());
  }, [onChange, dateState, disabled]);

  // reset function
  useEffect(() => {
    switch (+category) {
      case SCHEDULE_TIMER_OPTIONS.EVERY_YEAR: {
        setDateState((prev) => prev.startOf('year'));
        break;
      }
      case SCHEDULE_TIMER_OPTIONS.EVERY_MONTH: {
        setDateState((prev) => {
          prev = dayjs().startOf('month');
          //do this to show all 31 days in the select
          while (prev.daysInMonth() < 31) {
            prev = prev.subtract(1, 'month');
          }
          return prev;
        });
        break;
      }
      case SCHEDULE_TIMER_OPTIONS.EVERY_WEEK: {
        setDateState(dayjs().startOf('week'));
        break;
      }
      case SCHEDULE_TIMER_OPTIONS.EVERY_DAY: {
        setDateState(dayjs().startOf('day'));
        break;
      }
      default:
        console.error('cannot find the category');
    }
  }, [category]);

  const handleChange = (e) => {
    e.persist();
    // using dayjs setter function
    setDateState((prev) => prev.set(e.target.name, e.target.value));
  };

  return (
    <Grid container spacing={1} style={{ minHeight: '40px' }}>
      <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
        {(+category <= SCHEDULE_TIMER_OPTIONS.EVERY_YEAR || disabled) && (
          <>
            <Select
              name="month"
              value={dateState.month()}
              onChange={handleChange}
              style={{ flexGrow: 1 }}
              disabled={disabled}
            >
              {Array.from({ length: 12 }, (_, i) => i).map((e) => (
                <MenuItem key={e} value={e}>
                  {(e + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </MenuItem>
              ))}
            </Select>
            <span className="pr-2">月</span>
          </>
        )}
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
        {(+category <= SCHEDULE_TIMER_OPTIONS.EVERY_MONTH || disabled) && (
          <>
            <Select
              name="date"
              value={dateState.date()}
              onChange={handleChange}
              style={{ flexGrow: 1 }}
              disabled={disabled}
            >
              {Array.from(
                { length: dateState.daysInMonth() },
                (_, i) => i + 1,
              ).map((e) => (
                <MenuItem key={e} value={e}>
                  {e.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </MenuItem>
              ))}
            </Select>
            <span className="pr-2">日</span>
          </>
        )}
        {+category === SCHEDULE_TIMER_OPTIONS.EVERY_WEEK && (
          <Select
            name="day"
            value={dateState.day()}
            onChange={handleChange}
            style={{ flexGrow: 1 }}
            disabled={disabled}
          >
            {dayjs.weekdays().map((e, idx) => (
              <MenuItem key={idx} value={idx}>
                {e}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <select
          name="hour"
          value={dateState.hour()}
          onChange={handleChange}
          disabled={disabled}
        >
          {Array.from({ length: 24 }, (_, i) => i).map((e) => (
            <option key={e} value={e}>
              {e.toLocaleString('ja-JP', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </option>
          ))}
        </select>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <select
          name="minute"
          value={dateState.minute()}
          onChange={handleChange}
          disabled={disabled}
        >
          {Array.from({ length: 60 }, (_, i) => i).map((e) => (
            <option key={e} value={e}>
              {e.toLocaleString('ja-JP', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </option>
          ))}
        </select>
      </Grid>
    </Grid>
  );
});

export default CustomTimerPicker;
