import React, { useState } from 'react';
import { CircularProgress, createMuiTheme, FormLabel, MuiThemeProvider, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import { updateHelpMailSmsText } from '../../Api/helpMailSmsTextAPI';
import { toast } from 'react-toastify';
import './styles.css';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function MailSmsTextFormModal({ onClose, onSuccess, mailSmsTexts = [] }) {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(mailSmsTexts);

  const onChange = (e,mailSmsTextItem) => {
    mailSmsTextItem[e.target.name] = e.target.value;
    let mailSmsTextItemUpdate = {...mailSmsTextItem, [e.target.name]: e.target.value };
    setModel([...mailSmsTexts.filter(item => item.id !== mailSmsTextItemUpdate.id), mailSmsTextItemUpdate])
  };

  const handleSubmit = () => {
    for (const item of model) {
      const { content, code } = item;
      if (!(content && code) || !item.id) {
        return;
      }
    }
    setLoading(true);
    updateHelpMailSmsText(model).then((response) => {
      const { data } = response;
      if (data.data) {
        onClose();
        onSuccess();
        setLoading(false);
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
        setLoading(false);
      }
    }).catch((e) => setLoading(false));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      aria-labelledby="max-width-dialog-title"
      className={"d-modal-wrapper"}
    >
      <MuiThemeProvider theme={theme}>
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <p className={'d-title mb-0'}>{model.id ? '更新' : '登録'}</p>
          <div>
            <Button
              onClick={onClose}
              color="primary"
              disabled={loading}
              className="btn-custom btn-red"
            >
              キャンセル
            </Button>
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              disabled={loading}
              className="btn-custom btn-blue mr-3"
            >
              {loading && <CircularProgress />}
              保存
            </Button>
          </div>
        </DialogTitle>
      </MuiThemeProvider>
      <DialogContent>
        {
          mailSmsTexts.map((item) => (
            <Row className="pb-3">
              <Col xs={12}>
                <Card body>
                  <FormGroup row>
                    <Col xs={12}>
                      <div className="w-100">
                        <label className={'mb-0 d-label-disable'} data-shrink="true">Code</label>
                        <div className="d-box-disable">
                          <p className={'d-text-disable mb-0'}>{item.code}</p>
                        </div>
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col xs={12}>
                      <TextField
                        label="説明テキスト"
                        type="text"
                        defaultValue={item.description}
                        name="description"
                        onChange={(e) => onChange(e,item)}
                        className="w-100"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs={12}>
                      <FormLabel component="legend">内容</FormLabel>
                      <textarea
                        name="content"
                        rows="3"
                        defaultValue={item.content}
                        onChange={(e) => onChange(e,item)}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Col>
                  </FormGroup>
                  {/*0= on（表示）, 1=off（非表示）*/}
                  <FormGroup row>
                    <Col xs={12}>
                      <TextField
                        label="Display Order"
                        type="number"
                        defaultValue={item.displayOrder}
                        name="displayOrder"
                        onChange={(e) => onChange(e,item)}
                        className="w-100"
                      />
                    </Col>
                  </FormGroup>
                </Card>
              </Col>
            </Row>
          ))
        }
      </DialogContent>
    </Dialog>
  );
}

export default MailSmsTextFormModal;
