import { call, put, takeLatest } from 'redux-saga/effects';
import Admin from '../Types/admin';
import { getAdmin } from '../../Api/userAPI';
import { toast } from 'react-toastify';
import { loadAdminFail, loadAdminSuccess } from '../Actions/admin';

function* handleLoadAdmin(action) {
  try {
    const response = yield call(getAdmin, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(loadAdminSuccess(admin));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadAdminFail(error));
  }
}

export default function* adminSaga() {
  yield takeLatest(Admin.LOAD_ADMIN, handleLoadAdmin);
}
