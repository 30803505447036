import { call, put, takeLatest } from 'redux-saga/effects';
import EnvUISidenav from '../Types/envUISidenav';
import { getEnvUICms, updateEnvUICms } from '../../Api/EnvUiCmsAPI';
import {
  loadEnvUISidenavFail,
  loadEnvUISidenavSuccess,
  updateEnvUISidenavFail,
  updateEnvUISidenavSuccess,
} from '../Actions/envUISidenav';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvUISidenav(action) {
  try {
    const response = yield call(getEnvUICms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadEnvUISidenavSuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvUISidenavFail(error));
  }
}

function* handleUpdateEnvUISidenav(action) {
  try {
    const response = yield call(updateEnvUICms, action.payload);
    const { data } = response;
    if (data.success) {
      const environment = data.data;
      yield put(updateEnvUISidenavSuccess({ data: environment }));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvUISidenavFail(error));
  }
}

export default function* envUISidenavSaga() {
  yield takeLatest(EnvUISidenav.LOAD_ENVUISIDENAV, handleLoadEnvUISidenav);
  yield takeLatest(EnvUISidenav.UPDATE_ENVUISIDENAV, handleUpdateEnvUISidenav);
}
