import { createAction } from 'redux-actions';
import Agents from '../Types/agents';

export const loadAgents = createAction(Agents.LOAD_AGENTS);
export const loadAgentsSuccess = createAction(Agents.LOAD_AGENTS_SUCCESS);
export const loadAgentsFail = createAction(Agents.LOAD_AGENTS_FAIL);

export const loadAgentIds = createAction(Agents.LOAD_AGENT_IDS);
export const loadAgentIdsSuccess = createAction(Agents.LOAD_AGENT_IDS_SUCCESS);
export const loadAgentIdsFail = createAction(Agents.LOAD_AGENT_IDS_FAIL);
