import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DragDropFile from './DragDropFile';
import { fileDataType } from '../Util/utils';

function FileUploadModal(props) {
  const {
    showDialogDragDrop,
    setShowDialogDragDrop,
    setFileData,
    dataInfoHeadText,
    infoTitleElement,
    fileData,
    acceptFileType,
  } = props;

  const inputFileChangedClick = (files) => {
    setShowDialogDragDrop(false);
    let file = files[0];

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (evt) {
        setFileData({
          fileName: file.name,
          fileContent: evt.target.result,
          file,
          size: file.size,
          options: fileData?.options ?? {},
        });
      };

      reader.onerror = function (evt) {
        console.error('error reading file');
      };
    }
  };

  const onCloseDialogDragDrop = () => {
    setShowDialogDragDrop(false);
  };

  return (
    <div className="">
      {/*showDialogDragDrop*/}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={showDialogDragDrop}
        onClose={onCloseDialogDragDrop}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          style: {
            top: 0,
            maxHeight: '100%',
            maxWidth: 1200,
          },
        }}
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            className="w-100"
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ファイルアップロード</p>
            {infoTitleElement && (
              <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                <div
                  className={'text mb-0'}
                  dangerouslySetInnerHTML={{ __html: infoTitleElement }}
                ></div>
              </div>
            )}
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onCloseDialogDragDrop}
        >
          <CloseIcon color="primary" />
        </Button>
        {dataInfoHeadText && (
          <div className={'d-title-info-box'}>
            <div
              className={'text mb-0'}
              dangerouslySetInnerHTML={{ __html: dataInfoHeadText }}
            ></div>
          </div>
        )}

        <DialogContent style={{ height: '50%', overflow: 'hidden' }}>
          <DragDropFile
            acceptFileType={acceptFileType}
            indexInput={new Date().getTime()}
            handleFile={inputFileChangedClick}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FileUploadModal;
