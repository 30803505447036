export const CONTACT_TYPE = {
  BEFORE_LOGIN: 1,
  AFTER_LOGIN: 2,
  AGE_VERIFICATION: 3,
};

export const CONTACT_REASON_TYPE = {
  AGE_VERIFICATION: 1,
  PAYMENT: 2,
  WITHDRAW: 3,
  OTHER: 4,
};

export const DISPLAY_CMS_UI_TYPE = {
  ON: 0,
  NOT_LINK: 1,
  HIDDEN: 2,
};

export const BLOG_TYPE = {
  TEXT: 1,
  IMAGE: 2,
};

export const REPLIED_STATUS = {
  NOT_REPLIED: 0,
  REPLIED: 1,
};

export const MEMBER_SETTING_OPTION_SHOW = {
  OPTION_SHOW_1: 0,
  OPTION_SHOW_2: 1,
};

export const MEMBER_STATUS = {
  USER: 0,
  CHARA: 1,
};

export const POINT_TYPE = {
  CHAT_SEND: 1,
  CHAT_RECEIVE: 2,
  PHOTO_SEND: 3,
  PHOTO_RECEIVE: 4,
  CALL_TALK: 8,
};

export const COMMON_TYPE = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
};

export const MEMBER_TYPE = {
  SYSTEM_ADMIN: 0,
  NORMAL: 1,
  PONT_USER: 2,
  PLAN_USER: 3,
  JOB_TRIAL: 5,
  JOB_MEMBER: 6,
  ADMIN: 9,
};

export const MEMBER_AGENT_TYPE = {
  NORMAL: 1,
  PLAN: 2,
};

export const LOGIN_TYPE = {
  SYSTEM_ADMIN: 0,
  OPERATOR: 1,
  AGENT: 2,
  ADMIN: 3,
};

export const CREATE_USER_TYPE = {
  USER: 1,
  OPERATOR: 2,
  AGENT: 3,
  MEMBER_ADMIN: 4,
};

export const DEVICE_TYPE_IN_LOG = {
  WEB: 0,
  APP: 1,
  OTHER: 9,
};

export const DEVICE_TYPE = {
  CMS: 1,
  WEB: 2,
  APP: 3,
};

export const TIMER_SENDING_TYPE = {
  FROM_USER_DETAIL: 1,
  FROM_USER_SEARCH: 2,
  FROM_AGENT_DETAIL: 3,
};

export const TIMER_HIDDEN_TYPE = {
  SHOW: 0,
  HIDE: 1,
};

export const JOB_ENTRY_MEMBER_LIST_TYPE = {
  TRAIL: 1,
  ENTRY: 2,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const GENDER_CHARACTER = {
  MALE_CHAR: 3,
  FEMALE_CHAR: 4,
};

export const DISPLAY_OPTIONS = {
  ON: 0,
  OFF: 1,
};

export const LIKE_MATCH_TYPE = {
  NO_MATCH: 0,
  MATCH: 1,
};

export const PAYMENT_TYPE = {
  BANK_TRANSFER: 1,
  CREDIT_PAYMENT: 11,
  GOOGLE_PAY: 21,
  ITUNE_STORE: 31,
  CONVENIENCE_STORE: 41,
  BITCASH: 51,
  GIFT_POINT: 81,
  BONUS: 91,
  UNKNOWN: 0,
};

export const POINT_USAGE_LOG_TYPE = {
  CHAT_SEND: 1,
  CHAT_RECEIVE: 2,
  PHOTO_SEND: 3,
  PHOTO_RECEIVE: 4,
  PROFILE_VIEW: 5,
  PHOTO_UPDATE: 6,
  LIKE_SEND: 7,
  CALL_TALK: 8,
  CALL_VIDEO: 9,
  UNKNOWN: 0,
};

export const MEMBER_CATEGORY_TYPE = {
  WEB_MATCH: 1,
  JOB: 2,
};

export const INSPECTION_TYPE = {
  NO_INSPECTION: 0,
  INSPECTION: 1,
};

export const HIDDEN_TYPE = {
  NO_HIDDEN: 0,
  HIDDEN: 1,
};

export const READ_TYPE = {
  NO_READ: 0,
  READ: 1,
};

export const OTP_TYPE = {
  OTP: 0,
  NO_OTP: 1,
};

export const OTP_CUSTOM_TYPE = {
  ALL: 0,
  OTP: 1,
  NO_OTP: 2,
};

export const PROF_IMAGE_MAIN_TYPE = {
  STYLE: 1,
};

export const PROF_IMAGE_SUB_TYPE = {
  STYLE_1: 1,
  STYLE_2: 2,
  STYLE_3: 3,
  STYLE_4: 4,
  STYLE_5: 5,
};

export const CHAT_USER_TYPE = {
  STYLE_1: 1,
  STYLE_2: 2,
};

export const CHAT_MESSAGE_TYPE = {
  TEXT: 1,
  IMAGE: 2,
};

export const CHAT_CATEGORY_TYPE = {
  CHAT_SUPPORT: 1,
  CHAT_INDIVIDUAL: 2,
  CHAT_AGENT: 3,
  CHAT_ADMIN: 4,
};

export const TWILIO_STATUS_TYPE = {
  PREPARE_TO_CALL: 0,
  CALL_START: 1,
  IN_CONVERSATION: 2,
  END_CALL: 3,
  NULL: 4,
  OTHER: 9,
};

export const TWILIO_RESULT_TYPE = {
  COMPLETED: 'completed',
  NO_ANSWER: 'no-answer',
  BUSY: 'busy',
  NULL: 'NULL', // fixed
};

export const TWILIO_RECEIVER_STATUS_TYPE = {
  OK: 1,
  NG: 2,
  NULL: 3,
};

export const CONFIRM_TYPE = {
  UN_CONFIRMED: 0,
  CONFIRMED: 1,
};

export const TIMER_SENT = {
  WAITING: 0,
  SENT: 1,
};

export const TIMER_SENT_TYPE = {
  SEND_INDIVIDUALLY: 1,
  BROADCAST: 2,
};

export const COLUMN_DEFINED_SEARCH_BOX = {
  COLUMN_1: { sm: 3, xs: 2 },
  COLUMN_2: { sm: 4, xs: 4 },
  COLUMN_3: { sm: 5, xs: 6 },
};

export const DIR_FOLDER_IMAGE = {
  CHAT_SUPPORT: 'chat_support_individual',
  CHAT_AGENT: 'chat_agent_individual',
  CHAT_ADMIN: 'chat_admin_individual',
  CHAT: 'chat',
  PROFILE_MEMBER: 'profile_member',
  JOB_DETAIL: 'job_detail',
  PROFILE_AGENT: 'profile_agent',
  CONTACT_BEFORE_LOGIN: 'contact_nologin',
  POPUP: 'popup',
  CAMPAIGN: 'campaign',
  INFO_ADMIN: 'information_admin',
};

export const JOB_PUBLISHING_TYPE = {
  DRAFT: 0,
  PUBLISHING: 1,
};

export const APP_ALLOW_UPLOAD_TYPE = {
  // FIREBASE: 'firebase',
  SERVER: 'server',
};

export const REGEX_EMAIL =
  /^([0-9+\s-]{8,13})$|^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+)$/;
export const REGEX_ALPHABET = /^[A-Za-z0-9]+$/;
export const REGEX_NUMBER = /^\d+$/;
export const REGEX_NUMBER_PHONE = /^0\d{9,}$/;

export const PLACE_TYPE = {
  LEFT: 1,
  RIGHT: 2,
};

export const APP_DESTINATION = {
  NONE: 0,
  DEST_1: 1,
  DEST_2: 2,
  DEST_3: 3,
  DEST_4: 4,
  DEST_5: 5,
  DEST_6: 6,
  DEST_7: 7,
  DEST_8: 8,
  DEST_9: 9,
  DEST_10: 10,
  DEST_11: 11,
  DEST_12: 12,
  DEST_13: 13,
  DEST_14: 14,
  DEST_15: 15,
  DEST_16: 16,
  DEST_17: 17,
  DEST_18: 18,
};

export const EMAIL_SEND_CATEGORIES = {
  MEMBER: 1,
  AGENT: 2,
};

export const LOGIN_CONTROL = {
  ACCEPT: 0,
  NO_ACCEPT: 1,
};

export const REGISTER_STATUS_MEMBER = {
  NO_VERIFY_PHONE_NUMBER: 0,
  VERIFY_PHONE_NUMBER: 1,
};

export const ADD_DAY_ENTRY_PAIL_PLAN_AGENT = {
  DAY: 30,
};

export const IMAGE_STATUS = {
  PENDDING: 1,
  CONFIRMED: 2,
  ALL: 3,
};

export const IS_ADMIN = {
  ADMIN: 'M-oaIKaiODHDU5dgR4o',
};

export const IMAGE_MEMBER_USER_TYPE = {
  MAIN: 1,
  SUB: 2,
  ALL: 3,
};

export const MEMBER_USER_STATUS = {
  ACTIVE: 0,
  IN_ACTIVE: 1,
  ALL: 2,
};

export const CONFIG_LINK = {
  WEB: 'web',
};

export const ON_OFF_TYPE = {
  ON: 0,
  OFF: 1,
};

// Order Notification by NOTIFY_FILED_TYPES
export const NOTIFY_FILED_TYPES = {
  MAIL_INFO_SUPPORT: 'mailInfoSupport',
  MAIL_CHAT_USER: 'mailChatUser',
  SMS_INFO_SUPPORT: 'smsInfoSupport',
  SMS_CHAT_USER: 'smsChatUser',
  PUSH_CHAT: 'pushChat',
  PUSH_LIKE: 'pushLike',
  PUSH_INFO: 'pushInfo',
};

export const CONVERSATION_TAG = {
  SITE_NAME: '%SITE_NAME%',
  NICKNAME: '%NICKNAME%',
  PROF_POINT: '%PROF_POINT%',
  MAIL_ADDRESS: '%MAIL_ADDRESS%',
  PHONE_NUMBER: '%PHONE_NUMBER%',
  LOGIN_HOME: '%%LOGIN_HOME%%',
  LOGIN_MYPAGE: '%%LOGIN_MYPAGE%%',
  URL_LP: '%URL_LP%',
  LOGIN_BUY_POINT: '%%LOGIN_BUY_POINT%%',
  URL_CONTACT: '%URL_CONTACT%',
  LOGIN_MYPROFILE: '%%LOGIN_MYPROFILE%%',
  LOGIN_CHAT_ROOM: '%%LOGIN_CHAT_ROOM%%',
  LOGIN_INFO_1: '%%LOGIN_INFO_1%%',
  LOGIN_INFO_2: '%%LOGIN_INFO_2%%',
  LOGIN_INFO_3: '%%LOGIN_INFO_3%%',
  LOGIN_INFO_4: '%%LOGIN_INFO_4%%',
  LOGIN_INFO_5: '%%LOGIN_INFO_5%%',
  LOGIN_INFO_6: '%%LOGIN_INFO_6%%',
  LOGIN_INFO_7: '%%LOGIN_INFO_7%%',
  LOGIN_INFO_8: '%%LOGIN_INFO_8%%',
  PROFILE_SQLID_UUID: '%%PROFILE_SQLID_UUID%%',
};

export const CONVERSION_TAG_URL = {
  URL_LP: '/lp',
  URL_CONTACT: '/web/contactnologin',
};

export const TIMER_OPTIONS = {
  OFF: 0,
  ONCE: 1,
  LOOP: 2,
};
export const SCHEDULE_TIMER_OPTIONS = {
  EVERY_YEAR: 1,
  EVERY_MONTH: 2,
  EVERY_WEEK: 3,
  EVERY_DAY: 4,
  REGISTER_DATE: 5,
  FIRST_MESSAGE_SENDING_DATE: 6,
  LAST_PAYING_DATE: 7,
};
export const SCHEDULE_SEND_STATUS_OPTIONS = {
  SEND: 0,
  UNSEND: 1,
};

export const SCHEDULE_SMS_SENDING_TYPE = {
  FROM_USER_SEARCH: 1,
  FROM_OPE_USER_SEARCH: 2,
};

export const SCHEDULE_CRONJOB_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
};

export const LAST_MESSAGE_RANGE = {
  WITHIN_RANGE: 1,
  OUT_OF_RANGE: 2,
};
