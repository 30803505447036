import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Util from '../../../Util/Util';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import SortArrows from '../../../Components/SortArrows';
import { Link, useHistory } from 'react-router-dom';
import NoPhotoJob from '../../../assets/image/no_job_photo.png';
import JobEntryModal from './JobEntryModal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL } from '../../../Common/modal_text_info';
import { IMAGE_TYPE } from '../../../Config/listConstants';
import { convertHiddenStatusText, jobPublishingTypeText } from '../../../Util/utils';

function JobList(
  {
    jobs,
    filter = {},
    onSort = (sortByKey) => {
    },
    labelDb
  }) {
  // const history = useHistory();
  const [dataMemberJobModal, setDataMemberJobModal] = useState({ show: false, jobId: null });
  const handleVisible = (data) => {
    setDataMemberJobModal(data);
  };

  const renderMainImageJob = (imgJobs = []) => {
    let url = NoPhotoJob;
    let mainImg = imgJobs.find(ele => ele.type === IMAGE_TYPE.AVATAR);

    if (mainImg) {
      url = mainImg.url;
    }

    return (
      <img className={'imgSquare object-fit-cover'}
           style={{
             objectFit: 'cover',
           }}
           src={url ?? NoPhotoJob}
           alt=""
      />
    );
  };

  return (
    <div>
      <div className="align" style={{ justifyContent: 'flex-start' }}>
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ marginBottom: 20 }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>会社</TableCell>
                <TableCell>都道府県</TableCell>
                <TableCell>エリア</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="表示設定"
                    name="displaySetting"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>掲載状況JobList.js</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    dangerouslySetInnerHTML={{
                      __html: '登録日',
                    }}
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    dangerouslySetInnerHTML={{
                      __html: '更新日時',
                    }}
                    name="updatedDate"
                    onClick={onSort}
                  />
                </TableCell>
                {/* <TableCell>掲載開始日</TableCell>
                <TableCell>掲載終了日</TableCell> */}
                <TableCell>応募者数</TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {jobs.map((job, index) => (
                <TableRow key={job.id} style={{ height: 48 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <span>{job?.title}</span>
                  </TableCell>
                  <TableCell align="center">
                    {renderMainImageJob((job?.imageJobs ?? []))}
                  </TableCell>
                  <TableCell>{job?.agent?.nickName}</TableCell>
                  <TableCell>{job?.state?.label}</TableCell>
                  <TableCell>{job?.area?.label}</TableCell>
                  <TableCell>{convertHiddenStatusText(job?.displaySetting)}</TableCell>
                  <TableCell>{jobPublishingTypeText(job?.draft)}</TableCell>
                  <TableCell>
                    <span>
                      {Util.timestampToDate(job?.createdDate, 'YYYY/MM/DD')}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {Util.timestampToDate(job?.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                    </span>
                  </TableCell>
                  {/* <TableCell>
                    <span>
                      {job.startTime && !isNaN(job.startTime) ? Util.timestampToDate(job?.startTime, 'YYYY/MM/DD'): ''}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {job.endTime && !isNaN(job.endTime) ? Util.timestampToDate(job?.endTime, 'YYYY/MM/DD'): ''}
                    </span>
                  </TableCell> */}
                  <TableCell>
                    <a href='#' className="d-color-link" style={{ cursor: 'pointer', padding: 5 }} onClick={() => handleVisible({ show: true, jobId: job.id })}>
                      {job?.countMembersApplicanJob ? job?.countMembersApplicanJob : 0 }
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Link to={`/job/${job.id}/detail`} target="_blank" className="d-color-link">
                        詳細
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dataMemberJobModal.show}
        onClose={() => handleVisible({ show: false, jobId: null })}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={() => handleVisible({ show: false, jobId: null })}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <JobEntryModal jobId={dataMemberJobModal.jobId} labelDb={labelDb} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default JobList;
