import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import Nophoto from '../../../assets/image/no_user_photo.png';
import { loadChatUser } from '../../../Redux/Actions/chatUser';
import ChatDetailModal from './ChatDetailModal';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Util from '../../../Util/Util';
import { Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { themeChatModal } from '../theme_modal';
import { TEXT_HTML_CHAT_MODAL } from '../../../Common/modal_text_info';
import { CHAT_MESSAGE_TYPE, MEMBER_USER_STATUS } from '../../../Common/constant';
import UserDelete from '../../../assets/image/delete_user.png';

const pageSize = 10;

function ChatModel({ visible, onClose, userId, labelDb }) {
  const [pageIndex, setPageIndex] = useState(1);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [sender, setSender] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const loading = useSelector((state) => state.chatUser.loading);
  const chats = useSelector((state) => state.chatUser.items);
  const totalRow = useSelector((state) => state.chatUser.totalRow);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadChatUser({ pageIndex, pageSize, userId }));
  }, [dispatch, pageIndex, userId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const showDetail = (chatId, sender, receiver) => {
    setVisibleDetail(true);
    setChatId(chatId);
    setSender(sender);
    setReceiver(receiver);
  };

  const handleCloseDetail = () => {
    setVisibleDetail(false);
  };
  const checkUser = (chat) => {
    let user;
    if (chat?.sender?.id === userId) {
      user = chat.receiver;
    } else {
      user = chat.sender;
    }
    return user;
  };
  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };
  return visibleDetail ? (
    <ChatDetailModal
      visible={visibleDetail}
      onClose={handleCloseDetail}
      conversationId={chatId}
      sender={sender}
      receiver={receiver}
    />
  ) : (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={themeChatModal}>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-チャットログ
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_CHAT_MODAL }}></div>
          </div>

          <DialogContent className={'table-modal-wrapper'}>
            <Card className="scroll-x-on-top">
              <Table padding="checkbox" className="d-table-modal mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell className="d-width-no">No</TableCell>
                    <TableCell className="d-width-phone">電話番号</TableCell>
                    <TableCell align="center">画像</TableCell>
                    <TableCell style={{ width: '10%' }}>{labelDb?.label_nickName?.content}</TableCell>
                    <TableCell style={{ width: '5%' }}>性別</TableCell>
                    <TableCell style={{ width: '15%' }}>日時</TableCell>
                    <TableCell>本文</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {chats
                    .map((chat, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {checkUser(chat) !== null
                            ? checkUser(chat)['phoneNumber']
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={`/user/${checkUser(chat)?.id}/detail`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(checkUser(chat).id)}
                          >
                            <img className="object-fit-cover"
                                 style={{
                                   width: 30,
                                   height: 30,
                                   borderRadius: '50%',
                                 }}
                                 src={
                                  checkUser(chat)?.['memberStatus'] === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                  checkUser(chat) !== null &&
                                  checkUser(chat)?.['avatar']
                                    ? checkUser(chat)?.['avatar'].url
                                    : Nophoto
                                 }
                                 alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {checkUser(chat) !== null ? checkUser(chat)['nickName'] : null}
                        </TableCell>
                        <TableCell>
                          {checkUser(chat) !== null ? Util.genderIdToText(checkUser(chat).gender) : null}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(chat.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {chat.lastMessage
                            ? (chat.lastMessage?.type === CHAT_MESSAGE_TYPE.TEXT
                                ? <div className="ellipsis-1-line">{chat.lastMessage.content}</div>
                                : <img className="imgSquare object-fit-cover"
                                       style={{
                                         width: 30,
                                         height: 30,
                                         borderRadius: '50%',
                                       }}
                                       src={chat?.lastMessage?.content}
                                       alt=""
                                />
                            ) : null}
                        </TableCell>
                        <TableCell align="center" style={{ width: '10%' }}>
                          <Link
                            to="#"
                            style={{ cursor: 'pointer' }}
                            onClick={() => showDetail(chat?.id, chat?.sender, chat?.receiver)}
                            className="d-color-link"
                          >
                            詳細表示
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Card>

            <center>
              <Pagination
                className={'d-page-wrapper'}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default ChatModel;
