import { createAction } from 'redux-actions';
import Job from '../Types/job';

export const loadJob = createAction(Job.LOAD_JOB);
export const loadJobSuccess = createAction(Job.LOAD_JOB_SUCCESS);
export const loadJobFail = createAction(Job.LOAD_JOB_FAIL);

export const updateJob = createAction(Job.UPDATE_JOB);
export const updateJobSuccess = createAction(Job.UPDATE_JOB_SUCCESS);
export const updateJobFail = createAction(Job.UPDATE_JOB_FAIL);
