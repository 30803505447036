import { Button, Card, createMuiTheme, Dialog, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import Util from '../../Util/Util';
import { removePoint } from '../../Api/pointApi';
import { toast } from 'react-toastify';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';
import get from 'lodash/get';
import moment from 'moment';
import NoPhotoAgent from '../../assets/image/no_agent_photo.png';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

function PointPaymentJobDetail({ detail, onClose, reload }) {
  const [removeVisible, setRemoveVisible] = useState(false);
  const onCloseRemove = () => {
    setRemoveVisible(false);
  };

  const handleRemove = () => {
    setRemoveVisible(true);
  };

  const handleDelete = async () => {
    await removePoint(detail.id).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div>
      {removeVisible && (
        <RemoveItemModal visible={removeVisible}
                         onClose={onCloseRemove}
                         handleSubmit={handleDelete}
                         title={MESSAGE_CONFIRM_DELETE.TITLE}
                         content={MESSAGE_CONFIRM_DELETE.CONTENT}
        />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        onClose={onClose}
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>会社詳細-入金ログ詳細</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                {detail?.agent?.phoneNumber}
              </p>
              <img className="object-fit-cover"
                   style={{
                     width: 30,
                     height: 30,
                     borderRadius: '50%',
                     marginRight: 20,
                   }}
                   src={
                     detail?.agent?.avatar ? detail?.agent?.avatar?.url : NoPhotoAgent
                   }
                   alt=""
              />
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {detail?.agent?.nickName}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card body style={{ marginBottom: 10, padding: 20 }}>
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>決済日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{moment(Number(get(detail, 'createdDate'))).format('YYYY-MM-DD hh:mm:ss')}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>入金種類</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{Util.paymentTypeIdToText(detail?.paymentType)}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>入金タイプ</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{Util.payingTypeIdToText(detail?.payingType)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>入金前残高</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{Util.separator(detail?.balancePaymentBefore)}円</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>入金後残高</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{Util.separator(detail?.balancePaymentAfter)}円</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>金額</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{Util.separator(detail?.price)}円</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>×購入期間</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={{ ...style, textAlign: 'end' }}>
                          <p>{detail?.month}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card style={{ padding: 20, marginBottom: 10 }}>
                  <Row>
                    <Col xs={3}>
                      <Label>メモ</Label>
                    </Col>
                    <Col xs={9}>
                      <Label style={{ whiteSpace: 'pre-line' }}>{detail?.memo}</Label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemove}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PointPaymentJobDetail;
