import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, MuiThemeProvider, Card } from '@material-ui/core';
import { loadListContacts } from '../../Redux/Actions/listContacts';
import _ from 'lodash';
import Util from '../../Util/Util';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

const pageSize = 1000;

function ListContacts() {
  const [filter] = useState({ sort: {} });
  const contacts = useSelector((state) => state.listContact.items);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadListContacts({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  return (
    <>
      <React.Fragment>
        <div className="space-top">&nbsp;</div>
        <div className="space-left">&nbsp;</div>
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top mt-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  {!_.isEmpty(contacts) &&
                  Object.keys(contacts[0]).map((key) => (
                    <TableCell>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.map((contact, index) => (
                  <TableRow key={contact.id} style={{ height: 48 }}>
                    <TableCell>{index + 1}</TableCell>
                    {Object.keys(contact).map((key) => (
                      <TableCell>
                        {['createdDate', 'updatedDate'].includes(key)
                          ? Util.timestampToDate(contact[key], 'YYYY/MM/DD')
                          : contact[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
      </React.Fragment>
    </>
  );
}

export default ListContacts;
