import { call, put, takeLatest } from 'redux-saga/effects';
import Agent from '../Types/agent';
import { getAgent, updateAgent } from '../../Api/agentAPI';
import { toast } from 'react-toastify';
import { loadAgentFail, loadAgentSuccess, updateAgentFail, updateAgentSuccess } from '../Actions/agent';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadAgent(action) {
  try {
    const response = yield call(getAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const agent = data.data;
      yield put(loadAgentSuccess(agent));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(loadAgentFail(error));
  }
}

function* handleUpdateAgent(action) {
  try {
    const response = yield call(updateAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const agent = data.data;
      yield put(updateAgentSuccess(agent));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateAgentFail(error));
  }
}

function* handleUpdateAgentLocal({ payload, type }) {
  try {
    yield put(loadAgentSuccess(payload));
  } catch (error) {
    !error?.response?.data?.message.includes('Cannot') &&
    toast.error(error?.response?.data?.message);
    yield put(updateAgentFail(error));
  }
}

export default function* agentSaga() {
  yield takeLatest(Agent.LOAD_AGENT, handleLoadAgent);
  yield takeLatest(Agent.UPDATE_AGENT, handleUpdateAgent);
  yield takeLatest(Agent.LOAD_AGENT_UPDATE_LOCAL, handleUpdateAgentLocal);
}
