import { call, put, takeLatest } from 'redux-saga/effects';
import Users from '../Types/users';
import { getUserIds, getUsers } from '../../Api/userAPI';
import { loadUserIdsFail, loadUserIdsSuccess, loadUsersFail, loadUsersSuccess } from '../Actions/users';

function* handleLoadUsers(action) {
  try {
    const response = yield call(getUsers, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadUsersSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadUsersFail(error));
  }
}

function* handleLoadUserIds(action) {
  try {
    const response = yield call(getUserIds, action.payload);
    const { data } = response;
    if (data.success) {
      const userIds = data.data;
      const totalUserIds = data.totalRow;
      yield put(loadUserIdsSuccess({ userIds, totalUserIds }));
    }
  } catch (error) {
    yield put(loadUserIdsFail(error));
  }
}

export default function* usersSaga() {
  yield takeLatest(Users.LOAD_USERS, handleLoadUsers);
  yield takeLatest(Users.LOAD_USER_IDS, handleLoadUserIds);
}
