import { createAction } from 'redux-actions';
import helpCms from '../Types/helpCms';

export const loadHelpCms = createAction(helpCms.LOAD_HELP_CMS);
export const loadHelpCmsSuccess = createAction(helpCms.LOAD_HELP_CMS_SUCCESS);
export const loadMHelpCmsFail = createAction(helpCms.LOAD_HELP_CMS_FAIL);

export const updateHelpCms = createAction(helpCms.UPDATE_HELP_CMS);
export const updateHelpCmsSuccess = createAction(helpCms.UPDATE_HELP_CMS_SUCCESS);
export const updateHelpCmsFail = createAction(helpCms.UPDATE_HELP_CMS_FAIL);

export const getByIdHelpCms = createAction(helpCms.GET_BY_ID_HELP_CMS);
export const getByIdHelpCmsSuccess = createAction(helpCms.GET_BY_ID_HELP_CMS_SUCCESS);
export const getByIdHelpCmsFail = createAction(helpCms.GET_BY_ID_HELP_CMS_FAIL);

export const createHelpCms = createAction(helpCms.CREATE_HELP_CMS);
export const createHelpCmsSuccess = createAction(helpCms.CREATE_HELP_CMS_SUCCESS);
export const createHelpCmsFail = createAction(helpCms.CREATE_HELP_CMS_FAIL);
