import React, { useState } from 'react';
import { Button, FormLabel, TextField } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../Components/RadioGroup';
import RadioCheckboxGroup from '../../Components/RadioCheckboxGroup';
import {
  imageProfStatusOptions,
  imageTypeOptions,
  searchBoxGenders,
} from '../../Util/utils';
import { GENDER_CHARACTER, GENDER, COLUMN_DEFINED_SEARCH_BOX } from '../../Common/constant';


function SearchBox({ onSubmit, labelDb }) {
  const [filter, setFilter] = useState({ gender: [GENDER.MALE, GENDER.FEMALE, GENDER_CHARACTER.MALE_CHAR, GENDER_CHARACTER.FEMALE_CHAR] }); // 1, 2, 3, 4

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    try {
      return onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    setFilter({ gender: [GENDER.MALE, GENDER.FEMALE, GENDER_CHARACTER.MALE_CHAR, GENDER_CHARACTER.FEMALE_CHAR] }); // 1, 2, 3, 4
  };

  const onChangeGender = (event) => {
    let newGender = [];
    if (filter.gender?.indexOf(Number(event.target.value)) > -1) {
      newGender = filter.gender.filter(
        (item) => item !== Number(event.target.value),
      );
    } else {
      newGender = [...filter.gender, Number(event.target.value)];
    }
    if (!Number(event.target.value) && filter.gender.length === 0) {
      newGender = [GENDER.MALE, GENDER.FEMALE, GENDER_CHARACTER.MALE_CHAR, GENDER_CHARACTER.FEMALE_CHAR]; // 1, 2, 3, 4
    }
    if (!Number(event.target.value) && filter.gender.length > 0) {
      newGender = [GENDER.MALE, GENDER.FEMALE, GENDER_CHARACTER.MALE_CHAR, GENDER_CHARACTER.FEMALE_CHAR]; // 1, 2, 3, 4
    }
    if (!Number(event.target.value) && filter.gender.length === 4) {
      newGender = [];
    }

    setFilter({ ...filter, gender: newGender });
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">プロフィール画像管理-検索条件入力</h4> */}
      <div className="form__search fake-test-search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1 }>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  label="電話番号"
                  type="text"
                  name="phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickname"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2 }>
            <FormGroup row>
              <Col sm={5} xs={5}>
                <TextField
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_lower"
                  onChange={onChange}
                />
              </Col>
              <Col xs={2} sm={2} className="flex-end d-col-2-symbol">
                〜
              </Col>
              <Col sm={5} xs={5}>
                <TextField
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_upper"
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3 }>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper d-item-checkbox">
                <FormLabel component="legend">性別</FormLabel>
                <RadioCheckboxGroup
                  classCustom="d-cus-item"
                  name="gender"
                  options={searchBoxGenders()}
                  onChange={onChangeGender}
                  checked={filter.gender}
                />
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginTop: 20 }}>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">写真種類</FormLabel>
                <RadioGroup
                  name="type"
                  defaultValue=""
                  options={imageTypeOptions()}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginTop: 20 }}>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">写真認証</FormLabel>
                <RadioGroup
                  classCustom="d-cus-item"
                  name="inspection"
                  defaultValue=""
                  options={imageProfStatusOptions()}
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
