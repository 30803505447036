import React from 'react';
import { toast } from 'react-toastify';
import { removeInformation } from '../../Api/informationAPI';
import { deleteImageStorage } from '../../Api/imageApi';
import Util from '../../Util/Util';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';

function RemoveInformationModal({ visible, onClose, informationId, reload, onCloseDetail, information, urlImageCacheEdit }) {
  const handleSubmit = async () => {
    await removeInformation(informationId).then((response) => {
      const { data } = response;
      if (data.success) {
        if (information?.urlImage) {
          deleteImageStorage(Util.getFilePath(information?.urlImage)).then();
        }
        if (urlImageCacheEdit) {
          deleteImageStorage(Util.getFilePath(urlImageCacheEdit)).then();
        }
        onClose();
        reload();
        onCloseDetail();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default RemoveInformationModal;
