import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card, MuiThemeProvider } from '@material-ui/core';
import { updateChildResource } from '../../Redux/Actions/resource';
import { useDispatch } from 'react-redux';
import './style.scss';
import Util from '../../Util/Util';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { toast } from 'react-toastify';
import { REGEX_NUMBER } from '../../Common/constant';

function ChildProfileList({
                            dataChildProfile,
                            handleEdit,
                            onClose,
                            isEditing,
                            changeDataChildProfile,
                          }) {
  const dispatch = useDispatch();

  const [dataProfile, setDataProfile] = useState({});
  const [inputUpdate, setInputUpdate] = useState({});
  const [filter, setFilter] = useState({
    sortBy: 'profile',
    orderBy: 'ASC',
    sort: { profile: 'ASC' },
  });

  useEffect(() => {
    const sortedEntries = Object.entries(dataChildProfile);
    sortedEntries.forEach((x) => (x[0] = '_' + x[0]));
    sortedEntries.sort(([, a], [, b]) => {
      if (b[0].profile > a[0].profile) {
        return -1;
      } else if (b[0].profile < a[0].profile) {
        return 1;
      }
      return 0;
    });
    const data = Object.fromEntries(sortedEntries);
    setDataProfile(data);
  }, [dataChildProfile]);

  const handleChangeLabel = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.label = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // let newData = { ...dataProfile };
    // changeDataChildProfile(newData);
  };

  const handleChangeValue = (e) => {
    const valueOld = e.target.dataset.valold;
    const value = e.target.value;
    // Validate
    if (!value) {
      toast.error('表示順を正しい値で入力してください。');
    } else if (value && !REGEX_NUMBER.test(value)) {
      toast.error('表示順を正しい値で入力してください。');
    } else if (value && (value <= 0 || value >= 100)) {
      toast.error('表示順を正しい値で入力してください。');
    }

    // Validate change value for order sort
    // if (dataProfile[`_${value}`] && dataProfile[`_${valueOld}`]) {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.value = e.target.value;
        }
        return item;
      });
    }
    setInputUpdate(newArray);
    // const newData = { ...dataProfile };
    // changeDataChildProfile(newData);
    // }
  };

  const handleChangeValueName = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.label = e.target.value;
        }
        return item;
      });
    }
    setInputUpdate(newArray);
    // const newData = { ...dataProfile };
    // changeDataChildProfile(newData);
  };

  const handleUpdate = async () => {
    // Validate
    const arrayData = {};
    const arrayDataValue = [];

    for (let key in inputUpdate) {
      arrayDataValue.push(inputUpdate[key].map(ele => ele.value));
      if (inputUpdate[key].length !== 4) {
        arrayData[key] = true;
      }

      for (let k in inputUpdate[key]) {
        if (isNaN(inputUpdate[key][k].value) || !inputUpdate[key][k].value) {
          arrayData[key] = true;
        }
      }

      let value0 = inputUpdate[key][0].value;
      const valueFilter = inputUpdate[key].filter(ele => !isNaN(ele.value) && +ele.value === +value0);

      if (valueFilter.length !== 4) {
        arrayData[key] = true;
      }
    }

    for (let i = 0; i < arrayDataValue.length; i++) {
      let value0 = arrayDataValue[i][0];
      for (let j = i + 1; j < arrayDataValue.length; j++) {
        const valueFilter = !isNaN(arrayDataValue[j][0]) && +arrayDataValue[j][0] === +value0;

        if (valueFilter) {
          arrayData[i] = true;
        }
      }
    }

    if (!Object.keys(arrayData).length) {
      dispatch(updateChildResource(inputUpdate));
      setDataProfile(inputUpdate); // Set update when change data
      changeDataChildProfile(inputUpdate); // Set update when change data
      onClose();
    } else {
      // Error
      toast.error('表示順は同じ値で入力してください。');
    }
  };

  const handleClose = () => {
    changeDataChildProfile({ ...dataProfile }); // Revert data old
    setInputUpdate({}); // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); // Clone deep data when use object
    handleEdit();
  };

  const onSort = (profile) => {
    if (isEditing) {
      return; // Not sort while editing
    }

    let sortable = {};
    if (filter.orderBy === 'ASC') {
      const sortedEntries = Object.entries(dataProfile).sort(([, a], [, b]) => {
        if (b[0][profile] > a[0][profile]) return 1;
        else if (b[0][profile] < a[0][profile]) return -1;
        return 0;
      });
      sortable = sortedEntries.reduce((prev, [k, v]) => {
        prev[k] = v;
        return prev;
      }, {});
    } else
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (a[0][profile] > b[0][profile]) return 1;
          else if (a[0][profile] < b[0][profile]) return -1;
          return 0;
        }),
      );

    setFilter({
      ...filter,
      sortBy: profile,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [profile]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setDataProfile(sortable);
  };

  if (!dataProfile || dataProfile && !Object.keys(dataProfile).length) {
    return null;
  }

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div style={{ textAlign: 'right', margin: '10px 0' }}>
        {isEditing ? (
          <div className="my-3">
            <Button
              className="btn-custom btn-red"
              variant="contained"
              onClick={handleClose}
            >
              閉じる
            </Button>
            <Button
              className="btn-custom btn-blue"
              variant="contained"
              onClick={handleUpdate}
            >
              更新する
            </Button>
          </div>
        ) : (
          <Button
            className="btn-blue"
            style={{
              padding: '6px 14px',
              margin: 'auto',
              color: 'white',
              marginRight: 10,
            }}
            variant="contained"
            onClick={handleBtnEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table">
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ borderBottom: 'none', width: '22%' }}
              >
                男性
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ borderBottom: 'none', width: '22%' }}
              >
                女性
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ borderBottom: 'none', width: '22%' }}
              >
                キャラ男性
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ borderBottom: 'none', width: '22%' }}
              >
                キャラ女性
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">カラム名</TableCell>
              <TableCell align="center" style={{ width: '9%' }}>
                表示順（空欄NG）
              </TableCell>
              <TableCell style={{ width: '150px' }}>項目名（全角15文字）</TableCell>
              <TableCell align="center" style={{ width: '9%' }}>
                表示順（空欄NG）
              </TableCell>
              <TableCell style={{ width: '13%' }}>項目名</TableCell>
              <TableCell align="center" style={{ width: '9%' }}>
                表示順（空欄NG）
              </TableCell>
              <TableCell align="center" style={{ width: '13%' }}>
                項目名
              </TableCell>
              <TableCell align="center" style={{ width: '9%' }}>
                <SortArrows
                  sortObject={filter.sort}
                  text="表示順（空欄NG）"
                  name="value"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell style={{ width: '13%' }}>
                <SortArrows
                  sortObject={filter.sort}
                  text="項目名"
                  name="label"
                  onClick={onSort}
                />
              </TableCell>
            </TableRow>

            {Object.keys(dataProfile)?.map((child, index) => (
                <TableRow key={Util.findProfileId('male', dataProfile[child])}>
                  <TableCell style={{ width: '50px' }}>{index + 1}</TableCell>
                  <TableCell align="center">
                    {dataProfile[child][0].profile}
                  </TableCell>
                  <TableCell align="center" style={{ width: '16%' }}>
                    {isEditing ? (
                      <input
                        data-idold={Util.findProfileId('male', dataProfile[child])}
                        data-valold={Util.findValue('male', dataProfile[child])}
                        className="input-rank"
                        defaultValue={Util.findValue('male', dataProfile[child])}
                        name={`value.${Util.findProfileId('male', dataProfile[child])}`}
                        onChange={handleChangeValue}
                      />
                    ) : (
                      Util.findValue('male', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell style={{ width: '180px' }}>
                    {isEditing ? (
                      <input
                        style={{ width: '180px' }}
                        className="input-rank"
                        defaultValue={Util.findLabel('male', dataProfile[child])}
                        name={`label.${Util.findProfileId('male', dataProfile[child])}`}
                        onChange={handleChangeValueName}
                      />
                    ) : (
                      Util.findLabel('male', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell align="center" style={{ width: '16%' }}>
                    {isEditing ? (
                      <input
                        data-idold={Util.findProfileId('female', dataProfile[child])}
                        data-valold={Util.findValue('female', dataProfile[child])}
                        className="input-rank"
                        defaultValue={Util.findValue('female', dataProfile[child])}
                        name={`value.${Util.findProfileId('female', dataProfile[child])}`}
                        onChange={handleChangeValue}
                      />
                    ) : (
                      Util.findValue('female', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell style={{ width: '180px' }}>
                    {isEditing ? (
                      <input
                        style={{ width: '180px' }}
                        className="input-rank"
                        defaultValue={Util.findLabel('female', dataProfile[child])}
                        name={`label.${Util.findProfileId('female', dataProfile[child])}`}
                        onChange={handleChangeValueName}
                      />
                    ) : (
                      Util.findLabel('female', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell align="center" style={{ width: '16%' }}>
                    {isEditing ? (
                      <input
                        data-idold={Util.findProfileId('chara_male', dataProfile[child])}
                        data-valold={Util.findValue('chara_male', dataProfile[child])}
                        className="input-rank"
                        defaultValue={Util.findValue('chara_male', dataProfile[child])}
                        name={`value.${Util.findProfileId('chara_male', dataProfile[child])}`}
                        onChange={handleChangeValue}
                      />
                    ) : (
                      Util.findValue('chara_male', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell style={{ width: '180px' }}>
                    {isEditing
                      ? (<input
                        style={{ width: '180px' }}
                        className="input-rank"
                        defaultValue={Util.findLabel('chara_male', dataProfile[child])}
                        name={`label.${Util.findProfileId('chara_male', dataProfile[child])}`}
                        onChange={handleChangeValueName}
                      />)
                      : Util.findLabel('chara_male', dataProfile[child])
                    }
                  </TableCell>
                  <TableCell align="center" style={{ width: '9%' }}>
                    {isEditing ? (
                      <input
                        data-idold={Util.findProfileId('chara_male', dataProfile[child])}
                        data-valold={Util.findValue('chara_male', dataProfile[child])}
                        className="input-rank"
                        defaultValue={Util.findValue('chara_female', dataProfile[child])}
                        name={`value.${Util.findProfileId('chara_female', dataProfile[child])}`}
                        onChange={handleChangeValue}
                      />
                    ) : (
                      Util.findValue('chara_female', dataProfile[child])
                    )}
                  </TableCell>
                  <TableCell style={{ width: '180px' }}>
                    {isEditing ? (
                      <input
                        style={{ width: '180px' }}
                        className="input-rank"
                        defaultValue={Util.findLabel('chara_female', dataProfile[child])}
                        name={`label.${Util.findProfileId('chara_female', dataProfile[child])}`}
                        onChange={handleChangeValueName}
                      />
                    ) : (
                      Util.findLabel('chara_female', dataProfile[child])
                    )}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ChildProfileList;
