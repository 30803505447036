import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import React from 'react';
import Nophoto from '../../../assets/image/no_user_photo.png';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { TEXT_HTML_ADD_POINT_MODAL } from '../../../Common/modal_text_info';
import { themeStyleNormal1Modal } from './theme_modal';
import Util from '../../../Util/Util';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    fontSize: 18,
    marginBottom: 0,
  },
}));

function StripeAgentModal({ visible, onClose, setPriceBalancePayment, priceBalancePayment, nickName, avatar, labelDb, agent, setCheckoutBalance }) {
  const classes = useStyles();

  const onChange = (event) => {
    setPriceBalancePayment(event.target.value ? event.target.value : 0);
  };

  const handleConfirm = () => {
    let check = true;
    if (!priceBalancePayment || priceBalancePayment === '') {
      check = false;
      toast.error('金額を入力してください');
    } else if (priceBalancePayment < 50) {
      check = false;
      toast.error('決済額は50円以上でご指定下さい');
    } else if (+priceBalancePayment > +agent?.balancePayment) {
      check = false;
      toast.error('利用残高以上で決済はできません');
    }

    if (check) {
      setCheckoutBalance({ check });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>クレジット決済</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>受信者</p>
              <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>{nickName}</p>
              <img className="object-fit-cover"
                   style={{ width: 30, height: 30, borderRadius: '50%' }}
                   src={avatar ? avatar.url : Nophoto}
                   alt=""
              />
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_ADD_POINT_MODAL }}></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={themeStyleNormal1Modal}>
            <Card>
              <Row style={{ padding: 20 }}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="d-flex flex-column">
                      <p style={{ marginBottom: 5 }}>銀行振込によるお支払いはサポートへお問い合わせ下さい。</p>
                      <p>クレジットによるお支払いは金額をご記入し、「お支払いに進む」をクリックして下さい。</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FormControl style={{ marginTop: 20 }} className={classes.formControl}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}>
                          <span>支払残高</span>
                          <span>{Util.separator(+agent?.balancePayment)}円</span>
                        </div>
                      </FormControl>
                      <FormControl style={{ marginTop: 20 }} className={classes.formControl}>
                        <InputLabel className={classes.inputLabel} shrink><span
                          className="text-red">*</span>金額</InputLabel>
                        <Input
                          type="number"
                          style={{
                            width: '100%',
                            marginBottom: '20px',
                          }}
                          name="price"
                          onChange={onChange}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </MuiThemeProvider>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button
              onClick={handleConfirm}
              color="primary"
              className="btn-custom btn-blue"
            >
              お支払いに進む
            </Button>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default StripeAgentModal;
