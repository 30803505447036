import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createHelpCmsFail,
  createHelpCmsSuccess,
  getByIdHelpCmsFail,
  getByIdHelpCmsSuccess,
  loadHelpCmsSuccess,
  loadMHelpCmsFail,
  updateHelpCmsFail,
  updateHelpCmsSuccess,
} from '../Actions/helpCms';
import { createHelp, getHelpById, getHelps, updateHelp } from '../../Api/helpAPI';
import HelpCms from '../Types/helpCms';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadHelpCms(action) {
  try {
    const response = yield call(getHelps, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(loadHelpCmsSuccess(data));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(loadMHelpCmsFail(error));
  }
}

function* handleCreateHelpCms(action) {
  try {
    const response = yield call(createHelp, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(createHelpCmsSuccess(admin));
      toast.success(TOAST_MESSAGE.SUCCESS_ADD);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_ADD);
    }
  } catch (error) {
    yield put(createHelpCmsFail(error));
    toast.error(error?.response?.data?.message);
  }
}

function* handleUpdateHelpCms(action) {
  try {
    const response = yield call(updateHelp, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(updateHelpCmsSuccess(admin));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateHelpCmsFail(error));
  }
}

function* handleGetByIdHelpCms(action) {
  try {
    const response = yield call(getHelpById, action.payload);
    const { data } = response;
    if (data.success) {
      const admin = data.data;
      yield put(getByIdHelpCmsSuccess(admin));
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    yield put(getByIdHelpCmsFail(error));
  }
}

export default function* helpCmsSaga() {
  yield takeLatest(HelpCms.LOAD_HELP_CMS, handleLoadHelpCms);
  yield takeLatest(HelpCms.CREATE_HELP_CMS, handleCreateHelpCms);
  yield takeLatest(HelpCms.UPDATE_HELP_CMS, handleUpdateHelpCms);
  yield takeLatest(
    HelpCms.GET_BY_ID_HELP_CMS,
    handleGetByIdHelpCms,
  );
}
