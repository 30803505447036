import { handleActions } from 'redux-actions';
import Operator from '../Types/operator';

const initialState = {
  data: null,
  loading: false,
  updating: false,
  success: false,
  updated: false,
};

const actions = {
  [Operator.LOAD_DEATIL_OPERATOR]: (state) => ({
    ...state,
    loading: true,
    success: false,
    updated: false
  }),
  [Operator.LOAD_DEATIL_OPERATOR_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    success: false,
  }),
  [Operator.LOAD_DEATIL_OPERATOR_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
  [Operator.UPDATE_OPERATOR]: (state) => ({
    ...state,
    updating: true,
  }),
  [Operator.UPDATE_OPERATOR_SUCCESS]: (state) => ({
    ...state,
    updating: false,
    success: true,
    updated: true,
  }),
  [Operator.UPDATE_OPERATOR_FAIL]: (state) => ({
    ...state,
    updating: false,
  }),
  [Operator.OPERATOR_LOG_OUT]: (state) => ({
    ...state,
    updating: false,
    data: null,
  }),
};
export default handleActions(actions, initialState);
