import { createAction } from 'redux-actions';
import NameStateAgent from '../Types/stateAgent';

export const loadStateAgent = createAction(NameStateAgent.LOAD_STATE_AGENT);
export const loadStateAgentSuccess = createAction(NameStateAgent.LOAD_STATE_AGENT_SUCCESS);
export const loadStateAgentFail = createAction(NameStateAgent.LOAD_STATE_AGENT_FAIL);

export const loadAllAreaAgent = createAction(NameStateAgent.LOAD_ALL_AREA_AGENT);
export const loadAllAreaAgentSuccess = createAction(NameStateAgent.LOAD_ALL_AREA_AGENT_SUCCESS);
export const loadAllAreaAgentFail = createAction(NameStateAgent.LOAD_ALL_AREA_AGENT_FAIL);

export const updateNameStateAgent = createAction(NameStateAgent.UPDATE_STATE_AGENT);
export const updateNameStateAgentSuccess = createAction(NameStateAgent.UPDATE_STATE_AGENT_SUCCESS);
export const updateNameStateAgentFail = createAction(NameStateAgent.UPDATE_STATE_AGENT_FAIL);
