import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  createMuiTheme,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
} from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import { paymentTypeOptionsRequired } from '../../Util/utils';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../Common/constant';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
  },
});

const defaultFilter = {
  phone: '',
  pointGetLower: '',
  pointGetUpper: '',
  paymentDateLower: '',
  paymentDateUpper: '',
  nickName: '',
  paymentType: [],
};

function SearchBox(props) {
  const { labelDb } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState(defaultFilter);

  const onChange = (event) => {
    const filterClone = { ...filter };
    filterClone[event.target.name] = event.target.value.trim();
    setFilter(filterClone);
  };

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    setFilter(defaultFilter);
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
  };

  const renderValueSelected = () => {
    return paymentTypeOptionsRequired()
      .filter(ele => filter.paymentType.includes(ele.value))
      .sort()
      .map(item => item?.label)
      .join(', ');
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">入金管理-検索条件入力</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  label="電話番号"
                  type="text"
                  name="phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickName"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}>
            <MuiThemeProvider theme={theme}>
              <FormGroup row>
                <Col xs={5}>
                  <TextField
                    id="date"
                    label="登録日"
                    className="w-100"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="paymentDateLower"
                    onChange={onChange}
                  />
                </Col>
                <Col xs={2} className="flex-end d-col-2-symbol">〜</Col>
                <Col xs={5}>
                  <TextField
                    id="date"
                    label="登録日"
                    className="w-100"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="paymentDateUpper"
                    onChange={onChange}
                  />
                </Col>
              </FormGroup>
            </MuiThemeProvider>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <FormGroup row>
              <Col xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>入金種類</InputLabel>
                  <Select
                    multiple
                    value={filter.paymentType}
                    onChange={handleChange}
                    name="paymentType"
                    input={<Input />}
                    renderValue={renderValueSelected}
                    style={{ width: '100%' }}
                    className={classes.selectEmpty}
                  >
                    {paymentTypeOptionsRequired()
                      ?.filter((item) => item.label)
                      .map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                          <Checkbox
                            value={option}
                            checked={filter.paymentType.indexOf(option.value) > -1}
                          />
                          <ListItemText
                            primary={`${option.value}: ${option.label}`}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
