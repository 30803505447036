import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { loadPoint } from '../../Redux/Actions/point';
import PointPaymentList from './PointPaymentList';
import SearchBox from './SearchBox';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function Index() {
  const [filter, setFilter] = useState({
    sort: { 'paymentLog.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.point.loading);
  const point = useSelector((state) => state.point.items);
  const totalRow = useSelector((state) => state.point.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPoint({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadPoint({ ...filter, pageIndex, pageSize }));
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...filter, ...searchFilter });
    setPageIndex(1);
    dispatch(loadPoint({ ...filter, ...searchFilter, pageIndex: 1, pageSize }));
  };

  const reload = () => {
    dispatch(loadPoint({ pageIndex, pageSize, ...filter }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  return (
    <div>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />
      <LoadingBar show={loading} />
      <PointPaymentList
        reload={reload}
        point={point}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
