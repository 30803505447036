import React from 'react';
import { FormLabel } from '@material-ui/core';
import { DatePicker } from 'antd';
import { Col, FormGroup } from 'reactstrap';
import jaJP from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import style from './styles.module.scss';

dayjs.locale('ja');

export default function AntDateTimeRangePicker({
  title,
  titleStyle,
  timestamp,
  onChange,
  showTime,
  defaultValue,
  value,
}) {
  return (
    <React.Fragment>
      <FormGroup row>
        <Col xs={12}>
          <FormLabel className={style.labelTitle} component="legend">
            {title}
          </FormLabel>
        </Col>

        <Col className="datePicker" xs={12}>
          <DatePicker.RangePicker
            showTime={showTime && { format: 'HH:mm' }}
            format={`YYYY-MM-DD ${showTime ? 'HH:mm' : ''}`}
            locale={jaJP}
            defaultValue={
              !defaultValue
                ? null
                : defaultValue.map((e) => (e ? dayjs(e) : null))
            }
            value={!value ? null : value.map((e) => (e ? dayjs(e) : null))}
            defaultOpenValue={dayjs()}
            onChange={onChange}
            style={{ width: '90%' }}
            suffixIcon={<span className="material-icons">calendar_today</span>}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
}
