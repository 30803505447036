import { call, put, takeLatest } from 'redux-saga/effects';
import NameState from '../Types/state';
import { getAllArea, getNameState, updateNameState } from '../../Api/resourseApi';
import {
  loadAllAreaFail,
  loadAllAreaSuccess,
  loadStateFail,
  loadStateSuccess,
  updateNameStateFail,
  updateNameStateSuccess,
} from '../Actions/nameState';

function* handleLoadNameState(action) {
  try {
    const response = yield call(getNameState, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadStateSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadStateFail(error));
  }
}

function* handleLoadAllArea(action) {
  try {
    const response = yield call(getAllArea, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadAllAreaSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadAllAreaFail(error));
  }
}

function* handleUpdateNameState(action) {
  try {
    const response = yield call(updateNameState, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(updateNameStateSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(updateNameStateFail(error));
  }
}

export default function* nameStateSaga() {
  yield takeLatest(NameState.LOAD_STATE, handleLoadNameState);
  yield takeLatest(NameState.LOAD_ALL_AREA, handleLoadAllArea);
  yield takeLatest(NameState.UPDATE_STATE, handleUpdateNameState);
}
