import React from 'react';
import { toast } from 'react-toastify';
import { deleteFavoriteJob } from '../../Api/favoriteJobLogApi';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';

function RemoveFavoriteJobModal({
                                  visible,
                                  onClose,
                                  favoriteId,
                                  targetId,
                                  reload,
                                  onCloseDetail,
                                }) {

  const handleSubmit = async () => {
    if (!favoriteId || !targetId) {
      return;
    }

    await deleteFavoriteJob({ favoriteId, targetId }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        onCloseDetail();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default RemoveFavoriteJobModal;
