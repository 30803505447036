import { call, put, takeLatest } from 'redux-saga/effects';
import { getListConversation } from '../../Api/conversation';
import { loadListConversationFail, loadListConversationSuccess } from '../Actions/listConversation';
import ListConversation from '../Types/listConversation';

function* handleLoadListConversation(action) {
  try {
    const response = yield call(getListConversation, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListConversationSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListConversationFail(error));
  }
}

export default function* listConversationSaga() {
  yield takeLatest(ListConversation.LOAD_LIST_CONVERSATION, handleLoadListConversation);
}
