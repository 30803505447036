import React, { useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Util from '../../Util/Util';
import RemoveContactModal from './RemoveContactModal';
import moment from 'moment';
import get from 'lodash/get';
import ImageModal from '../../Components/ImageModal';
import { updateContact } from '../../Api/contactApi';
import { toast } from 'react-toastify';
import { TEXT_HTML_MANAGEMENT_CONTACTS_LINK_MODAL } from '../../Common/modal_text_info';
import { convertConfirmTypeText, convertContactTypeText } from '../../Util/utils';
import { CONFIRM_TYPE } from '../../Common/constant';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

function ContactDetailModal(props) {
  const { onClose, contact, reload, onUpdateConfirm, labelDb } = props;
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [previewingImage, setPreviewingImage] = useState(null);

  const [confirmText, setConfirmText] = useState(
    contact.confirm ? '未確認する' : '確認済みにする',
  );

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemoveContact = () => {
    setConfirmVisible(true);
  };

  const handleShowImageModal = (image) => {
    setPreviewingImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  const updateConfirm = () => {
    if (contact) {
      contact.confirm = contact.confirm === CONFIRM_TYPE.CONFIRMED ? CONFIRM_TYPE.UN_CONFIRMED : CONFIRM_TYPE.CONFIRMED;
      updateContact({ id: contact.id, confirm: contact.confirm })
        .then((res) => {
          if (res.data.success) {
            setConfirmText(contact.confirm === CONFIRM_TYPE.CONFIRMED ? '未確認する' : '確認済みにする');
            onUpdateConfirm(contact);
            toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_EDIT);
          }
        })
        .catch((error) => {
          toast.success(error?.response?.data?.message || error.message);
        });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveContactModal
          visible={confirmVisible}
          onClose={handleClose}
          contactId={contact?.id}
          reload={reload}
          onCloseDetail={onClose}
          contact={contact}
        />
      )}
      <>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={!!contact}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>問合せ-詳細</DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_CONTACTS_LINK_MODAL }}></div>
          </div>

          <DialogContent>
            <MuiThemeProvider theme={theme}>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue float-right"
                    onClick={() => updateConfirm()}
                    style={{ marginBottom: 10 }}
                  >
                    {confirmText}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card body style={{ marginBottom: '1rem' }}>
                    <Row>
                      <Col xs={6}>
                        <Row>
                          <Label xs={6} style={style}>
                            <p>日時</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>
                              {' '}
                              {moment(Number(get(contact, 'createdDate'))).format('YYYY-MM-DD hh:mm:ss')}
                            </p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>種類</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{convertContactTypeText(contact?.type)}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>電話番号</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{contact?.user?.phoneNumber ?? ''}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{labelDb?.label_nickName?.content}</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{ contact?.user?.nickName ?? contact?.fullname }</p>
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Label xs={6} style={style}>
                            <p>性別</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>
                              {Util.genderIdToText(get(contact, 'user.gender'))}
                            </p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>メールアドレス</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{get(contact, 'email')}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>連絡先</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{get(contact, 'phone_number')}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>問合せ内容</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>{get(contact, 'title')}</p>
                            <hr style={stylehrright} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>確認状態</p>
                            <hr style={stylehrleft} />
                          </Label>
                          <Label xs={6} style={style}>
                            <p>
                              {convertConfirmTypeText(contact?.confirm)}
                            </p>
                            <hr style={stylehrright} />
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <Card body style={{ marginBottom: '1rem' }}>
                    <Row>
                      <Label xs={2}>本文</Label>
                      <Label xs={10} style={{ whiteSpace: 'pre-line' }}>
                        {get(contact, 'content')}
                      </Label>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card body style={{ marginBottom: 10 }}>
                    <Row>
                      {get(contact, 'attachments') ? (
                        <>
                          {get(contact, 'attachments').map((image) => (
                            <Col xs={2} style={{ marginTop: 20 }}>
                              <img
                                key={image.id}
                                style={{
                                  width: 250,
                                  objectFit: 'cover',
                                  margin: 2.5,
                                }}
                                src={image.url}
                                alt=""
                                onClick={() => handleShowImageModal(image)}
                              />
                            </Col>
                          ))}
                        </>
                      ) : null}
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Button
                variant="contained"
                className="btn-custom btn-red float-right"
                onClick={handleRemoveContact}
                style={{ marginBottom: 2 }}
              >
                削除
              </Button>
              {previewingImage && (
                <ImageModal
                  image={previewingImage}
                  onClose={handleHideImageModal}
                />
              )}
            </MuiThemeProvider>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default ContactDetailModal;
