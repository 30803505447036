import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import './style.scss';
import { getDetailRootResourceItemJob } from '../../Redux/Actions/resourceItemJob';
import ChildNameItemJobList from './ChildNameItemJobList';
import RootNameItemJobList from './RootNameItemJobList';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

function NameItemJobList({ rootResource = [], loading, setIsEditing }) {
  const [dataRootItemJob, setDataRootItemJob] = useState({});
  const [dataChildItemJob, setDataChildItemJob] = useState({});
  const [isEditingRoot, setIsEditingRoot] = useState(false);
  const [isEditingChild, setIsEditingChild] = useState(false);
  const childResource = useSelector((state) => state.resourceItemJob.dataChild);
  const [childItemJob, setChildItemJob] = useState(null);
  const [rootItemJob, setRootItemJob] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let rootItemJobGroup = groupBy(rootResource, ele => ele.item);

    setDataRootItemJob(rootItemJobGroup);
  }, [rootResource]);

  useEffect(() => {
    let childProfileGroup = groupBy(childResource, ele => ele.value);

    setDataChildItemJob(childProfileGroup);
  }, [childResource]);

  const handleEditRootProfile = () => {
    setIsEditingRoot(true);
  };

  const handleCloseEditRootProfile = () => {
    setIsEditingRoot(false);
  };

  const handleEditChildProfile = () => {
    setIsEditingChild(true);
  };

  const handleCloseEditChildProfile = () => {
    setIsEditingChild(false);
  };

  const handleDetailRootProfile = () => {
    setRootItemJob(true);
    setIsEditingChild(false);
    setChildItemJob(null);
  };

  const handleClickDetail = (itemGroup) => {
    dispatch(getDetailRootResourceItemJob(itemGroup));
    setChildItemJob(itemGroup);
    setRootItemJob(false);
    setIsEditingRoot(false);
  };

  const changeDataRootProfile = (data) => {
    setDataRootItemJob(data); // Change data update after fetch api
    setRootItemJob(data);
  };

  const changeDataChildProfile = (data) => {
    setChildItemJob(data);
  };

  const showNameProfile = (nameProfile) => {
    return nameProfile.map(item =>
      <TableRow>
        <TableCell>{item.item}</TableCell>
        <TableCell>{item.label}</TableCell>
        <TableCell>{item.itemOrder}</TableCell>
        <TableCell>{item.searchOrder}</TableCell>
        <TableCell>{item.searchType}</TableCell>
        <TableCell>
          <Button
            className="btnEditProfile"
            variant="contained"
            onClick={() => handleClickDetail(item.item)}
          >
            表示
          </Button>
        </TableCell>
      </TableRow>,
    );
  };

  return (
    <>
      <div className="m-2 mt-3 mb-3">
        <span style={{ marginRight: 105 }}>大項目</span>
        <span style={{ marginRight: 150 }}>大項目名設定</span>
        <Button
          className="btnEditRootProfile"
          variant="contained"
          onClick={handleDetailRootProfile}
        >
          表示（大項目）
        </Button>
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card style={{ display: 'flex', overflowX: 'hidden' }}>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>カラム名</TableCell>
                <TableCell>項目名</TableCell>
                <TableCell>表示順</TableCell>
                <TableCell>検索表示順</TableCell>
                <TableCell>検索方法(1Check/2Radio/3Range)</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {Object.values(dataRootItemJob)
                .slice(0, 13)
                .map((itemGroup) => showNameProfile(itemGroup))}
            </TableBody>
          </Table>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>カラム名</TableCell>
                <TableCell>項目名</TableCell>
                <TableCell>表示順</TableCell>
                <TableCell>検索表示順</TableCell>
                <TableCell>検索方法(1Check/2Radio/3Range)</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {Object.values(dataRootItemJob)
                .slice(13)
                .map((itemGroup) => showNameProfile(itemGroup))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>

      {childItemJob && (
        <ChildNameItemJobList
          dataChildItemJob={dataChildItemJob}
          handleEdit={handleEditChildProfile}
          onClose={handleCloseEditChildProfile}
          isEditing={isEditingChild}
          changeDataChildProfile={changeDataChildProfile}
        />
      )}

      {rootItemJob && (
        <RootNameItemJobList
          dataRootItemJob={dataRootItemJob}
          handleEdit={handleEditRootProfile}
          onClose={handleCloseEditRootProfile}
          isEditing={isEditingRoot}
          changeDataRootProfile={changeDataRootProfile}
        />
      )}
    </>
  );
}

export default NameItemJobList;
