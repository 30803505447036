import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import Util from '../../../Util/Util';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import NoAvatar from '../../../assets/image/sys_photo.png';
import { CHAT_MESSAGE_TYPE } from '../../../Common/constant';

function TimerChatRoomList(props) {
const {
  data = [],
  pageIndex,
  pageSize,
  loading,
  filter = { sort: {} },
  onSort = (sortByKey) => {
  },
  handleClickDetail,
  labelDb
} = props;

  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* sender */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="senderPhoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="senderNickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>オペNum</TableCell>
                <TableCell>会社Num</TableCell>

                {/* receiver */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="receiverPhoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="受信者"
                    name="receiverNickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>本文</TableCell>
                <TableCell>画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="送信予定日時"
                    name="sendTime"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="作成日"
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>表示</TableCell>
              </TableRow>

              {data.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{item.sender?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${item.sender?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => localStorage.setItem('userId', item.sender?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          item.sender?.avatar
                            ? item.sender.avatar.url
                            : NoAvatar
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{item.sender?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(item.sender?.gender)}
                  </TableCell>
                  <TableCell>{item.sender?.operator?.operatorNumber}</TableCell>
                  <TableCell>{item.sender?.agent?.agentNumber}</TableCell>
                  <TableCell>
                    <span>{item.receiver?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/user/${item.receiver?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => localStorage.setItem('userId', item.receiver?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          item.receiver?.avatar
                            ? item.receiver.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{item.receiver?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(item.receiver?.gender)}
                  </TableCell>
                  <TableCell>
                    {Number(item.type) === CHAT_MESSAGE_TYPE.TEXT ? item.content : ''}
                  </TableCell>
                  <TableCell>
                    {Number(item.type) === CHAT_MESSAGE_TYPE.IMAGE ? <img className="imgSquare" alt="" src={item.content} /> : ''}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(item.sendTime, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(item.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell  className="d-color-link" style={{ cursor: 'pointer' }}>
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default TimerChatRoomList;
