import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { loadChatLog } from '../../Redux/Actions/chatLog';
import ChatLogDetailModal from './ChatLogDetailModal';
import ChatLogList from './ChatLogList';
import ChatLogSearchBox from './ChatLogSearchBox';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function ChatLogUser() {
  const loading = useSelector((state) => state.chatLog.loading);
  const chatLog = useSelector((state) => state.chatLog.items);
  const totalRow = useSelector((state) => state.chatLog.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'message.createdDate': 'DESC' },
  });
  const [chatDetail, setChatDetail] = useState(null);

  const handleClickDetail = (chat) => {
    setChatDetail(chat);
  };

  const handleClose = () => {
    setChatDetail(null);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadChatLog({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const refreshList = () => {
    dispatch(loadChatLog({ ...filter, pageIndex, pageSize }));
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
    dispatch(
      loadChatLog({ ...searchFilter, pageIndex: 1, pageSize, sort: filter.sort }),
    );
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadChatLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read

    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const reload = () => {
    dispatch(loadChatLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const onClear = () => setFilter({ sort: { 'message.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 10 }}>
      <ChatLogSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      <LoadingBar show={loading} />
      {chatDetail && (
        <ChatLogDetailModal
          onClose={handleClose}
          chatLog={chatDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <ChatLogList
        chatLog={chatLog}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onUpdate={refreshList}
        // for sorting
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default ChatLogUser;
