import React, { useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextWebPageItem from './TextWebPageItem';
import SortArrows from '../../Components/SortArrows';
import { Card } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

import TextWebPageFormModal from './TextWebPageFormModal';

function TextWebPageList(props) {
  const {
    textWebPages,
    onSuccess = () => {
    },
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
  } = props;

  const [modalVisible, setModalVisible] = useState(null);

  const handleEdit = (m) => {
    setModalVisible(m);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>説明</TableCell>
              <TableCell style={{ width: '30%' }}>表示テキスト</TableCell>
              <TableCell style={{ maxWidth: 120 }}>
                <SortArrows
                  sortObject={filter.sort}
                  text="URL(link)"
                  name="link"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="表示設定(0:On/1:Off)"
                  name="displaySetting"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>表示順(管理）</TableCell>
              <TableCell></TableCell>
            </TableRow>

            {textWebPages.map((textWebPage) => (
              <TextWebPageItem
                key={textWebPage.id}
                item={textWebPage}
                handleEdit={handleEdit}
              />
            ))}

          </TableBody>
        </Table>
      </Card>

      {modalVisible && (
        <TextWebPageFormModal
          textWebPage={modalVisible}
          onClose={() => setModalVisible(null)}
          onSuccess={onSuccess}
        />
      )}
    </MuiThemeProvider>
  );
}

export default TextWebPageList;
