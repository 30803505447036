import { call, put, takeLatest } from 'redux-saga/effects';
import FootprintLog from '../Types/footprintLog';
import { getFootprints, getFootprintsByMe } from '../../Api/footprintLogApi';
import { loadFootprintLogFail, loadFootprintLogSuccess } from '../Actions/footprintLog';

function* handleLoadFootprintLog(action) {
  try {
    const response = yield call(getFootprints, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFootprintLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFootprintLogFail(error));
  }
}

function* handleLoadFootprintByMe(action) {
  try {
    const response = yield call(getFootprintsByMe, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFootprintLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFootprintLogFail(error));
  }
}

export default function* footprintLogSaga() {
  yield takeLatest(FootprintLog.LOAD_FOOTPRINTLOG, handleLoadFootprintLog);
  yield takeLatest(FootprintLog.LOAD_FOOTPRINTS, handleLoadFootprintByMe);
}
