import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { loadUserIds } from '../../Redux/Actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, sendSmsListUser, sendSmsUser, setTimeSms } from '../../Api/userAPI';
import LoadingBar from '../../Components/LoadingBar';
import { FormLabel } from '@material-ui/core';
import { Card, Col, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import RadioGroup from '../../Components/RadioGroup';
import { CONVERSION_TAG_URL, DISPLAY_OPTIONS, GENDER, TIMER_SENDING_TYPE } from '../../Common/constant';
import { conversionTags } from '../../Api/conversionTagApi';
import { loadEnvInfo } from '../../Redux/Actions/envInfo';
import Util from '../../Util/Util';
import { TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LINK_MODAL } from '../../Common/modal_text_info';
import { convertDisplayOptionsText, displayOptionsOptionsRequired } from '../../Util/utils';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { CODE_MAP_DEFINED_API } from '../../Config/listConstants';


const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};
// const stylehrleft = {
//   width: '100%',
//   backgroundColor: 'rgba(0,0,0,.125)',
//   margin: 0,
//   position: 'absolute',
//   top: 18,
// };
// const stylehrright = {
//   width: '90%',
//   backgroundColor: 'rgba(0,0,0,.125)',
//   margin: 0,
//   position: 'absolute',
//   top: 18,
// };

function SendSmsModal({ visible, onClose, filter }) {
  const [data, setData] = useState();
  const [userIds, setUsersId] = useState([]);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState(new Date(moment().startOf('day')).getTime());
  const [timeSend, setTimeSend] = useState();
  const [timerSms, setTimerSms] = useState(`${DISPLAY_OPTIONS.OFF}`);

  const [conversionTag, setConversionTag] = useState([]);
  const [contentHtml, setContentHtml] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, [dispatch]);

  const users = useSelector((state) => state.users.itemsUserIds);
  const totalRow = useSelector((state) => state.users.totalRowUserids);
  const envInfo = useSelector((state) => state.envInfo.data);

  // const envInfoTag = envInfo.filter(item => 'site_name,contact_mail_address,url_lp,contact_phone_number,url_contact'.includes(item.code));
  const envInfoTag = envInfo.filter(item => [
      CODE_MAP_DEFINED_API.SITE_NAME,
      CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
      CODE_MAP_DEFINED_API.URL_LP,
      CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
      CODE_MAP_DEFINED_API.URL_CONTACT,
    ].includes(item.code),
  );

  useEffect(() => {
    dispatch(loadUserIds(filter));
    conversionTags({}).then((res) => {
      const { data: tags } = res;
      setConversionTag((tags?.data || []));
    });
  }, [dispatch, filter]);

  useEffect(() => {
    const userIds = users.map((item) => item.id);
    setUsersId(userIds.join(','));
  }, [users]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!userIds) {
      setLoading(false);
      toast.error('受信者数が０人のため、SMS送信できません。');
      return;
    }
    if (data
      && (data.indexOf('%%LOGIN_') > -1
        || data.indexOf('%NICKNAME%') > -1
        || data.indexOf('%URL_LP%') > -1
        || data.indexOf('%MAIL_ADDRESS%') > -1
        || data.indexOf('%PHONE_NUMBER%') > -1
        || data.indexOf('%URL_CONTACT%') > -1
        || data.indexOf('%PROF_POINT%') > -1
        || data.includes('%%PROFILE_SQLID')
        || data.indexOf('%SITE_NAME%') > -1)) {
      return sendMultiUrl(data);
    } else {
      const content = await replaceTag(data, false, null);

      if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.OFF) {
        await sendSmsListUser({ userIds, data: content }).then((response) => {
          const { data } = response;
          if (data.success) {
            onClose();
            setLoading(false);
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            setLoading(false);
          }
        });
      } else {
        const ids = userIds.split(',');
        await setTimeSms({
          sendTime: timeSend,
          userIds: ids,
          content: content,
          type: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
        }).then((response) => {
          const { data } = response;
          if (data.success) {
            onClose();
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            setConFirmVisible(false);
          }
        });
      }
    }
  };

  const onChange = (event) => {
    const value = event.target.value.trimLeft().trimRight();
    setData(value);
  };

  const handleConfirm = () => {
    if (!data) {
      return toast.error('本文してください!');
    }

    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;
    if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON && checkTime && checkTime < new Date().getTime()) {
      return toast.error('予約時間が過去に設定されています');
    }
    replaceUrlPreview(data);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const sendMultiUrl = (content) => {
    if (users) {
      users.map(async (t) => {
        getUser(t.id).then(async (res) => {
          const { data: userDetail } = res;
          if (userDetail.data.password) {
            const user = userDetail.data;
            const contentData = await replaceTag(content, false, {
              phoneNumber: user.phoneNumber,
              password: user.password,
              nickName: user.nickName,
              depositpoint: user.depositpoint,
              needOtp: user.needOtp,
            });
            if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.OFF) {
              await sendSmsUser({ id: t.id, data: contentData });
            } else {
              await setTimeSms({
                sendTime: timeSend,
                userIds: [t.id],
                type: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
                content: contentData,
              });
            }
          }
        });
      });
      toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      onClose();
    }
  };

  const replaceProfileSQLId = async (content, preview, phoneNumber, password, needOtp) => {
    if (content.includes('%%PROFILE_SQLID')) {
      const item = conversionTag.find(t => t.tag === '%%PROFILE_SQLID_UUID%%');
      if (item) {
        const userSendId = (content.split('%%PROFILE_SQLID_')[1] + '').substring(0, 36);
        const IsGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(userSendId);
        if (IsGuid) {
          let user = null;
          try {
            const response = await getUser(userSendId);
            const { data: userData } = response;
            user = userData.data;
            const myRegex = new RegExp(`%%PROFILE_SQLID_${userSendId}%%`, 'g');
            const path = `-user/${userSendId.replace(/-/g, '*')}`;
            const params = `${phoneNumber}-${password || ''}${path}`;

            const url = Util.buildLink('/autologin/?id=' + btoa(params));
            const userInfo = `◇${user.nickName}　${Util.calcAge(Util.timestampToDate(user.birthday, 'YYYY-MM-DD'))}歳　${user.gender === GENDER.MALE ? '男性' : '女性'}　${user?.states?.label || ''}`;
            content = content.replace(myRegex, `<p>${userInfo}</p>${(preview ? (item.previewSms || item.contentSms) : item.contentSms)}`.replace(/#URL#/g, url));
          } catch (e) {
            content = content.replace(new RegExp(`%%PROFILE_SQLID_${userSendId}`, 'g'), 'PROFILE_SQLID_ERROR_TAG');
          }
        } else {
          const text = userSendId.includes('%%') ? userSendId.split('%%')[0] : userSendId;
          content = content.replace(new RegExp(`%%PROFILE_SQLID_${text}%%`, 'g'), 'PROFILE_SQLID_ERROR_TAG');
        }
        if (content.includes('%%PROFILE_SQLID')) {
          return replaceProfileSQLId(content, preview, phoneNumber, password, needOtp);
        }

        return content;
      }
    }
    return content;
  };

  const replaceTag = async (content, preview, userData) => {
    if (userData) {
      const { phoneNumber, password, nickName, depositpoint, needOtp } = userData;
      if (phoneNumber && password) {
        if (content.includes('%%PROFILE_SQLID')) {
          const contentProfile = await replaceProfileSQLId(content, preview, phoneNumber, password, needOtp);
          if (contentProfile) content = contentProfile;
        }
        for (const item of conversionTag) {
          const regex = new RegExp(item.tag, 'g');
          if (['%%LOGIN_HOME%%',
            '%%LOGIN_BUY_POINT%%',
            '%%LOGIN_MYPAGE%%',
            '%%LOGIN_INFO_1%%',
            '%%LOGIN_INFO_2%%',
            '%%LOGIN_INFO_3%%',
            '%%LOGIN_INFO_4%%',
            '%%LOGIN_INFO_5%%',
            '%%LOGIN_INFO_6%%',
            '%%LOGIN_INFO_7%%',
            '%%LOGIN_INFO_8%%',
            '%%LOGIN_MYPROFILE%%',
          ].includes(item.tag) && content.indexOf(item.tag) > -1) {
            let path = '';
            if (item.tag === '%%LOGIN_BUY_POINT%%') {
              path = '-point';
            }
            if (item.tag === '%%LOGIN_MYPAGE%%') {
              path = '-mypagemale';
            }
            if (item.tag === '%%LOGIN_INFO_1%%') {
              path = '-info/info1';
            }
            if (item.tag === '%%LOGIN_INFO_2%%') {
              path = '-info/info2';
            }
            if (item.tag === '%%LOGIN_INFO_3%%') {
              path = '-info/info3';
            }
            if (item.tag === '%%LOGIN_INFO_4%%') {
              path = '-info/info4';
            }
            if (item.tag === '%%LOGIN_INFO_5%%') {
              path = '-info/info5';
            }
            if (item.tag === '%%LOGIN_INFO_6%%') {
              path = '-info/info6';
            }
            if (item.tag === '%%LOGIN_INFO_7%%') {
              path = '-info/info7';
            }
            if (item.tag === '%%LOGIN_INFO_8%%') {
              path = '-info/info8';
            }
            if (item.tag === '%%LOGIN_MYPROFILE%%') {
              path = `-myprofile`;
            }

            const params = `${phoneNumber}-${password || ''}${path}`;
            const url = Util.buildLink('/autologin/?id=' + btoa(params));
            content = content.replace(regex, (preview ? (item.previewSms || item.contentSms) : item.contentSms).replace(/#URL#/g, url));
          } else if (item.tag === '%NICKNAME%' && content.indexOf(item.tag) > -1) {
            content = content.replace(regex, preview ? item.previewSms : `${nickName}`);
          } else if (item.tag === '%PROF_POINT%' && content.indexOf(item.tag) > -1) {
            content = content.replace(regex, preview ? item.previewSms : `${depositpoint}`);
          } else if (item.tag === '%SITE_NAME%' && content.indexOf(item.tag) > -1) {
            const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.SITE_NAME)?.content;
            content = content.replace(regex, preview ? item.previewSms : `${description}`);
          } else if (item.tag === '%URL_LP%' && content.indexOf(item.tag) > -1) {
            const url = Util.buildLink('/lp');
            const txtLp = preview ? (item.previewSms || item.contentSms) : item.contentSms;
            if (txtLp) {
              content = content.replace(regex, txtLp.replace(/#URL#/g, url));
            }
          } else if (item.tag === '%MAIL_ADDRESS%' && content.indexOf(item.tag) > -1) {
            const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS)?.content;
            content = content.replace(regex, preview ? item.previewSms : `${description}`);
          } else if (item.tag === '%PHONE_NUMBER%' && content.indexOf(item.tag) > -1) {
            const description = (envInfoTag ?? []).find(t => t.code === CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER)?.content;
            content = content.replace(regex, preview ? item.previewSms : `${description}`);
          } else if (item.tag === '%URL_CONTACT%' && content.indexOf(item.tag) > -1) {
            const url = Util.buildLink(CONVERSION_TAG_URL.URL_CONTACT);
            const txtContact = preview ? (item.previewSms || item.contentSms) : item.contentSms;
            if (txtContact) {
              content = content.replace(regex, txtContact.replace(/#URL#/g, url));
            }
          } else {
            content = content.replace(regex, preview ? (item.previewSms || item.contentSms) : item.contentSms);
          }
        }

        const arr = content.split('\n');
        if (arr.length > 0 && preview) {
          let contentTag = '';
          for (const line of arr) {
            contentTag += `<p style="margin:0">${line}</p>`;
          }
          return contentTag;
        }
      }
    }

    return content;
  };

  const replaceUrlPreview = (content) => {
    getUser(users[0].id).then(async (res) => {
      const { data: userDetail } = res;
      const user = userDetail.data;
      const replaceContent = await replaceTag(content, true, {
        phoneNumber: user.phoneNumber,
        password: user.password,
        nickName: user.nickName,
        depositpoint: user.depositpoint,
        needOtp: user.needOtp,
      });
      setContentHtml(replaceContent);
      setConFirmVisible(true);
    });
  };

  const handlerTimerSms = (value) => {
    setTimerSms(`${value}`);
  };

  return (
    <>
      <div>
        <LoadingBar show={loading} />
        <div style={{ position: 'relative' }}>
          <>
            {confirmVisible && (
              <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={confirmVisible}
                onClose={onCloseConfirm}
                aria-labelledby="max-width-dialog-title"
                className={'d-modal-wrapper'}
              >
                <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'} />
                <Button
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 0,
                  }}
                  onClick={onCloseConfirm}
                >
                  <CloseIcon color="primary" />
                </Button>
                <DialogContent>
                  <div style={{ margin: '0.6rem 0 1rem 0' }}>
                    {/*実行してよろしいですか？*/}
                    <Card body className="mb-4">
                      <Row>
                        <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <p style={{ paddingLeft: 3 }}>日時</p>
                          </div>
                          <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                            <p>
                              {String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON && timeSend ? moment(timeSend).format('YYYY/MM/DD HH:mm') : dateSend}
                            </p>
                          </div>
                        </Col>
                        <Col style={style} xs={6} className={'d-line-content'}>
                          <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
                        <span
                          className={`${String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON ? 'd-red-color' : 'd-blue-color'}`}>
                          {convertDisplayOptionsText(timerSms)}</span>
                          </div>
                        </Col>
                      </Row>

                      <Row className={'d-line-col'}>
                        <Col style={style} xs={6} className={'d-line-content'}>
                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                        </Col>
                        <Col style={style} xs={6} className={'d-line-content'}>
                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
                        </Col>
                      </Row>
                    </Card>

                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={3}>
                          <p style={{ paddingLeft: 3 }}>本文</p>
                        </Label>
                        <Label style={style} xs={9}>
                          <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
                        </Label>
                      </Row>
                    </Card>

                  </div>
                  <div>
                    <center style={{ margin: 15 }}>
                      <Button
                        className="btn-custom btn-red"
                        onClick={onCloseConfirm}
                        color="primary"
                      >
                        いいえ
                      </Button>
                      <Button
                        className="btn-custom btn-blue"
                        onClick={handleSubmit}
                        color="primary"
                      >
                        はい
                      </Button>
                    </center>
                  </div>
                </DialogContent>
              </Dialog>
            )}
            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              open={visible}
              onClose={onClose}
              aria-labelledby="max-width-dialog-title"
              className={'d-modal-wrapper'}
            >
              <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
                <div
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    marginRight: 50,
                  }}
                >
                  <p className={'d-title mb-0'}>SMS送信</p>
                  <div style={{ fontSize: '1rem' }} className={'wrapper-info-title'}>
                    受信者数 <span>{totalRow}人</span>
                  </div>
                </div>
              </DialogTitle>
              <Button
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 0,
                }}
                onClick={onClose}
              >
                <CloseIcon color="primary" />
              </Button>
              <div className={'d-title-info-box'}>
                <div className={'text mb-0'}
                     dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LINK_MODAL }}></div>
              </div>

              <DialogContent>
                <Card style={{ marginBottom: 10 }}>
                  <Row style={{ padding: 20 }}>
                    <Col xs={12}>
                      <div style={{ margin: '16px 0 8px 0' }}><span style={{ color: 'red' }}>*</span>送信文</div>
                      <textarea
                        name="data"
                        rows="6"
                        onChange={onChange}
                        style={{ width: '100%', padding: 10 }}
                      />

                      <div className="mt-3 mb-1 timer-container">
                        <span>タイマー日時</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="datePicker">
                          <DatePicker
                            selected={selectedDate}
                            className="date"
                            onChange={(e) => handleDate(e)}
                            locale="ja"
                            dateFormat="yyyy/MM/dd"
                            popperPlacement="top-start"
                            disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
                          />
                          <select
                            defaultChecked={hourSend}
                            onChange={(e) => handleHour(e)}
                            className="hour"
                            disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
                          >
                            {hour.map((value) => (
                              <option selected={value.toString() === hourSend} key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                          <select
                            onChange={(e) => handleMinus(e)}
                            className="minus"
                            disabled={!(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)}
                          >
                            {minute.map((value) => (
                              <option selected={value.toString() === minusSend} key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="display-setting-timer d-un-width">
                          <RadioGroup
                            name="displaySetting"
                            defaultValue={timerSms}
                            options={displayOptionsOptionsRequired()}
                            onChange={(e) => handlerTimerSms(e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <div style={{ textAlign: 'right', marginBottom: 2 }}>
                  <Button
                    onClick={handleConfirm}
                    color="primary"
                    className="btn-custom btn-blue"
                  >
                    送信
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </>
        </div>
      </div>
    </>
  );
}

export default SendSmsModal;
