export const settingOrder = [
  {
    key: 'displayPageAfterLogin',
    order: 1,
    explainText: 'WEB-ログイン後ページ（1HOME/2MyPage/3MyPage仕事/4仕事LP）',
  },
  {
    key: 'displayTopNaviMenu',
    order: 2,
    explainText: 'WEB-トップ右メニューボタン',
  },
  {
    key: 'footerNav',
    order: 3,
    explainText: 'WEB-フッター（1A無/2A有/3AT無/4ATL無/9CopRright）',
  },
  {
    key: 'displayBottomNaviMenu',
    order: 4,
    explainText: 'WEB-ボトムメニュー表示',
  },
  {
    key: 'displayAccountMenuBlockList',
    order: 5,
    explainText: '設定（WEBのみ）-ブロックリスト表示',
  },
  {
    key: 'displayAccountMenuContactAgeverification',
    order: 6,
    explainText: '設定（WEBのみ）-年齢認証表示',
  },
  { key: 'homeResultGender', order: 101, explainText: 'HOME-異性表示' },
  { key: 'homeResultUser', order: 102, explainText: 'HOME-ユーザー表示' },
  { key: 'homeResultChara', order: 103, explainText: 'HOME-キャラ表示' },
  { key: 'homeResultBlockuser', order: 104, explainText: 'HOME-ブロック表示' },
  {
    key: 'homeSortType',
    order: 105,
    explainText:
      'HOME-ソート（0/画像>ログイン/2画像>登録/3登録/4非設定デフォルト）',
  },
  { key: 'searchResultGender', order: 111, explainText: '検索-異性表示' },
  { key: 'searchResultUser', order: 112, explainText: '検索-ユーザー表示' },
  { key: 'searchResultChara', order: 113, explainText: '検索-キャラ表示' },
  {
    key: 'searchResultBlockuser',
    order: 114,
    explainText: '検索-ブロック表示',
  },
  {
    key: 'searchSortType',
    order: 115,
    explainText:
      '検索-ソート（0/画像>ログイン/2画像>登録/3登録/4非設定デフォルト）',
  },
  {
    key: 'searchCallStandby',
    order: 121,
    explainText: '検索会話待機条件（WEBのみ）',
  },
  { key: 'searchTag', order: 122, explainText: '検索条件にタブを表示' },
  { key: 'buttonProfDetailLike', order: 201, explainText: 'いいねボタン' },
  { key: 'buttonProfDetailChat', order: 202, explainText: 'トークボタン' },
  { key: 'buttonProfDetailReport', order: 203, explainText: '報告ボタン' },
  { key: 'buttonProfDetailBlock', order: 204, explainText: 'ブロックボタン' },
  {
    key: 'buttonProfdetailGift',
    order: 301,
    explainText:
      'WEB-プロフにプレゼントボタン（0全/1男/2女/3ユーザー/4キャラ/9非表示）',
  },
  {
    key: 'buttonProfdetailFavorite',
    order: 302,
    explainText: 'WEB-プロフにお気に入りボタン(01)',
  },
  {
    key: 'buttonProfdetailAgent',
    order: 303,
    explainText: 'WEB-プロフに会社ボタン(01)',
  },
  {
    key: 'buttonProfdetailBlog',
    order: 304,
    explainText: 'WEB-プロフにブログボタン(01)',
  },
  {
    key: 'buttonProfdetailCall',
    order: 305,
    explainText:
      'WEB-プロフにコールボタン（0全/1男/2女/3ユーザー/4キャラ/9非表示）',
  },
  {
    key: 'displayProfGender',
    order: 306,
    explainText: 'WEB-性別表示',
  },
  {
    key: 'displayProfdetailTag',
    order: 307,
    explainText: 'WEB-プロフにタグ表示',
  },
  {
    key: 'buttonMyPageChatSupport',
    order: 401,
    explainText: 'WEB-マイページにサポートチャットボタン',
  },
  {
    key: 'buttonMypageFavoriteList',
    order: 402,
    explainText: 'WEB-マイページにお気に入りボタン',
  },
  {
    key: 'buttonMypageFootprintList',
    order: 403,
    explainText: 'WEB-マイページに足跡ボタン',
  },
  {
    key: 'buttonSettingBlogEdit',
    order: 404,
    explainText: 'WEB-マイページにブログ編集ボタン',
  },
  {
    key: 'displayMyPageGender',
    order: 405,
    explainText: 'WEB-マイページ性別表示',
  },
  {
    key: 'buttonSettingTagEdit',
    order: 411,
    explainText: 'WEB-プロフ編集タグ編集ボタン',
  },
  {
    key: 'displayProfileEditArea',
    order: 412,
    explainText: 'WEB-プロフ編集にエリア表示',
  },
  {
    key: 'displayProfileDetailArea',
    order: 413,
    explainText: 'WEB-プロフにエリア表示',
  },
  {
    key: 'displayAccountMemberType',
    order: 501,
    explainText: '設定-会員種類（会員ステイタス）',
  },
  {
    key: 'displayAccountPlanExpireDate',
    order: 502,
    explainText: '設定-月額会員期限',
  },
  {
    key: 'displayAccountSalaryPoint',
    order: 503,
    explainText: '設定（WEBのみ）-給与ポイント（2:キャラのみ表示）',
  },
  {
    key: 'displayAccountNotificationTalk',
    order: 504,
    explainText: '設定-通知（トーク）Push',
  },
  {
    key: 'displayAccountNotificationLike',
    order: 505,
    explainText: '設定-通知（いいね）Push',
  },
  {
    key: 'displayAccountNotificationInfo',
    order: 506,
    explainText: '設定-通知（WEBのみ）-全ての通知表示',
  },
  {
    key: 'displayAccountStandby',
    order: 507,
    explainText:
      '設定-（WEBのみ）電話転送設定（0全/1男/2女/3ユーザー/4キャラ/9非表示）',
  },
  { key: 'displayRegisterButton', order: 811, explainText: 'WEB-LP登録ボタン' },
  {
    key: 'displayLoginButton',
    order: 812,
    explainText: 'WEB-LPログインボタン',
  },
  { key: 'chatRoomAge', order: 901, explainText: 'トーク年齢認証が必要' },
  { key: 'chatRoomLike', order: 902, explainText: 'トークルームいいねが必要' },
  { key: 'buttonBirthdayEdit', order: 903, explainText: '誕生日編集許可' },
  {
    key: 'imageProfInspection',
    order: 904,
    explainText: 'WEB-画像アップ認証(0:未認証/1:認証済)',
  },
  { key: 'womenBuy', order: 905, explainText: '女性Point購入許可' },
  {
    key: 'chatRoomMemberType',
    order: 991,
    explainText: '×W月額会員条件（停止中）',
  },
  {
    key: 'displayProfilePageMember',
    order: 992,
    explainText: 'WEB-プロフにプロフフリーページリンクボタン(01)',
  },
];

// NOTE: LEGACY FUNCTION, SAVE THIS IF THE explainText OF THE ARRAY IS WRONG
function explainItem(item) {
  switch (item) {
    case 'womenBuy':
      return '女性Point購入許可';
    case 'homeResultGender':
      return 'HOME-性別表示';
    case 'homeResultUser':
      return 'HOME-ユーザー表示';
    case 'homeResultChara':
      return 'HOME-キャラ表示';
    case 'homeSortType':
      return 'HOME-ソート（0/画像>ログイン/2画像>登録/3登録/4非設定デフォルト）';
    case 'searchCallStandby':
      return '検索会話待機条件（WEBのみ）';
    case 'searchResultGender':
      return '検索-性別表示';
    case 'searchResultUser':
      return '検索-ユーザー表示';
    case 'searchResultChara':
      return '検索-キャラ表示';
    case 'searchSortType':
      return '検索-ソート（0/画像>ログイン/2画像>登録/3登録/4非設定デフォルト）';
    case 'chatRoomAge':
      return 'トーク年齢認証が必要';
    case 'chatRoomLike':
      return 'トークルームいいねが必要';
    case 'chatRoomMemberType':
      return '×W月額会員条件（停止中）';
    case 'buttonProfdetailAgent':
      return 'WEB-プロフに会社ボタン(01)';
    case 'buttonProfdetailBlog':
      return 'WEB-プロフにブログボタン(01)';
    case 'buttonProfdetailCall':
      return 'WEB-プロフにコールボタン（0全/1男/2女/3ユーザー/4キャラ/9非表示）';
    case 'buttonSettingBlogEdit':
      return 'WEB-マイページにブログ編集ボタン';
    case 'footerNav':
      return 'WEB-フッター（1A無/2A有/3AT無/4ATL無/9CopRright）';
    case 'buttonProfdetailGift':
      return 'WEB-プロフにプレゼントボタン（0全/1男/2女/3ユーザー/4キャラ/9非表示）';
    case 'buttonProfdetailFavorite':
      return 'WEB-プロフにお気に入りボタン(01)';
    case 'buttonMypageFavoriteList':
      return 'WEB-マイページにお気に入りボタン';
    case 'buttonMypageFootprintList':
      return 'WEB-マイページに足跡ボタン';
    case 'buttonBirthdayEdit':
      return '誕生日編集許可';
    case 'homeResultBlockuser':
      return 'HOME-ブロック表示';
    case 'searchResultBlockuser':
      return '検索-ブロック表示';
    case 'buttonProfDetailReport':
      return '報告ボタン';
    case 'buttonProfDetailBlock':
      return 'ブロックボタン';
    case 'buttonProfDetailChat':
      return 'トークボタン';
    case 'buttonProfDetailLike':
      return 'いいねボタン';
    case 'displayAccountMemberType':
      return '設定-会員種類（会員ステイタス）';
    case 'displayAccountPlanExpireDate':
      return '設定-月額会員期限';
    case 'displayAccountSalaryPoint':
      return '設定（WEBのみ）-給与ポイント（2:キャラのみ表示）';
    case 'displayAccountNotificationTalk':
      return '設定-通知（トーク）Push';
    case 'displayAccountNotificationLike':
      return '設定-通知（いいね）Push';
    case 'displayAccountStandby':
      return '設定-（WEBのみ）電話転送設定（0全/1男/2女/3ユーザー/4キャラ/9非表示）';
    case 'displayAccountNotificationInfo':
      return '設定（WEBのみ）-通知（Info）Push';
    case 'displayLoginButton':
      return 'WEB-LPログインボタン';
    case 'displayRegisterButton':
      return 'WEB-LP登録ボタン';
    case 'imageProfInspection':
      return 'WEB-画像アップ認証(0:未認証/1:認証済)';
    case 'displayProfdetailTag':
      return 'WEB-プロフにタグ表示';
    case 'buttonSettingTagEdit':
      return 'WEB-プロフ編集タグ編集ボタン';
    case 'buttonMyPageChatSupport':
      return 'WEB-マイページにサポートチャットボタン';
    case 'displayProfileEditArea':
      return 'WEB-プロフ編集にエリア表示';
    case 'displayProfileDetailArea':
      return 'WEB-プロフにエリア表示';
    case 'displayTopNaviMenu':
      return 'WEB-トップ右メニューボタン';
    case 'displayBottomNaviMenu':
      return 'WEB-ボトムメニュー表示';
    case 'searchTag':
      return '検索条件にタブを表示';
    case 'displayPageAfterLogin':
      return 'WEB-ログイン後ページ（1HOME/2MyPage/3MyPage仕事/4仕事LP）';
    case 'displayAccountMenuBlockList':
      return '設定（WEBのみ）-ブロックリスト表示';
    case 'displayAccountMenuContactAgeverification':
      return '設定（WEBのみ）-年齢認証表示';
    case 'displayProfilePageMember':
      return 'WEB-プロフにプロフフリーページリンクボタン(01)';
    default:
      break;
  }
}
