import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { updateEnvInfo } from '../../Redux/Actions/envInfo';
import { CODE_MAP_DEFINED_API } from '../../Config/listConstants';

function EnvInfoSetting({ data, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);

  const colorCode = '0,1,2,3,99,--main-color,--sub-color,--text1,--text2,--text3,--text4,--btoff,--btoff-text,--btmain,--btsub,--btmain-text,--btsub-text,--btmain-text-mobile,--btsub-text-mobile,--stick,.btn-list,.box-header,.box-selected-item,.box-text,.box-select-item:hover,.btn-search,.btn-clear, admin_lable_form_1, admin_lable_form_2, --text-my-page';
  const colorBg = '.btn-list,.box-header,.box-selected-item,.box-text,.box-select-item:hover,.btn-search,.btn-clear';

  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(data);
  }, [data]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = [...inputUpdate];
    const elementsIndex = envSetting.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };
  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    dispatch(updateEnvInfo(inputUpdate));
    setEnvSetting(inputUpdate);
    setIsEditing(false);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell title="説明" style={{ width: '40%' }}>説明</TableCell>
              <TableCell title="カラム名(code)" style={{ width: '5%' }}>カラム名(code)</TableCell>
              <TableCell title="DATA名(coctent)">DATA名(coctent)</TableCell>
              <TableCell title="情報">情報</TableCell>
              <TableCell title="表示順(管理）">表示順(管理）</TableCell>
            </TableRow>
            {envSetting.map((item, key) => (
              <TableRow key={key}>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item?.description}
                      name={`description.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item?.description
                  )}
                </TableCell>
                <TableCell style={{ width: '34%' }}>{item.code}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.content}
                      name={`content.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.content
                  )}
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  {item.code === CODE_MAP_DEFINED_API.LOGIN_LOGO && (
                    <img alt="logo" src={item.content} style={{ height: 30 }} />
                  )}
                  {item.code === CODE_MAP_DEFINED_API.TOP_NAVI_BAR_LOGO && (
                    <img alt="logo" src={item.content} className="box-shadow" style={{ height: 30 }} />
                  )}
                  {colorCode.includes(item.code) && (colorBg.includes(item.code) && item.content.includes('color:')
                      ? <p className="d-circle box-shadow" style={{
                        backgroundColor: `${item.content.split('color:')[1].replace('!important', '')}`
                      }}></p>
                      : (item.content.includes('border-')
                        ? <p className="d-circle box-shadow" style={{
                          backgroundColor: `#${item.content.split('#')[1].replace('!important', '')}`,
                        }}></p>
                        : <p className="d-circle box-shadow" style={{
                          backgroundColor: `${item.content}`,
                        }}></p>)
                  )
                  }
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.display_order}
                      name={`display_order.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.display_order
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default EnvInfoSetting;
