import React, { useEffect, useRef, useState } from 'react';
import socket from '../../../../../socket';
import { getMessagesAdmin, readAllMessageUnRead } from '../../../../../Api/chatAdminApi';
import Message from './Message';

function AppChat(props) {
  const [messages, setMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);

  let idConversation = props.data.id;
  let sender = props.data.sender;
  let receiver = props.data.receiver;
  let targetUser = props.data.receiver;
  const inputEl = useRef(null);

  useEffect(() => {
    getMessagesOfConversation(idConversation)
  },[idConversation])

  const getMessagesOfConversation = async(idConversation) =>{
    try {
      const messagesOfConversation = await getMessagesAdmin(idConversation)
      setMessages(messagesOfConversation?.data?.data);
    } catch (error) {
      console.error(error);
    }

  }
  const joinRoomName = 'joinAdminConversation';
  const leaveRoomName = 'leaveConversationAdmin';
  const receiveMessageName = 'receiveMessageAdmin';

  useEffect(() => {
    const handleJoinConversation = async () => {
      try {
        socket.emit(joinRoomName, { conversationId: idConversation });
        readAllMessageUnRead(idConversation)
      } catch (error) {
        console.error(error);
      }
    };
    handleJoinConversation();
    return () => {
      socket.emit(leaveRoomName, { conversationId: idConversation });
    };
  }, [idConversation]);

  useEffect(() => {
    socket.on(receiveMessageName, (data) => {
      setNewMessages([...newMessages, data]);
    });
  }, [newMessages]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (inputEl) {
      scrollToRef(inputEl);
    }
  });

  return (
    <div style={{ margin: '0 auto', width: '100%' }}>
      <div>
        {messages && messages.map((m, i) => {
          return <Message key={i}
                          message={m}
                          sender={sender}
                          receiver={receiver}
                          targetUser={targetUser} />;
        })}
        {newMessages && newMessages.map((m, i) => {
          return <Message message={m}
                          sender={sender}
                          receiver={receiver}
                          targetUser={targetUser} />;
        })}
      </div>
      <div style={{ float: 'left', clear: 'both' }} ref={inputEl}></div>
    </div>
  );
}

export default AppChat;
