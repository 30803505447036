import { call, put, takeLatest } from 'redux-saga/effects';
import EnvInfo from '../Types/envInfo';
import { getEnvInfo, updateEnvInfo } from '../../Api/envInfoAPI';
import { loadEnvInfoFail, loadEnvInfoSuccess, updateEnvInfoFail, updateEnvInfoSuccess } from '../Actions/envInfo';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvInfo(action) {
  try {
    const response = yield call(getEnvInfo, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadEnvInfoSuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvInfoFail(error));
  }
}

function* handleUpdateEnvInfo(action) {
  try {
    const response = yield call(updateEnvInfo, action.payload);
    const { data } = response;
    if (data.success) {
      const environment = data.data;
      yield put(updateEnvInfoSuccess({ data: environment }));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvInfoFail(error));
  }
}

export default function* envInfoSaga() {
  yield takeLatest(EnvInfo.LOAD_ENVINFO, handleLoadEnvInfo);
  yield takeLatest(EnvInfo.UPDATE_ENVINFO, handleUpdateEnvInfo);
}
