const Job = {
  LOAD_JOB: 'LOAD_JOB',
  LOAD_JOB_SUCCESS: 'LOAD_JOB_SUCCESS',
  LOAD_JOB_FAIL: 'LOAD_JOB_FAIL',

  UPDATE_JOB: 'UPDATE_JOB',
  UPDATE_JOB_SUCCESS: 'UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAIL: 'UPDATE_JOB_FAIL',
};

export default Job;
