import { call, put, takeLatest } from 'redux-saga/effects';
import ChatLog from '../Types/chatLog';
import { getAllChat } from '../../Api/chatLogApi';
import { loadChatLogFail, loadChatLogSuccess } from '../Actions/chatLog';

function* handleLoadChatLog(action) {
  try {
    const response = yield call(getAllChat, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadChatLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadChatLogFail(error));
  }
}

export default function* chatLogSaga() {
  yield takeLatest(ChatLog.LOAD_CHATLOG, handleLoadChatLog);
}
