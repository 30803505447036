import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import NameStateJobList from './NameStateJobList';
import { loadStateJob } from '../../Redux/Actions/nameStateJob';

function NameStateJob() {
  const loading = useSelector((state) => state.nameStateJob.loading);
  const dataNameState = useSelector((state) => state.nameStateJob.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStateJob());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loading || false} />
      <NameStateJobList nameState={dataNameState?.items} loading={loading} />
    </div>
  );
}
export default NameStateJob;
