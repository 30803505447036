import { call, put, takeLatest } from 'redux-saga/effects';
import { getListContacts } from '../../Api/database';
import { loadListContactsFail, loadListContactsSuccess } from '../Actions/listContacts';
import ListContacts from '../Types/listContacts';

function* handleLoadListContacts(action) {
  try {
    const response = yield call(getListContacts, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListContactsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListContactsFail(error));
  }
}

export default function* listContactsSaga() {
  yield takeLatest(ListContacts.LOAD_LIST_CONTACTS, handleLoadListContacts);
}
