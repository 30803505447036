import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const createMemberAdmin = (memberadmins) => {
  return sendPost('/cms/memberadmin/create', memberadmins);
};
export const getMemberAdmin = (id) => sendGet(`/cms/memberadmin/${id}`);
export const getMemberAdmins = (filter = {}) => {
  return sendPost('/cms/memberadmins', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
  });
};

export const updateMemberAdmin = (memberadmins) => {
  return sendPut(`/cms/memberadmin/${memberadmins.id}`, memberadmins);
};

export const removeMemberAdmin = (id) => sendDelete(`/cms/memberadmin/${id}`);
