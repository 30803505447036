import React, { useState } from 'react';
import NoPhotoAgent from '../../../assets/image/no_agent_photo.png';
import NonAuth from '../../../assets/image/non_auth.png';
import _ from 'lodash';
import ImageModal from '../../../Components/ImageModal';
import { IMAGE_STATUS, IMAGE_TYPE } from '../../../Config/listConstants';

function ImageList({ agent }) {
  const avatar = agent.avatar || {};

  let subImagesRaw = agent.images;
  _.remove(subImagesRaw, (x) => x.type === IMAGE_TYPE.AVATAR);
  // orderBy Asc
  let subImagesObj = {};
  for (let i = 0; i < subImagesRaw.length; i++) {
    subImagesObj[subImagesRaw[i].subImgNumber] = subImagesRaw[i];
  }

  const subImages = [];
  Array(5).fill(1).forEach((ele, index) => {
    if (subImagesObj[(index + 1)]) {
      subImages.push({ ...subImagesObj[(index + 1)], none: false });
    } else {
      subImages.push({
        none: true,
      });
    }
  });

  const [previewingImage, setPreviewingImage] = useState(null);

  const handleShowImageModal = (image) => {
    setPreviewingImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  return (
    <div className="clearfix">
      <div style={{ position: 'relative', float: 'left' }} className={'d-main-image'}>
        <img
          src={avatar.url ? avatar.url : NoPhotoAgent}
          style={{ width: 160, height: 160, objectFit: 'cover' }}
          border={0}
          alt=""
          onClick={() => handleShowImageModal(avatar)}
        />
        {avatar.inspection === IMAGE_STATUS.PENDING && (
          <div className="image-watermark">
            <img src={NonAuth} alt="" />
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className={'sub-images'}>
        {subImages.map((image, index) => (
          <div
            key={index}
            style={{ position: 'relative', float: 'left', marginLeft: 15, marginBottom: 15 }}
          >
            {!image.none
              ? (<img
                src={image.url ? image.url : NoPhotoAgent}
                style={{ width: 70, height: 70, objectFit: 'cover' }}
                border={0}
                alt=""
                onClick={() => handleShowImageModal(image)}
              />)
              : (<img
                src={NoPhotoAgent}
                style={{ width: 70, height: 70, objectFit: 'cover' }}
                border={0}
                alt=""
              />)
            }
            {image.inspection === IMAGE_STATUS.PENDING && (
              <div className="image-watermark">
                <img src={NonAuth} alt="" width="32" />
              </div>
            )}
          </div>
        ))}
      </div>
      {previewingImage && (
        <ImageModal image={previewingImage} onClose={handleHideImageModal} />
      )}
    </div>
  );
}

export default ImageList;
