import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const getOperators = (filter = {}) => {
  return sendGet('/cms/operator/search', filter);
};
export const getOperator = (filter = {}) => {
  return sendGet(`/cms/characters/list-by-operator`, filter);
};
export const getDetailOperator = (id) => {
  return sendGet(`/cms/operator/${id}/detail`);
};
export const createOperator = (operator) => {
  return sendPost('/cms/operator/create', operator);
};
export const updateOperator = (operator) => {
  return sendPut(`/cms/operator/${operator.id}/update`, {
    phoneNumber: operator.phoneNumber,
    operatorNumber: operator.operatorNumber,
    password: operator.password,
    operatorName: operator.operatorName,
    adminMemo: operator.adminMemo,
    logincontrol: operator.logincontrol
  });
};

export const getListCharaByOpe = () => {
  return sendGet(`/cms/operator/list-all-chara`);
};
export const deleteOperator = (id) => {
  return sendDelete(`/cms/operator/${id}`);
};
