const memberAdmin = {
  LOAD_MEMBER_ADMIN: 'LOAD_MEMBER_ADMIN',
  LOAD_MEMBER_ADMIN_SUCCESS: 'LOAD_MEMBER_ADMIN_SUCCESS',
  LOAD_MEMBER_ADMIN_FAIL: 'LOAD_MEMBER_ADMIN_FAIL',

  GET_BY_ID_MEMBER_ADMIN: 'GET_BY_ID_MEMBER_ADMIN',
  GET_BY_ID_MEMBER_ADMIN_SUCCESS: 'GET_BY_ID_MEMBER_ADMIN_SUCCESS',
  GET_BY_ID_MEMBER_ADMIN_FAIL: 'GET_BY_ID_MEMBER_ADMIN_FAIL',

  CREATE_MEMBER_ADMIN: 'CREATE_MEMBER_ADMIN',
  CREATE_MEMBER_ADMIN_SUCCESS: 'CREATE_MEMBER_ADMIN_SUCCESS',
  CREATE_MEMBER_ADMIN_FAIL: 'CREATE_MEMBER_ADMIN_FAIL',

  UPDATE_MEMBER_ADMIN: 'UPDATE_MEMBER_ADMIN',
  UPDATE_MEMBER_ADMIN_SUCCESS: 'UPDATE_MEMBER_ADMIN_SUCCESS',
  UPDATE_MEMBER_ADMIN_FAIL: 'UPDATE_MEMBER_ADMIN_FAIL',
};
export default memberAdmin;
