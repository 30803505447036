import { createAction } from 'redux-actions';
import SearchPresets from '../Types/searchPresets';

export const loadSearchPresets = createAction(
  SearchPresets.LOAD_SEARCH_PRESETS,
);
export const loadSearchPresetsSuccess = createAction(
  SearchPresets.LOAD_SEARCH_PRESETS_SUCCESS,
);
export const loadSearchPresetsFail = createAction(
  SearchPresets.LOAD_SEARCH_PRESETS_FAIL,
);

