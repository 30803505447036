import { createAction } from 'redux-actions';
import User from '../Types/user';

export const loadUser = createAction(User.LOAD_USER);
export const loadUserSuccess = createAction(User.LOAD_USER_SUCCESS);
export const loadUserFail = createAction(User.LOAD_USER_FAIL);

export const updateUser = createAction(User.UPDATE_USER);
export const updateUserSuccess = createAction(User.UPDATE_USER_SUCCESS);
export const updateUserFail = createAction(User.UPDATE_USER_FAIL);

export const userLogout = createAction(User.LOG_OUT);
