import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  CircularProgress,
  createMuiTheme,
  FormLabel,
  InputLabel,
  MuiThemeProvider,
  TextField,
} from '@material-ui/core';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../Components/RadioGroup';
import SelectList from '../../Components/SelectList';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { createPopup } from '../../Api/popupApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import './style.css';
import configs from '../../Config/config';
import { uploadImage } from '../../Api/uploadApi';
import { deleteImageStorage } from '../../Api/imageApi';
import Util from '../../Util/Util';
import {
  TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_ADD_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL,
} from '../../Common/modal_text_info';
import {
  fileDataType,
  hiddenStatusRequired,
  inspectionStatusRequired,
  needOtpOptionsRequired,
  sexOptionsRequired,
  typeConnectionsRequired,
} from '../../Util/utils';
import {
  APP_ALLOW_UPLOAD_TYPE,
  DEVICE_TYPE_IN_LOG,
  DIR_FOLDER_IMAGE,
  GENDER,
  HIDDEN_TYPE,
  INSPECTION_TYPE,
  OTP_CUSTOM_TYPE,
} from '../../Common/constant';
import FileUploadModal from '../../Components/FileUploadModal';
import {
  AppDestinationOptions,
  TOAST_MESSAGE,
} from '../../Common/constant_text';
import { DeleteImageButton } from '../../Components/IconImageAction';
import ReactDateTimePicker from 'Components/ReactDateTimePicker';
import _ from 'lodash';

registerLocale('ja', ja);

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function AddPopupModal({ onClose, onUpdate }) {
  const [submitting, setSubmitting] = useState(false);
  const [popup, setPopup] = useState({
    gender: GENDER.MALE, //0,
    confirmAge: INSPECTION_TYPE.NO_INSPECTION, // 0
    place: 0,
    typeDevice: DEVICE_TYPE_IN_LOG.WEB, // 0,
    displaySetting: HIDDEN_TYPE.NO_HIDDEN, // 0
    start_time: new Date(moment().startOf('day')).getTime(),
    end_time: new Date(moment().add(1, 'days').startOf('day')).getTime(),
    app_destination: 0,
    order: null,
    needOtp: OTP_CUSTOM_TYPE.ALL,
  });

  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic(fileData.file); // Trigger call data
    }
  }, [fileData]);

  const onEmitClose = () => {
    onDeleteImage();
    onClose();
  };

  const onChange = (e) => {
    popup[e.target.name] = e.target.value;
    setPopup({ ...popup, [e.target.name]: e.target.value });
  };

  const onChangeCustomDateTime = (name, value) => {
    const edittedPopup = _.set(popup, name, value);
    setPopup({ ...edittedPopup });
  };

  const handleSubmit = async () => {
    let isError = false;

    if (!popup.name) {
      toast.error('設定名を入力してください');
      isError = true;
    }
    if (!popup.start_time) {
      toast.error('開始日を入力してください');
      isError = true;
    }
    if (!popup.end_time) {
      toast.error('終了日を入力してください');
      isError = true;
    }
    if (!popup.app_destination) {
      toast.error('リンク先ページを選択してください');
      isError = true;
    }
    if (!popup.url) {
      toast.error('ポップアップ画像を登録してください');
      isError = true;
    }
    if (popup.place !== 0) {
      toast.error('表示場所を選択してください');
      isError = true;
    }
    if (popup.start_time > popup.end_time) {
      toast.error('開始日は終了日より前で設定してください');
      isError = true;
    }

    if (!popup.order) {
      toast.error('優先表示順位を数字で入力してください');
      isError = true;
    } else if (!/^[0-9]+$/.test(popup.order)) {
      toast.error('数字のみ only number');
      isError = true;
    }
    if (popup.needOtp === '') {
      popup.needOtp = null;
    }

    if (isError) {
      return;
    }

    createPopup(popup)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          onUpdate();
          setSubmitting(true);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const onDeleteImage = () => {
    if (popup.url) {
      deleteImageStorage(Util.getFilePath(popup.url)).then();
    }
  };

  const uploadStartLogic = async (file, task) => {
    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      if (task) {
        task.cancel();
      }
      const filename = new Date().getTime() + '.jpg';
      const formData = new FormData();
      formData.append('imageFile', new File([file], filename));
      const dir = `${DIR_FOLDER_IMAGE.POPUP}/${moment().format('YYYYMMDD')}`;
      const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
      const { data: uploaded } = await uploadImage(formData, {
        dir: btoa(dir),
      });
      if (uploaded.data) {
        onDeleteImage();
        popup.url = imagePath;
        setPopup({ ...popup });
      }
    }
  };

  const handleDeleteImage = (image) => {
    onDeleteImage(image);
    popup.url = null;
    setPopup({ ...popup });
  };

  const handleUploading = (snapshot) => {
    // Todo
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.info('Upload is ' + progress + '% done');
  };

  // const handleUploadError = (payload) => {
  //   console.error('Firebase.storage::FAIL ', payload);
  // };

  const handleUploadSuccess = (url) => {
    if (String(url)) {
      popup.url = url;
      setPopup({ ...popup });
    }
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <MuiThemeProvider theme={theme}>
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            <p className={'d-title'}>ポップアップ設定－新規登録</p>
            <div>
              <Button
                onClick={onEmitClose}
                color="primary"
                disabled={submitting}
                className="btn-custom btn-red"
              >
                閉じる
              </Button>
              <Button
                onClick={() => handleSubmit()}
                color="primary"
                disabled={submitting}
                className="btn-custom btn-blue mr-3"
              >
                {submitting && <CircularProgress />}
                登録
              </Button>
            </div>
          </DialogTitle>
          <div className={'d-title-info-box'}>
            <div
              className={'text mb-0'}
              dangerouslySetInnerHTML={{
                __html:
                  TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_ADD_LINK_MODAL,
              }}
            ></div>
          </div>
        </MuiThemeProvider>
        <DialogContent>
          <Row className="pb-3">
            <Col xs={6}>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>設定名
                    </InputLabel>
                    <TextField
                      type="text"
                      defaultValue={popup.name}
                      name="name"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
                {/*<FormGroup row>*/}
                {/*  <Col xs={12}>*/}
                {/*    <InputLabel shrink>表示場所</InputLabel>*/}
                {/*    <SelectList*/}
                {/*      name="place"*/}
                {/*      defaultValue={popup.place}*/}
                {/*      options={placeOptions}*/}
                {/*      onChange={onChange}*/}
                {/*    />*/}
                {/*  </Col>*/}
                {/*</FormGroup>*/}
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>表示端末</InputLabel>
                    <RadioGroup
                      name="typeDevice"
                      defaultValue={popup.typeDevice}
                      options={typeConnectionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">性別</FormLabel>
                    <RadioGroup
                      name="gender"
                      defaultValue={popup.gender}
                      options={sexOptionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">年齢認証</FormLabel>
                    <RadioGroup
                      name="confirmAge"
                      defaultValue={popup.confirmAge}
                      options={inspectionStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">ログイン方式</FormLabel>
                    <RadioGroup
                      name="needOtp"
                      defaultValue={popup.needOtp}
                      options={needOtpOptionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Card>
            </Col>
            <Col xs={6}>
              <Card body className="mb-2">
                <ReactDateTimePicker
                  name="start_time"
                  title="開始日時"
                  timestamp={popup.start_time}
                  onChange={onChangeCustomDateTime}
                />
                <ReactDateTimePicker
                  name="end_time"
                  title="終了日時"
                  timestamp={popup.end_time}
                  onChange={onChangeCustomDateTime}
                />
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">表示設定</FormLabel>
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={popup.displaySetting}
                      options={hiddenStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">
                      <span className="text-red">*</span>
                      優先表示順位（数字が少ないほうを表示）
                    </FormLabel>
                    <TextField
                      type="number"
                      defaultValue={popup.order}
                      name="order"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
              </Card>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>リンク先
                    </InputLabel>
                    <SelectList
                      name="app_destination"
                      defaultValue={popup.app_destination}
                      options={AppDestinationOptions}
                      onChange={onChange}
                      label="リンク先"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="pt-2 mb-0">
                  <Col xs={12}>
                    <FormLabel component="legend">
                      <span className="text-red">*</span>リンク画像を選択
                    </FormLabel>
                  </Col>
                  <Col xs={12}>
                    {!popup.url && (
                      <label>
                        <AddBoxIcon
                          onClick={() => setShowDialogDragDrop(true)}
                          color="action"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            margin: 'auto',
                          }}
                        />
                      </label>
                    )}
                  </Col>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          {popup.url && (
            <Card body className="my-3 align-items-center">
              <label
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 220,
                }}
              >
                <img
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  src={popup.url}
                  alt="addpopup"
                />
                {popup.url && (
                  <DeleteImageButton
                    onClick={() => handleDeleteImage(popup.url)}
                  />
                )}
                {/*<div>*/}
                {/*  <div onClick={() => setShowDialogDragDrop(true)}*/}
                {/*       style={{*/}
                {/*         position: 'absolute',*/}
                {/*         bottom: 13,*/}
                {/*         left: 13,*/}
                {/*         width: 19,*/}
                {/*         height: 18,*/}
                {/*         zIndex: 100,*/}
                {/*         filter: 'brightness(0) invert(1)',*/}
                {/*         cursor: 'pointer',*/}
                {/*         background: `url(${EditButton}) no-repeat center bottom/contain`,*/}
                {/*       }}*/}
                {/*  />*/}
                {/*  <div onClick={() => setShowDialogDragDrop(true)}*/}
                {/*       style={{*/}
                {/*         position: 'absolute',*/}
                {/*         bottom: 0,*/}
                {/*         left: 0,*/}
                {/*         width: 50,*/}
                {/*         height: 50,*/}
                {/*         cursor: 'pointer',*/}
                {/*         background: `url(${BorderEdit}) no-repeat center bottom/contain`,*/}
                {/*       }}*/}
                {/*  />*/}
                {/*</div>*/}
              </label>
            </Card>
          )}
        </DialogContent>
      </Dialog>
      {showDialogDragDrop && (
        <FileUploadModal
          showDialogDragDrop={showDialogDragDrop}
          setShowDialogDragDrop={setShowDialogDragDrop}
          setFileData={setFileData}
          fileData={fileData}
          dataInfoHeadText={
            TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL
          }
        />
      )}
    </>
  );
}

export default AddPopupModal;
