import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import UserDelete from '../../assets/image/delete_user.png';

const ReportListItem = ({ report, handleClickDetail, no }) => {
  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const { reporter, target } = report;

  return (
    <TableRow style={{ height: 48 }}>
      <TableCell>{no}</TableCell>

      <TableCell>{reporter?.phoneNumber}</TableCell>
      <TableCell align="center">
        <Link
          to={`/user/${reporter?.id}/detail`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => saveLocalStore(reporter?.id)}
        >
          <img
            className="imgRound"
            src={
              reporter?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
              reporter?.avatar?.url || NoPhoto}
            alt=""
          />
        </Link>
      </TableCell>
      <TableCell>{reporter?.nickName}</TableCell>
      <TableCell>{Util.genderIdToText(reporter?.gender)}</TableCell>


      <TableCell>{target?.phoneNumber}</TableCell>
      <TableCell align="center">
        <Link
          to={`/user/${target?.id}/detail`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => saveLocalStore(target?.id)}
        >
          <img
            className="imgRound"
            src={
              target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
              target?.avatar?.url || NoPhoto}
            alt=""
          />
        </Link>
      </TableCell>
      <TableCell>{target?.nickName}</TableCell>
      <TableCell>{Util.genderIdToText(target?.gender)}</TableCell>

      <TableCell>{report.content}</TableCell>
      <TableCell>
        {moment(Number(report.createdDate)).format('YYYY-MM-DD HH:mm:ss')}
      </TableCell>
      <TableCell align="center">
        <div className="d-color-link"
             onClick={handleClickDetail}
             style={{ cursor: 'pointer' }}>
          表示
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ReportListItem;
