import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { checkYourIP, login } from '../../Api/authenticateApi';
import { LOGIN_TYPE } from '../../Common/constant';
import { loadEnvInfo } from '../../Redux/Actions/envInfo';
import { useDispatch, useSelector } from 'react-redux';
import LoginBanner from './CommonLayout';
import { getTextInfo } from '../../Api/infoAPI';
import socketMaintenancePage from '../../Util/socketMaintenancePage';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { CODE_MAP_DEFINED_API } from '../../Config/listConstants';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { userLogout } from '../../Redux/Actions/user';
import User from '../../Redux/Types/user';
import { removeSession } from '../../Util/utils';

function SystemLogin(props) {
  const [email, setEmail] = useState();
  const [password, setPassWord] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [type, setType] = useState(LOGIN_TYPE.SYSTEM_ADMIN);
  const [textInfo, setTextInfo] = useState('');
  const [color, setColor] = useState('green');
  const [showPassword, setShowPassword] = useState(false);
  const [mailto, setMailto] = useState('');
  const loading = useSelector((state) => state.envInfo.loading);
  const envInfo = useSelector((state) => state.envInfo.data);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadEnvInfo({ pageIndex: 1, pageSize: 10000 }));
    getTextInfo().then(res => setTextInfo(res.data.data));
  }, [dispatch]);

  useEffect(() => {
    const dataColor = envInfo.find(item => item.code === CODE_MAP_DEFINED_API.ADMIN);

    if (dataColor) {
      setColor(dataColor.content);
    }

    const result = envInfo.find(t => t.code === 'contact_mail_address')?.content
    if(result){
      setMailto(result)
    }

  }, [envInfo]);

  useEffect(() => {
    socketMaintenancePage();
  }, []);

  const textFooter = () => {
    return (envInfo ?? []).find(t => t.code === 'web_footer_text')?.content;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert('メールアドレスとパスワード（数字6桁）を入力してください');
      return false;
    }

    try {
      const response = await login({
        email,
        password,
        typeLogin: type,
        typeDevice: 1,
      });
      const { data } = response;
      Cookies.set('token', data.data.token, {
        expires: rememberMe ? 999999 : undefined,
      });

      // clean cache
      localStorage.removeItem('operatorId');
      localStorage.removeItem('agentId');
      localStorage.removeItem('userId');
      localStorage.removeItem('memberAdminId');
      localStorage.removeItem('keepSidenav');
      localStorage.removeItem('homePage');

      props.history.push('/home');
    } catch (error) {
      alert('ログイン情報に誤りがあります');
    }

    try {
      const checkIP = await checkYourIP();
      if (!checkIP.data.data) {
        removeSession();
        dispatch(userLogout(User.LOG_OUT));
        props.history.push('/login_admin')
        alert('ご利用の環境からアクセスできません。システム管理者にご確認下さい。（IP error）');
        return;
      }
    } catch (e) {
        removeSession();
        dispatch(userLogout(User.LOG_OUT));
        props.history.push('/login_admin');
        alert('ご利用の環境からアクセスできません。システム管理者にご確認下さい。（IP error）');
        return;
    }

  };

  const setTypeLogin = type => setType(type);

  return (
    <div className='wrapper-login-admin'>
      {!loading && (<Toolbar style={{ justifyContent: 'space-between', background: `${color}`, color: '#fff' }}>
          <Typography variant="h6" color="inherit" noWrap>
            管理画面 Admin
          </Typography>
        </Toolbar>
      )}
      <main className="loginForm" style={{ borderRadius: 10 }}>
        <LoginBanner setTypeLogin={setTypeLogin} />

        <form onSubmit={submitLogin} noValidate className="d-form-login-action">
          <p style={{ textAlign: 'center', marginTop: 20 }}>
            管理者情報でログインして下さい。
          </p>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">ログインID（メールアドレス）</InputLabel>
            <Input
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">パスワード</InputLabel>
            <Input
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              onChange={(e) => setPassWord(e.target.value)}
            />
            <IconButton style={{ position: 'absolute', right: 0, top: 7, outline: 'none' }} onClick={handleTogglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="d-checkbox">
          </div>
          <Button
            className={'btn-submit'}
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 24 }}
            type="submit"
          >
            Login ログイン
          </Button>
          {/* <p className='api-info'> {textInfo} </p> */}
        </form>
      </main>
      <div style={{ marginTop: 'auto' }} className='attention'>
        <p dangerouslySetInnerHTML={{ __html: textFooter() }}>
        </p>
      </div>
    </div>
  );
}

export default withRouter(SystemLogin);
