import React, { useState } from 'react';
import { Button, FormLabel, TextField } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../Components/RadioGroup';
import RadioCheckboxGroup from '../../Components/RadioCheckboxGroup';
import { confirmTypeOptions, contactTypeOptions } from '../../Util/utils';
import { COLUMN_DEFINED_SEARCH_BOX, CONTACT_TYPE } from '../../Common/constant';

function SearchBox({ onSubmit, labelDb }) {
  const initialType = ['', CONTACT_TYPE.AFTER_LOGIN, CONTACT_TYPE.BEFORE_LOGIN, CONTACT_TYPE.AGE_VERIFICATION];

  const [filter, setFilter] = useState({ type: initialType });

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    try {
      return onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    setFilter({ type: initialType });
  };

  const onChangeType = (event) => {
    const { value } = event.target;
    let newType = [];

    if (value === '') {
      newType = !filter.type.includes(value) ? initialType : [];
      setFilter({ ...filter, type: newType });
      return;
    }

    newType = filter.type.includes(+value)
      ? filter.type.filter(item => item !== +value && item !== '')
      : [...filter.type, +value];

    if (newType.length === initialType.length - 1 && !newType.includes('')) newType.push('');

    setFilter({ ...filter, type: newType });
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">プロフィール画像管理-検索条件入力</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1 }>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  label="電話番号"
                  type="text"
                  name="phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickName"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>


            <FormGroup row>
              <Col xs={12}>
                <TextField
                  id="standard-required"
                  label="メールアドレス"
                  type="text"
                  name="email"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2 }>
            <FormGroup row>
              <Col xs={5}>
                <TextField
                  id="date"
                  label="日時"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="fromDate"
                  onChange={onChange}
                />
              </Col>
              <Col xs={2} className="flex-end d-col-2-symbol">
                〜
              </Col>
              <Col xs={5}>
                <TextField
                  id="date"
                  label="日時"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="toDate"
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3 }>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper d-item-checkbox">
                <FormLabel component="legend">種類</FormLabel>
                <RadioCheckboxGroup
                  name="type"
                  options={contactTypeOptions()}
                  onChange={onChangeType}
                  checked={filter.type}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>

            <FormGroup row style={{ marginTop: 20 }}>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">確認状態</FormLabel>
                <RadioGroup
                  name="confirm"
                  defaultValue=""
                  options={confirmTypeOptions()}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
