import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { updateRootResourceItemJob } from '../../Redux/Actions/resourceItemJob';
import { useDispatch } from 'react-redux';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';

function RootNameItemJobList(props) {
  const { dataRootItemJob, loading, isEditing, handleEdit, onClose, changeDataRootProfile } = props;

  const dispatch = useDispatch();

  const [dataItemJob, setDataItemJob] = useState({});
  const [inputUpdate, setInputUpdate] = useState({});
  const [filter, setFilter] = useState({
    sortBy: 'item',
    orderBy: 'ASC',
    sort: { item: 'ASC' },
  });

  useEffect(() => {
    const data = Object.fromEntries(
      Object.entries(dataRootItemJob).sort(([, a], [, b]) => {
        if (b[0].item > a[0].item) {
          return -1;
        } else if (b[0].item < a[0].item) {
          return 1;
        }

        return 0;
      }),
    );

    setDataItemJob(data);
  }, [dataRootItemJob]);

  const handleChangeItem = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    if (splitName && splitName.length) {
      for (const property in newArray) {
        newArray[property] = newArray[property].map((item) => {
          if (item.item === splitName[1]) {
            item[splitName[0]] = e.target.value;
          }

          return item;
        });
      }
      setInputUpdate(newArray);
      changeDataRootProfile(newArray);
    }
  };

  const handleUpdate = () => {
    dispatch(updateRootResourceItemJob(inputUpdate));
    setDataItemJob(inputUpdate); // Set update when change data
    changeDataRootProfile(inputUpdate); // Set update when change data
    onClose();
  };

  const handleClose = () => {
    changeDataRootProfile({ ...dataItemJob }); // Revert data old
    setInputUpdate({}); // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataItemJob))); // Clone deep data when use object
    handleEdit();
  };

  const onSort = (item) => {
    if (isEditing) {
      return; // Not sort while editing
    }

    let sortable;
    if (filter.orderBy === 'ASC') {
      sortable = Object.fromEntries(
        Object.entries(dataItemJob).sort(([, a], [, b]) => {
          if (b[0][item] > a[0][item]) {
            return 1;
          } else if (b[0][item] < a[0][item]) {
            return -1;
          }

          return 0;
        }),
      );
    } else {
      sortable = Object.fromEntries(
        Object.entries(dataItemJob).sort(([, a], [, b]) => {
          if (a[0][item] > b[0][item]) {
            return 1;
          } else if (a[0][item] < b[0][item]) {
            return -1;
          }

          return 0;
        }),
      );
    }

    setFilter({
      ...filter,
      sortBy: item,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [item]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });

    setDataItemJob(sortable);
  };

  return (
    <>
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ textAlign: 'right', margin: '10px 0' }}>
          {isEditing ? (
            <div className="my-3">
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={handleClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </div>
          ) : (
            <Button
              className="btn-blue"
              style={{
                padding: '6px 14px',
                margin: 'auto',
                color: 'white',
                marginRight: 10,
              }}
              variant="contained"
              onClick={handleBtnEdit}
            >
              編集
            </Button>
          )}
        </div>

        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="カラム名"
                    name="item"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="項目名"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="項目表示順"
                    name="itemOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索表示順"
                    name="searchOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索表示方法（1～4)"
                    name="searchType"
                    onClick={onSort}
                  />
                </TableCell>
                {/*<TableCell>*/}
                {/*  <SortArrows*/}
                {/*    sortObject={filter.sort}*/}
                {/*    text="Show Display"*/}
                {/*    name="showDisplay"*/}
                {/*    onClick={onSort}*/}
                {/*  />*/}
                {/*</TableCell>*/}
              </TableRow>
              {Object.keys(dataItemJob).map((itemGroup, index) => (
                <TableRow align="left" key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{itemGroup}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataItemJob[itemGroup][0]?.label}
                        name={`label.${itemGroup}`}
                        onChange={handleChangeItem}
                      />
                    ) : (
                      dataItemJob[itemGroup][0]?.label
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataItemJob[itemGroup][0]?.itemOrder}
                        name={`itemOrder.${itemGroup}`}
                        onChange={handleChangeItem}
                      />
                    ) : (
                      dataItemJob[itemGroup][0]?.itemOrder
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataItemJob[itemGroup][0]?.searchOrder}
                        name={`searchOrder.${itemGroup}`}
                        onChange={handleChangeItem}
                      />
                    ) : (
                      dataItemJob[itemGroup][0]?.searchOrder
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={dataItemJob[itemGroup][0]?.searchType}
                        name={`searchType.${itemGroup}`}
                        onChange={handleChangeItem}
                      />
                    ) : (
                      dataItemJob[itemGroup][0]?.searchType
                    )}
                  </TableCell>
                  {/*<TableCell>*/}
                  {/*  {isEditing ? (*/}
                  {/*    <input*/}
                  {/*      className="input-rank"*/}
                  {/*      defaultValue={dataItemJob[itemGroup][0]?.showDisplay}*/}
                  {/*      name={`showDisplay.${itemGroup}`}*/}
                  {/*      onChange={handleChangeItem}*/}
                  {/*    />*/}
                  {/*  ) : (*/}
                  {/*    dataItemJob[itemGroup][0]?.showDisplay*/}
                  {/*  )}*/}
                  {/*</TableCell>*/}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </>
  );
}

export default RootNameItemJobList;
