import { call, put, takeLatest } from 'redux-saga/effects';
import jobEntryMembers from '../Types/jobEntryMembers';
import { jobEntryMembersSearch } from '../../Api/jobEntryMemberApi';
import { loadJobEntryMembersFail, loadJobEntryMembersSuccess } from '../Actions/jobEntryMembers';

function* handleLoadJobEntryMembers(action) {
  try {
    const response = yield call(jobEntryMembersSearch, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadJobEntryMembersSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadJobEntryMembersFail(error));
  }
}

export default function* jobMembersSaga() {
  yield takeLatest(jobEntryMembers.LOAD_JOB_ENTRY_MEMBERS, handleLoadJobEntryMembers);
}
