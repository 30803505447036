import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TEXT_HTML_REMOVE_USER_MODAL } from '../../../Common/modal_text_info';
import {
  MESSAGE_CONFIRM_DELETE,
  TOAST_MESSAGE,
} from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';
import {
  moveToArchiveMultipleUsers,
  removeMultipleUsers,
} from '../../../Api/userAPI';

function RemoveMultipleUsersModal({ visible, onClose, moveToArchive }) {
  const history = useHistory();
  const userIds = useMemo(() => {
    if (visible || moveToArchive)
      return localStorage.getItem('userIds').split(',');
  }, [visible, moveToArchive]);

  const handleSubmit = async () => {
    if (moveToArchive) {
      await moveToArchiveMultipleUsers({ userIds }).then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          localStorage.removeItem('UserSearchFilter');
          onClose();
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    } else {
      await removeMultipleUsers({ userIds }).then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          localStorage.removeItem('UserSearchFilter');
          onClose();
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal
        visible={visible}
        onClose={onClose}
        handleSubmit={handleSubmit}
        title={MESSAGE_CONFIRM_DELETE.TITLE}
        content={
          moveToArchive
            ? 'このユーザーを削除し、一時保存に移動します。\nこのユーザーのログは全て削除されます。'
            : 'このユーザーを物理削除します、元には戻せません。\nこの処理はシステム管理者のみ実行して下さい'
        }
        contentSticky={TEXT_HTML_REMOVE_USER_MODAL}
      />
    </div>
  );
}

export default RemoveMultipleUsersModal;
