import { createAction } from 'redux-actions';
import Resource from '../Types/resources';

export const loadResource = createAction(Resource.LOAD_RESOURCE);
export const loadResourceSuccess = createAction(Resource.LOAD_RESOURCE_SUCCESS);
export const loadResourceFail = createAction(Resource.LOAD_RESOURCE_FAIL);

export const loadRootResource = createAction(Resource.LOAD_ROOT_RESOURCE);
export const loadRootResourceSuccess = createAction(Resource.LOAD_ROOT_RESOURCE_SUCCESS);
export const loadRootResourceFail = createAction(Resource.LOAD_ROOT_RESOURCE_FAIL);

export const getDetailRootResource = createAction(Resource.GET_DETAIL_ROOT_RESOURCE);
export const getDetailRootResourceSuccess = createAction(Resource.GET_DETAIL_ROOT_RESOURCE_SUCCESS);
export const getDetailRootResourceFail = createAction(Resource.GET_DETAIL_ROOT_RESOURCE_FAIL);

export const updateRootResource = createAction(Resource.UPDATE_ROOT_RESOURCE);
export const updateRootResourceSuccess = createAction(Resource.UPDATE_ROOT_RESOURCE_SUCCESS);
export const updateRootResourceFail = createAction(Resource.UPDATE_ROOT_RESOURCE_FAIL);

export const updateChildResource = createAction(Resource.UPDATE_CHILD_RESOURCE);
export const updateChildResourceSuccess = createAction(Resource.UPDATE_CHILD_RESOURCE_SUCCESS);
export const updateChildResourceFail = createAction(Resource.UPDATE_CHILD_RESOURCE_FAIL);
