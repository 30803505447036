import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { updateRootResource } from '../../Redux/Actions/resource';
import { useDispatch } from 'react-redux';
import './style.scss';
import Util from '../../Util/Util';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';

function RootProfileList({
                           dataRootProfile,
                           loading,
                           isEditing,
                           setIsEditing,
                           handleEdit,
                           onClose,
                           changeDataRootProfile,
                         }) {
  const dispatch = useDispatch();

  const [dataProfile, setDataProfile] = useState({});
  const [inputUpdate, setInputUpdate] = useState({});
  const [filter, setFilter] = useState({
    sortBy: 'profile',
    orderBy: 'ASC',
    sort: { profile: 'ASC' },
  });

  useEffect(() => {
    const data = Object.fromEntries(
      Object.entries(dataRootProfile).sort(([, a], [, b]) => {
        if (b[0].profile > a[0].profile) return -1;
        else if (b[0].profile < a[0].profile) return 1;
        return 0;
      }),
    );
    setDataProfile(data);
  }, [dataRootProfile]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.id === splitName[1]) {
          item.label = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // const newData = { ...dataProfile };
    // changeDataRootProfile(newArray);
  };

  const changeProfileOrder = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.profile === splitName[1]) {
          item.profileOrder = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // let newData = { ...dataProfile };
    // changeDataRootProfile(newArray);
  };

  const changeSearchOrder = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.profile === splitName[1]) {
          item.searchOrder = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    // let newData = { ...dataProfile };
    // changeDataRootProfile(newArray);
  };

  const changeSearchType = (e) => {
    const splitName = e.target.name.split('.');
    const newArray = { ...inputUpdate };

    for (const property in newArray) {
      newArray[property] = newArray[property].map((item) => {
        if (item.profile === splitName[1]) {
          item.searchType = e.target.value;
        }

        return item;
      });
    }
    setInputUpdate(newArray);
    changeDataRootProfile(newArray);
  };

  const handleUpdate = () => {
    dispatch(updateRootResource(inputUpdate));
    setDataProfile(inputUpdate); // Set update when change data
    changeDataRootProfile(inputUpdate); // Set update when change data
    onClose();
  };

  const handleClose = () => {
    changeDataRootProfile({ ...dataProfile }); // Revert data old
    setInputUpdate({}); // Reset data after change input
    onClose();
  };

  const handleBtnEdit = () => {
    setInputUpdate(JSON.parse(JSON.stringify(dataProfile))); // Clone deep data when use object
    handleEdit();
  };

  const onSort = (profile) => {
    if (isEditing) {
      return; // Not sort while editing
    }

    let sortable;
    if (filter.orderBy === 'ASC')
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (b[0][profile] > a[0][profile]) return 1;
          else if (b[0][profile] < a[0][profile]) return -1;
          return 0;
        }),
      );
    else
      sortable = Object.fromEntries(
        Object.entries(dataProfile).sort(([, a], [, b]) => {
          if (a[0][profile] > b[0][profile]) return 1;
          else if (a[0][profile] < b[0][profile]) return -1;
          return 0;
        }),
      );
    setFilter({
      ...filter,
      sortBy: profile,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [profile]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });

    setDataProfile(sortable);
  };

  return (
    <>
      <MuiThemeProvider theme={tableNewTheme}>
        <div style={{ textAlign: 'right', margin: '10px 0' }}>
          {isEditing ? (
            <div className="my-3">
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={handleClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </div>
          ) : (
            <Button
              className="btn-blue"
              style={{
                padding: '6px 14px',
                margin: 'auto',
                color: 'white',
                marginRight: 10,
              }}
              variant="contained"
              onClick={handleBtnEdit}
            >
              編集
            </Button>
          )}
        </div>

        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="カラム名"
                    name="profile"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="男性（全角15文字）"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="女性"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="キャラ男性"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="キャラ女性"
                    name="label"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="プロフィール表示順"
                    name="profileOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索表示順"
                    name="searchOrder"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="検索方法(123)"
                    name="searchType"
                    onClick={onSort}
                  />
                </TableCell>
              </TableRow>
              {Object.keys(dataProfile).map((itemGroup, index) => (
                <TableRow align="left" key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{itemGroup}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={Util.findLabel('male', dataProfile[itemGroup])}
                        name={`label.${Util.findProfileId('male', dataProfile[itemGroup])}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.findLabel('male', dataProfile[itemGroup])
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={Util.findLabel('female', dataProfile[itemGroup])}
                        name={`label.${Util.findProfileId('female', dataProfile[itemGroup])}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.findLabel('female', dataProfile[itemGroup])
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        className="input-rank"
                        defaultValue={Util.findLabel('chara_male', dataProfile[itemGroup])}
                        name={`label.${Util.findProfileId('chara_male', dataProfile[itemGroup])}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.findLabel('chara_male', dataProfile[itemGroup])
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing
                      ? (<input
                        className="input-rank"
                        defaultValue={Util.findLabel('chara_female', dataProfile[itemGroup])}
                        name={`label.${Util.findProfileId('chara_female', dataProfile[itemGroup])}`}
                        onChange={handleChange}
                      />)
                      :
                      Util.findLabel('chara_female', dataProfile[itemGroup])
                    }
                  </TableCell>
                  <TableCell>
                    {isEditing
                      ? (<input
                        className="input-rank"
                        defaultValue={Util.findProfile('male', dataProfile[itemGroup])?.profileOrder}
                        name={`profileOrder.${itemGroup}`}
                        onChange={changeProfileOrder}
                      />)
                      :
                      Util.findProfile('male', dataProfile[itemGroup])?.profileOrder
                    }
                  </TableCell>
                  <TableCell>
                    {isEditing
                      ? (<input
                        className="input-rank"
                        defaultValue={Util.findProfile('male', dataProfile[itemGroup])?.searchOrder}
                        name={`searchOrder.${itemGroup}`}
                        onChange={changeSearchOrder}
                      />)
                      : Util.findProfile('male', dataProfile[itemGroup])?.searchOrder
                    }
                  </TableCell>
                  <TableCell>
                    {isEditing
                      ? (<input
                        className="input-rank"
                        defaultValue={Util.findProfile('male', dataProfile[itemGroup])?.searchType}
                        name={`searchType.${itemGroup}`}
                        onChange={changeSearchType}
                      />) :
                      Util.findProfile('male', dataProfile[itemGroup])?.searchType
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </>
  );
}

export default RootProfileList;
