import { call, put, takeLatest } from 'redux-saga/effects';
import FavoriteLog from '../Types/favoriteLog';
import { getFavorites, getFavoritesByMe } from '../../Api/favoriteLogApi';
import { loadFavoriteLogFail, loadFavoriteLogSuccess } from '../Actions/favoriteLog';

function* handleLoadFavoriteLog(action) {
  try {
    const response = yield call(getFavorites, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFavoriteLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFavoriteLogFail(error));
  }
}

function* handleLoadFavoritesByMe(action) {
  try {
    const response = yield call(getFavoritesByMe, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadFavoriteLogSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadFavoriteLogFail(error));
  }
}

export default function* favoriteLogSaga() {
  yield takeLatest(FavoriteLog.LOAD_FAVORITELOG, handleLoadFavoriteLog);
  yield takeLatest(FavoriteLog.LOAD_FAVORITES, handleLoadFavoritesByMe);
}
