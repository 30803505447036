import React, { useEffect } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import NameStateAgentList from './NameStateAgentList';
import { loadStateAgent } from '../../Redux/Actions/nameStateAgent';

function NameStateAgent() {
  const loading = useSelector((state) => state.nameStateAgent.loading);
  const dataNameState = useSelector((state) => state.nameStateAgent.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStateAgent());
  }, [dispatch]);

  return (
    <div>
      <LoadingBar show={loading || false} />
      <NameStateAgentList nameState={dataNameState?.items} loading={loading} />
    </div>
  );
}
export default NameStateAgent;
