const character = {
  LOAD_CHARACTER: 'LOAD_CHARACTER',
  LOAD_CHARACTER_SUCCESS: 'LOAD_CHARACTER_SUCCESS',
  LOAD_CHARACTER_FAIL: 'LOAD_CHARACTER_FAIL',
  LOAD_DEATIL_CHARACTER: 'LOAD_DEATIL_CHARACTER',
  LOAD_DEATIL_CHARACTER_SUCCESS: 'LOAD_DEATIL_CHARACTER_SUCCESS',
  LOAD_DEATIL_CHARACTER_FAIL: 'LOAD_DEATIL_CHARACTER_FAIL',
  UPDATE_CHARACTER: 'UPDATE_CHARACTER',
  UPDATE_CHARACTER_SUCCESS: 'UPDATE_CHARACTER_SUCCESS',
  UPDATE_CHARACTER_FAIL: 'UPDATE_CHARACTER_FAIL',
};

export default character;
