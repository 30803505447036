import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadJobMembers } from '../../../Redux/Actions/jobMembers';
import JobEntryMemberList from '../../JobMemberManagement/JobEntryMemberList';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';


const pageSize = 50;

function JobEntryModal(props) {
  const {labelDb} = props
  const jobId = props.jobId;
  const loading = useSelector((state) => state.jobMembers.loading);
  const jobEntryMembers = useSelector((state) => state.jobMembers.items);
  const totalRow = useSelector((state) => state.jobMembers.totalRow);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'createdDate',
    orderBy: 'DESC',
    sort: { createdDate: 'DESC' },
    jobId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadJobMembers({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadJobMembers({ ...filter, pageIndex, pageSize }));
  };

  const refreshList = () => {
    dispatch(loadJobMembers({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }

    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const onSearch = (searchData) => {
    setFilter({ ...filter, ...searchData });
  };

  return (
    <div className="table-modal-wrapper mt-3 mb-2">
      <LoadingBar show={loading} />

      <JobEntryMemberList
        jobEntryMembers={jobEntryMembers}
        onUpdate={refreshList}
        filter={filter}
        onSort={onSort}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={loading}
        totalRow={totalRow}
        onSearch={onSearch}
        type={'type_member_job'}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          className={'d-page-wrapper'}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default JobEntryModal;
