import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import CircularLoading from '../../Components/CircularLoading';
import { updateEnvironment } from '../../Redux/Actions/environment';

function EnvAspSettingList({ nameEnv = {}, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(nameEnv);
  }, [nameEnv]);

  const handleChange = (e) => {
    setEnvSetting({ ...envSetting, [e.target.name]: e.target.value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onClose = () => {
    setEnvSetting(nameEnv);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = envSetting;
    dispatch(updateEnvironment(values));
    setIsEditing(false);
  };

  const envdata = Object.keys(nameEnv)
    .filter((item) => item !== 'id')
    .map((item) => item);

  const envdatahead = envdata.slice(0, 13);
  const envdataend = envdata.slice(13);

  const explainItem = (item) => {
    switch (item) {
      case 'fbApiKey':
        return 'FB:WEB API キー';
      case 'fbAuthDomain':
        return 'FB:ドメイン デフォルト';
      case 'fbDatabaseUrl':
        return 'FB:リアルタイムDB-URL';
      case 'fbProjectId':
        return 'FB:プロジェクト名';
      case 'fbStorageBucket':
        return 'FB:Strage';
      case 'fbMessagingSenderId':
        return 'FB:プロジェクト 公開名';
      case 'fbAppId':
        return 'FB:アプリID';
      case 'fbNotificationKey':
        return 'FB:サーバーキー';
      case 'smsService':
        return 'Nexmo:smsService';
      case 'smsSender':
        return '@Nexmo:smsSender';
      case 'smsAccessKey':
        return 'Nexmo:API key';
      case 'smsSecretKey':
        return 'Nexmo:API Secret';
      case 'smsRegion':
        return 'Nexmo:smsRegion';
      case 'sendgridApiKey':
        return 'SendG:app-sendgrid-api';
      case 'sendgridAddress1':
        return '@FromMail-1(システムMail)';
      case 'sendgridAddress2':
        return '@FromMail-2';
      case 'sendgridAddress3':
        return '@FromMail-3';
      case 'sendgridAddress4':
        return '@FromMail-4';
      case 'sendgridAddress5':
        return '@FromMail-5';
      case 'twilioPhone':
        return 'Twilio:050電話番号';
      case 'stripePublicKey':
        return 'Stripe: PublicKey';
      case 'stripeSecretKey':
        return 'Stripe Secret Key';
      case 'clientEmail':
        return 'Google Client email';
      case 'privateKey':
        return 'Google: Private key';
      case 'bitcashUrl':
        return 'BitcashAPIURL';
      case 'bitcashText':
        return 'Bitcash識別文字列';
      default:
        break;
    }
  };

  if (!Object.keys(nameEnv).length) {
    return <CircularLoading />;
  }

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="main-content-custom"
      >
        <Card style={{ width: '49.3%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明</TableCell>
                <TableCell>DATA名</TableCell>
                <TableCell className="data-column">DBdata</TableCell>
              </TableRow>
              {envdatahead.map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{explainItem(item)}</TableCell>
                  <TableCell>{item}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 400,
                    }}
                  >
                    {isEditing ? (
                      <input
                        value={envSetting[item]}
                        name={item}
                        onChange={handleChange}
                      />
                    ) : (
                      envSetting[item]
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {envdatahead?.length < 15 &&
                Array.from({ length: 15 - envdatahead?.length }, (v, i) => (
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>
        <Card style={{ width: '49.3%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>説明</TableCell>
                <TableCell>DATA名</TableCell>
                <TableCell className="data-column">DBdata</TableCell>
              </TableRow>
              {envdataend.map((item, key) => (
                <TableRow key={key}>
                  <TableCell>{explainItem(item)}</TableCell>
                  <TableCell>{item}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        value={envSetting[item]}
                        name={item}
                        onChange={handleChange}
                      />
                    ) : (
                      envSetting[item]
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {envdataend?.length < 15 &&
                Array.from({ length: 15 - envdataend?.length }, (v, i) => (
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </MuiThemeProvider>
  );
}

export default EnvAspSettingList;
