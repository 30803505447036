import { USER_DETAIL_MESSAGES } from '../../Common/constant_text';
import { toast } from 'react-toastify';

export const copyToClipboard = (text) => () => {
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
    } catch (err) {
      toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
    }

    document.body.removeChild(textArea);
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(
    function () {
      toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
    },
    function (err) {
      toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
    },
  );
};
