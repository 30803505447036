import { call, put, takeLatest } from 'redux-saga/effects';
import { getListAgent, getListInformation, getListJobDetail, getListLogMailVerification, getListMemberAdmin, getListMemberArchive, getListOperator, getListOtp, getListPaymentLogJob, getListPopupSetting, getListTextWebPage, getListTwilioHistory } from '../../Api/database';
import {
  loadListRawAgentFail,
  loadListRawAgentSuccess,
  loadListRawInformationFail,
  loadListRawInformationSuccess,
  loadListRawJobDetailFail,
  loadListRawJobDetailSuccess,
  loadListRawLogMailVerificationFail,
  loadListRawLogMailVerificationSuccess,
  loadListRawMemberAdminFail,
  loadListRawMemberAdminSuccess,
  loadListRawMemberArchiveFail,
  loadListRawMemberArchiveSuccess,
  loadListRawOperatorFail,
  loadListRawOperatorSuccess,
  loadListRawOtpFail,
  loadListRawOtpSuccess,
  loadListRawPaymentLogJobFail,
  loadListRawPaymentLogJobSuccess,
  loadListRawPopupSettingFail,
  loadListRawPopupSettingSuccess,
  loadListRawTextWebPageFail,
  loadListRawTextWebPageSuccess,
  loadListRawTwilioHistoryFail,
  loadListRawTwilioHistorySuccess,
} from '../Actions/listRawDatabase';
import listRawDatabase from '../Types/listRawDatabase';

function* handleLoadListRawInformation(action) {
  try {
    const response = yield call(getListInformation, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawInformationSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawInformationFail(error));
  }
}

function* handleLoadListRawTwilioHistory(action) {
  try {
    const response = yield call(getListTwilioHistory, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawTwilioHistorySuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawTwilioHistoryFail(error));
  }
}

function* handleLoadListRawTextWebPage(action) {
  try {
    const response = yield call(getListTextWebPage, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawTextWebPageSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawTextWebPageFail(error));
  }
}

function* handleLoadListRawOperator(action) {
  try {
    const response = yield call(getListOperator, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawOperatorSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawOperatorFail(error));
  }
}

function* handleLoadListRawAgent(action) {
  try {
    const response = yield call(getListAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawAgentFail(error));
  }
}

function* handleLoadListRawMemberArchive(action) {
  try {
    const response = yield call(getListMemberArchive, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawMemberArchiveSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawMemberArchiveFail(error));
  }
}

function* handleLoadListRawJobDetail(action) {
  try {
    const response = yield call(getListJobDetail, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawJobDetailSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawJobDetailFail(error));
  }
}

function* handleLoadListRawMemberAdmin(action) {
  try {
    const response = yield call(getListMemberAdmin, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawMemberAdminSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawMemberAdminFail(error));
  }
}

function* handleLoadListRawOtp(action) {
  try {
    const response = yield call(getListOtp, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawOtpSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawOtpFail(error));
  }
}

function* handleLoadListRawPaymentLogJob(action) {
  try {
    const response = yield call(getListPaymentLogJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawPaymentLogJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawPaymentLogJobFail(error));
  }
}

function* handleLoadListRawPopupSetting(action) {
  try {
    const response = yield call(getListPopupSetting, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawPopupSettingSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawPopupSettingFail(error));
  }
}

function* handleLoadListRawLogMailVerification(action) {
  try {
    const response = yield call(getListLogMailVerification, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadListRawLogMailVerificationSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadListRawLogMailVerificationFail(error));
  }
}



export default function* listRawUsersSaga() {
  // information
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_INFORMATION, handleLoadListRawInformation);
  // twilio-history
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY, handleLoadListRawTwilioHistory);
  // text-web-page
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE, handleLoadListRawTextWebPage);
  // operator
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_OPERATOR, handleLoadListRawOperator);
  // agent
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_AGENT, handleLoadListRawAgent);
  // member-archive
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE, handleLoadListRawMemberArchive);
  // member-admin
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN, handleLoadListRawMemberAdmin);
  // otp
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_OTP, handleLoadListRawOtp);
  // payment-log-job
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB, handleLoadListRawPaymentLogJob);
  // popup-setting
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING, handleLoadListRawPopupSetting);
  // log-mail-verification
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION, handleLoadListRawLogMailVerification);
  // job-detail
  yield takeLatest(listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL, handleLoadListRawJobDetail);
}
