import React from 'react';
import { toast } from 'react-toastify';
import { moveToArchiveUser, removeUser } from '../../../Api/userAPI';
import { useHistory } from 'react-router-dom';
import { TEXT_HTML_REMOVE_USER_MODAL } from '../../../Common/modal_text_info';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';

function RemoveUserModal({ visible, onClose, userId, moveToArchive, labelDb }) {
  const history = useHistory();

  const handleSubmit = async () => {
    if (moveToArchive) {
      await moveToArchiveUser(userId).then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          window.location.reload();
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    } else {
      await removeUser(userId).then((response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          history.push('/user/search');
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={moveToArchive ? 'このユーザーを削除し、一時保存に移動します。\nこのユーザーのログは全て削除されます。' : 'このユーザーを物理削除します、元には戻せません。\nこの処理はシステム管理者のみ実行して下さい'}
                       contentSticky={TEXT_HTML_REMOVE_USER_MODAL}
      />
    </div>
  );
}

export default RemoveUserModal;
