import { handleActions } from 'redux-actions';
import listRawDatabase from '../Types/listRawDatabase';

const initialState = {
  infos: [],
  twilioHistories: [],
  textWebPages: [],
  operators: [],
  agents: [],
  memberArchive: [],
  memberAdmin: [],
  otp: [],
  paymentLogJob: [],
  popupSetting: [],
  logMailVerification: [],
  jobDetail: [],
  loading: false,
  totalInfo: 0,
  totalTwilioHistory: 0,
  totalTextWebPage: 0,
  totalOperator:0,
  totalAgent:0,
  totalMemberArchive:0,
  totalMemberAdmin:0,
  totalOtp: 0,
  totalPaymentLogJob: 0,
  totalPopupSetting: 0,
  totalLogMailVerification: 0,
  totalJobDetail:0,
};

const actions = {
  // information
  [listRawDatabase.LOAD_LIST_RAW_INFORMATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_INFORMATION_SUCCESS]: (state, action) => ({
    ...state,
    infos: action.payload.items,
    totalInfo: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_INFORMATION_FAIL]: (state) => ({
    ...state,
    infos: [],
    loading: false,
  }),

  // twilio-history
  [listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY_SUCCESS]: (state, action) => ({
    ...state,
    twilioHistories: action.payload.items,
    totalTwilioHistory: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY_FAIL]: (state) => ({
    ...state,
    twilioHistories: [],
    loading: false,
  }),

  // text-web-page
  [listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    textWebPages: action.payload.items,
    totalTextWebPage: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE_FAIL]: (state) => ({
    ...state,
    textWebPages: [],
    loading: false,
  }),

  // operator
  [listRawDatabase.LOAD_LIST_RAW_OPERATOR]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_OPERATOR_SUCCESS]: (state, action) => ({
    ...state,
    operators: action.payload.items,
    totalOperator: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_OPERATOR_FAIL]: (state) => ({
    ...state,
    operators: [],
    loading: false,
  }),

  // agent
  [listRawDatabase.LOAD_LIST_RAW_AGENT]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_AGENT_SUCCESS]: (state, action) => ({
    ...state,
    agents: action.payload.items,
    totalAgent: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_AGENT_FAIL]: (state) => ({
    ...state,
    agents: [],
    loading: false,
  }),

  // member_archive
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE_SUCCESS]: (state, action) => ({
    ...state,
    memberArchive: action.payload.items,
    totalMemberArchive: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE_FAIL]: (state) => ({
    ...state,
    memberArchive: [],
    loading: false,
  }),

  // member_admin
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN_SUCCESS]: (state, action) => ({
    ...state,
    memberAdmin: action.payload.items,
    totalMemberAdmin: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN_FAIL]: (state) => ({
    ...state,
    memberAdmin: [],
    loading: false,
  }),

  // otp
  [listRawDatabase.LOAD_LIST_RAW_OTP]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_OTP_SUCCESS]: (state, action) => ({
    ...state,
    otp: action.payload.items,
    totalOtp: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_OTP_FAIL]: (state) => ({
    ...state,
    otp: [],
    loading: false,
  }),

  // payment_log_job
  [listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB_SUCCESS]: (state, action) => ({
    ...state,
    paymentLogJob: action.payload.items,
    totalPaymentLogJob: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB_FAIL]: (state) => ({
    ...state,
    paymentLogJob: [],
    loading: false,
  }),

  // popup_setting
  [listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING_SUCCESS]: (state, action) => ({
    ...state,
    popupSetting: action.payload.items,
    totalPopupSetting: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING_FAIL]: (state) => ({
    ...state,
    popupSetting: [],
    loading: false,
  }),

   // log_mail_verification
   [listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    logMailVerification: action.payload.items,
    totalLogMailVerification: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_FAIL]: (state) => ({
    ...state,
    logMailVerification: [],
    loading: false,
  }),

  // job_detail
  [listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL]: (state) => ({
    ...state,
    loading: true,
  }),
  [listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    jobDetail: action.payload.items,
    totalJobDetail: action.payload.totalRow,
    loading: false,
  }),
  [listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL_FAIL]: (state) => ({
    ...state,
    jobDetail: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
