import { handleActions } from 'redux-actions';
import FavoriteJobLog from '../Types/favoriteJobLog';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [FavoriteJobLog.LOAD_FAVORITE_JOB_LOG]: (state) => ({
    ...state,
    loading: true,
  }),
  [FavoriteJobLog.LOAD_FAVORITE_JOB_LOG_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [FavoriteJobLog.LOAD_FAVORITE_JOb_LOG_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};

export default handleActions(actions, initialState);
