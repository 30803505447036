import React from 'react';
import { DatePicker } from 'antd';
import jaJP from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import './styles.module.scss';

dayjs.locale('ja');

const AntDateTimePicker = React.forwardRef(function AntDateTimePicker(
  { onChange, showTime, defaultValue, value },
  ref,
) {
  return (
    <DatePicker
      ref={ref}
      showTime={showTime && { format: 'HH:mm' }}
      format={`YYYY/MM/DD ${showTime ? 'HH:mm' : ''}`}
      placeholder="YYYY/MM/DD"
      locale={jaJP}
      defaultValue={defaultValue ? dayjs(defaultValue) : null}
      value={value ? dayjs(value) : null}
      onChange={onChange}
      style={{ width: '100%' }}
      suffixIcon={<span className="material-icons">calendar_today</span>}
    />
  );
});

export default AntDateTimePicker;
