import React, { useEffect, useMemo } from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';

const TextWebPageItem = ({ item, handleEdit }) => {

  useEffect(() => {
    const mouseoverElement = (event) => {
      let myEvent = event.target || event.currentTarget;
      let tmpHoverTooltip = document.getElementById('d-hover-tooltip');
      let text = myEvent.getAttribute('data-text');
      if (tmpHoverTooltip && text) {
        tmpHoverTooltip.innerHTML = text;
        tmpHoverTooltip.style.cssText = `top: ${event.clientY}px; left: ${event.clientX}px;`;
      }
    };

    const mouseoutElement = () => {
      let tmpHoverTooltip = document.getElementById('d-hover-tooltip');
      if (tmpHoverTooltip) {
        tmpHoverTooltip.removeAttribute('style');
        tmpHoverTooltip.innerHTML = '';
      }
    };

    const sectionScrolls = document.querySelectorAll('.hover-able');
    if (!sectionScrolls || !sectionScrolls.length) {
      return;
    }

    (sectionScrolls ?? []).forEach(ele => {
      ele.addEventListener('mouseover', mouseoverElement, true);
      ele.addEventListener('mouseout', mouseoutElement, true);
    });

    return () => {
      (sectionScrolls ?? []).forEach(ele => {
        ele.removeEventListener('mouseover', mouseoverElement, true);
        ele.removeEventListener('mouseout', mouseoutElement, true);
      });
    };
  }, [item]);

  return useMemo(() => (
      <TableRow>
        <TableCell
          className={'hover-able'}
          data-text={item?.description}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 500,
            minWidth: 140,
          }}
        >
          {item?.description}
        </TableCell>

        <TableCell
          className={'hover-able'}
          data-text={item?.content}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 500,
            minWidth: 140,
          }}
        >
          {item?.content}
        </TableCell>

        <TableCell style={{ maxWidth: 100 }}>{item.link}</TableCell>

        <TableCell
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 100,
          }}
        >
          {item.displaySetting}
        </TableCell>

        <TableCell
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 100,
          }}
        >
          {item.displayOrder}
        </TableCell>
        <TableCell
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 500,
          }}
        >
          <Button
            className="btn-cus-font btn-custom btn-blue btn-cus-r5"
            variant="contained"
            onClick={() => handleEdit(item)}
          >
            編集
          </Button>
        </TableCell>
      </TableRow>
    ),
    [item, handleEdit],
  );
};

export default TextWebPageItem;
