import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import './style.scss';
import { getDetailRootResource } from '../../Redux/Actions/resource';
import ChildProfileList from './ChildProfileList';
import RootProfileList from './RootProfileList';
import './style.scss';
import { tableNewTheme } from '../../ConfigUI/tableTheme';

function NameProfileList({ rootResource = [], loading, setIsEditing }) {
  const [dataRootProfile, setDataRootProfile] = useState({});
  const [dataChildProfile, setDataChildProfile] = useState({});
  const [isEditingRoot, setIsEditingRoot] = useState(false);
  const [isEditingChild, setIsEditingChild] = useState(false);
  const childResource = useSelector((state) => state.resource.dataChild);
  const dispatch = useDispatch();

  useEffect(() => {
    let rootProfileGroup = _.groupBy(rootResource, (rootProfile) => {
      return rootProfile.profile;
    });
    setDataRootProfile(rootProfileGroup);
  }, [rootResource]);

  useEffect(() => {
    let childProfileGroup = _.groupBy(childResource, (childProfile) => {
      return childProfile.value;
    });
    setDataChildProfile(childProfileGroup);
  }, [childResource]);

  const handleEditRootProfile = () => {
    setIsEditingRoot(true);
  };

  const handleCloseEditRootProfile = () => {
    setIsEditingRoot(false);
  };

  const handleEditChildProfile = () => {
    setIsEditingChild(true);
  };

  const handleCloseEditChildProfile = () => {
    setIsEditingChild(false);
  };

  const [childProfile, setChildProfile] = useState(null);
  const [rootProfile, setRootProfile] = useState(false);

  const handleDetailRootProfile = () => {
    setRootProfile(true);
    setIsEditingChild(false);
    setChildProfile(null);
  };

  const handleClickDetail = (itemGroup) => {
    dispatch(getDetailRootResource(itemGroup));
    setChildProfile(itemGroup);
    setRootProfile(false);
    setIsEditingRoot(false);
  };

  const changeDataRootProfile = (data) => {
    setDataRootProfile(data); // Change data update after fetch api
    setRootProfile(data);
  };

  const changeDataChildProfile = (data) => {
    setChildProfile(data);
  };

  const showNameProfile = (nameProfile) => {
    return nameProfile.map(
      (item) =>
        item.gender === 'male' && (
          <TableRow>
            <TableCell>{item.profile}</TableCell>
            <TableCell>{item.label}</TableCell>
            <TableCell>{item.profileOrder}</TableCell>
            <TableCell>{item.searchOrder}</TableCell>
            <TableCell>{item.searchType}</TableCell>
            <TableCell>
              <Button
                className="btnEditProfile"
                variant="contained"
                onClick={() => handleClickDetail(item.profile)}
              >
                表示
              </Button>
            </TableCell>
          </TableRow>
        ),
    );
  };

  return (
    <>
      <div className="m-2 mt-3 mb-3">
        <span style={{ marginRight: 105 }}>大項目</span>
        <span style={{ marginRight: 150 }}>大項目名設定</span>
        <Button
          className="btnEditRootProfile"
          variant="contained"
          onClick={handleDetailRootProfile}
        >
          表示（大項目）
        </Button>
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card style={{ display: 'flex', overflowX: 'hidden' }}>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>カラム名</TableCell>
                <TableCell>項目名(男性）</TableCell>
                <TableCell>表示順</TableCell>
                <TableCell>検索表示順</TableCell>
                <TableCell>検索方法(1Check/2Radio/3Range)</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {Object.values(dataRootProfile)
                .slice(0, 13)
                .map((itemGroup) => showNameProfile(itemGroup))}
            </TableBody>
          </Table>
          <Table className="table" style={{ width: '50%' }}>
            <TableBody>
              <TableRow>
                <TableCell>カラム名</TableCell>
                <TableCell>項目名(男性）</TableCell>
                <TableCell>表示順</TableCell>
                <TableCell>検索表示順</TableCell>
                <TableCell>検索方法(1Check/2Radio/3Range)</TableCell>
                <TableCell>編集</TableCell>
              </TableRow>
              {Object.values(dataRootProfile)
                .slice(13)
                .map((itemGroup) => showNameProfile(itemGroup))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      {childProfile && (
        <ChildProfileList
          dataChildProfile={dataChildProfile}
          handleEdit={handleEditChildProfile}
          onClose={handleCloseEditChildProfile}
          isEditing={isEditingChild}
          changeDataChildProfile={changeDataChildProfile}
        />
      )}
      {rootProfile && (
        <RootProfileList
          dataRootProfile={dataRootProfile}
          handleEdit={handleEditRootProfile}
          onClose={handleCloseEditRootProfile}
          isEditing={isEditingRoot}
          changeDataRootProfile={changeDataRootProfile}
        />
      )}
    </>
  );
}

export default NameProfileList;
