import { createAction } from 'redux-actions';
import NameStateJob from '../Types/stateJob';

export const loadStateJob = createAction(NameStateJob.LOAD_STATE_JOB);
export const loadStateJobSuccess = createAction(NameStateJob.LOAD_STATE_JOB_SUCCESS);
export const loadStateJobFail = createAction(NameStateJob.LOAD_STATE_JOB_FAIL);

export const loadAllAreaJob = createAction(NameStateJob.LOAD_ALL_AREA_JOB);
export const loadAllAreaJobSuccess = createAction(NameStateJob.LOAD_ALL_AREA_JOB_SUCCESS);
export const loadAllAreaJobFail = createAction(NameStateJob.LOAD_ALL_AREA_JOB_FAIL);

export const updateNameStateJob = createAction(NameStateJob.UPDATE_ROOT_STATE_JOB);
export const updateNameStateJobSuccess = createAction(NameStateJob.UPDATE_STATE_JOB_SUCCESS);
export const updateNameStateJobFail = createAction(NameStateJob.UPDATE_STATE_JOB_FAIL);
