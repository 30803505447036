import { call, put, takeLatest } from 'redux-saga/effects';
import resourcesItemJob from '../Types/resourcesItemJob';
import {
  getDetailRootResourceItemJobFail,
  getDetailRootResourceItemJobSuccess,
  loadResourceItemJobFail,
  loadResourceItemJobSuccess,
  loadRootResourceItemJobFail,
  loadRootResourceItemJobSuccess,
  updateChildResourceItemJobFail,
  updateChildResourceItemJobSuccess,
  updateRootResourceItemJobFail,
  updateRootResourceItemJobSuccess,
} from '../Actions/resourceItemJob';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import {
  getDetailResourceItemJob,
  getResourceItemJob,
  getRootResourceItemJob,
  updateChildResourceItemJob,
  updateRootResourceItemJob,
} from '../../Api/resourseItemJobApi';

function* handleLoadResourceAgent(action) {
  try {
    const response = yield call(getResourceItemJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadResourceItemJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadResourceItemJobFail(error));
  }
}

function* handleLoadRootResourceItemJob(action) {
  try {
    const response = yield call(getRootResourceItemJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      yield put(loadRootResourceItemJobSuccess({ items }));
    }
  } catch (error) {
    yield put(loadRootResourceItemJobFail(error));
  }
}

function* handleGetDetailResourceItemJob(action) {
  try {
    const response = yield call(getDetailResourceItemJob, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(getDetailRootResourceItemJobSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(getDetailRootResourceItemJobFail(error));
  }
}

function* handleUpdateRootResourceItemJob(action) {
  try {
    const response = yield call(updateRootResourceItemJob, action.payload);
    const { data } = response;
    if (data.success) {
      const rootResource = data.data;
      yield put(updateRootResourceItemJobSuccess(rootResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateRootResourceItemJobFail(error));
  }
}

function* handleUpdateChildResourceItemJob(action) {
  try {
    const response = yield call(updateChildResourceItemJob, action.payload);
    const { data } = response;
    if (data.success) {
      const childResource = data.data;
      yield put(updateChildResourceItemJobSuccess(childResource));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateChildResourceItemJobFail(error));
  }
}

export default function* resourceSaga() {
  yield takeLatest(resourcesItemJob.LOAD_RESOURCE_ITEM_JOB, handleLoadResourceAgent);

  yield takeLatest(resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB, handleLoadRootResourceItemJob);
  yield takeLatest(resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB, handleUpdateRootResourceItemJob);

  yield takeLatest(resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB, handleGetDetailResourceItemJob);
  yield takeLatest(resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB, handleUpdateChildResourceItemJob);
}
