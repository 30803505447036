import { handleActions } from 'redux-actions';
import Admin from '../Types/admin';

const initialState = {
  data: null,
  loading: false,
  success: false,
  totalRow: 0,
};

const actions = {
  [Admin.LOAD_ADMIN]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [Admin.LOAD_ADMIN_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    success: false,
  }),
  [Admin.LOAD_ADMIN_FAIL]: (state) => ({
    ...state,
    data: null,
    loading: false,
  }),
};
export default handleActions(actions, initialState);
