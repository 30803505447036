import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import './index.scss';
import { getAllTagJobs, updateTagJobs } from '../../Api/tag';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import LoadingBar from '../../Components/LoadingBar';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const TagJobSettingList = forwardRef((props, ref) => {
  const { isEditing, activeTag, setIsEditing } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [dataUpdate, setDataUpdate] = React.useState({});
  const [dataRender, setDataRender] = React.useState({ leftSide: [], rightSide: [], data: [] });

  const getAllTagJob = async () => {
    setIsLoading(true);
    getAllTagJobs({all: true}).then(res => {
      if (res.data) {
        setDataRender({
          leftSide: [],
          rightSide: [],
          data: (res.data?.data ?? []),
        });
      }

      setIsLoading(false);
    }).catch(err => console.error('Error!', err));
  };

  useEffect(() => {
    getAllTagJob();
  }, []);

  useEffect(() => {
    const data = sortBy(dataRender.data, 'id');

    const halfLength = Math.ceil(data.length / 2);
    setDataRender({
      ...dataRender,
      leftSide: data.slice(0, halfLength),
      rightSide: data.slice(halfLength, data.length),
    });
  }, [dataRender.data]);

  const handleUpdate = () => {
    const tags = Object.values(dataUpdate);

    if (tags.length > 0) {
      updateTagJobs(tags).then(res => {
        if (res.data?.data) {
          update(tags);
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        }
      }).catch(err => {
        toast.error(err?.response?.data?.message);
      });
    }

    setIsEditing(false);
    setDataUpdate({});
  };

  // Event func child
  useImperativeHandle(ref, () => ({
    updateTagJob() {
      handleUpdate();
    },
  }));

  const update = tagsUpdate => {
    const tags = dataRender.data.map(tag => {
      tagsUpdate.map(tagUpdate => {
        if (tag.id === tagUpdate.id) {
          tag = { ...tag, ...tagUpdate };
        }
      });

      return tag;
    });

    setDataRender({ ...dataRender, data: tags });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const splitName = name.split('.');

    const data = {
      id: splitName[1],
      [splitName[0]]: value,
    };

    setDataUpdate({ ...dataUpdate, [data.id]: { ...dataUpdate[data.id], ...data } });
  };

  const renderTable = (dataRender, isRightSide = false) => {
    return (
      <Table
        padding="checkbox"
        className="table text-nowrap mb-0"
      >
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '20%' }}>Column Name</TableCell>
            <TableCell className="font-weight-normal" style={{ width: '40%' }}>表示名</TableCell>
            <TableCell className="font-weight-normal" style={{ width: '15%' }}>表示順</TableCell>
            <TableCell className="font-weight-normal" style={{ width: '10%' }}>表示設定</TableCell>
            <TableCell className="font-weight-normal" style={{ width: '15%' }}>×Type</TableCell>
          </TableRow>
          {dataRender.map((item, key) => (
            <TableRow key={key}>
              <TableCell width={'30%'}>{item.id}</TableCell>
              <TableCell width={'50%'}>
                {isEditing ? (
                  <input
                    defaultValue={item.label}
                    name={`label.${item.id}`}
                    onChange={handleChange}
                    className="input-rank"
                  />
                ) : (
                  item.label
                )}
              </TableCell>
              <TableCell width={'5%'}>
                {isEditing ? (
                  <input
                    defaultValue={item.displayOrder}
                    name={`displayOrder.${item.id}`}
                    onChange={handleChange}
                    className="input-rank"
                  />
                ) : item.displayOrder
                }
              </TableCell>

              <TableCell width={'5%'}>
                {isEditing ? (
                  <input
                    defaultValue={item.displaySetting}
                    name={`displaySetting.${item.id}`}
                    onChange={handleChange}
                    className="input-rank"
                  />
                ) : item.displaySetting}
              </TableCell>

              <TableCell width={'5%'}>
                {isEditing ? (
                  <input
                    defaultValue={item.type}
                    name={`type.${item.id}`}
                    onChange={handleChange}
                    className="input-rank"
                  />
                ) : item.type}
              </TableCell>

            </TableRow>
          ))}

          {isRightSide && (dataRender.length + dataRender.length) % 2 !== 0 &&
          (!isEditing ? (
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>
                <input
                  className="input-rank"
                  style={{ opacity: 0, visibility: 'hidden' }}
                ></input>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      {isLoading
        ? (<LoadingBar show={isLoading} />)
        : (<>
          <Card style={{ width: '49.3%' }}>
            {renderTable(dataRender.leftSide)}
          </Card>

          <Card
            style={{
              width: '49.3%',
              boxShadow:
                '0px 0px 0px -6px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(236 239 244 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0.12)',
            }}
          >
            {renderTable(dataRender.rightSide, true)}
          </Card>
        </>)
      }
    </>
  );
});

export default TagJobSettingList;
