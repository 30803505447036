import { call, put, takeLatest } from 'redux-saga/effects';
import Environment from '../Types/environment';
import { getEnvironment, updateEnvironment } from '../../Api/environmentApi';
import {
  loadEnvironmentFail,
  loadEnvironmentSuccess,
  updateEnvironmentFail,
  updateEnvironmentSuccess,
} from '../Actions/environment';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function* handleLoadEnvironment(action) {
  try {
    const response = yield call(getEnvironment, action.payload);
    const { data } = response;
    if (data.success) {
      // const cipherText = data.data;
      // const plainText = CryptoJS.AES.decrypt(
      //   cipherText,
      //   configs.resources.aesSecretKey,
      // );
      // const rawData = JSON.parse(plainText.toString(CryptoJS.enc.Utf8));
      const totalRow = data.totalRow;
      yield put(loadEnvironmentSuccess({ items: data.data, totalRow }));
    }
  } catch (error) {
    yield put(loadEnvironmentFail(error));
  }
}

function* handleUpdateEnvironment(action) {
  try {
    const response = yield call(updateEnvironment, action.payload);
    const { data } = response;
    if (data.success) {
      const environment = data.data;
      yield put(updateEnvironmentSuccess(environment));
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
    } else {
      toast.error(TOAST_MESSAGE.ERROR_EDIT);
    }
  } catch (error) {
    yield put(updateEnvironmentFail(error));
  }
}

export default function* environmentSaga() {
  yield takeLatest(Environment.LOAD_ENVIRONMENT, handleLoadEnvironment);
  yield takeLatest(Environment.UPDATE_ENVIRONMENT, handleUpdateEnvironment);
}
