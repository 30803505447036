import { handleActions } from 'redux-actions';
import Agents from '../Types/agents';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
  itemsAgentIds: [],
  loadingAgentIds: false,
  totalRowAgentids: 0,
};

const actions = {
  [Agents.LOAD_AGENTS]: (state) => ({
    ...state,
    loading: true,
  }),
  [Agents.LOAD_AGENTS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [Agents.LOAD_AGENTS_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
  [Agents.LOAD_AGENT_IDS]: (state) => ({
    ...state,
    loadingAgentIds: true,
  }),
  [Agents.LOAD_AGENT_IDS_SUCCESS]: (state, action) => ({
    ...state,
    itemsAgentIds: action.payload.agentIds,
    totalRowAgentids: action.payload.totalAgentIds,
    loadingAgentIds: false,
  }),
  [Agents.LOAD_AGENT_IDS_FAIL]: (state) => ({
    ...state,
    itemsAgentIds: [],
    loadingAgentIds: false,
  }),
};
export default handleActions(actions, initialState);
