import { call, put, takeLatest } from 'redux-saga/effects';
import NameStateAgent from '../Types/stateAgent';
import { getAllAreaAgent, getNameStateAgent, updateNameStateAgent } from '../../Api/resourseAgentApi';
import {
  loadAllAreaAgentFail,
  loadAllAreaAgentSuccess,
  loadStateAgentFail,
  loadStateAgentSuccess,
  updateNameStateAgentFail,
  updateNameStateAgentSuccess,
} from '../Actions/nameStateAgent';

function* handleLoadNameStateAgent(action) {
  try {
    const response = yield call(getNameStateAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadStateAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadStateAgentFail(error));
  }
}

function* handleLoadAllAreaAgent(action) {
  try {
    const response = yield call(getAllAreaAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadAllAreaAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadAllAreaAgentFail(error));
  }
}

function* handleUpdateNameStateAgent(action) {
  try {
    const response = yield call(updateNameStateAgent, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(updateNameStateAgentSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(updateNameStateAgentFail(error));
  }
}

export default function* nameStateAgentSaga() {
  yield takeLatest(NameStateAgent.LOAD_STATE_AGENT, handleLoadNameStateAgent);
  yield takeLatest(NameStateAgent.LOAD_ALL_AREA_AGENT, handleLoadAllAreaAgent);
  yield takeLatest(NameStateAgent.UPDATE_STATE_AGENT, handleUpdateNameStateAgent);
}
