import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadPointGetLog } from '../../Redux/Actions/pointGetLog';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import SearchBox from './SearchBox';
import PointGetLogDetail from './PointGetLogDetail';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const pageSize = 50;

function Index() {
  const [filter, setFilter] = useState({
    sort: { 'pointgetlog.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);

  const loading = useSelector((state) => state.pointGetLog.loading);
  const pointGetLog = useSelector((state) => state.pointGetLog.items);
  const totalRow = useSelector((state) => state.pointGetLog.totalRow);
  const [pointLogDetail, setPointLogDetail] = useState(null);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadPointGetLog({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadPointGetLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...filter, ...searchFilter });
    setPageIndex(1);
  };

  const handleClickDetail = (pointLog) => {
    setPointLogDetail(pointLog);
  };

  const handleClose = () => {
    setPointLogDetail(null);
  };

  const reload = () => {
    dispatch(loadPointGetLog({ pageIndex: 1, pageSize, ...filter }));
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />

      {pointLogDetail && (
        <PointGetLogDetail
          onClose={handleClose}
          item={pointLogDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}

      <LoadingBar show={loading} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="user.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="内容"
                    name="pointgetlog.type"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="取得ポイント"
                    name="pointgetlog.point"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="pointgetlog.createdDate"
                    onClick={onSort}
                    defaultName="pointgetlog.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {pointGetLog.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x.user !== null ? x.user.phoneNumber : null}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={`/user/${x.user?.id}/detail`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x.user.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x.user !== null && x.user.avatar !== null
                            ? x.user.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x.user !== null ? x.user.nickName : null}
                  </TableCell>
                  <TableCell>{Util.genderIdToText(x?.user?.gender)}</TableCell>
                  <TableCell>{Util.paymentTypeIdToText(x.type)}</TableCell>
                  <TableCell>{Util.separator(x.point)}</TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell className="text-center" align="center">
                    <Link onClick={() => handleClickDetail(x)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
