import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: location.pathname === '/undefined' ? '/login_sys' : '/404',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
};
