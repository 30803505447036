import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import ScheduleSmsComponent from './ScheduleSms';
import ScheduleMailComponent from './ScheduleMail';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../Common/constant';
import orderBy from 'lodash/orderBy';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';

tableNewTheme.overrides = Object.assign(
  {},
  {
    ...tableNewTheme?.overrides,
    MuiTabs: {
      root: {
        marginTop: '14px',
      },
      PrivateTabIndicator: {
        root: {
          backgroundColor: 'unset',
        },
      },
    },
  },
);

const theme = createMuiTheme({
  ...tableNewTheme,
});

function Index() {
  const [value, setValue] = useState('');
  const [typeShowLogs, setTypeShowLogs] = useState([
    {
      type: DISPLAY_CMS_UI_TYPE.HIDDEN,
      active: 'MAIL_TIMER',
      order: -1,
      content: '',
    },
    {
      type: DISPLAY_CMS_UI_TYPE.HIDDEN,
      active: 'SMS_TIMER',
      order: -1,
      content: '',
    },
  ]); // 0 show, 1 show + not link, 2 hidden

  const defineShows = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.NOT_LINK];
  const admin = useSelector((state) => state.admin.data);
  const envUICms = useSelector((state) => state.envUICms.data);

  const scheduleMail = envUICms.find(
    (item) =>
      item.link === LINK_MAP_DEFINED_API.MANAGEMENT_SCHEDULE_MAIL_STANDBY,
  );
  const scheduleSMS = envUICms.find(
    (item) =>
      item.link === LINK_MAP_DEFINED_API.MANAGEMENT_SCHEDULE_SMS_STANDBY,
  );

  const setDataTypeShowAllActive = () => {
    const tmp = typeShowLogs.map((ele) => ({
      ...ele,
      type: DISPLAY_CMS_UI_TYPE.ON,
    }));
    setTypeShowLogs(uniqBy(tmp, 'active'));
  };

  const setDataTypeShowActive = (key = '', payload) => {
    let findActive = typeShowLogs.find((ele) => ele.active === key);

    if (findActive) {
      Object.assign(findActive, payload);
      setTypeShowLogs(
        orderBy(
          uniqBy(Object.assign([], [...typeShowLogs, findActive]), 'active'),
          ['order'],
          ['asc'],
        ),
      );
    }
  };

  useEffect(() => {
    let typeSysAdmin = false;
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setDataTypeShowAllActive();
      typeSysAdmin = true;
    }

    // Check type show setting
    if (scheduleMail) {
      setDataTypeShowActive('MAIL_TIMER', {
        type: typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +scheduleMail.type,
        order: scheduleMail.order,
        content: scheduleMail.content,
      });
    }
    if (scheduleSMS) {
      setDataTypeShowActive('SMS_TIMER', {
        type: typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +scheduleSMS.type,
        order: scheduleSMS.order,
        content: scheduleSMS.content,
      });
    }
  }, [admin]);

  useEffect(() => {
    let findActive = typeShowLogs.filter(
      (ele) => ele.type === DISPLAY_CMS_UI_TYPE.ON,
    );
    if (findActive && findActive.length) {
      setValue(findActive[0].active);
    }
  }, [typeShowLogs]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const clickTabSelected = (selected) => {
    setValue(selected.active);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Tabs
          scrollButtons="auto"
          indicatorColor="unset"
          textColor="white"
          value={value}
          onChange={handleChangeTab}
          className="d-tab-wrapper tab-timer-send-wrapper"
        >
          {typeShowLogs &&
            typeShowLogs.map((ele, index) => (
              <>
                {defineShows?.includes(ele.type) ? (
                  <Tab
                    disableRipple
                    key={index}
                    value={ele.active}
                    data-id={ele.active}
                    onClick={() => clickTabSelected(ele)}
                    className={`${value === ele.active ? 'btn-blue' : ''} btn-custom`}
                    label={ele.content}
                    title={ele.content}
                  />
                ) : (
                  <div key={index}></div>
                )}
              </>
            ))}
        </Tabs>

        <div className="mt-2">
          {value === 'MAIL_TIMER' &&
            typeShowLogs.findIndex(
              (ele) =>
                ele.active === 'MAIL_TIMER' &&
                ele.type === DISPLAY_CMS_UI_TYPE.ON,
            ) !== -1 && <ScheduleMailComponent value={value} />}
          {value === 'SMS_TIMER' &&
            typeShowLogs.findIndex(
              (ele) =>
                ele.active === 'SMS_TIMER' &&
                ele.type === DISPLAY_CMS_UI_TYPE.ON,
            ) !== -1 && <ScheduleSmsComponent value={value} />}
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default Index;
