import { createAction } from 'redux-actions';
import resourcesItemJob from '../Types/resourcesItemJob';

export const loadResourceItemJob = createAction(resourcesItemJob.LOAD_RESOURCE_ITEM_JOB);
export const loadResourceItemJobSuccess = createAction(resourcesItemJob.LOAD_RESOURCE_ITEM_JOB_SUCCESS);
export const loadResourceItemJobFail = createAction(resourcesItemJob.LOAD_RESOURCE_ITEM_JOB_FAIL);

export const loadRootResourceItemJob = createAction(resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB);
export const loadRootResourceItemJobSuccess = createAction(resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB_SUCCESS);
export const loadRootResourceItemJobFail = createAction(resourcesItemJob.LOAD_ROOT_RESOURCE_ITEM_JOB_FAIL);

export const getDetailRootResourceItemJob = createAction(resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB);
export const getDetailRootResourceItemJobSuccess = createAction(resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_SUCCESS);
export const getDetailRootResourceItemJobFail = createAction(resourcesItemJob.GET_DETAIL_ROOT_RESOURCE_ITEM_JOB_FAIL);

export const updateRootResourceItemJob = createAction(resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB);
export const updateRootResourceItemJobSuccess = createAction(resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB_SUCCESS);
export const updateRootResourceItemJobFail = createAction(resourcesItemJob.UPDATE_ROOT_RESOURCE_ITEM_JOB_FAIL);

export const updateChildResourceItemJob = createAction(resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB);
export const updateChildResourceItemJobSuccess = createAction(resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB_SUCCESS);
export const updateChildResourceItemJobFail = createAction(resourcesItemJob.UPDATE_CHILD_RESOURCE_ITEM_JOB_FAIL);
