import { handleActions } from 'redux-actions';
import ListMessage from '../Types/listMessage';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [ListMessage.LOAD_LIST_MESSAGE]: (state) => ({
    ...state,
    loading: true,
  }),
  [ListMessage.LOAD_LIST_MESSAGE_BY_ID]: (state) => ({
    ...state,
    loading: true,
  }),
  [ListMessage.LOAD_LIST_MESSAGE_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ListMessage.LOAD_LIST_MESSAGE_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
