import { call, put, takeLatest } from 'redux-saga/effects';
import TimerSms from '../Types/timerSms';
import { getAllTimerSms } from '../../Api/userAPI';
import { loadTimerSmsFail, loadTimerSmsSuccess } from '../Actions/timerSms';

function* handleLoadTimerSms(action) {
  try {
    const response = yield call(getAllTimerSms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTimerSmsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTimerSmsFail(error));
  }
}

export default function* timerSmsSaga() {
  yield takeLatest(TimerSms.LOAD_TIMERSMS, handleLoadTimerSms);
}
