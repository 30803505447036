import _ from 'lodash';
import { sendDelete, sendGet, sendPost, sendPostFile, sendPut } from './axios';

export const getCampaigns = (params) => sendGet('/cms/campaigns', params);
export const getCampaign = (id) => sendGet(`/cms/campaign${id}`);
export const createCampaign = (campaign) => sendPost('/cms/campaign', campaign);
export const updateCampaign = (campaign) => {
  return sendPut(`cms/campaign/${campaign.id}`, _.omit(campaign, ['id']));
};
export const deleteCampaign = (id) => sendDelete(`cms/campaign/delete/${id}`);
export const exportCampaign = () => sendGet(`cms/campaign/export`);
export const importFileCampaign = (params) => {
  return sendPostFile(`cms/campaign/import`, params);
};
export const updateCampaignUrl = (id, payload) => {
  return sendPut(`cms/campaign-url/${id}`, payload);
};
