import React, { useEffect, useMemo, useState } from 'react';

// NOTE: I'm too scared to refactor the default component
export default function RadioCheckboxGroupV2(props) {
  const {
    name,
    options,
    onChange,
    disabled,
    classCustom = '',
    isDefaultCheckedAll,
    value,
  } = props;
  const [checkedItems, setCheckedItems] = useState(new Set());

  const isControlled = typeof value !== 'undefined';
  const actualState = isControlled ? value : checkedItems;

  useEffect(() => {
    console.log(actualState, 'HEHEHEHEHEHEHEHE');
  });

  useEffect(() => {
    if (isControlled || !isDefaultCheckedAll) return;
    const defaultCheckedSet = new Set();
    options.forEach((e) => {
      defaultCheckedSet.add(`${e.value}`);
    });
    setCheckedItems(defaultCheckedSet);
  }, []);

  const handleChangeState = (data) => {
    if (onChange) onChange(name, data);
    if (!isControlled) setCheckedItems(data);
  };

  const handleCheckItem = (e) => {
    e.persist();
    const itemVal = e.target.value;
    const newSet = new Set(actualState);
    if (newSet.has(itemVal)) newSet.delete(itemVal);
    else newSet.add(itemVal);

    handleChangeState(newSet);
  };

  return (
    <div className={`radio-group ${classCustom ?? ''}`}>
      {options.map((option) => (
        <label
          key={option.label}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: '1',
            marginBottom: '0',
          }}
          className={`${classCustom ? classCustom + '-child' : ''}`}
        >
          <input
            name={name}
            value={option.value}
            type="checkbox"
            onChange={handleCheckItem}
            disabled={disabled}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={actualState.has(`${option.value}`)}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  );
}
