import React from 'react';
import { toast } from 'react-toastify';
import { removeReport } from '../../Api/reportApi';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';

function RemoveReportModal({
                             visible,
                             onClose,
                             reportId,
                             onCloseModal,
                             reload,
                           }) {
  const handleSubmit = async () => {
    await removeReport(reportId).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        onCloseModal();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <RemoveItemModal visible={visible}
                       onClose={onClose}
                       handleSubmit={handleSubmit}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default RemoveReportModal;
