import { createAction } from 'redux-actions';
import ResourceAgent from '../Types/resourcesAgent';

export const loadResourceAgent = createAction(ResourceAgent.LOAD_RESOURCE_AGENT);
export const loadResourceAgentSuccess = createAction(ResourceAgent.LOAD_RESOURCE_AGENT_SUCCESS);
export const loadResourceAgentFail = createAction(ResourceAgent.LOAD_RESOURCE_AGENT_FAIL);

export const loadRootResourceAgent = createAction(ResourceAgent.LOAD_ROOT_RESOURCE_AGENT);
export const loadRootResourceAgentSuccess = createAction(ResourceAgent.LOAD_ROOT_RESOURCE_AGENT_SUCCESS);
export const loadRootResourceAgentFail = createAction(ResourceAgent.LOAD_ROOT_RESOURCE_AGENT_FAIL);

export const getDetailRootResourceAgent = createAction(ResourceAgent.GET_DETAIL_ROOT_RESOURCE_AGENT);
export const getDetailRootResourceAgentSuccess = createAction(ResourceAgent.GET_DETAIL_ROOT_RESOURCE_AGENT_SUCCESS);
export const getDetailRootResourceAgentFail = createAction(ResourceAgent.GET_DETAIL_ROOT_RESOURCE_AGENT_FAIL);

export const updateRootResourceAgent = createAction(ResourceAgent.UPDATE_ROOT_RESOURCE_AGENT);
export const updateRootResourceAgentSuccess = createAction(ResourceAgent.UPDATE_ROOT_RESOURCE_AGENT_SUCCESS);
export const updateRootResourceAgentFail = createAction(ResourceAgent.UPDATE_ROOT_RESOURCE_AGENT_FAIL);

export const updateChildResourceAgent = createAction(ResourceAgent.UPDATE_CHILD_RESOURCE_AGENT);
export const updateChildResourceAgentSuccess = createAction(ResourceAgent.UPDATE_CHILD_RESOURCE_AGENT_SUCCESS);
export const updateChildResourceAgentFail = createAction(ResourceAgent.UPDATE_CHILD_RESOURCE_AGENT_FAIL);
