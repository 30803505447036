import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import { loadTimerSms } from '../../../Redux/Actions/timerSms';
import TimerSmsDetailModal from './TimerSmsDetailModal';
import TimerSmsList from './TimerSmsList';
import TimerSmsSearchBox from './TimerSmsSearchBox';
import { TIMER_HIDDEN_TYPE } from 'Common/constant';

const pageSize = 50;

function SendingTimerSms() {
  const loading = useSelector((state) => state.timerSms.loading);
  const timerSms = useSelector((state) => state.timerSms.items);
  const totalRow = useSelector((state) => state.timerSms.totalRow);
  const [filter, setFilter] = useState({
    timer: 0,
    hidden: `${TIMER_HIDDEN_TYPE.HIDE}`,
    sort: { sendTime: 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [timerSmsDetail, setTimerSmsDetail] = useState(null);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTimerSms({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadTimerSms({ ...filter, pageIndex, pageSize }));
  };

  const handleClose = () => {
    setTimerSmsDetail(null);
  };

  const reload = () => {
    dispatch(loadTimerSms({ ...filter, pageIndex, pageSize }));
  };

  const handleClickDetail = (item) => {
    setTimerSmsDetail(item);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ timer: 0, sort: { sendTime: 'DESC' } });

  return (
    <div>
      <LoadingBar show={loading} />
      <TimerSmsSearchBox onSubmit={handleSearch} onClear={onClear} />
      {timerSmsDetail && (
        <TimerSmsDetailModal
          onClose={handleClose}
          timerSms={timerSmsDetail}
          reload={reload}
        />
      )}
      <TimerSmsList
        loading={loading}
        logSendSms={timerSms}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default SendingTimerSms;
