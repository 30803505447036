import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Label, Card } from 'reactstrap';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Util from '../../../Util/Util';
import { PROFILE_TYPE } from '../../../Config/listConstants';
import { GENDER, OTP_TYPE } from '../../../Common/constant';
import RadioGroup from '../../../Components/RadioGroup';
import LoadingBar from '../../../Components/LoadingBar';
import ImageList from './ImageList';
import { loadUser, updateUser } from '../../../Redux/Actions/user';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { FormGroup, FormLabel, MenuItem, Select } from '@material-ui/core';
import { loadOperators } from '../../../Redux/Actions/operators';
import { loadAgents } from '../../../Redux/Actions/agents';
import { loadAllArea } from '../../../Redux/Actions/nameState';
import DatePicker from '../../../Components/DatePicker';
import {
  checkFullWidth,
  needOtpOptionsRequired,
  standByOptions,
  standByRequired,
  validateFullWidthVsNumber,
} from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index({ location }) {
  const params = useParams();
  const userId = params.userId;
  const dispatch = useDispatch();
  const [isOpeChara, setIsOpeChara] = useState(false);
  const loading = useSelector((state) => state.user.loading);
  const updating = useSelector((state) => state.user.updating);
  const updated = useSelector((state) => state.user.updated);
  const user = useSelector((state) => state.user.data);
  const resource = useSelector((state) => state.resource.data);
  const nameState = useSelector((state) => state.nameState.data);
  const loadingResource = useSelector((state) => state.resource.loading);
  const loadingNameRank = useSelector((state) => state.nameRank.loading);
  const areas = useSelector((state) => state.nameState.dataChild);

  useEffect(() => {
    dispatch(loadUser(userId));
    setIsOpeChara(
      (localStorage.getItem('homePage') || '').includes('opechara'),
    );
  }, [dispatch, userId]);

  useEffect(() => {
    if (updated) {
      redirectPageDetailUser(userId);
    }
  }, [updated]);

  const refreshUser = () => {
    dispatch(loadUser(userId));
  };

  const history = useHistory();
  const redirectPageDetailUser = (id) => {
    history.push(`/${isOpeChara ? 'character' : 'user'}/${id}/detailshort`);
  };

  const submitEditMember = () => {
    if (!editingUser.gender) {
      return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.gender);
    }

    if (Number(editingUser.needOtp) === OTP_TYPE.NO_OTP) {
      if (!editingUser.password) {
        return toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.phoneNumber);
      } else if (
        editingUser.password &&
        (String(editingUser.password).match(/\d+/g) ?? []).join('').length !== 6
      ) {
        return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      } else if (editingUser.password && checkFullWidth(editingUser.password)) {
        return toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      }
    }

    const updateData = _.pick(editingUser, [
      'id',
      'password',
      'gender',
      'birthday',
      'state',
      'charaStatus',
      'aboutMe',
      'adminMemo',
      'attributes',
      'needOtp',
      'area',
      'standBy',
      'agentNumber',
      'operatorNumber',
    ]);
    dispatch(updateUser(updateData));
  };

  useEffect(() => {
    dispatch(loadOperators({ pageIndex: 1, pageSize: 1000 }));
    dispatch(loadAgents({ pageIndex: 1, pageSize: 1000 }));
  }, [dispatch]);

  const [editingUser, setEditingUser] = useState();
  useEffect(() => {
    if (user) {
      setEditingUser({
        ...user,
        operatorNumber: user.operator?.operatorNumber,
        agentNumber: user.agent?.agentNumber,
      });
      if (user?.state) {
        dispatch(loadAllArea(user?.state));
      }
    }
  }, [dispatch, user]);

  const onChange = (e) => {
    const updatedUser = _.set(editingUser, e.target.name, e.target.value);
    if ('state' === e.target.name) {
      updatedUser.area = null;
    }
    setEditingUser({ ...updatedUser });
  };

  const onChangeBirthday = (e) => {
    const updatedUser = _.set(
      editingUser,
      e.target.name,
      new Date(e.target.value).getTime(),
    );
    setEditingUser({ ...updatedUser });
  };

  const onChangeAttribute = (e) => {
    setEditingUser({
      ...editingUser,
      attributes: {
        ...editingUser.attributes,
        [e.target.name]: Number(e.target.value),
      },
    });
  };

  const showResource = () => {
    let profileItem = [];
    let userGender = Number(editingUser.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : editingUser.gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    resource.items
      .filter(
        (item) =>
          item.value === PROFILE_TYPE.ROOT && item.gender === userGender,
      )
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        profileItem.push({
          resource: findResources(resourceItem.label),
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        });
        return resourceItem;
      });

    return profileItem?.map((item, key) => (
      <Row key={key}>
        <Label xs={12}>
          <FormLabel component="legend">{item.label}</FormLabel>
          <div className="d-line-select-border">
            {!loadingResource ? (
              <Select
                name={item.name}
                key={item?.name}
                onChange={onChangeAttribute}
                value={
                  editingUser?.attributes && editingUser?.attributes[item?.name]
                }
                defaultValue={user?.attributes && user?.attributes[item?.name]}
                className={'w-100 d-select-multiple-cus'}
              >
                <MenuItem value=""> </MenuItem>
                {item.resource
                  .sort((a, b) => a.value - b.value)
                  .map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      selected={
                        editingUser.attributes &&
                        option.value === editingUser.attributes[option.profile]
                          ? true
                          : false
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <div></div>
            )}
          </div>
        </Label>
      </Row>
    ));
  };

  const findAttributes = (resourceName) => {
    let userGender = Number(editingUser.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : editingUser.gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    let parrentName = resource?.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );
    let responseResources = resource?.items.find(
      (x) =>
        x.gender === userGender &&
        x.profile === parrentName?.profile &&
        user?.attributes &&
        user?.attributes[parrentName.profile] &&
        x.value === user?.attributes[parrentName.profile],
    );

    return responseResources?.label;
  };

  const findResources = (resourceName) => {
    let userGender = Number(editingUser.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : user.gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    let parrentName = resource.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );
    let responseResources = resource.items.filter(
      (x) =>
        x.gender === userGender &&
        x.profile === parrentName.profile &&
        x.value !== 0,
    );
    return responseResources;
  };

  const handleChangeState = (e) => {
    onChange(e);
    dispatch(loadAllArea(e.target.value));
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsNumber(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
        editingUser[name] = '';
      } else {
        editingUser[name] = value;
      }

      setEditingUser({ ...editingUser });
    });
  };

  return (
    <>
      <Helmet>
        <title>ユーザー詳細-編集</title>
      </Helmet>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={(loading && !_.isEmpty(resource)) || updating} />
        {editingUser && !loadingNameRank && (
          <MuiThemeProvider theme={customTheme}>
            <div className="align">
              <div>
                <Button
                  className="btn-custom btn-red"
                  variant="contained"
                  onClick={() => redirectPageDetailUser(user.id)}
                >
                  閉じる
                </Button>
                <Button
                  className="btn-custom btn-blue mr-3"
                  variant="contained"
                  onClick={submitEditMember}
                >
                  更新する
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={4}>
                <Card body className="mb-4">
                  <ImageList
                    user={user}
                    onUpdateData={refreshUser}
                    loading={loading}
                  />
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>&nbsp;</Label>
                    <Label xs={12}>&nbsp;</Label>
                    <Label xs={12}>&nbsp;</Label>
                    <Label xs={12}>&nbsp;</Label>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  {!loadingResource ? showResource() : null}
                </Card>
              </Col>
              <Col xs={8}>
                <Card body className="mb-4">
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={12}>
                          <FormLabel component="legend">
                            パスワード（数字6桁）
                          </FormLabel>
                          <div
                            style={{ display: 'flex', justifyContent: 'left' }}
                          >
                            <input
                              defaultValue={editingUser.password}
                              name="password"
                              type="text"
                              value={editingUser.password}
                              onChange={(e) =>
                                validatePassword(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Label>

                        <Label xs={12}>
                          <FormLabel component="legend">
                            ログインSMS必要
                          </FormLabel>
                          <div
                            style={{ display: 'flex', justifyContent: 'left' }}
                          >
                            <RadioGroup
                              name="needOtp"
                              defaultValue={editingUser.needOtp}
                              options={needOtpOptionsRequired()}
                              onChange={onChange}
                            />
                          </div>
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <FormGroup row>
                        <Label xs={12}>
                          <FormLabel component="legend">
                            年齢{' '}
                            {Util.calcAge(
                              Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
                            )}
                            歳
                          </FormLabel>
                          {/*<input
                            name="birthday"
                            type="date"
                            defaultValue={Util.timestampToDate(
                              user?.birthday,
                              'YYYY-MM-DD',
                            )}
                            onChange={onChangeBirthday}
                             />*/}

                          <DatePicker
                            defaultValue={Util.timestampToDate(
                              user?.birthday,
                              'YYYY-MM-DD',
                            )}
                            name="birthday"
                            onChange={onChangeBirthday}
                          />
                        </Label>

                        <Label xs={12}>
                          <FormLabel component="legend">待機状態</FormLabel>
                          <RadioGroup
                            name="standBy"
                            defaultValue={editingUser?.standBy}
                            options={standByRequired()}
                            onChange={onChange}
                            disabled={false}
                          />
                        </Label>

                        <Label xs={12}>&nbsp;</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Label
                        xs={12}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <FormLabel component="legend">都道府県</FormLabel>
                        <div className="d-line-select-border">
                          {nameState ? (
                            <Select
                              name="state"
                              onChange={handleChangeState}
                              className={'w-100 d-select-multiple-cus'}
                              defaultValue={user.state}
                              value={editingUser.state}
                            >
                              <MenuItem value="">---</MenuItem>
                              {nameState.items.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  selected={option.id === user.state}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </Label>
                      <Label
                        xs={12}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <FormLabel component="legend">エリア</FormLabel>
                        <div className="d-line-select-border">
                          {areas ? (
                            <Select
                              name="area"
                              onChange={onChange}
                              className={'w-100 d-select-multiple-cus'}
                              defaultValue={user.area}
                              value={editingUser.area}
                            >
                              <MenuItem value="">---</MenuItem>
                              {areas.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  selected={option.id === user.area}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </Label>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                      <Label xs={12}>&nbsp;</Label>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label xs={12} xl={9}>
                          <FormLabel component="legend">自己PR</FormLabel>
                          <textarea
                            defaultValue={editingUser.aboutMe}
                            name="aboutMe"
                            rows="3"
                            onChange={onChange}
                            style={{ width: '100%', padding: 10 }}
                          />
                        </Label>
                      </Row>
                    </Card>
                    <Card body className="mb-4">
                      <Row>
                        <Label xs={12} xl={9}>
                          <FormLabel component="legend">管理者メモ</FormLabel>
                          <textarea
                            defaultValue={editingUser.adminMemo}
                            name="adminMemo"
                            rows="5"
                            onChange={onChange}
                            style={{ width: '100%', padding: 10 }}
                          />
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MuiThemeProvider>
        )}
      </div>
    </>
  );
}

export default Index;
