import { handleActions } from 'redux-actions';
import IMPORT_CSV from '../Types/importCSV';

const initialState = {
  message: null,
  loading: false,
  success: false,
};

const actions = {
  [IMPORT_CSV.IMPORT_CSV]: (state) => ({
    ...state,
    message: null,
    loading: true,
    success: false,
  }),
  [IMPORT_CSV.IMPORT_CSV_SUCCESS]: (state) => ({
    ...state,
    message: 'インポートが完了しましました',
    loading: false,
    success: true,
  }),
  [IMPORT_CSV.IMPORT_CSV_FAIL]: (state, payload) => ({
    ...state,
    message: payload.message,
    loading: false,
    success: false,
  }),
};

export default handleActions(actions, initialState);
