import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ImageModal from '../UserManagement/UserEdit/ImageModal';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { Link } from 'react-router-dom';
import './styles.scss';
import sortBy from 'lodash/sortBy';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

function TagList(props) {
  const {
    data,
    onUpdate,
    pageSize = 2,
    pageIndex = 1,
    filter = { sort: {} },
    handleClickDetail,
    onSort = (sortByKey) => {
    },
    labelDb
  } = props

  const [editingImage, setEditingImage] = useState();

  const hideEditModal = () => {
    setEditingImage(null);
  };

  const onUpdateImage = () => {
    hideEditModal();
    onUpdate();
  };

  const renderTagUserByOrder = ({ tags }) => {
    const values = sortBy(tags, 'displayOrder');

    return values ? values.map(v => (<div key={v.id} className='tag'> {v.label} </div>)) : '';
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="電話番号（送信）"
                  name="users.phoneNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text={labelDb?.label_nickName?.content}
                  name="users.nickName"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>性別</TableCell>
              <TableCell>登録タグ</TableCell>
            </TableRow>

            {data.map((value, index) => (
              <TableRow key={value?.id}>
                <TableCell>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                <TableCell>
                  <span>{value?.phoneNumber}</span>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/user/${value?.id}/detail`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => localStorage.setItem('userId', value?.id)}
                  ><img
                    className="imgRound"
                    src={
                      value?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                      value?.avatar?.url
                        ? value?.avatar?.url
                        : NoPhoto
                    }
                    alt=""
                  /></Link>
                </TableCell>
                <TableCell>{value?.nickName}</TableCell>
                <TableCell>
                  {Util.genderIdToText(value?.gender)}
                </TableCell>

                <TableCell>
                  <div id='wrapperTag'>
                    {renderTagUserByOrder({ tags: value.tags })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {editingImage && (
          <ImageModal
            image={editingImage}
            onClose={hideEditModal}
            onUpdate={onUpdateImage}
            type={'tag_list'}
          />
        )}
      </Card>
    </MuiThemeProvider>
  );
}

export default TagList;
