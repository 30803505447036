import { createAction } from 'redux-actions';
import Operator from '../Types/operator';

export const loadOperator = createAction(Operator.LOAD_OPERATOR);
export const loadOperatorSuccess = createAction(Operator.LOAD_OPERATOR_SUCCESS);
export const loadOperatorFail = createAction(Operator.LOAD_OPERATOR_FAIL);

export const loadDetailOperator = createAction(Operator.LOAD_DEATIL_OPERATOR);
export const loadDetailOperatorSuccess = createAction(Operator.LOAD_DEATIL_OPERATOR_SUCCESS);
export const loadDetailOperatorFail = createAction(Operator.LOAD_DEATIL_OPERATOR_FAIL);

export const updateOperator = createAction(Operator.UPDATE_OPERATOR);
export const updateOperatorSuccess = createAction(Operator.UPDATE_OPERATOR_SUCCESS);
export const updateOperatorFail = createAction(Operator.UPDATE_OPERATOR_FAIL);

export const operatorLogout = createAction(Operator.OPERATOR_LOG_OUT);
