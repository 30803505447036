import { sendDelete, sendGet, sendPut } from './axios';

export const getResource = (params) => sendGet('/all-resource', params);
export const getNameState = (params) => sendGet('/all-state', params);
export const getAllArea = (params) => sendGet('/all-area/' + params);

export const getRootResource = (params) => sendGet('/cms/all-root-resource', params);
export const getDetailResource = (params) => sendGet(`/cms/child-attribute`, { profile: params });
export const updateRootResource = (params) => sendPut(`/cms/update-root-profile`, params);
export const updateChildResource = (params) => sendPut(`/cms/update-child-profile`, params);

export const updateNameState = (params) => sendPut(`/cms/update-name-state`, params);

export const removeTwilio = (id) => sendDelete(`/cms/twilio/${id}`);
