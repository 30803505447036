import { handleActions } from 'redux-actions';
import SearchPresets from '../Types/searchPresets';

const initialState = {
  data: [],
  loading: false,
  success: false,
};

const actions = {
  [SearchPresets.LOAD_SEARCH_PRESETS]: (state) => ({
    ...state,
    loading: true,
    success: false,
  }),
  [SearchPresets.LOAD_SEARCH_PRESETS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    success: false,
  }),
  [SearchPresets.LOAD_SEARCH_PRESETS_FAIL]: (state) => ({
    ...state,
    data: null,
    success: false,
  }),
};

export default handleActions(actions, initialState);
