import { sendGet, sendPost } from './axios';

export const getChatByUser = (params) => {
  return sendGet(`/cms/chat/user/${params.userId}/get`, params);
};
export const getChatMessageByConversation = (params) => {
  return sendGet(`/cms/chat/message/${params.conversationId}`, params);
};
export const sendMessage = (payload) => {
  return sendPost('/cms/chat/send-message', payload);
};
export const readAllMessageUnRead = (payload) => {
  return sendGet(`/chat/${payload.conversationId}`, { userId: payload.userId });
};
