import { handleActions } from 'redux-actions';
import textWebPage from '../Types/textWebPage';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
  updating: false,
  success: false,
};

const actions = {
  [textWebPage.LOAD_TEXT_WEB_PAGE]: (state) => ({
    ...state,
    loading: true,
  }),
  [textWebPage.LOAD_TEXT_WEB_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [textWebPage.LOAD_TEXT_WEB_PAGE_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};

export default handleActions(actions, initialState);
