import React, { useState } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import {
  Button,
  createMuiTheme,
  MuiThemeProvider,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import {getOperators} from '../../../Api/operatorAPI';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../../Common/constant';


const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
  },
});

function SearchBox(props) {
  const [filter, setFilter] = useState({});
  const [operators, setOperators] = useState([]);

  React.useEffect(() => {
    getOperators({ pageIndex: 1, pageSize: 1000 }).then(res => {
      setOperators(res.data.data);
    });
  }, [])

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    setFilter({});
  };

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <div className="form__search" style={{ margin: '15px 15px' }}>
          <Row className="blockSearch">
            <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1 }>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Col xs={12}>
                      <TextField
                        id="standard-required"
                        label="電話番号"
                        type="text"
                        name="phoneNumber"
                        onChange={onChange}
                        className="w-100"
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup row>
                    <Col xs={12}>
                      <Select
                        id="operatorNumber"
                        label="オペレーターNumber"
                        name="operatorNumber"
                        onChange={onChange}
                        className="w-100"
                        defaultValue={'label'}
                      >
                        <MenuItem disabled value="label" style={{ display: 'none' }}>
                          <span style={{color: 'rgba(0, 0, 0, 0.54)'}}>オペレーターNumber</span>
                        </MenuItem>
                        <MenuItem value={''}>---</MenuItem>
                        {operators.map((v) => {
                          return (
                            <MenuItem value={v.operatorNumber} key={v.id}>{v.operatorNumber}</MenuItem>
                          )
                        })}
                      </Select>
                      {/* <TextField
                        id="standard-required"
                        label="オペレーターNumber"
                        type="text"
                        name="operatorNumber"
                        maxLength={11}
                        onChange={onChange}
                        className="w-100"
                      /> */}
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup row>
                    <Col xs={12}>
                      <TextField
                        id="standard-required"
                        label="オペレーター名"
                        type="text"
                        name="operatorName"
                        onChange={onChange}
                        className="w-100"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2 }>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={5}>
                    <TextField
                      id="date"
                      label="最終ログイン"
                      size={7}
                      className="w-100"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxLength={7}
                      name="lastloginLower"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs={2} className="flex-end d-col-2-symbol">〜</Col>
                  <Col xs={5}>
                    <TextField
                      id="date"
                      label="最終ログイン"
                      size={7}
                      className="w-100"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxLength={7}
                      name="lastloginUpper"
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup row>
                  <Col xs={5}>
                    <TextField
                      id="date"
                      label="登録日時"
                      size={7}
                      className="w-100"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxLength={7}
                      name="registerDateLower"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs={2} className="flex-end d-col-2-symbol">〜</Col>
                  <Col xs={5}>
                    <TextField
                      id="date"
                      label="登録日時"
                      size={7}
                      className="w-100"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxLength={7}
                      name="registerDateUpper"
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Col>
            <Col { ...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3 }>
            </Col>
          </Row>
          <div className="mtSearch" style={{ position: 'relative' }}>
            <center>
              <Button
                className="btn-search"
                variant="contained"
                onClick={handleSearch}
              >
                検索する
              </Button>
            </center>
            <Button
              className="btn-red"
              variant="contained"
              onClick={handleClear}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              クリア
            </Button>
          </div>
        </div>
      </React.Fragment>
    </MuiThemeProvider>
  );
}

export default SearchBox;
