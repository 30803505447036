import { call, put, takeLatest } from 'redux-saga/effects';
import Information from '../Types/information';
import { getInformations } from '../../Api/informationAPI';
import { loadInformationsFail, loadInformationsSuccess } from '../Actions/information';

function* handleLoadInformations(action) {
  try {
    const response = yield call(getInformations, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadInformationsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadInformationsFail(error));
  }
}

export default function* agentsSaga() {
  yield takeLatest(Information.LOAD_INFORMATIONS, handleLoadInformations);
}
