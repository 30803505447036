import { sendDelete, sendGet, sendPost } from './axios';

export const deleteTimerMail = (id) => sendDelete(`/cms/timer-mail/${id}`);
export const updateTimerMail = (data) => sendPost(`/cms/timer-mail/update`, data);
export const deleteTimerNoti = (id) => sendDelete(`/cms/timer-noti/${id}`);
export const updateTimerNoti = (data) => sendPost(`/cms/timer-noti/update`, data);
export const deleteTimerSms = (id) => sendDelete(`/cms/timer-sms/${id}`);
export const updateTimerSms = (data) => sendPost(`/cms/timer-sms/update`, data);

export const createChatRoomTimer = (data) => sendPost('/cms/create-chatroom-timer', data);
export const getTimerChatRooms = (data) => sendGet('/cms/chatroom-timers', data);
export const deleteTimerChatRoom = (id) => sendDelete(`/cms/timer-chatroom/${id}`);
