import React, { useEffect, useState } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import TextWebPageList from './TextWebPageList';
import Pagination from '../../Components/Pagination';
import { loadTextWebPage } from '../../Redux/Actions/textWebPage';

const pageSize = 50;

function Index() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.textWebPage.loading);
  const textWebPages = useSelector((state) => state.textWebPage.items);
  const totalRow = useSelector((state) => state.textWebPage.totalRow);
  // const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    sort: { 'displayOrder': 'DESC' },
    sortBy: 'displayOrder',
    orderBy: 'DESC', // Sort asc, so by sort null last rows for displayOrder; It's fixed DESC
  });

  useEffect(() => {
    dispatch(loadTextWebPage({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const onSuccess = () => {
    dispatch(loadTextWebPage({ ...filter, pageIndex, pageSize }));
  };

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      {/*Trigger hover tooltip*/}
      <div id={'d-hover-tooltip'}></div>

      <TextWebPageList
        textWebPages={textWebPages}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        onSuccess={onSuccess}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
