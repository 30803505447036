import { sendDelete, sendGet } from './axios';

export const getPoint = (params) => sendGet('/cms/payment', params);
export const getPointByUser = (params) => sendGet(`/cms/payment/user/${params.userId}`, params);

export const getPointGetLog = (params) => sendGet('/cms/pointgetlog/list', params);

export const removePoint = (id) => sendDelete(`/cms/payment/${id}`);

export const removePointLogGet = (id) => sendDelete(`/cms/point-get-log/${id}`);
