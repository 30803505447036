import { call, put, takeLatest } from 'redux-saga/effects';
import LikeUserByMe from '../Types/likeUserByMe';
import { getLikeByMe } from '../../Api/likeApi';
import { loadLikeUserByMeFail, loadLikeUserByMeSuccess } from '../Actions/likeUserByMe';

function* handleLoadLikeUserByMe(action) {
  try {
    const response = yield call(getLikeByMe, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLikeUserByMeSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLikeUserByMeFail(error));
  }
}

export default function* likeUserByMeSaga() {
  yield takeLatest(LikeUserByMe.LOAD_LIKE_USER_BY_ME, handleLoadLikeUserByMe);
}
