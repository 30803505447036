import { Card, Table, TableBody, TableCell, TableRow, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../Components/Pagination';
import { loadCharacter } from '../../../Redux/Actions/character';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import { convertEnvInfo } from '../../../Util/utils';

const pageSize = 20;

export default function CharaListAgent() {
  const params = useParams();
  const agentId = params.agentId;

  const [pageIndex, setPageIndex] = useState(1);
  const dispatch = useDispatch();

  const listChara = useSelector((state) => state.chara.data);
  const totalRow = useSelector((state) => state.chara.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadCharacter({ agentId, pageIndex, pageSize }));
  }, [dispatch, agentId, pageIndex]);

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const styleTableCellTd = {
    padding: "0 .75rem"
  }

  return (
    <div>
      <div style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end', marginTop: 20 }}>
          <Link
            to={`/agent/${agentId}/detailagent`}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              前の画面に戻る
            </Button>
          </Link>
      </div>

      <Card
        className="scroll-x-on-top"
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">画像</TableCell>
              <TableCell style={{minWidth:70, maxWidth:90}}>{labelDb?.label_nickName?.content}</TableCell>
              <TableCell>性別</TableCell>
              <TableCell>都道府県</TableCell>
              <TableCell>会社名</TableCell>
              <TableCell>送信数</TableCell>
              <TableCell>受信数</TableCell>
              <TableCell>×未返信数</TableCell>
              <TableCell>登録日</TableCell>
              <TableCell>ログイン</TableCell>
            </TableRow>
            {listChara?.map((chara, index) => (
              <TableRow key={chara.id} style={{ height: 48 }}>
                <TableCell style={{minWidth:20, maxWidth:20, ...styleTableCellTd}}>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                <TableCell style={styleTableCellTd} align="center">
                  <Link
                    to={`/user/${chara.id}/detail`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => saveLocalStore(chara.id)}
                  >
                    <img className="object-fit-cover"
                      style={{ width: 30, height: 30, borderRadius: '50%' }}
                      src={chara.avatar ? chara.avatar.url : NoPhoto}
                      alt=""
                    />
                  </Link>
                </TableCell>
                <TableCell style={styleTableCellTd}>{chara.nickName}</TableCell>
                <TableCell style={styleTableCellTd}>{Util.genderIdToText(chara.gender)}</TableCell>
                <TableCell style={styleTableCellTd}>{chara.states?.label}</TableCell>
                <TableCell style={styleTableCellTd}>{chara.agent.nickName}</TableCell>
                <TableCell style={styleTableCellTd}>
                  {chara.countPhotoSending + chara.countTextSending}
                </TableCell>
                <TableCell style={styleTableCellTd}>
                  {chara.countPhotoReceived + chara.countTextReceived}
                </TableCell>
                <TableCell style={styleTableCellTd}>{3}</TableCell>
                <TableCell style={styleTableCellTd} className="text-muted">
                  <time>
                    {Util.timestampToDate(chara.registerDate, 'YYYY/MM/DD')}
                  </time>
                </TableCell>
                <TableCell style={styleTableCellTd} className="text-muted">
                  <time>
                    {Util.timestampToDate(chara.lastlogin, 'YYYY/MM/DD')}
                  </time>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <center>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
            onPageChange={handlePageChange}
          />
        </center>
      </Card>
    </div>
  );
}
