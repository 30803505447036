import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TagSearchBox from './TagSearchBox';
import TagList from './TagList';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { getUsersWithTagUsers } from '../../Api/tag';
import { toast } from 'react-toastify';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function Tag() {
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'users.lastlogin': 'DESC' },
  });

  const [data, setData] = useState({ items: [], totalRow: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  useEffect(() => {
    getData();
  }, [filter, pageIndex]);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const getData = async () => {
    setIsLoading(false);

    try {
      const result = await getUsersWithTagUsers({ pageIndex, pageSize, ...filter });

      if (result.data?.success) {
        const dataFetch = result.data;
        setData({ items: dataFetch.data, totalRow: dataFetch.totalRow });
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Error!');
    }
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: {} });
    setPageIndex(1);
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const onClear = () => {
    setFilter({ sort: { 'users.lastlogin': 'DESC' } });
  };

  return (
    <div>
      <TagSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      <LoadingBar show={isLoading} />
      <TagList
        data={data.items}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        onClear={onClear}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={data.totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Tag;
