import React, { useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import moment from 'moment';
import truncate from 'lodash/truncate';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { convertConfirmTypeText, convertContactTypeText } from '../../Util/utils';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import UserDelete from '../../assets/image/delete_user.png';

const ContactListItem = ({ contact, handleClickDetail, no }) => {
  const content = truncate(get(contact, 'content'), {
    length: 6,
    separator: ' ',
  });

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return useMemo(
    () => (
      <TableRow style={{ height: 48 }}>
        <TableCell>{no}</TableCell>
        <TableCell>{contact?.user?.phoneNumber ?? ''}</TableCell>
        <TableCell align="center">
          {contact.memberId ? (
            <Link
              to={`/user/${contact.memberId}/detail`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => saveLocalStore(contact.memberId)}
            >
              <img
                className="imgRound"
                src={
                  contact?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                  get(contact, 'user.avatar.url') || NoPhoto}
                alt=""
              />
            </Link>
          ) : (
            <Link
              to={`/user/detail`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="imgRound"
                src={
                  contact?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                  get(contact, 'user.avatar.url') || NoPhoto}
                alt=""
              />
            </Link>
          )}
        </TableCell>
        <TableCell>
          { contact?.user?.nickName ?? contact?.fullname }
        </TableCell>
        <TableCell>
          {Util.genderIdToText(get(contact, 'user.gender'))}
        </TableCell>
        <TableCell>{get(contact, 'email')}</TableCell>
        <TableCell>{get(contact, 'phone_number')}</TableCell>
        <TableCell>{convertContactTypeText(contact?.type)}</TableCell>
        <TableCell>{content}</TableCell>
        <TableCell align="center">
          {get(contact, 'attachments') ? (
            <>
              {get(contact, 'attachments').map((image) => (
                <img
                  key={image.id}
                  className="imgSquare object-fit-cover"
                  src={image.url}
                  alt=""
                />
              ))}
            </>
          ) : null}
        </TableCell>
        <TableCell>{get(contact, 'title')}</TableCell>
        <TableCell>{convertConfirmTypeText(contact?.confirm)}</TableCell>

        <TableCell>
          {moment(Number(get(contact, 'createdDate'))).format('YYYY-MM-DD HH:mm:ss')}
        </TableCell>

        <TableCell align="center">
          <div onClick={handleClickDetail}
               className="d-color-link"
               style={{ cursor: 'pointer' }}>
            表示
          </div>
        </TableCell>
      </TableRow>
    ),
    [contact, no, content, handleClickDetail],
  );
};

export default ContactListItem;
