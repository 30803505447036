import { call, put, takeLatest } from 'redux-saga/effects';
import LogSendMail from '../Types/logSendMail';
import { getLogSendMail } from '../../Api/logSendMailApi';
import { loadLogMailFail, loadLogMailSuccess } from '../Actions/logSendMail';

function* handleLoadLogSendMail(action) {
  try {
    const response = yield call(getLogSendMail, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadLogMailSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadLogMailFail(error));
  }
}

export default function* logSendMailSaga() {
  yield takeLatest(LogSendMail.LOAD_LOG_SEND_MAIL, handleLoadLogSendMail);
}
