import { handleActions } from 'redux-actions';
import ChatUser from '../Types/chatUser';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [ChatUser.LOAD_CHAT_USER]: (state) => ({
    ...state,
    loading: true,
  }),
  [ChatUser.LOAD_CHAT_USER_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ChatUser.LOAD_CHAT_USER_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
