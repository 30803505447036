import { call, put, takeLatest } from 'redux-saga/effects';
import Twilio from '../Types/twilio';
import { getTwilio } from '../../Api/twilioApi';
import { loadTwilioFail, loadTwilioSuccess } from '../Actions/twilio';

function* handleLoadTwilio(action) {
  try {
    const response = yield call(getTwilio, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadTwilioSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadTwilioFail(error));
  }
}

export default function* pointSaga() {
  yield takeLatest(Twilio.LOAD_TWILIO, handleLoadTwilio);
}
