import { sendDelete, sendGet } from './axios';

export const getBlocks = (pageIndex, pageSize) => {
  return sendGet(`/cms/blocks/?pageIndex=${pageIndex}&pageSize=${pageSize}`);
};
export const getBlockSaga = (payload) => {
  return sendGet(`/cms/blocks`, payload);
};
export const unBlock = (blockId, blockerId, targetId) => {
  return sendDelete(`/cms/block/${blockId}?blockerId=${blockerId}&targetId=${targetId}`);
};

export const getUserBlocks = (payload) => sendGet(`/cms/blocks/user`, payload);
