import { handleActions } from 'redux-actions';
import FootprintLog from '../Types/footprintLog';

const initialState = {
  items: [],
  loading: false,
  totalRow: 0,
};

const actions = {
  [FootprintLog.LOAD_FOOTPRINTLOG]: (state) => ({
    ...state,
    loading: true,
  }),
  [FootprintLog.LOAD_FOOTPRINTS]: (state) => ({
    ...state,
    loading: true,
  }),
  [FootprintLog.LOAD_FOOTPRINTLOG_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [FootprintLog.LOAD_FOOTPRINTLOG_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
};
export default handleActions(actions, initialState);
