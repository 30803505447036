import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUsers } from '../../Redux/Actions/users';
import SearchBox from './SearchBox';
import UserList from './UserList';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function UserSearch() {
  const loading = useSelector((state) => state.users.loading);
  const users = useSelector((state) => state.users.items);
  const totalRow = useSelector((state) => state.users.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'lastlogin',
    orderBy: 'DESC',
    sort: { lastlogin: 'DESC' },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadUsers({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handleSearch = (searchFilter) => {
    setFilter({
      // ...filter,
      ...searchFilter,
      sortBy: 'lastlogin',
      orderBy: 'DESC',
    });

    setPageIndex(1);
    localStorage.setItem('UserSearchFilter', JSON.stringify(searchFilter));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadUsers({ ...filter, pageIndex, pageSize }));
  };

  const refreshList = (isReloadFilter) => {
    if (isReloadFilter)
      setFilter({
        sortBy: 'lastlogin',
        orderBy: 'DESC',
        sort: { lastlogin: 'DESC' },
      });
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter?.sort && filter?.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter?.sort && filter?.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter?.sort && filter?.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }

    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  return (
    <div className="mt-2">
      <LoadingBar show={loading} />
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />

      <UserList
        users={users}
        onUpdate={refreshList}
        filter={filter}
        onSort={onSort}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={loading}
        totalRow={totalRow}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default UserSearch;
