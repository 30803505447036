import React, { useState } from 'react';
import { Button, FormLabel } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../../Components/RadioGroup';
import { timerSendOptions, timerSentTypeOptions } from '../../../Util/utils';
import {
  COLUMN_DEFINED_SEARCH_BOX,
  TIMER_HIDDEN_TYPE,
  TIMER_SENT,
} from '../../../Common/constant';
import { timerHiddenTypes } from 'Common/constant_text';

function TimerSmsSearchBox(props) {
  const [filter, setFilter] = useState({ timer: 0 });

  const onChange = (event) => {
    filter[event.target.name] = event.target.value.trim();
    setFilter(filter);
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    setFilter({});
    Array.from(document.querySelectorAll('input')).forEach((input) => {
      if (
        input.type === 'radio' &&
        input.value === '0' &&
        input.name === 'timer'
      )
        input.checked = true;
      if (input.type === 'radio' && input.value === '') input.checked = true;
      if (!input.type !== 'checkbox' && input.type !== 'radio')
        input.value = '';
      if (input.type === 'checkbox' && input.name === 'gender')
        input.checked = true;
    });
    // props.onClear();
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">チャット画像</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}></Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}></Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">予約時間</FormLabel>
                <RadioGroup
                  name="timer"
                  defaultValue={TIMER_SENT.WAITING}
                  options={timerSendOptions()}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">送信種別</FormLabel>
                <RadioGroup
                  name="type"
                  defaultValue=""
                  options={timerSentTypeOptions()}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">非表示Data</FormLabel>
                <RadioGroup
                  name="hidden"
                  defaultValue={`${TIMER_HIDDEN_TYPE.HIDE}`}
                  options={timerHiddenTypes.map((e) => ({
                    value: e.id,
                    label: e.label,
                  }))}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TimerSmsSearchBox;
