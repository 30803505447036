import { call, put, takeLatest } from 'redux-saga/effects';
import Blogs from '../Types/blogs';
import { getBlogs } from '../../Api/blogAPI';
import { loadBlogsFail, loadBlogsSuccess } from '../Actions/blogs';

function* handleLoadBlogs(action) {
  try {
    const response = yield call(getBlogs, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadBlogsSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadBlogsFail(error));
  }
}

export default function* blogsSaga() {
  yield takeLatest(Blogs.LOAD_BLOGS, handleLoadBlogs);
}
