import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const createSubImageJob = (payload) => {
  const { url, subImgNumber, jobDetailId, status, type } = payload;
  return sendPost('/cms/job/img-sub', {
    sub_images: [
      {
        url,
        subImgNumber,
        jobDetailId,
        status,
        type,
      },
    ],
  });
};

export const createImgMain = (payload) => {
  const { url, jobDetailId, status, subImgNumber, type } = payload;

  return sendPost('/cms/job/img-main', {
    url,
    jobDetailId,
    status,
    subImgNumber,
    type,
  });
};

export const deleteImageJob = (id) => sendDelete(`/cms/job/img/${id}`);
export const deleteImageJobStorage = (file) => sendGet(`/image/storage?file=${btoa(file)}`);
// No use
export const changeImageJonStatus = ({ id, inspection }) => sendPut(`/cms/job/img/${id}`, { inspection });

