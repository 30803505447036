import React, { useEffect, useState } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import { loadContacts } from '../../Redux/Actions/contacts';
import ContactList from './ContactList';
import Pagination from '../../Components/Pagination';
import ContactDetailModal from './ContactDetailModal';
import { sendPut } from '../../Api/axios';
import { toast } from 'react-toastify';
import SearchBox from './SearchBox';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { convertEnvInfo } from '../../Util/utils';

const pageSize = 50;

function ContactManagement() {
  const [timestamp, setTimestamp] = React.useState(new Date().getTime());
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.contacts.loading);
  const reduxContacts = useSelector((state) => state.contacts.items);
  const [contacts, setContacts] = useState([]);
  const totalRow = useSelector((state) => state.contacts.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    sort: { 'contact.createdDate': 'DESC' },
  });

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const [contactDetail, setContactDetail] = useState(null);
  const [mutating, setMutating] = useState(false);

  const reload = () => setTimestamp(new Date().getTime());

  const handleClickDetail = (contact) => {
    setContactDetail(contact);
  };
  const handleClose = () => {
    setContactDetail(null);
  };
  const onToggleAgeVerification = async () => {
    setMutating(true);
    const newValueOfAgeVerification = !!contactDetail.user.ageverification;
    const result = await sendPut(`/cms/user/${contactDetail.user.id}`, {
      ageverification: newValueOfAgeVerification,
    });
    const { message, success } = result.data;
    if (success) {
      // update in client
      // draftState.items[index].user.ageverification = ageverification ? 0 : 1;
      setContacts(
        contacts.map((item) => {
          if (item.id === contactDetail.id) {
            return {
              ...item,
              user: {
                ...item.user,
                ageverification: newValueOfAgeVerification,
              },
            };
          }
          return item;
        }),
      );
      setContactDetail(null);
      setMutating(false);
      toast.success(TOAST_MESSAGE.SUCCESS_EDIT);

      return;
    }
    toast.error(message);
    setMutating(false);
  };

  useEffect(() => {
    dispatch(loadContacts({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter, timestamp]);

  useEffect(() => {
    setContacts([...reduxContacts]);
  }, [reduxContacts]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: {} });
    setPageIndex(1);
    dispatch(loadContacts({ pageIndex, pageSize: 1, ...filter }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const onUpdateConfirm = (contact) => {
    setContacts(
      contacts.map((item) => {
        if (item.id === contact.id) {
          return {
            ...contact,
          };
        }
        return item;
      }),
    );
  };

  return (
    <div>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />
      <LoadingBar show={loading || mutating} />
      {contactDetail ? (
        <ContactDetailModal
          onClose={handleClose}
          contact={contactDetail}
          mutating={mutating}
          onToggleAgeVerification={onToggleAgeVerification}
          reload={reload}
          onUpdateConfirm={onUpdateConfirm}
          labelDb={labelDb}
        />
      ) : null}
      <ContactList
        contacts={contacts}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default ContactManagement;
