import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Chip from '@material-ui/core/Chip';
import style from './style.module.scss';
import Avatar from '@material-ui/core/Avatar';
import NoAvatar from '../../../../../../assets/image/sys_photo.png';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiChip: {
      label: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      },
    },
  },
});

function Message(props) {
  let { message } = props;
  const messageList = () => {
    const timestamp = props.message.createdDate; // This would be the timestamp you want to format
    let chatTime = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
    }).format(timestamp);

    let chatTimeDisplay = chatTime.replace(/,/g, ' ');
   
    if (props.sender.id !== message.senderId) {
      return (
        <div className={style.__message__revecie}>
          <Avatar className="" src={NoAvatar} />
          {message.type === 2 ? (
            <img src={message.content} alt="" />
          ) : (
            <Chip label={message.content} className={style.__receive} />
          )}
          <p> {chatTimeDisplay} </p>
        </div>
      );
     }
     if (props.sender.id === message.senderId) {
      return (
         <div className={style.__message__sender}>
           <p> {chatTimeDisplay} </p>
           {message.type === 2 ? (
             <img src={message.content} alt="" />
           ) : (
             <Chip label={message.content} className={style.__sender} />
           )}
         </div>
       );
     }
    return null;
  };

  return (
    <div>
      <MuiThemeProvider theme={customTheme}>{messageList()}</MuiThemeProvider>
    </div>
  );
}

export default Message;
