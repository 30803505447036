import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import { loadEnvTopNavWeb } from '../../Redux/Actions/envTopNavWeb';
import EnvTopNaviWebSettingList from './EnvTopNaviWebSettingList';

const pageSize = 1000;
const mutating = false;

function Index() {
  const [pageIndex] = useState(1);
  const loading = useSelector((state) => state.envTopNavWeb.loading);
  const envTopNavWeb = useSelector((state) => state.envTopNavWeb.data);

  const [isEditing, setIsEditing] = useState(false);
  const [envTopNavWebs, setEnvTopNavWebs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvTopNavWeb({ pageSize, pageIndex }));
  }, [dispatch, pageIndex]);

  useEffect(() => {
    setEnvTopNavWebs(envTopNavWeb);
  }, [envTopNavWeb]);

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvTopNaviWebSettingList
        loading={loading}
        data={envTopNavWebs}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export default Index;
