import { createAction } from 'redux-actions';
import listRawDatabase from '../Types/listRawDatabase';

// information
export const loadListRawInformation = createAction(listRawDatabase.LOAD_LIST_RAW_INFORMATION);
export const loadListRawInformationSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_INFORMATION_SUCCESS);
export const loadListRawInformationFail = createAction(listRawDatabase.LOAD_LIST_RAW_INFORMATION_FAIL);

// twilio-history
export const loadListRawTwilioHistory = createAction(listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY);
export const loadListRawTwilioHistorySuccess = createAction(listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY_SUCCESS);
export const loadListRawTwilioHistoryFail = createAction(listRawDatabase.LOAD_LIST_RAW_TWILIO_HISTORY_FAIL);

// text-web-page
export const loadListRawTextWebPage = createAction(listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE);
export const loadListRawTextWebPageSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE_SUCCESS);
export const loadListRawTextWebPageFail = createAction(listRawDatabase.LOAD_LIST_RAW_TEXT_WEB_PAGE_FAIL);

// operator
export const loadListRawOperator = createAction(listRawDatabase.LOAD_LIST_RAW_OPERATOR);
export const loadListRawOperatorSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_OPERATOR_SUCCESS);
export const loadListRawOperatorFail = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE_FAIL);

// agent
export const loadListRawAgent = createAction(listRawDatabase.LOAD_LIST_RAW_AGENT);
export const loadListRawAgentSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_AGENT_SUCCESS);
export const loadListRawAgentFail = createAction(listRawDatabase.LOAD_LIST_RAW_AGENT_FAIL);

// member_archive
export const loadListRawMemberArchive = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE);
export const loadListRawMemberArchiveSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE_SUCCESS);
export const loadListRawMemberArchiveFail = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ARCHIVE_FAIL);

// member admin
export const loadListRawMemberAdmin = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN);
export const loadListRawMemberAdminSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN_SUCCESS);
export const loadListRawMemberAdminFail = createAction(listRawDatabase.LOAD_LIST_RAW_MEMBER_ADMIN_FAIL);

// otp
export const loadListRawOtp = createAction(listRawDatabase.LOAD_LIST_RAW_OTP);
export const loadListRawOtpSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_OTP_SUCCESS);
export const loadListRawOtpFail = createAction(listRawDatabase.LOAD_LIST_RAW_OTP_FAIL);

// payment log job
export const loadListRawPaymentLogJob = createAction(listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB);
export const loadListRawPaymentLogJobSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB_SUCCESS);
export const loadListRawPaymentLogJobFail = createAction(listRawDatabase.LOAD_LIST_RAW_PAYMENT_LOG_JOB_FAIL);

// popup setting
export const loadListRawPopupSetting = createAction(listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING);
export const loadListRawPopupSettingSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING_SUCCESS);
export const loadListRawPopupSettingFail = createAction(listRawDatabase.LOAD_LIST_RAW_POPUP_SETTING_FAIL);

// log mail verification
export const loadListRawLogMailVerification = createAction(listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION);
export const loadListRawLogMailVerificationSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_SUCCESS);
export const loadListRawLogMailVerificationFail = createAction(listRawDatabase.LOAD_LIST_RAW_LOG_MAIL_VERIFICATION_FAIL);

// member_archive
export const loadListRawJobDetail = createAction(listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL);
export const loadListRawJobDetailSuccess = createAction(listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL_SUCCESS);
export const loadListRawJobDetailFail = createAction(listRawDatabase.LOAD_LIST_RAW_JOB_DETAIL_FAIL);
